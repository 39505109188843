import { GamePlayerRankingModel } from 'src/app/scouting/models/GameModels';

export class GameRatingLog {
    GameId: string;
    TeamId: string;
    PlayerId: string;
    Ranking: number;
    DefensiveGameRanking: number;
    OffensiveGameRanking: number;
    GameContributionRanking: number;
    GameFocusRanking: number;
    Stress: number;
    Nutrition:number;
    Sleep: number;    
    Duration: number;
    NoParticipationReason: number;
    Comment: string;
    CommentToPlayer: string;
    CommentPrivate: string;
    ScoutName: string;
    ScoutFullName: string;
    UserId: string;
  }

  export class GamePlayerRankingSummaryModel{

    GameId: string;
    UserId: string;
    TeamId: string;
    ScoutName: string;
    LastName: string;
    FirstName: string;
    RankingNoOfBad: number;
    RankingNoOfAverage: number;
    RankingNoOfGood: number;    
    RankingNoOfVeryGood: number;    
    RankingNoOfExcellent: number;    
  }

  export class GamePlayerRankingPlayerModel
  {
      PlayerId: string;
      GameId: string;
      Ranking: number;
      DefensiveGameRanking: number;
      OffensiveGameRanking: number;
      GameContributionRanking: number;
      GameFocusRanking: number;
      Comment: string;

      //Prep
      Stress: number;
      Nutrition: number;
      Sleep: number;

      //
      TeamHomeName: string;
      TeamAwayName: string;
      OpponentName: string;


  }

  export class GameAssessmentPlayer
  {
      GameId: string;
      PlayerId: string;
      UserId: string;
      Ranking: number;
      Duration: string;

      NoParticipationReason: number;
      OrgId: number;
      Comment: string;

      ParameterGroups: GameAssessmentGroup[]



  }

  export class GameAssessmentGroup
  {
      ParameterGroupId: string;
      InternalName: string;
      Name: string;

      Sequence: string;

       Parameter: GameAssessmentPlayerParameter[];
  }


  export class GameAssessmentParameter
  {
      ParameterId: string;
      Name: string;
      ReverseRanking: boolean;




  }

  export class GameAssessmentPlayerParameter
  {
      GameId: string;

      PlayerId: string;
      UserId: string;

      ParameterId: string;
      Name: string;

      ReverseRanking: boolean;

      Ranking: string;



  }

  export class GamePlayerRankingViewModel
  {
      ScoutName: string;
      ScoutFirstName: string;
      ScoutLastName: string;
      GamePlayerRankingsHome: GamePlayerRankingModel[];
      GamePlayerRankingsAway: GamePlayerRankingModel[];
      NoOfBadHome: number;
      NoOfNeutralHome: number;
      NoOfGoodHome: number;
      NoOfVeryGoodHome: number;
      NoOfExcellentHome: number;
      AverageGradeHom: string;

      NoOfBadAway: number;
      NoOfNeutralAway: number;
      NoOfGoodAway: number;
      NoOfVeryGoodAway: number;
      NoOfExcellentAway: number;
      AverageGradeAway: string;

      RankingNoOfBad: number;
      RankingNoOfAverage: number;
      RankingNoOfGood: number;
      RankingNoOfVeryGood: number;
      RankingNoOfExcellent: number;

      AverageRanking: number;



  }

 