import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Form, UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ProgramDayModel, ProgramModel, ProgramWorkoutModel, WorkoutModel } from '../../models/exercise.model';
import { TrainingService } from '../../services/training.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { weekdays } from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';




@Component({
  selector: 'app-new-program',
  templateUrl: './new-program.component.html',
  styleUrls: ['./new-program.component.css']
})
export class NewProgramComponent implements OnInit {
  isLoaded: boolean = false;
  Program: ProgramModel;
  ProgramId: number;
  programForm: any;
  realForm: UntypedFormGroup;
  Days: UntypedFormArray;
  isUpdate: boolean;
  noofdays: number=0;
  noofWeeks: number= 0;
  droptargets: string[];
  filteredWorkouts: WorkoutModel[];
  filteredWorkoutsForm: UntypedFormGroup[] = [];
  Workouts: WorkoutModel[];
  Weeks: any;
  public DayNames =  ['MON','TUE','WED','THU','FRI','SAT','SUN'];

  constructor(private _router: Router,
    private route: ActivatedRoute,
    private _trainingService: TrainingService,  
    private _location: Location,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    console.log("OnInit start");
    this.isLoaded = false;

    this.route.params.subscribe(params => {
      this.ProgramId = params['ProgramId'];
      //console.log(this.ProgramId);
    });

    

    this.droptargets = [];

    this.isUpdate =  (this.ProgramId!=undefined);

/*     this.programForm = this.fb.group({       
      ProgramId: '',
      Name:'',
      Status: 'DRAFT',
      Description: '',
      Weeks: this.fb.array([
        this.fb.group({
          WeekId: 1,
          Days: this.createEmptyWeekDays()

        }),
        this.fb.group({
          WeekId: 1,
          Days: this.createEmptyWeekDays()

        })
      ])
      //Days: this.fb.array([])
    }) */

    //this.Days = this.programForm.get('Days') as FormArray;
    //console.log(this.programForm);
    //console.log("Before form builds");
    if(!this.isUpdate){
      //create new workout
      //Start defining the empty reactive form
      this.programForm = this.fb.group({       
        Name: ['',[Validators.required]],
        ProgramId: '',
        Description: '',
        Status: 'DRAFT',       
        Weeks: this.createAnEmptyWeek(),
        //Days: this.fb.array([])
      });
      this.noofWeeks=1;
      //this.Days = this.programForm.get('Days') as FormArray;
      //console.log('IS NEW');

     /*  console.log(this.programForm);
      console.log('droptargets');  
      console.log(this.droptargets);
      console.log('isLoaded');   */
      this.isLoaded= true;
      //console.log("after form builds");
    }else{
      //Get the program
      this._trainingService.getProgram(this.ProgramId).subscribe(data=>{
        this.Program = data as ProgramModel;

        
        //this.Weeks = this.Weeks =  [ ...Array(this.Program.NoOfWeeks).keys() ];
       /*  console.log("getting program")
        console.log(this.Program); */

        console.log('this.Program=');
        console.log(this.Program);
        this.programForm = this.fb.group({
          ProgramId: this.Program.ProgramId,
          Status: this.Program.Status,
          Description: this.Program.Description,
          Name: [this.Program.Name,[Validators.required]], 

         
          Weeks: this.createWeeks(this.Program),
          //Days: this.addDaysFromModel(this.Program.Days)
        });

        

        this.programForm.patchValue({ Status: this.Program.Status });
        //this.Days = this.programForm.get('Days') as FormArray;
       /*  console.log('IS UPDATE');
        console.log(this.programForm);
        console.log("droptargets");
        console.log(this.droptargets);
        console.log('isLoaded'); */
        this.isLoaded= true;
       /*  console.log("after form builds"); */
        
      })
      
      //this.isLoaded=true;
      //Build form with workout data
      
    }

    this.getWorkouts();
   

  }

  get weeks() : UntypedFormArray{
    console.log("get weeks");
    //console.log(this.programForm?.get("Weeks") as FormArray);
    //return this.programForm?.get("Weeks");
    return null;
  }

  getWeeks(): UntypedFormArray{
    console.log("getWeeks()");
    console.log(this.programForm.get('Weeks'));
    //return this.createWeeks(this.Program);
    return this.programForm.get('Weeks');
    
  }

  daysforweek(weekindex): UntypedFormArray{
    console.log('daysforweek' + weekindex);
    console.log(this.programForm.get("Weeks").at(weekindex).get('Days')); 
    return null;
    //return this.programForm?.get("Weeks").at(weekindex).get('Days');
    
  }

  createAnEmptyWeek(){
    var weeks = this.fb.array([]);
    
    weeks.push(this.createEmptyWeekFormGroup());
    

    return weeks;
  }

  createEmptyWeekFormGroup(){
    var week = this.fb.group({
      WeekId: 1,            
      Days: this.createEmptyWeekDays(),          
    });
    return week;
  }

  createEmptyWeekDays(){
    var days = this.fb.array([]);
    for (let index = 0; index < 7; index++) {
      days.push(this.addDayForm());            
    }
    return days;
  }

  createWeeks(program: ProgramModel): UntypedFormArray{
    console.log('Create weeks start');
    var weeks  = this.fb.array([]);
    for (let index = 0; index < program.NoOfWeeks; index++) {
        
        var _days = this.fb.array([]);
        for (let dayNo = 0; dayNo < 7; dayNo++) {
          var day = this.fb.group({
              Week: index+1,
              Day: dayNo+1,
              ProgramWorkouts: this.getWorkoutsForWeekDay(index,dayNo)
          });
          _days.push(day);
          this.droptargets.push("Day_" + dayNo + "_" + index);
        }

        var week = this.fb.group({
          WeekId: index+1,            
          Days: _days          
        });

        weeks.push(week);                    
    }
    console.log('Create weeks:');
    console.log(weeks);
   return weeks;
  }

  

  getProgramWorkoutsForWeekDay(weekNo: number, dayNo: number): UntypedFormArray{
    /* console.log("get wiorkoutsdfor day: " + dayNo); */
   /*  console.log(this.getDays().at(dayNo).get('Workouts'));
    console.log(this.getDays().length); */

    var week = this.programForm.get('Weeks').at(weekNo);
    var day = week.get('Days').at(dayNo);
    console.log('day.get(Workouts):');
    console.log(day.get('Workouts'));
    return day.get('ProgramWorkouts');
    
    
  }


  getWorkoutsForWeekDay(weekNo: number, dayNo: number): UntypedFormArray{
  
      
      var workouts = this.Program.ProgramWorkouts.filter((obj)=>{
        return (obj.Day === (dayNo+1) && obj.Week===(weekNo+1));
      }) as ProgramWorkoutModel[];
      
      
      var workoutarray= this.fb.array([]);
      workouts.forEach(element => {
        workoutarray.push(
          this.fb.group({
            Name: element.Workout.Name,
            WorkoutId:element.WorkoutId,
            Day: element.Day,
            Ordinal: element.Ordinal,
            ProgramId: element.ProgramId,
            Workout: this.fb.group(element.Workout)
          
          })
        );
      });
      console.log('-----------');
      console.log(workoutarray);
      console.log('-----------');
      return workoutarray;
 
    
  }

  addWeek(){
    var _days = this.fb.array([]);
    var noOfWeeks = this.programForm.get('Weeks').length;
    for (let dayNo = 0; dayNo < 7; dayNo++) {
      var day = this.fb.group({
          Week: noOfWeeks+1,
          Day: dayNo+1,
          ProgramWorkouts: this.fb.array([])
      });
      _days.push(day);
      this.droptargets.push("Day_" + dayNo + "_" + noOfWeeks);
    }

    console.log('this.droptargets');
    console.log(this.droptargets);

    var week = this.fb.group({
      WeekId: noOfWeeks,            
      Days: _days          
    });
    

    this.programForm.get('Weeks').push(week);   
  }

  removeWeek(weekNo){
    //this.programForm.get('Weeks').controls.splice(weekNo,1);
    this.programForm.get('Weeks').removeAt(weekNo);

    console.log('Remove week');
    console.log(this.programForm.get('Weeks'));
  }


  getWorkouts(){
    this._trainingService.getWorkouts().subscribe(data=>{
      this.Workouts = data as WorkoutModel[];
      this.filteredWorkouts = data  as WorkoutModel[];
      this.Workouts.forEach(wo=>{
        this.filteredWorkoutsForm.push(this.addWorkoutForm(wo));
      });
    });
  }

  addWorkoutsFormFromModel(theWorkouts: ProgramWorkoutModel[]){
    var workouts = this.fb.array([]);
    theWorkouts.forEach(wo => {
      workouts.push(this.addProgramWorkoutForm(wo));
    });

    return workouts;

  }

  addWorkoutForm(theWorkout: WorkoutModel){
    return this.fb.group({
      OrganizationId: theWorkout.OrganizationId,
      ProgramId: '',
      Name: theWorkout.Name,
      WorkoutId: theWorkout.WorkoutId,
      Day: '',          
    });
  }

  addProgramWorkoutForm(theWorkout: ProgramWorkoutModel){
    return this.fb.group({
      OrganizationId: theWorkout.OrganizationId,
      ProgramId: '',
      Name: theWorkout.Workout.Name,
      WorkoutId: theWorkout.WorkoutId,
      Day: '',
      
    
    });
  }



  getDays(): UntypedFormArray {  
    //console.log(this.programForm);
    return this.programForm.get('Days') as UntypedFormArray;
  }

  addDaysFromModel(days: ProgramDayModel[]): UntypedFormArray{
    var newFormArray = this.fb.array([]);
    days.forEach(day => {
      newFormArray.push(this.addDayFormFromModel(day))
    });

    return newFormArray;
  }



  addDay(){
    //this.Days.push(this.addDayForm());
  }


  

  deleteDay(dayNo){
    this.getDays().removeAt(dayNo);
  }

  drop1(event: CdkDragDrop<UntypedFormGroup[]>) {
    //console.log('drop1');
    var splittedTextPrevious = event.previousContainer.id.split('_',3);
    var splittedTextNext = event.container.id.split('_',3);
    var dayno_previous = parseInt(splittedTextPrevious[1]);
    var weekno_previous = parseInt(splittedTextPrevious[2]);
    var dayno_next = parseInt(splittedTextNext[1]);
    var weekno_next = parseInt(splittedTextNext[2]);

    if (event.previousContainer === event.container) {
        //Dropped from the same container
        var splittedText = event.container.id.split('_',3);
        //console.log(splittedText);
        var day_next: number = + event.container.id.substring(4);   
        //var formArray = this.getProgramWorkoutsForWeekDay(day_next-1);
        const from = event.previousIndex; 
        const to = event.currentIndex      
        //this.moveItemInFormArray(formArray, from, to);
    } else {
        var splittedText = event.container.id.split('_',3);
        //console.log(splittedText);
        //Dragged from a different container than where it was dropped
        if(event.previousContainer.id!="workoutList"){
          //Not dropped from exercise library, so it is moved between sections
         

          //var dayno_previous= + event.previousContainer.id.substring(4);
          //var dayno_next: number = + event.container.id.substring(4);        
          //Add to new section, remove from old
         /*  console.log("Previous_" + dayno_previous + "_" + weekno_previous);
          console.log("Next_" + dayno_next + "_" + weekno_next); */
          console.log(event.previousContainer.data[event.previousIndex]);
          this.getProgramWorkoutsForWeekDay(weekno_next, dayno_next).push(event.previousContainer.data[event.previousIndex]);
          this.getProgramWorkoutsForWeekDay(weekno_previous, dayno_previous).removeAt(event.previousIndex);
        }else{
          //Dropped from exercise library, just add in new container
          /* console.log("drop 1 event.container.id=" + event.container.id);       */
          //var dayno_next: number = + event.container.id.substring(4) ;        
          /* console.log("drop1: from exerciselib, dayno_next=" + dayno_next);      */   
          var workout = event.previousContainer.data[event.previousIndex] as UntypedFormGroup;
          /* console.log(workout); */
          this.getProgramWorkoutsForWeekDay(weekno_next, dayno_next).push(event.previousContainer.data[event.previousIndex]);
        }
      
      
    }
  }

  moveItemInFormArray(formArray: UntypedFormArray, fromIndex: number, toIndex: number): void {
    const from = this.clamp(fromIndex, formArray.length - 1);
    const to = this.clamp(toIndex, formArray.length - 1);
  
    if (from === to) {
      return;
    }
  
    const delta = from < to ? 1 : -1;
    //console.log('moveiteminformarray '+  from + '->' + to + ' with delta ' + delta);
    for (let i = from; i * delta < to * delta; i += delta) {
     /*  console.log('i: ' + i);
      console.log('delta: ' + delta); */
      const previous = formArray.at(i);
      const current = formArray.at(i + delta);
      formArray.setControl(i, current);
      formArray.setControl(i + delta, previous);
    }
   /*  console.log('In moveitem'); */
   /*  formArray.controls.forEach(c =>{
      console.log(c.get('Name').value);
    }); */
    

  }

    /** Clamps a number between zero and a maximum. */
    clamp(value: number, max: number): number {
      return Math.max(0, Math.min(max, value));
    }
  
  addDayForm(){
    console.log('addDayForm');
    this.droptargets.push("Day_" + this.noofdays + "_0");
    this.noofdays++;
    
    return this.fb.group({      
      Day: this.noofdays,     
      ProgramWorkouts: this.createEmptyWorkoutArray()
    
    });
  }

  createEmptyWorkoutArray(){
    var workouts = this.fb.array([]);   
    return workouts;
  }

  addDayFormFromModel(day: ProgramDayModel ){
    
    this.droptargets.push("Day_" + this.noofdays);
    this.noofdays++;
    //console.log(this.droptargets); 
    return this.fb.group({      
      Day: this.noofdays,     
      Week: day.Week,      
      //Workouts: this.addWorkoutsFormFromModel(day.Workouts)
    
    });
  }


  save(){


    //console.log(this.programForm.value);

    if(!this.isUpdate){
      this._trainingService.createProgram(this.programForm.value).subscribe(result=>{
        //console.log(result);
        if(result){
          this.openSnackBar("Program created", "CLOSE");
          this._location.back();
        }else{
          this.openSnackBar("Could not create program", "CLOSE");
        }
      });
    }else{
      //console.log(this.programForm.value);
      this._trainingService.updateProgram(this.programForm.value).subscribe(result=>{
        //console.log(this.programForm.value);
        if(result){
          this.openSnackBar("Program saved", "CLOSE");
          this._location.back();

        }else{
          this.openSnackBar("Save failed", "CLOSE");
        }
          
        
      });
    }  
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  

  cancel(){
    this._location.back();
  }


}
