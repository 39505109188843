import { Component, OnInit, Input } from '@angular/core';
import { GameLineUp, SCGameLineUpPlayer } from '../../models/GameModels';

@Component({
  selector: 'app-game-lineup',
  templateUrl: './game-lineup.component.html',
  styleUrls: ['./game-lineup.component.css']
})
export class GameLineupComponent implements OnInit {
  @Input() LineUpInfo: SCGameLineUpPlayer[];
  @Input() TeamIcon: string;
  displayLineUp: GameLineUp;

  
  constructor() { }

  ngOnInit() {

    this.displayLineUp = new GameLineUp(this.LineUpInfo);
  }

  getPlayer(LineCode: string, PositionCode: string){
    return this.LineUpInfo.filter(function(el){
      return el.PositionCode == PositionCode && el.LineCode==LineCode;
    });
  }

}
