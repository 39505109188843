<mat-card appearance="outlined" style="max-width: 330px; min-width: 275px; margin: 10px;" class="mat-elevation-z4">
    <mat-card-header>
     <div mat-card-avatar class="example-header-image">
            <mat-icon>
                grid_view
            </mat-icon>
        </div> 
       
    <!-- <img mat-card-avatar *ngIf="!(this.Player.ProfileImage)" src="../../assets/img/icons8-user-shield-48.png">     -->
    <mat-card-title>
        <mat-label style="font-size: medium ;">{{ TestView.Name }}</mat-label>
    </mat-card-title>       
    <mat-card-subtitle>
        <mat-label style="font-size: medium ;">{{ TestView.DisplayName }}</mat-label>
    </mat-card-subtitle> 
</mat-card-header>
<mat-card-content >
    <table>
        <tr>
            <th style="min-width: 70px; text-align: left;">Position</th>
            <th  style="min-width: 70px; text-align: left;">Profile</th>
        </tr>
        
        <tr *ngFor="let profile of TestView.Profiles">
            <td>
                {{profile.PositionLanguage.Text}}
            </td>
            <td> 
                <ng-container *ngIf="profile.Profile">
                    <i class="material-icons">
                        assignment
                    </i>
                 
                    {{profile.Profile.Name}}
                </ng-container>

                
            </td>
        </tr>
    </table>
    <br/>
    <table>
        <tr>
            <th style="min-width: 70px; text-align: left;">Team</th>
            <th style="text-align: left;">Enabled</th>
            <th style="width: 100px;text-align: left;">Visible for all</th>
            <th style="width: 50px; text-align: left;">Users</th>
        </tr>
        
        <tr *ngFor="let team of TestView.Teams">
            <td>
                {{team.LevelName}}
            </td>
            <td>
                <mat-icon *ngIf="team.Enabled">
                    check_box
                </mat-icon>
                <mat-icon *ngIf="!team.Enabled">
                    check_box_outline_blank
                </mat-icon>                
            </td>
            <td>
                <mat-icon *ngIf="team.VisibleForAll">
                    check_box
                </mat-icon>
                <mat-icon *ngIf="!team.VisibleForAll">
                    check_box_outline_blank
                </mat-icon>                
            </td>
            <td>
                <i class="material-icons">
                    person
                </i>
                {{team.Users.length}}
                
            </td>
        </tr>
    </table>
  
    
</mat-card-content>
<mat-card-actions>
  <button mat-button [routerLink]="['/testview/', TestView.TestViewId]">EDIT</button>
  <button mat-button (click)="deleteView()">DELETE</button>
</mat-card-actions>
</mat-card>
