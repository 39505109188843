   
<div style="margin: 5px; padding-top: 5px; background-color: #424242; font-family:Roboto; min-width: 270px; max-width: 270px; border-style: solid; border-color: gray;" class="mat-elevation-z5"  [ngStyle]="{'border-width': ((this.Game.HomeTeamTags && this.Game.HomeTeamTags.length>0) || (this.Game.AwayTeamTags && this.Game.AwayTeamTags.length>0)) ? '2px' : '0px'}" >
    <div style="color: darkgray;">
            {{this.Game.DivisionName}}
    </div>
    <div style="display: flex; margin: 5px 5px 5px 5px;"   (click)="openGameDetails(this.Game.GameId)" matRipple matRippleColor="'red''">
        <div style="font-size: x-large;" *ngIf="ShowDate">
            {{this.Game.GameDate | date: 'dd'}}<br>
            {{this.Game.GameDate  | date: 'LLL'}}
        </div>
        <div style="display: block; width: 100%">
            <table style="width: 100%;">
                <tr>
                    <td style="text-align: center; width: 33%;height: 50px;">
                        <img *ngIf="this.Game.HomeTeamSmallIcon" style="height: 50px;" [src]="'../../assets/img/' + this.Game.HomeTeamSmallIcon">
                        <img  *ngIf="this.Game.HomeTeamSmallIconFileName" style="height: 50px;" [src]="'../../assets/img/' + this.Game.HomeTeamSmallIconFileName"></td>
                    <td style="width: 33%;font-size: small; text-align: center; color: darkgray;vertical-align: top;;">
                        <div style="font-size: large;">{{this.Game.GameTime | date: 'HH:mm'}}</div>
                        {{this.Game.Venue}}
                    </td>
                    <td style="text-align: center;width: 33%;">
                        <img *ngIf="this.Game.AwayTeamSmallIcon" style="height: 50px;" [src]="'../../assets/img/' + this.Game.AwayTeamSmallIcon">
                        <img *ngIf="this.Game.AwayTeamSmallIconFileName" style="height: 50px;" [src]="'../../assets/img/' + this.Game.AwayTeamSmallIconFileName">
                    </td>
                    
                    
                </tr>
          
            </table>

            <div style="display: flex; justify-content: space-between;flex-direction: row; width: 100%; font-size: small;">
                <div>{{this.Game.HomeTeamName}}</div>
                <div>{{this.Game.AwayTeamName}}</div>
            </div>
            <div></div>
        </div>
        
        <div></div>
        <div></div>
    </div>
    <div *ngIf="!((this.Game.HomeTeamTags && this.Game.HomeTeamTags.length>0) || (this.Game.AwayTeamTags && this.Game.AwayTeamTags.length>0))  " style="margin-left: 5px; font-size: small; color: darkgray; height: 40px;">
    </div>
    <div *ngIf="(this.Game.HomeTeamTags && this.Game.HomeTeamTags.length>0) || (this.Game.AwayTeamTags && this.Game.AwayTeamTags.length>0)  " style="margin-left: 5px; font-size: small; color: darkgray;">Tags</div>
    <div style="display: flex; justify-content: space-between;flex-direction: row; width: 100%; font-size: small;">
        <div style="margin-left: 5px; display: flex; flex-direction: row;">
         <!--    <mat-icon *ngFor="let tag of this.Game.HomeTeamTags" [class]="tag.TagColor">label</mat-icon> -->
            <div *ngFor="let tag of this.Game.HomeTeamTags" [class]="tag.TagColor" style="font-size: small; border-radius: 3px;  margin-right: 3px; width: 20px;  height: 7px;   ">
                
            </div>
        </div>
        <div style="margin-right: 5px; display: flex; flex-direction: row;">
            <!-- <mat-icon *ngFor="let tag of this.Game.AwayTeamTags" [class]="tag.TagColor">label</mat-icon> -->
            <div *ngFor="let tag of this.Game.AwayTeamTags" [class]="tag.TagColor" style="font-size: small; border-radius: 3px; margin-right: 3px;  width: 20px; height: 7px;   ">
                
            </div>
        </div>
    </div>
    <div  style=" background-color: #424242; font-family:Roboto; min-width: 270px; max-width: 270px;" >
        <button mat-icon-button (click)="addGameToSchedule()" *ngIf="!this.Game.IsInMySchedule" matTooltip="ADD TO MY SCHEDULE">
            <span class="material-symbols-outlined">
                calendar_add_on
            </span>
        </button>
        
        <button mat-icon-button (click)="removeGameFromSchedule()" *ngIf="this.Game.IsInMySchedule"
            matTooltip="REMOVE FROM MY SCHEDULE">
            <span class="material-symbols-outlined" style="color: indianred;">
                event_busy
            </span>
        </button>

    </div>

</div>

