
<mat-card appearance="outlined" style="max-width: 340px; min-width: 300px;   margin: 10px; border: gray 1px solid; border-left: white 3px solid;" class="mat-elevation-z8">
    <table>
        <tr>
            <td>
                <mat-icon mat-card-avatar style="font-size: 40px; width: 45px;">person</mat-icon>
            </td>
            <td>
                    <span style="font-size: large;">{{ this.Contact.LastName}}, {{ this.Contact.FirstName}}</span><br>
                    <span style="font-size: medium; color: darkgray; font-size: 12px; ">
                      {{this.Contact.Relation}}
                    </span>
                  
                    
            </td>
        </tr>
    </table>
     
<mat-card-header>
    <!-- <img mat-card-avatar  [src]="'../../assets/img/' + this.Report.PlayerCurrentTeamIcon"     > -->
   
    <mat-card-title>
     
    </mat-card-title>
  


</mat-card-header>
<mat-card-content style="margin-bottom: 0px;" >

    <div
        style="font-family: rubik; font-size: small;  text-align: left; vertical-align: top;  padding: 0; white-space: normal; margin: 0; color: white;  ">
        <table>
            <tr *ngIf="this.Contact.HomePhone">
                <td>
                        <mat-icon style="color: white;font-size: 16px;">phone</mat-icon>
                </td>
                <td>
                    <a [href]="'tel:' + this.Contact.HomePhone" style="text-decoration: none; color: white;">
                    {{this.Contact.HomePhone}}
                    </a>
                </td>
            </tr>
            <tr *ngIf="this.Contact.MobilePhone">
                <td>
                        <mat-icon style="color: white; font-size: 16px;">phone</mat-icon>
                </td>
                <td>
                    <a [href]="'tel:' + this.Contact.MobilePhone" style="text-decoration: none; color: white;">
                    {{this.Contact.MobilePhone}}
                    </a>
                </td>
            </tr>
            <tr *ngIf="this.Contact.Email">
                <td>                    
                        <mat-icon style="color:white;font-size: 16px;">mail</mat-icon>                                         
                </td>
                <td>  
                    <div >
                        <a [href]="'mailto:' + this.Contact.Email" style="text-decoration: none; color: white;">
                        {{this.Contact.Email}}
                        </a>
                   
                    </div>
                </td>
            </tr>
            
           
            <tr *ngFor="let address of Contact.Addresses;">
                    <td style="vertical-align: top;">
                        <mat-icon style="color: white;font-size: 16px; "> home</mat-icon>
                        
                    </td>
                    <td>
                                          
                        <div *ngIf="address.Address1">
                            {{address.Address1}}    
                        </div>
                        <div *ngIf="address.Address2">
                            {{address.Address2}}
                        </div>
                        <div *ngIf="address.Address3">
                            {{address.Address3}}
                        </div>
                        <div *ngIf="address.City">
                            {{address.PostCode}}&nbsp;{{address.City}}                            
                        </div>
                        <div *ngIf="address.Country">
                            {{address.Country}}                            
                        </div>                                                                        
                        
                    </td>
                </tr>
        </table>    
      
       
        
    </div>





</mat-card-content>
<mat-card-actions>
    <button *ngIf="this.isAllowedToEdit" mat-button (click)="ediContact()">EDIT</button>
    <button *ngIf="this.isAllowedToEdit" mat-button (click)="deleteContact()">DELETE</button>
</mat-card-actions>

</mat-card> 
