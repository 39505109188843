
<mat-card appearance="outlined" STYLE="max-width: 570px; margin: 10px;">
    <mat-card-header>  
      <div mat-card-avatar>
      
          <i class="material-icons">
              dynamic_form
              </i>
        </div>    
      <mat-card-title>{{this.FormSubmitted.Form.Name}}</mat-card-title>
      <mat-card-subtitle>
        <table>
          <tr>
            <td  >
              <mat-icon>today</mat-icon>
            </td>
            <td >
              {{this.FormSubmitted.DocumentDate | date:'yyyy-MM-dd'}}
            </td>
          </tr>
          <tr>
            <td  >
              <img _ngcontent-swi-c109="" src="../../assets/img/shield.png" style="height: 20px;">
            </td>
            <td  >
              {{this.FormSubmitted.TeamName}}
              
            </td>
          </tr>
        
          <!-- <tr *ngIf="this.FormSubmitted.Player">
            <td style="color:white;">
              <mat-icon>person</mat-icon>
              
            </td>
            <td >
              <a [routerLink]="['/player/details', this.FormSubmitted.PlayerId]" style="color: white;">
              {{this.FormSubmitted.Player.Player.LastName}},{{this.FormSubmitted.Player.Player.FirstName}}
              </a>
            </td>
          </tr> -->
          <tr *ngIf="false">
            <td  >
              Game:
            </td>
            <td >
              
            </td>
          </tr>
         
        </table>
        <app-player-details-section  *ngIf="this.FormSubmitted.Player" [Player]="this.FormSubmitted.Player.Player"></app-player-details-section>
        <br/>
        
        <button mat-icon-button (click)="this.Minimized=!this.Minimized">
<!--           <span *ngIf="this.Minimized">EXPAND</span>
          <span *ngIf="!this.Minimized">HIDE</span> -->
          <mat-icon *ngIf="this.Minimized">expand_more</mat-icon>
          <mat-icon *ngIf="!this.Minimized">expand_less</mat-icon>
        </button>
      </mat-card-subtitle>
      
    </mat-card-header>    
    <mat-card-content class="CardContent">

        
      

        <mat-divider></mat-divider>
        <ng-container *ngIf="!this.Minimized">
        <div *ngFor="let section of this.FormSubmitted.Form.FormVersion.Sections" class="Section" >
          {{section.Text}}
          <table style="width: 100%; margin-left: 10px;">
              <ng-container *ngFor="let question of section.Questions" >
                <ng-container [ngSwitch]="question.ValueType"  > 


                  
                  <ng-container *ngSwitchCase="'Text'">
                    <tr  class="Question">
                      <td class="QuestionText">
                        {{question.Text}}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div  *ngIf="question.Answer" style="margin-left: 5px; border-left: white solid 2px; padding: 3px;">
                          {{question.Answer.TextValue}}
                        </div>
                        
                      </td>                  
                    </tr>
                  </ng-container>

                  <ng-container *ngSwitchCase="'Integer'">
                    <tr>
                      <td class="QuestionText">
                        {{question.Text}}
                      </td> 
                                                    
                      <td  class="AnswerText">
                        <div  *ngIf="question.Answer && question.Answer.IntValue>0" [class]="this.getValueClass(question.ControlType, question.Answer.IntValue)">
                        {{question.Answer.IntValue}}
                        </div>
                      </td>
                    </tr>
                  </ng-container>

                  <ng-container *ngSwitchCase="'DateTime'">
                      <tr>
                        <td class="QuestionText">
                          {{question.Text}}
                        </td> 
                        
                        <td class="AnswerText">
                          <span  *ngIf="question.Answer" >
                          {{question.Answer.DateValue | date: "YYYY-MM-dd"}}
                          </span>
                        </td>
                        
                      </tr>
                  </ng-container>

                </ng-container>
              </ng-container>
             
              
             
            
          </table>

          <div *ngFor="let question of section.Questions" class="Question">
             <!-- {{Question.Answer.TextValue}} -->
          </div>
        </div>
        <mat-divider></mat-divider >
        <table style="color:gray; font-size: small;"  >
          <tr>
            <td>Created by:</td>
            <td style="color: white">{{this.FormSubmitted.SubmittedByUser.FullName}} {{this.FormSubmitted.Submitted | date: 'YYYY-MM-dd HH:mm'}}</td>
          </tr>
          <tr *ngIf="this.FormSubmitted.UpdatedByUser">
            <td>Updated by:</td>
            <td style="color: white;">{{this.FormSubmitted.UpdatedByUser.FullName}} {{this.FormSubmitted.Updated | date: 'YYYY-MM-dd HH:mm'}}</td>
          </tr>
        </table>
        </ng-container>

        <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button *ngIf="this.FormSubmitted.IsEditable" [routerLink]="['/formsubmission/edit', this.FormSubmitted.SubmissionId]">EDIT</button>
      <button mat-button *ngIf="this.FormSubmitted.IsEditable" (click)="deleteSubmission(this.FormSubmitted.SubmissionId)">DELETE</button>
    </mat-card-actions>
  </mat-card>
  
