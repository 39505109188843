<div class="app-page" fxFill>

    <div style="margin-left: 20px; margin-top: 20px;">
     <form [formGroup]="editUser" *ngIf="this.editUser">
        
            <input  type="hidden"  formControlName="Email">
        
        
            <input matInput type="hidden" placeholder="Username" formControlName="UserName">

            <label style="font-family: Roboto; font-size: small; color: rgba(255, 255, 255, 0.7)" >User name</label><br/>
            <label style="font-family: Roboto;" >{{this.user.UserName}}</label>
          <br/>
        <mat-form-field style="width: 350px;">
          <input matInput placeholder="First name" formControlName="FirstName" [disabled]="this.user.ConnectedPlayer">
        </mat-form-field>
        <br/>
        <mat-form-field style="width: 350px;">
            <input matInput placeholder="Last name" formControlName="LastName"  >                        
        </mat-form-field><br/>
        
        <!--   <mat-checkbox formControlName="AllTeams">All teams</mat-checkbox>      
        <br/>
       
          <mat-checkbox formControlName="AllSeasons">All seasons</mat-checkbox> -->

          
        
        <br>
          <mat-checkbox id="WebCalendarEnabled" 
          formControlName="WebCalendarEnabled">Web calendar</mat-checkbox>
      <div *ngIf="this.user.ConnectedPlayer">
          <h2 style="font-family: Roboto;">
            Connected player
             </h2>
             <div style="font-family: Roboto;">
                User is connected to this player in database
            </div>
            <div>
                <table>
                    <tr>
                        <td>
                           
                        </td>
                        <td>
                            <a [routerLink]="['/player/details', this.user.ConnectedPlayer.PlayerId]" style="color: White; font-size: large;">
                                {{this.user.ConnectedPlayer.LastName}}, {{this.user.ConnectedPlayer.FirstName}}
                            </a>
                        </td>
                    </tr>
                </table>
            </div>
        </div>


      <div style="display: flex; flex-direction: row; flex-wrap: wrap; "    *ngIf="!this.user.ConnectedPlayer"   >
            <div style="max-width: 390px;" *ngIf="false">
                <h2 style="font-family: Roboto;">
                    Scouting - Roles 
                </h2>
                <div style="margin-left: 20px;">
                    <div style="font-family: Roboto;">
                        Changes in roles require the user to login again before they are active.
                    </div>
                    <br/>
                    <mat-checkbox id="RoleOrgadmin" 
                    formControlName="RoleOrgadmin">Administrator</mat-checkbox>
                    <br/>
                    <br/>
                    <mat-checkbox id="RoleScout" 
                    formControlName="RoleScout">Scout - required to log in as scout</mat-checkbox>
                    <br/>
                    <br/>
                    <mat-checkbox id="RoleAllReports"         
                    formControlName="RoleAllReports">Reports and notes - ALL</mat-checkbox>
                    <br/>
                    <mat-checkbox id="RoleAllGameRatings" 
                    formControlName="RoleAllGameRatings">Game grades - ALL</mat-checkbox>
                    <br/>
                    <mat-checkbox id="RoleAllCalendarEvents" 
                    formControlName="RoleAllCalendarEvents">Calendar - ALL</mat-checkbox>
                    <br/>
                    <mat-checkbox id="RoleAllPlayerTags" 
                    formControlName="RoleAllPlayerTags">Player tags - ALL</mat-checkbox>
                    <br/>
                    <mat-checkbox id="RoleStatisticsViewer" 
                    formControlName="RoleStatisticsViewer">Statistics - ALL</mat-checkbox>
                    <br/>                              
                    <mat-checkbox id="RoleStatisticsEditor" 
                    formControlName="RoleStatisticsEditor">Statistics Editor</mat-checkbox>
                    <br/>
                
                    <mat-checkbox id="RoleOrgPlayeradmin" 
                    formControlName="RoleOrgPlayeradmin">Add player to roster</mat-checkbox>
                    <br/>
                </div>

            </div>  
            <div style="max-width: 390px;" *ngIf="false">
                <h2 style="font-family: Roboto;">
                Scouting - scout access rights
                 </h2>
                 <div style="font-family: Roboto;">
                   
                </div>
                <div formArrayName="UserSecurity"> 
                    <div  STYLE="display: flex; flex-direction: row;">
                        <div style="width: 200px; font-family: Roboto;" >
                            Scout
                            </div>
                        <div style="width: 120px; font-family: Roboto;" >
                        Reports
                        </div>
                        <div  style="width: 120px;font-family: Roboto;">
                        Notes
                        </div>
                        <div style="width: 120px;font-family: Roboto;">
                        Grades
                        </div>
                        <div style="width: 120px;font-family: Roboto;">
                        Statistics
                        </div>
                  
                        
                    </div>
                    <div *ngFor="let access of UserSecurity.controls; index as i" >
                        <div [formGroupName]="i" STYLE="display: flex; flex-direction: row; border-bottom: gray solid 1px;">
                            <div style="width: 200px; font-family: Roboto;" >
                                {{this.editUser.controls['UserSecurity'].controls[i].controls.Value.value}}<br/>
                                
                            </div>
                            <div  style="width: 120px;">
                                <mat-checkbox formControlName="ReportAccessIschecked"
                                                    ></mat-checkbox>
                            </div>
                            <div style="width: 120px;">
                                <mat-checkbox formControlName="NoteAccessIschecked"
                                ></mat-checkbox>
                            </div>
                            <div style="width: 120px;">
                                <mat-checkbox formControlName="GameRatingAccessIschecked"
                                ></mat-checkbox>
                            </div>
                            <div style="width: 120px;">
                                <mat-checkbox formControlName="StatisticsAccessIschecked"
                            ></mat-checkbox>
                            </div>             
                             
                                                                                                                   
                        </div>
                    </div>
                </div> 
            </div>  

            <div style="max-width: 390px;">
                <h2 style="font-family: Roboto;">
                    Roles 
                </h2>
               
              
                <div style="margin-left: 20px;">
                    <label class="roledescription">A role gives the user rights to perform actions on specific data object.</label>
                    <div  class="roledescription">
                        Changes in roles require the user to login again before they are active.
                    </div>
                    <br/>  
                    <mat-checkbox id="Role_TeamManager" 
                    formControlName="Role_TeamManager">Team manager - required for Testcenter use</mat-checkbox>
                    <br/>

                    <br/>  
                    <mat-checkbox id="Role_ScoutingAdmin" 
                    formControlName="Role_ScoutingAdmin">Scouting administrator</mat-checkbox>
                    <br/>
                    <mat-checkbox id="Role_ScoutingScout" 
                    formControlName="Role_ScoutingScout">Scout</mat-checkbox>
                    <br/>


                    <br/>  
                    <mat-checkbox id="Role_MedicalAdmin" 
                    formControlName="Role_MedicalAdmin">Medical administrator</mat-checkbox>
                    <br/>
                    <mat-checkbox id="Role_MedicalViewer" 
                    formControlName="Role_MedicalViewer">Medical viewer</mat-checkbox>
                    <br/>
                    <div  class="roledescription">
                        Allow user to access injury information
                    </div>  
                  
                    <br/>
                    <mat-checkbox id="Role_TestAdmin" 
                    formControlName="Role_TestAdmin">Test administrator</mat-checkbox>
                    <br/>
                    <mat-checkbox id="Role_TestPerformer" 
                    formControlName="Role_TestPerformer">Test performer</mat-checkbox>
                    <br/>
                    <mat-checkbox id="Role_TestViewer" 
                    formControlName="Role_TestViewer">Test viewer</mat-checkbox>
                    <br/>
                    <br/>
                    <mat-checkbox id="Role_StatisticsAdmin" 
                    formControlName="Role_StatisticsAdmin">Statistics administrator</mat-checkbox>
                    <br/>
                    <mat-checkbox id="Role_StatisticsEditor" 
                    formControlName="Role_StatisticsEditor">Statistics editor</mat-checkbox>
                    <br/>
                    <mat-checkbox id="Role_StatisticsViewer" 
                    formControlName="Role_StatisticsViewer">Statistics viewer</mat-checkbox>
                    <br/>
                    <br/>
                    <mat-checkbox id="Role_PerformanceViewer"         
                    formControlName="Role_PerformanceViewer">Performance viewer</mat-checkbox>
                    <br/>             
                    <div  class="roledescription">
                        Allow user to view player grades
                    </div>       
                    <!-- <br/>
                    <mat-checkbox id="Role_Load_All"         
                    formControlName="Role_Load_All">Player load, physical status, assessments - ALL</mat-checkbox> -->
                    <br/>
                    
                    <mat-checkbox id="Role_Load_AllPhysicalStatus"         
                    formControlName="Role_Load_AllPhysicalStatus">Physical status viewer</mat-checkbox>
                    <br/>
                    <div  class="roledescription">
                        Allow user to view player status and load
                    </div> 
                    <br/>
                    <mat-checkbox id="Role_ObjectiveEditor"         
                    formControlName="Role_ObjectiveEditor">Objective Editor</mat-checkbox>
                    <br/>
                    <br/>
                    <mat-checkbox id="Role_ContactsEditor" 
                    formControlName="Role_ContactsEditor">Player contacts editor</mat-checkbox>
                    <br/>
                    <mat-checkbox id="Role_ContactsViewer" 
                    formControlName="Role_ContactsViewer">Player contacts viewer</mat-checkbox>
                    <br/>
                    <div  class="roledescription">
                        Allow user to view or edit player contact information
                    </div>
                    <br/>
                    <mat-checkbox id="Role_TrainingMgr"         
                    formControlName="Role_TrainingMgr">Training Manager</mat-checkbox>
                    <br/>
                    <label class="roledescription">Enables user to create training programs, workouts, exercises</label>
                    <br/>
                    <mat-checkbox id="Role_FormsAdmin"         
                    formControlName="Role_FormsAdmin">Forms Admin</mat-checkbox>
                    <br/>
                    <label class="roledescription">Enables user to create new forms and delete any submitted form</label>
                    <br/>
                    <mat-checkbox id="Role_FormsViewer"         
                    formControlName="Role_FormsViewer">Forms Viewer</mat-checkbox>
                    <br/>
                    <label class="roledescription">Enables user to see submitted forms</label>
                    <br/>
                    <mat-checkbox id="Role_FormsSubmitter"         
                    formControlName="Role_FormsSubmitter">Forms Submitter</mat-checkbox>
                    <br/>
                    <label class="roledescription">Enables user to submit forms</label>
                    <br/>
                    <br/>
                    <mat-checkbox id="Role_UserAdmin"         
                    formControlName="Role_UserAdmin">User Admin</mat-checkbox>
                    <br/>
                    <label class="roledescription">Enables user to create edit user information</label>
               
                </div>
                
            </div> 
            <div >
                <h2 style="font-family: Roboto;">
                           Team access
                       </h2>
                       <div  class="roledescription">
                        Assing what data the user can see in each team. <br/>

                        </div>
                       <div formArrayName="LoadAccessRights">
                           <div  STYLE="display: flex; flex-direction: row;">
                               <div style="width: 200px; font-family: Roboto;" >
                               
                               </div>
                               <div  style="width: 100px;font-family: Roboto;">
                               Grades
                               </div>
                               <div style="width: 100px;font-family: Roboto;">
                               Status
                               </div>
                               <div style="width: 100px;font-family: Roboto;">
                               Load
                               </div>
                               <div style="width: 100px;font-family: Roboto;">
                               Test
                               </div>
                               <div style="width: 100px;font-family: Roboto;">
                                Injury
                                </div>
                                <div style="width: 100px;font-family: Roboto;">
                                Objective
                                </div>
                                <div style="width: 100px;font-family: Roboto;">
                                Contacts
                                </div>  
                            <!--     <div style="width: 100px;font-family: Roboto;">
                                Training
                                </div> -->
                                <div style="width: 100px;font-family: Roboto;">
                                Forms
                                </div>

                           </div>
                           <div *ngFor="let team of LoadAccessRights.controls; index as i" >
                               <div [formGroupName]="i" STYLE="display: flex; flex-direction: row; border-bottom: gray solid 1px;">
                                   <div style="width: 200px; font-family: Roboto; font-weight: bold;" >
                                       {{this.editUser.controls['LoadAccessRights'].controls[i].controls.Value.value}}<br/>
                                      
                                   </div>
                                   <div  style="width: 100px;">
                                       <mat-checkbox formControlName="Access_PlayerGameRankings"
                                                           ></mat-checkbox>
                                   </div>
                                   <div style="width: 100px;">
                                       <mat-checkbox formControlName="Access_PhysicalStatus"
                                       ></mat-checkbox>
                                   </div>
                                   <div style="width: 100px;">
                                       <mat-checkbox formControlName="Access_PlayerLoad"
                                       ></mat-checkbox>
                                   </div>
                                   <div style="width: 100px;">
                                       <mat-checkbox formControlName="Access_Test"
                                       ></mat-checkbox>
                                   </div>
                                   <div style="width: 100px;">
                                    <mat-checkbox formControlName="Access_Injury"
                                    ></mat-checkbox>
                                    </div>
                                    <div style="width: 100px;">
                                        <mat-checkbox formControlName="Access_Objective"
                                        ></mat-checkbox>
                                    </div>
                                    <div style="width: 100px;">
                                        <mat-checkbox formControlName="Access_Contacts"
                                        ></mat-checkbox>
                                    </div>
                                   <!--  <div style="width: 100px;">
                                        <mat-checkbox formControlName="Access_Training"
                                        ></mat-checkbox>
                                    </div> -->
                                     <div style="width: 100px;">
                                        <mat-checkbox formControlName="Access_Forms"
                                        ></mat-checkbox>
                                    </div>
                                  
                                  
                                   
                               </div>
                           </div>
                       </div>
                   </div>

    </div>

      
           
       


  

       
      


      
        <br/>
        <button  mat-raised-button color="primary" (click)="saveUser();">SAVE</button>
        &nbsp;
        <button  mat-button  (click)="cancel();">CANCEL</button>

      </form>

    </div>
   
</div>