
<div id="{{'chart_plannedload_' + TeamId}}">

</div>
<div  style="display: flex; color: white; font-family: Roboto; font-size: small;margin-left:100px;">
    <div style="width:10px; height  :10px; background-color:dodgerblue; border: 1px solid white ;">
    
      </div>
      <div style="margin-left: 5px; margin-right: 5px;">
          &nbsp;{{ 'Training' | translate }}
      </div>
      <div style="width:10px; height  :10px; background-color:#c632df; border: 1px solid white ;">
    
    </div>
    <div style="margin-left: 5px; margin-right: 5px;">
        &nbsp;{{ 'Event' | translate }}
    </div>
    
      <div style="width:10px; height:10px; background-color:#F1CA3A;border: 1px solid white ;">
      </div>
      <div style="margin-left: 5px; margin-right: 5px;">
          &nbsp;{{ 'Game' | translate }}
      </div>
      <div style="width:10px; height:10px; background-color: white; border: 1px solid white;">
    </div>
        <div style="margin-left: 5px; margin-right: 5px;">
            &nbsp;{{ 'Load 4w sliding avg' | translate }}
        </div>
  
</div>
