<div class="app-page" style="max-width: 580px; ">
  <h2>
    <mat-icon>assignment_turned_in</mat-icon>
    Tests
  </h2>
  <button mat-button  (click)="addClick();" color="primary"><mat-icon>add</mat-icon>NEW</button>

<table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8" >
    <ng-container matColumnDef="Image">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let test"> 
            <i class="material-icons">
                assignment_turned_in
                </i>
          
              </td>
    </ng-container>

    <ng-container matColumnDef="Name">

        <th mat-header-cell *matHeaderCellDef style="min-width: 200px;"> Name </th>
        <td mat-cell *matCellDef="let test"> 
          
        {{test.Name}}
       </td>
    </ng-container>

    <ng-container matColumnDef="Description">

      <th mat-header-cell *matHeaderCellDef style="min-width: 300px;"> Description </th>
      <td mat-cell *matCellDef="let test"> 
        
      {{test.Description}}
     </td>
  </ng-container>
  

    <ng-container matColumnDef="ResultUnit">
      <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Unit </th>
      <td mat-cell *matCellDef="let test"> {{test.ResultUnit}} </td>
    </ng-container>

    <ng-container matColumnDef="ResultType">
      <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Unit </th>
      <td mat-cell *matCellDef="let test" style="white-space: none; text-wrap: nowrap;">
        {{this.getTypeText(test.ResultType)}} </td>
    </ng-container>

  <!--   <ng-container matColumnDef="ResultType">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let test"> {{ this.getTypeText(test.ResultType) }} </td>
    </ng-container> -->

    <ng-container matColumnDef="Ranking">
      <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Ranking </th>
      <td mat-cell *matCellDef="let test"><mat-icon>{{ this.getRankingArrow(test.Operator)}} </mat-icon></td>
    </ng-container>
  
    <ng-container matColumnDef="Actions" >
        <th mat-header-cell *matHeaderCellDef style="min-width: 100px;"> Actions </th>
        <td mat-cell *matCellDef="let test">
           <!--  <button type="button" mat-icon-button  *ngIf="(!test.Global)"  >
                <mat-icon>delete</mat-icon>
              </button> -->
              <button type="button" mat-icon-button *ngIf="(!test.Global)" (click)="openDialog(test.TestId);">
                  <mat-icon>create</mat-icon>
              </button>
              
        
      </td>      
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
  </table>
  <mat-paginator [pageSizeOptions]="[15, 20, 25, 30]"
                 showFirstLastButtons 
                 style="width: 900px;">
  </mat-paginator>
</div>
<!-- <div class="fab-container">
    <button mat-mini-fab color="primary"  (click)="addClick();" ><mat-icon>add</mat-icon></button>
  
  </div> -->