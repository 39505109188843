import {Team, TeamDetails} from './team';
import { AveragePhysicalStatus, PhysicalStatus } from './physicalstatus';
import { GamePlayerRankingSummaryModel, GameRatingLog, GamePlayerRankingViewModel } from './gamerating';

export class GameEvent {
    GameId: string;
    GameDate: Date;
    EventDate: Date;
    startTime: string;
    endTime: string;    
    IsHomeGame: boolean;
    HomeTeamId: string;
    AwayTeamId: string;
    HomeTeamName: string;
    HomeTeamIcon: string;
    HomeTeam: TeamDetails;
    AwayTeamName: string;
    AwayTeamIcon: string;
    AwayTeam: TeamDetails;
    DivisionName: string;
    Venue: string;
    //Settings from OrgSettings
    Settings_Load_MaxDuration: number;
    Settings_Load_DurationSteps: number;
    //Logged player data from here
    Nutrition: number;
    Stress: number;
    Sleep: number;
    Ranking: number;
    Duration: number; 
  }

  export class GameEventLog{
    GameId: string;
    PlayerId: string;
    LastName: string;
    FirstName: string;
    Position: string;
    JerseyNo: string;
    ProfileImage: string;
    Nutrition: number;
    Sleep: number;
    Stress: number;
    Ranking: number;
    PlayedMinutes: number;
    Duration: number;
    Comment: string;
    EventDate: string;    
    GameRankings: GameRatingLog[];
    CoachRankings: GameRatingLog[];
    PlayerRanking: GameRatingLog;
    Venue: string;
    DivisionName: string;
    AssessmentBeforeGame: string;
    GameStatistics: GameStatisticsViewModel;

  }

  export class GameEventOverview {
    GameId: string;
    EventDate: Date;
    startTime: string;
    endTime: string;    
    MyTeamId: string;
    HomeTeamId: string;
    AwayTeamId: string;
    HomeTeam: Team;
    AwayTeam: Team;
    DivisionName: string;
    Venue: string;
    NutritionAvg: number;
    StressAvg: number;
    SleepAvg: number;
    RankingNoOfBad: number;
    RankingNoOfNeutral: number;
    RankingNoOfGood: number;
    RankingNoOfVeryGood: number;
    RankingNoOfExcellent: number;
    GameLogs: GameEventLog[];
    PlayerAssessmentAverages: AveragePhysicalStatus;
    AssessmentBeforeGame: PhysicalStatus;
    PlayerRankingSummaryByScout: GamePlayerRankingSummaryModel[];
    
  }

  import { GameAssessmentPlayer } from './gamerating';
import { GamePlayerRankingModel } from 'src/app/scouting/models/GameModels';
import { LoadTeamViewModel } from './loadteam';
import { GameAssessmentGroup } from './EventModels';
import { GameStatisticsViewModel } from 'src/app/shared/models/gamestatistics.model';
import { TeamEventAttendeeViewModel, TeamEventLogSummary, TeamEventPlayerLog } from 'src/app/TeamSchedule/models/schedulemodels';


  export class GameEventOverviewViewModel
  {
      GameId:string;
      EventDate: Date;
      StartTime: Date;
      MyTeamId:string;
      HomeTeamId:string;
      HomeTeam:LoadTeamViewModel;
      AwayTeamId:string;
      AwayTeam:LoadTeamViewModel;
      DivisionName:string;
      Venue:string;
      NutritionAvg:number;
      SleepAvg:number;
      StressAvg:number;
      RankingNoOfBad:number;
      RankingNoOfNeutral:number;
      RankingNoOfGood:number;
  
      RankingNoOfVeryGood:number;
      RankingNoOfExcellent:number;
      OverallAvg:number;
      OffensiveGameAvg:number;
      DefensiveGameAvg:number;
      GameContributionAvg:number;
      GameFocusAvg:number;
      GameLogs:PlayerGameLogViewModel[];
      PlayerAssessmentAverages:AveragePhysicalStatusModel;
      //Game grades/rankings
      GameRankings:LoadGamePlayerRankingModel[];
  
      GameAssessment:GameAssessmentPlayer;
  
      PlayerRankingSummaryByScout:GamePlayerRankingViewModel[];
  
      GameStatistics: GameStatisticsViewModel;
  }
  
  export class LoadGamePlayerRankingModel
  {
      GameId: string;
      PlayerId: string;
      UserId: string;
      TeamId: string;
      Ranking: number;
      OffensiveGameRanking: number;
      DefensiveGameRanking: number;
      GameContributionRanking: number;
      GameFocusRanking: number;
      Organizationid: number;
      Updated: Date;
      NoParticipationReason: number;
      Duration: number;
      Comment: string;
  
      ScoutName: string;
      CommentToPlayer: string;
      CommentPrivat: string;
  
  }
  
  export class AveragePhysicalStatusModel
      {
                
          NoOfPlayers: number;
          Freshness: number;
          Muscles: number;
          Joints: number;
          Sleep: number;
          Confidence: number;

          minFreshness: number;
          minMuscles: number;
          minJoints: number;
          minSleep: number;
          minConfidence: number;

          maxFreshness: number;
          maxMuscles: number;
          maxJoints: number;
          maxSleep: number;
          maxConfidence: number;
  
  
      }
  
  export class PlayerGameLogViewModel
      {
          GameId: string
          PlayerId: string
          JerseyNo: number;
          LastName: string
          FirstName: string       
          Duration: number;
          LoadLevel: number;
          Ranking: number;
          Nutrition: number;
          Sleep: number;
          Stress: number;
          Comment: string
          
             
          OffensiveGameRanking: number;
          DefensiveGameRanking: number;
          GameContributionRanking: number;
          GameFocusRanking: number;
          PhysicalAssessment: PhysicalStatus;
          CoachRankings: GamePlayerRankingModel[];
          
          
      }

      export class GameEventViewModel
    {
        GameId: string;
        EventDate: Date;
        StartTime: Date;
        IsHomeGame: boolean;
        HomeTeamId: string;
        //public LoadTeamViewModel HomeTeam: string;
        HomeTeamName: string;
        HomeTeamIcon: string;
        HomeTeam: TeamDetails;
        AwayTeamName: string;
        AwayTeamIcon: string;
        AwayTeam: TeamDetails;

        AwayTeamId: string;
        //public LoadTeamViewModel AwayTeam: string;

        DivisionName: string;
        Venue: string;
        MyTeamId: string; 

        //Logged plyer info below
        Nutrition: number;
        Sleep: number;
        Stress: number;
        Comment: string;
        NoParticipationReason: number;
        Ranking: number;
       
        OffensiveGameRanking: number;
        DefensiveGameRanking: number;
        GameContributionRanking: number;
        GameFocusRanking: number;
        AssessmentBeforeGame: PhysicalStatus;

        GameRankings: any;

       


        //}
        GameAssessmentGroups: GameAssessmentGroup[];

        Duration: number;
        LoadValue: number;
        
        Settings_Load_MaxDuration: number;
        Settings_Load_DurationSteps: number;
        

        //Game grades
        //public IList<LoadGamePlayerRankingModel> GameRankings: string;
        //Phsycl status
        //public PhysicalStatusModel AssessmentBeforeGame: string;
        //public IList<GamePlayerRankingViewModel> PlayerRankingSummaryByScout: string;

        //GameAssessment
        //public IList<GameAssessmentGroup> GameAssessmentGroups: string;
    }

  
    export class TeamEventViewModel
    {
        EventId: string;
        TeamId: string;        
        EventDate: Date;
        StartTime: string;
        FullTeamEvent: boolean;
        Duration: number;
        LoadLevel: number;
        LoadValue: number;
        LoadAvg_4weeks: number;
        Title: string;
        Description: string;
        Note: string;
        Location: string;
        Feeling_NoOfVeryBad: number;
        Feeling_NoOfBad: number;
        Feeling_NoOfOK: number;
        Feeling_NoOfGood: number;
        Feeling_NoOfVeryGood: number;
        //public LoadTeamViewModel Team  { get; set;}
        //public TeamEventLogSummary LogSummary: string;
        //public TeamEventPlayerLogModel PlayerLog: string;
        Attendees: TeamEventAttendeeViewModel[];
        LogSummary: TeamEventLogSummary;
        PlayerLogs: TeamEventPlayerLog[];

        CreatedBy: string;
        CreatedByName: string;
        UpdatedBy: string;
        UpdatedByName: string;

        Team: LoadTeamViewModel;

    }