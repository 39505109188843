import { Component, OnInit } from '@angular/core';
import { TestResultService } from 'src/app/shared/test-result.service';
import { TestTopList } from 'src/app/shared/models/test.model';

@Component({
  selector: 'app-test-top-lists',
  templateUrl: './test-top-lists.component.html',
  styleUrls: ['./test-top-lists.component.css']
})
export class TestTopListsComponent implements OnInit {

  private _toplists: TestTopList[];

  constructor(private _testService: TestResultService) { 
  
  }

  ngOnInit() {

     this._testService.getTestResults_BestEverGender(10, 'W').subscribe((x: TestTopList[])=>{
      console.log(x);
      this._toplists = x;
    });
  }

}
