<div class="app-page">
    <mat-card appearance="outlined" style="  margin: 10px;" *ngIf="this._Player">
        <mat-card-header>
            <img mat-card-avatar src="../../assets/img/icons8-user-shield-48.png">
            <mat-card-title>
              <div style="display: flex; justify-content: space-between; width: 10">
                  <div style="display: inline-block;">{{ this._Player.LastName }}, {{ this._Player.FirstName }}<br/>
                    
                  </div>
                 
              </div>
              </mat-card-title>
        </mat-card-header>
      </mat-card>

  <mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="margin-left: 10px; ">
      <ng-container matColumnDef="PerformedDate">

        <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
        <mat-cell *matCellDef="let test"> 
          
        {{test.PerformedDate | date:'yyyy-MM-dd  hh:mm:ss'}}
       </mat-cell>       
    </ng-container>
    
    <ng-container matColumnDef="TestName">

        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let test"> 
          
        {{test.TestName}}
       </mat-cell>
    </ng-container>



    <ng-container matColumnDef="Result">

      <mat-header-cell *matHeaderCellDef> Result </mat-header-cell>
      <mat-cell *matCellDef="let test"> 
        
      {{test.Result}}
     </mat-cell>
     <td mat-footer-cell *matFooterCellDef> Test </td>
  </ng-container>    
  <ng-container matColumnDef="ResultUnit">

    <mat-header-cell *matHeaderCellDef> Unit </mat-header-cell>
    <mat-cell *matCellDef="let test"> 
      
    {{test.ResultUnit}}
   </mat-cell>
</ng-container>  
<ng-container matColumnDef="Actions">

  <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
  <mat-cell *matCellDef="let test"> 
    
    <button mat-icon-button type="button" (click)="deleteValue(test.ResultId)" *ngIf="this.userService.roleMatch(['Test_Admin','Test_Performer'])"><mat-icon>delete</mat-icon></button>
 </mat-cell>
</ng-container>  


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
   
  </mat-table>
  <mat-paginator [length]="100"
  [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>