<div class="app-page">

    <ng-container *ngIf="formGroup && form && !loading" [formGroup]="formGroup">
        <h3>
            <mat-icon>
                dynamic_form
            </mat-icon>
            {{form.Name}}</h3>
       
        <input type="hidden" id="Name" formControlName="Name"/>
        <input type="hidden" id="FormId" formControlName="FormId"/>
        <input type="hidden" id="Version" formControlName="Version"/>

        <ng-container formArrayName="Sections" >    
            <ng-container *ngFor="let section of Sections.controls; let i = index;">
                <app-form-edit-section   [form]="section" [formControlName]="i"  ></app-form-edit-section> 
            </ng-container>
            
        </ng-container>
    </ng-container>
    
    <button mat-stroked-button  (click)="addSection()">ADD SECTION</button>

</div>
<button *ngIf="this.formGroup" mat-raised-button color="primary" (click)="saveAsNewVersion();" [disabled]="!this.formGroup.valid">SAVE NEW VERSION</button>
<div>
    {{this.errorMessage}}
</div>
<div *ngIf="this.formGroup">
    {{this.formGroup.value | json}}
</div>