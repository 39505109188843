import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UntypedFormArray } from '@angular/forms';
import { GameService } from '../../services/game.service';
import { DivisionGameFilterFlatModel } from '../../models/GameModels';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-division-select-dialog',
  templateUrl: './division-select-dialog.component.html',
  styleUrls: ['./division-select-dialog.component.css']
})
export class DivisionSelectDialogComponent implements OnInit {

  _form: UntypedFormGroup;
  divisions: DivisionGameFilterFlatModel[];


  constructor(public dialogRef: MatDialogRef<DivisionSelectDialogComponent>,public gameService: GameService, public formBuilder: UntypedFormBuilder) {
    this._form = this.formBuilder.group({
      divisions: new UntypedFormArray([])
    });
    
    
  }

  ngOnInit() {
   this.getData();
  }

  getData(){

    this.gameService.getDivisionFilter().subscribe(data=>{
      this.divisions = data;
      this.addCheckboxes();
    });
  }


  private addCheckboxes() {
    this.divisions.forEach((o, i) => {
      const control = new UntypedFormControl(this.divisions[i].DivisionFiltered); // if first item set to true, else false
      (this._form.controls.divisions as UntypedFormArray).push(control);
    });
  }


  save(){
    
  }

  submit(){
    const selectedDivisions = this._form.value.divisions
      .map((v, i) => v ? this.divisions[i].DivisionId : null)
      .filter(v => v !== null);
      this.gameService.setDivisionFilter(selectedDivisions).subscribe(res=>{
        this.dialogRef.close('success');
      });




  }

}
