<!-- <div style="display: flex; justify-content: space-between; margin: 5px;font-size:x-small;">
    <div>
        <div class="OverallGradeLabel">Game</div>
        <div [class]="this.classesRankingBox[this.GamePerformance]"></div>{{this.GamePerformanceText}}
    </div>
    <div>
            <div class="OverallGradeLabel">Capacity</div>
            <div [class]="this.classesRankingBoxCapacity[floor(this.Capacity)]"></div>{{this.CapacityText}}
    </div>
    <div>
            <div class="OverallGradeLabel">Projection</div>
            <div [class]="this.classesRankingBoxProjection[this.TotalQuality]"></div>{{this.TotalQualityText}}
    </div>
</div> -->
<div style="display: flex; justify-content: start;  margin: 5px;font-size:small;">

    <ng-container *ngIf="this.Capacity!==0">
        <div [class]="this.classesRankingBoxCapacity[floor(this.Capacity)]">
            {{this.CapacityText}}
        </div>
    </ng-container>

    <div *ngIf="this.PlayerType!==0 && this.PlayerType!=null" class="grayshading">
        {{this.PlayerTypeText}}</div>

    <div [class]="this.classesRankingBoxProjection[this.TotalQuality]">{{this.TotalQualityText}}</div>

</div>
