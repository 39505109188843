import { Component, OnInit, Input } from '@angular/core';
import { FlowEventModel } from '../models/EventModels';
import { GameEventOverviewViewModel } from '../models/gameevent';

@Component({
  selector: 'app-load-game-assessment',
  templateUrl: './load-game-assessment.component.html',
  styleUrls: ['./load-game-assessment.component.css']
})
export class LoadGameAssessmentComponent implements OnInit {

  public intensityColors: string[] = ['', '#4CAF50','#FFEB30','#FF5722'];
  public intensityTextColors: string[] = ['', 'white','black','white'];
  public gamePerformanceIcon: string[] = ['block','sentiment_very_dissatisfied','sentiment_neutral','sentiment_satisfied','sentiment_very_satisfied','star_border']
  public gamePerformanceColors: string[] = ['','#FF5722','#FFC107','#4CAF50','#2E7D32','#00BCD4'];
  public gamePerformanceTextColors: string[] = ['white', 'white','black','white','white','white'];
  public scaleColors: string[] = ['gray','#FF5722','#FF5722','#FF9800','#FFC107','#FFFEB3','#CDDC39', '#8BC34A', '#4CAF50','#4CAF50','#4CAF50']
  public scaleTextColors: string[] = ['black','black','black','black','black','black','black', 'black', 'white','white','white']
  public painScaleColors: string[] = ['gray','#FFFEB3','#FFFEB3', '#FFC107', '#FFC107', '#FF9800','#FF9800','#FF5722','#FF5722','#FF5722','#FF5722']
  public painScaleTextColors: string[] = ['white','black','black','black','black','white','white','white','white','white','white']
  public availabilityColors: string[] = ['gray', '#4CAF50','#FF5722','#FF5722'];
  public availabilityText: string[] = ['-','OK','SJUK','SKADAD','BORTA' ];

  constructor() { }

  
    @Input() event:  FlowEventModel;

  ngOnInit() {
  }

}
