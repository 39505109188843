<div style="height: 100%;display: flex; flex-direction: row;" class="app-page">

    <div style="display: block; width: 300px; padding: 10px; border: 1px solid gray; height: 1000px;">
<!--         <label style="font-family: Rubik;">FILTER</label> -->
        <form [formGroup]="searchForm" style="display: flex; flex-direction: column;">

            <mat-form-field appearance="fill">
                <mat-label>Last name</mat-label>
                <input matInput formControlName="PlayerLastName" placeholder="Last name">
                <mat-hint>Use * as wildcard</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>First name</mat-label>
                <input matInput formControlName="PlayerFirstName" placeholder="First name">
                <mat-hint>Use * as wildcard</mat-hint>
            </mat-form-field>&nbsp;
            <mat-form-field appearance="fill" style="font-size: small;">
                <mat-label>Positions</mat-label>
                <mat-select formControlName="PositionIds" id="PositionIds" multiple>
                    <mat-option value="1">Goaltender</mat-option>
                    <mat-option value="2">Defender</mat-option>
                    <mat-option value="3">LW</mat-option>
                    <mat-option value="4">Forward</mat-option>
                    <mat-option value="5">Center</mat-option>
                    <mat-option value="6">RW</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Year of birth</mat-label>
                <mat-select formControlName="YearsOfBirth" id="YearsOfBirth" multiple>
                    <mat-option *ngFor="let year of availableBirthYears" [value]="year">{{year}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Shoots/glove</mat-label>
                <mat-select formControlName="ShootIds" id="ShootIds" multiple>
                    <mat-option value="1">Left</mat-option>
                    <mat-option value="2">Right</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="this.reportMasterdata">
                <mat-label>Projection</mat-label>
                <mat-select formControlName="Projections" id="Projections" multiple>
                    <mat-option *ngFor="let proj of this.reportMasterdata.Projection" [value]="proj.Ranking">
                        {{ proj.Text}}<span *ngIf="proj.Hidden" style="color: gray;"> (hidden)</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngIf="this.reportMasterdata">
                <mat-label>Capacity</mat-label>
                <mat-select formControlName="Capacities" id="Capacities" multiple>
                    <mat-option *ngFor="let proj of this.reportMasterdata.Capacity" [value]="proj.Ranking">
                        {{ proj.Text}}
                        <span *ngIf="proj.Hidden" style="color: gray;"> (hidden)</span>
                    </mat-option>
                </mat-select>
                </mat-form-field>
                
                <mat-form-field appearance="fill" *ngIf="this.reportMasterdata?.PlayerTypeEnabled">
                    <mat-label>Player type</mat-label>
                    <mat-select formControlName="PlayerTypes" id="PlayerTypes" multiple>
                        <mat-option *ngFor="let type of this.reportMasterdata.PlayerType" [value]="type.Ranking">
                            {{ type.Text}}
                            <span *ngIf="type.Hidden" style="color: gray;"> (hidden)</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field appearance="fill" *ngIf="this.reportMasterdata">
                    <mat-label>Source</mat-label>
                    <mat-select formControlName="ScoutingSources" id="ScoutingSources" multiple>
                        <mat-option *ngFor="let source of this.reportMasterdata.ScoutingSource" [value]="source.ScoutingSourceId">
                            {{ source.Text}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Report date from</mat-label>
                <input matInput [matDatepicker]="pickerfrom" formControlName="minReportDate" name="minReportDate">
                <mat-datepicker-toggle matSuffix [for]="pickerfrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerfrom></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Report date to</mat-label>
                <input matInput [matDatepicker]="pickerto" formControlName="maxReportDate" name="maxReportDate">
                <mat-datepicker-toggle matSuffix [for]="pickerto"></mat-datepicker-toggle>
                <mat-datepicker #pickerto></mat-datepicker>
            </mat-form-field>


            <mat-form-field appearance="fill">
                <mat-label>Max no of items</mat-label>
                <mat-select formControlName="MaxHits" id="MaxHits">
                    <mat-option selected value="50">50</mat-option>
                    <mat-option value="100">100</mat-option>
                    <mat-option value="200">200</mat-option>
                    <mat-option value="500">500</mat-option>

                </mat-select>
            </mat-form-field>
            <br />
            <a mat-raised-button color="primary" (click)="this.loadFlow()">SEARCH</a>
        </form>
    </div>

<!--   <div *ngIf="this._flowitems!=null && this._flowitems.length==0"
        style="font-family: Roboto; margin: 20px; color: gray;">
        NO ITEMS FOUND FOR THE SELECTED FILTERS
    </div> -->

    <mat-progress-bar mode="indeterminate" *ngIf="this.isBusy()" style="margin-top: 50px;"></mat-progress-bar>


    <div *ngIf="this.isBusy()==false"
        style="display: flex; flex-flow: column; justify-content: center; min-height: 100%; overflow-y: scroll; justify-content: start; ">
        <app-report-search-result-overview [ResultOverview]="this.SearchResultOverview"
            *ngIf="this.isBusy()==false || this._flowitems!==null || this._flowitems.length>0" style="margin-left: 20px;">
        </app-report-search-result-overview>
        <div style="display: flex; flex-flow: row wrap; justify-content: center;  overflow-y: scroll; ">
            <ng-container *ngFor="let item of this._flowitems">
            <app-report-card *ngIf="item.FlowItemType=='SCREPORT'" [Report]="item"></app-report-card>
            <app-note-card *ngIf="item.FlowItemType=='PLAYERNOTE'" [Note]="item"></app-note-card>
        </ng-container>

        </div>

    </div>

</div>