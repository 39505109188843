import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { GameStatisticsViewViewModel, GameStatisticsGroupViewModel, GameStatisticsProfileViewModel } from 'src/app/stats/models/statistics.model';

@Component({
  selector: 'app-config-statistics-profiles',
  templateUrl: './config-statistics-profiles.component.html',
  styleUrls: ['./config-statistics-profiles.component.css']
})
export class ConfigStatisticsProfilesComponent implements OnInit {

  profiles: GameStatisticsProfileViewModel[];

  constructor(public statsService: GameStatisticsService, public dialog: MatDialog) {

   

    this.loadData();

  }

  ngOnInit() {
  }

  
  loadData(){
    this.statsService.getAllProfiles().subscribe(data=>{
      
      this.profiles = data as GameStatisticsProfileViewModel[] ;
    });
    
  }

  confirmDeleteProfile(profile){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Do you really want to delete the profile ' + profile.Name + '?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        console.log(profile);
        this.statsService.deleteProfile(profile.GameStatisticsProfileId).subscribe(res=>{
          console.log(res);
          if(res)
            this.loadData();

        });
        console.log('deleting');
      }      
      
      //this.animal = result;
    });

  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(ConfigStatisticsNewprofileDialog, {
      width: '250px',
      data: {name: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result.length>0)
        this.statsService.insertProfile(result).subscribe(res=>{
          this.loadData();
        });
      
    });
  }

}
export interface DialogData { 
  name: string;
}


@Component({
  selector: 'config-statistics-newprofile-dialog',
  templateUrl: 'config-statistics-newprofile-dialog.html',
})
export class ConfigStatisticsNewprofileDialog {

  constructor(
    public dialogRef: MatDialogRef<ConfigStatisticsNewprofileDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close("");
  }

}
