<h2 mat-dialog-title style="font-family: Roboto;">
    <mat-icon>person</mat-icon>
    Add tag
</h2>
<div mat-dialog-content>
    <div style="height: 200px; overflow-y: scroll;">
        <mat-selection-list #tags>
            <mat-list-option *ngFor="let tag of this.Tags" [value]="tag.TagId">
                <div  [ngClass]="tag.TagColor" style="max-width: 100px;" >
                    {{tag.Text}}
                </div>
            </mat-list-option>
        </mat-selection-list>
        
    </div>
</div> 
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="save()">SAVE</button>
   <button mat-button [mat-dialog-close]="false"  >CANCEL</button>
 
 </div>

