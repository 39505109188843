<div  *ngIf="Test.TopList" >

  <ng-container   style="display: flex;">

    <table style="width: 245px; table-layout: fixed;">
      <tr >
        <td style=" font-size: large;vertical-align: top; overflow: hidden ; white-space: nowrap;">
            <mat-icon style="font-size: large;">assignment_turned_in</mat-icon>
            <b>{{ Test.Name}}</b>
        </td>
      </tr>
      <tr>
          <td style="font-size: small;" >
              <div *ngIf="Test.TopList.Items.length==0" style="color:gray; height: 80px;">
                  NO DATA
              </div>
              <div *ngIf="Test.TopList.Items.length>00" style="display: flex; flex-wrap: nowrap;width: 350px;">
                <div style="height: 80px; " >
                  <img style="height: 80px;" src="/assets/{{Test.TopList.Items[0].ProfileImage}}" *ngIf="Test.TopList.Items[0].ProfileImage">
                  <img style="width: 41px;opacity: 0.5;" src="/assets/img/icons8-user-shield-48.png" *ngIf="!Test.TopList.Items[0].ProfileImage">
                </div>
                <div style="white-space: nowrap; margin-left: 5px; overflow-x: hidden;width: 150px;" >  
                  
                 
                  <span style="font-size:large; font-weight: bold;">{{Test.TopList.Items[0].Result}} {{Test.TopList.Items[0].ResultUnit}}</span><br/>
                  {{Test.TopList.Items[0].PlayerLastName}}, {{Test.TopList.Items[0].PlayerFirstName}}<br/>
                  <span style="color:gray">{{Test.TopList.Items[0].PerformedDate | date: 'yyyy-MM-dd'}}</span>

                </div>            
              </div>
              <div *ngFor="let item of Test.TopList.Items; let i=index;" style="display: flex; flex-wrap: nowrap;width: 250px;">
                <div *ngIf="i<3 && i>0" style="font-size: medium; margin-right: 10px;;">{{item.Position}}</div>
                <div *ngIf="item.Position==1 && i>0">
                  <b>{{item.PlayerLastName}}, {{item.PlayerFirstName}}<br/>
                    {{item.Result}} {{item.ResultUnit}}</b><br/>
                    <span style="color:gray">{{item.PerformedDate | date: 'yyyy-MM-dd'}}</span>
                </div>
                <div *ngIf="item.Position==2">
                  <b>{{item.PlayerLastName}}, {{item.PlayerFirstName}}<br/>
                    {{item.Result}} {{item.ResultUnit}}</b><br/>
                    <span style="color:gray">{{item.PerformedDate | date: 'yyyy-MM-dd'}}</span>
                </div>
                <div  *ngIf="item.Position==3" style="white-space: nowrap; margin-left: 5px; overflow-x: hidden;width: 150px;" >  
                  
                  <b>{{item.PlayerLastName}}, {{item.PlayerFirstName}}<br/>
                  {{item.Result}} {{item.ResultUnit}}</b><br/>
                  <span style="color:gray">{{item.PerformedDate | date: 'yyyy-MM-dd'}}</span>
                </div>            
              </div>
            </td>
            
            
      </tr>
    </table>

 
    

    
  </ng-container>
</div>