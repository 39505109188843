<div class="app-page">
    <form [formGroup]="searchForm">
            
        &nbsp;
          <mat-form-field  appearance="fill"  style="font-size: small;">
            <mat-label>Tests</mat-label>
            <mat-select formControlName="SelectedTests" id="SelectedTests" multiple>
              <mat-option *ngFor="let test of this.allTests" [value]="test.TestId">{{test.Name}}</mat-option>
              
            </mat-select>
          </mat-form-field>&nbsp;
          <mat-form-field  appearance="fill"  style="font-size: small;">
            <mat-label>Players</mat-label>
            <mat-select formControlName="selectedPlayers" id="selectedPlayers" multiple>
              <mat-option *ngFor="let player of this.alldata.Players" [value]="player.LifelinescoutingId">{{player.LastName}}, {{player.FirstName}}</mat-option>
              
            </mat-select>
          </mat-form-field>&nbsp;
          <mat-form-field  appearance="fill"  style="font-size: small;">
            <mat-label>Period</mat-label>
            <mat-select formControlName="selectedPlayers" id="selectedPlayers" multiple>
              <mat-option value="6M">6 months</mat-option>
              <mat-option value="1Y">1 year</mat-option>
              <mat-option value="2Y">2 years</mat-option>
              <mat-option value="3Y">3 years</mat-option>
              <mat-option value="5Y">5 years</mat-option>
              
            </mat-select>
          </mat-form-field>&nbsp;
          <mat-form-field  appearance="fill" style="font-size: small;">
              <mat-label>X Axis</mat-label>
              <mat-select formControlName="xaxis" id="xaxis" [value]="'date'">
                <mat-option value="date" selected>Date</mat-option>
                <mat-option value="age">Age</mat-option>             
              </mat-select>
            </mat-form-field>&nbsp;
            <br/>
            <button mat-raised-button color="primary" (click)="displayData()">DISPLAY</button>
         
    </form>


</div>
