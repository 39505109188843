import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { Observable, Subject } from 'rxjs'
import { tap, finalize } from 'rxjs/operators'
import { UserService } from "../shared/user.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //console.log(req);
        if (req.headers.get('No-Auth') == "True"){
            return next.handle(req.clone());
        }
            

        

        if (localStorage.getItem('userToken') != null) {
            const clonedreq = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('userToken'))

            });
            return next.handle(clonedreq)
                .pipe(
                    tap(
                    event => { },
                    error => {
                        console.log(error.status);
                        console.log(error);
                        if (error.status === 401)
                            this.router.navigateByUrl('/');
                        else if (error.status === 403)
                            this.router.navigateByUrl('/login');
                        else (error.status === 400  && (req.headers.get('forwarderror') == "True"))
                            return true;
                        //this.router.navigateByUrl('/forbidden');
                    }
                    ),
                     finalize(() => {
                       
                      })
                );                
        }
        else {
            //this.router.navigateByUrl('/');
        }
  }
    
}