<h2 mat-dialog-title>
    SELECT PLAYERS
  </h2>

        <div mat-dialog-content >

            <mat-selection-list #playerlist style="height: 350px; " [multiple]="true" >
                <ng-container *ngFor="let team of this.teams" >
                
                    <mat-divider *ngIf="team.Players.length>0"></mat-divider>
                    <label *ngIf="team.Players.length>0" >{{team.team.LevelName}}</label><br/>
                    
                    <mat-list-option *ngFor="let player of team.Players" style="height: 40px;" [value]="player.Player" >        
                        
                    <h4 mat-line>{{player.Player.LastName}}, {{player.Player.FirstName}}</h4>
                    <span mat-line>{{player.Player.DateOfBirth | date:'yyyy-MM-dd'}}</span> 
                    </mat-list-option>
                
                </ng-container>
            </mat-selection-list>
          
        </div>
      
  <div mat-dialog-actions>
      
      <button mat-button [mat-dialog-close]="playerlist.selectedOptions?.selected" cdkFocusInitial >SELECT</button>
      <button mat-button [mat-dialog-close]="false" >CANCEL</button>
    
  
  </div>
