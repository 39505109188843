<div class="app-page" style="margin-left:30px;">


    <div >
        <h2>GUIDE FOR FORMS</h2></div>

    <div >General purpose</div>
    <div >
        <pre>
            Forms are used to collect information from users. 
            
        </pre>
    </div>
    
    <div >Form</div>
    <div >
        <pre>
            Forms define what sections and questions should be filled in. Like a document template.
            The form also defines if it is enabled, which teams that can use it and if it can be entered with reference to a player.

            Form administrators can create and change forms.

            Every time the form is saved, a new version is created. This is to keep track of which version users have submitted.
            
            Forms can be submitted into a form submission (document).

            A Form submission can
        </pre>
    </div>

    <div >Form version</div>
    <div >
        <pre>

            Form version keeps track of how many times the form has changed.
            
            Forms can be submitted into a form submission (document).

            A Form submission can
        </pre>
    </div>

    <div >Form submission</div>
    <div >
        <pre>
            Forms can be submitted into a form submission (document).
            A Form submission can
        </pre>
    </div>


</div>