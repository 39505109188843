import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerSearchDialogComponent } from 'src/app/player-search-dialog/player-search-dialog.component';
import { PlayerService } from 'src/app/shared/player.service';
import { AgentViewModel } from '../../models/AgentModels';
import { AgentService } from '../../services/agent.service';

@Component({
  selector: 'app-agent-select-dialog',
  templateUrl: './agent-select-dialog.component.html',
  styleUrls: ['./agent-select-dialog.component.css']
})
export class AgentSelectDialogComponent implements OnInit {

  _agents: AgentViewModel[];

  constructor(private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AgentSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private agentService: AgentService
  ) { }


  ngOnInit(): void {
    this.getAgents();
  }

  getAgents(){
    this.agentService.getAgents().subscribe(data=>{
      this._agents = data as AgentViewModel[];
    });
  }

}
