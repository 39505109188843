<div >

    <h4>Question text</h4>
    <div>
      
        <mat-form-field  appearance="fill">
          <mat-label>Favorite food</mat-label>
          <mat-select>
            <mat-option value="1" >
              vgdfsgds
            </mat-option>
            <mat-option value="2" >
              vgdfsgds2
            </mat-option>
          </mat-select>
        </mat-form-field>

    </div>

</div>
<mat-divider></mat-divider>
