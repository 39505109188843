<div class="app-page">
    <h2>
        <mat-icon>dynamic_form</mat-icon>
        Forms

       <button [routerLink]="['/forms/new', {mode: 'new'}]" mat-raised-button color="primary" style="margin-left: 20px;" >NEW</button>
    </h2>

    <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
    <app-form-admin-card *ngFor="let form of this.forms" [Form]="form">

    </app-form-admin-card>
</div>
    

    
  <!--   <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let form" style="min-width: 200px;"><mat-icon>dynamic_form</mat-icon> {{form.Name}} </td>
        </ng-container>
        <ng-container matColumnDef="LatestVersion">
            <th mat-header-cell *matHeaderCellDef> Latest version </th>
            <td mat-cell *matCellDef="let form" style="width: 50px;"> {{form.LatestVersion}} </td>
        </ng-container>
        <ng-container matColumnDef="Description" >
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let form" style="min-width: 300px;"> {{form.Description}} </td>
        </ng-container>
        <ng-container matColumnDef="TeamEnabled" >
            <th mat-header-cell *matHeaderCellDef>For Team </th>
            <td mat-cell *matCellDef="let form" style="min-width: 300px;">               
                <div [ngClass]="form.FormVersion.TeamEnabled ? 'greenBox' : 'redBox'"></div>
            </td>
        </ng-container>
        <ng-container matColumnDef="PlayerEnabled" >
            <th mat-header-cell *matHeaderCellDef>Player level </th>
            <td mat-cell *matCellDef="let form" style="min-width: 300px;">
                <div *ngIf="form.FormVersion.PlayerEnabled">Yes</div>
                <div *ngIf="!form.FormVersion.PlayerEnabled ">No</div>
            </td>
        </ng-container>
        <ng-container matColumnDef="Actions" >
            <th mat-header-cell *matHeaderCellDef>  </th>
            <td mat-cell *matCellDef="let form" style="width: 90px;"> 
                <button mat-icon-button [routerLink]="['/forms/edit', form.FormId]">
                    <mat-icon>design_services</mat-icon>
                </button>
                
                <button mat-icon-button [routerLink]="['/forms/settings', form.FormId]">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let dataSource; columns: columnsToDisplay"></tr>         
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons 
    aria-label="Select page of forms">
</mat-paginator> -->
</div>
