import { MediaFile } from "src/app/shared/models/mediafile.model";

export class ExerciseModel
{
    ExerciseId : number;
    OrganizationId : number;
    Name : string;
    Description : string;

   

}

export class EquipmentModel
{
    EquipmentId : number;
    OrganizationId : number;
    Name : string;

}

export class TargetModel
{
    TargetId : number;
    OrganizationId : number;
    Name : string;
    Description: string;

}

export class TrainingMethodModel
{
    TrainingmethodId : number;    
    OrganizationId : number;
    Categpory: string;
    Name : string;
    Load: string;
    Execution: string;

}

export class GenericUnitModel
{
    UnitName : string;    
    
}

export class ExerciseViewModel extends ExerciseModel
{
    Targets: TargetModel[];
    EquipmentList: EquipmentModel[];
    MediaFile: MediaFile;
}


export class SetTemplateModel{
    SetTemplateId: number;
    OrganizationId: number;
    Name: string;
    Type: string;
    Description: string;
    Sets: TrainingSetModel[];
}

export class TrainingSetModel
{
    SetTemplateId : number;
    OrganizationId : number;
    SetId : number;
    Ordinal : number;
    Repetitions : number;
    Load: number; 
    Rest : number;

}

export class WorkoutSectionExerciseModel{
    WorkoutId: number;
    Name:String;
    OrganizationId: number;
    SectionId: number;
    ExerciseId: number;
    Exercise: ExerciseViewModel;
    //SetTemplateId: number;
    Sets: number;
    Reps: number;
    Rest: number;
    Weight: number;
    WeightUnit: string;
    Duration: number;
    DurationUnit: string;
    Speed: number;
    SpeedUnit: string;
    Effect: number;
    EffectUnit: string;
    TrainingMethodId: number;
    TrainingMethod: TrainingMethodModel;
}

export class WorkoutSectionModel{
    WorkoutId: number;
    OrganizationId: number;
    SectionId: number;
    Name: string;
    Ordinal: number;
    Exercises: WorkoutSectionExerciseModel[];
}

export class WorkoutModel{
    WorkoutId: number;    
    OrganizationId: number;    
    Name: string;
    Description: string;
    Status: string;
    EstimatedDuration: number;
    Intensity: number;
    Sections: WorkoutSectionModel[];
}

export class ProgramModel{    
    OrganizationId: number;    
    ProgramId: number;    
    Name: string;
    Description: string;
    Status: string;
    NoOfWorkouts: number;
    NoOfWeeks; number;
    ProgramWorkouts: ProgramWorkoutModel[];
    Days: ProgramDayModel[];
}

export class ProgramViewModel extends ProgramModel
{
    Days: ProgramDayModel[];    
    NoOfWorkouts: number;
    TotalDuration: number;
    
}

export class ProgramDayModel
{
    Week: number;
    Day: number;
    Workouts: ProgramWorkoutModel[];
}

export class WeekModel{
    Week: number;
}

export class ProgramWorkoutModel{
    WorkoutId: number;    
    Name: string;
    OrganizationId: number;    
    ProgramId: number;        
    Week: number;
    Day: number;
    Ordinal: number;

    Workout: WorkoutModel;
    
    
}

export class AssignedProgramModel{    
    OrganizationId: number;    
    AssignedProgramId: number;    
    Title: string;
    Description: string;
    Status: string;
    NoOfWorkouts: number;
    NoOfWeeks; number;
    AssignedWorkouts: AssignedWorkoutModel[];
    Days: ProgramDayModel[];
}

export class AssignedWorkoutModel{
    OrganizationId: number;    
    AssignedProgramId: number;
    AssignedWorkoutId: number;    
    PlayerId: string
    TeamId: string;
    NoOfAssignedPlayers: number;
    WorkoutId: number;        
    Workout: WorkoutModel;
    AssignedProgram: AssignedProgramModel;
    TeamName: string;
    TeamLevel: string;
    PlannedDate: Date;   
}


