<h2 mat-dialog-title>INVITE PLAYER</h2>
<mat-dialog-content>
        <form  style="color: white;"  (ngSubmit)="onSubmit()" #addEventForm="ngForm">
                <div style="font-family: Roboto">
                Invite <b>{{this.data.LastName + ", " + this.data.FirstName}}</b> by entering the email address and clicking SEND.
                </div>
                <div style="font-family: Roboto; color: gray;">Players will get an email with an authorization codethat can be used in the player app to register an account. </div>
                <div style="font-family: Roboto; color: gray;">The code will also be visible in the list. </div>
                <br/><br/>
                <mat-form-field color="accent">
                        <mat-label style="color: white">Enter players email</mat-label>
                        <input matInput  [formControl]="emailFormControl"  [(ngModel)]="invite.UserEmail" id="UserEmail" name="UserEmail">
                        <input type="hidden" [(ngModel)]="invite.PlayerId" name="PlayerId" id="PlayerId">
                        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                Enter a valid email address
                              </mat-error>
                              <mat-error *ngIf="emailFormControl.hasError('required')">
                                Email <strong>is required</strong>
                              </mat-error>
                </mat-form-field>
               <br/>
                <div>
                        <button mat-raised-button color="primary" type="submit" >SEND</button>
                        <button mat-button mat-dialog-close>CANCEL</button>
                        
                </div>
        </form>

</mat-dialog-content>