import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlayerService } from 'src/app/shared/player.service';
import { PlayerModel } from '../models/player';
import * as moment from 'moment';
import { interval, timer } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from 'src/app/shared/notification.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { UserService } from 'src/app/shared/user.service';
import { InvitePlayerDialogComponent } from 'src/app/shared/invite-player-dialog/invite-player-dialog.component';
import { PlayerInviteService } from 'src/app/shared/player-invite.service';

@Component({
  selector: 'app-load-team-overview',
  templateUrl: './load-team-overview.component.html',
  styleUrls: ['./load-team-overview.component.css']
})
export class LoadTeamOverviewComponent implements OnInit {

  private sub: any;
  private updateSubscription: any;
  isBusy: boolean;
  TeamId: string;
  TodayOnly: boolean;
  _players: PlayerModel[];
  public scaleColors: string[] = ['gray','#FF5722','#FF5722','#FF9800','#FFC107','#FFFEB3','#CDDC39', '#8BC34A', '#4CAF50','#4CAF50','#4CAF50']
  public scaleTextColors: string[] = ['black','black','black','black','black','black','black', 'black', 'white','white','white']
  public availabilityColors: string[] = ['gray', '#4CAF50','#FF5722','#FF5722'];
  public availabilityText: string[] = ['-','OK','SJUK','SKADAD','BORTA' ];
  public painScaleColors: string[] = ['#4CAF50','#FFFEB3','#FFFEB3', '#FFC107', '#FFC107', '#FF9800','#FF9800','#FF5722','#FF5722','#FF5722','#FF5722']
  public painScaleTextColors: string[] = ['white','black','black','black','black','white','white','white','white','white','white']
  private CurrentPosition: string ="";
  private PositionTexts: string[] = ['-','GOALKEEPER','DEFENDER', 'DEFENDER','CENTER', 'FORWARD'];

  constructor(
    public playerService: PlayerService, 
    public inviteService: PlayerInviteService,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar, 
    public _userService: UserService,
    private _notificationService: NotificationService,
    public dialog: MatDialog) { 

   

  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
     

     
    
      this.TeamId = params['TeamId']; 
      this.TodayOnly = params['TodayOnly'];
      //console.log(this.TeamId);
      this.getData();
    });
    this.updateSubscription = interval(60000).subscribe(
      (val) => { this.getData()
      });
  }

  getData(){
    this.isBusy=true;
    this._snackBar.open("Updating...", "", {
      duration: 1000,
    });
    this.playerService.getPlayersByTeamSeasonOverview(this.TeamId, this.TodayOnly).subscribe(d=>{
      //console.log(d);
      this._players = d as PlayerModel[];
      for(let p of this._players){
        var minDate = moment.utc('0001-01-01');
        moment.locale('sv');

        //console.log(p.AssessmentTimestamp);
        moment.updateLocale('sv', {
          relativeTime : {
              future: "in %s",
              past:   "%s",
              s  : 'now',
              ss : '%d s',
              m:  "1 min",
              mm: "%d min",
              h:  "1 h",
              hh: "%d h",
              d:  "1 d",
              dd: "%d d",
              M:  "1 mth",
              MM: "%d mth",
              y:  "1 y",
              yy: "%d y"
          }
      });


        if(moment(p.AssessmentTimestamp +  "+01:00").isAfter(minDate))
          p.TimeAgoText = moment(p.AssessmentTimestamp + "+01:00").fromNow();  
        else
          p.TimeAgoText = '';
        
        p.TimeAgoStatus = "dotred";
        if( moment(p.AssessmentTimestamp +  "+01:00").isAfter(moment().subtract(3,'days'))){
          p.TimeAgoStatus = "dotgreen";
        }else{
          if(moment(p.AssessmentTimestamp +  "+01:00").isAfter(moment().subtract(7,'days'))){
            p.TimeAgoStatus = "dotyellow";
          }
        }


        //Decide when to add a header for the position
        if(p.Position!=this.CurrentPosition){
            p.AddPositionHeaderBefore=true;
            this.CurrentPosition=p.Position;
        }
        else{
          p.AddPositionHeaderBefore=false;
        }

      }
      
      this.isBusy=false;
    });
    
  }

  sendReminder(){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Do you want to send a reminder to all players with outdated status?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){

        this._notificationService.sendPushReminder_for_StatusUpdate(this.TeamId).subscribe(res=>{
          this._snackBar.open("Push sent", "", {
            duration: 1000,
          });
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.updateSubscription.unsubscribe();
  }

  
  openInviteDialog(teamid, playerid, lastname, firstname): void {
    let dialogRef = this.dialog.open(InvitePlayerDialogComponent, 
      { data: { TeamId: teamid, PlayerId: playerid, LastName: lastname, FirstName: firstname  },}
    );

    dialogRef.afterClosed().subscribe(result => {
      
      this.ngOnInit();
      
    });   
  }

  cancelInvitation(inviteId){
    
  
    this.inviteService.CancelInvite(inviteId).subscribe( res => {
      this.ngOnInit();
    });

  }

 /*  resendInvitation(inviteId){
    this.inviteService.ResendInvite(inviteId).subscribe( res => {
      this.ngOnInit();
    });
    
  } */


}
