
<mat-card appearance="outlined" style="max-width: 570px; width: 570px; margin: 10px;" class="mat-elevation-z4">        
<mat-card-header>
    <mat-card-title>
        <img src="/assets/img/{{this.Team.SmallIconFileName}}" height="40px;"/>
        {{Team.Level}}
    </mat-card-title>       
 <!--    <mat-card-subtitle>
        {{Team.Level}}
    </mat-card-subtitle>  -->
</mat-card-header>
<mat-card-content >
    <mat-divider *ngIf="this.Team.Access_PhysicalStatus"></mat-divider> 
    <app-team-physical-status-block [Team]="Team" *ngIf="this.Team.Access_PhysicalStatus"></app-team-physical-status-block>
    <mat-divider *ngIf="this.Team.Access_Injury"></mat-divider>
    <app-team-injury-block [Team]="Team" *ngIf="this.Team.Access_Injury"></app-team-injury-block><br/>
    <mat-divider *ngIf="this.Team.Access_PlayerGameRankings"></mat-divider>
    <app-team-games-block [Team]="Team" *ngIf="this.Team.Access_PlayerGameRankings"></app-team-games-block><br/>
    <mat-divider *ngIf="this.Team.Access_Test"></mat-divider>
    <app-team-physical-test-block [Team]="Team" *ngIf="this.Team.Access_Test"></app-team-physical-test-block><br/>
    <mat-divider></mat-divider>
</mat-card-content>
</mat-card>
