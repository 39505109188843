<h2 mat-dialog-title style="font-family: Roboto;">
    <mat-icon>person</mat-icon>
    New contact
</h2>
<div mat-dialog-content>

  <form  [formGroup]="form" (ngSubmit)="save()" autocomplete="off">
    <div style="display: flex; flex-direction: row;">
        <mat-form-field appearance="fill"  class="smallinput">
            <mat-label>FirstName</mat-label>
            <input matInput  formControlName="FirstName" id="FirstName" /> 
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="fill"  class="smallinput">
              <mat-label>Lastname</mat-label>
              <input matInput formControlName="LastName" id="LastName" /> 
              <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
              <!-- <mat-hint>Hint</mat-hint> -->
            </mat-form-field>
    </div>
    <mat-form-field appearance="fill"  class="smallinput">
        <mat-label>Relation to player</mat-label>
        <input matInput formControlName="Relation" id="Relation" /> 
    </mat-form-field>
                
    <mat-form-field appearance="fill"  class="longinput">
        <mat-label>Company name</mat-label>
        <input matInput formControlName="CompanyName" id="CompanyName" />         
    </mat-form-field>

    <div style="display: flex; flex-direction: row;">
        <mat-form-field appearance="fill"  class="smallinput">
            <mat-label>Home phone</mat-label>
            <input matInput  formControlName="HomePhone" id="HomePhone" /> 
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
            <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="fill"  class="smallinput">
              <mat-label>MobilePhone</mat-label>
              <input matInput formControlName="MobilePhone" id="MobilePhone" /> 
              <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
              <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
          
          
    </div>
    <mat-form-field appearance="fill"  class="longinput">
        <mat-label>Email</mat-label>
        <input matInput formControlName="Email" id="Email" /> 
        <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
        <!-- <mat-hint>Hint</mat-hint> -->
    </mat-form-field>
    <br/>    
    <ng-container formArrayName="Addresses">
        <div *ngFor="let address of this.Addresses.controls; let i = index;" [formGroupName]="i">
            <mat-form-field appearance="fill"  class="longinput">
                <mat-label>Address 1</mat-label>
                <input matInput formControlName="Address1" id="Address1" /> 
                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                <!-- <mat-hint>Hint</mat-hint> -->
            </mat-form-field>
            <br/>
            <mat-form-field appearance="fill"  class="longinput">
                <mat-label>Address 2</mat-label>
                <input matInput formControlName="Address2" id="Address2" /> 
                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                <!-- <mat-hint>Hint</mat-hint> -->
            </mat-form-field>
            <br/>
            <div style="display: flex; flex-direction: row;">
                <mat-form-field appearance="fill"  class="smallinput" style="width: 150px;">
                    <mat-label>Post code</mat-label>
                    <input matInput formControlName="PostCode" id="PostCode" /> 
                    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
                &nbsp;
                <mat-form-field appearance="fill"  class="smallinput">
                    <mat-label>City</mat-label>
                    <input matInput formControlName="City" id="City" /> 
                    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
            </div>
            <mat-form-field appearance="fill"  class="smallinput">
                <mat-label>Country</mat-label>
                <input matInput formControlName="Country" id="Country" /> 
                <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                <!-- <mat-hint>Hint</mat-hint> -->
            </mat-form-field>

        </div>
    </ng-container>
    
   

  
    
   
    <button mat-button color="primary"   *ngIf="this.data.Contact==null" type="submit" [disabled]="!form.valid">CREATE</button>
    <button mat-button color="primary" *ngIf="this.data.Contact!=null" type="submit" [disabled]="!form.valid">SAVE</button>
    <button type="button" mat-button  (click)="onCancel()">CANCEL</button>
   
      
    

    
  </form>
  <!--     
  <div>
    {{ this.form.value | json}}
  </div> -->
    
</div>
