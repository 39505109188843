import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { TestResultKPIViewModel } from 'src/app/shared/models/test.model';
import { Team } from 'src/app/shared/team.model';
import { TestResultService } from 'src/app/shared/test-result.service';


@Component({
  selector: 'app-team-physical-test-block',
  templateUrl: './team-physical-test-block.component.html',
  styleUrls: ['./team-physical-test-block.component.css']
})
export class TeamPhysicalTestBlockComponent implements OnInit {

  @Input() Team: Team; 
  testOverView: boolean = false;
  kpis: TestResultKPIViewModel;
  noOfRankedTests: number = 0;
  
  constructor(private testResultService: TestResultService) { }
  
  ngOnInit(): void {
    this.refreshData();

   
  }

  refreshData(){
    this.testResultService.getTestResultKPIs(this.Team.TeamId, moment().add(-28,'day').toDate(),moment().toDate()).subscribe(kpis=>{
      console.log(kpis);
      this.kpis = kpis;
      this.noOfRankedTests = kpis.Ranking1_Count + kpis.Ranking2_Count + kpis.Ranking3_Count + kpis.Ranking4_Count + kpis.Ranking5_Count;
      this.testOverView = true;
    })
  }

  getWidth(count){
    var width = 400 * count/this.noOfRankedTests;
    return width + "px";
  }

  

  
}
