<form [formGroup]="keyresultForm" (ngSubmit)="onSubmit()">
    <input type="hidden" id="ObjectiveId" formControlName="ObjectiveId">
    <input type="hidden" id="KeyResultId" formControlName="KeyResultId">
    <h2 mat-dialog-title>Update progress</h2>
    <mat-dialog-content >

            <div style="display: flex; flex-direction: row;">
                <div style="width: 32px;">
                    <mat-icon style="font-size: 24px; background-color: lightseagreen;">show_chart</mat-icon>
                </div>
                <div style=" font-family: Roboto; font-size: large;">       
                    {{this.keyResult.Title}}
                </div>
                
            </div>
            <div style="display: flex; flex-direction: row;">
                <div style="width: 150px;">Startvalue is 
                </div>
                <div>{{this.keyResult.BaselineInteger}}</div>
                
               
            </div>
            <div style="display: flex; flex-direction: row;">
                <div style="width: 150px;">Current value is 
                </div>
                <div>{{this.keyResult.CurrentInteger}}</div>
                
               
            </div>
            <div style="display: flex; flex-direction: row;">
                <div  style="width: 150px;">Target value is 
                </div>
                <div>{{this.keyResult.TargetInteger}}</div>
            </div>
           
            <br/>
            <h3>Enter new value below</h3>
            <mat-form-field appearance="fill" style="width: 100px;">
                <mat-label>New value</mat-label>
                <input matInput id="ValueInteger" type="text" formControlName="ValueInteger">
            </mat-form-field>
          
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button  type="submit">SAVE</button>
            <button mat-button mat-dialog-close>CANCEL</button> 
        
    </mat-dialog-actions>
        
        
</form>
    