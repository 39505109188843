<mat-card appearance="outlined" style="max-width: 340px;  margin: 10px;">
   
    <mat-card-header>
        
    
        <mat-card-title>
            <div style="display: flex; flex-direction: row;">
                <mat-icon aria-hidden="false" aria-label="Example home icon">track_changes</mat-icon>
                <div style="font-size: medium;"> {{this.Objective.Title }}</div>
            </div>
            
            <div style="display: flex; flex-direction: row;">
                <mat-progress-bar mode="determinate" value="40" style="width: 100px; padding-top: 10px;"></mat-progress-bar><label style="font-size: small; font-family: Roboto;">{{ this.Objective.CurrentProgress }}</label>
                <mat-icon style="font-size: medium; color:gray;">watch_later</mat-icon> <span style="font-size: small; color: gray;"></span>
            </div>
            <div>
                {{this.Objective.OwnerInfo.LastName}},{{this.Objective.OwnerInfo.FirstName}}
            </div>
        </mat-card-title>
        


    </mat-card-header>
    <mat-card-content style="margin-bottom: 0px;" >
        <!--     <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Key results
                    </mat-panel-title>
                    <mat-panel-description>
    
                    </mat-panel-description>
                </mat-expansion-panel-header>
    
    
                <app-keyresult-status-row></app-keyresult-status-row> 
                <app-keyresult-status-row></app-keyresult-status-row>
    
    
            </mat-expansion-panel> -->
                
                <label style="font-family: Roboto; font-size: x-small;">Key results</label>
                <app-keyresult-status-row [KeyResult]="kr" *ngFor="let kr of this.Objective.KeyResults">

                </app-keyresult-status-row> 
                
                        
                    
                
            <mat-divider></mat-divider>
            
    
    
<!--             <div style="font-family: verdana,arial,sans-serif; font-size: x-small;  text-align: left; vertical-align: top;  padding: 0; white-space: normal; margin: 0; color: white;  ">    
                <table>
                    <tr>
                        <td>
                                <mat-icon style="color: orange;font-size: 18px;">person</mat-icon>
                        </td>
                        <td>
                                <div style="font-size: 10px;">this.Report.CreatedByLastName, this.Report.CreatedByFirstName</div>
                                <span style="color: gray;font-size: 10px; color: gray;">this.Report.DateCreated | date:'yyyy-MM-dd HH:mm' </span>
                        </td>
                    </tr>
                    <tr >
                        <td>  
                        
                                <mat-icon style="color:orange;font-size: 18px;">sports_hockey</mat-icon>
                            
                        
                        </td>
                        <td>  
                            <div style="font-size: 10px;">
                                this.Report.GameText     <br>
                                <span>this.Report.ReportDivision</span>  
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            
                            <mat-icon style="color: orange; font-size: 18px;"> location_on</mat-icon>
                        </td>
                        <td>
                            <div style="font-size: 10px;">
                            
                            </div>
                        </td>
                    </tr>
                </table>                       
            </div>
     -->
    
            <mat-divider></mat-divider>
    
    
    
        </mat-card-content>
    <mat-card-actions>
            <button mat-button  >            
                CHECK IN
            </button>
            <button mat-button [routerLink]="['/objectives/details', this.Objective.ObjectiveId]" >         
                DETAILS
            </button>
        
    </mat-card-actions>
</mat-card> 


