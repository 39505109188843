import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeamScheduleService } from '../../services/team-schedule.service';
import { TeamEventViewModel } from 'src/app/load/models/gameevent';

@Component({
  selector: 'app-team-event-details-dialog',
  templateUrl: './team-event-details-dialog.component.html',
  styleUrls: ['./team-event-details-dialog.component.css']
})
export class TeamEventDetailsDialogComponent implements OnInit {

  public EventId: string;
  private Event: TeamEventViewModel;

  constructor( public dialogRef: MatDialogRef<TeamEventDetailsDialogComponent>,  
    private _scheduleService: TeamScheduleService,
    @Inject(MAT_DIALOG_DATA) public data: any, ) { }

  ngOnInit(): void {
    this.EventId = this.data.EventId;
    this._scheduleService.getTeamEventWithLogs(this.EventId).subscribe(data=>{
      this.Event = data;
    })
  }

  cancel(){
    this.dialogRef.close();
  }

}
