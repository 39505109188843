import { Injectable } from '@angular/core';
import { BaseService } from './baseservice';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';
import { FormGroup } from '@angular/forms';
import { Form, FormQuestion, FormQuestionViewModel, FormSection, FormSectionViewModel, FormVersionViewModel, FormViewModel, FormsSubmissionViewModel } from './models/FormModels';
import { ResponseModel } from './models/ResponseModel';
import { DCTable } from './models/tablemodels';

@Injectable({
  providedIn: 'root'
})
export class FormService extends BaseService {

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }

/*   getFormMOckup(FormId: string): Observable<FormViewModel>{

    var theForm = new FormViewModel();
    theForm.FormId = "someguid";
    theForm.Name = "backutvärdering";
    var version = new FormVersionViewModel(theForm);
    version.Version = 1;

    var aSection: FormSectionViewModel = new FormSectionViewModel();
    aSection.Name = "TEST";
    aSection.Ordinal = 1;

    var aQuestion: FormQuestionViewModel = new FormQuestionViewModel();
    aQuestion.ControlType = "text";
    aQuestion.QuestionType = "ShortText";
    aQuestion.Label ="How do you feel";
    aQuestion.Key = "Guid1";

    var aQuestion1: FormQuestionViewModel = new FormQuestionViewModel();
    aQuestion1.ControlType = "text";
    aQuestion1.QuestionType = "LongText";
    aQuestion1.Label ="How do you really feel";
    aQuestion1.Key = "Guid2";

    var aQuestion2: FormQuestionViewModel = new FormQuestionViewModel();
    aQuestion2.ControlType = "text";
    aQuestion2.QuestionType = "SelectList";
    aQuestion2.Label ="Food fetisch";
    aQuestion2.Key = "Guid3";

    var aQuestion3: FormQuestionViewModel = new FormQuestionViewModel();
    aQuestion3.ControlType = "text";
    aQuestion3.QuestionType = "Date";
    aQuestion3.Label ="When was the last day you were happy";
    aQuestion3.Key = "Guid4";

    aSection.Questions = [aQuestion, aQuestion1, aQuestion2, aQuestion3];

    version.Sections = [aSection];

    theForm.FormVersion = version;
    
    
    return of(theForm);
  }
 */
  getForm_LatestVersion(FormId: string){
    return this.http.get(this.rootUrl + '/api/forms/GetFormLatestVersion?FormId=' + FormId);
  }

  getForms_ByTeam(TeamId: string){
    return this.http.get(this.rootUrl + '/api/forms/GetFormsByTeam?TeamId=' + TeamId);
  }

  getForms_ByTeamForTeam(TeamId: string){
    return this.http.get(this.rootUrl + '/api/forms/GetFormsByTeamForTeam?TeamId=' + TeamId);
  }

  getForms_ByTeamForGame(TeamId: string){
    return this.http.get(this.rootUrl + '/api/forms/GetFormsByTeamForGame?TeamId=' + TeamId);
  }
  
  getForms_ByTeamForPlayer(TeamId: string){
    return this.http.get(this.rootUrl + '/api/forms/GetFormsByTeamForPlayer?TeamId=' + TeamId);
  }



  getForms(){
    return this.http.get(this.rootUrl + '/api/forms/GetForms');
  }

  getFormSubmitted(){
    return this.http.get(this.rootUrl + '/api/forms/GetSubmitted');
  }

  getFormSubmittedByFormId(formId: string): Observable<FormsSubmissionViewModel[]>{
    return this.http.get<FormsSubmissionViewModel[]>(this.rootUrl + '/api/forms/GetSubmittedByForm?FormId=' + formId);
  }

  getFormSubmittedByFormIdAndTeam(formId: string, teamId: string): Observable<FormsSubmissionViewModel[]>{
    return this.http.get<FormsSubmissionViewModel[]>(this.rootUrl + '/api/forms/GetSubmittedByFormAndTeam?FormId=' + formId + '&TeamId=' + teamId);
  }

  getFormSubmittedByPlayer(playerId: string): Observable<FormsSubmissionViewModel[]>{
    return this.http.get<FormsSubmissionViewModel[]>(this.rootUrl + '/api/forms/GetSubmittedByPlayer?PlayerId=' + playerId);
  }

  getFormSubmittedByGame(gameId: string): Observable<FormsSubmissionViewModel[]>{
    return this.http.get<FormsSubmissionViewModel[]>(this.rootUrl + '/api/forms/GetSubmittedByGame?GameId=' + gameId);
  }

  getFormSubmittedByFormIdForTable(formId: string): Observable<FormsSubmissionViewModel[]>{
    return this.http.get<FormsSubmissionViewModel[]>(this.rootUrl + '/api/forms/GetSubmittedByForm_ForTable?FormId=' + formId);
  }

  getFormSubmitted_ByFormIdAndTeamId_ForTable(formId: string, teamId: string): Observable<DCTable>{
    return this.http.get<DCTable>(this.rootUrl + '/api/forms/GetSubmittedByFormAndTeam_ForTable?TeamId=' + teamId + '&FormId=' + formId);
  }

  getFormSubmittedByTeam(TeamId: string){
    return this.http.get(this.rootUrl + '/api/forms/GetSubmittedByTeam?TeamId=' + TeamId);
  }

  createNewFormVersion(form: FormViewModel[]): Observable<ResponseModel>{
    let ret = this.http.post<ResponseModel>(this.rootUrl + '/api/forms/CreateNewFormVersion',form,this.httpOptions);
    return ret;
  }
  
  getFormSubmissionById(SubmissionId: string){
    return this.http.get(this.rootUrl + '/api/forms/GetSubmissionById?SubmissionId=' + SubmissionId);
  }

  getFormSettingsById(FormId: string){
    return this.http.get(this.rootUrl + '/api/forms/getformsettingsbyid/' + FormId);
  }

  createForm(form: FormViewModel): Observable<ResponseModel>{    
    let ret = this.http.post<ResponseModel>(this.rootUrl + '/api/forms/CreateNewForm', form ,this.httpOptions);
    return ret;
  }

  UpdateFormSettings(form: FormViewModel): Observable<ResponseModel>{    
    let ret = this.http.put<ResponseModel>(this.rootUrl + '/api/forms/' + form.FormId + '/settings', form ,this.httpOptions);
    return ret;
  }

  SubmitFormData(data: string): Observable<ResponseModel>{    
    let ret = this.http.post<ResponseModel>(this.rootUrl + '/api/forms/SubmitForm',data,this.httpOptions);
    return ret;
  }

  UpdateSubmission(data: string): Observable<ResponseModel>{    
    let ret = this.http.put<ResponseModel>(this.rootUrl + '/api/forms/updatesubmission',data,this.httpOptions);
    return ret;
  }

  DeleteSubmission(SubmissionId: string): Observable<ResponseModel>{    
    let ret = this.http.delete<ResponseModel>(this.rootUrl + '/api/forms/submission/' + SubmissionId);
    return ret;
  }
}
