<mat-card appearance="outlined" style="max-width: 400px;  margin: 10px; font-family: Roboto;" [ngClass]="setClass()">
    <table>
        <tr>
            <td><img style="height: 48px;" *ngIf="this.Report.PlayerCurrentTeamIcon!=null" [src]="'../../assets/img/' + this.Report.PlayerCurrentTeamIcon"     ></td>
            <td>
                    <span style="font-size: large;">{{ this.Report.PlayerLastName}}, {{ this.Report.PlayerFirstName}}</span>
                    <div style="font-size: medium; color: darkgray; font-size: 12px; ">
                        
                        <span *ngIf="this.Report.PositionId===1">
                            Goaltender
                        </span>
                        <span *ngIf="this.Report.PositionId===2 || this.Report.PositionId===3 " >
                            Defender
                        </span>
                        <span *ngIf="this.Report.PositionId>3">
                            Forward
                        </span>
                        <span >
                            
                            {{ this.Report.DateOfBirth | date:'yyyy-MM-dd'}} ({{this.Report.PlayerAgeYear + ' Years ' + this.Report.PlayerAgeMonth + ' Months'}})
                            
                        </span>
                        <br>
                        <span *ngIf="this.Report.ReportDivision != null && this.Report.ReportTeamName != null">
                            {{this.Report.ReportDivision + "/" + this.Report.ReportTeamName}}<br/>
                        </span>
                        <span *ngIf="this.Report.ReportDivision === null || this.Report.ReportTeamName === null">
                            {{this.Report.PlayerCurrentDivision + "/" + this.Report.PlayerCurrentTeam}}<br/>
                        </span>

                    </div>
                    
                 



                    
            </td>
        </tr>
    </table>
     
<mat-card-header>
    <!-- <img mat-card-avatar  [src]="'../../assets/img/' + this.Report.PlayerCurrentTeamIcon"     > -->
   
    <mat-card-title>
      
    </mat-card-title>
  


</mat-card-header>
<mat-card-content style="margin-bottom: 0px;">

        
    <mat-divider></mat-divider>
        <div style="font-size: 14px; padding: 5px;min-height: 50px; white-space: pre-line;font-family: Roboto;">
            {{this.Report.Text}}
        </div>
        <mat-divider></mat-divider>
        <app-report-overall-game [GameText]="this.Report.GameText" [GameDivision]="this.Report.ReportDivision"
            [GamePerformanceText]="this.Report.GamePerformanceText" [GamePerformance]="this.Report.GamePerformance"
            [ScoutingSourceText]="this.Report.ScoutingSourceText" [Position]="this.Report.PositionText"></app-report-overall-game>
        <mat-divider></mat-divider>
        <app-report-overall-grades [TotalQualityText]="this.Report.TotalQualityText" [TotalQuality]="this.Report.TotalQuality"
            [PlayerType]="this.Report.PlayerType" [PlayerTypeText]="this.Report.PlayerTypeText"
            [CapacityText]="this.Report.PlayerCapacityText" [Capacity]="this.Report.PlayerCapacity"></app-report-overall-grades>

    <div style="font-family: verdana,arial,sans-serif; font-size: x-small;  text-align: left; vertical-align: top;  padding: 0; white-space: normal; margin: 0; color: white;  ">    
        <table>
        <!--   <tr>
                <td>
                        <mat-icon style="color: orange;font-size: 18px;">person</mat-icon>
                </td>
                <td>
                        <div style="font-size: 10px;">{{this.Report.CreatedByLastName}}, {{this.Report.CreatedByFirstName}}  <span style="color: gray;font-size: 10px; color: darkgray;">- {{getTimeAgo()}} ({{this.Report.DateCreated | date:'yyyy-MM-dd HH:mm' }})</span></div>
                        
                </td>
            </tr> -->
            <!--  <tr *ngIf="this.Report.GameText">
                <td>  
                  
                        <mat-icon style="color:orange;font-size: 18px;">sports_hockey</mat-icon>
                       
                  
                </td>
                <td>  
                    <div style="font-size: 10px;">
                        
                            {{this.Report.GameText}}     <br>
                            <span>{{this.Report.ReportDivision}}</span>  
                        
                    </div>
                </td>
                </tr> -->
                
                </table>
                <mat-divider></mat-divider>
                <table>
                    <tr>
                        <td>
                            <mat-icon style="color: orange;font-size: 18px;">person</mat-icon>
                        </td>
                        <td>
                            <div style="font-size: 10px;">{{this.Report.CreatedByLastName}}, {{this.Report.CreatedByFirstName}} <span
                                    style="color: gray;font-size: 10px; color: darkgray;">- {{getTimeAgo()}} ({{this.Report.DateCreated
                                    | date:'yyyy-MM-dd HH:mm' }})</span></div>
                        </td>
            </tr>
            <tr>
                <td>
                    
                    <mat-icon style="color: orange; font-size: 18px;"> location_on</mat-icon>
                </td>
                <td>
                    <div style="font-size: 10px;">
                        {{this.Report.Location}}
                        <div style="font-size: 10px;" class="grayshading" *ngIf="this.Report.ScoutingSourceText">
                            {{this.Report.ScoutingSourceText}}
                        </div>
                    </div>
                </td>
            </tr>
        </table>
      
       
        
    </div>


    <mat-divider></mat-divider>



</mat-card-content>
<mat-card-actions>
    <button mat-icon-button (click)="openViewReport(this.Report.ReportId)">
        <mat-icon style="font-size: 24px;">
            preview
        </mat-icon>
    
    </button>
    <button mat-icon-button (click)="openPlayer(this.Report.PlayerId)">
        <mat-icon>
            account_box
        </mat-icon>
    </button>
    <button mat-icon-button *ngIf="this.Report.IsCreatedByCurrentUser" [routerLink]="['/scouting/editreport', this.Report.ReportId]" >
        <mat-icon style="font-size: 24px;">
                edit
        </mat-icon>   
        
    </button>
<!--   <button mat-icon-button>
        <span class="material-symbols-outlined">
            playlist_add
        </span>
    </button> -->
    
<span style="width: 100px;"></span>
    <button mat-icon-button (click)="gotoPlayerDetails(this.Report.PlayerId)"><img style="height: 24px;"
            src="../../assets/img/icons8-user-shield-48.png"></button>

    <a *ngIf="this.Report.GameId != null && this.Report.GameId > '00000000-0000-0000-0000-000000000000'" mat-icon-button
    type="button" [routerLink]="[ '/scouting/gamedetails', this.Report.GameId ]">
        <mat-icon style="font-size: 24px; ">
            sports_hockey
        </mat-icon>     
    </a>
</mat-card-actions>
</mat-card> 


