<h1 mat-dialog-title style="font-family: Roboto;">SET GAME ASSESSMENT</h1>
<div mat-dialog-content >
  
  <form id="gameasessmentform">

    <mat-form-field *ngIf="this.gameassessments" style="width:100%">
      <mat-label>Select game assessment for team</mat-label>
      <mat-select [(value)]="GameAssessmentId"  >
        <mat-option *ngFor="let ga of this.gameassessments" [value]="ga.GameAssessmentId" (onSelectionChange)="change($event)">{{ ga.Name }}</mat-option>        
      </mat-select>   
    </mat-form-field>
    
    
  </form>

 </div>
 <div mat-dialog-actions>
    <button mat-raised-button color="primary"  (click)="onCloseConfirm()" cdkFocusInitial>SAVE</button>
   <button mat-button (click)="onNoClick()">CANCEL</button>
 </div>