<div class="app-page">
    <div *ngIf="this._flowitems!=null && this._flowitems.length==0" style="font-family: Roboto; margin: 20px;;">
        NO ITEMS FOUND
    </div>
    <div style="display: flex; flex-flow: row wrap; justify-content: center; ">       
        <ng-container *ngFor="let item of this._flowitems">
             <app-report-card *ngIf="item.FlowItemType=='SCREPORT'"  [Report]="item" ></app-report-card>
             <app-note-card *ngIf="item.FlowItemType=='PLAYERNOTE'" [Note]="item"></app-note-card>
        </ng-container>  
          


    </div>
 
</div>
