import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { FormService } from 'src/app/shared/form.service';
import { FormViewModel } from 'src/app/shared/models/FormModels';
import { DCColumn, DCTable } from 'src/app/shared/models/tablemodels';

@Component({
  selector: 'app-form-submission-table',
  templateUrl: './form-submission-table.component.html',
  styleUrls: ['./form-submission-table.component.css']
})
export class FormSubmissionTableComponent implements OnInit, AfterViewInit  {
  @Input() FormId: string;
  @Input() TeamId: string;
  @Input() PlayerId: string;

  dataSource:  MatTableDataSource<Object>;
  nonPrintProps: string[] = ['SubmissionId', 'FormId', 'Form','SubmittedByUserId','TeamId','PlayerId','UserId','GameId', 'OrganizationId', 'Submitted', 'SubmittedByUser'];
  columns: string[];
  rows: any[] = [];
  datafound: boolean = true;
  selectedColumns: UntypedFormControl;
  columnNames: DCColumn[];    
 
  stickyColumns: string[]=  ["Submitted","Submitted by"];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  constructor(private formService: FormService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    //this.getFormData();

  }

  getFormData(){
    let objArr = [];
    this.formService.getFormSubmitted_ByFormIdAndTeamId_ForTable(this.FormId, this.TeamId).subscribe(data=>{
      //console.log(data);
      //this.table = data as DCTable;
      if(data.Data.length==0){
        this.datafound = false;
      } else{
        this.columnNames=data.Columns;
        console.log(data.Data);
        data.Data.forEach(dataObject=>{
          this.removeProps(dataObject);
          //console.log(dataObject);
          objArr.push(dataObject);
          this.datafound = true;
        });
        this.rows = objArr;
        //console.log("this.rows");
        //console.log(this.rows);
        this.generateColumns();
        
      }


      //console.log("data.Columns");
      //console.log(data.Columns);

      //console.log(Object.keys(data.Columns));
    });
    
  }

  ngOnChanges(changes: SimpleChanges) {
    
    this.dataSource = new MatTableDataSource<Object>([]);
    this.getFormData();
    
  }

  removeProps(dataObject: Object): Object{
    for(var key in dataObject){
      for (let i = 0; i < this.nonPrintProps.length; i++) {
        if(dataObject.hasOwnProperty(key)){
          if(key== this.nonPrintProps[i]){
            delete dataObject[key];
          }
        } 
      }
    }
    return dataObject;
  }


  generateColumns(){
    this.columns = Object.keys(this.rows[0]);
    console.log(this.columns);
    //this.selectedColumns = new FormControl(this.columns);
    this.createDataSource();
  }

  createDataSource(){
    this.dataSource = new MatTableDataSource<Object>(this.rows);
    this.dataSource.paginator = this.paginator;
  }

  isSticky(id: string) {
    //onsole.log(id);
    return this.stickyColumns.indexOf(id) !== -1;
  }


  getClass(ColumnKey, value){
   
    var colum = this.columnNames.find(({ Key }) => Key === ColumnKey);
    console.log(colum.ControlType);
    var className = "";
    if(colum.ControlType==="Rating5"){
      if(value==="0")
        className = "HiddenDiv";
      else
        className = "RatingDiv" + " Rating5_" + value;
    }
    if(colum.ControlType==="Rating6"){
      if(value==="0")
        className = "HiddenDiv";
      else
        className = "RatingDiv" + " Rating6_" + value;
    }
    if(colum.ControlType==="Rating10"){
      if(value==="0")
        className = "HiddenDiv";
      else
        className = "RatingDiv" + " Rating10_" + value;
    }
    className = "truncate-cell " + className;
    return className;
  }

}

function byKey(object, key) {
  return object.Key === key;
}