import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
//import { TeamScheduleService } from '../../services/team-schedule.service';
import * as moment from 'moment';
import { CalendarDayModel, TeamScheduleViewModel } from '../../models/schedulemodels';
import { ActivatedRoute } from '@angular/router';
import { ScheduleEvent, ScheduleViewModel } from 'src/app/scouting/models/ScheduleModels';
import { TeamEventViewModel } from 'src/app/load/models/gameevent';
import { MatDialog } from '@angular/material/dialog';
import { TeamScheduleService } from 'src/app/TeamSchedule/services/team-schedule.service';
import { TeamService } from 'src/app/shared/team.service';
import { Team, TeamViewModel } from 'src/app/scouting/models/TeamModels';
import { TeamOverviewCardComponent } from 'src/app/team-overview-card/team-overview-card.component';
import { PlayerService } from 'src/app/shared/player.service';
import { Player } from 'src/app/load/models/player';
import { Season, TeamOverviewModel } from 'src/app/shared/team.model';
import { PlayerViewModel } from 'src/app/shared/models/player.model';

import { TestTeamSeason } from 'src/app/shared/models/test.model';

import { AddTeamEventDialogComponent } from 'src/app/TeamSchedule/screens/add-team-event-dialog/add-team-event-dialog.component';
import { AssignWorkoutDialogComponent } from 'src/app/training/components/assign-workout-dialog/assign-workout-dialog.component';
import { DisplayAssignedWorkoutDialogComponent } from 'src/app/training/components/display-assigned-workout-dialog/display-assigned-workout-dialog.component';
import { TeamEventDetailsDialogComponent } from '../team-event-details-dialog/team-event-details-dialog.component';


//import { AddTeamEventDialogComponent } from '../add-team-event-dialog/add-team-event-dialog.component';


@Component({
  selector: 'app-team-schedule-monthly-view',
  templateUrl: './team-schedule-monthly-view.component.html',
  styleUrls: ['./team-schedule-monthly-view.component.css']
})
export class TeamScheduleMonthlyViewComponent implements OnInit {

  @Input() TeamSelectorVisible: boolean;
  @Input() PlayerSelectorVisible: boolean;
  @Input() SelectedPlayerId: string;
  @Input() SelectedTeamId: string;

  Days: Array<Date> = [];
  Teams: Team[];
  Players: PlayerViewModel[];
  WeekIndex: Array<number> = [];
  DayActive: Array<boolean> = [];
  DayClass: Array<string> = [];
  daysOfMonth = [];
  schedule: ScheduleViewModel;
  _seasons: Season[];
  currentYear: number;
  currentMonth: number;
  isLoading: boolean = false;
  TeamId: string;
  PlayerId: string;
  daysbeforeStartOfMonth: number;

  constructor(private scheduleService: TeamScheduleService,
              private teamService: TeamService,
              private playerService: PlayerService,
              private _activatedRoute: ActivatedRoute, 
              public dialog: MatDialog,  
              public ref: ChangeDetectorRef) {

     
   }

  ngOnInit() {

    var now = new Date();
    this.currentYear = now.getFullYear();
    this.currentMonth = now.getMonth();

    this._activatedRoute.params.subscribe(params => {
      this.TeamId = params["TeamId"];
      
    
      this.teamService.getSeasons().subscribe(data=>{
        this._seasons = data as Season[]; 

        this.teamService.getTeams().subscribe(data=>{
          this.getScheduleData(this.TeamId);   
            
          this.playerService.getPlayersByTeamAndSeasonSimple(this.TeamId, this._seasons[this._seasons.length-1].SeasonId.toString()).subscribe(data=>{
            //console.log(data);
            this.PlayerId='';
            var team = data as TeamOverviewModel;
            this.Players = team.Players;
          });
        });
      });
   });
    

  }

  openAssignWorkoutDialog(date: Date){
    console.log(moment(date).format('YYYY-MM-DD[T]HH:mm:ss'));
    let dialogRef = this.dialog.open(AssignWorkoutDialogComponent,{
      data: {TeamId: this.TeamId, PlannedDate: moment(date).format('YYYY-MM-DD[T]HH:mm:ss') }
    }
  );

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    
    this.reload();
    
    this.ref.reattach(); //turn change detection back on

    
  });

  }

  reload(){
    if(this.PlayerId==''){
      this.getScheduleData(this.TeamId)
    }else{
      this.getScheduleDataPlayer(this.PlayerId);
    }
  }

  reloadSchedule(event){
    //console.log(event);
    this.getScheduleData(event.value);
    //get players in team
    this.playerService.getPlayersByTeamAndSeasonSimple(event.value, this._seasons[this._seasons.length-1].SeasonId.toString()).subscribe(data=>{
      console.log(data);
      this.PlayerId='';
      var team = data as TeamOverviewModel;
      this.Players = team.Players;
    });
  }

  reloadSchedulePlayer(event){
    console.log(event);
    this.PlayerId=event.value;
    if(event.value!=''){
      this.PlayerId=event.value;
      this.getScheduleDataPlayer(event.value);
    }
    else{
      this.PlayerId='';
      this.getScheduleData(this.TeamId);
    }
      
    
  }
  

  getScheduleData(TeamId: string){
    this.isLoading=true;
    //console.log(this.currentYear + '-' + (this.currentMonth+1));
    this.scheduleService.getSchedule(TeamId, this.currentYear, this.currentMonth+1).subscribe(data=>{
        this.schedule = data;

        let a = moment(data.StartDateForMonthView);
        let b = moment(data.EndDateForMonthView);
        let tempDate = a;
        let daysCount = b.diff(a, 'days');
        let startofmonth = moment(this.currentYear + "-" + (this.currentMonth+1) + '-01','YYYY-MM-DD');
        this.daysbeforeStartOfMonth = startofmonth.diff(a, 'days');
        //console.log(a);
        //console.log(startofmonth);
        //console.log(this.daysbeforeStartOfMonth);
        this.Days = [];
        this.DayClass = [];
        //console.log(data);
     
        for(let i= 0;i<=daysCount ;i++){
          
          this.Days.push(tempDate.toDate());  

          var tmpClass ="calendar-day ";
          if(tempDate<moment(data.StartDateForMonth)|| tempDate>moment(data.EndDateForMonth)){
            tmpClass +=" calendar-day-outofrange ";
          }
          if(tempDate.isSame(moment().toDate())){
            tmpClass +=" calendar-day-today ";
          }

          this.DayClass.push(tmpClass);
          
          
          tempDate =tempDate.add(1, 'day');
          
        }
        //console.log(this.Days);

        //console.log(this.schedule.EventList);
        this.isLoading=false;

    });
  }

  getScheduleDataPlayer(PlayerId: string){
    this.isLoading=true;
    //console.log(this.currentYear + '-' + (this.currentMonth+1));
    this.scheduleService.getPlayerSchedule(PlayerId, this.currentYear, this.currentMonth+1).subscribe(data=>{
        this.schedule = data;

        let a = moment(data.StartDateForMonthView);
        let b = moment(data.EndDateForMonthView);
        let tempDate = a;
        let daysCount = b.diff(a, 'days');
        let startofmonth = moment(this.currentYear + "-" + (this.currentMonth+1) + '-01','YYYY-MM-DD');
        this.daysbeforeStartOfMonth = startofmonth.diff(a, 'days');
        //console.log(a);
        //console.log(startofmonth);
        //console.log(this.daysbeforeStartOfMonth);
        this.Days = [];
        this.DayClass = [];
        //console.log(data);
     
        for(let i= 0;i<=daysCount ;i++){
          
          this.Days.push(tempDate.toDate());  

          var tmpClass ="calendar-day ";
          if(tempDate<moment(data.StartDateForMonth)|| tempDate>moment(data.EndDateForMonth)){
            tmpClass +=" calendar-day-outofrange ";
          }
          if(tempDate.isSame(moment().toDate())){
            tmpClass +=" calendar-day-today ";
          }

          this.DayClass.push(tmpClass);
          
          
          tempDate =tempDate.add(1, 'day');
          
        }
        //console.log(this.Days);

        //console.log(this.schedule.EventList);
        this.isLoading=false;

    });
  }

  getDay1(){

    return this.getDay(new Date());
  }

  getDay(daytoget: Date):Array<CalendarDayModel>{
    /* console.log(this.schedule.EventList.filter( event=>{
      return moment(event.EventStart).isSame(moment(day), 'day');
    })); */
    //console.log("getday");
    return this.schedule.Days.filter( day=>{                
      //console.log('1:' + moment(day.CalendarDate).toDate());
      //console.log('2:' + moment(daytoget).toDate());      
      //if(moment(day.CalendarDate).isSame(moment(daytoget),'day')) console.log("day found");
      return moment(day.CalendarDate).isSame(moment(daytoget),'day');      
    });
  }



  nextMonth(){
    var m =  moment().set({'year': this.currentYear, 'month': this.currentMonth, 'date': 1}).add(1, 'months');

    this.currentYear = m.year();
    this.currentMonth = m.month();
    console.log(this.PlayerId);
    if(this.PlayerId=='')
      this.getScheduleData(this.TeamId);
    else
      this.getScheduleDataPlayer(this.PlayerId);
  }

  previousMonth(){
    var m =  moment().set({'year': this.currentYear, 'month': this.currentMonth, 'date': 1}).subtract(1, 'months');
    console.log(m);
    this.currentYear = m.year();
    this.currentMonth = m.month();
    console.log(this.PlayerId);
    if(this.PlayerId=='')
      this.getScheduleData(this.TeamId);
    else
      this.getScheduleDataPlayer(this.PlayerId);

  }

 openAddEventDialog(date: Date): void {
    //console.log("Add dialog");
    //console.log(this.TeamId);

    this.ref.detach(); //Turn off calender change detection, performance improvment

    let dialogRef = this.dialog.open(AddTeamEventDialogComponent,{
      width: '680px',
        data: {TeamId: this.TeamId, EventId: '', PlannedDate: moment(date).format('YYYY-MM-DD[T]HH:mm:ss')}
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      
      this.reload();
      this.ref.reattach(); //turn change detection back on
      
    });    
  }

  openTeamEventDetails(EventId: string){
    this.ref.detach(); //Turn off calender change detection, performance improvment

    let dialogRef = this.dialog.open(TeamEventDetailsDialogComponent,{
      width: '680px',
        data: { EventId: EventId}
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      
      this.reload();
      this.ref.reattach(); //turn change detection back on
      
    });    
  }

  
 /* 
  openForEditDialog(EventId: string): void {
    //console.log("Add dialog");
    //console.log(this.teams);
    this.ref.detach(); //Turn off calender change detection, performance improvment
    let dialogRef = this.dialog.open(AddTeamEventDialogComponent,{
        data: {TeamId: this.TeamId, EventId: EventId}
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.ref.reattach(); //turn change detection back on
      this.getScheduleData();
      
      
      
    });

        
  } */

  openDisplayWorkoutDialog(assignedWorkout){
    var TeamName: string='';
    var TeamLevel: string='';
    var obj;

    /* console.log(assignedWorkout);
    if(assignedWorkout.AssignedProgram!=null)
      obj = this.Teams.find(o => o.TeamId === assignedWorkout.AssignedProgram.TeamId);
    else if(assignedWorkout.TeamId!=null)
      obj=this.Teams.find(o => o.TeamId === assignedWorkout.TeamId); */

    TeamLevel = assignedWorkout.PlannedFor;
   
    //console.log(TeamLevel);


    const dialogRef = this.dialog.open(DisplayAssignedWorkoutDialogComponent, {
      width: '550px',
      data: assignedWorkout
      
    });

    dialogRef.afterClosed().subscribe(result => {
      this.reload();
      if(result) {
       
        //Notify about delete
        
      }
    });
  
  }

  openForEditDialog(EventId: string): void {
    //console.log("Add dialog");
    //console.log(this.teams);
    this.ref.detach(); //Turn off calender change detection, performance improvment
    let dialogRef = this.dialog.open(AddTeamEventDialogComponent,{
      width: '680px',

        data: {TeamId: this.TeamId, EventId: EventId}
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      this.ref.reattach(); //turn change detection back on
      this.reload();
    }); 
  }

  formatTimeSpan(time: string): string{

    return moment(time, 'HH:mm:ss').format("HH:mm");
  }

}

