import { DateAdapter } from '@angular/material/core';
/* import { NUMBER_TYPE } from '@angular/compiler/src/output/output_ast'; */
import { PlayerPosition } from './player.model';


export class Test {
    TestId: number;
    OrganizationId: number;
    Name: string;
    Description: string;
    ResultUnit: string;
    ResultType: number;
    Operator: string;
    Validation: string;
    Global: boolean;


}

export class TestViewModel extends Test{
    TestResults: TestResultPlayer[];
    TopList: TestTopList;
    Limit: TestProfileTestLimit;
    LimitOK_SeriesData: TestResultPlayer[];
    LimitGood_SeriesData: TestResultPlayer[];
    LimitVeryGood_SeriesData: TestResultPlayer[];
    LimitExcellent_SeriesData: TestResultPlayer[];
    Statistics: TestResultStatistics;
    
}


export class TestResultStatistics{
    Average : number;
    AverageText : string;
    Median : number;
    MedianText: string;
    Min : number;
    Max : number;
    Stdev : number;
}

export class TestProfile{
    ProfileId: string;
    OrganizationId: number;
    Name: string;
    MaxPointsCalculated: number;
    Tests?: Test[];
    TestsUpdate?: String[];
    TestLimits: TestProfileTestLimit[];
}

export class TestProfile_Update{
    ProfileId: string;
    OrganizationId: number;
    Name: string;
    TestsUpdate?: String[];
}

export class TestProfileTest{
    ProfileId: string;
    TestId: number;
}



export class TestProfileTestLimit{
    ProfileId: string;
    Name: string;
    TestId: number;
    Operator: string;
    LimitOK: string;
    LimitGood: string;
    LimitVeryGood: string;
    LimitExcellent: string;
    ResultUnit: string;
    ResultType: number;
    ValidationLimit: string;
    ValidationResult: string;

    LimitMultiplierTestName: string;
    LimitMultiplierTestId: number;

    IncludeInTotalRanking: boolean;
    OrderInProfile: number;

}

export class TestProfileViewModel
{
    ProfileId: string;        
    OrganizationId: number;
    Name: string;
    MaxPointsCalculated: number;
    Tests: Test[];
    TestLimits: TestProfileTestLimit[];
}

export class TestResultPlayer{
    PlayerId: string;
    ResultId: string;
    TestId: number;
    TestName: string;
    PerformedDate: Date;
    ResultType: number;
    ResultUnit: string;
    ResultInt: number;
    ResultDecimal: number;
    ResultTime: Date;
    ResultText: string;

    Ranking: number;
    Trend: string;
    PlayerName: string;
    AgeYearsDecimal: number;
    IsPB: boolean;

    WARN_Result_Not_Current: boolean;

    Limit: TestProfileTestLimit;

    BestResult: string;
    BestResultDate: Date;
    
    
    

}

export class TestResultPlayer_Update extends TestResultPlayer{
    
    constructor(_test: Test, _playerId: string){
        super();
        this.TestId = _test.TestId;
        this.TestName = _test.Name;
        this.ResultType = _test.ResultType;
        this.ResultUnit = _test.ResultUnit;
        this.PlayerId = _playerId;
        this.PerformedDate  = new Date();

    }


    Result: string;
    
    Ranking: number;

}

export class TestTopList{
    TestId: number;
    TestName: string;
    Items: TestTopListItem[];

}

export class TestTopListItem{
    Position: number;
    PlayerId: string;
    PlayerLastName: string;
    PlayerFirstName: string;
    JerseyNo: number;
    Result: string;
    PerformedDate: Date;
    ProfileImage: string;
}


export class TestTeamSeason{
        
    TeamId: string;
    SeasonId : number;
    ClubId : number;
    LevelName : string;
    NoOfPlayers : number;
    Name : string;
            

}

export class TestView
    {
        TestViewId: string;
        OrganizationId: string;
        Name: string;
        DisplayName: string;

    }

    export class TestViewViewModel extends TestView
    {

        Profiles: TestViewProfilesViewModel[];
        Users: TestViewTeamUserViewModel[];
        Teams: TestViewTeamViewModel[];


    }
    

    export class TestViewProfiles
    {
        TestViewId: string;
        ProfileId: string;
        PositionId: number;

    }

    export class TestViewTeamViewModel{
        TestViewId: string;
        TeamId: string;
        Enabled: boolean;
        VisibleForAll: boolean;
        Users: TestViewTeamUserViewModel[];
    }

    export class TestViewProfilesViewModel extends TestViewProfiles
    {

        Profile: TestProfile;
        PositionLanguage: PlayerPosition;

    }

    export class TestViewTeam
    {
        TestViewId: string;
        TeamId: string;
        Enabled: boolean;
        VisibleForAll: boolean;
    }

    export class TestViewTeamUser
    {
        TestViewId: string;
        TeamId: string;
        UserId: string;
    }

    export class TestViewTeamUserViewModel extends TestViewTeamUser
    {      

        FirstName: string;
        LastName: string;

    }

    export class TestResultKPIViewModel{
        NoOfTestsPerformed: number;
        Ranking1_Count: number;
        Ranking2_Count: number;
        Ranking3_Count: number;
        Ranking4_Count: number;
        Ranking5_Count: number;
        NoOfTestsOutdated: number;
        NotRanked_Count: number;
    
    }

