import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import * as moment from 'moment';
import { LoadEventService } from '../services/load-event.service';
declare var google:any;

@Component({
  selector: 'app-team-loadlevel-chart',
  templateUrl: './team-loadlevel-chart.component.html',
  styleUrls: ['./team-loadlevel-chart.component.css']
})
export class TeamLoadlevelChartComponent implements OnInit {

  constructor( private eventService: LoadEventService ) {}

  @Input() TeamId: string;
  @Input() Period: string;
  @Input() SeasonStartMonth: number;

  ngOnInit() {
    //console.log(this.Period + ":" + this.SeasonStartMonth);
/*     switch(this.Period){
      case "3+3":
          this.generateLoadPlanChart(this.TeamId, 3,3);
        break;
      case "2+0":
        this.generateLoadPlanChart(this.TeamId, 2,0);
        break;
      case "2+1":
        this.generateLoadPlanChart(this.TeamId, 2,1);
        break;
      case "Season":
        
        break;
      default:
        this.generateLoadPlanChart(this.TeamId, 3,3); 
    } */

   
  
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    //console.log("onChange");
    switch(this.Period){
      case "3+3":
          this.generateLoadPlanChart(this.TeamId, 3,3);
        break;
      case "2+0":
        this.generateLoadPlanChart(this.TeamId, 2,0);
        break;
      case "2+1":
        this.generateLoadPlanChart(this.TeamId, 2,1);
        break;
      case "6+6":
          this.generateLoadPlanChart(this.TeamId, 6,6);
          break;
      case "12+0":
        this.generateLoadPlanChart(this.TeamId, 12,0);
        break;
      case "Season":
        this.generateLoadPlanChartBySeasons(this.TeamId,this.SeasonStartMonth,1)
        break;
      default:
        this.generateLoadPlanChart(this.TeamId, 3,3); 
    }
  }

  


generateLoadPlanChartBySeasons(_TeamId: string, _seasonStartMonth: number, _noOfSeasons: number ): void{


    this.eventService.GetEventLoadForTeamBySeason(this.TeamId).subscribe(datahttp => {
            
      google.charts.load('current', {'packages':['corechart']});
        google.charts.setOnLoadCallback(drawChart);
      function drawChart() {

        //console.log(datahttp);
        var data = new google.visualization.DataTable(datahttp);
        /* var minDate = new Date();
        var maxDate = new Date();
        minDate =  moment().subtract(_monthsPast, 'months').toDate();
        maxDate =  moment().add(_monthsComing, 'months').toDate();
       
  
          //Tcikmarks on mondays
          var tickMarks = [];          
          for (var d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
            if(d.getDay()==1){
              tickMarks.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
              console.log("Tick");
              console.log(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            }
            
            
        } */
 

        var undanfluytt  ={
          titleTextStyle: { color: 'white'},
          animation: { startup: true, duration: '1000'},
          width: '350px',
          hAxis: {
            baselineColor: 'white',
            format: 'd/M',
            textStyle: {color: 'white'},
            gridlines:{ color: '#303030'},
            //minValue: minDate,
            //maxValue: maxDate
            
          },
          vAxis: {
            gridlines:{ color: '#303030'},
            textStyle: {color: 'white'},
            baselineColor: '#303030',
            minValue: '0',
            maxValue: '2000'
            
          },
          connectSteps: false,
          backgroundColor: '#303030',
          legend: {position: 'none'},
          colors: ['#4374E0', '#53A8FB', '#F1CA3A', '#E49307','white'],
        };

        var options = {
          title: "Planned load - weekly",
          titleTextStyle: { color: 'white'},
          animation: { startup: true, duration: '1000'},
          hAxis: {
            baselineColor: 'white',
            format: 'd/M',
            textStyle: {color: 'white',fontSize: 10,},
            gridlines:{ color: '#303030'},
            //minValue: minDate,
           // maxValue: maxDate,
            //ticks: tickMarks,
            
          },
          vAxis: {
            gridlines:{ color: '#303030'},
            textStyle: {color: 'white',fontSize: 10,},
            baselineColor: '#303030',
            minValue: '0',
            maxValue: '2000'
            
          },
          connectSteps: false,
          backgroundColor: '#303030',
          legend: {position: 'none'},
          colors: ['#4374E0', '#53A8FB', '#F1CA3A', '#E49307','white'],
          isStacked: true,
          seriesType: 'steppedArea',
          series: {3: {color: '#a8a8a8', type: 'area'}}
        }; 

        /* var options = {
          title: "Belastning / vecka",
          //titleTextStyle: { color: 'white'},
          animation: { startup: true, duration: '1000'},
          hAxis: {
            //baselineColor: 'white',
            format: 'd/M',
            //textStyle: {color: 'white'},
            gridlines:{ color: '#303030'},
            minValue: minDate,
            maxValue: maxDate
            
          },
          vAxis: {
            gridlines:{ color: '#303030'},
            //textStyle: {color: 'white'},
            baselineColor: '#303030',
            minValue: '0',
            maxValue: '2000'
            
          },
          connectSteps: false,
          //backgroundColor: '#303030',
          legend: {position: 'none'},
          //colors: ['#4374E0', '#53A8FB', '#F1CA3A', '#E49307','white'],
          colors: ['blue', 'lightblue', 'yellow', '#dbb427','white'],
          isStacked: true,
          seriesType: 'steppedArea',
          series: {3: {color: '#565656', type: 'area'}}
        };*/

        //console.log('4');
        //console.log(data);

        var chart = new google.visualization.ComboChart(document.getElementById('chart_plannedload_' + _TeamId));      
        chart.draw(data, options);
    }
  });
  }

  generateLoadPlanChart(_TeamId: string, _monthsPast: number, _monthsComing: number ): void{


    this.eventService.GetEventLoadForTeam(this.TeamId, _monthsPast.toString(),  _monthsComing.toString()).subscribe(datahttp => {
            
      google.charts.load('current', {'packages':['corechart']});
        google.charts.setOnLoadCallback(drawChart);
      function drawChart() {

        //console.log(datahttp);
        var data = new google.visualization.DataTable(datahttp);
        var minDate = new Date();
        var maxDate = new Date();
        minDate =  moment().subtract(_monthsPast, 'months').toDate();
        maxDate =  moment().add(_monthsComing, 'months').toDate();
       
  
          //Tcikmarks on mondays
          var tickMarks = [];          
          for (var d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
            if(d.getDay()==1){
              tickMarks.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
              //console.log("Tick");
              //console.log(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            }
            
            
        }
 

        var undanfluytt  ={
          titleTextStyle: { color: 'white'},
          animation: { startup: true, duration: '1000'},
          width: '350px',
          hAxis: {
            baselineColor: 'white',
            format: 'd/M',
            textStyle: {color: 'white'},
            gridlines:{ color: '#303030'},
            minValue: minDate,
            maxValue: maxDate
            
          },
          vAxis: {
            gridlines:{ color: '#303030'},
            textStyle: {color: 'white'},
            baselineColor: '#303030',
            minValue: '0',
            maxValue: '2000'
            
          },
          connectSteps: false,
          backgroundColor: '#303030',
          legend: {position: 'none'},
          colors: ['#4374E0', '#53A8FB', '#F1CA3A', '#E49307','white'],
        };

        var options = {
          title: "Planned load - weekly",
          titleTextStyle: { color: 'white'},
          animation: { startup: true, duration: '1000'},
          hAxis: {
            baselineColor: 'white',
            format: 'd/M',
            textStyle: {color: 'white'},
            gridlines:{ color: '#303030'},
            minValue: minDate,
            maxValue: maxDate,
            ticks: tickMarks,
            
          },
          vAxis: {
            gridlines:{ color: '#303030'},
            textStyle: {color: 'white'},
            baselineColor: '#303030',
            minValue: '0',
            maxValue: '2000'
            
          },
          connectSteps: false,
          backgroundColor: '#303030',
          legend: {position: 'none'},
          colors: ['#c632df', '#c632df', '#F1CA3A', 'dodgerblue','purple','white'],
          isStacked: true,
          height: 200,
          seriesType: 'steppedArea',
          series: {4: {color: '#a8a8a8', type: 'area'}}
        }; 

        /* var options = {
          title: "Belastning / vecka",
          //titleTextStyle: { color: 'white'},
          animation: { startup: true, duration: '1000'},
          hAxis: {
            //baselineColor: 'white',
            format: 'd/M',
            //textStyle: {color: 'white'},
            gridlines:{ color: '#303030'},
            minValue: minDate,
            maxValue: maxDate
            
          },
          vAxis: {
            gridlines:{ color: '#303030'},
            //textStyle: {color: 'white'},
            baselineColor: '#303030',
            minValue: '0',
            maxValue: '2000'
            
          },
          connectSteps: false,
          //backgroundColor: '#303030',
          legend: {position: 'none'},
          //colors: ['#4374E0', '#53A8FB', '#F1CA3A', '#E49307','white'],
          colors: ['blue', 'lightblue', 'yellow', '#dbb427','white'],
          isStacked: true,
          seriesType: 'steppedArea',
          series: {3: {color: '#565656', type: 'area'}}
        };*/

        //console.log('4');
        //console.log(data);

        var chart = new google.visualization.ComboChart(document.getElementById('chart_plannedload_' + _TeamId));
        

        chart.draw(data, options);
    }
  });
  }

}
