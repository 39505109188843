import { CommonModule } from '@angular/common';
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";

import { ConfigurationLoaderService } from "./configuration-loader.service";


export function loadConfiguration(configService: ConfigurationLoaderService) {
  return () => configService.loadConfiguration();
}

@NgModule({ declarations: [], imports: [], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfiguration,
            deps: [ConfigurationLoaderService],
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppConfigurationModule { 

  
}
