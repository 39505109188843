<mat-card appearance="outlined" style="max-width: 500px;  margin: 10px;color: white;" [ngClass]="setClass()">
    
    <table>
        <tr>
            <td style="width: 32px;">  <mat-icon style="font-size: 30px;">person</mat-icon>
            </td>
            <td>
                    
                        <div style="font-size: 16px;">{{this.Report.CreatedByLastName}}, {{this.Report.CreatedByFirstName}}

                        </div>
                        <div style="color: darkgray;font-size: 12px; ">{{this.Report.DateCreated | date:'yyyy-MM-dd HH:mm' }}</div>
                   
                    
                                      
            </td>
        </tr>
    </table>  
<mat-card-header>
    <!-- <img mat-card-avatar  [src]="'../../assets/img/' + this.Report.PlayerCurrentTeamIcon"     > -->
  


</mat-card-header>
<mat-card-content style="margin-bottom: 0px;" >
    <mat-divider></mat-divider>
        
        
    <div style="font-size: 12px; padding: 5px; white-space:pre-line;color: white;">
        {{this.Report.Text}}
    </div>
    <mat-divider></mat-divider>
    <app-report-overall-game [GameText]="this.Report.GameText" [GameDivision]="this.Report.ReportDivision"
        [GamePerformanceText]="this.Report.GamePerformanceText" [GamePerformance]="this.Report.GamePerformance"
        [ScoutingSourceText]="this.Report.ScoutingSourceText"
        [Position]="this.Report.PositionText"></app-report-overall-game>
    <mat-divider></mat-divider>
    <app-report-overall-grades [TotalQualityText]="this.Report.TotalQualityText" [TotalQuality]="this.Report.TotalQuality"
        [PlayerType]="this.Report.PlayerType" [PlayerTypeText]="this.Report.PlayerTypeText"
        [CapacityText]="this.Report.PlayerCapacityText" [Capacity]="this.Report.PlayerCapacity"></app-report-overall-grades>
    <mat-divider></mat-divider>
    <app-report-overall-skillsgroup [SkillsGroups]="this.Report.SkillGroups"></app-report-overall-skillsgroup>
    <div
        style="font-size: x-small;  text-align: left; vertical-align: top;  padding: 0; white-space: normal; margin: 0; color: white;  ">
        <table>
           
            <tr >
                <td >
                    <mat-icon style="color: orange; font-size: 18px;"> cake</mat-icon>
                </td>
                <td>
                    <div style="font-size: 10px;">
                        {{this.Report.PlayerAgeYear}} years {{this.Report.PlayerAgeMonth}} months
                    </div>
                </td>
                <td *ngIf="this.Report.GameText">  
                  
                        <mat-icon style="color:orange;font-size: 18px;">sports_hockey</mat-icon>
                       
                  
                </td>
                <td *ngIf="this.Report.GameText">  
                    <div style="font-size: 10px;">
                        {{this.Report.GameText}}     <br>
                        <span>{{this.Report.ReportDivision}}</span>  
                    </div>
                </td>
                <td *ngIf="this.Report.GameText">
                    <mat-icon style="color: orange; font-size: 18px;"> location_on</mat-icon>
                </td>
                <td *ngIf="this.Report.GameText">
                    <div style="font-size: 10px;">
                        {{this.Report.Location}}
                    </div>
                </td>
               
            </tr>
           
        </table>    
      
       
        
    </div>


    <mat-divider></mat-divider>
  <!--   <app-report-view-skills [SkillGroups]="this.Report.SkillGroups"></app-report-view-skills> -->



</mat-card-content>
<mat-card-actions>
        <!-- <button mat-button  (click)="openViewReport(this.Report.ReportId)" >
           
           VIEW
        </button> -->
    <!--  <button mat-icon-button  (click)="openViewReport(this.Report.ReportId)" >
            <mat-icon style="font-size: 24px; opacity: 0.6;">
                preview
            </mat-icon>
           
        </button> -->
    
    <button mat-icon-button *ngIf="this.Report.IsCreatedByCurrentUser" [routerLink]="['/scouting/editreport', this.Report.ReportId]" >
        <mat-icon style="font-size: 18px; opacity: 0.6;">
                edit
        </mat-icon>   
      
    </button>

    <!-- <button mat-icon-button style="opacity: 0.6;" (click)="gotoPlayerDetails(this.Report.PlayerId)" ><img style="height: 24px;"   src="../../assets/img/icons8-user-shield-48.png"></button>
     -->
    <a *ngIf="this.Report.GameId != null && this.Report.GameId > '00000000-0000-0000-0000-000000000000'" mat-icon-button
        type="button" [routerLink]="['/scouting/gamedetails', this.Report.GameId ]">
        <mat-icon style="font-size: 18px; opacity: 0.6;">
            sports_hockey
        </mat-icon>     
    </a>
    <!-- <button mat-button style="opacity: 0.6;" (click)="gotoPlayerDetails()" ><img style="height: 24px;"   src="../../assets/img/icons8-user-shield-48.png">Player</button>
    <button mat-button style="opacity: 0.6;" (click)="gotoPlayerDashboard()" ><img style="height: 24px;"   src="../../assets/img/icons8-user-shield-48.png">Player</button>
    <button mat-button style="opacity: 0.6;" >
            <mat-icon style="font-size: 18px; opacity: 0.6;">sports_hockey</mat-icon>
        Game
    </button> -->
</mat-card-actions>
</mat-card> 


