import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TestListComponent } from './test-list/test-list.component';
import { TestCardComponent } from './test-card/test-card.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { TestToplistComponent } from './test-toplist/test-toplist.component';
import { TestAddComponent } from './test-add/test-add.component';
import { TestEditorComponent } from './test-editor/test-editor.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TestTableComponent } from './test-table/test-table.component';
import { TestTopListsComponent } from './test-top-lists/test-top-lists.component';
import { TeamTestTopListsComponent } from './team-test-top-lists/team-test-top-lists.component';
import { TestTopListCardComponent } from './test-top-list-card/test-top-list-card.component';
import { OrganizationTestToplistsComponent } from './organization-test-toplists/organization-test-toplists.component';
import { TestNavigationComponent } from './test-navigation/test-navigation.component';
import { TestViewPlayerCardsComponent } from '../test-result/test-view-player-cards/test-view-player-cards.component';


@NgModule({
    declarations: [
        TestListComponent,
        TestCardComponent,
        TestToplistComponent,
        TestAddComponent,
        TestEditorComponent,
        TestTableComponent,
        TestTopListsComponent,
        TeamTestTopListsComponent,
        TestTopListCardComponent, OrganizationTestToplistsComponent, TestNavigationComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModuleModule,
        ReactiveFormsModule,
    ]
})
export class TestModule { }
