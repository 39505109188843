 <mat-card appearance="outlined" style="max-width: 325px; margin-bottom: 10px; min-width: 325px; margin-right: 10px;">
    <mat-card-title>
        Värva en center
    </mat-card-title>
    <mat-card-content>
        dfsdffsa fsa f
    </mat-card-content>
    <mat-card-actions>
        <button mat-button>VIEW</button> 
    </mat-card-actions>
</mat-card>
        