import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgramModel } from 'src/app/configuration/training/models/exercise.model';
import { TrainingService } from 'src/app/configuration/training/services/training.service';
import { TeamViewModel } from 'src/app/scouting/models/TeamModels';
import { SelectMultiplePlayerorgDialogComponent } from 'src/app/shared/components/select-multiple-playerorg-dialog/select-multiple-playerorg-dialog.component';
import { PlayerService } from 'src/app/shared/player.service';
import { AssignedProgramService } from '../../service/assigned-program.service';
import { Observable } from 'rxjs';
import { TeamEventViewModel } from 'src/app/load/models/gameevent';
import { PlayerListViewModel } from 'src/app/scouting/models/PlayerModels';
import { ActivatedRoute } from '@angular/router';
import { NumericLiteral } from 'typescript';
import { TeamService } from 'src/app/shared/team.service';


@Component({
  selector: 'app-new-assigned-program',
  templateUrl: './new-assigned-program.component.html',
  styleUrls: ['./new-assigned-program.component.css']
})
export class NewAssignedProgramComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  playerCtrl = new UntypedFormControl('');
  filteredPlayers: Observable<any[]>;
  players: PlayerListViewModel[] = [];
  allPlayers: PlayerListViewModel[] = [];
  assignedPgmForm: UntypedFormGroup;
  programs: ProgramModel[];
  teams: TeamViewModel[];
  @Input() ProgramId;
  _players: PlayerListViewModel[];

  @ViewChild('playerInput') playerInput: ElementRef<HTMLInputElement>;

  constructor(
    private _trainingSrvc: TrainingService,
    private _assignedPgmService: AssignedProgramService,
    public _snackBar: MatSnackBar,
    public route: ActivatedRoute,
    private _location: Location,
    public fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private teamService: TeamService,
    private playerSrv: PlayerService
    ) {

    /*   this.filteredPlayers = this.playerCtrl.valueChanges.pipe(
        startWith(null),
        map((player: PlayerListViewModel | null) => (player ? this._filter(player) : this.allPlayers.slice())),
      ); */

      this.filteredPlayers = this.playerCtrl.valueChanges.pipe(
            startWith(null),
            map(term => this._filter(term)));
  }

  findOption(val: string) {
    console.log('findoption');
    return this.allPlayers.filter((s) => new RegExp(val, 'gi').test(s.Player.LastName));
  }



  ngOnInit(): void {
   
    this.ProgramId = this.route.snapshot.paramMap.get('ProgramId');
    
    this.playerSrv.getAllPlayersInCurrentSeason().subscribe(players=>{
      this.players = players as PlayerListViewModel[];
    
    });    

    this.assignedPgmForm = this.fb.group({
      ProgramId: +this.ProgramId,
      Title: ['',[Validators.required, Validators.maxLength(100)]],
      Description: '',
      RepeatProgramCount: [1,[Validators.required, Validators.max(30), Validators.min(1)]],
      StartDate: ['', [Validators.required]],
      TeamId: '',
      AssignedPlayers: this.fb.array([])      
    })

    this.teamService.getTeams().subscribe((data: any) =>{
      this.teams = data;
    });

    this._trainingSrvc.getActivePrograms().subscribe(result=>{
      this.programs = result as ProgramModel[];
   
    });
  }



  get assignedPlayers(): UntypedFormArray{
    return this.assignedPgmForm.get('AssignedPlayers') as UntypedFormArray;
  }




  addPlayer(player){
    this.assignedPlayers.push(this.fb.group({
      PlayerId: player.LifelineScoutingId,
      FirstName: player.FirstName,
      LastName: player.LastName
    }));
  }

  save(){

    console.log(this.assignedPgmForm.value);
    this._assignedPgmService.createAssignedProgram(this.assignedPgmForm.value).subscribe(result=>{
      if(result.Success){
        this._location.back();
      }
    });

  }

  onCancel(){
    this._location.back();
  }

  
  openAddPlayerDialog(){
    const dialogRef = this.dialog.open(SelectMultiplePlayerorgDialogComponent, {
      width: '400px',     
    });

    dialogRef.afterClosed().subscribe(players => {
      players.forEach(player => {
      /*   console.log(player);
        console.log((<FormArray>this.assignedPgmForm.get('AssignedPlayers')).controls); */
        if(this.assignedPlayers)
        var index = (<UntypedFormArray>this.assignedPgmForm.get('AssignedPlayers')).controls.findIndex(x => x.value.PlayerId === player.value.LifelineScoutingId);
        console.log('index=' + index);
        if(index==-1)
          this.addPlayer(player.value);
      });
      
      //console.log(this.assignedPlayers);
    });
  }

  add(event: MatChipInputEvent): void {    
    var pppp: PlayerListViewModel;
    pppp = JSON.parse(event.value);

    // Add our player
    if (pppp) {
      this.players.push(pppp);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.playerCtrl.setValue(null);
  }

  remove(playerId): void {
    var index = this.assignedPlayers.value.findIndex(obj=> obj.PlayerId===playerId);
    console.log(index);
    if(index>-1)
      this.assignedPlayers.removeAt(index);
    
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    //console.log(event.option.value);
   /*  this.players.push(event.option.viewValue);*/
    var pppp: PlayerListViewModel;
    //pppp = JSON.parse(event.option.value);
    pppp = event.option.value;
    this.addPlayer(pppp.Player);
    this.playerInput.nativeElement.value = ''; 
    this.playerCtrl.setValue(null);
  }

  private _filter(filterValue: string): PlayerListViewModel[] {
    /* const filterValue = value.toLowerCase(); */
    console.log('----------');
    /* console.log(filterValue); */
    //return [];
    return this.allPlayers.filter(player => player.Player.LastName.includes(filterValue));
  }

  /* startDateSelection(){
    console.log(this.assignedPgmForm.controls["start"].value);
    let start = new Date(this.assignedPgmForm.controls["start"].value);
    start.setDate(start.getDate() - start.getDay());
    this.assignedPgmForm.controls["start"].setValue(start);
  }
  endDateSelection(){
    let start = new Date(this.assignedPgmForm.controls["start"].value);
    let end = new Date(start);
    end.setDate(start.getDate() + 6); 
    console.log(end);
    this.assignedPgmForm.controls["end"].setValue(end);
  } */

}
