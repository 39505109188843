import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TestService } from 'src/app/shared/test.service';
import { Test } from 'src/app/shared/models/test.model';
import { UntypedFormGroup, UntypedFormControl, FormArray, Form } from '@angular/forms';


export class InData{
  ProfileId: string;
  TestId: string;
}

@Component({
  selector: 'app-add-test-to-profile-dialog',
  templateUrl: './add-test-to-profile-dialog.component.html',
  styleUrls: ['./add-test-to-profile-dialog.component.css']
})
export class AddTestToProfileDialogComponent implements OnInit {
  testid: string;
  _tests: Test[];
  testForm: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<AddTestToProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InData,
    public  _testService: TestService) { 

  }

  ngOnInit() {
    
    this.getAvailableTests();

    
  }

  onSubmitForm(){
    
    //return this.testForm.value;    
    
    this.dialogRef.close(this.testid);
   
  }


  getAvailableTests(){
    this._testService.getAvailableTestsForProfile(this.data.ProfileId).subscribe((tests: Test[])=>{
      this._tests = tests;
      this.testForm = new UntypedFormGroup({    
        tests: new UntypedFormControl(this._tests)
      })
    
    });
    
  }
}
