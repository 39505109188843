import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { PlayerListViewModel } from '../../models/PlayerModels';
import { PlayerTotalViewComponent } from '../../player/player-total-view/player-total-view.component';

@Component({
  selector: 'app-team-roster-player',
  templateUrl: './team-roster-player.component.html',
  styleUrls: ['./team-roster-player.component.css']
})
export class TeamRosterPlayerComponent implements OnInit {

  @Input() Player: PlayerListViewModel;
  @Input() GameId: string;
 
  constructor(public _router: Router) { }

  ngOnInit() {
    
  }

  navigate(event:any, PlayerId){
    console.log(PlayerId);
    this._router.navigate(['/scouting/playerdetails', PlayerId]);
    event.stopPropagation();
  }

  addPushed(event:any, PlayerId, GameId){
    console.log(PlayerId);
    console.log(GameId);
    event.stopPropagation();
    this._router.navigate(['/scouting/report/new', PlayerId, GameId])
    event.stopPropagation();
  }

}
