/* import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast'; */
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder, UntypedFormArray, Form, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseModel } from 'src/app/shared/models/ResponseModel';
import { textChangeRangeIsUnchanged } from 'typescript';
import { PlayerContactModel } from '../../models/PlayerModels';
import { ScoutingPlayerService } from '../../services/scouting-player.service';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-add-player-contact-dialog',
  standalone: true,
  imports: [SharedModuleModule, CommonModule, ReactiveFormsModule],
  templateUrl: './add-player-contact-dialog.component.html',
  styleUrls: ['./add-player-contact-dialog.component.css']
})
export class AddPlayerContactDialogComponent implements OnInit {

  form: UntypedFormGroup;
  isUpdate: boolean;

  constructor(     
    public _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddPlayerContactDialogComponent>,
    public fb: UntypedFormBuilder,
    private scoutingService: ScoutingPlayerService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.isUpdate= this.data.Contact != null;

    if(this.isUpdate){
      //update
      var contact = this.data.Contact as PlayerContactModel;
      this.form = this.fb.group({
        PlayerId: contact.PlayerId,
        ContactId: contact.ContactId,
        FirstName: [contact.FirstName,[Validators.required, Validators.maxLength(50)]],
        LastName: [contact.LastName,[Validators.required, Validators.maxLength(50)]],
        Relation: [contact.Relation,[Validators.maxLength(50)]],
        CompanyName: [contact.CompanyName,[Validators.maxLength(150)]],
        Email: [contact.Email,[Validators.maxLength(150)]],
        HomePhone: [contact.HomePhone,[Validators.maxLength(50)]],
        WorkPhone:[contact.WorkPhone,[Validators.maxLength(50)]],
        MobilePhone: [contact.MobilePhone,[Validators.maxLength(50)]],
        Addresses: this.fb.array([])
                  

      });   
      var addresses = this.form.get('Addresses') as UntypedFormArray;
      contact.Addresses.forEach(address=>{
        addresses.push(
          this.fb.group({            
            AddressId: address.AddressId,
            AddressType: address.AddressType,
            Address1: address.Address1,
            Address2: address.Address2,
            Address3: address.Address3,
            PostCode: address.PostCode,
            City: address.City,
            Country: address.Country
          })
        );
      });



    }else{
      //New
      this.form = this.fb.group({
        PlayerId: this.data.PlayerId,
        FirstName: ['',[Validators.required, Validators.maxLength(50)]],
        LastName: ['',[Validators.required, Validators.maxLength(50)]],
        Relation: ['',[Validators.maxLength(50)]],
        CompanyName: ['',[Validators.maxLength(150)]],
        Email: ['',[Validators.maxLength(150)]],
        HomePhone: ['',[Validators.maxLength(50)]],
        WorkPhone:['',[Validators.maxLength(50)]],
        MobilePhone: ['',[Validators.maxLength(50)]],
        Addresses: this.fb.array([this.fb.group({
          AddressId: '1',
          AddressType: '-',
          Address1: '',
          Address2: '',
          Address3: '',
          PostCode: '',
          City: '',
          Country: ''
        })
          
        ])
      });   

    }


  }

  get Addresses(): UntypedFormArray{
    //console.log(this.form.get('Addresses'));
    return this.form.get('Addresses') as UntypedFormArray;
  }

  save(){
    console.log("save");console.log(this.form.value);
    if(this.isUpdate){
      //Update
      console.log('Update');
      this.scoutingService.updatePlayerContact(this.form.value).subscribe(response =>{
        console.log(response);
        var resp = response as ResponseModel;
  
        if(resp.Success){
          this.dialogRef.close(true);
        }else{
          this.openSnackBar("Update contact failed:"  + resp.Message, 'OK');
        }
      });
    }else{
      //New
      this.scoutingService.addPlayerContact(this.form.value).subscribe(response =>{
        var resp = response as ResponseModel;
  
        if(resp.Success){
          this.dialogRef.close(true);
        }else{
          this.openSnackBar("Create contact failed:"  + resp.Message, 'OK');
        }
      });
    }
    

    

  }

  onCancel(){
    this.dialogRef.close();
    
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }


}
