import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { TestProfile, TestProfileTestLimit, Test } from 'src/app/shared/models/test.model';
import { TestService } from 'src/app/shared/test.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormArray,Validators, UntypedFormBuilder, FormControl } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { LimitEditorDialogComponent } from '../limit-editor-dialog/limit-editor-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { AddTestToProfileDialogComponent } from '../add-test-to-profile-dialog/add-test-to-profile-dialog.component';


@Component({
  selector: 'app-test-limits-editor',
  templateUrl: './test-limits-editor.component.html',
  styleUrls: ['./test-limits-editor.component.css']
})
export class TestLimitsEditorComponent implements OnInit {
  _profile: TestProfile;  
  limitsForm: UntypedFormGroup;
  _limits: UntypedFormArray;
  

  constructor(public testService: TestService,
    public activatedRoute: ActivatedRoute,
    public _location: Location,
    public _router: Router,
    public fb: UntypedFormBuilder,
    public _globals: Globals,
    public dialog: MatDialog)
  { }

  ngOnInit() {
    this._globals.titleBarText="Edit limits for profile";
    this.limitsForm = this.fb.group({        
      testlimits: this.fb.array([          
      ])
    });
    
    this.activatedRoute.params.subscribe(params => {
      let Profileid = params['ProfileId'];
      this.testService.getProfile(Profileid).subscribe((p: TestProfile) => {
        
        this._profile = p;       
        
        p.TestLimits.forEach(limit => {
         
          if(limit.Operator.toString()!=="NO"){
         
            this.TestLimits.push(this.createLimit(limit));
          }
            
        });
        this.limitsForm.setValue(p);
      });
    });
        
  }


  

  save(){
    console.log(this.limitsForm.value.testlimits);
    this.testService.updateLimits(this.limitsForm.value.testlimits).subscribe(r=>{
      console.log(r);
      this._router.navigateByUrl('/profiles');
    });


  }


  removeTest(TestId: string){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to remove the test and the related test limit from the profile? "
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log(result);
        this.testService.removeTestFromProfile(TestId, this._profile.ProfileId).subscribe(result => {
          this.ngOnInit();
        }); 
        //Notify about delete
        
      }
    });

  }
  
  addTestOpenDialog(){
    const dialogRef = this.dialog.open(AddTestToProfileDialogComponent, {
      width: '350px',
      data: { ProfileId: this._profile.ProfileId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log(result);
      
        this.testService.addTestToProfile(this._profile.ProfileId,result).subscribe(ret => {
   
          this.ngOnInit();
      
        });
      }

     });
  }
    
  addTestLimit(limit: TestProfileTestLimit): void{

     this._limits = this.limitsForm.get('testlimits') as UntypedFormArray;

     this._limits.push(this.createLimit(limit));
        
      
  };

  get TestLimits(): UntypedFormArray{
    return this.limitsForm.get('testlimits') as UntypedFormArray;
  }

   
  createLimit(limit: TestProfileTestLimit): UntypedFormGroup{
    var ValidationRegEx: string;
   
    ValidationRegEx = limit.ValidationLimit;
   
    return this.fb.group({
      ProfileId: limit.ProfileId,
      TestId: limit.TestId,
      Name: limit.Name,
      LimitOK: [limit.LimitOK, [Validators.required,Validators.pattern(ValidationRegEx)]],
      LimitGood: [limit.LimitGood, Validators.pattern(ValidationRegEx)],
      LimitVeryGood: [limit.LimitVeryGood, Validators.pattern(ValidationRegEx)],
      LimitExcellent:[limit.LimitExcellent, Validators.pattern(ValidationRegEx)],
      ResultUnit: limit.ResultUnit,
      ResultType: limit.ResultType,
      Validation: limit.ValidationLimit,
      LimitMultiplierTestId: limit.LimitMultiplierTestId,
      IncludeInTotalRanking: limit.IncludeInTotalRanking,
      OrderInProfile: [limit.OrderInProfile, Validators.pattern(/^\d+$/)]

      
    });
  }


  back(){
    this._location.back();
  }

  settingsClick(profileId, testId, TestName){
    var limit: TestProfileTestLimit;
    limit = new TestProfileTestLimit();
    limit.TestId = testId;
    limit.ProfileId = profileId;
    limit.Name = TestName;

    const dialogRef = this.dialog.open(LimitEditorDialogComponent, {
      width: '350px',
      data: limit
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    
    });
    
  }
}

  
  
