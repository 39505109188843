import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import moment, { months } from 'moment';
import { PlayerService } from 'src/app/shared/player.service';
import { Season } from 'src/app/shared/team.model';
declare var google:any;

@Component({
  selector: 'app-player-load-chart',
  templateUrl: './player-load-chart.component.html',
  styleUrls: ['./player-load-chart.component.css']
})
export class PlayerLoadChartComponent implements OnInit {

  @Input() PlayerId: string;
  @Input() Season: Season;
  

  constructor(private playerService: PlayerService ) { }

  

  ngOnInit(): void {
    //this.getPlayerLoadChartData(this.PlayerId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.getPlayerLoadChartData(this.PlayerId);
    
  }

  getPlayerLoadChartData(_playerid: string): void{

    this.playerService.getWeeklyLoadBySeason_GoogleCharts(_playerid, this.Season.SeasonId.toString()).subscribe(datahttp => {
      //this.datajson = datahttp;

     
      google.charts.load('current', {'packages':['bar','corechart']});
      google.charts.setOnLoadCallback(drawChart.bind(this));        

      //console.log(datahttp);
      function drawChart() {

       // Create the data table.
       var data = new google.visualization.DataTable(datahttp);
      
         
        //Set minDate
        var minDate = new Date(this.Season.SeasonStartDate);
        //minDate.setMonth(minDate.getMonth()-2);

        //Tcikmarks on mondays
        var tickMarks = [];
        var maxDate = new Date(this.Season.SeasonEndDate);
        for (var d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
          
          if(d.getDay()==1){                                            
            tickMarks.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            //console.log(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
          }
           
           
         }

       

     /*  var options = {
        chartArea: {width: '95%'},            
         titleTextStyle: {color: 'white'},
         legend: { position: 'none' },
         height: 180,
         backgroundColor: '#303030',
         colors: [ 'white'],
         hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: minDate, maxValue: maxDate, ticks: tickMarks, format: 'd/M'},
         vAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: 0 },
         isStacked: true,
         
         

       };  */

       var options = {
        title: "Load - planned weekly",
        chartArea: {width: '95%'}, 
        titleTextStyle: { color: 'white'},
        animation: { startup: true, duration: '1000'},
        hAxis: {
          baselineColor: 'white',
          format: 'd/M',
          textStyle: {color: 'white'},
          gridlines:{ color: 'gray'},
          minValue: minDate,
          maxValue: maxDate,
          ticks: tickMarks,
          
        },
        vAxis: {
          gridlines:{ color: '#303030'},
          textStyle: {color: 'white',fontSize: 10,},
          baselineColor: '#303030',
          minValue: '0',
          maxValue: '2000'
          
        },
       /*  vAxis: {
          0:{
            gridlines:{ color: 'gray'},
            textStyle: {color: 'white'},
            baselineColor: '#303030',
            minValue: '0',
            maxValue: '2000'
          },
          1:{
            gridlines:{ color: 'gray'},
            textStyle: {color: 'white'},
            baselineColor: '#303030',
            minValue: '0',
            maxValue: '2000'
          }
          
          
        }, */
        connectSteps: false,
        backgroundColor: '#303030',
        legend: {position: 'none'},
        //colors: ['yellow', 'gray', 'black', 'red','blue','green', 'red'],
        //colors: ['#c632df', '#c632df', '#F1CA3A', '#E49307','white'],
        //colors: ['#dfc5e3', '#dbcfa2', '#acd4fa', '#E49307','white','#4374E0', '#53A8FB', '#F1CA3A', '#E49307'],
        colors: ['#c632df',  '#E49307','dodgerblue', 'dodgerblue','white'],
        
        //colors: ['white', '#F1CA3A', '#c632df','#c632df','#c632df'],
        isStacked: true,
        seriesType: 'steppedArea',
       
        /* series: {
          0: { targetAxisIndex: 0, },
          1: { targetAxisIndex: 1, },
          2: { targetAxisIndex: 0, },
          3: {targetAxisIndex: 1, },
          4: {targetAxisIndex: 0, },
          //3: {targetAxisIndex: 1, color: '#a8a8a8', type: 'area'},
          5: { targetAxisIndex: 1 },
          
      
        } */
        
        
      }; 

      var reported_options = {
        title: "Load - reported weekly",
        chartArea: {width: '95%'}, 
        titleTextStyle: { color: 'white'},
        animation: { startup: true, duration: '1000'},
        hAxis: {
          baselineColor: 'white',
          format: 'd/M',
          textStyle: {color: 'white'},
          gridlines:{ color: 'gray'},
          minValue: minDate,
          maxValue: maxDate,
          ticks: tickMarks,
          
        },
        vAxis: {
          minValue: 0,
          maxValue: 3000,
          textStyle: {color: 'white',fontSize: 10,},
        },
       /*  vAxis: {
          0:{
            gridlines:{ color: 'gray'},
            textStyle: {color: 'white'},
            baselineColor: '#303030',
            minValue: '0',
            maxValue: '2000'
          },
          1:{
            gridlines:{ color: 'gray'},
            textStyle: {color: 'white'},
            baselineColor: '#303030',
            minValue: '0',
            maxValue: '2000'
          }
          
          
        }, */
        connectSteps: false,
        backgroundColor: '#303030',
        legend: {position: 'none'},
        //colors: ['yellow', 'gray', 'black', 'red','blue','green', 'red'],
        //colors: ['#c632df', '#c632df', '#F1CA3A', '#E49307','white'],
        //colors: ['#4374E0', '#53A8FB', '#F1CA3A', '#E49307','white'],
        colors: ['#c632df',  '#E49307','dodgerblue', 'dodgerblue','#717171'],
        //colors: ['white', '#F1CA3A', '#c632df','#c632df','#c632df'],
        isStacked: true,
        //seriesType: 'steppedArea',
       
        /* series: {
          0: { targetAxisIndex: 0, },
          1: { targetAxisIndex: 1, },
          2: { targetAxisIndex: 0, },
          3: {targetAxisIndex: 1, },
          4: {targetAxisIndex: 0, },
          //3: {targetAxisIndex: 1, color: '#a8a8a8', type: 'area'},
          5: { targetAxisIndex: 1 },
          
      
        } */
        
        
      }; 


  var classicOptions = {
    width: 900,
    isStacked: true,
   
    series: {
      0: {
        targetAxisIndex: 0
      },
      1: {
        targetAxisIndex: 1
      },
      2: {
        targetAxisIndex: 0
      },
      3: {
        targetAxisIndex: 0
      },
      4: {
        targetAxisIndex: 1
      },
    },
    title: 'Nearby galaxies - distance on the left, brightness on the right',
    vAxes: {
      // Adds titles to each axis.
      0: {
        title: 'parsecs',
        maxValue: '2000'
      },
      1: {
        title: 'apparent magnitude',
        maxValue: '2000'
      }
    }
  };

      var materialoptions={
        colors: ['#4374E0', '#53A8FB', '#F1CA3A', '#E49307','white','#4374E0', '#53A8FB', '#F1CA3A', '#E49307'],
        isStacked: 'true',
        legend: {position: 'none'},
        backgroundColor: '#303030',
        chartArea: { backgroundColor: '#303030'},
        
        series: {          
            0: { axis: 'Planned', isStacked: 'true', color: '#dfc5e3'}, 
            1: { axis: 'Planned', isStacked: 'true', color: '#dbcfa2' },
            2: { axis: 'Planned', isStacked: 'true', color: '#acd4fa' },             
            3: { axis: 'Reported', isStacked: 'true', color: '#c632df' }, 
            4: { axis: 'Reported', isStacked: 'true', color: '#F1CA3A' },
            5: { axis: 'Reported', isStacked: 'true', color: 'dodgerblue' }, 
            6: { axis: 'Reported', isStacked: 'true', color: 'dodgerblue' } 
          },
          vAxis: {
            viewWindow: {min: 0,max:4000}, 
            textStyle: {color: 'white'},
          0:{
            gridlines:{ color: 'gray'},
            textStyle: {color: 'white'},
            baselineColor: '#303030',
           
          },
          1:{
            gridlines:{ color: 'gray'},
            textStyle: {color: 'white'},
            baselineColor: '#303030',
          
          }   
        },
        hAxis:{
          textStyle: {color: 'white'},
        },
        axes: {
          y: {              
            Reported: { label: 'Reported', 
                        gridlines:{ color: 'gray'},
                        textStyle: {color: 'white'},
                        baselineColor: '#303030',
                      }, 
            Planned: {  side: 'right',
                          label: 'Planned', 
                          gridlines:{ color: 'gray'},
                        textStyle: {color: 'white'},
                        baselineColor: '#303030',
                      } 
          }
        }
      }

    //console.log(data);
       
      var view_planned = new google.visualization.DataView(data);      
      view_planned.hideColumns([4,5,6,7,8]);      
      //console.log(view_planned);
      var chart1 = new google.visualization.ComboChart(document.getElementById('LoadChart_Planned_' + _playerid));
      chart1.draw(view_planned.toDataTable(), options);

      var view_reported = new google.visualization.DataView(data);
      view_reported.hideColumns([1,2,3]);      
      //console.log(view_reported);
      var chart2 = new google.visualization.ColumnChart(document.getElementById('LoadChart_Reported_' + _playerid));
      chart2.draw(view_reported.toDataTable(), reported_options);

      // When the table is selected, update the orgchart.
      google.visualization.events.addListener(chart2, 'select', function() {
        console.log(chart2.getSelection());
      });

     /*   var chart = new google.charts.Bar(document.getElementById('LoadChart_' + _playerid));
      chart.draw(data, google.charts.Bar.convertOptions(materialoptions));  */
      
      /* var chart1 = new google.visualization.ColumnChart(document.getElementById('LoadChart1_' + _playerid));
      chart1.draw(data, google.charts.Bar.convertOptions(options));  */
      //chart1.draw(data, classicOptions);
/*        var chart2 = new google.charts.Bar(document.getElementById('LoadChart1_' + _playerid));

        chart2.draw(data, options); */
     }
    });

   

  }

}
