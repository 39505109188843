import { Component, OnInit } from '@angular/core';
import { TestViewViewModel } from 'src/app/shared/models/test.model';

import { TestService } from 'src/app/shared/test.service';

@Component({
  selector: 'app-config-test-view-list',
  templateUrl: './config-test-view-list.component.html',
  styleUrls: ['./config-test-view-list.component.css']
})
export class ConfigTestViewListComponent implements OnInit {

  TestViews: TestViewViewModel[];

  constructor(public testService: TestService) { }

  ngOnInit(): void {

    this.testService.getAllTestViews().subscribe(data=>{
      //console.log(data);
      this.TestViews = data as TestViewViewModel[];
    });
  }

  refresh(){
    this.testService.getAllTestViews().subscribe(data=>{
      //console.log(data);
      this.TestViews = data as TestViewViewModel[];
    });
  }

}
