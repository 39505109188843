<div class="app-page" style="display: flex; flex-direction: row; justify-content: space-between; margin: auto; margin-top: 80px; margin-left: 20px;">
    <mat-form-field>
        <mat-label>Team</mat-label>
        <mat-select [(value)]="selected">
          <mat-option *ngFor="let team of teams" [value]="team.TeamId">
            {{team.Level}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
      <div>
        <app-team-player-game-grades *ngIf="selected" [TeamId]="selected" [SeasonId]="2019"></app-team-player-game-grades>
      </div>
    
    
