<div class="app-page" style="margin-left: 15px;">
    <br/>
    <mat-card appearance="outlined" STYLE="max-width: 370px; margin: 10px;">
      <mat-card-title>
        <img style="height: 24px; " src="../../assets/img/icons8-scorecard-48.png" >
          {{this._profile.Name}}
        </mat-card-title>
      <mat-card-subtitle>Limits</mat-card-subtitle>
      <mat-card-content >
    </mat-card-content>
  </mat-card>   
    
      
    <div *ngIf="this._profile" >
      
      <form [formGroup]="limitsForm" (ngSubmit)="save()">
  
        <div formArrayName="limits">
          <table>
            <ng-container *ngFor="let limit of Limits.controls; let i = index" [formGroupName]="i">
  
  
            
            <tr    >
              <td style="white-space: nowrap;  max-width: 100px; overflow: hidden; font-family: Roboto;" >
                <mat-icon > table_row </mat-icon>
                {{this._profile.Limits[i].Abbreviation}}
               
              </td>
              <td style="text-align: right; white-space: nowrap;">
                <!--  <button mat-icon-button type="button" (click)="settingsClick(this._profile.Limits[i].ProfileId, this._profile.Limits[i].TestId, this._profile.Limits[i].Name);">
                    <mat-icon style="font-size: small;">settings</mat-icon>
                  </button> -->
                <mat-form-field appearance="fill" class="smallfield" >
                    <mat-label >OK</mat-label>
                    <input matInput placeholder="" formControlName="LimitOK">                 
                  </mat-form-field>
            </td>
            <td><mat-form-field appearance="fill" class="smallfield">
                <mat-label>Good</mat-label>
                <input matInput placeholder="" formControlName="LimitGood" >                
             
              </mat-form-field></td>
            <td><mat-form-field appearance="fill" class="smallfield">
                <mat-label>Very Good</mat-label>
                <input matInput placeholder="" formControlName="LimitVeryGood">                
              
              </mat-form-field></td>
              <td>
                <mat-form-field appearance="fill" class="smallfield">
                    <mat-label>Excellent</mat-label>
                    <input matInput  placeholder="" formControlName="LimitExcellent">                                                                              
                  </mat-form-field>
            </td>     

              <td> 
                 
                <button mat-button type="button" color="warn" (click)="deleteParameter(this._profile.Limits[i])">
                   <!--  <mat-icon color="warn">delete</mat-icon> -->
                    REMOVE
                  </button></td>
            </tr>
            <tr>           
              <td colspan="2">
               
             <!--    <mat-slide-toggle color="primary" style="padding-left: 40px;" matInput formControlName="IncludeInTotalRanking">Included in score</mat-slide-toggle> -->
                
              
              </td>
              
              <td>
                
              </td>
              <!-- <td>
                <mat-form-field appearance="fill" class="smallfield">
                  <mat-label>View order</mat-label>
                  <input matInput  placeholder="" formControlName="OrderInProfile">                                  
                  
                </mat-form-field>
            
              </td> -->
             
            </tr>
        
          </ng-container>
          </table>
        </div>
        <div formArrayName="limitscalculated">
          <table>
            <ng-container *ngFor="let limit of LimitsCalculated.controls; let i = index" [formGroupName]="i">
  
  
            
            <tr    >
              <td style="white-space: nowrap;  max-width: 100px; overflow: hidden; font-family: Roboto;" >
                <mat-icon > calculate </mat-icon>
                {{this._profile.LimitsCalculated[i].Abbreviation}}
               
              </td>   
             <!--  <td style="text-align: right; white-space: nowrap;">
              
              <mat-form-field appearance="fill" class="smallselectfield">
                <mat-label>Operator</mat-label>
                <mat-select formControlName="Operator">
                  <mat-option value=">=">
                    Greater value is better
                  </mat-option>
                  <mat-option value="<=">
                    Smaller value is better
                  </mat-option>
                  <mat-option value="==">
                    Exact match
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>          -->
              <td style="text-align: right; white-space: nowrap;">
                <!--  <button mat-icon-button type="button" (click)="settingsClick(this._profile.Limits[i].ProfileId, this._profile.Limits[i].TestId, this._profile.Limits[i].Name);">
                    <mat-icon style="font-size: small;">settings</mat-icon>
                  </button> -->
                <mat-form-field appearance="fill" class="smallfield" >
                    <mat-label >OK</mat-label>
                    <input matInput placeholder="" formControlName="LimitOK">                 
                  </mat-form-field>
            </td>
            <td><mat-form-field appearance="fill" class="smallfield">
                <mat-label>Good</mat-label>
                <input matInput placeholder="" formControlName="LimitGood" >                
             
              </mat-form-field></td>
            <td><mat-form-field appearance="fill" class="smallfield">
                <mat-label>Very Good</mat-label>
                <input matInput placeholder="" formControlName="LimitVeryGood">                
              
              </mat-form-field></td>
              <td>
                <mat-form-field appearance="fill" class="smallfield">
                    <mat-label>Excellent</mat-label>
                    <input matInput  placeholder="" formControlName="LimitExcellent">                                                                              
                  </mat-form-field>
            </td>     

              <td> 
                 
                <button mat-button type="button" color="warn" (click)="deleteParameterCalculated(this._profile.LimitsCalculated[i])">
                   <!--  <mat-icon color="warn">delete</mat-icon> -->
                    REMOVE
                  </button></td>
            </tr>
            <tr>           
              <td colspan="2">
               
             <!--    <mat-slide-toggle color="primary" style="padding-left: 40px;" matInput formControlName="IncludeInTotalRanking">Included in score</mat-slide-toggle> -->
                
              
              </td>
              
              <td>
                
              </td>
              <!-- <td>
                <mat-form-field appearance="fill" class="smallfield">
                  <mat-label>View order</mat-label>
                  <input matInput  placeholder="" formControlName="OrderInProfile">                                  
                  
                </mat-form-field>
            
              </td> -->
             
            </tr>
        
          </ng-container>
          </table>
        </div>
        <button mat-raised-button color="primary" type="submit" [disabled]="!limitsForm.valid" >SAVE</button> &nbsp;
        <button mat-button  type="button" (click)="addParameterOpenDialog()"  >
          <mat-icon>add</mat-icon>
          ADD PARAMETER
        </button> &nbsp;
        <button mat-button (click)="back()" >
            <mat-icon>arrow_back</mat-icon>
          BACK
        </button>
      </form>
      
      
      
    </div>
   
    
  </div>