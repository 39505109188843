<div class="gamerank">
    <div style="display: flex; padding-top: 5px; margin-left: 10px;width: 100%; border-radius: 4px;">
        <div >
          <div style=" font-weight: bold;">
              <a routerLink="/player/details/{{event.PlayerId}}" style="color:#96bdff;text-decoration: none;">
                {{event.LastName}}  {{event.FirstName}} 
              </a>
          </div>
          <div style="font-size:x-small; font-family: Roboto;">{{event.TimeAgoText}} - ({{event.UpdatedTimestamp | date:'yyyy-MM-dd HH:mm:ss'}}) <br></div>
        </div>
    </div>
  <!--   <div style=" background: linear-gradient(to right, #888888, #303030); height: 2px;">

    </div>     -->
    <div style="margin-left: 10px;font-family: Roboto;">
        {{event.HomeTeamName + " - " + event.AwayTeamName}}
    </div>

    <div style="margin-left: 30px; margin-top: 5px; margin-bottom: 5px;  ">

        <span *ngIf="event.Ranking==0" style="font-size: small;">
        <!--     <i class="material-icons">
                highlight_off
                </i> -->
            DID NOT PLAY
        </span>
       

        <table *ngIf="event.Ranking>0">
                <tr *ngIf="event.Comment>''">
                    
                  
                        <td colspan="6" style="font-size: small;">
                              <i class="material-icons" style="color: white;" >chat_bubble_outline</i>
                            {{event.Comment}}
                        </td>
                </tr>    
            <tr>
                <td  class="bigtitlecell"><b>OVERALL</b></td>
                <td  class="valuecell" [style.background-color]="gamePerformanceColors[event.Ranking]" [style.color]="gamePerformanceTextColors[event.Ranking]">
                    {{event.Ranking}}
                </td>
                <td class="spacer"></td>
                <td colspan="3">
                <!--     <i class="material-icons" style="font-size: 18px;"> access_time </i> {{event.Duration}} min -->
                </td>
            </tr>
<!--             <tr>
                <td class="titlecell">
                        DEFENSIVE
                </td>
                <td class="valuecell" [style.background-color]="gamePerformanceColors[event.DefensiveGameRanking]"  [style.color]="gamePerformanceTextColors[event.DefensiveGameRanking]">
                        <span *ngIf="event.DefensiveGameRanking>0">{{event.DefensiveGameRanking}}   </span>            
                </td>
                <td class="spacer">
                    
                </td>
                <td class="titlecell">
                    SLEEP
                </td>
                <td class="valuecell"  [style.background-color]="scaleColors[event.Sleep]" [style.color]="scaleTextColors[event.Sleep]">
                        {{event.Sleep}}
                </td>
                <td></td>
    
            </tr>
            <tr>
                    <td class="titlecell">
                        OFFENSIVE
                    </td>
                    <td class="valuecell" [style.background-color]="gamePerformanceColors[event.OffensiveGameRanking]"  [style.color]="gamePerformanceTextColors[event.OffensiveGameRanking]">
                            <span *ngIf="event.OffensiveGameRanking>0">{{event.OffensiveGameRanking}}    </span>           
                    </td>
                    <td class="spacer">
                        
                    </td>
                    <td class="titlecell">
                        NUTRITION
                    </td>
                    <td class="valuecell" [style.background-color]="scaleColors[event.Nutrition]"  [style.color]="scaleTextColors[event.Nutrition]">
                        {{event.Nutrition}}
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td class="titlecell">
                            CONTRIBUTION
                    </td>
                    <td class="valuecell" [style.background-color]="gamePerformanceColors[event.GameContributionRanking]"  [style.color]="gamePerformanceTextColors[event.GameContributionRanking]">
                            <span *ngIf="event.GameContributionRanking>0">{{event.GameContributionRanking}}          </span>       
                        </td>
                        <td class="spacer">
                            
                        </td>
                        <td class="titlecell">
                            STRESS
                        </td>
                        <td class="valuecell" [style.background-color]="painScaleColors[event.Stress]"  [style.color]="painScaleTextColors[event.Stress]" >
                            {{event.Stress}}
                        </td>
                        <td></td>
                </tr>
                <tr>
                    <td class="titlecell">
                            FOCUS
                    </td>
                    <td class="valuecell" [style.background-color]="gamePerformanceColors[event.GameFocusRanking]"  [style.color]="gamePerformanceTextColors[event.GameFocusRanking]">
                            <span *ngIf="event.GameFocusRanking>0">{{event.GameFocusRanking}}   </span>            
                        </td>
                        <td class="spacer">
                            
                        </td>
                        <td class="titlecell">
                           
                        </td>
                        <td class="valuecell" >
                            
                        </td>
                        <td></td>
                </tr> -->
            
        </table>
        <div *ngIf="event.PlayerAssessmentAverages" style="display: flex; border-left:orange solid 1px; padding-left: 5px; margin-left: 10px; background: linear-gradient(to right, #424242, #303030);  max-width: 450px; ">

            <div style="display: flex; padding-top: 5px; margin-left: 10px;width: 100%" >
               
              <table style="font-size: x-small;">
                  <tr>
                      <td colspan="6" style="font-family: Roboto;">Pre game status (avg)</td>
                  </tr>             
                  <tr>
                      <td>
                          <mat-icon class="example-icon" >person</mat-icon>
                      </td>
                      <td style="width:32px;">
                          <mat-icon class="example-icon"  >directions_run</mat-icon>
                      </td>
                      <td style="width:32px;">
                              <mat-icon class="example-icon"  >highlight</mat-icon>
                          </td>
                      <td style="width:32px;">
                          <mat-icon class="example-icon"  >hotel</mat-icon>
                      </td>
                      
                      <td style="width:37px; ">
                              <mat-icon class="example-icon" >flash_on</mat-icon>M
                      </td>
          
                      <td style="width:37px; ">
                              <mat-icon class="example-icon" >flash_on</mat-icon>O
                      </td>
                  </tr>
                  <tr>
                      <td style="font-family: Roboto;">
                          {{ event.PlayerAssessmentAverages.NoOfPlayers }}
                      </td>
                      <td style="font-family: Roboto;">
                          {{ event.PlayerAssessmentAverages.Freshness | number:'1.2-2' }}
                      </td>
                      <td style="font-family: Roboto;">
                          {{ event.PlayerAssessmentAverages.Confidence  | number:'1.2-2'  }}
                      </td>
                      <td style="font-family: Roboto;">
                          {{ event.PlayerAssessmentAverages.Sleep | number:'1.2-2'  }}
                      </td>
                      <td style="font-family: Roboto;">
                          {{ event.PlayerAssessmentAverages.Muscles | number:'1.2-2'  }} 
                      </td>
                      <td style="font-family: Roboto;">
                          {{ event.PlayerAssessmentAverages.Joints | number:'1.2-2'  }} 
                      </td>
          
          
                  </tr>
              </table>
               
                
            </div> 
            
          </div>
              
        <div *ngIf="event.GameAssessmentGroups!=null && event.Ranking>0 " style="display: flex;">
            <div *ngFor="let group of event.GameAssessmentGroups">
                <span  style="font-family: Roboto; font-size: small;">{{group.Name}}</span>
                <table>
                    <tr *ngFor="let param of group.Parameters">
                        <td class="titlecell">
                            {{param.Name}}
                        </td>
                        <td *ngIf="!param.ReverseRanking" class="valuecell" [style.background-color]="scaleColors[param.Ranking]"  [style.color]="scaleTextColors[param.Ranking]" >
                                <span *ngIf="param.Ranking>0">{{param.Ranking}}   </span>            
                        </td>
                        <td *ngIf="param.ReverseRanking" class="valuecell" [style.background-color]="painScaleColors[param.Ranking]"  [style.color]="painScaleTextColors[param.Ranking]" >
                            <span *ngIf="param.Ranking>0">{{param.Ranking}}   </span>            
                    </td>
                </table>
                <div >
                    
                </div>
            </div>

        </div>
    </div>
</div>
