import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SkillsGroupViewModel } from '../../models/ReportModels';

@Component({
  selector: 'app-report-overall-skillsgroup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './report-overall-skillsgroup.component.html',
  styleUrl: './report-overall-skillsgroup.component.css'
})
export class ReportOverallSkillsgroupComponent {
  @Input() SkillsGroups: SkillsGroupViewModel[];

  classesRankingBox: string[] = ['grayshading','redshading','yellowshading', 'greenshading', 'lightgreenshading','blueshading' ];
  
}
