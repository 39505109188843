import { Component, OnInit } from '@angular/core';
import { TeamPerformanceService } from '../../services/team-performance.service';
import { TeamService } from 'src/app/shared/team.service';
import { Team } from 'src/app/shared/team.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-team-performance-screen',
  templateUrl: './team-performance-screen.component.html',
  styleUrls: ['./team-performance-screen.component.css']
})
export class TeamPerformanceScreenComponent implements OnInit {

  _teamId: string;
  teams: Team[];
  selected: string;
  
  constructor(
    public perfService: TeamPerformanceService,
    public teamService: TeamService,
    public _activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    /* this._activatedRoute.params.subscribe(params => {
      this._teamId = params['TeamId'];
    }); */

    this.teamService.getTeams().subscribe((data: any) =>{
      this.teams = data;
      this.selected = this.teams[0].TeamId;
      //console.log(this.selected);
    });
      
  }


}
