import { Component, Input, OnInit } from '@angular/core';
import { InjuryViewModel } from 'src/app/shared/models/injurymodel';

@Component({
  selector: 'app-player-injury',
  templateUrl: './player-injury.component.html',
  styleUrls: ['./player-injury.component.css']
})
export class PlayerInjuryComponent implements OnInit {

  @Input() Injury: InjuryViewModel;
  bordercolor: string;

  constructor() { }

  ngOnInit() {
    if(this.Injury.IsActive)
      this.bordercolor = "injury_active_div";
    else
      this.bordercolor = "injury_div";
  }

}
