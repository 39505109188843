import { PLUS_SIGN } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { AssignProgramDialogComponent } from '../../components/assign-program-dialog/assign-program-dialog.component';
import { AssignedProgramViewModel } from '../../models/AssignedProgramModels';
import { AssignedProgramService } from '../../service/assigned-program.service';

@Component({
  selector: 'app-assigned-programs',
  templateUrl: './assigned-programs.component.html',
  styleUrls: ['./assigned-programs.component.css']
})
export class AssignedProgramsComponent implements OnInit {

  programs: AssignedProgramForTable[]=[];
  dataLoaded = false;
  constructor(public dialog: MatDialog, public pgmService: AssignedProgramService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    
    this.getData();
   
  }

  displayedColumns = [
   
    'Title',
    'ProgramName',
    'NoOfWeeks',
    'NoOfWorkouts',    
    'Team',
    'NoOfPlayers', 
    'Status',
    'StartDate',
    'EndDate',
       
    'Actions'

    
    /* 'star', */
  ];
  dataSource = this.programs;

  getData(){
    var updated: AssignedProgramForTable[]=[];
    this.pgmService.getAssignedPrograms().subscribe(result=>{
      //console.log(result);
      result.forEach(pgm=>{
        var tmp = new AssignedProgramForTable();
        tmp.AssignedProgramId = pgm.AssignedProgramId,
        tmp.Title = pgm.Title;
        tmp.ProgramName = pgm.Program.Name;
        tmp.Status = pgm.Status;
        tmp.StartDate = pgm.StartDate;
        if(pgm.AssignedWorkouts.length>0)
          tmp.EndDate = pgm.EndDate;
        if(pgm.TeamId=='00000000-0000-0000-0000-000000000000')
          tmp.Team = '';
        else
          tmp.Team = pgm.LevelName;
        tmp.NoOfPlayers = pgm.AssignedPlayers.length;
        tmp.NoOfWeeks = pgm.Program.NoOfWeeks * pgm.RepeatProgramCount;        
        tmp.NoOfWorkouts = pgm.Program.NoOfWorkouts * pgm.RepeatProgramCount;        
        updated.push(tmp);
      });
      this.dataSource = updated;
      this.dataLoaded = true;
      //console.log(this.programs);
    });
  }

  openAssignProgramDialog(){
    const dialogRef = this.dialog.open(AssignProgramDialogComponent, {
      width: '450px',
      data: {},
    });
    dialogRef.afterClosed().subscribe(result => {
      
      
    });
  }

  statuscardcolor(status: string): string{
    switch(status){
      case "DRAFT":
        return "#686868";
      case "ACTIVE":
        return "green";
      case "ARCHIVED":
        return "red";
    }
  }

  delete(assignedProgramId: number, programName: string){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Are you sure you want to delete the assigned program '" + programName + "'? This cannot be undone."
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){

        this.pgmService.deleteAssignedProgram(assignedProgramId).subscribe(result=>{
          if(result.Success){
            this.openSnackBar('Program deleted','CLOSE');
            this.getData();
          }else{
            this.openSnackBar('Delete failed','CLOSE');
          }
        })

      }
    });
        
  }

  activate(assignedProgramId: number){
    this.pgmService.activateAssignedProgram(assignedProgramId).subscribe(result=>{
      if(result.Success){
        this.openSnackBar("Program activated", "CLOSE");
        this.getData();
      }
      else{
        this.openSnackBar("Activation failed", "CLOSE");        
      }
    });
  }

  archive(assignedProgramId: number, programName: string){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Are you sure you want to archive the assigned program '" + programName + "'? This cannot be undone."
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.pgmService.archiveAssignedProgram(assignedProgramId).subscribe(result=>{
          if(result.Success){
            this.openSnackBar("Program archived", "CLOSE");
            this.getData();
          }
          else{
            this.openSnackBar("Archive failed", "CLOSE");        
          }
        });
      }
    });

    
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}

export class AssignedProgramForTable {
  AssignedProgramId: number;
  ProgramName: string;
  Status: string;
  Title: string;
  NumberOfPlayers: number;
  StartDate: Date;
  EndDate: Date;
  Team: string;
  NoOfPlayers: number;
  NoOfWeeks: number;
  NoOfWorkouts: number;
  RepeatProgramCount: number;  
}



