import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-game-search',
  templateUrl: './game-search.component.html',
  styleUrls: ['./game-search.component.css']
})
export class GameSearchComponent implements OnInit {

  constructor( 
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,) { }

  range = new UntypedFormGroup({
    start: new UntypedFormControl(moment()),
    end: new UntypedFormControl(moment().add(1, 'month')),
    teamname: new UntypedFormControl()
  });

  ngOnInit(): void {
    this._adapter.setLocale('sv');  

  }

  search(){}

}
