import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { GameEventOverviewViewModel } from 'src/app/load/models/gameevent';
import { GameService } from 'src/app/load/services/game.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UserService } from 'src/app/shared/user.service';
declare var google:any;

@Component({
  selector: 'app-game-charts',
  templateUrl: './game-charts.component.html',
  styleUrls: ['./game-charts.component.css']
})
export class GameChartsComponent implements OnInit {

  
  TeamId: string;
  gameService: GameService;
  games: GameEventOverviewViewModel[];
  @Input() GameList: GameEventOverviewViewModel[];
  isBusy: boolean;

  constructor(private _gameService: GameService,
    public _route: ActivatedRoute, 
    public _userService: UserService,  
    public _router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar ) {

      this.isBusy=true;
      //this.gameService = _gameService;    

      /* this._route.params.subscribe(params => {
        this.TeamId = params['TeamId'];    
        console.log("1");
        this.LoadGames(this.TeamId) ;
      }); */
     
      

}


  ngOnInit(): void {
    this.drawGameGradeChart();
    this.drawGameWellnesChart();
  }

  /* LoadGames(TeamId: string){
    this.isBusy=true;
    this.gameService.getPastGamesForTeam(TeamId).subscribe( g => {
        this.games = g;
        console.log("2");
        console.log(this.games);
        this.drawGameGradeChart();
        this.isBusy=false;
    });
  }
 */
  drawGameGradeChart() {
   /*  console.log("jkkjk");
    console.log(this.GameList); */
    var _games : GameEventOverviewViewModel[] = this.GameList;
    
/*     var _results= this.Results;
    var _limitsOK = this.LimitsOK_Series;
    var _limitsGood = this.LimitsGood_Series;
    var _limitsVeryGood = this.LimitsVeryGood_Series;
    var _limitsExcellent = this.LimitsExcellent_Series;
    var _resultsforcomparison= this.ResultsForComparison;
    var _resType = this.ResultType;
    var _xvalue = this._xaxis;
    var _interval = this._interval;
    var _currentAge = this.CurrentAge; */
    
   
    var divname = 'chart_div';
    
     
      google.charts.load('current', {'packages':['corechart']});
      google.charts.setOnLoadCallback(function(){drawChart()});        

 
    function drawChart() {

      // Create the data table.
      var data = new google.visualization.DataTable();

      
      //console.log("3");
      //console.log(_games);
      if(_games.length==0)
        return;

     
      data.addColumn('date', 'Game date'); 
      data.addColumn('number', 'Average grade');    
      data.addColumn({type: 'string', role: 'tooltip'});

      var _data = [];
      
      _games.forEach(element => {
        var TeamName: string;
        if(element.HomeTeamId===element.MyTeamId)
          TeamName = element.AwayTeam.Name;
        else
          TeamName = element.HomeTeam.Name;


        _data.push([new Date(element.EventDate), element.OverallAvg, TeamName]);


      });
     

      
      data.addRows(_data);
     
     
    
      //console.log('Hännä2');
    

      var options;
      
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth();
      var day = d.getDate();
      var minDate = new Date(year, month, day);
      //minDate.setMonth(minDate.getMonth()-12);
      

      //e.log("mintdate" + minDate);
      //console.log('Hännä1');
      
        /* if(_LimitOK!=null){
        
        _dataOKLimit.push([new Date(minDate), _LimitOK]);
        _dataOKLimit.push([new Date(d), _LimitOK]);
        datafull = google.visualization.data.join(data, _dataOKLimit, 'full', [[0, 0]], [1], [1]);
      } */
      
      //console.log('almost dwrawing now');
      //console.log(dataevenfuller);
      options = {
      
        //chartArea: { width: this._chartWidth, height: this._chartHeight},
        legend: { position: 'none' },
        chartArea: { width: '90%'},
        //histogram: { bucketSize: 1 },
        /* chartArea:{left:'10%',width:'80%',height:'100%'}, */
        /* series: {
          2: { pointSize: '3',pointShape: 'square',lineWidth: 1},
          3: { pointSize: '3',pointShape: 'square',lineWidth: 1},
          4: { pointSize: '3',pointShape: 'square',lineWidth: 1},
          5: { pointSize: '3',pointShape: 'square',lineWidth: 1}
        }, */
        colors: ['white', '#1E90FF', '#FFC107', '#00ff00', 'limegreen', '#16DCFF'],
        title: 'Player assessments (averages)',
        titleTextStyle: { color: 'white', fontName: 'Rubik' },
        //pointSize: 5,
        //interpolateNulls: true,
        backgroundColor: '#303030',
        hAxis: { textStyle: { color: 'white', opacity: 0.3} , gridlines: { color: 'white', opacity: 0.3},  baselineColor: 'white', minValue: minDate, maxValue: d, format: 'd/M'  },
        vAxis: { textStyle: { color: 'white', opacity: 0.3} , baselineColor: 'white', opacity:0.3, minValue:1, maxValue: 5, gridlines: { count: 5 },minorGridlines:{ count:0}},
      }
      
      
      
      ////console.log("redrawing");
      var chart = new google.visualization.LineChart(document.getElementById(divname));
      chart.draw(data, options);
      }
      
      
          
  }

  drawGameWellnesChart() {
    /*  console.log("jkkjk");
     console.log(this.GameList); */
     var _games : GameEventOverviewViewModel[] = this.GameList;
     
 /*     var _results= this.Results;
     var _limitsOK = this.LimitsOK_Series;
     var _limitsGood = this.LimitsGood_Series;
     var _limitsVeryGood = this.LimitsVeryGood_Series;
     var _limitsExcellent = this.LimitsExcellent_Series;
     var _resultsforcomparison= this.ResultsForComparison;
     var _resType = this.ResultType;
     var _xvalue = this._xaxis;
     var _interval = this._interval;
     var _currentAge = this.CurrentAge; */
     
    
     var divname = 'chart_div_wellness';
     
      
       google.charts.load('current', {'packages':['corechart']});
       google.charts.setOnLoadCallback(function(){drawChart()});        
 
  
     function drawChart() {
 
       // Create the data table.
       var data = new google.visualization.DataTable();
 
       
       //console.log("3");
       //console.log(_games);
       if(_games.length==0)
         return;
 
      
       data.addColumn('date', 'Game date'); 
       data.addColumn('number', 'Freshness');
       data.addColumn('number', 'Muscle pain');
       data.addColumn('number', 'Other pain');    
      
       data.addColumn('number', 'Confidence');    
       data.addColumn('number', 'Sleep');    


 
       var _data = [];
       
       _games.forEach(element => {
         var TeamName: string;
         if(element.HomeTeamId===element.MyTeamId)
           TeamName = element.AwayTeam.Name;
         else
           TeamName = element.HomeTeam.Name;
 
 
         _data.push([new Date(element.EventDate), element.PlayerAssessmentAverages.Freshness, element.PlayerAssessmentAverages.Muscles,element.PlayerAssessmentAverages.Joints,element.PlayerAssessmentAverages.Confidence,element.PlayerAssessmentAverages.Sleep]);
 
 
       });
      
 
       
       data.addRows(_data);
      
      
     
       //console.log('Hännä2');
     
 
       var options;
       
       var d = new Date();
       var year = d.getFullYear();
       var month = d.getMonth();
       var day = d.getDate();
       var minDate = new Date(year, month, day);
       //minDate.setMonth(minDate.getMonth()-12);
       
 
       //e.log("mintdate" + minDate);
       //console.log('Hännä1');
       
         /* if(_LimitOK!=null){
         
         _dataOKLimit.push([new Date(minDate), _LimitOK]);
         _dataOKLimit.push([new Date(d), _LimitOK]);
         datafull = google.visualization.data.join(data, _dataOKLimit, 'full', [[0, 0]], [1], [1]);
       } */
       
       //console.log('almost dwrawing now');
       //console.log(dataevenfuller);
       options = {
       
         //chartArea: { width: this._chartWidth, height: this._chartHeight},
         legend: { position: 'bottom', textStyle: { color: 'white'} },
         chartArea: { width: '90%'},
         //histogram: { bucketSize: 1 },
         /* chartArea:{left:'10%',width:'80%',height:'100%'}, */
         /* series: {
           2: { pointSize: '3',pointShape: 'square',lineWidth: 1},
           3: { pointSize: '3',pointShape: 'square',lineWidth: 1},
           4: { pointSize: '3',pointShape: 'square',lineWidth: 1},
           5: { pointSize: '3',pointShape: 'square',lineWidth: 1}
         }, */
         colors: ['limegreen', '#FF5722', '#FF5722', 'white', 'green'],
         title: 'Pre game wellness (average)',
         titleTextStyle: { color: 'white', fontName: 'Rubik' },
         //pointSize: 5,
         //interpolateNulls: true,
         backgroundColor: '#303030',
         hAxis: { textStyle: { color: 'white', opacity: 0.3} , gridlines: { color: 'white', opacity: 0.3},  baselineColor: 'white', minValue: minDate, maxValue: d, format: 'd/M'  },
         vAxis: { textStyle: { color: 'white', opacity: 0.3} , baselineColor: 'white', opacity:0.3, minValue:1, maxValue: 10, gridlines: { count: 10 },minorGridlines:{ count:0}},
       }
       
       
       
       ////console.log("redrawing");
       var chart = new google.visualization.LineChart(document.getElementById(divname));
       chart.draw(data, options);
       }
       
       
           
   }

}
