<h2 mat-dialog-title>
    ADD AGENT 
  </h2>

  <mat-selection-list mat-dialog-content #agent style="height: 350px; background-color: #303030 " [multiple]="false">
    <mat-list-option *ngFor="let agent of this._agents" style="height: 40px;" [value]="agent.Agent.AgentId">
     
      <h4 mat-line>{{agent.Agent.LastName}}, {{agent.Agent.FirstName}}</h4>
      <span mat-line style="color: darkgray;">{{agent.Agent.BusinessName}}</span> 
     </mat-list-option>
    
    </mat-selection-list>
  <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="agent.selectedOptions.selected[0]?.value" cdkFocusInitial >ADD</button>      
      <button mat-button [mat-dialog-close]=""  >CANCEL</button>      
  </div>
