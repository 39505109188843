<div class="app-page">
  <mat-card appearance="outlined" style="  margin: 10px;">
      <mat-card-header>
          <img mat-card-avatar src="../../assets/img/icons8-user-shield-48.png">
          <mat-card-title>
            <div style="display: flex; justify-content: space-between; width: 10">
                <div style="display: inline-block;">Teams per season<br/>
                  
                </div>
               
            </div>
            </mat-card-title>
      </mat-card-header>
    </mat-card>
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="margin-left: 10px; ">
    <ng-container matColumnDef="Name">

      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let season"> 
        
      {{season.Name}}
     </mat-cell>
  </ng-container>
    <ng-container matColumnDef="LevelName">

      <mat-header-cell *matHeaderCellDef> LevelName </mat-header-cell>
      <mat-cell *matCellDef="let season"> 
        
      {{season.LevelName}}
     </mat-cell>
    </ng-container>

    <ng-container matColumnDef="SeasonId">

      <mat-header-cell *matHeaderCellDef> Season </mat-header-cell>
      <mat-cell *matCellDef="let season"> 
        
      {{season.SeasonId}}
     </mat-cell>       
  </ng-container>
  


  



  <ng-container matColumnDef="NoOfPlayers">

    <mat-header-cell *matHeaderCellDef> NoOfPlayers </mat-header-cell>
    <mat-cell *matCellDef="let season"> 
      
    {{season.NoOfPlayers}}
   </mat-cell>
</ng-container>    

<ng-container matColumnDef="Actions">

<mat-header-cell *matHeaderCellDef>  </mat-header-cell>
<mat-cell *matCellDef="let test"> 
  
  <button mat-icon-button type="button" (click)="alert('not implemented')"><mat-icon>delete</mat-icon></button>
</mat-cell>
</ng-container>  


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
<mat-paginator [length]="100"
[pageSize]="10" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>