import { Component, OnInit, Input } from '@angular/core';
import moment, { Moment } from 'moment';

import { FlowItemModel } from '../../models/FlowItemModel';
import { Router, RouterModule } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ReportViewDialogComponent } from '../../report-view-dialog/report-view-dialog.component';
import { ReportViewModel } from '../../models/ReportModels';
import { ScoutingService } from '../../services/scouting.service';
import { TestResultModule } from 'src/app/test-result/test-result.module';
import { CommonModule } from '@angular/common';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { ReportOverallGradesComponent } from '../report-overall-grades/report-overall-grades.component';
import { PlayerViewDialogComponent } from '../../player-view-dialog/player-view-dialog.component';
import { ReportOverallGameComponent } from '../report-overall-game/report-overall-game.component';


@Component({
  selector: 'app-report-card',
  standalone: true,
  imports: [CommonModule, SharedModuleModule, RouterModule, ReportOverallGradesComponent, ReportOverallGameComponent],
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.css']
})
export class ReportCardComponent implements OnInit {

  
  borderColors: string[] = ['grayshading','redshading','yellowshading', 'yellowshading', 'greenshading','greenshading','greenshading','greenshading' ];

  @Input() Report: FlowItemModel;

  getTimeAgo(){
    moment.locale('en');
    return moment(this.Report.DateCreated).fromNow();
  }

  constructor(public _router: Router, public matDialog: MatDialog, public scoutingService: ScoutingService) { }

  ngOnInit() {
  }

  gotoPlayerDetails(playerid: string){
    this._router.navigateByUrl('/scouting/playerdetails/' + playerid );

  }

  gotoPlayerDashboard(){
    this._router.navigateByUrl('/scouting/playertotalview');

  }

  openPlayer(playerId:String) {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(PlayerViewDialogComponent, {
      data: { PlayerId: playerId },
    });
  }
  openViewReport(reportId:String) {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(ReportViewDialogComponent, {
      data: { ReportId: reportId },
    });
  }

  deleteReport(report: FlowItemModel){
    console.log(report);
    this.scoutingService.addReport
  }
  

  setClass(){
    return {    

      greenborder: this.Report.TotalQuality>3,
      lightgreenborder: this.Report.TotalQuality==3,
      yellowborder: this.Report.TotalQuality==2,
      redborder: this.Report.TotalQuality==1,

    };
  }
}
