import { Component, Input, OnInit } from '@angular/core';
import { Team } from 'src/app/shared/team.model';

@Component({
  selector: 'app-organization-team-card',
  templateUrl: './organization-team-card.component.html',
  styleUrls: ['./organization-team-card.component.css']
})
export class OrganizationTeamCardComponent implements OnInit {

  @Input() Team: Team;
  constructor() { }

  ngOnInit(): void {
  }

}
