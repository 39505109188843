import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/baseservice';
import { Observable } from 'rxjs';
import { PlayerListListViewModel, PlayerListDetailsViewModel, PlayerListSecurity } from '../models/PlayerListModels';
import { HttpClient } from '@angular/common/http';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerListService extends BaseService {

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }

  getLists(): Observable<PlayerListListViewModel[]>{

    let ret = this.http.get<PlayerListListViewModel[]>(this.rootUrl + '/api/playerlists/' );
    return ret;
   }

   getListDetails(ListId: string): Observable<PlayerListDetailsViewModel>{

    let ret = this.http.get<PlayerListDetailsViewModel>(this.rootUrl + '/api/playerlists/' + ListId );
    return ret;
   }

   getCategories(): Observable<string[]>{

    let ret = this.http.get<string[]>(this.rootUrl + '/api/playerlists/categories' );
    return ret;
   }

   addList(Description: string, Category: string, Security: PlayerListSecurity[] ){
    var params = {
     Description: Description,
     Category: Category,
     PlayerListSecurity: Security     
    }
     //console.log(params)   ;
     return this.http.post<string>(this.rootUrl + '/api/playerlists', params);     
  }

  updateList(ListId: string, Description: string, Category: string,  archived: boolean, Security: PlayerListSecurity[] ){
    var params = {
     Description: Description,
     Category: Category,
     Archived: archived,
     PlayerListSecurity: Security     
    }
     console.log(params)   ;
     return this.http.put<boolean>(this.rootUrl + '/api/playerlists/' + ListId , params);     
  }

  
  archiveList(ListId: string ){
    
    
     return this.http.patch<boolean>(this.rootUrl + '/api/playerlists/archive/' + ListId, null);     
  }



  MovePlayerInList(ListId: string, PlayerId: string, OldPosition: number, NewPosition: number): Observable<boolean>{
    //console.log(this.rootUrl + 'api/playerlists/MovePlayer/' + ListId + '/' + PlayerId + '/' + (OldPosition+1) + '/' + (NewPosition+1) );
    let ret = this.http.get<boolean>(this.rootUrl + '/api/playerlists/MovePlayer/' + ListId + '/' + PlayerId + '/' + (OldPosition+1) + '/' + (NewPosition+1) );
    return ret;
   }

   addPlayerInList(ListId: string, PlayerId: string): Observable<boolean>{

    return this.http.post<boolean>(this.rootUrl + '/api/playerlists/' + ListId + "/" + PlayerId, null, this.httpOptions);   

   }

   deletePlayerInList(ListId: string, PlayerId: string){
    let ret = this.http.delete(this.rootUrl + '/api/playerlists/' +  ListId + '/' + PlayerId);
    return ret;
  }

  deleteList(ListId: string){
    let ret = this.http.delete(this.rootUrl + '/api/playerlists/' +  ListId);
    return ret;
  }

  copyList(CopyFromListId: string, CopyToListName: string, CopyToListCategory: string, CopySecurity: boolean  ){
    var data = {
      CopyFromListId: CopyFromListId,
      CopyToListName: CopyToListName,
      CopyToListCategory: CopyToListCategory,
      CopySecurity: CopySecurity
    };
    return this.http.post<boolean>(this.rootUrl + '/api/playerlists/copy', data, this.httpOptions);   
   
  }

}
