import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerService } from 'src/app/shared/player.service';
import { User } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { KeyResult } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';
import { NewKeyresultDialogComponent } from '../new-keyresult-dialog/new-keyresult-dialog.component';

@Component({
  selector: 'app-edit-key-result-dialog',
  templateUrl: './edit-key-result-dialog.component.html',
  styleUrls: ['./edit-key-result-dialog.component.css']
})
export class EditKeyResultDialogComponent implements OnInit {
  keyresultForm;
  users: User[];

  constructor(private fb: UntypedFormBuilder,
    private playerService: PlayerService,
    private objectiveService: ObjectivesService,
    private userService: UserService,
    public dialogRef: MatDialogRef<NewKeyresultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {KeyResult: KeyResult}
    ) { }

    ngOnInit(): void {

     this.keyresultForm = this.fb.group({
       ObjectiveId: [this.data.KeyResult.ObjectiveId],
       KeyResultId: [this.data.KeyResult.KeyResultId],
       Owner: [this.data.KeyResult.Owner, [Validators.required]],
       Title: [this.data.KeyResult.Title,Validators.required],        
       KeyResultType: ['INTEGER'],
       TargetInteger: [this.data.KeyResult.TargetInteger,Validators.required],
       BaselineInteger: [this.data.KeyResult.BaselineInteger,Validators.required],
       StartDate:[this.data.KeyResult.StartDate],
       TargetDate:[this.data.KeyResult.TargetDate],
       
 
       
     });
 
     this.userService.getAllUsers().subscribe(data=>{
       this.users =  data as User[];
     });
    }

    onSubmit(){
      // TODO: Use EventEmitter with form value
     /*  console.warn(this.keyresultForm.value);
      console.log(this.keyresultForm.valid);*/
      this.objectiveService.updateKeyResult(this.keyresultForm.value).subscribe(res=>{
        this.dialogRef.close();
      }); 
    }
}
