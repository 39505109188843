<div class="app-page" style="margin-left: 10px;">
    <h3>PREVIEW INFO</h3>
    <div style="font-family: Roboto;">
        The features for injuries are not ready for production use! <br>
        <br>
        Some features for handling injuries have not been developed yet, we are working on integrating injury info into other screens. <br/>
        <br/>
        The PREVIEW will give you the chance to give feedback to Dalecarlia Scouting and influence future development.<br/>
        <br>
        <br>        

        Short instructions<br/>
        1. New injuries are for the moment created from "TEST"- "PLAYERS" screen. <br/>
        2. INJURY - TEAM OVERVIEW screen contains a lot pie-charts of injury data, will most likely change, feel free to suggest needed charts  <br/>
        Charts show 2 different values. <br/>
        -Number of injuries (how many injuries that are created)<br/>
        -Number of injury days (how many days the injuries lasted (ActualReturnDate-InjuryDate = Number of days)<br/>
        If ActualReturnDate is not set, we consider the injury to be still active (Todays date-InjuryDate = Number of days)<br/>
        <br/>
        Future charts that we consider is related to positions (injury days per position), age-intervals... Chart showing number of injurydays per week over the entire season. <br/><br/>
        Definitions:<br/>
        <table>
            <tr>
                <td style="font-family: Roboto; font-weight: bold;">
Injury Date
                </td>
                <td>
Date when injury happened
                </td>
            </tr>
            <tr>
                <td style="font-family: Roboto; font-weight: bold;">
                    Estimated Return Date:
                </td>
                <td>
                    When do you estimate when player returns to competition
                </td>
            </tr>
            <tr>
                <td style="font-family: Roboto; font-weight: bold;">
                    Actual Return Date
                </td>
                <td>
                    When the player actually returned to competition
                </td>
            </tr>
            <tr>
                <td style="font-family: Roboto; font-weight: bold;">
                    Type of injury

                </td>
                <td>
 What type of injury (Fracture, strain, sprain, concussion,...)
                </td>
            </tr>
            <tr>
                <td style="font-family: Roboto; font-weight: bold;">
                    Body Area
                </td>
                <td>
                    What area of the body was injured (Arm, Leg, Head,..)
                </td>
            </tr>
            <tr>
                <td style="font-family: Roboto; font-weight: bold;">
                    Body Part
                </td>
                <td>
                    What part (more detailed than area) of the body was injured
                </td>
            </tr>
            <tr>
                <td style="font-family: Roboto; font-weight: bold;">
                    Conditions
                </td>
                <td>
                    The condition when the injury was suffered (Practise, Regular season game, Pre-season,...)
                </td>
            </tr>
            <tr>
                <td style="font-family: Roboto; font-weight: bold;">
                    Location
                </td>
                <td>
                    Where was the injury suffered (Def Zone, Behind the net, ....)
                </td>
            </tr>

        </table>
    
    


    </div>
</div>

