import { Player } from './PlayerModels';
import { SCGameLineUpPlayer, GameModel, GameStatisticsPlayerViewModel } from './GameModels';


export class ReportViewModel{
    SCReport: Report;  
    TotalQuality: SkillViewModel;
    TotalQualityText: string;
    PlayerCapacity: SkillViewModel;
    PlayerCapacityText: string;
    
    GamePerformance: SkillViewModel;
    GamePerformanceText: string;
    GameStatistics: GameStatisticsPlayerViewModel;

    PlayerType: SkillViewModel;
    PlayerTypeText: string;
    ReportText: ReportText;
    PositionId: number;

    Gameid: string;    
    Game: GameModel;
    ReportTeamIconFileName: string;
    Division: string;

    ScoutLastName: string;
    ScoutFirstName: string;

    PlayerAgeYears: number;
    PlayerAgeMonths: number;
    PlayerAgeDays: number;

    PlayerAgeYearsReportDate: number;
    PlayerAgeMonthsReportDate: number;
    PlayerAgeDaysReportDate: number;


    SkillGroups: SkillsGroupViewModel[];
}


export class Report{
    ReportId: string;
    PlayerId: string;
    Player: Player;
    ReportTexts: ReportText[];
    ReportSkills: ReportSkill[];
    Userid: string;
    OrganizationId: number;
    ScoutName: string;
    ScoutLastName: string;
    ScoutFirstName: string;
    Location: string;
    ScoutingSourceId: number;
    ScoutingSourceText: string;
    LeaugeId: number;
    League: string;
    PositionId: number;
    ReportDate: Date;
    Team: string;
    TeamId: string;
    FirstName: string;
    LastName: string;
    Weight: number;
    Height: number;
    JerseyNo: number;
    DateOfBirth: Date;
    //int ReportStatus
    GameId: string;
    DateCreated: Date;
}

export class ReportText{
   ReportId: string;
   TextTypeId: number;
   Text: string;
}

export class ReportSkill{
    ReportId: string
    SkillId: number;
    SkillGroupId: number;
    Ranking: number;
}

export class RankingViewModel
{
    RankingId: number;
    Ranking: number;
    Name:string;
    Text:string;
    
}

export class SkillViewModel
{
    SkillId: number;
    SkillGroupId: number;
    Name:string;
    Text:string;
    RankingId: number;
    Sequence: number;

    Rankings: RankingViewModel[];
    Ranking: number;


}

export class SkillsGroupViewModel
{
    SkillGroupId: number;
    Name:string;
    Text:string;
    Sequence: number;

    Skills: SkillViewModel[];

}

export class ScoutingReportMasterData
{
    Projection: RankingLanguage[];
    Capacity: RankingLanguage[];
    GamePerformance: RankingLanguage[];
    ScoutingSource: ScoutingSourceLanguage[];

    CapacityInfoText: string;
    ProjectionInfoText: string;
    CommentInfoText: string;
    GamePerformanceInfoText: string;
    PlayerTypeInfoText: string;
    LocationInfoText: string;

    SkillsGroups: SkillsGroupLanguage[];
    SkillsGroupsView: SkillsGroupViewModel[];

    
    CapacityEnabled: boolean;
    GamePerformanceEnabled: boolean;
    PlayerTypeEnabled: boolean;
     PlayerType: RankingLanguage[];
}

export class RankingLanguage {
    
     RankingId: number;
     Ranking: number;
     LanguageId: string;
     Text: string;
     ShortText: string;
     Hidden: boolean;
}

export class SkillsGroupLanguage {
    SportId: number;
    SkillGroupId: number;
    LanguageId: string;
    Text: string;
}

export class ScoutingSourceLanguage{
    ScoutingSourceId: number;
    LanguageId: string;
    Text: string;
}



export class ScoutingSourceViewModel{
    ScoutingSourceId: number;
    OrganizationId: number;
    LanguageId: string;
    Text: string;
    IsEnabled: boolean;
}