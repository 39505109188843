export class ReportSearchParameters
{
    OrganizationId: number;
    PlayerLastName: string;
    PlayerFirstName: string;
    Projections: number[];
    Capacities: number[];   
    ShootIds: number[];
    PositionIds: number[];
    ScoutUserIds: string[];
    minReportDate: Date;
    maxReportDate: Date;
    Location: string;
    Source: string;
    maxHits: number;
    BirthYears: number[];

}