<div style="display: flex; flex-direction: column; background-color: #303030;padding-left: 50px;">
    <div style="    font-size: small;
    font-weight: 600;
    margin: 5px;
    margin-left: 30px;">Feeling reported</div>
    <div style="display: flex; flex-direction: row;">
        <div style="width:50px; text-align: right; font-size: x-small;">Very bad</div>
        <div *ngFor="let item of [].constructor(this.LogSummary.Feeling_NoOfVeryBad)" style="width: 5px; height: 10px; margin: 2px; background-color: red;"></div>
    </div>
    <div style="display: flex; flex-direction: row;">
        <div style="width:50px; text-align: right; font-size: x-small;">Bad</div>
        <div *ngFor="let item of [].constructor(this.LogSummary.Feeling_NoOfBad)" style="width: 5px; height: 10px; margin: 2px;background-color: orange;;"></div>
    </div>
    <div style="display: flex; flex-direction: row;">
        <div style="width:50px; text-align: right; font-size: x-small;">OK</div>
        <div *ngFor="let item of [].constructor(this.LogSummary.Feeling_NoOfOK)" style="width: 5px; height: 10px; margin: 2px;background-color: yellow;"></div>
    </div>
    <div style="display: flex; flex-direction: row;">
        <div style="width:50px; text-align: right; font-size: x-small;">Good</div>
        <div *ngFor="let item of [].constructor(this.LogSummary.Feeling_NoOfGood)" style="width: 5px; height: 10px; margin: 2px;background-color: greenyellow;"></div>
    </div>
    <div style="display: flex; flex-direction: row;">
        <div style="width:50px; text-align: right; font-size: x-small;">Very good</div>
        <div *ngFor="let item of [].constructor(this.LogSummary.Feeling_NoOfVeryGood)" style="width: 5px; height: 10px; margin: 2px;background-color: green;"></div>
    </div>

   

</div>