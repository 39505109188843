<section style=" height: 100%;
width: 100%;">
<!--     <header style="height: 50px; max-height: 50px; background-color: #424242;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);">
        <h2>&nbsp;Agents</h2>
    </header> -->
    &nbsp;
    <mat-form-field appearance="fill">
        <mat-label>Filter authority</mat-label>
        <mat-select (selectionChange)="this.getAgents($event.value)" [(value)]="_selectedAuthority">
          <mat-option value="-1">All</mat-option>
          <mat-option *ngFor="let authority of _agentauthorities" [value]="authority.AuthorityId">
            {{authority.Name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    <section style="  height: 100%;
    overflow: auto;
    padding: 10px; display: flex; flex-direction: row; flex-wrap: wrap;
    word-break: break-all;">
            <app-agent-card *ngFor="let agent of this._agents" [Agent]="agent"></app-agent-card>

    </section>

<div style="display: flex; flex-wrap: wrap; height: calc(100%-50px); overflow: auto;
padding: 10px;
word-break: break-all;">
   
    
</div>

</section>