import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Team } from 'src/app/shared/team.model';
import { TeamService } from 'src/app/shared/team.service';
import { KeyresultComponent } from '../../components/keyresult/keyresult.component';
import { NewObjectiveDialogComponent } from '../../components/new-objective-dialog/new-objective-dialog.component';
import { KeyResult, Objective } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';
import { NewObjectiveComponent } from '../new-objective/new-objective.component';


@Component({
  selector: 'app-team-objectives',
  templateUrl: './team-objectives.component.html',
  styleUrls: ['./team-objectives.component.css']
})
export class TeamObjectivesComponent implements OnInit {

  TeamId: string;
  team: Team;
  objectives: Objective[] = [];
  objectivesTeam: Objective[] = [];

  constructor(
    private objectiveSrv: ObjectivesService,
    private teamServ: TeamService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute) { 

  }

  

  ngOnInit(): void {
/*     this.objectiveSrv.getObjectivesForTeam("1").subscribe(data=>{
        this.objectives = data;
    }); */

    this.activatedRoute.params.subscribe(params => {
      
      this.TeamId = params['TeamId'];      

      this.teamServ.getTeam(this.TeamId).subscribe(team=>{
          this.team = team as Team;
      });
      //console.log(this.TeamId);
      this.objectiveSrv.getObjectivesForTeam(this.TeamId).subscribe(data=>{
        this.objectives = data;
      });
      this.objectiveSrv.getObjectivesForTeam(this.TeamId, 'TEAM').subscribe(data=>{
        this.objectivesTeam = data;
      });
    });
      
    
  }

  addObjective(level: string) {
    const newObjectiveDialog = this.dialog.open(NewObjectiveDialogComponent, {
      data: {
       ObjectiveLevel: level,
       TeamId: this.TeamId
      }
    });
    newObjectiveDialog.afterClosed().subscribe(result => {
      this.objectiveSrv.getObjectivesForTeam(this.TeamId).subscribe(data=>{
        this.objectives = data;
      });
      this.objectiveSrv.getObjectivesForTeam(this.TeamId, 'TEAM').subscribe(data=>{
        this.objectivesTeam = data;
      });
    });
  }


  refresh(){
    this.objectiveSrv.getObjectivesForTeam(this.TeamId, 'TEAM').subscribe(data=>{
      this.objectivesTeam = data;
    });
    this.objectiveSrv.getObjectivesForTeam(this.TeamId).subscribe(data=>{
      this.objectives = data;
    });
  }
}
