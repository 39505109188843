<div class="app-page">
  <mat-toolbar style="height: 80px;">
   <!--  <mat-form-field style="font-size: 14px;">
      <mat-label>Team</mat-label>
      <mat-select [formControl]="teamFormControl" (selectionChange)="refreshData()" >
        <mat-option *ngFor="let team of _teams" [value]="team">
          {{team.Level}}
        </mat-option>        
      </mat-select>
    </mat-form-field> -->
    <mat-form-field style="font-size: 14px;">
      <mat-label>Season</mat-label>
      <mat-select [formControl]="seasonFormControl"  (selectionChange)="refreshData()">
        <mat-option *ngFor="let season of _seasons"  [value]="season">
          {{season.Name}}
        </mat-option>
        
      </mat-select>
    </mat-form-field>
    
  </mat-toolbar>
  <div>
    <!-- <mat-list dense>
      <mat-list-item *ngFor="let player of _players" >
        {{player.LastName}}, {{player.FirstName}}
      </mat-list-item>


     </mat-list> -->

  <!--    <mat-list dense>
      <h3 mat-subheader>Goal keepers</h3>
      <ng-container  *ngFor="let player of _players" >
          <mat-list-item *ngIf="player.Position.PlayerPositionId==1">
     
          <mat-icon mat-list-icon>folder</mat-icon>
          <h4 mat-line>    {{player.LastName}}, {{player.FirstName}}</h4>
          <p mat-line>     {{player.DateOfBirth}}</p>
 
        </mat-list-item>
      </ng-container>
      <mat-divider></mat-divider>
      <h3 mat-subheader>Defenders</h3>
      <ng-container  *ngFor="let player of _players" >
              <mat-list-item *ngIf="player.Position.PlayerPositionId==2 || player.Position.PlayerPositionId==3">
         
              <mat-icon mat-list-icon>folder</mat-icon>
              <h4 mat-line>    {{player.LastName}}, {{player.FirstName}}</h4>
              <p mat-line>     {{player.DateOfBirth}}</p>
     
      </mat-list-item>
      </ng-container>
      <mat-divider></mat-divider>
      <h3 mat-subheader>Forwards</h3>
      <ng-container  *ngFor="let player of _players" >
              <mat-list-item *ngIf="player.Position.PlayerPositionId==4 || player.Position.PlayerPositionId==5 || player.Position.PlayerPositionId==6">
         
              <mat-icon mat-list-icon>folder</mat-icon>
              <h4 mat-line>    {{player.LastName}}, {{player.FirstName}}</h4>
              <p mat-line>     {{player.DateOfBirth}}</p>
     
      </mat-list-item>
      </ng-container>
    </mat-list> -->
  </div>
  <div style="margin: 10px;" >
    <mat-menu #addMenu="matMenu">
      <button mat-menu-item>FROM ORGANIZATION</button>
      <button mat-menu-item>FROM CENTRAL DATABASE</button>
      <button mat-menu-item>NEW PLAYER</button>
    </mat-menu>

    <button mat-raised-button color="accent" _disabled='true' (click)="openSearch()" >
      <mat-icon>add</mat-icon>
        ADD PLAYER
    </button>&nbsp;
    <button mat-raised-button color="accent" _disabled='true' (click)="AddAllFromCurrentTeam()" >
        <mat-icon>add</mat-icon>
        IMPORT FROM DATABASE
      </button>&nbsp;
     <!--  <button mat-raised-button color="accent" _disabled='true' (click)="OpenPlayersWithTestResults()" >
        <mat-icon>add</mat-icon>
        FROM TESTRESULTS...
      </button> -->
      
  <table mat-table [dataSource]="dataSource"  style="padding: 5px;" >
      <ng-container matColumnDef="JerseyNo">
          <th mat-header-cell *matHeaderCellDef > No </th>
          <td mat-cell *matCellDef="let element"> {{element.JerseyNo}}</td>
          <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
        </ng-container>
    <ng-container matColumnDef="LastName">
      <th mat-header-cell *matHeaderCellDef > Lastname </th>
      <td mat-cell *matCellDef="let element"> {{element.LastName}}</td>
      <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
    </ng-container>
    <ng-container matColumnDef="FirstName">
      <th mat-header-cell *matHeaderCellDef > Firstname </th>
      <td mat-cell *matCellDef="let element"> {{element.FirstName}}</td>
      <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
    </ng-container>
    <ng-container matColumnDef="DateOfBirth">
      <th mat-header-cell *matHeaderCellDef > Date of birth </th>
      <td mat-cell *matCellDef="let element"> {{element.DateOfBirth | date: 'yyyy-MM-dd'}}</td>
      <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
    </ng-container>
    <ng-container matColumnDef="Position">
      <th mat-header-cell *matHeaderCellDef  style="min-width: 100px;">Position</th>
      <td mat-cell *matCellDef="let element">  {{_positionName[element.Position.PlayerPositionId]}}</td>
      <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
    </ng-container>
    <ng-container matColumnDef="ShootId">
      <th mat-header-cell *matHeaderCellDef  style="min-width: 50px;">Shot/Glove</th>
      <td mat-cell *matCellDef="let element" > {{_shootName[element.ShootId]}}</td>
      <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
    </ng-container>
    <ng-container matColumnDef="ProfileImage">
        <th mat-header-cell *matHeaderCellDef > Image </th>
        <td mat-cell *matCellDef="let element"> {{element.ProfileImage}}</td>
        <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
      </ng-container>
    <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef >  </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button _disabled='true' type="button" (click)="deleteFromTeamSeason(element.PlayerId)">
            <mat-icon>clear</mat-icon>            
          </button>
          <button mat-icon-button _disabled='true' type="button" (click)="editTeamSeason(element.PlayerId, element.LastName,element.FirstName, element.ProfileImage, element.JerseyNo, element.ShootId, element.PositionId)">
            <mat-icon>edit</mat-icon>            
          </button>
        </td>
        <!-- <td mat-footer-cell *matFooterCellDef> Total </td> -->
      </ng-container>
   
    
   
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!-- <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr> -->
  </table>
  </div>

  

</div>
