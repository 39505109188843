<div class="app-page">
    <ng-container *ngIf="this.form">
  
    
      <h3>
        <mat-icon>
          dynamic_form
        </mat-icon>
          {{this.formSubmission.Form.Name}}
      </h3>
      <mat-divider></mat-divider>
      <div>
        <app-player-details-section *ngIf="this.formSubmission.Player"  [Player]="this.formSubmission.Player?.Player">

        </app-player-details-section>
        <br/>
        <mat-form-field style="width: 600px;"  appearance="fill"  class="smalltext" *ngIf="this.formSubmission"  >
          <mat-label>Team</mat-label>
          <input matInput  id="TeamName" maxlength="100" disabled [value]="this.formSubmission.TeamName"/>
      </mat-form-field>
      <br/>
      <mat-form-field appearance="fill" class="smalltext" [formGroup]="this.form">
        <mat-label>Document date</mat-label>
        <input matInput [matDatepicker]="docdate"  id="DocumentDate" formControlName="DocumentDate">
        <mat-hint>YYYY-MM-DD</mat-hint>
        <mat-datepicker-toggle matIconSuffix  matSuffix  [for]="docdate"></mat-datepicker-toggle>
        <mat-datepicker #docdate></mat-datepicker>
      </mat-form-field>
          
      </div>
     
      <div>
         <!--  <button mat-button (click)="accordion.openAll()">Expand All</button>
          <button mat-button (click)="accordion.closeAll()">Collapse All</button> -->
        </div>
        <form (ngSubmit)="onSubmit()" [formGroup]="this.form">
          <!-- <mat-form-field appearance="fill" >
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" id="SubmitDate" [formControl]="this.form.SubmitDate">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix  matSuffix  [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field> -->
          <input type="hidden" [value]="this.form.FormId" />
          <input type="hidden" id="RowVersion" [value]="this.form.RowVersion" />
          <div style="display: flex; flex-direction: column;">
            <div *ngFor="let section of this.formSubmission.Form.FormVersion.Sections" >
              <app-dynamic-section [section]="section" [form]="this.form"></app-dynamic-section>
            </div>
          </div>
          
          <br/>
          <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">SAVE</button>
          &nbsp;
          <button mat-button  type="button" (click)="this.back()" >CANCEL</button>
        </form>
  
       <!--  <div *ngIf="payLoad" >
          <strong>Saved the following values</strong><br>{{payLoad}}
        </div> -->
      </ng-container>
  </div>
  