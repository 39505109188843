<div  class="mat-elevation-z8" style="padding: 7px;">
    SCOUT PRESENCE<br/>
    <div *ngFor="let event of this.Events">
        <mat-icon style="color: orange">person</mat-icon>
        <span style="font-family: Roboto;">{{event.LastName}}, {{event.FirstName}}</span>
        <div>
            {{event.Comment}}
        </div>

    </div>

</div>
