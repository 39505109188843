import { Component, OnInit, Input } from '@angular/core';
import { Team, TeamOverviewModel } from '../shared/team.model';

@Component({
  selector: 'app-team-overview-card',
  templateUrl: './team-overview-card.component.html',
  styleUrls: ['./team-overview-card.component.css']
})
export class TeamOverviewCardComponent implements OnInit {
  
  @Input() Team: TeamOverviewModel;

  constructor() { }

  ngOnInit() {



  }


}
