<div style="display: flex;flex-direction: row;font-family: Roboto; align-content: space-between; border-bottom: 1px #56565677 solid; " class="DS_Teamroster"   >
    <div style="font-size: 20px; margin-right: 5px; flex-grow: 1;" (click)="navigate($event,this.Player.LifelineScoutingId);">
        {{this.Player.PlayerView.JerseyNo}}
    </div>    
    <div style="flex-grow: 1;" (click)="navigate($event,this.Player.LifelineScoutingId);" matRipple matRippleColor="#ffe0b24d">        
        <span style="font-size: 12px; ">{{this.Player.PlayerView.LastName}}, {{this.Player.PlayerView.FirstName}}</span><br/>
        <span style="font-size: 10px"> {{this.Player.PlayerView.DateOfBirth | date:'yyy-mm-dd'}} - {{this.Player.PlayerView.Height}} cm - {{this.Player.PlayerView.Weight}} kg </span><br/>
        <!-- add tags-->        
    </div>
    <div>
      <div *ngFor="let tag of this.Player.Tags" [ngClass]="tag.TagColor" >
        {{tag.TagText}}
      </div>
    </div>
    <div  style="flex-grow: 100; text-align: right;">
        <mat-icon *ngIf="this.Player.IsFavourite" style="color: orange;">star</mat-icon>
    </div>
    <div *ngIf="this.Player.PlayerView.ReportCount>0 || this.Player.PlayerView.NoteCount>0 "  style="flex-grow: 1; text-align: LEFT;width: 50px;font-size: small;">
        <mat-icon  style="font-size: small;"> rate_review </mat-icon>&nbsp;<b>{{this.Player.PlayerView.ReportCount}}</b><br/>
        <span *ngIf="this.Player.PlayerView.NoteCount>0"><mat-icon   style="font-size: medium;"> insert_comment </mat-icon>&nbsp;{{this.Player.PlayerView.NoteCount}}<br/></span>
    </div>
    <div *ngIf="this.Player.ReportCount==0 && this.Player.NoteCount==0 "  style="flex-grow: 1; text-align: right; width: 40px;">

    </div>
  <!--   <div style="flex-grow: 1;">
            <button mat-icon-button aria-label="Add button" (click)="addPushed($event,this.Player.LifelineScoutingId, this.GameId); false;">
                    <mat-icon>add</mat-icon>
            </button>
    </div> -->
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['/scouting/newreport', this.Player.LifelineScoutingId, this.GameId]"> 
          <mat-icon>rate_review</mat-icon>
          <span>New report</span>
        </button>
        <button mat-menu-item [routerLink]="['/scouting/newnote', this.Player.LifelineScoutingId, this.GameId]"> 
          <mat-icon>insert_comment</mat-icon>
          <span>New note</span>
        </button>
<!--         <button mat-menu-item disabled>
          <mat-icon>format_list_numbered</mat-icon>
          <span>Add to list</span>
        </button> -->
        
      </mat-menu>

</div>