<div mat-dialog-content>
    <h2 style="font-family: Roboto;;">Select parameters</h2>
    <form (ngSubmit)="onSubmitForm()" id="statsForm" autocomplete="off">
     
        <mat-tab-group>
            <mat-tab label="Plain" style="min-width: 60px;"> 
             
                <mat-selection-list #parameters id="plainparameters" [(ngModel)]="selectedPlain" [ngModelOptions]="{standalone: true}" (selectionChange)="onSelection($event, parameters.selectedOptions.selected)"  style="max-height: 300px; overflow: auto;" >
                    <mat-list-option *ngFor="let parameter of this.limits" [value]="parameter">
                    {{parameter.Abbreviation}}
                    </mat-list-option>
                </mat-selection-list>    
            
            </mat-tab>
            <mat-tab label="Calculated" style="min-width: 60px;">
                <mat-selection-list #parameterscalculated  [(ngModel)]="selectedCalculated" [ngModelOptions]="{standalone: true}" (selectionChange)="onSelectionCalc($event, parameterscalculated.selectedOptions.selected)" style="max-height: 300px; overflow: auto;">
                    <mat-list-option *ngFor="let parameter of this.limitscalculated" [value]="parameter">
                        {{parameter.Abbreviation}}
                    </mat-list-option>
                </mat-selection-list>    
            </mat-tab>
            
          </mat-tab-group>
      
       
       
      </form>
  
   </div>
   <div mat-dialog-actions>
     <button type="submit" form="statsForm"  mat-raised-button color="primary" cdkFocusInitial>ADD</button>   
     <button mat-button [mat-dialog-close]="false" >CANCEL</button>
   </div>