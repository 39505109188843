<div style="margin-left: 20px;" *ngIf="this.isLoaded==true">
      
    <h3 *ngIf="this.AssignedProgram.Status=='DRAFT'">EDIT PROGRAM</h3>
    <h3 *ngIf="this.AssignedProgram.Status=='ACTIVE'">VIEW PROGRAM</h3>

     <form [formGroup]="programForm" (ngSubmit)="save()" autocomplete="off">
       <mat-form-field appearance="fill"  style="width: 400px;">
         <mat-label>Title</mat-label>
         <input matInput placeholder="Enter a name for the program" formControlName="Title" id="Title" /> 
         <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
        <!--  <mat-hint>Max length 50 characters</mat-hint>  -->
       </mat-form-field>   
       <br/>     
       <mat-form-field appearance="fill"  style="width: 400px;" class="smallinput">
         <mat-label>Description</mat-label>
         <input matInput formControlName="Description" id="Description" />            
       </mat-form-field>  
       <br>
       <mat-form-field appearance="fill"  style="width: 400px;" class="smallinput">
        <mat-label>Team</mat-label>
        <mat-select formControlName="TeamId" [disabled]="this.AssignedProgram.Status=='ACTIVE'" >
          <mat-option *ngFor="let team of Teams" [value]="team.TeamId">{{team.Name}} - {{team.LevelName}}</mat-option>
        </mat-select>
        
      </mat-form-field>  
      <br/>
       <mat-form-field style="max-width: 770px; min-width: 570px;" appearance="fill" formArrayName="AssignedPlayers">
        <mat-label>Assigned players</mat-label>
        <mat-chip-listbox #chipList aria-label="Player selection">
          <mat-chip-option
          *ngFor="let player of this.assignedPlayers?.controls; let i= index;"
          [formGroupName]="i"
          
           >
           <mat-icon>person</mat-icon>
           {{player.get('LastName').value}}, {{player.get('FirstName').value}}
           
          </mat-chip-option>         
        </mat-chip-listbox>        
      </mat-form-field>
     
       <br/> 
       <input type="hidden" formControlName="Status" id="Status">
       <mat-form-field appearance="fill"  style="width: 200px;">
        <mat-label>Status</mat-label>
        <input matInput  formControlName="StatusDisplay" id="StatusDisplay"  /> 
        <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
       <!--  <mat-hint>Max length 50 characters</mat-hint>  -->
      </mat-form-field>   
      
     <!-- <mat-form-field appearance="fill" class="smallinput">
         <mat-label  class="smallinput">Status</mat-label>
         <mat-select  class="smallinput" formControlName="Status" id="Status" [value]="this.programForm.Status"  >              
           <mat-option value="DRAFT">DRAFT</mat-option>
           <mat-option value="ACTIVE">ACTIVE</mat-option>
           <mat-option value="ARCHIVED">ARCHIVED</mat-option>
       </mat-select>
     </mat-form-field>  -->
     <br/>
     <br/>
     <label>WORKOUTS</label>
     <!-- <button mat-button type="button" (click)="addWeek()">ADD WEEK</button>&nbsp;<br/> -->
     <br/>      
     <mat-divider></mat-divider>
     <br/>
     <div  formArrayName="Weeks">
      <div *ngFor="let week of this.programForm.get('Weeks').controls; let weekNo = index" [formGroupName]="weekNo">
     <!--  <div> -->
       

        <div  class="example-box">
          <div  formArrayName="Days" style=" height: 4; display: flex; flex-direction: row; flex-wrap: wrap; " >
            <div   *ngFor="let theDay of week.get('Days')?.controls; let dayno = index"  class="dayframe" [formGroupName]="dayno">
              <div style="display: flex; flex-direction: column;">
                <div style="text-align: center;" >
                  
                    <h2>
                      {{this.DayNames[dayno%7]}}
                    </h2>
                    <h3>
                      {{theDay.get('Date').value | date}}
                    </h3>
                    <mat-divider></mat-divider>
                  
                  
                </div>
                <div formArrayName="AssignedWorkouts" style="margin-left: 10px;"  >
                 
                   <!-- <div
                          cdkDropList                              
                          [id]="'Day_' + (dayno) + '_' + (weekNo)"
                          [cdkDropListData]="theDay.get('ProgramWorkouts')?.controls"
                          [cdkDropListConnectedTo]="this.droptargets"
                          class="example-list"
                          (cdkDropListDropped)="drop1($event)"> -->
                        <div 
                          [id]="'Day_' + formatDateForDiv(theDay.get('Date').value)"                                                        
                          cdkDropList
                          [cdkDropListData]="theDay.get('AssignedWorkouts')?.controls"
                          [cdkDropListConnectedTo]="this.droptargets"
                          (cdkDropListDropped)="drop1($event)"
                          class="example-list"
                          >
                     <div *ngFor="let workout of theDay.get('AssignedWorkouts')?.controls; let workoutindex = index" [formGroupName]="workoutindex" class="workout" cdkDrag>
                      <mat-expansion-panel cdkDrag  >
                        <mat-expansion-panel-header style="padding: 0px 8px;background-color: dodgerblue;">
                          <mat-panel-title style="font-family: Roboto; font-size: small;">
                            {{workout.get('Name').value}}<br/>
                            
                           <!--  <span style="font-size: 12px; min-width: 22px;">
                              <mat-icon  style="font-size: 12px;width: 12px;">person</mat-icon>
                              {{workout.get('NoOfAssignedPlayers').value}}
                            </span> -->
                            
                          </mat-panel-title>                       
                        </mat-expansion-panel-header>
                        <div style="margin-left: -15px;">
                        
                          <ng-container *ngFor="let section of getWorkout(workout.get('AssignedWorkoutId').value)?.Workout.Sections">
                            <div class="woSection" >
                              {{ section.Name }}
                            </div>
                            <div class="woExercise" *ngFor="let exercise of section.Exercises">
                              {{exercise.Name}}
                            </div>
                          </ng-container>                          
                        </div>
                      </mat-expansion-panel>
                            <!-- <div  >
                              <div style="display: flex; align-items: center;">                                     
                                    <mat-icon>view_list</mat-icon>
                                    <label>
                                      {{workout.get('Name').value}}
                                    </label>                                
                              </div>                                                                 
                            </div> -->
                          
                      </div>  
                      
                  </div>
      
                 </div> 
   
              </div>
             <!--  <input  formControlName="Day" id="Day"/> -->
            </div>  
          </div>
         
        </div> 
        

        
      </div>
    </div>


   

    
     <br/>
     <br/>
     <button *ngIf="this.AssignedProgram.Status=='DRAFT'" mat-raised-button type="button" color="primary" (click)="save()" [disabled]="!this.programForm.valid">SAVE</button>&nbsp;
     <button *ngIf="this.AssignedProgram.Status=='DRAFT'" mat-raised-button type="button" color="primary" (click)="saveActivate()" [disabled]="!this.programForm.valid">SAVE AND ACTIVATE</button>&nbsp;
     <button *ngIf="this.AssignedProgram.Status=='DRAFT'" mat-raised-button type="button" (click)="cancel()" >CANCEL</button>
     <button *ngIf="this.AssignedProgram.Status=='ACTIVE'" mat-raised-button type="button" (click)="cancel()" >CLOSE</button>
     </form>

 
 </div>