<h2 mat-dialog-title>
    SELECT USERS
  </h2>

        <div mat-dialog-content >

            <mat-selection-list #userlist style="height: 350px; " [multiple]="true" >
               
                

                    
                    <mat-list-option *ngFor="let user of Users" style="height: 40px;" [value]="user" >        
                        
                    <h4 mat-line>{{user.LastName}}, {{user.FirstName}}</h4>
                    <span mat-line>{{user.EMail}}</span> 
                    </mat-list-option>
                

            </mat-selection-list>
          
        </div>
      
  <div mat-dialog-actions>
      
      <button mat-button [mat-dialog-close]="userlist.selectedOptions?.selected" cdkFocusInitial >SELECT</button>
      <button mat-button [mat-dialog-close]="false" >CANCEL</button>
    
  
  </div>
