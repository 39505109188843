import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseService } from './baseservice';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends BaseService{

  //readonly rootUrl = 'http://apitest.dalecarliascouting.se';
  //readonly rootUrl = 'http://localhost:60194';
  constructor(private http: HttpClient) {
    super();
   }

  getAllRoles() {
    var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/Teams/1', { headers: reqHeader });
  }

  getTeams(){
    
    return this.http.get(this.rootUrl + '/api/teams');
  }

  getTeam(TeamId: string){
    
    return this.http.get(this.rootUrl + '/api/team/' + TeamId);
  }

  getUserTeams(){
    
    return this.http.get(this.rootUrl + '/api/user/teams');
  }

  setProfileForTeam(profileId: string, teamId: string, positionid: string){
    return this.http.post(this.rootUrl + "/api/teams/" + teamId + "/profile/" + profileId ,positionid);
  }

  setGameStatisticsProfileForTeam(profileId: string, teamId: string, positionid: string){
    return this.http.post(this.rootUrl + "/api/teams/" + teamId + "/statisticsprofile/" + profileId ,positionid);
  }

  setGameAssessmentForTeam( teamId: string,gameAssessmentId: string){
    return this.http.post(this.rootUrl + "/api/teams/" + teamId + '/gameassessment', gameAssessmentId);
  }


  getTeamOverview(){
    return this.http.get(this.rootUrl + "/api/teams/overview");

  }

  getTeamSeasons(){
    return this.http.get(this.rootUrl + "/api/teamseason/all");
  }

  getSeasons(){
    return this.http.get(this.rootUrl + "/api/seasons");
  }

  CalculateAverages_PlannedLoad_ForTeam(TeamId: string, monthspast: number, monthscoming: number){
    return this.http.get(this.rootUrl + "/api/load/CalculateAverages_PlannedLoad_ForTeam/" + TeamId + "/" + monthspast + "/" + monthscoming);

  }

  CalculateAverages_PlannedLoad_ForTeam_GoogleChart(TeamId: string, monthspast: number, monthscoming: number){
    return this.http.get(this.rootUrl + "/api/load/CalculateAverages_PlannedLoad_ForTeam/" + TeamId + "/" + monthspast + "/" + monthscoming + "/GoogleChart");

  }


  
}
