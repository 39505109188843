<h2 mat-dialog-title>
    ADD PLAYER 
  </h2>
  <form [formGroup]='playersForm'>
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Search"  formControlName='userInput' required appearance="fill">
          </mat-form-field>
          <button mat-button type="button" (click)="search()">SEARCH</button>
        </form>
        <div mat-dialog-content >

            <mat-selection-list #playerlist style="height: 350px; " [multiple]="false" [(ngModel)]="this.selectedPlayer">
                <mat-list-option *ngFor="let player of this.filteredPlayers"[value]="{SelectedPlayer: player.LifelineScoutingId, LastName: player.LastName, FirstName: player.FirstName}">
                 <!--  <button mat-raised-button (click)="addPlayer(player.LifelineScoutingId)"> 
                    ADD
                   </button>  -->
                  <span matListItemTitle>{{player.LastName}}, {{player.FirstName}} ({{this.positionNames[player.PositionId]}})</span>
                  <span matListItemLine>{{player.DateOfBirth | date:'yyyy-MM-dd'}}</span> 
                 </mat-list-option>
                
                </mat-selection-list>
        </div>
  
  <div mat-dialog-actions>
      
      <button mat-button [mat-dialog-close]="playerlist.selectedOptions?.selected[0]?.value" cdkFocusInitial >{{ data.Action }}</button>
      <button mat-button [mat-dialog-close]="" >CANCEL</button>
    
  
  </div>