<h2 mat-dialog-title>{{PlayerName}}</h2>
<div mat-dialog-content>
  <form [formGroup]="testResultsForm" (ngSubmit)="save()">
    <br/>
    <mat-form-field>
        <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="PerformedDate" required >
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        
      </mat-form-field>
      &nbsp;
      <mat-form-field>
          <input matInput type="time" placeholder="Choose a time" formControlName="PerformedTime"  required>
        </mat-form-field>
  <div formArrayName="testsarray">
      <table>
        
          <tr>
            <td >
              <mat-divider></mat-divider>
            
            </td>
          </tr>
          <tr  *ngFor="let test of Tests.controls; let i = index" [formGroupName]="i"  >
                  
            <td     style="width: 150px;">
                <mat-form-field appearance="fill"  >
                    <mat-label>{{this._profile.Tests[i].Name}}</mat-label>
                    <input matInput placeholder="" formControlName="Result" >                
                    <mat-hint>{{ this._profile.Tests[i].ResultUnit }}</mat-hint>
                  </mat-form-field>
            </td>
        </tr>
      </table>
  </div>
  
</form>
 </div>
 <div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial (click)="save()" [disabled]="!this.testResultsForm.valid">SAVE</button>
   <button mat-button (click)="onNoClick()">CANCEL</button>
 
 </div>