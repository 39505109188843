export class TeamGameStatisticsProfilePositionViewModel{
    GameStatisticsProfileId: string;
    Name: string;
    PositionId: string;
    PositionText: string;
}

export class GameStatisticsViewModel
{
    GameId: string;
    GoalsHome: number;
    GoalsAway: number;
    ShotsHome: number;
    ShotsAway: number;
    GameClock: string;
    SavesHome: number;
    SavesAway: number;
    PenaltyTimeHome: string;
    PenaltyTimeAway: string;
    IsFinalScore: boolean;
    Spectators: number;

}

export class TeamPlayerGameStatisticsViewModel
{
    GameStatisticsViewId: string;
    Name: string;
    PositionId: number;
    PositionText: string;
    TeamId: string;

    Organizationid: number;
}