<h1 mat-dialog-title>Connect position to statistics profile</h1>
<div mat-dialog-content >
  
  <form id="statsprofile">

    <mat-form-field>
      <mat-label>Position</mat-label>
      <mat-select [(value)]="PlayerPositionId"  >
        <mat-option *ngFor="let p of this._positions" [value]="p.PlayerPositionId">{{ p.Text }}</mat-option>        
      </mat-select>   
    </mat-form-field>
    &nbsp;
    <mat-form-field>
      <mat-label>Profile</mat-label>
      <mat-select [(value)]="profileId"  >
        <mat-option *ngFor="let p of this._profiles" [value]="p.GameStatisticsProfileId">{{ p.Name }}</mat-option>        
      </mat-select>     
    </mat-form-field>
    
  </form>

 </div>
 <div mat-dialog-actions>
    <button mat-raised-button color="primary"  (click)="onCloseConfirm()"   cdkFocusInitial>SAVE</button>
   <button mat-button (click)="onNoClick()">CANCEL</button>
 </div>