import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

import { tick } from '@angular/core/testing';
import { PlayerService } from 'src/app/shared/player.service';
import { Season } from 'src/app/shared/team.model';
declare var google:any;

@Component({
  selector: 'app-physical-status-chart',
  templateUrl: './physical-status-chart.component.html',
  styleUrls: ['./physical-status-chart.component.css']
})
export class PhysicalStatusChartComponent implements OnInit {

  @Input() PlayerId: string;
  @Input() Season: Season;
  public uniqueid: string = Math.random().toString(36).substr(2, 9);

  constructor(private playerService: PlayerService ) { }

  

  ngOnInit(): void {
    this.getPhysicalStatusChartData(this.PlayerId, this.uniqueid);  
  
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.getPhysicalStatusChartData(this.PlayerId, this.uniqueid);  
  }

  getPhysicalStatusChartData(_playerid: string, _uniqueid: string): void{
    this.playerService.getPhysicalStatusBySeason_GoogleCharts(_playerid, this.Season.SeasonId.toString()).subscribe(datahttp => {
      //this.datajson = datahttp;

       google.charts.load('current', {'packages':['corechart']});
       google.charts.setOnLoadCallback(drawChart.bind(this));        
       function drawChart() {

        // Create the data table.
        var data = new google.visualization.DataTable(datahttp);
        
        var minDate = new Date(this.Season.SeasonStartDate);
        //minDate.setMonth(minDate.getMonth()-2);

          //Tcikmarks on mondays
          var tickMarks = [];
          var maxDate = new Date(this.Season.SeasonEndDate);
          for (var d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
            if(d.getDay()==1){
              tickMarks.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            }
            
            
        }

        var options = {
          
          legend: {position: 'bottom', textStyle: {color: 'white', fontSize: 12}  },
          titleTextStyle: {color: 'white'},
          height: 200,         
          chartArea: {width: '95%'},
          backgroundColor: '#303030',
          colors: ['#4285f4','#008744','#d62d20','#ffa700','white'],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines: { color: 'gray' }, minValue: minDate, maxValue: maxDate, ticks: tickMarks, format: 'd/M'},
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines: { color: 'gray' }, minValue: 0, maxValue: 10, ticks: [0,5,10] },

        };

        var optionsFever = {
          
          legend: {position: 'bottom', textStyle: {color: 'white', fontSize: 12}  },
          titleTextStyle: {color: 'white'},
          height: 200,         
          chartArea: {width: '95%'},
          backgroundColor: '#303030',
          colors: ['red'],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines: { color: 'gray' }, minValue: minDate, maxValue: maxDate, ticks: tickMarks, format: 'd/M'},
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines: { color: 'gray' }, minValue: 36, maxValue: 42, ticks: [36,37,38,39,40,41,42] },

        };

        var chart = new google.visualization.LineChart(document.getElementById('PhysStatus_' + _uniqueid + '_' +_playerid));
        var view_freshness = new google.visualization.DataView(data);
        view_freshness.setColumns([0,1,2,3,4,5]);
        chart.draw(view_freshness, options);

        var chart_fever = new google.visualization.ScatterChart(document.getElementById('Fever_' + _uniqueid + '_' +_playerid));
        var view_fever = new google.visualization.DataView(data);
        view_fever.setColumns([0,7]);
        console.log("View");
        console.log(view_fever);
        chart_fever.draw(view_fever, optionsFever);

      }          

    });
  }

}
