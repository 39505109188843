<div class="app-page">
  <div class="app-page" style="margin-left: 20px;">
    <h3 *ngIf="this._Player">
        <img style="height: 24px;" src="../../assets/img/icons8-user-shield-48.png">
        {{ this._Player.LastName}}, {{ this._Player.FirstName}}
    </h3>
    <form [formGroup]="searchForm">
      <div style="font-family: Roboto;">
          Search parameters
      </div>
        <mat-form-field  appearance="fill" style="font-size: small;" >
          <mat-label>Last name</mat-label>
          <input matInput formControlName="PlayerLastName" placeholder="Last name" required>
          <mat-hint>Use * as wildcard</mat-hint>
        </mat-form-field>&nbsp;
        <mat-form-field  appearance="fill"  style="font-size: small;">
          <mat-label>First name</mat-label>
          <input matInput formControlName="PlayerFirstName" placeholder="First name" >
          <mat-hint>Use * as wildcard</mat-hint>
        </mat-form-field>&nbsp;
          <mat-form-field  appearance="fill"  style="font-size: small;">
            <mat-label>Tests</mat-label>
            <mat-select formControlName="TestIds" id="TestIds" multiple>
              <mat-option *ngFor="let test of _availableTests" [value]="test.TestId">{{test.Name}}</mat-option>
            
            </mat-select>
          </mat-form-field>&nbsp;
        
            <mat-form-field appearance="fill" style="font-size: small;">
              <mat-label > Date from</mat-label>
              <input matInput [matDatepicker]="pickerfrom" formControlName="fromTestDate"   name="fromTestDate">
              <mat-datepicker-toggle matSuffix [for]="pickerfrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerfrom></mat-datepicker>
          </mat-form-field>&nbsp;
          <mat-form-field appearance="fill" style="font-size: small;">
              <mat-label > Date to</mat-label>
              <input matInput [matDatepicker]="pickerto" formControlName="toTestDate"   name="toTestDate">
              <mat-datepicker-toggle matSuffix [for]="pickerto"></mat-datepicker-toggle>
              <mat-datepicker #pickerto></mat-datepicker>
          </mat-form-field>&nbsp;


            <!-- <mat-form-field  appearance="fill" style="font-size: small;">
              <mat-label>Max no of rows</mat-label>
              <mat-select formControlName="MaxHits" id="MaxHits" >
                  <mat-option selected value="50">50</mat-option>         
                  <mat-option value="100">100</mat-option>         
                  <mat-option value="200">200</mat-option>         
                  <mat-option value="500">500</mat-option>         
                
              </mat-select>
            </mat-form-field> -->
          &nbsp;
          <a mat-raised-button color="primary" (click)="this.searchTestResults()" >SEARCH</a>
        
    </form>
  </div>

   <!--  <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field> -->
    <mat-table matTableExporter  [dataSource]="dataSource" class="mat-elevation-z8" style="margin-left: 10px; margin-right: 20px; " >
        <ng-container matColumnDef="PerformedDate">
  
          <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
          <mat-cell *matCellDef="let test"> 
            
          {{test.PerformedDate | date:'yyyy-MM-dd  hh:mm:ss'}}
         </mat-cell>       
      </ng-container>
      
      <ng-container matColumnDef="TestName">
  
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let test"> 
            
          {{test.TestName}}
         </mat-cell>
      </ng-container>
      <ng-container matColumnDef="PlayerName">
  
          <mat-header-cell *matHeaderCellDef> Player </mat-header-cell>
          <mat-cell *matCellDef="let test"> 
            
          {{test.PlayerName}}
         </mat-cell>
      </ng-container>
  
  
  
      <ng-container matColumnDef="Result">
  
        <mat-header-cell *matHeaderCellDef> Result </mat-header-cell>
        <mat-cell *matCellDef="let test"> 
          
        {{test.Result}}
       </mat-cell>
    </ng-container>    
    <ng-container matColumnDef="ResultUnit">
  
      <mat-header-cell *matHeaderCellDef> Unit </mat-header-cell>
      <mat-cell *matCellDef="let test"> 
        
      {{test.ResultUnit}}
     </mat-cell>
  </ng-container>  
  <ng-container matColumnDef="Actions">
  
    <mat-header-cell *matHeaderCellDef>A  </mat-header-cell>
    <mat-cell *matCellDef="let test"> 
      
      <button mat-icon-button type="button" (click)="deleteValue(test.ResultId)" *ngIf="this.userService.roleMatch(['Test_Admin','Test_Perfomer']) "><mat-icon>delete</mat-icon></button>
   </mat-cell>
  </ng-container>  
  
  
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [length]="100" style="margin-left: 10px; margin-right: 20px; "
    [pageSize]="10" [pageSizeOptions]="[10, 15, 25]" showFirstLastButtons></mat-paginator>
    <br/>
    &nbsp;
  <!--   <a mat-raised-button (click)="exporter.exportTable('csv')">CSV</a>
    <button mat-raised-button (click)="exporter.exportTable('xlsx', {fileName:'Testresults', sheet: 'Test results', Props: {Author: 'DCS'}})">Excel</button> -->

  </div>
