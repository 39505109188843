import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { GameService } from 'src/app/scouting/services/game.service';
import { Season } from 'src/app/shared/team.model';
declare var google:any;

@Component({
  selector: 'app-game-ranking-chart',
  templateUrl: './game-ranking-chart.component.html',
  styleUrls: ['./game-ranking-chart.component.css']
})
export class GameRankingChartComponent implements OnInit {

  @Input() PlayerId: string;
  @Input() Season: Season;

  public uniqueid: string = Math.random().toString(36).substr(2, 9);
  
  constructor(private gameService: GameService ) { }

  ngOnInit() {
    //this.getGameRankingsForPlayer(this.PlayerId, this.uniqueid);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.getGameRankingsForPlayer(this.PlayerId, this.uniqueid);
  }

  getGameRankingsForPlayer(_playerid: string, _uniqueid: string): void{

    this.gameService.getGameRankingsForPlayerAndSeason_GoogleCharts(_playerid, this.Season.SeasonId.toString()).subscribe(datahttp => {
      //this.datajson = datahttp;
      
       google.charts.load('current', {'packages':['corechart']});
       google.charts.setOnLoadCallback(drawChart.bind(this));        
       function drawChart() {

        // Create the data table.
        var data = new google.visualization.DataTable(datahttp);

        data.addColumn({type:'string', role:'annotation'}); // annotation role col.

        //console.log(data);
        var minDate = new Date(this.Season.SeasonStartDate);
        //minDate.setMonth(minDate.getMonth()-2);

          //Tcikmarks on mondays
          var tickMarks = [];
          var maxDate = new Date(this.Season.SeasonEndDate);
          for (var d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
            if(d.getDay()==1){
              tickMarks.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            }
            
            
        }

        var options = {
          title: 'PLAYER SELF ASSESSMENT',
          legend: { position: 'bottom', textStyle: {color: 'white', fontSize: 12} },
          titleTextStyle: {color: 'white'},
          backgroundColor: '#303030',
          chartArea: {width: '95%'},
          annotation: { 0: {style: 'letter'}},
          annotations: {
            textStyle: {
              //auraColor: '#ccc',
              bold: true,
              color: 'white',
              fontSize: 10,
              opacity: 0.3
            }
          },
          displayAnnotations: true,
          height: 300,         
          colors: ['#FF5722','#FFC107','#4CAF50','#2E7D32','00BCD4'],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines: { color: 'gray' }, minValue: minDate, maxValue: maxDate, ticks: tickMarks, format: 'd/M'},
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines: { color: 'gray' }, minValue: 0, maxValue: 5, ticks: [0,1,2,3,4,5] },

        };

        var chart = new google.visualization.ScatterChart(document.getElementById('GameRanking_' + _uniqueid + '_' +_playerid ));
        chart.draw(data, options);
      }          

    });
  }

}
