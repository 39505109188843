import { Component, OnInit } from '@angular/core';
import { TeamService } from '../shared/team.service';
import { TeamOverviewModel } from '../shared/team.model';
import { Globals } from '../globals';
import { TestService } from '../shared/test.service';
import { TestTopList } from '../shared/models/test.model';

@Component({
  selector: 'app-organization-team-list',
  templateUrl: './organization-team-list.component.html',
  styleUrls: ['./organization-team-list.component.css']
})
export class OrganizationTeamListComponent implements OnInit {

  _teams: TeamOverviewModel[];
  _topLists: TestTopList[];
  

  constructor(
    public _teamService: TeamService,
    public _testService: TestService,
    public _globals: Globals

  ) { }

  ngOnInit() {

    this._globals.titleBarText ="TEAMS"
    this._teamService.getTeamOverview().subscribe((teams: TeamOverviewModel[])=>{
      this._teams = teams;
      
      
    });
  }

 

}
