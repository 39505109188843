import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/shared/form.service';
import { FormViewModel, FormsSubmissionViewModel } from 'src/app/shared/models/FormModels';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/sv';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-form-submission-edit',
  templateUrl: './form-submission-edit.component.html',
  styleUrls: ['./form-submission-edit.component.css']
})
export class FormSubmissionEditComponent implements OnInit {
  submissionId: string;
  form: UntypedFormGroup;
  formSubmission: FormsSubmissionViewModel;
  snackBarRef;

  constructor(private activatedRoute: ActivatedRoute, private formSrvc: FormService,private _adapter: DateAdapter<any>, private router: Router, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this._adapter.setLocale('sv');
    this.activatedRoute.params.subscribe(params => {
      this.submissionId = params['SubmissionId'];
      this.getData();
      
    });    
  }

  getData(){
    this.formSrvc.getFormSubmissionById(this.submissionId).subscribe((s: any)=>{
      console.log(s);
      this.formSubmission = s as FormsSubmissionViewModel;
      this.form = this.toFormGroup(s);
    });
  }
  
  toFormGroup(theForm: FormViewModel): UntypedFormGroup{
    const group: any = {};
    this.formSubmission.Form.FormVersion.Sections.forEach(section => {
      section.Questions.forEach(question => {
        var value: string = '';
        if(question.Answer!=null){
          switch (question.ValueType) {
            case 'Text':
              value=question.Answer.TextValue;
              break;
            case 'DateTime':
             /*  console.log(question.Answer.DateValue);
              console.log(question.Answer.DateValue); */
              value=question.Answer.DateValue ? question.Answer.DateValue.toString() : '';
              break;
            case 'Integer':
              value= question.Answer.IntValue.toString();
            default:
              break;
          }
        }
          
        group[question.Key] = question.Required ?  new UntypedFormControl(value,  Validators.required) : new UntypedFormControl(value) ;
      });      
    });

    group["FormId"] = new UntypedFormControl(this.formSubmission.Form.FormId);
    group["Version"] =  new UntypedFormControl(this.formSubmission.Version);
    
    group["PlayerId"] = new UntypedFormControl(this.formSubmission.PlayerId);
    //group["SubmitDate"] = new FormControl(new Date());
    group["RowVersion"] = new UntypedFormControl(this.formSubmission.RowVersion);
    group["SubmissionId"] = new UntypedFormControl(this.formSubmission.SubmissionId);
    console.log(this.formSubmission.DocumentDate);
    group["DocumentDate"]= new UntypedFormControl(this.formSubmission.DocumentDate,  Validators.required );



    //console.log( new FormGroup(group));
    return new UntypedFormGroup(group);
  }

  onSubmit(){
    //this.payLoad = JSON.stringify(this.form.value);
    //console.log(this.form.value);
    
    //Loop throug controls, put together a flat key/value pair array
    var keyvaluepairs= {};
    for (const field in this.form.controls) { // 'field' is a string

      const control = this.form.get(field); // 'control' is a FormControl  
      //console.log(control);
      var value = control.value; 
      keyvaluepairs[field]=value;
    
    }
    console.log(keyvaluepairs);
    //Post to backend as key/value pair list
    this.formSrvc.UpdateSubmission(JSON.stringify(keyvaluepairs)).subscribe(res=>{
      //console.log(res);
      if(res.Success)
        this.router.navigate(['team', this.formSubmission.TeamId, 'formfeed', this.formSubmission.FormId], { queryParams: { TeamName: this.formSubmission.TeamName }});
      else{
        this.openSnackBar(res.Message, "REFRESH");
      }
    });

    
    
    
  }

  back(){
    this.router.navigate(['team', this.formSubmission.TeamId, 'formfeed', this.formSubmission.FormId], { queryParams: { TeamName: this.formSubmission.TeamName }});
  }

  openSnackBar(message: string, action: string) {
    let snackBarRef = this._snackBar.open(message, action);

    snackBarRef.onAction().subscribe(() => {
      this.getData();
    });

  }

 

}
