import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import { InjuryService } from 'src/app/shared/injury.service';
import { InjuryTeamDashboardModel, InjuryViewModel } from 'src/app/shared/models/injurymodel';
import { Season } from 'src/app/shared/team.model';
import { TeamService } from 'src/app/shared/team.service';
declare var google:any;

@Component({
  selector: 'app-injury-team-overview',
  templateUrl: './injury-team-overview.component.html',
  styleUrls: ['./injury-team-overview.component.css']
})
export class InjuryTeamOverviewComponent implements OnInit {

  TeamId: string;
  

  constructor(public _route: ActivatedRoute,   
    public _teamService: TeamService,
    public injuryService: InjuryService,
    public _globals: Globals) 
  { 

    


    this._route.params.subscribe(params => {
      this.TeamId = params['TeamId'];
      injuryService.getOverviewByTeam(this.TeamId).subscribe( (data: InjuryTeamDashboardModel) =>{
        //console.log(data.BodyPartSeasonCount);
        this.drawPiechart(data.BodyAreaSeasonCount,'bodyareaseason_chart', 'Area',1);
        this.drawPiechart(data.BodyPartSeasonCount,'bodypartseason_chart', 'Part',1);
        this.drawPiechart(data.TypeSeasonCount,'typeseason_chart', 'Type of injury',1);
        this.drawPiechart(data.ConditionSeasonCount,'conditionseason_chart', 'Conditions',1);

        this.drawPiechart(data.BodyAreaActiveCount,'bodyareacurrent_chart','Area',1);
        this.drawPiechart(data.BodyPartActiveCount,'bodypartcurrent_chart','Part',1);
        this.drawPiechart(data.TypeActiveCount,'typecurrent_chart','Type of injury',1);
        this.drawPiechart(data.ConditionActiveCount,'conditioncurrent_chart','Conditions',1);

        this.drawPiechart(data.BodyAreaSeasonCount,'bodyareaseason_chart_days', 'Area',2);
        this.drawPiechart(data.BodyPartSeasonCount,'bodypartseason_chart_days', 'Part',2);
        this.drawPiechart(data.TypeSeasonCount,'typeseason_chart_days', 'Type of injury',2);
        this.drawPiechart(data.ConditionSeasonCount,'conditionseason_chart_days', 'Conditions',2);

        this.drawPiechart(data.BodyAreaActiveCount,'bodyareacurrent_chart_days','Area',2);
        this.drawPiechart(data.BodyPartActiveCount,'bodypartcurrent_chart_days','Part',2);
        this.drawPiechart(data.TypeActiveCount,'typecurrent_chart_days','Type of injury',2);
        this.drawPiechart(data.ConditionActiveCount,'conditioncurrent_chart_days','Conditions',2);
      });
    });
  }

  ngOnInit() {
  }

  drawPiechart(chartdata: any, divname: string, title: string, datacolumn: number){
    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(drawChart);        
    function drawChart() {
        // Create the data table.
        var data = new google.visualization.DataTable(chartdata);
        var view = new google.visualization.DataView(data);
        view.setColumns([0, datacolumn]);
  
        var options = {
          
          legend: { textStyle: {color: 'white', fontSize: 12}  },
          title: title,
          titleTextStyle: {color: 'white', fontName: 'Roboto', bold: false},
          height: 200,         
          chartArea: {width: '95%', left: 10},
          backgroundColor: '#303030',
          
          is3D: false,
         

        };

        var chart = new google.visualization.PieChart(document.getElementById(divname));
        chart.draw(view, options);
      }


  }


  drawchartBodyPartActive(chartdata: any){
    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(drawChart);        
    function drawChart() {
        // Create the data table.
        var data = new google.visualization.DataTable(chartdata);
        
  
        var options = {
          
          legend: { textStyle: {color: 'white', fontSize: 12}  },
          titleTextStyle: {color: 'white'},
          height: 300,         
          chartArea: {width: '95%'},
          backgroundColor: '#303030',
          
          is3D: true,
         

        };

        var chart = new google.visualization.PieChart(document.getElementById('bodypartactive_chart'));
        chart.draw(data, options);
      }


  }

}
