<div class="app-page">
       
  
                <form>
                  <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                <mat-form-field  style="font-size: small; width: 100px;" style="display: 'none';">
                                <mat-label>Roster - Season</mat-label>
                                <mat-select [formControl]="seasonFormControl" (selectionChange)="refreshData()">
                                  <mat-option *ngFor="let season of _seasons" [value]="season" >
                                    {{season.Name}}
                                  </mat-option>
                                </mat-select>
                </mat-form-field>       
                &nbsp;
                <mat-form-field style="font-size: 14px;" >
                        <mat-label>Testresults - search period</mat-label>
                        <mat-select [formControl]="periodFormControl"  (selectionChange)="changePeriod()">
                          <mat-option value="1D">
                            1 day back
                          </mat-option>
                          <mat-option value="1W">
                            1 week back
                          </mat-option>
                          <mat-option value="1M">
                            1 month back
                          </mat-option>
                          <mat-option value="3M" selected>
                            3 months back 
                          </mat-option>
                          <mat-option value="Season" selected>
                            From start of  season 
                          </mat-option>
                          <mat-option value="FromStart" selected>
                            Anytime
                          </mat-option>
                          
                          
                        </mat-select>
                </mat-form-field>
                &nbsp;
                <mat-form-field  style="font-size: 14px; width: 200px;">
                                <input matInput placeholder="Filter players" #playerFilter >
                </mat-form-field>          
<!--                 <mat-icon class="example-icon" aria-hidden="false" aria-label="Example user verified icon">filter_list</mat-icon> -->

                <a mat-button [routerLink]="['/teamprofilesplayers', this.TeamId, true]">
                  <i class="material-icons" style="height: 24px; ">
                    table_chart
                </i>&nbsp;
                  TABLE
                </a>
                <a mat-button   [routerLink]="['/testtoplist/team/', this.TeamId]" routerLinkActive="active-list-item" >  
                  <i class="material-icons" style="height: 24px; ">
                    format_list_numbered
                  </i>
                  SEASON TOP 10
                </a>
                </div>  
               <!--  <button mat-raised-button (click)="editRoster()" *ngIf="this._userService.roleMatch('Test_Admin')" >EDIT ROSTER</button> -->
                </form>
                <br/>


         <div class="loading-indicator" *ngIf="this.isBusy">
                <mat-progress-spinner mode="indeterminate" ></mat-progress-spinner>
        </div> 

        <div *ngIf="this.Players && this.Players.length==0" style="display: flex; flex-wrap: wrap; justify-content: center; margin-top: 100px; ">
                <h2>
                         No players found
                </h2>                                            
        </div>

        <div *ngIf="Team && this.Players">
                <ng-container *ngFor="let position of Team.PositionProfiles, let i=index">
                        <div style="display: flex; flex-wrap: wrap; justify-content: center; ">
                              
                                <app-player-card *ngFor="let player of this.Players.filter(filterPlayer, position.PositionId)"  [Player]="player" [Profile]="Team.Profiles.find(filterProfile, position.TestProfileId)" [TeamId]="this.TeamId" [SeasonId]="this.selectedSeason" (changed)="onCardChanged()"></app-player-card>
                        </div>
                </ng-container>
        </div>
                
    <div *ngIf="false" style="display: flex; flex-wrap: wrap; justify-content: center; ">
             
            <app-player-card *ngFor="let player of this.Players.filter(filterGoalies)"  [Player]="player" [Profile]="Profile" [TeamId]="this.TeamId" [SeasonId]="this.selectedSeason" (changed)="onCardChanged()"></app-player-card>
    </div>
    <div *ngIf="false" style="display: flex; flex-wrap: wrap;justify-content: center; ">
           
            <app-player-card *ngFor="let player of this.Players.filter(filterDefenders)"  [Player]="player" [Profile]="Profile" [TeamId]="this.TeamId" [SeasonId]="this.selectedSeason" (changed)="onCardChanged()"></app-player-card>
    </div>
    <div *ngIf="false" style="display: flex; flex-wrap: wrap;justify-content: center; ">
           
        <app-player-card *ngFor="let player of this.Players.filter(filterMidfielders)"  [Player]="player" [Profile]="Profile" [TeamId]="this.TeamId" [SeasonId]="this.selectedSeason" (changed)="onCardChanged()"></app-player-card>
        </div>
    <div *ngIf="false" style="display: flex; flex-wrap: wrap; justify-content: center;">
           
            <app-player-card *ngFor="let player of this.Players.filter(filterForwards)"  [Player]="player" [Profile]="Profile" [TeamId]="this.TeamId" [SeasonId]="this.selectedSeason" (changed)="onCardChanged()"></app-player-card>
    </div>
    
<!-- 1hidden="checkHidden(player, searchString)" -->

</div>

