<div class="app-page" style="display: flex; flex-direction: column; justify-content: center;margin-left:10px; font-style: Roboto; height: 100%;">
    <mat-toolbar>
        <mat-icon mat-list-icon style="font-size: 24px; font-weight: bold;"  >format_list_numbered</mat-icon>
        <span>{{_list?.Description}}</span>
    </mat-toolbar>
    <div style="display: flex; flex-direction: row; height: 95%;" >
        <div style=" overflow-y: scroll; background-color: #303030; min-width: 330px; max-width: 330px; " *ngIf="_list">
            <div>

                <span mat-line *ngIf="_list.Category" style="color: gray;"><mat-icon style="font-size: small; height: 13px;width: 13px;">category</mat-icon> {{_list.Category}} </span>

            </div>
         
            <button mat-raised-button *ngIf="_list.IsMyList || _list.CanEdit" (click)="addPlayer()">ADD PLAYER</button>            
            &nbsp;
            <button mat-raised-button *ngIf="_list.IsMyList || _list.CanEdit"
                [routerLink]="['/scouting/rankinglistedit',_list.ListId]">
                 SETTINGS
            </button>
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)" *ngIf="_list.players">
                <div *ngIf="_list.players.length==0" style="margin-left: 5px; color:gray;">
                    No players in list
                </div>
                <div class="example-box" *ngFor="let player of _list.players; let i=index" cdkDrag  [cdkDragDisabled]="_list.IsMyList===false && _list.CanEdit===false">
                 
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                        <div cdkDragHandle style="font-weight: bold; width: 30px; font-size: large; font-family: Roboto; flex-grow: 1;" >
                            {{i+1}}
                        </div> 
                        <div class="listitem" style="flex-grow: 10;" (click)="this.selectedPlayer=player.Player.LifelineScoutingId">
                                {{player.Player.LastName}}, {{player.Player.FirstName}}<br/>
                                <span style="font-size: small;">{{player.Player.DateOfBirth | date: 'yyyy-MM-dd'}}</span>
                                <div style="display: flex;">
                                    <mat-icon> rate_review </mat-icon>&nbsp;
                                    <label>{{player.NoOfReports}} </label>&nbsp;
                                    <mat-icon>note</mat-icon>&nbsp;{{player.NoOfNotes}}</div>
                        </div>
                        <button mat-icon-button *ngIf="_list.IsMyList || _list.CanEdit" style="flex-grow: 1;" (click)="confirmDelete(player.Player.LifelineScoutingId)">
                            <mat-icon>delete</mat-icon>
                        </button>
                   
                </div>
              </div>                      
        </div>

       

        <div  >
            <div *ngIf="!this.selectedPlayer">
                <br/>
                <label style="color: darkgray; font-family: Rubik;">Select player from list to display details</label>
            </div>
           <app-player-total-view *ngIf="this.selectedPlayer" [PlayerId]="this.selectedPlayer"></app-player-total-view>
        </div>
      
        
    </div>

</div>
