import { Objective, Stream } from "../models/objectives";

export const OBJECTIVES_DATA: Objective[] = 
[
/*     {
        "ObjectiveId": "test",
        "ParentObjective": null,
        "Name": "Rekrytera som fan",
        "AssignedTo_Name": "Andreas",
        "AssignedTo_UserId": "id",
        "CurrentValue": 0,
        "DueDate": new Date(),
        "KeyResults": [
            {
                "KeyResultId": "1",
                "Name": "Scouta 1000 spelare",
                "StartValue": 0,
                "AssignedTo_Name": "Fredrik Sjöström",
                "AssignedTo_UserId":"12",
                "CurrentValue": 352,
                "Tasks": null,
                "Weight": 1,
                "TargetValue": 1000
            },
            {
                "KeyResultId": "2",
                "Name": "Bevaka 200 matcher",
                "StartValue": 0,
                "AssignedTo_Name": "Fredrik Sjöström",
                "AssignedTo_UserId":"12",
                "CurrentValue": 10,
                "Tasks": null,
                "Weight": 1,
                "TargetValue": 200
            },
        ],
        "StartDate": new Date(),
        "StartValue": 0,
        "TargetValue": 100
    },
    {
        "ObjectiveId": "test",
        "ParentObjective": null,
        "Name": "Fysa spelarna",
        "AssignedTo_Name": "Andreas",
        "AssignedTo_UserId": "id",
        "CurrentValue": 0,
        "DueDate": new Date(),
        "KeyResults": [
            {
                "KeyResultId": "1",
                "Name": "Skapa fysprofiler",
                "StartValue": 0,
                "AssignedTo_Name": "Fredrik Sjöström",
                "AssignedTo_UserId":"12",
                "CurrentValue": 352,
                "Tasks": null,
                "Weight": 1,
                "TargetValue": 1000
            },
            {
                "KeyResultId": "2",
                "Name": "Göra träningsprogram",
                "StartValue": 0,
                "AssignedTo_Name": "Fredrik Sjöström",
                "AssignedTo_UserId":"12",
                "CurrentValue": 10,
                "Tasks": null,
                "Weight": 1,
                "TargetValue": 200
            },
        ],
        "StartDate": new Date(),
        "StartValue": 0,
        "TargetValue": 100
    },
    {
        "ObjectiveId": "test",
        "ParentObjective": null,
        "Name": "Fysa spelarna",
        "AssignedTo_Name": "Andreas",
        "AssignedTo_UserId": "id",
        "CurrentValue": 0,
        "DueDate": new Date(),
        "KeyResults": [
            {
                "KeyResultId": "1",
                "Title": "Skapa fysprofiler",
                "StartValue": 0,
                "AssignedTo_Name": "Fredrik Sjöström",
                "AssignedTo_UserId":"12",
                "CurrentValue": 352,
                "Tasks": null,
                "Weight": 1,
                "TargetValue": 1000
            },
            {
                "KeyResultId": "2",
                "Title": "Göra träningsprogram",
                "StartValue": 0,
                "AssignedTo_Name": "Fredrik Sjöström",
                "AssignedTo_UserId":"12",
                "CurrentValue": 50,
                "Tasks": null,
                "Weight": 1,
                "TargetValue": 200
            },
            {
                "KeyResultId": "2",
                "Name": "Göra träningsprogram",
                "StartValue": 0,
                "AssignedTo_Name": "Fredrik Sjöström",
                "AssignedTo_UserId":"12",
                "CurrentValue": 10,
                "Tasks": null,
                "Weight": 1,
                "TargetValue": 200
            },
        ],
        "StartDate": new Date(),
        "StartValue": 0,
        "TargetValue": 100
    } */
]

export const STREAMS_DATA: Stream[] = [
    {
    "Name": "Sporten",
    "StreamId": "llllll"
    }   ,
    {
        "Name": "Fysio",
        "StreamId": "lllll2"
    }   ,
    {
        "Name": "Sales",
        "StreamId": "lllll3"
    }   
]