
<div style="margin-left: 20px; margin-top: 20px;">
    <h1 *ngIf="this._testviewid!='NEW'">EDIT VIEW</h1>
    <h1 *ngIf="this._testviewid=='NEW'">NEW VIEW</h1>
    <form [formGroup]="editTestView" *ngIf="this.testview || this._testviewid=='NEW'">
       

       
       <mat-form-field style="width: 350px;">
         <input matInput placeholder="Name" formControlName="Name" maxlength="50" >
         <mat-hint style="font-size:10px; color: gray;">A name to identify the view, only visible in configuration section</mat-hint>    
       </mat-form-field>
       <br/>
       <br/>
       <mat-form-field style="width: 350px;">
           <input matInput placeholder="Display name" formControlName="DisplayName" maxlength="50"  >   
           <mat-hint style="font-size:10px; color: gray;">This text will be visible in the menu for the users</mat-hint>                     
       </mat-form-field><br/>

       <h3>PROFILES</h3> 
       <div style="font-size:12px; color: gray;">
        Select which profile to display for player positions. Only players with positions that has a profile will be displayed in the view.
      </div>
       <table style="margin-left: 20px;">
        <tr *ngFor="let profile of  getTestViewProfiles_FormArray.controls ;let j = index;" formArrayName="Profiles">
          <ng-container [formGroupName]="j">
            <td style="min-width: 120px;">{{profile.controls['Name'].value}}</td>
            <td style="min-width: 120px;" >
              <mat-form-field>
                
             <mat-select formControlName="ProfileId">
              <mat-option >
                NO PROFILE
              </mat-option>
              <mat-option *ngFor="let selectable_profile of this._allprofiles" [value]="selectable_profile.ProfileId">
                <img style="height: 24px; " src="../../assets/img/icons8-scorecard-48.png" >{{selectable_profile.Name}}
              </mat-option>
             </mat-select>
            </mat-form-field>
              
            </td>
          </ng-container> 
        </tr>
       </table>



     
      <div style="display: flex; flex-direction: row; align-items: center;">
        <h3>VISIBILITY</h3> 
     
        <a mat-button (click)="addTeam()">
          <mat-icon>add</mat-icon>
          ADD TEAM
        </a>
      </div>
      <div style="font-size:12px; color: gray;">
        Add the teams the view should be available for.
        You can give access to the view in two ways, "Visible for all" or specify which users that have access.<br/>
        To see a view, the user must have one of the roles of Test administrator, Test Performer or Test viewer, and have access to the teams "Test" data.<br/>
        If you select "Visible for all", it is visible for all users  with above requirements.<br/>
        If you dont select "Visible for all", you need to specify which users that can access the view. The the access is restricted to only the specified users.<br/>
      </div>
      <br/>

      <div *ngFor="let team of  getTestViewTeams_FormArray.controls ;let i = index;" formArrayName='Teams' >
        <div [formGroupName]="i" >

          <table>
            <!--  <tr>
               <th>Team</th>
               <th>Enabled</th>
               <th>Visible for all</th>
               <th></th>
             </tr> -->
             <tr>
               <td style="font-weight: bold;">
                 <img src="../../assets/img/shield.png" />
               
                 {{team.controls['LevelName'].value}}
               </td>
               <td>
                 <mat-checkbox formControlName="Enabled" >
                   Enabled
                  </mat-checkbox>
               </td>
               <td>
                <mat-checkbox formControlName="VisibleForAll" >
                  Visible for all
                 </mat-checkbox>
               </td>
               <td>
                  <a mat-raised-button (click)="removeTeam(i, team.controls['TeamId'].value)">REMOVE</a>
               </td>
             </tr>
           </table>

           <div style="display: flex; flex-direction: row;">
           
            <table style="margin-left:50px;" >
              <tr>
                <th>User</th>
                <th></th>
              </tr>
              <tr *ngIf="team.controls['Users'].length==0">
                <td style="color: gray;">
                  NO USERS ADDED
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr *ngFor="let user of team.controls['Users'].controls; let userindex =index">
                  <td>
                    <mat-icon>person</mat-icon>
                   
                    {{user.controls['LastName'].value }}, {{user.controls['FirstName'].value }}
                  </td>
                  <td>
                    <a mat-icon-button (click)="removeUser(i,userindex);">
                      <mat-icon>delete</mat-icon>
                    </a>
                  </td>

              </tr>
              <tr>
                <td>
                  <a mat-button (click)="addUser(i)">
                    <mat-icon>add</mat-icon>
                    ADD USER
                  </a>
                </td>
              </tr>
                

            </table>
          
          </div>

           <mat-divider></mat-divider>

        </div>
      </div>
      
      <mat-divider></mat-divider>
      <div formArrayName="Teams">
     <!--    <div *ngFor="let team of this.testview.Teams; index as i" >
          <div [formGroupName]="i">
            <table>
           
              <tr>
                <td style="font-weight: bold;">
                  <img src="../../assets/img/shield.png" />
                
                  {{team.LevelName}}
                </td>
                <td>
                  <mat-checkbox [checked]="team.Enabled">
                    Enabled
                </mat-checkbox>
                </td>
                <td>
                  <mat-checkbox [checked]="team.VisibleForAll">
                    Visible for all
                </mat-checkbox>
                </td>
                <td>

                </td>
              </tr>
            </table>
            
            <div style="display: flex; flex-direction: row;">
            
              <table style="margin-left:50px;" >
                <tr>
                  <th>User</th>
                  <th></th>
                </tr>
                <tr *ngIf="team.Users || team.Users.length==0">
                  <td style="color: gray;">
                    NO USERS ADDED
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr *ngFor="let user of team.Users; index as userindex">
                    <td>
                      <mat-icon>person</mat-icon>
                      {{user.LastName}}, {{user.FirstName}}
                    </td>
                    <td>
                      <a mat-icon-button>
                        <mat-icon>delete</mat-icon>
                      </a>
                    </td>

                </tr>
                <tr>
                  <td>
                    <a mat-button>
                      <mat-icon>add</mat-icon>
                      ADD USER
                    </a>
                  </td>
                </tr>
                  

              </table>
            
            </div>
            <mat-divider></mat-divider>
           
           <!--  <div style="margin-left: 10px;" formArrayName="Skills">


             <div *ngFor="let skill of group.Skills; index as skillindex" >
                <div style="font-family: Roboto">
                  {{skill.Text}} 
                </div>
                <div style="margin-left: 10px;" [formGroupName]="skillindex">
                  <mat-button-toggle-group formControlName="Value"  >
                    <mat-button-toggle *ngFor="let op of mylist" 
                      [value]="op"          
                      [ngClass]="{'bg-bad': this['selected'+skill.SkillId] === 1,'bg-average':this['selected'+skill.SkillId] === 2,'bg-good':this['selected'+skill.SkillId] === 3,'bg-verygood':this['selected'+skill.SkillId] === 4,'bg-excellent':this['selected'+skill.SkillId] === 5}" 
                      (click)="this['selected'+skill.SkillId] = op;">
                      <div  style="font-size: small; line-height: 22px;display: flex; flex-direction: column;">
                        <div>
                            {{op}}
                        </div> 
                        <div>
                            {{getText(op) }}
                        </div>
                      </div>                                          
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              
             </div>
             
            </div>
          </div>
          
          
        </div> -->
        
      </div>

     
    </form>
</div>
<br/>
<a mat-raised-button color="primary" (click)="submit()" [disabled]="!editTestView.valid">SAVE</a>
<a mat-button (click)="cancel()">CANCEL</a>

<!-- <div>
  {{this.editTestView.value | json}}
</div> -->

