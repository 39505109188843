import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';
import { TeamEventViewModel } from 'src/app/load/models/gameevent';
import { ScheduleViewModel } from 'src/app/scouting/models/ScheduleModels';
import { BaseService } from 'src/app/shared/baseservice';
import { ScheduleEvent, TeamEventModel, TeamScheduleViewModel } from '../models/schedulemodels';

@Injectable({
  providedIn: 'root'
})
export class TeamScheduleService   extends BaseService{

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }
   

  "api/scouting/schedule/addgame"


  getSchedule(TeamId: string, year: number, month: number){
    
    let ret = this.http.get<ScheduleViewModel>(this.rootUrl + '/api/team/' + TeamId + '/schedule/' + year + "/" + month );
    return ret;
  }

  getScheduleWeek(TeamId: string, year: number, month: number, day: number){
    
    let ret = this.http.get<ScheduleViewModel>(this.rootUrl + '/api/team/' + TeamId + '/schedule/week/' + year + "/" + month + "/" + day );
    return ret;
  }

  getPlayerSchedule(PlayerId: string, year: number, month: number){
    
    let ret = this.http.get<ScheduleViewModel>(this.rootUrl + '/api/load/player/' + PlayerId + '/schedule/' + year + "/" + month );
    return ret;
  }

  getPlayerScheduleWeek(PlayerId: string, year: number, month: number, day: number){
    
    let ret = this.http.get<ScheduleViewModel>(this.rootUrl + '/api/load/player/' + PlayerId + '/schedule/week/' + year + "/" + month + "/" + day );
    return ret;
  }

  saveTeamEvent(TeamId: string, event: TeamEventModel): Observable<ScheduleEvent>{
    let ret = this.http.post<ScheduleEvent>(this.rootUrl + '/api/team/' + TeamId + '/schedule/event', event, this.httpOptions);
    return ret;
    
  }

  addGameToSchedule(GameId: string, StartTime: Date, Comment: string, Visibility: string ): Observable<boolean>{

    var data = {
      GameId: GameId,
      EventStart: StartTime, 
      Comment: Comment,
      Visibility: Visibility
    };
    console.log(data);
    let ret = this.http.post<boolean>(this.rootUrl + '/api/scouting/schedule/addgame', data, this.httpOptions);
    return ret;
    
  }

  getTeamEvent(TeamId: string, EventId: string){
    let ret = this.http.get<TeamEventViewModel>(this.rootUrl + '/api/team/' + TeamId + '/schedule/event/' + EventId );
    return ret;
  }

  deleteTeamEvent(TeamId: string, EventId: string){
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/team/' + TeamId + '/schedule/event/' + EventId );
    return ret;
  }


  getTeamEventWithLogs(EventId: string){
    
    let ret = this.http.get<TeamEventViewModel>(this.rootUrl + '/api/load/events/teamevent/' + EventId );
    return ret;
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {    
      if (error instanceof HttpErrorResponse) {
        console.log("error status=" + error.status);
        if (error.status === 401) {
          // redirect to the login route
          // or show a modal 
          window.location.href="/Account/Login";
          //this.router.navigate(['/notlo//console.log("Redirecting to login");
          
        }else{
          //window.location.href="/Account/Login";
        }
      }
    
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
