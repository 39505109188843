<div style="display: flex; flex-direction: column; margin: 10px;padding:10px ;" class="checkintitle">
    <div style="display: flex; flex-direction: row; ">
        <mat-icon style="font-size: 32px;">history</mat-icon>
        <div style="width: 380px; margin-left:20px;  font-family: Roboto; font-size: large; "  >
            <div>{{this.Checkin.OwnerInfo.LastName}},{{this.Checkin.OwnerInfo.FirstName}}</div>
            <div>{{this.Checkin.CheckinDate | date}}</div>
        </div>
    </div>
    
    
    <!-- Status change -->
    <div class="flexdiv">
        <div class="flexdivtitle">
            <mat-label>Status change</mat-label>
        </div>
        <div style="display: flex; flex-direction: row;">
            <app-objective-status-div [Status]="this.Checkin.StatusPrevious"></app-objective-status-div>
            &nbsp;
            <mat-icon>east</mat-icon>
            &nbsp;
            <app-objective-status-div [Status]="this.Checkin.Status"></app-objective-status-div>
        </div>
      
    </div>
    <!-- Texts -->
<!--     <div style="display: flex; flex-direction: row; flex-wrap: wrap;"> -->
        <div class="flexdiv" *ngIf="this.Checkin.Progress!=''">
       
                <div class="flexdivtitle">
                    <mat-label>Progress since last checkin</mat-label>
                </div>
                
                <div  *ngIf="this.Checkin.Progress!=''">
                    {{this.Checkin.Progress}}
                    
                </div>
                <div style="margin-left: 30px;" *ngIf="this.Checkin.Progress==''">
                    -
                    
                </div>
          
        </div>
        <div class="flexdiv" *ngIf="this.Checkin.Confidence!=''">
            <div class="flexdivtitle">
                <mat-label>Confidence in reaching the objective</mat-label>
            </div>
            <div  *ngIf="this.Checkin.Confidence!=''">
                {{this.Checkin.Confidence}}
            </div>
            <div style="margin-left: 30px;" *ngIf="this.Checkin.Confidence==''">
                -
                
            </div>
        </div>
    <!-- </div> -->
    <!-- <div style="display: flex; flex-direction: row;"> -->
        <div class="flexdiv" *ngIf="this.Checkin.Impediments!=''">
            <div class="flexdivtitle">
                <mat-label>What is stopping me from reaching the objective</mat-label>
            </div>
            <div *ngIf="this.Checkin.Impediments!=''">
                {{this.Checkin.Impediments}}
            </div>
            <div style="margin-left: 30px;" *ngIf="this.Checkin.Impediments==''">
                -
                
            </div>
        </div>
        <div class="flexdiv" *ngIf="this.Checkin.Initiatives!=''">
            <div class="flexdivtitle">
                <mat-label>Initiatives to improve progress</mat-label>
            </div>
            <div *ngIf="this.Checkin.Initiatives!=''">
                {{this.Checkin.Initiatives}}
            </div>
            <div style="margin-left: 30px;" *ngIf="this.Checkin.Initiatives==''">
                -
                
            </div>
        </div>
        <div>
            <button mat-button color="warn" (click)="deleteCheckin()">DELETE</button>
        </div>
 <!--    </div> -->



</div>