import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TeamScheduleService } from 'src/app/TeamSchedule/services/team-schedule.service';

@Component({
  selector: 'app-add-to-schedule-dialog',
  templateUrl: './add-to-schedule-dialog.component.html',
  styleUrls: ['./add-to-schedule-dialog.component.css']
})
export class AddToScheduleDialogComponent implements OnInit {

  _form: UntypedFormGroup;
  _gameId: string;
  _gameDate: string;
  _gameTime: string;
  _homeTeam: string;
  _awayTeam: string;


  constructor(private fb: UntypedFormBuilder,
              public dialogRef: MatDialogRef<AddToScheduleDialogComponent>,  
              private _scheduleService: TeamScheduleService,
              @Inject(MAT_DIALOG_DATA) public data: any, 
              private _snackBar: MatSnackBar,   ) {

    this._gameId = this.data.GameId;
    this._gameDate = this.data.GameDate;
    this._gameTime = this.data.GameTime;
    this._homeTeam = this.data.HomeTeam;
    this._awayTeam = this.data.AwayTeam;
    //console.log(this._gameDate);
    //console.log(this._gameTime);

    this._form = this.fb.group({    
      _comment: '',
      _private: false 
    });
    
   }



 

  ngOnInit(): void {
  }

  add(){
    var Visibility: string;
    if(this._form.value._private)
      Visibility = "PRIVATE";
    else
    Visibility = "ORGANIZATION";


    this._scheduleService.addGameToSchedule(this._gameId, new Date(), this._form.value._comment,Visibility).subscribe(res=>{
      if(res){
        this.openSnackBar("ADDED TO SCHEDULE", "CLOSE");
        this.dialogRef.close();
      }
      else
        this.openSnackBar("FAILED", "CLOSE");

    });

  }

/*   submit(){
    this._scheduleService.addGameToSchedule(this._gameId, new Date(),'',false).subscribe(res=>{
      if(res)
        this.openSnackBar("ADDED TO SCHEDULE", "CLOSE");
      else
        this.openSnackBar("FAILED", "CLOSE");

    });

  } */

  cancel(){
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
