<div class="app-page"  style="padding-top: -10px; padding-right:0;">

    <div *ngFor="let team of teams">
        <ng-container *ngIf="team.TeamId==this.TeamId">
            <mat-button-toggle-group #group="matButtonToggleGroup" name="fontStyle" aria-label="Font Style">
               
                <mat-button-toggle  value="6+6">6m + 6m</mat-button-toggle> 
                <mat-button-toggle  value="3+3">3m + 3m</mat-button-toggle>
                <mat-button-toggle  value="2+0">2m + 0m </mat-button-toggle>
                <mat-button-toggle  value="12+0">12m + 0m</mat-button-toggle> 
            </mat-button-toggle-group>
            <div>Only team level events are included.</div>
            <app-team-loadlevel-chart [TeamId]="team.TeamId" [Period]="group.value" ></app-team-loadlevel-chart>
            <app-team-loadlevelincrease-chart [TeamId]='team.TeamId' [Period]="group.value" ></app-team-loadlevelincrease-chart>
            <app-team-loadlevel-acwrchart [TeamId]='team.TeamId' [Period]="group.value"></app-team-loadlevel-acwrchart>
        </ng-container> 
    </div>
</div>  
