<div class="app-page">
    <h3>
        <mat-icon>dynamic_form</mat-icon>
        SUBMITTED - {{formName}} ({{teamName}})
    </h3>
    <button mat-raised-button *ngIf="this.theform.AllowCreate" [routerLink]="['/formsubmission/new', formId]" [queryParams]="{TeamId: teamId, TeamName: teamName  }" color="primary" style="margin-right: 3px;" >
        <mat-icon>
            dynamic_form
        </mat-icon>
        NEW
    </button>
    <button mat-button [routerLink]="['/team',  teamId, 'formfeed', formId]" [queryParams]="{FormName: formName, TeamName: teamName  }"  style="margin-right: 3px;">
        <mat-icon>
            dynamic_form
        </mat-icon>
        CARDS
    </button>
    <br/>
    <br/>
    <mat-divider></mat-divider>
    <app-form-submission-table [FormId]="this.formId"  [TeamId]="this.teamId">
    
    </app-form-submission-table>
    
</div>
