
<mat-card appearance="outlined" STYLE="max-width: 400px; margin: 10px;">
  <mat-card-header>  
    <div mat-card-avatar>
    
        <i class="material-icons" *ngIf="!(this.Test.Global)">
            assignment_turned_in
            </i>
      </div>    
    <mat-card-title> {{ this.Test.Name }}</mat-card-title>
    <mat-card-subtitle> Unit: {{ this.Test.ResultUnit }}</mat-card-subtitle>
    
  </mat-card-header>    
  <mat-card-content>
    <p>
    {{ this.Test.Description }}
 

  </mat-card-content>
  <mat-card-actions>
    <button mat-button [routerLink]="['/testedit', this.Test.TestId]">EDIT</button>
    <button mat-button>DELETE</button>
  </mat-card-actions>
</mat-card>
