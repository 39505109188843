import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from 'src/app/shared/form.service';
import { Validators } from '@angular/forms';
import { FormViewModel } from 'src/app/shared/models/FormModels';
import { TeamService } from 'src/app/shared/team.service';
import { TeamViewModel } from 'src/app/scouting/models/TeamModels';
import { MatDialog } from '@angular/material/dialog';
import { SelectSingleTeamDialogComponent } from 'src/app/shared/components/select-single-team-dialog/select-single-team-dialog.component';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.css']
})
export class FormSettingsComponent implements OnInit {
  formId: string;
  form: FormViewModel;
  mode: string = "";

  _reactiveForm: UntypedFormGroup; 
  _teams: TeamViewModel[];

  constructor(private formService: FormService, 
    private teamService: TeamService, 
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    private fb: UntypedFormBuilder, 
    private location: Location, 
    private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.formId = params['FormId'];
      this.mode = params['mode'];
      if(this.mode=="new"){
        this.buildFormCreate();

      }else{
        this.getSettings();
      }
     
      this.getTeams();


    });

  }

  getSettings(){
    this.formService.getFormSettingsById(this.formId).subscribe( data =>{
      this.form = data as FormViewModel;

      this.buildForm();
      //console.log(this.form);
    });
  }

  getTeams(){
    this.teamService.getTeams().subscribe(data=>{
      this._teams = data as TeamViewModel[];
    });

  }

  addTeam(){
    const dialogRef = this.dialog.open(SelectSingleTeamDialogComponent, {
      data: { Action: 'ADD'},
    });

    dialogRef.afterClosed().subscribe(result => {
      //Dont add same team multiple times
      var alreadyadded: boolean;
      this.TeamForms.controls.forEach(element => {
        if(element.get('TeamId').value==result.TeamId)
          alreadyadded = true;
        //console.log(element.get('TeamId').value);
      });      
      if(!alreadyadded)
        this.TeamForms.push(this.fb.group(
          {TeamName: result.Level, TeamId: result.TeamId}
        ));
      
    });
  }

  removeTeam(TeamId: string){
    console.log('Deleting');
    var indextodelete;
    for (let index = 0; index < this.TeamForms.controls.length; index++) {

      if(this.TeamForms.controls[index].get('TeamId').value==TeamId)
        indextodelete = index;;
    }

    this.TeamForms.removeAt(indextodelete);
  }

  get TeamForms() {
    return (this._reactiveForm.get("TeamForms")  as UntypedFormArray);
  }

  buildForm(){

    var teamarray = this.fb.array([]);
    this.form.TeamForms.forEach( team=>{
      teamarray.push(this.fb.group({
        TeamName: team.TeamName,
        TeamId: team.TeamId
        })
      );
    });

    this._reactiveForm = this.fb.group({
      FormId: this.form.FormId,
      Name:  [this.form.Name, Validators.required],
      Description: this.form.Description,
      MultiEdit: this.form.MultiEdit,
      Visible: this.form.Visible, 
      AllowCreate: this.form.AllowCreate, 
      TeamForms: teamarray
    });
    //console.log(this._reactiveForm);
  }

  buildFormCreate(){

    var teamarray = this.fb.array([]);
   

    this._reactiveForm = this.fb.group({
      FormId: "",
      Name:  ["", Validators.required],
      Description: "",
      MultiEdit: false,
      Visible: false, 
      AllowCreate: false, 
      TeamForms: teamarray
    });
    //console.log(this._reactiveForm);
  }

  back(){
    this.location.back();
  }

  openSnackbar(message: string, action = '', config?: MatSnackBarConfig) {
    return this.snackBar.open(message, action, config);
  }

  save(){
    if(this.mode=='new'){
      this.formService.createForm(this._reactiveForm.value).subscribe(result=>{
        console.log(result);
        if(result.Success){
          this.openSnackbar("New form created", 'OK', {duration: 2000});
          this.router.navigate(['/forms/edit', result.Message]);
        }
      });
    }
    else{
      this.formService.UpdateFormSettings(this._reactiveForm.value).subscribe(result=>{
        //console.log('create');
        if(result.Success)
          this.openSnackbar("Form settings updated.", 'OK', {duration: 2000});
          this.location.back();
        }
      );
    }
    

  }

}
