import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-text-divider',
  templateUrl: './text-divider.component.html',
  styleUrls: ['./text-divider.component.css']
})
export class TextDividerComponent implements OnInit {
  @Input() DividerText: string;
  constructor() { }

  ngOnInit() {
  }

}
