import { Component, OnInit, ViewChild } from '@angular/core';
import { Test } from 'src/app/shared/models/test.model';
import { TestService } from 'src/app/shared/test.service';
import { MatDialog } from '@angular/material/dialog';
import { TestAddComponent } from '../test-add/test-add.component';
import { Globals } from 'src/app/globals';
import { TestEditorComponent } from '../test-editor/test-editor.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-test-table',
  templateUrl: './test-table.component.html',
  styleUrls: ['./test-table.component.scss']
})




export class TestTableComponent implements OnInit {

  displayedColumns: string[] = ['Image','Name','Description', 'ResultUnit','Ranking', 'ResultType', 'Actions'];
  //dataSource: Test[];
  dataSource = new MatTableDataSource<Test>();
  Tests: Test[];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private testService: TestService,
    public dialog: MatDialog,
    private _globals: Globals  ) { }
  
   


  ngOnInit() {
    this._globals.titleBarText="Tests"
      this.testService.getAllTests().subscribe((data: any) => {
        this.dataSource = new MatTableDataSource<Test>(data);
        this.dataSource.paginator = this.paginator;
      })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  getRankingArrow(type: string): string{

    switch(type){
      case "<=":
        return "arrow_downward";
        break;
      case ">=":
        return "arrow_upward";
        break;
      case "==":
        return "arrow_forward";
        break;
      case "NO":
        return "clear"
    }
  }

  getTypeText(resulttype: number): string{
    console.log(resulttype);
    switch(resulttype){
      case 10:
        return "Integer";
        break;
      case 20:
          return "1 decimal";
          break;
      case 21:
        return "2 decimals";
        break;
      case 30:
        return "Time";
        break;
      case 31:
        return "Time";
        break;
      case 40:
        return "Text";
        break;
    }
  }

  
  addClick(){
    const dialogRef = this.dialog.open(TestAddComponent, {
      width: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.testService.getAllTests().subscribe((data: any) => {
        this.dataSource = new MatTableDataSource<Test>(data);;
        this.dataSource.paginator = this.paginator;
      });  
    });
    
  }

  openDialog(testId: string): void {
    const dialogRef2 = this.dialog.open(TestEditorComponent, {
      width: '250px',
      data: {TestId: testId}
    });

    dialogRef2.afterClosed().subscribe(result => {
      this.testService.getAllTests().subscribe((data: any) => {
        this.dataSource = new MatTableDataSource<Test>(data);;
        this.dataSource.paginator = this.paginator;
      }); 
    });
  }

}
