import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/baseservice';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { FlowEventModel, PlayerLoadEvents } from '../models/EventModels';
import { GameRatingLog } from '../models/gamerating';
import { catchError } from 'rxjs/operators';
import { parseTemplate } from '@angular/compiler';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';

@Injectable({
  providedIn: 'root'
})
export class LoadEventService  extends BaseService {

  
  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }

  getEventFlow(TeamId: string): Observable<FlowEventModel[]>{

    let ret = this.http.get<FlowEventModel[]>(this.rootUrl + '/api/load/events/flow/' + TeamId );
    return ret;
   }

   GetGameRatingLogCoach(GameId: string, TeamId: string, PlayerId: string): Observable<GameRatingLog>{
    

    return this.http.get<GameRatingLog>(this.rootUrl + '/api/load/game/logcoach/'+ GameId + '/team/' + TeamId + '/player/' + PlayerId);

  }

/*
  saveGameRatingLog( string, log: GameRatingLog): Observable<GameRatingLog>{
    
    let ret = this.http.post<GameRatingLog>(this.rootUrl + '/api/load/game/logcoach/'+ log.GameId + '/team/' + log.TeamId + '/player/' + log.PlayerId, log, this.httpOptions);
    return ret;
    
  }
*/
  saveGameRatingLogCoach(log: GameRatingLog): Observable<GameRatingLog>{
    
    let ret = this.http.post<GameRatingLog>(this.rootUrl + '/api/load/game/logcoach/'+ log.GameId + '/team/' + log.TeamId + '/player/' + log.PlayerId, log, this.httpOptions);
    return ret;
    
  }

  deleteGameRatingLogCoach(log: GameRatingLog): Observable<GameRatingLog>{
    
    let ret = this.http.post<GameRatingLog>(this.rootUrl + '/api/load/game/logcoach/delete/'+ log.GameId + '/team/' + log.TeamId + '/player/' + log.PlayerId, log, this.httpOptions);
    return ret;
    
  } 

/* 
  GetEventLoadForUser(): Observable<PlayerLoadEvents[]> {
    return this.http.get<PlayerLoadEvents[]>(this.eventLoadUrl).pipe(
      catchError(this.handleError('GetEventLoadForUser', []))
        );
  } */

 /*  GetWeeklyEventLoadForUser(PlayerId: string, TeamId: string, NoOfMonths: number ): Observable<PlayerLoadEvents[]> {
    var params = "?PlayerId="+PlayerId + "&TeamId="+ TeamId + "&NoOfMontsBack=" + NoOfMonths;
    return this.http.get<PlayerLoadEvents[]>(this.rootUrl + "" + params).pipe(
      catchError(this.handleError('GetWeeklyEventLoadForUser', []))
        );
  } */

  
  GetEventLoadForTeam(TeamId: string, MonthsPast: string, MonthsComing: string): Observable<PlayerLoadEvents[]> {
    var params: String = "/" + MonthsPast + "/" + MonthsComing ;
    return this.http.get<PlayerLoadEvents[]>(this.rootUrl + "/api/load/team/" + TeamId + "/weeklyload_bymonth" + params).pipe(
      catchError(this.handleError('GetEventLoadForTeam', []))
        );
  }

  GetEventLoadForTeamBySeason(TeamId: string): Observable<PlayerLoadEvents[]> {    
    return this.http.get<PlayerLoadEvents[]>(this.rootUrl + "/api/load/team/" + TeamId +"/weeklyload").pipe(
      catchError(this.handleError('GetEventLoadForTeam', []))
        );
  }

  GetEventLoadIncreaseForTeam(TeamId: string, MonthsPast: number, MonthsComing: number): Observable<PlayerLoadEvents[]> {
    var params: String = "/" + MonthsPast + "/" + MonthsComing ;
    return this.http.get<PlayerLoadEvents[]>(this.rootUrl + "/api/load/team/" + TeamId +"/weeklyloadincrease/months" + params).pipe(
      catchError(this.handleError('GetEventLoadIncreaseForTeam', []))
        );
  }

  GetEventLoadIncreaseForTeamAndSeasson(TeamId: string): Observable<PlayerLoadEvents[]> {
    return this.http.get<PlayerLoadEvents[]>(this.rootUrl + "/api/load/team/" + TeamId +"/weeklyloadincrease/season").pipe(
      catchError(this.handleError('GetEventLoadIncreaseForTeamAndSeasson', []))
        );
  }

  CalculateLoadAverages_Planned_ForTeam(TeamId: string, fromDate: string, toDate: string): Observable<PlayerLoadEvents[]> {
    return this.http.get<PlayerLoadEvents[]>(this.rootUrl + "/api/load/CalculateAverages_PlannedLoad_ForTeam/" + TeamId + "/" + fromDate + "/" + toDate).pipe(
      catchError(this.handleError('CalculateAverages_PlannedLoad_ForTeam', []))
        );
  }
  

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {    
      if (error instanceof HttpErrorResponse) {
        console.log("error status=" + error.status);
        if (error.status === 401) {
          // redirect to the login route
          // or show a modal 
          window.location.href="/Account/Login";
          //this.router.navigate(['/notlo//console.log("Redirecting to login");
          
        }else{
          //window.location.href="/Account/Login";
        }
      }
    
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
