<mat-card appearance="outlined" style="max-width: 400px; min-width: 280px; margin: 10px; background-color: #303030;" class="mat-elevation-z8">
    <mat-card-header>
        <div mat-card-avatar class="example-header-image">
            <mat-icon>view_list</mat-icon>
        </div>        
       
    <!-- <img mat-card-avatar *ngIf="!(this.Player.ProfileImage)" src="../../assets/img/icons8-user-shield-48.png">     -->
    <mat-card-title>
        <mat-label>
            {{ Workout.Name }}
        </mat-label>
    </mat-card-title>        
    <mat-card-subtitle>
        <div style="display: flex; flex-direction: row;">
            <mat-icon style="font-size: 15px; color: gray;">schedule</mat-icon>
            <label style="color: gray">{{ Workout.EstimatedDuration }} min</label>
            <div class="status-card" [style.background-color]="statuscardcolor(Workout.Status)">
                {{ Workout.Status}}
            </div>
        </div>
        
        
    </mat-card-subtitle>
</mat-card-header>
<mat-card-content >
    <table>
        <ng-container *ngFor="let section of Workout.Sections">
            <tr>
                <td colspan="4">{{section.Name}}</td>
                
            </tr>
            <tr *ngFor="let exercise of section.Exercises">
                <td style="width: 10px;"></td>
                <td class="exercise">
                    <mat-icon style="font-size: small;">fitness_center</mat-icon>
                    {{ exercise.Name }}
                </td>
                <td style="display: flex;">
                    <div class="settemplate">
                        <ng-container *ngIf="exercise.Sets>0"> {{ exercise.Sets}} * </ng-container>
                        <ng-container *ngIf="exercise.Reps>0"> {{ exercise.Reps}} - </ng-container>                        
                        <ng-container *ngIf="exercise.Effect>0"> {{ exercise.Effect}}W  </ng-container>
                        <ng-container *ngIf="exercise.Duration>0"> {{ exercise.Duration}} {{ exercise.DurationUnit}} </ng-container>
                        <ng-container *ngIf="exercise.Weight>0"> {{ exercise.Weight}} kg </ng-container>
                        
                        
                    </div>
                    <div class="method" *ngIf="exercise.TrainingMethod">
                        {{ exercise.TrainingMethod.Name }}
                    </div>
                    
                </td>
                <td></td>
            </tr>

        </ng-container>
      
    </table>
</mat-card-content>


</mat-card>
