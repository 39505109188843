import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { Globals } from 'src/app/globals';
import { InjuryService } from 'src/app/shared/injury.service';
import { InjuryViewModel } from 'src/app/shared/models/injurymodel';
import { Season } from 'src/app/shared/team.model';
import { TeamService } from 'src/app/shared/team.service';
import { DeleteInjuryDialogComponent } from '../../components/delete-injury-dialog/delete-injury-dialog.component';

@Component({
  selector: 'app-injury-table-team',
  templateUrl: './injury-table-team.component.html',
  styleUrls: ['./injury-table-team.component.css']
})
export class InjuryTableTeamComponent implements OnInit {

  _injuryService: InjuryService;
  _teamId: string;
  public displayedColumns: string[] = ['InjuryDate','EstimatedReturnDate','ActualReturnDate','LastName','FirstName','BodyPartName', 'Side', 'TypeName', 'actions'];
  public dataSource = new MatTableDataSource();
  public TeamId: string;
  public Scope: string;

  public data = [];
  averages=  new Map();
  median =  new Map();
  public _seasons: Season[];
  seasonFormControl = new UntypedFormControl('');

  selection: SelectionModel<InjuryViewModel>;

  constructor(public _route: ActivatedRoute,   
    public _teamService: TeamService,
    public injuryService: InjuryService,
    public _globals: Globals,
    private dialog: MatDialog) 
  { 

    this._injuryService = injuryService;


    this._route.params.subscribe(params => {
      this.TeamId = params['TeamId'];
      this.Scope = params['Scope'];
      this._teamService.getSeasons().subscribe((s: Season[])=>{
        this._seasons = s; 
        this.seasonFormControl.setValue(this._seasons[this._seasons.length-1]);
        if(this.Scope.indexOf('season')>-1){
          this._injuryService.getAllByTeam(this.TeamId, this._seasons[this._seasons.length-1].SeasonId).subscribe( (data: InjuryViewModel[]) =>{
            //console.log(data);                    
            this.dataSource.data =data;
            const initialSelection = [];
            const allowMultiSelect = true;
            this.selection = new SelectionModel<InjuryViewModel>(allowMultiSelect, initialSelection);
           
          });
        }else{
          this._injuryService.getCurrentByTeam(this.TeamId,this._seasons[this._seasons.length-1].SeasonId).subscribe( (data: InjuryViewModel[]) =>{
            //console.log(data);                    
            this.dataSource.data =data;
            const initialSelection = [];
            const allowMultiSelect = true;
            this.selection = new SelectionModel<InjuryViewModel>(allowMultiSelect, initialSelection);
           
          });
        }
        
      //this.getProfile(); 
      }); 
    });
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  refreshData(){
    let tmp: Season = this.seasonFormControl.value;
    if(this.Scope.indexOf('season')>-1){
      this._injuryService.getAllByTeam(this.TeamId, tmp.SeasonId).subscribe( (data: InjuryViewModel[]) =>{      
        this.dataSource.data =data;
      });
    }else{
      this._injuryService.getCurrentByTeam(this.TeamId, tmp.SeasonId).subscribe( (data: InjuryViewModel[]) =>{      
        this.dataSource.data =data;
      });
    }
   
  }

  /** Whether the number of selected elements matches the total number of rows. */
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected == numRows;
}

/** Selects all rows if they are not all selected; otherwise clear selection. */
/* masterToggle() {
  this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
} */

  ngOnInit() {

    
  }


  deleteInjury(InjuryId) {
    const confirmDialog = this.dialog.open(DeleteInjuryDialogComponent, {
      data: {
        title: 'Confirm delete',
        message: 'Are you sure you want to delete the injury?'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this._injuryService.deleteInjury(InjuryId).subscribe( res =>{
          if(res){
            //display sucess
            this.refreshData();

          }else{
            //display failed
          }
        });
      }
    });
  }

}
