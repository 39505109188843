<div class="app-page" style="display: flex; flex-direction: row;">
    <div>
       <a  routerLink="test1" style="color: white;">
        TEST1
        </a><br/>
        <a  routerLink="test2" style="color: white;">
        test2
        </a>
    </div>
    <div>
        <router-outlet >
        </router-outlet>
    </div>
    
    
</div>
