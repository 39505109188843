import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormService } from 'src/app/shared/form.service';
import { FormSubmission, FormViewModel, FormsSubmissionViewModel } from 'src/app/shared/models/FormModels';

@Component({
  selector: 'app-team-forms-feed',
  templateUrl: './team-forms-feed.component.html',
  styleUrls: ['./team-forms-feed.component.css']
})
export class TeamFormsFeedComponent implements OnInit {
  
  expanded: boolean = true;
  teamId: string;
  formId: string;
  teamName: string;
  theform: FormViewModel;
  formssubmitted: FormsSubmissionViewModel[];
  constructor(private activatedRoute: ActivatedRoute, private formService: FormService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.teamId = params['TeamId'];
      this.formId = params['FormId'];
      this.formService.getFormSettingsById(this.formId).subscribe(form=>{
        //console.log(form);
        this.theform = form as FormViewModel;        
        this.getSubmittedForms(this.formId,this.teamId);
      });
    });

    this.activatedRoute.queryParams.subscribe(queryparams=>{
      this.teamName = queryparams["TeamName"];
      //console.log(this.teamName);
    });


  }

  getSubmittedForms(FormId: string, TeamId: string){
    this.formService.getFormSubmittedByFormIdAndTeam(FormId,TeamId).subscribe(formssubmitted=>{
      //console.log(formssubmitted);
      this.formssubmitted = formssubmitted as FormsSubmissionViewModel[];        

    });
  }

  expandNew(){
    this.expanded=true;
  }

  collapseNew(){
    this.expanded=false;
  }

 

}
