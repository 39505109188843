<!-- <div style="width: 100%; display: block; ">
    <table style="width: 100%; ">
        <tr>
            <td style="width: 30px;">
                <mat-icon style="font-size: large;">track_changes</mat-icon>
            </td>
            <td>
                <div style="display: flex; flex-direction: row; width: 100%; padding: 2px ; ">
                    <div style="font-size: small; font-family: Roboto; max-width: 200px; flex-grow: 4;">        
                        {{this.Objective.Title}}<br/>
                        <label style="font-size: x-small; color: gray; font-family: Roboto;">Fredrik Sjostrom</label>
                    </div>
                    <mat-progress-bar mode="determinate"  value="{{this.valueInPercent}}" style="width: 80px; padding-top: 10px; flex-grow: 1;"></mat-progress-bar><label style="font-size: x-small; font-family: Roboto;">{{this.Objective.CurrentValue}}</label>
                </div>
            </td>
            <td>
                
            </td>
        </tr>
        <tr>
            <td></td>
            <td style="background-color: #424242;">
                <div *ngFor="let kr of this.Objective.KeyResults" style="width: 300px;">
                                    <app-keyresult-status-row [KeyResult]="kr"></app-keyresult-status-row>
                </div>
            </td>
            <td></td>
        </tr>
    </table>
    <mat-divider></mat-divider>
</div> -->
<div>
    <div style="display: flex; flex-direction: row; width: 100%; padding: 2px ; ">
        <div style="font-size: small; font-family: Roboto; max-width: 200px; flex-grow: 4;">        
            {{this.Objective.Title}}<br/>
            <label style="font-size: x-small; color: gray; font-family: Roboto;">Fredrik Sjostrom</label>
        </div>
        <mat-progress-bar mode="determinate"  value="{{this.valueInPercent}}" style="width: 80px; padding-top: 10px; flex-grow: 1;"></mat-progress-bar><label style="font-size: x-small; font-family: Roboto;">{{this.Objective.CurrentProgress}}</label>
    </div>
</div>
