import { Component, Inject, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { GameService } from '../../services/game.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ChangeDetectorRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
@Component({
  selector: 'app-set-game-filter-dialog',
  standalone: true,
  templateUrl: './set-game-filter-dialog.component.html',
  styleUrls: ['./set-game-filter-dialog.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatBadgeModule,
    MatDialogModule,
    MatIconModule
  ],
})
export class SetGameFilterDialogComponent implements OnInit 
{
  @Output() divisionChanged = new EventEmitter<void>();
  @Output() showAllDivisionChanged = new EventEmitter<void>();
  form: UntypedFormGroup;
  divisionsData: any[] = [];
  hideOldDivisions: boolean = false;
  isLoaded: boolean = false;

  constructor
  (
    private gameService: GameService, 
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<SetGameFilterDialogComponent>,
    //@Inject(MAT_DIALOG_DATA) public injectedData: { divisionsData: any[] },
    private formBuilder: FormBuilder,
  ) 
  {
    this.form = this.formBuilder.group({
      divisions: new UntypedFormArray([]),
    });
  }

  ngOnInit(): void {
    this.getData();
    
  }

  get theDivisions() {
    return this.form.get('divisions') as UntypedFormArray;
  }

  async getData() 
  {
    const divisionFilterSignal = await this.gameService.getDivisionFilterSignals();
    this.divisionsData = (await divisionFilterSignal)();
    this.gameService.getHideOldDivisionsFilter().subscribe((hideOldDivisions: boolean) => {
      this.hideOldDivisions = hideOldDivisions;
    });
    this.addCheckboxes();
    this.cdr.detectChanges(); // Manually trigger change detection
  }
  
  private addCheckboxes() {
    this.theDivisions.clear(); // Clear existing checkboxes
    this.divisionsData.forEach((division) => {
      const control = new UntypedFormControl(division.DivisionFiltered); // Default value from data
      this.theDivisions.push(control);
    });
    this.isLoaded = true;
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getUniqueCountries(): string[] {
    if (!this.divisionsData) {
      return [];
    }
    return [...new Set(this.divisionsData.map((division) => division.CountryName))];
  }

  getDivisionCount(country: string): number {
    return this.divisionsData.filter((division) => division.CountryName === country).length;
  }

  getSelectedDivisionCount(country: string): number {
    return this.divisionsData.filter(
      (division, index) =>
        division.CountryName === country && this.theDivisions.at(index).value
    ).length;
  }
  onClose(): void {
    this.dialogRef.close();
  }

  onCheckboxChange(): void {
    const selectedDivisions = this.form.value.divisions
      .map((checked: boolean, index: number) =>
        checked ? this.divisionsData[index].DivisionId : null
      )
      .filter((id: number | null) => id !== null);

    this.gameService.setDivisionFilter(selectedDivisions).subscribe(() => {
      this.divisionChanged.emit(); // Notify parent about changes
    });
  }
  async onCheckboxHideOldDivisionsChange(event: MatCheckboxChange): Promise<void> {
    this.hideOldDivisions = event.checked;    
    await (await this.gameService.setHideOldDivisionsFilter(this.hideOldDivisions)).subscribe(() => {
      this.getData();
      this.showAllDivisionChanged.emit(); // Notify parent about changes
      this.cdr.detectChanges(); // Manually trigger change detection
    });

  }
}
    