import { Component, Input, OnInit } from '@angular/core';
import { Player, PlayerDetailsViewModel } from '../../models/PlayerModels';

@Component({
  selector: 'app-player-header',
  templateUrl: './player-header.component.html',
  styleUrls: ['./player-header.component.css']
})
export class PlayerHeaderComponent implements OnInit {

  @Input() Player: PlayerDetailsViewModel;
  constructor() { }

  ngOnInit(): void {
  }

}
