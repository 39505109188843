
<mat-card  appearance="outlined"  style="margin-bottom: 20px;margin-left: 10px; ">
    <mat-card-header>
        <img mat-card-avatar src="/assets/img/{{ Team.SmallIconFileName}}">
        <mat-card-title>
          {{Team.Name}}
        </mat-card-title>
        <mat-card-subtitle>
          {{Team.Level}}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content style =" display: flex; align-content: space-between; flex-wrap: wrap;">
        <app-high-score *ngFor="let test of Team.Tests" [Test]="test" style="margin: 10px; ">

        </app-high-score>
    </mat-card-content>
    <mat-card-actions>
      <a mat-icon-button  [routerLink]="['/teamplayers', Team.TeamId]"  > <img style="height: 24px; " src="../../assets/img/icons8-user-shield-48.png" ></a>
      <a mat-icon-button  [routerLink]="['/teamprofilesplayers', Team.TeamId]"  > <i class="material-icons">
        table_chart
        </i></a>
    </mat-card-actions>
</mat-card>