import { Component, OnInit } from '@angular/core';
import { Objective, ObjectiveTask } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
  selector: 'app-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.css']
})
export class MyTasksComponent implements OnInit {

  Tasks: ObjectiveTask[];

  constructor(
    private objectiveSrv: ObjectivesService
  ) { }

  ngOnInit(): void {
    this.objectiveSrv.getTasksForUser('').subscribe(objs=>{
      this.Tasks = objs as ObjectiveTask[];
    });
  }

}
