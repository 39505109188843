<form [formGroup]="taskForm" (ngSubmit)="onSubmit()">
    <input type="hidden" id="ObjectiveId" formControlName="ObjectiveId">
    <input type="hidden" id="TaskId" formControlName="KeyResultId">
    
    <mat-dialog-content >

        <label style="font-family: Roboto; font-size: medium;">Task</label><br>
        <label style="font-family: Roboto; font-size: x-large;">
            {{this.task.Title}}
        </label>
        <br/>
        <br/>
        <mat-form-field style="width: 300px;" appearance="fill">            
            <mat-label>What did you do to complete this</mat-label>
            <textarea matInput placeholder="" formControlName="CompletedComment"></textarea>
        </mat-form-field>
        <br/>
        <mat-form-field appearance="fill" class="smallinput">
            <mat-label>Completed date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="CompletedDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <br/>
            
        
        
          
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button  type="submit">SET AS COMPLETE</button>
            <button mat-button mat-dialog-close>CANCEL</button> 
        
    </mat-dialog-actions>
        
        
</form>
    