import { CommonModule } from '@angular/common';
import { Component, Input, signal, ViewEncapsulation } from '@angular/core';
import { ScoutingSearchResultOverview } from '../../models/ScoutingSearchResultOverview';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-report-search-result-overview',
  standalone: true,
  imports: [CommonModule, MatExpansionModule, MatAccordion, MatIconModule, MatButtonModule],
  templateUrl: './report-search-result-overview.component.html',
  styleUrl: './report-search-result-overview.component.css',
  encapsulation: ViewEncapsulation.None
})
export class ReportSearchResultOverviewComponent {

  @Input() ResultOverview: ScoutingSearchResultOverview;
  readonly panelOpenState = signal(false);
  

}
