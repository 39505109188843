import { PlayerDetailsViewModel } from 'src/app/shared/models/player.model';

export class PlayerListListViewModel{
        ListId: string;
        OrgId: number;
        Description: string;
        Category: string
        NumberOfPlayers: number;
        CreatedBy: string;
        Archived: boolean;
        CreatedByFirstName: string;
        CreatedByLastName: string;
        IsMyList: boolean;
        CanEdit: boolean;
        PlayerListPlayer: PlayerListPlayer[];
        Players: PlayerDetailsViewModel[];
    }

    export class PlayerListDetailsViewModel
    {
        ListId: string;
        OrgId: number;
        Description: string;
        Category: string;
        CreatedBy: string;
        Archived: boolean;
        players: PlayerDetailsViewModel[];
        IsMyList: boolean;
        CanEdit: boolean;
        PlayerListSecurity: PlayerListSecurity[];
        //OrgUsers: usersinfo[];
        
    }

    export class PlayerListPlayer{
        LastName: string;
    }

    export class PlayerListSecurity
    {
        ListId : string;
        UserId : string;        
        User_Id : string;
        CanEdit: boolean;
    }