import { Component, Input, OnInit } from '@angular/core';
import { Objective } from '../../models/objectives';

@Component({
  selector: 'app-objective',
  templateUrl: './objective.component.html',
  styleUrls: ['./objective.component.css']
})
export class ObjectiveComponent implements OnInit {

  @Input() Objective: Objective;
  
  constructor() { }

  ngOnInit(): void {
  }

}
