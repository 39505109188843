import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TestService } from 'src/app/shared/test.service';
import { Test } from 'src/app/shared/models/test.model';

@Component({
  selector: 'app-test-add',
  templateUrl: './test-add.component.html',
  styleUrls: ['./test-add.component.css']
})


export class TestAddComponent implements OnInit {
  public testForm: UntypedFormGroup;
  private _test: Test;

  constructor(
    public dialogRef: MatDialogRef<TestAddComponent>,
    public testService: TestService
  ) 
  {

  }

  ngOnInit() {
    this.testForm = new UntypedFormGroup({
      Name: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),      
      Description: new UntypedFormControl('', [ Validators.maxLength(100)]),
      ResultUnit: new UntypedFormControl('', [Validators.required] ),
      ResultType:  new UntypedFormControl('', [Validators.required] ),
      Operator:  new UntypedFormControl('', [Validators.required] ),
    });
  }


  save(){
    
    this.testService.saveTest(this.testForm.value).subscribe(ret => {
      console.log(ret);
      this.dialogRef.close();
    });
  }

  onCancel(){
    this.dialogRef.close();

  }
}
