<mat-card appearance="outlined" style="max-width: 400px; min-width: 280px; margin: 10px;" class="mat-elevation-z4">
    <mat-card-header>
  
        <i class="material-icons"  mat-card-avatar style="height: 40px; font-size: 40px; ">
          assignment_turned_in
        </i>
        <mat-card-title>
          {{ this.TopList.TestName}}          
        </mat-card-title>        
           
  
    </mat-card-header>
    <mat-card-content>
        
        <table mat-table [dataSource]="dataSource" >

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Position Column -->
            <ng-container matColumnDef="Position">
              <th mat-header-cell *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let element" style="padding-right: 5px;"> {{element.Position}} </td>
            </ng-container>
            <ng-container matColumnDef="Medal">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element" style="padding-right: 5px;">
                <mat-icon *ngIf="element.Position==1" style="color: gold">star</mat-icon>
                <mat-icon *ngIf="element.Position==2" style="color: silver">star</mat-icon>
                <mat-icon *ngIf="element.Position==3" style="color: #d7a17b">star</mat-icon>
              </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="PlayerLastName">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element" style="padding-right: 5px;">                 
                  {{element.PlayerLastName}} {{element.PlayerFirstName}}                                  
              </td>
            </ng-container>
              <!-- Name Column -->
              <ng-container matColumnDef="PlayerFirstName">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element" style="padding-right: 5px;"><b> {{element.PlayerFirstName}}</b> </td>
              </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="PerformedDate">
              <th mat-header-cell *matHeaderCellDef > Date </th>
              <td mat-cell *matCellDef="let element" style="white-space: nowrap;" > {{element.PerformedDate.substring(0,10)}} </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="Result">
              <th mat-header-cell *matHeaderCellDef> Result </th>
              <td mat-cell *matCellDef="let element" style="text-align: right;"><b> {{element.Result}}</b> </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div style="color: gray;" *ngIf="this.TopList.Items.length ==0">
            NO RESULTS
          </div>
    </mat-card-content>
    <mat-card-actions>
   
     
    </mat-card-actions>
  </mat-card>