import { Component, OnInit } from '@angular/core';
import {Location } from '@angular/common';
import { GameService } from '../../services/game.service';
import { Globals } from '../../../globals';
import { ActivatedRoute, Router } from '@angular/router';
import { GameDetailsViewModel } from '../../models/GameModels';

@Component({
  selector: 'app-game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.css']
})
export class GameDetailsComponent implements OnInit {

  _gameData: GameDetailsViewModel;
  _gameId: string;

  constructor(public _gameService: GameService, public route: ActivatedRoute, public _router: Router, public location: Location) {

    this._gameId = this.route.snapshot.paramMap.get('GameId');
    ///console.log(this._gameId);
    this.getGameData(this._gameId);

   }

  ngOnInit() {
  
    
  }

  getGameData(GameId){

    this._gameService.getGameDetails(GameId).subscribe(data=>{
      this._gameData = data;
    });
  }

  importStats(GameId){
    this._gameService.getExternalGameStatistics(GameId).subscribe(data=>{
      console.log(data);
    });
  }

}
