
<div style="color: white;margin-left:20px;" class="app-page">

    <mat-progress-spinner *ngIf="isBusy"
        class="loading-indicator"
        color="primary"
        mode="indeterminate"
    ></mat-progress-spinner>
    <br/>

    <a mat-raised-button (click)="getData()" >
        <mat-icon>refresh</mat-icon>    
        REFRESH</a> &nbsp;
    <a mat-raised-button (click)="sendReminder()" *ngIf="this._userService.roleMatch(['Org_Admin', 'Load_TeamMgr'])">
        <mat-icon>send</mat-icon>    
    SEND REMINDER</a>
        &nbsp;
    <a mat-raised-button [routerLink]="['/load/team/overview', this.TeamId, false ]" >
        LATEST 
    </a>

    <a mat-raised-button [routerLink]="['/load/team/overview', this.TeamId, true ]" >
        TODAY ONLY
    </a>

   
  <br/>
  <br/>
    <mat-table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8">
  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->
        <ng-container matColumnDef="JerseyNo">
            <th mat-header-cell *matHeaderCellDef> NO </th>
            <td mat-cell *matCellDef="let element" class="namecell"> {{element.JerseyNo }} </td>
        </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="PlayerName">
            <th mat-header-cell *matHeaderCellDef> PLAYER </th>
            <td mat-cell *matCellDef="let element" class="namecell" style="width: 150px;"> 
                <a [routerLink]="['/player/details',element.PlayerId]" style="color: white; text-decoration: none; ">
                 {{element.LastName + " " + element.FirstName}}
                </a>
             </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Availability">
            <th mat-header-cell *matHeaderCellDef  style="text-align: center;min-width: 100px;" > AVAILABILITY </th>
            <td mat-cell *matCellDef="let element" class="valuecell" [style.background-color]="this.availabilityColors[element.Availability]" > {{ this.availabilityText[element.Availability] }} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="Freshness">
            <th mat-header-cell *matHeaderCellDef  style="text-align: center;"> FRESHNESS <br/>   <mat-icon>directions_run</mat-icon> </th>
            <td mat-cell *matCellDef="let element" class="valuecell" [style.background-color]="this.scaleColors[element.Freshness]" [style.color]="this.scaleTextColors[element.Freshness]">
                <mat-icon style="font-size: 20px;" *ngIf="element.Availability>0">
                    directions_run                    
                </mat-icon>
                 <div style="display: inline;vertical-align: super;">{{element.Freshness}}</div>
                 </td>
        </ng-container>

        <ng-container matColumnDef="Confidence">
            <th mat-header-cell *matHeaderCellDef   style="text-align: center;"> CONFIDENCE <br/>   <mat-icon>psychology</mat-icon> </th>
            <td mat-cell *matCellDef="let element" class="valuecell" [style.background-color]="this.scaleColors[element.Confidence]" [style.color]="this.scaleTextColors[element.Confidence]"> 
                <mat-icon style="font-size: 20px;" *ngIf="element.Availability>0">psychology</mat-icon>
                <div style="display: inline;vertical-align: super;">{{element.Confidence}}</div>
             </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="Sleep">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;" > SLEEP <br/>   <mat-icon>hotel</mat-icon></th>
            <td mat-cell *matCellDef="let element" class="valuecell" [style.background-color]="this.scaleColors[element.Sleep]"  [style.color]="this.scaleTextColors[element.Sleep]">
                <mat-icon style="font-size: 20px;" *ngIf="element.Availability>0">hotel</mat-icon>
                <div style="display: inline;vertical-align: super;"> {{element.Sleep}} </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="MusclePain">
            <th mat-header-cell *matHeaderCellDef style="text-align: center; min-width: 90px;"> MUSCLE PAIN <br/>   <mat-icon>flash_on</mat-icon> </th>
            <td mat-cell *matCellDef="let element" class="valuecell"  [style.background-color]="this.painScaleColors[element.Muscles]"  [style.color]="this.painScaleTextColors[element.Muscles]">
                <mat-icon  style="font-size: 20px;" *ngIf="element.Availability>0">
                    flash_on                    
                </mat-icon>
                <div style="display: inline;vertical-align: super;">{{element.Muscles}}</div> 
            </td>
        </ng-container>

        <ng-container matColumnDef="OtherPain">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;min-width: 90px;"> OTHER PAIN <br/>   <mat-icon>flash_on</mat-icon> </th>
            <td mat-cell *matCellDef="let element" class="valuecell"  [style.background-color]="this.painScaleColors[element.Joints]" [style.color]="this.painScaleTextColors[element.Joints]">
                <mat-icon class="iconvalue" *ngIf="element.Availability>0">
                    flash_on
                    
                </mat-icon>
                 <div style="display: inline; vertical-align: super;">{{element.Joints}} </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="HasFever">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;"> FEVER </th>
            <td mat-cell *matCellDef="let element" class="valuecell" [style.background-color]="hasFeverColor(element.HasFever)"  > {{hasFeverText(element.HasFever)}} </td>
        </ng-container>

        <ng-container matColumnDef="BodyTemperature">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;"> BODY TEMP </th>
            <td mat-cell *matCellDef="let element" class="valuecell" [style.background-color]="hasFeverColor(element.HasFever)" > {{element.BodyTemperature}} </td>
        </ng-container>

        <ng-container matColumnDef="HasSicknessSymptoms">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;"> SYMPTOMS </th>
            <td mat-cell *matCellDef="let element" class="valuecell" [style.background-color]="hasFeverColor(element.HasSicknessSymptoms)" > {{hasSymptomsText(element.HasSicknessSymptoms)}} </td>
        </ng-container>

        <ng-container matColumnDef="ExposedToContagion">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;"> EXPOSED </th>
            <td mat-cell *matCellDef="let element" class="valuecell" [style.background-color]="hasFeverColor(element.ExposedToContagion)" > {{getExposedText(element.ExposedToContagion)}} </td>
        </ng-container>

        <ng-container matColumnDef="Space">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">  </th>
            <td mat-cell *matCellDef="let element" class="valuecell">  </td>
        </ng-container>
        
        

        <ng-container matColumnDef="TimeAgo">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;"> UPDATED  </th>
            <td mat-cell *matCellDef="let element" class="valuecell"> {{element.TimeAgoText}} </td>
        </ng-container>
        <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">   </th>
            <td mat-cell *matCellDef="let element" class="valuecell">
                <button *ngIf="this.allowInvites && element.UserId=='00000000-0000-0000-0000-000000000000' && !element.ActiveInvitationExists" mat-button (click)="openInviteDialog(this.TeamId, element.PlayerId, element.LastName, element.FirstName );">SEND INVITATION</button>    
                <ng-container *ngIf="this.allowInvites && element.UserId=='00000000-0000-0000-0000-000000000000' && element.ActiveInvitationExists">
                    <span  style="font-size: small;" >INVITATION CODE: {{element.AuthorizationCode}}</span>    
                    <button mat-icon-button (click)="cancelInvitation(element.InviteId);"  >
                        <mat-icon>delete</mat-icon>
                      </button>
                </ng-container>
                
                
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="this.displayedColumns; sticky: true;" sticky></tr>
        <tr mat-row *matRowDef="let row; columns: this.displayedColumns;" style="height: 24px;"></tr>
        </mat-table>


      



 <!--  <mat-nav-list dense >
      <mat-list-item *ngFor="let player of _players">                 
          <h4 matLine>{{ player.LastName }}, {{ player.FirstName }}</h4>
          <div matLine>          
              <table >
                  <tr>
                    <td></td>
                    <td style="text-align:center;">
                        <div [style.background]="availabilityColors[player.Availability]" style="padding-left: 3px; padding-right: 3px; width: 60px; ">
                            <div>&nbsp;</div>
                            {{ availabilityText[player.Availability] }}
                          </div>
                    </td>                
                    <td style="width: 25px;  text-align: center;">
                      <div [style.background]="scaleColors[player.Freshness]" [style.color]="scaleTextColors[player.Freshness]">
                          <div>Fresh</div>
                        {{player.Freshness}}
                      </div>
                    </td>
                    <td style="width: 25px; text-align: center;">
                        <div [style.background]="painScaleColors[player.Muscles]" [style.color]="scaleTextColors[player.Muscles]">
                            <div>Muskler</div>
                            {{player.Muscles}}
                            
                          </div>
                    </td>
                    <td style="width: 25px; text-align: center;">
                        <div [style.background]="painScaleColors[player.Joints]" [style.color]="scaleTextColors[player.Joints]">
                            <div>Leder</div>
                            {{player.Joints}}
                          </div>
                    </td>
                    <td style="width: 30px; text-align: center;">
                        <div [style.background]="scaleColors[player.Sleep]" [style.color]="scaleTextColors[player.Sleep]">
                            <div>Söm</div>
                            {{ player.Sleep}}
                          </div>
                          
                    </td>
                    <td style="width: 20px; text-align: center;">
                        <div [style.color]="scaleColors[10]"  style="padding-left: 3px; padding-right: 3px;">
                             {{ player.TimeAgoText }}
                        </div>
                    </td>
                  </tr>                 
               </table>
             </div>
       </mat-list-item>      
   </mat-nav-list> -->
</div>
