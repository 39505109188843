<mat-button-toggle-group formControlName="Value"  >
    <mat-button-toggle *ngFor="let op of mylist" 
      [value]="op"          
      [ngClass]="{'bg-bad': Skill.Value === 1,'bg-average':selected === '2_'+ this.SkillId,'bg-good':selected === '3_'+ this.SkillId,'bg-verygood':selected === '4_'+ this.SkillId,'bg-excellent':selected === '5_'+ this.SkillId}" 
      (click)="selected = op + '_' + skill.SkillId; this.log(skill);">
      <div  style="font-size: small; line-height: 22px;display: flex; flex-direction: column;">
        <div>
            {{op}}
        </div> 
        <div>
            {{getText(op) }}
        </div>
      </div>                                          
    </mat-button-toggle>
  </mat-button-toggle-group>