import { Component, OnInit } from '@angular/core';
import { GameEventOverview, GameEvent } from '../models/gameevent';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Player } from '../models/player';
import { GameService } from '../services/game.service';
import { GameGradeDialogComponent } from '../game-grade-dialog/game-grade-dialog.component';
declare var google:any;


@Component({
  selector: 'app-load-game-details',
  templateUrl: './load-game-details.component.html',
  styleUrls: ['./load-game-details.component.css']
})
export class LoadGameDetailsComponent implements OnInit {

  
  private TeamId: string;
  private GameId: string;
  public players: Player[];
  public Game: GameEventOverview;
  public gameDetails: GameEvent;
  private Freshness: number[] = [0,0,0,0,0,0,0,0,0,0,0];
  private Sleep: number[] = [0,0,0,0,0,0,0,0,0,0,0];
  private Confidence: number[] = [0,0,0,0,0,0,0,0,0,0,0];
  private MusclePain: number[] = [0,0,0,0,0,0,0,0,0,0,0];
  private OtherPain: number[] = [0,0,0,0,0,0,0,0,0,0,0];
  private GameAssessments: number[]=[0,0,0,0,0,0];
  

  
  public scaleColors: string[] = ['gray','#FF5722','#FF5722','#FF9800','#FFC107','#FFFEB3','#CDDC39', '#8BC34A', '#4CAF50','#4CAF50','#4CAF50']
  public scaleTextColors: string[] = ['white','white','white','white','white','black','white', 'white', 'white','white','white']
  public availabilityColors: string[] = ['gray', '#4CAF50','#FF5722','#FF5722'];
  public availabilityText: string[] = ['-','READY','SICK','INJURED','AWAY' ];
  public painScaleColors: string[] = ['#4CAF50','#FFFEB3','#FFFEB3', '#FFC107', '#FFC107', '#FF9800','#FF9800','#FF5722','#FF5722','#FF5722','#FF5722']
  public painScaleTextColors: string[] = ['white','black','black','black','black','white','white','white','white','white','white']
  public intensityColors: string[] = ['', '#4CAF50','#FFEB30','#FF5722'];
  public intensityTextColors: string[] = ['', 'white','black','white'];
  public gamePerformanceIcon: string[] = ['block','sentiment_very_dissatisfied','sentiment_neutral','sentiment_satisfied','sentiment_very_satisfied', 'star_border']
  public gamePerformanceColors: string[] = ['','#FF5722','#FFC107','#4CAF50','#2E7D32','#00BCD4']; 
  public gamePerformanceTextColors: string[] = ['white', 'white','black','white'];
  
  constructor( 
    public gameService: GameService,
    public route: ActivatedRoute,
    public location: Location,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.TeamId = this.route.snapshot.paramMap.get('TeamId');
    this.GameId = this.route.snapshot.paramMap.get('GameId');


    this.GetPlayersForGameView();    
    this.GetGameOverview();
  }

  GetGameOverview():void{
   /*  this.eventservice.GetGameOverViewForTeam(this.GameId,this.TeamId).subscribe(g => {
      this.Game = g;
      this.generateChart(g);
      this.generateGamePrepChart(g);

    }); */
    this.gameService.GetGameDetails(this.GameId).subscribe(g => {
      this.gameDetails = g;
      console.log(g);
    });
  }

  GetPlayersForGameView(): void{
     this.gameService.GetPlayersForGameView(this.GameId, this.TeamId).subscribe(
      response => {
        this.players = response;
        this.generateCharts();
      }
    ); 
  }

  generateCharts(){

    var _dataFreshness=[];
    var _dataSleep=[];
    var _dataConfidence=[];
    var _dataPains=[];
    
    var _data2 = [];
    //var data = new google.visualization.DataTable();
    //data.addColumn('number', 'Freshness');
    //data.addColumn('number', 'Players');

    this.players.forEach(p=>{
      console.log(p);
      if(p.Freshness>0)
        this.Freshness[p.Freshness]++;
      if(p.Sleep>0)
        this.Sleep[p.Sleep]++;
      if(p.Confidence>-1)
        this.Confidence[p.Confidence]++;
      if(p.Muscles>-1)
        this.MusclePain[p.Muscles]++;
      if(p.Joints>-1)
        this.OtherPain[p.Joints]++;
      if(p.GameAssessment&&p.GameAssessment.Ranking>0){
        this.GameAssessments[p.GameAssessment.Ranking]++;
      }

      

    })
    _dataFreshness.push(['Players', 'Freshness']);
    for (let index = 0; index <= 10; index++) {
      _dataFreshness.push([index, this.Freshness[index]]);      
    }
    _dataSleep.push(['Players', 'Sleep']);
    for (let index = 0; index <= 10; index++) {
      _dataSleep.push([index, this.Sleep[index]]);      
    }
    _dataConfidence.push(['Players', 'Confidence']);
    for (let index = 0; index <= 10; index++) {
      _dataConfidence.push([index, this.Confidence[index]]);      
    }
    _dataPains.push(['Players', 'Muscle pain', 'Other pain']);
    for (let index = 0; index <= 10; index++) {
      _dataPains.push([index, this.MusclePain[index], this.OtherPain[index] ]);      
    }

    //console.log(this.GameAssessments);
    _data2.push(['Players', 'Overall', { role: 'style' }]);
    var colors = ['color: gray','color: #FF5722','color: #FFC107','color: #4CAF50','color: #2E7D32', 'color: #00BCD4'];
    for (let index = 0; index <= 5; index++) {
      _data2.push([index, this.GameAssessments[index], colors[index]]);      
    }

    google.charts.load('current', {packages: ['corechart', 'bar']});
    google.charts.setOnLoadCallback(drawCharts);

    function drawCharts() {
      var data = google.visualization.arrayToDataTable(_dataFreshness);
      var data2 = google.visualization.arrayToDataTable(_data2);
      var dataSleep = google.visualization.arrayToDataTable(_dataSleep);
      var dataConfidence = google.visualization.arrayToDataTable(_dataConfidence);
      var dataPains = google.visualization.arrayToDataTable(_dataPains);

      var options = {
        title: 'Pre game status',

        titleTextStyle: {
          color: 'white',
          textSize: '8',
        },
        height: '300px',
        legend: {position: 'bottom', textStyle: {color: 'white', fontSize: 12}},
        colors: ['#4285f4','#008744','white','#d62d20','#ffa700'],
        backgroundColor: '#303030',
        hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: 0, maxValue: 10, gridlines: { count: 0} },
        vAxis: { title: 'No of players',  titleTextStyle: {color: 'white',textSize: '8'}, textStyle: { color: 'white'} , baselineColor: 'white', maxValue: 15, ticks: [0,5,10,15] },

        
      };

      var options2 = {
        title: 'Player assessments',

        titleTextStyle: {
          color: 'white',
          textSize: '8',
        },
        height: '300px',
        legend: {position: 'bottom', textStyle: {color: 'white', fontSize: 12}},
        colors: ['white'],
        backgroundColor: '#303030',
        hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: 0, maxValue: 5, gridlines: { count: 0} },
        vAxis: { title: 'No of players',  titleTextStyle: {color: 'white',textSize: '8'}, textStyle: { color: 'white'} , baselineColor: 'white', maxValue: 15, ticks: [0,5,10,15] },

        
      };

      var optionsSleep = {
        title: 'Sleep',

        titleTextStyle: {
          color: 'white',
          textSize: '8',
        },
        height: '300px',
        legend: {position: 'bottom', textStyle: {color: 'white', fontSize: 12}},
        colors: ['green'],
        backgroundColor: '#303030',
        hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: 0, maxValue: 5, gridlines: { count: 0} },
        vAxis: { title: 'No of players',  titleTextStyle: {color: 'white',textSize: '8'}, textStyle: { color: 'white'} , baselineColor: 'white', maxValue: 15, ticks: [0,5,10,15] },

        
      };

      var optionsConfidence = {
        title: 'Confidence',

        titleTextStyle: {
          color: 'white',
          textSize: '8',
        },
        height: '300px',
        legend: {position: 'bottom', textStyle: {color: 'white', fontSize: 12}},
        colors: ['white'],
        backgroundColor: '#303030',
        hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: 0, maxValue: 5, gridlines: { count: 0} },
        vAxis: { title: 'No of players',  titleTextStyle: {color: 'white',textSize: '8'}, textStyle: { color: 'white'} , baselineColor: 'white', maxValue: 15, ticks: [0,5,10,15] },

        
      };

      var optionsPains = {
        title: 'Pains',

        titleTextStyle: {
          color: 'white',
          textSize: '8',
        },
        height: '300px',
        legend: {position: 'bottom', textStyle: {color: 'white', fontSize: 12}},
        colors: ['red', 'orange'],
        backgroundColor: '#303030',
        hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: 0, maxValue: 5, gridlines: { count: 0} },
        vAxis: { title: 'No of players',  titleTextStyle: {color: 'white',textSize: '8'}, textStyle: { color: 'white'} , baselineColor: 'white', maxValue: 15, ticks: [0,5,10,15] },

        
      };

      var chart = new google.visualization.ColumnChart(document.getElementById('freshness_chart_div'));
      chart.draw(data, options);

      var chart2 = new google.visualization.ColumnChart(document.getElementById('grade_chart'));
      chart2.draw(data2, options2);

      var chartSleep = new google.visualization.ColumnChart(document.getElementById('sleep_chart_div'));
      chartSleep.draw(dataSleep, optionsSleep);

      var chartConfidence = new google.visualization.ColumnChart(document.getElementById('confidence_chart_div'));
      chartConfidence.draw(dataConfidence, optionsConfidence);

      var chartPains = new google.visualization.ColumnChart(document.getElementById('pains_chart_div'));
      chartPains.draw(dataPains, optionsPains);
    }

  }

  filterPlayers(PositionIds: number[]): Player[]{

    return this.players.filter(p=> PositionIds.includes(p.PositionId));
  }

  openRatingDialog(PlayerId: string, LastName: string, FirstName: string){
    let dialogRef = this.dialog.open(GameGradeDialogComponent, { data: {TeamId: this.TeamId, GameId: this.GameId, PlayerId: PlayerId, GameName: this.gameDetails.HomeTeam.Name + "-" + this.gameDetails.AwayTeam.Name, LastName: LastName, FirstName: FirstName }});

    dialogRef.afterClosed().subscribe(result => {      
     
      this.GetPlayersForGameView();
    });

  }  

}
