import { TestResultPlayer } from './test.model';
import { ReportViewModel } from 'src/app/scouting/models/ReportModels';
export class Player {
    
    LifelineScoutingId: string;
    ShootId: number;
    PositionId: number;
    //public virtual PlayersShoot PlayersShoot:
    //public virtual PlayersPosition PlayersPosition { get; set; }
    FirstName: string;
    LastName: string;
    DateOfBirth: Date;
    Weight: number;
    Height: number;
    Nationality: string;
    TownOfBirth: string;
    JerseyNo: number;
    Gender: string;
    CurrentTeamId: string;
    CurrentTeamIconFileName: string;
    DraftedToTeam: string;
    AgentId: string;
    ChangedByUserId: string;
    ChangedDate: Date;
    CurrentTeam: string;
    Division: string;
    TeamIsChecked: boolean;
    PlayerVisibility: string;
    CreatedByOrgId: number;
    EliteProspectsLink: string;
    ProfileImage: string;

    AgeYearsDecimal:number;
}


export class PlayerViewModel {
    LifelineScoutingId: string;
    PlayerId: string;
    LastName: string;
    FirstName: string;
    JerseyNo: number;
    PositionId: number;
    ShootId: number;
    TestResults: TestResultPlayer[];
    Position: PlayerPosition;
    DateOfBirth: Date;
    Height: number;
    Weight: number;

    PlayerPointsCalculated: number;
    PlayersPointsMax: number;
    PlayerNoOfTests: number;
    ProfileNoOfTests: number;
    PlayerPointsAverage: number;

    AgeYearsDecimal: number;

    ProfileImage: string;
    IsFavourite: boolean;
    ReportCount: number;
    NoteCount: number;

}


export class TeamSeasonPlayer{
        TeamId: string;
        SeasonId: string;
        PlayerId: string;
        JerseyNo: string;
        ProfileImage: string;            
        PositionId: string;
        ShootId: string;
        UpdateOptions: TeamSeasonPlayer_UpdateOptions;        
}

export enum TeamSeasonPlayer_UpdateOptions{
    OnlyCurrentTeam = 0,
    AllTeamsThisSeason = 1,
    AllTeamsAllSeasons = 2,

}

export class PlayerDetailsViewModel
{
    Player:  Player;
    DivisionName: string;
    TeamName: string;
    TeamIconFile: string;
    //Teams: PlayerTeam[] ;
    //TeamsOrg: PlayerTeam[] ;
    Reports: ReportViewModel[];
    //Tags: PlayerTagsViewModel[];
    //Agent: Agent ;
    NoOfReports: number;
    NoOfNotes: number;

    IsFavourite: boolean;

    PlayerAgeYears: number;
    PlayerAgeMonths: number;
    PlayerAgeDays: number;
}

export class PlayerPosition
{
    SportId :number;
    PlayerPositionId :number;
    LanguageCode :string;
    Text :string;

}

export class PlayerPositionCheck
{
    SportId :number;
    PlayerPositionId :number;
    LanguageCode :string;
    Text :string;
    Checked: boolean;
    
}