<form [formGroup]="orgForm" (ngSubmit)="onSubmit()">
<h2 mat-dialog-title>EDIT MISSION AND VALUES</h2>
<mat-dialog-content style="font-size: smaller;">        
        <mat-form-field appearance="fill" style="width: 380px;">
            <mat-label>Mission</mat-label>
            <textarea matInput id="Mission" type="text" formControlName="Mission" style="height: 120px;"></textarea>
        </mat-form-field>
       <br/>
       <mat-form-field appearance="fill" style="width: 380px;">
        <mat-label>Vision</mat-label>
        <textarea matInput id="Vision" type="text" formControlName="Vision" style="height: 120px;" ></textarea>
    </mat-form-field>
   <br/>
         
        

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button type="submit" [disabled]="!orgForm.valid">SAVE</button>
  <button mat-button mat-dialog-close>CANCEL</button> 
  
</mat-dialog-actions>
    
    
</form>


