import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/baseservice';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PlayerGameRatings_TeamAnalysisViewModel, TeamSeasonGradesUI } from '../models/AnalysisModels';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';


@Injectable({
  providedIn: 'root'
})
export class TeamPerformanceService extends BaseService {

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }

  getTeamGameGrades(TeamId: string, SeasonId: number): Observable<TeamSeasonGradesUI>{

    let ret = this.http.get<TeamSeasonGradesUI>(this.rootUrl + '/api/team/' + TeamId + '/grades/' + SeasonId );
    return ret;
   }

   getTeamGameGrades_MaxRows(TeamId: string, SeasonId: number, MaxRows: string): Observable<TeamSeasonGradesUI>{

    let ret = this.http.get<TeamSeasonGradesUI>(this.rootUrl + '/api/team/' + TeamId + '/grades/' + SeasonId + "/" + MaxRows );
    return ret;
   }


}
