<div class="app-page">

  <app-team-overview-card [Team]="team"  *ngFor="let team of _teams ">

  </app-team-overview-card> 

  <!-- <mat-card *ngFor="let team of _teams " style="margin-bottom: 20px;margin-left: 10px;">
    <mat-card-header>
        <img mat-card-avatar src="/assets/img/{{ team.SmallIconFileName}}">
      <mat-card-title>
        {{team.Name}}
      </mat-card-title>
      <mat-card-subtitle>
        {{team.Level}}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content >
      <div style="height: 350px; width: 100%; text-align: center; border: #ffffff38 solid 1px;">
        <br/>
        <div *ngIf="!team.hasTestResults">
          NO DATA
        </div>
        <div style="display: flex; overflow-x: auto; white-space: nowrap;"  >
            <div *ngFor="let test of team.Tests">
                <div style="display: flex; flex-wrap: wrap;">
                    <app-test-scatter-chart  [TeamId]="team.TeamId" [TestId]="test.TestId" [TestName]="test.Name" [Results]="test.TestResults" [ResultType]="test.ResultType" ></app-test-scatter-chart>
                </div>
                
            </div>
            
        </div>
        
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button type="button" mat-button [routerLink]="['/teamplayers', team.TeamId]">PLAYERS</button>
      <button type="button" mat-button [routerLink]="['/testtoplist/team/', team.TeamId]">TOP LISTS</button>
      </mat-card-actions>
  </mat-card> -->

</div>
