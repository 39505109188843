import { Component, OnInit, Output } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { Globals } from '../../globals';
import { SharedModuleModule } from '../../shared-module/shared-module.module'



@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  isLoginError : boolean = false;
  isLoggingIn : boolean = false;
  constructor(public userService : UserService,
    public router : Router,
    public _globals: Globals) { }

  

  ngOnInit() {
    this._globals.titleBarText="Dalecarlia Scouting - Logon";
  }

  OnSubmit(userName,password){
    this.isLoggingIn=true;
     this.userService.userAuthentication(userName,password).subscribe((data : any)=>{
      //console.log(data);
      localStorage.setItem('userToken',data.access_token);
      localStorage.setItem('userRoles',data.role);
      localStorage.setItem('Name',data.Name);
      localStorage.setItem('Modules', data.module);      
      //console.log("data.module");
      //console.log(data.module);
      localStorage.setItem('OrganizationId', data.OrganizationId);      
      localStorage.setItem('UserId', data.UserId);      
      
      this._globals.isLoggedIn=true;
      this._globals.UserFullName = data.Name;
      this._globals.organizationId = data.OrganizationId;
      this._globals.sendMessage("Logged in ");
      this._globals.UserId = data.UserId;
      //console.log(data.OrganizationSmallImage);
      this._globals.TeamImage = '/assets/img/teams/' + data.OrganizationSmallImage;

      this.router.navigateByUrl('/welcome');
    },
    (err : HttpErrorResponse)=>{
      this.isLoggingIn=false;
      this.isLoginError = true;
    });
  }

}
