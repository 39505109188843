<div class="app-page">
    <table>
        <tr>
            <td>
                <img src="/assets/img/{{teams[0].SmallIconFileName}}" height="80px;"/>
            </td>
            <td>
                <h1>{{teams[0].Name}}</h1>
            </td>
        </tr>
    </table>
    
    
    <br/>
    <div  style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <app-organization-team-card *ngFor="let team of teams" [Team]="team">

        </app-organization-team-card>
    </div>
  


</div>
