import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';
import { BaseService } from 'src/app/shared/baseservice';
import { PlayerNote, PlayerNoteViewModel } from '../models/Note';
import { Report, ReportViewModel, ScoutingSourceLanguage, ScoutingSourceViewModel } from '../models/ReportModels';

@Injectable({
  providedIn: 'root'
})

export class ScoutingSourceService extends BaseService{

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }

   
  getSources(): Observable<ScoutingSourceViewModel[]>{
    let ret = this.http.get<ScoutingSourceViewModel[]>(this.rootUrl + '/api/scoutingsource');
    return ret;
  }

  activateSource(ScoutingSourceId: number): Observable<boolean>{   
    return this.http.post<boolean>(this.rootUrl + '/api/scoutingsource/'+ ScoutingSourceId, {});     
  }

  
  inactivateSource(ScoutingSourceId: number): Observable<boolean>{   
    return this.http.delete<boolean>(this.rootUrl + '/api/scoutingsource/'+ ScoutingSourceId);     
  }


}
