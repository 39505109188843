import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/baseservice';
import { Observable } from 'rxjs';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';
import { KeyResult, KeyResultValue, Objective, ObjectiveCheckin, ObjectiveTask, Stream } from '../models/objectives';
import { of } from 'rxjs';
import { OBJECTIVES_DATA, STREAMS_DATA } from '../mockupdata/mock_objectives';

@Injectable({
  providedIn: 'root'
})
export class ObjectivesService extends BaseService {

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }



  public getOrgMission(): Observable<string>{

    return this.http.get<string>(this.rootUrl + '/api/v1/organization/mission');
  }

  public getOrgVision(): Observable<string>{

    return this.http.get<string>(this.rootUrl + '/api/v1/organization/vision');
  }

  public updateMission(Mission: string): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/v1/organization/mission', { Mission: Mission },this.httpOptions);
    return ret;
  }

  public updateVision(Vision: string): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/v1/organization/vision',{ Vision: Vision },this.httpOptions);
    return ret;
  }


   public getObjective(ObjectiveId: string): Observable<Objective>{
    let ret = this.http.get<Objective>(this.rootUrl + '/api/v1/objectives/' + ObjectiveId  );
    return ret;
   }

  
   public getObjectivesForUser(UserId: string): Observable<Objective[]>{
    let ret = this.http.get<Objective[]>(this.rootUrl + '/api/v1/objectives?UserId=' + UserId);
    return ret;
   }

   public getObjectivesForPlayer(PlayerId: string): Observable<Objective[]>{
    let ret = this.http.get<Objective[]>(this.rootUrl + '/api/v1/objectives?PlayerId=' + PlayerId);
    return ret;
   }
   

   public getObjectivesForTeam(TeamId: string, Level: string = 'INDIVIDUAL'): Observable<Objective[]>{
   
    let ret = this.http.get<Objective[]>(this.rootUrl + '/api/v1/objectives?Team=' + TeamId + '&Level=' + Level   );
    return ret;
   }
   public getObjectivesForOrg(): Observable<Objective[]>{
   
    let ret = this.http.get<Objective[]>(this.rootUrl + '/api/v1/objectives?Level=ORGANIZATION'   );
    return ret;
   }

   getTasksForUser(status: string): Observable<ObjectiveTask[]>{
     //If not sending Owner as parameter, will defualt to caller user in API
    let ret = this.http.get<ObjectiveTask[]>(this.rootUrl + '/api/v1/tasks?Status=' + status );
    return ret;
   }

   getTasksForPlayer(status: string, playerId: string): Observable<ObjectiveTask[]>{
    //If not sending Owner as parameter, will defualt to caller user in API
   let ret = this.http.get<ObjectiveTask[]>(this.rootUrl + '/api/v1/tasks?Status=' + status +'PlayerId=' + playerId);
   return ret;
  }

   addObjective(objective: Objective[]): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/v1/objectives',objective,this.httpOptions);
    return ret;
  }

  addKeyResult(keyresult: KeyResult): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/v1/keyresults',keyresult,this.httpOptions);
    return ret;
  }

  addTask(task: ObjectiveTask[]): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/v1/tasks',task,this.httpOptions);
    return ret;
  }

  addCheckin(checkin: ObjectiveCheckin): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/v1/checkin',checkin,this.httpOptions);
    return ret;
  }

  updateKeyResult(keyresult: KeyResult): Observable<boolean>{
    let ret = this.http.put<boolean>(this.rootUrl + '/api/v1/keyresults',keyresult,this.httpOptions);
    return ret;
  }



  updateObjective(objective: Objective): Observable<boolean>{
    let ret = this.http.put<boolean>(this.rootUrl + '/api/v1/objectives',objective,this.httpOptions);
    return ret;
  }

  deleteObjective(objectiveId: string): Observable<boolean>{
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/v1/objectives/' + objectiveId);
    return ret;
  }

  deleteKeyResult(keyresult: KeyResult): Observable<boolean>{
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/v1/keyresults/' + keyresult.KeyResultId + "/" + keyresult.ObjectiveId);
    return ret;
  }

  deleteTask(TaskId: string): Observable<boolean>{
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/v1/tasks/' + TaskId );
    return ret;
  }

  deleteCheckin(CheckinId: string): Observable<boolean>{
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/v1/checkins/' + CheckinId );
    return ret;
  }

  updateTask(task: ObjectiveTask): Observable<boolean>{
    let ret = this.http.put<boolean>(this.rootUrl + '/api/v1/tasks',task,this.httpOptions);
    return ret;
  }

  updateTaskComplete(task: ObjectiveTask): Observable<boolean>{
    let ret = this.http.patch<boolean>(this.rootUrl + '/api/v1/tasks/' + task.TaskId,task,this.httpOptions);
    return ret;
  }

  updateKeyResultValue(value: KeyResultValue): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/v1/keyresultvalues',value,this.httpOptions);
    return ret;
  }

  public getStreams(): Observable<Stream[]>{
    //let ret = this.http.get<Objective[]>(this.rootUrl + '/api/objectives/team/' + TeamId );
    return of(STREAMS_DATA);
   }
  
}
