<div class="app-page">
    <h2><mat-icon>label</mat-icon>&nbsp;Scouting sources</h2>
    <label style="color: white;">
        Enable the sources you want scouts to use. If no source is enabled, functionality is turned off.
    </label><br />

    <label style="color: white;">
        If you miss any value, contact Dalecarlia Scouting to request the new source. The sources will be available to
        all organizations using Dalecarlia Scouting.
    </label><br />
    <br />
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="ScoutingSourceId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
            <td mat-cell *matCellDef="let element" style="font-size: 12px;"> {{element.ScoutingSourceId}} </td>
            <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
          Average<br/>
          Median
         </td> -->
        </ng-container>
        <ng-container matColumnDef="Text">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Source text </th>
            <td mat-cell *matCellDef="let element" style="font-size: 12px;"> {{element.Text}} </td>
            <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
        Average<br/>
        Median
       </td> -->
        </ng-container>



        <ng-container matColumnDef="IsEnabled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Enabled </th>
            <td mat-cell *matCellDef="let element">
                <div style="border-color: black;"><mat-slide-toggle [checked]="element.IsEnabled"
                        (change)="onIsEnabledChange($event,element.ScoutingSourceId)"></mat-slide-toggle></div>
            </td>
            <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">


            </td>
        </ng-container>

        <!--         <ng-container matColumnDef="Buttons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">

                <button mat-icon-button _disabled='true' type="button" [routerLink]="['/config/user', element.UserId]">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-button _disabled='true' type="button"
                    (click)="activateSource(element.ScoutingSourceId"
                    value="SET PASSWORD">
                    SET PWD
                </button>

            </td>
        </ng-container> -->




        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 12px;;"></tr>
        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter</td>
        </tr>
        <!--   <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" style="height: 24px;"></tr> -->
    </table>
</div>