<a [routerLink]="['/player/details', Player.LifelineScoutingId]" style="color: white;">
<mat-form-field style="width: 300px;"  appearance="fill"  class="smalltext"   >
   
    <img [src]="'/assets/' + Player.ProfileImage" *ngIf="Player.ProfileImage!=null" style="width: 45px;">   
 
    <mat-label>Player</mat-label>
  
    <input matInput maxlength="100" class="smalltext" [value]="fullname()" disabled /> 
      
</mat-form-field>
</a>
    