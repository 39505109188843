import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadEventService } from '../services/load-event.service';
import { FlowEventModel } from '../models/EventModels';
import * as moment from 'moment';

@Component({
  selector: 'app-load-team-feed',
  templateUrl: './load-team-feed.component.html',
  styleUrls: ['./load-team-feed.component.css']
})
export class LoadTeamFeedComponent implements OnInit {
  TeamId: string;
  _flow: FlowEventModel[];

  constructor(public _route: ActivatedRoute,   public _router: Router,    public _eventService: LoadEventService) {
    this._route.params.subscribe(params => {
      this.TeamId = params['TeamId'];     
      this.getFlow();
    });
  }

 

  ngOnInit() {
    //this.getFlow();
  }

  getFlow(){
    this._eventService.getEventFlow(this.TeamId).subscribe(i => {

      for(let evt of i){
        var minDate = moment.utc('0001-01-01');
        moment.locale('sv');

        
       moment.updateLocale('sv', {
          relativeTime : {
              future: "om %s",

              past:   "%s sedan",
              s  : 'nu',
              ss : '%d s',
              m:  "1 min",
              mm: "%d min",
              h:  "1 timme",
              hh: "%d timmar",
              
              d:  "1 dag",
              dd: "%d dagar",
              M:  "1 mån",
              MM: "%d mån",
              y:  "1 år",
              yy: "%d år"
          }
      });


      if(moment)
        if(moment(evt.UpdatedTimestamp +  "+01:00").isAfter(minDate)){
           evt.TimeAgoText = moment(evt.UpdatedTimestamp).utc().fromNow();                  
        }
          
        else
          evt.TimeAgoText = '-';        
          
      } 


        this._flow = i;
    })

  }









}
