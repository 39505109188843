 <div class="app-page" >
<!--    <mat-toolbar style="height: 60px;">
        <mat-form-field style="font-size: 14px;">
         <mat-label>Team</mat-label>
         <mat-select [formControl]="teamFormControl" (selectionChange)="refreshData()" >
           <mat-option *ngFor="let team of _teams" [value]="team">
             {{team.Level}}
           </mat-option>        
         </mat-select>
       </mat-form-field> 
      <mat-form-field style="font-size: 14px;">
         <mat-label>Season</mat-label>
         <mat-select [formControl]="seasonFormControl"  (selectionChange)="refreshData()">
           <mat-option *ngFor="let season of _seasons"  [value]="season">
             {{season.Name}}
           </mat-option>
           
         </mat-select>
       </mat-form-field> 
       
       <a matButton (click)="createUser()">NEW USER</a>
     </mat-toolbar> -->

     <h2>Users</h2>
<!--      <a mat-button (click)="createUser()" color="primary"><mat-icon>add</mat-icon>NEW USER</a> -->
<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Enter text to filter on" #input>
</mat-form-field>
     <table mat-table [dataSource]="dataSource" matSort  >

        <ng-container matColumnDef="UserName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
            <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.UserName}} </td>
          <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
              Average<br/>
              Median
             </td> -->
          </ng-container>
        <ng-container matColumnDef="LastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Lastname </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.LastName}} </td>
        <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
            Average<br/>
            Median
           </td> -->
        </ng-container>
       
        <ng-container matColumnDef="FirstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Firstname </th>
          <td mat-cell *matCellDef="let element"  ><div style="border-color: black;">{{ element.FirstName }}</div> </td>
          <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">
       
    
          </td>
        </ng-container>

          <ng-container matColumnDef="IsEnabled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Enabled </th>
            <td mat-cell *matCellDef="let element"  ><div style="border-color: black;"><mat-slide-toggle [checked] = "element.IsEnabled" (change)="onIsEnabledChange($event,element.UserId)"></mat-slide-toggle></div> </td>
            <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">
         
      
            </td>
        </ng-container>

        <ng-container matColumnDef="Buttons">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"> 
                
                <button mat-icon-button _disabled='true' type="button" [routerLink]="['/config/user', element.UserId]">
                  <mat-icon>edit</mat-icon>            
                </button> 
                <button mat-button _disabled='true' type="button" (click)="setNewPassword(element.UserName, element.LastName, element.FirstName)" value="SET PASSWORD">
                  SET PWD
                </button>
               
            </td>
          </ng-container>
    
        
     
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 12px;;"></tr>
         <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      <!--   <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" style="height: 24px;"></tr> -->
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
     </div>