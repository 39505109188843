/* import { StringMap } from '@angular/compiler/src/compiler_facade_interface'; */
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-set-user-password-dialog',
  templateUrl: './set-user-password-dialog.component.html',
  styleUrls: ['./set-user-password-dialog.component.css']
})
export class SetUserPasswordDialogComponent implements OnInit {

  newPassword =  new UntypedFormControl('');

  constructor( 
    public dialogRef: MatDialogRef<SetUserPasswordDialogComponent>,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: PlayerDataModel,    
    ) {
     
      console.log(data);
  }

  ngOnInit(): void {
  }

  save(): void{
    this.userService.setNewUserPassword(this.data.UserName,this.newPassword.value).subscribe(result=>{
      if(result.Success){
        this.openSnackBar("Password successfully changed", "CLOSE");
        this.dialogRef.close();
      }else{
        this.openSnackBarNoDuration("Password change failed: " + result.Errors[0], "CLOSE");
      }
    });

  }

  onNoClick(){
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  openSnackBarNoDuration(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}

class PlayerDataModel{
  PlayerId: string;
  LastName: string;
  FirstName: string;
  UserName: string;
}
