<div class="app-page" style="margin-left: 20px;">
    <p>Organization Goals</p>
    <div style="display: flex;">
       <app-goal-card>
    
    </app-goal-card>
    <app-goal-card>
    
    </app-goal-card>
    </div>
 
    
</div>
