import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/baseservice';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FlowItemModel, FlowItemViewModel } from '../models/FlowItemModel';
import * as moment from 'moment';
import { ReportViewModel } from '../models/ReportModels';
import { PlayerFavouriteViewModel } from '../models/PlayerFavouriteModels';
import { ReportSearchComponent } from '../report-search/report-search.component';
import { ReportSearchParameters } from '../models/ReportSearchParameters';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ScoutingFlowService extends BaseService {

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }
   
   getAllReports(): Observable<FlowItemModel[]>{
    var startDate: String;
    startDate = moment().add(1,'days').format("YYYY-MM-DD");
    var endDate = moment().add(-4,'months').format("YYYY-MM-DD");
    let ret = this.http.get<any[]>(this.rootUrl + '/api/scoutingflow/all/' + endDate +'/' + startDate);
    return ret;
  }

  getReport(_reportId: string): Observable<ReportViewModel>{
    let ret = this.http.get<ReportViewModel>(this.rootUrl + '/api/report/' + _reportId);
    return ret;
  }

  getFavouritesFlow(): Observable<PlayerFavouriteViewModel[]>{
    //7 days back
    let ret = this.http.get<PlayerFavouriteViewModel[]>(this.rootUrl + '/api/scoutingflow/favourites/7');
    return ret;
  }

  getOrganizationStatsForScouting(): Observable<any>{
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/reports/analysis/permonth');
    return ret;    
    
  }

  getOrganizationBirthyearStatsForScouting(seasonId: number): Observable<any>{
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/reports/analysis/birthyear/' + seasonId);
    return ret;    
    
  }

  
  getOrganizationEventStatsForScouting(seasonId: number): Observable<any>{
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/reports/analysis/events/' + seasonId);
    return ret;    
    
  }

  getOrganizationProjectionStatsForScouting(seasonId: number): Observable<any>{
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/reports/analysis/projection/' + seasonId);
    return ret;    
    
  }
  
  getOrganizationReportingForScoutsBySeason(seasonId: number): Observable<any>{
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/reports/analysis/byscout/' + seasonId);
    return ret;    
    
  }

  GetReportingGameDivisionCountBySeason(seasonId: number): Observable<any>{
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/reports/analysis/divisions/' + seasonId);
    return ret;    
    
  }
  
  GetScoutReportingLocationCount(seasonId: number): Observable<any>{
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/reports/analysis/locations/' + seasonId);
    return ret;        
  }


}
