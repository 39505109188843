import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-ranking-list',
  templateUrl: './new-ranking-list.component.html',
  styleUrls: ['./new-ranking-list.component.css']
})
export class NewRankingListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
