import { Component, Input, OnInit } from '@angular/core';
import { SkillViewModel } from '../../models/ReportModels';

@Component({
  selector: 'app-skill-ranking',
  templateUrl: './skill-ranking.component.html',
  styleUrls: ['./skill-ranking.component.css']
})
export class SkillRankingComponent implements OnInit {

  @Input() SkillText: string;
  @Input() SkillId: string;
  @Input() Skill: SkillViewModel;

  controlId: string;

  mylist = [0,1,2,3,4,5];
  constructor() { }

  ngOnInit(): void {
    this.controlId = this.SkillId;
    console.log(this.SkillId);
  }

  formatLabel(value: number) {
    if (value == 1) {
      return 'Bad';
    }
    if (value == 2) {
      return 'Avg';
    }
    if (value == 3) {
      return 'G';
    }
    if (value == 4) {
      return 'VG';
    }
    if (value == 5) {
      return 'Exc';
    }


    return value;
  }

  getText(value: number){
    if (value == 0) {
      return '';
    }
    if (value == 1) {
      return 'Bad';
    }
    if (value == 2) {
      return 'Average';
    }
    if (value == 3) {
      return 'Good';
    }
    if (value == 4) {
      return 'Very good';
    }
    if (value == 5) {
      return 'Excellent';
    }


    return value;
  }
}
