import { Routes } from '@angular/router'
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { AuthGuard } from './auth/auth.guard';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { TeamListComponent } from './team-list/team-list.component';
import { TestListComponent } from './test/test-list/test-list.component';
import { TestAddComponent } from './test/test-add/test-add.component';
import { ProfilesListComponent } from './profiles/profiles-list/profiles-list.component';
import { TeamProfileListComponent } from './test-result/team-profile-list/team-profile-list.component';
import { TeamProfilePlayersListComponent } from './test-result/team-profile-players-list/team-profile-players-list.component';
import { TestResultAddComponent } from './test-result/test-result-add/test-result-add.component';
import { PlayerListComponent } from './test-result/player-list/player-list.component';
import { TestEditorComponent } from './test/test-editor/test-editor.component';
import { StartComponent } from './start/start.component';
import { TestTableComponent } from './test/test-table/test-table.component';
import { PlayerDashboardComponent } from './player-dashboard/player-dashboard.component';
import { PlayerFullDetailsComponent } from './player/player-full-details/player-full-details.component';
import { ConfigGuideComponent } from './config-guide/config-guide.component';
import { ProfileEditorComponent } from './profiles/profile-editor/profile-editor.component';
import { TestToplistComponent } from './test/test-toplist/test-toplist.component';
import { TeamTestTopListsComponent } from './test/team-test-top-lists/team-test-top-lists.component';
import { TestResultsPlayerComponent } from './test-result/test-results-player/test-results-player.component';
import { TestResultsOrganizationComponent } from './test-result/test-results-organization/test-results-organization.component';
import { OrganizationTestToplistsComponent } from './test/organization-test-toplists/organization-test-toplists.component';
import { OrganizationTeamListComponent } from './organization-team-list/organization-team-list.component';
import { TeamSeasonListComponent } from './team-season-list/team-season-list.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { PlayersTeamSeasonComponent } from './players-team-season/players-team-season.component';
import { ScoutingFlowComponent } from './scouting/flow/scouting-flow/scouting-flow.component';
/* import { ScoutingRankingListsComponent } from './scouting/rankinglists/scouting-ranking-lists/scouting-ranking-lists.component'; */
import { GameListComponent } from './scouting/game/game-list/game-list.component';
import { PlayerTotalViewComponent } from './scouting/player/player-total-view/player-total-view.component';
import { GameDetailsComponent } from './scouting/game/game-details/game-details.component';
import { TeamPerformanceScreenComponent } from './performance/screens/team-performance-screen/team-performance-screen.component';
import { ScheduleViewComponent } from './scouting/schedule/schedule-view/schedule-view.component';
import { PlayerFullComponent } from './performance/screens/player-full/player-full.component';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { TeamPlayerGameGradesComponent } from './performance/components/team-player-game-grades/team-player-game-grades.component';
import { PerformanceTeamPlayerListComponent } from './performance/screens/performance-team-player-list/performance-team-player-list.component';
import { TeamGameListComponent } from './performance/screens/team-game-list/team-game-list.component';
//import { RankingListDetailsComponent } from './scouting/rankinglists/ranking-list-details/ranking-list-details.component';
import { FavouritesFlowComponent } from './scouting/flow/favourites-flow/favourites-flow.component';
import { LoadTeamFeedComponent } from './load/load-team-feed/load-team-feed.component';
import { LoadGameListComponent } from './load/load-game-list/load-game-list.component';
import { LoadGameDetailsComponent } from './load/load-game-details/load-game-details.component';
import { PlayerDetailsComponent } from './player-details/player-details.component';
import { ConfigUserlistComponent } from './configuration/config-userlist/config-userlist.component';
import { AddPlayerInjuryComponent } from './player/components/add-player-injury/add-player-injury.component';
import { InjuryTableTeamComponent } from './injury/screens/injury-table-team/injury-table-team.component';
import { InjuryTeamOverviewComponent } from './injury/screens/injury-team-overview/injury-team-overview.component';
import { EditInjuryComponent } from './injury/screens/edit-injury/edit-injury.component';
import { InjuryPreviewinfoComponent } from './injury/screens/injury-previewinfo/injury-previewinfo.component';
import { TeamParametersettingsListComponent } from './configuration/team-parametersettings-list/team-parametersettings-list.component';
import { ScoutingRankingListsComponent } from './scouting/rankinglists/scouting-ranking-lists/scouting-ranking-lists.component';
import { LoadTeamOverviewComponent } from './load/load-team-overview/load-team-overview.component';
import { ConfigUserDetailsComponent } from './configuration/config-user-details/config-user-details.component';
import { ConfigStatisticsListComponent } from './configuration/config-statistics-list/config-statistics-list.component';
import { ConfigStatisticsHomeComponent } from './configuration/config-statistics-home/config-statistics-home.component';
import { ConfigStatisticsViewComponent } from './configuration/config-statistics-view/config-statistics-view.component';
import { ConfigStatisticsGroupComponent } from './configuration/config-statistics-group/config-statistics-group.component';
import { GameStatisticsComponent } from './scouting/components/game-statistics/game-statistics.component';
import { GameStatisticsTableComponent } from './performance/screens/game-statistics-table/game-statistics-table.component';
import { GameStatisticsTableEditComponent } from './performance/screens/game-statistics-table-edit/game-statistics-table-edit.component';
import { ConfigStatisticsCalculatedListComponent } from './configuration/config-statistics-calculated-list/config-statistics-calculated-list.component';
import { LoadGameOverviewComponent } from './load/load-game-overview/load-game-overview.component';
import { ConfigStatisticsProfilesComponent } from './configuration/config-statistics-profiles/config-statistics-profiles.component';
import { TeamScheduleMonthlyViewComponent } from './TeamSchedule/screens/team-schedule-monthly-view/team-schedule-monthly-view.component';
import { ConfigStatisticsProfileComponent } from './configuration/config-statistics-profile/config-statistics-profile.component';
import { TeamLoadlevelAnalysisComponent } from './load/team-loadlevel-analysis/team-loadlevel-analysis.component';
import { RankingListDetailsComponent } from './scouting/rankinglists/ranking-list-details/ranking-list-details.component';
import { PlayerSearchComponent } from './scouting/player-search/player-search.component';
import { ReportSearchComponent } from './scouting/report-search/report-search.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { ScoutingStatisticsDashboardComponent } from './scouting/scouting-statistics-dashboard/scouting-statistics-dashboard.component';
import { ImportStatisticsComponent } from './performance/screens/import-statistics/import-statistics.component';
import { NewReportComponent } from './scouting/new-report/new-report.component';
import { LoadTeamOverview2Component } from './load/load-team-overview2/load-team-overview2.component';
import { TeamGoalOverviewComponent } from './development/screens/team-goal-overview/team-goal-overview.component';
import { OrganizationGoalOverviewComponent } from './development/screens/organization-goal-overview/organization-goal-overview.component';
import { PlayerGoalsComponent } from './development/screens/player-goals/player-goals.component';
//import { LoadHomeComponent } from './load/load-home/load-home.component';
//import { ScoutingRankingListsComponent } from './scouting/rankinglists/scouting-ranking-lists/scouting-ranking-lists.component';

//Start OBJECTIVES
import { TeamObjectivesComponent } from './objectives/views/team-objectives/team-objectives.component';
import { OrganizationObjectivesComponent } from './objectives/views/organization-objectives/organization-objectives.component';
import { StreamObjectivesComponent } from './objectives/views/stream-objectives/stream-objectives.component';
import { OutletTestComponent } from './objectives/views/outlet-test/outlet-test.component';
import { ObjectiveDetailsComponent } from './objectives/views/objective-details/objective-details.component';
import { NewObjectiveComponent } from './objectives/views/new-objective/new-objective.component';
import { NewCheckinComponent } from './objectives/views/new-checkin/new-checkin.component';
import { OkrGuideComponent } from './objectives/views/okr-guide/okr-guide.component';
import { MyTasksComponent } from './objectives/views/my-tasks/my-tasks.component';


import { CompareTestResultsComponent } from './test-result/views/compare-test-results/compare-test-results.component';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';

//Training
import { NewEquipmentComponent } from './configuration/training/views/new-equipment/new-equipment.component';
import { NewWorkoutComponent } from './configuration/training/views/new-workout/new-workout.component';
import { NewProgramComponent } from './configuration/training/views/new-program/new-program.component';
import { GradeGameComponent } from './performance/screens/grade-game/grade-game.component';
import { TrainingPreviewInfoComponent } from './training/views/training-preview-info/training-preview-info.component';
import { AssignedProgramsComponent } from './training/views/assigned-programs/assigned-programs.component';
import { AssignedProgramDetailsComponent } from './training/views/assigned-program-details/assigned-program-details.component';
import { NewAssignedProgramComponent } from './training/views/new-assigned-program/new-assigned-program.component';

//Scouting
import { EditReportComponent } from './scouting/edit-report/edit-report.component';
import { NewNoteComponent } from './scouting/new-note/new-note.component';
import { AgentListComponent } from './scouting/agents/agent-list/agent-list.component';
import { GameSearchComponent } from './scouting/game/game-search/game-search.component';


import { PlayerScheduleComponent } from './training/views/player-schedule/player-schedule.component';
import { TeamPlayerScheduleComponent } from './training/views/team-player-schedule/team-player-schedule.component';
import { TeamScheduleWeekViewComponent } from './TeamSchedule/screens/team-schedule-week-view/team-schedule-week-view.component';
import { TagListComponent } from './scouting/tags/tag-list/tag-list.component';
import { SetNewPasswordComponent } from './user/set-new-password/set-new-password.component';
import { LoadTeamACWRTableComponent } from './load/load-team-acwrtable/load-team-acwrtable.component';
import { TestViewPlayerCardsComponent } from './test-result/test-view-player-cards/test-view-player-cards.component';
import { ConfigTestViewListComponent } from './configuration/config-test-view-list/config-test-view-list.component';
import { ConfigTestviewEditComponent } from './configuration/config-testview-edit/config-testview-edit.component';
import { TeamEventDetailsComponent } from './TeamSchedule/screens/team-event-details/team-event-details.component';
import { GameAssessmentsViewComponent } from './configuration/assessments/game-assessments-view/game-assessments-view.component';

//FORMS
import { SurveyFeedComponent } from './surveys/screens/survey-feed/survey-feed.component';
import { TeamFormsFeedComponent } from './forms/screens/team-forms-feed/team-forms-feed.component';
import { FormSubmissionComponent } from './forms/screens/form-submission/form-submission.component';
import { FormSubmissionEditComponent } from './forms/screens/form-submission-edit/form-submission-edit.component';
import { OrgFormsComponent } from './forms/screens/admin/org-forms/org-forms.component';
import { FormsTableComponent } from './forms/screens/forms-table/forms-table.component';
import { FormEditComponent } from './forms/screens/admin/form-edit/form-edit.component';
import { EditFormComponent } from './forms/screens/admin/edit-form/edit-form.component';
import { FormSettingsComponent } from './forms/screens/admin/form-settings/form-settings.component';
import { OrganizationTeamsDashboardComponent } from './dashboards/screens/organization/organization-teams-dashboard/organization-teams-dashboard.component';
import { GameChartsComponent } from './performance/screens/game-charts/game-charts.component';
import { FormsHelpComponent } from './forms/screens/admin/forms-help/forms-help.component';
import { SetGameFilterComponent } from './scouting/game/set-game-filter/set-game-filter.component';
import { RankingListEditComponent } from './scouting/rankinglists/ranking-list-edit/ranking-list-edit.component';
import { FilteredScoutingFlowComponent } from './scouting/flow/filtered-scouting-flow/filtered-scouting-flow.component';
import { ScoutingSourceListComponent } from './configuration/scouting/scouting-source-list/scouting-source-list.component';


export const appRoutes: Routes = [
    {
        path: 'setnewpassword',  component: SetNewPasswordComponent
    },
    /* { path : '', redirectTo:'/login', pathMatch : 'full'}, */
    {
        path: '', component: UserComponent,
        children: [{ path: '', component: SignInComponent }]
    },
    { path: 'home', component: HomeComponent, canActivate:[AuthGuard] },
    //{ path: 'forbidden', component: ForbiddenComponent, canActivate: [AuthGuard] },
    { path: 'forbidden', component: SignInComponent, canActivate: [AuthGuard] },
    {
        path: 'signup', component: UserComponent,
        children: [{ path: '', component: SignUpComponent,canActivate:[AuthGuard] }]
    },
    {
        path: 'login', component: UserComponent,
        children: [{ path: '', component: SignInComponent }]
    },
    {
        path: 'accessdenied', component: AccessDeniedComponent, canActivate: [AuthGuard]
    },
    {
        path: 'signin', component: SignInComponent
    },
   
    {
        path: 'profiles', component: ProfilesListComponent,canActivate:[AuthGuard]
        
    },
    {
        path: 'welcome', component: WelcomePageComponent,canActivate:[AuthGuard]
        
    },
    {
        path: 'profileeditor/:ProfileId', component: ProfileEditorComponent,canActivate:[AuthGuard]
        
    },
    {
        path: 'testview/list', component: ConfigTestViewListComponent,canActivate:[AuthGuard]
        
    },
    {
        path: 'testview/:TestViewId', component: ConfigTestviewEditComponent,canActivate:[AuthGuard]
        
    },
    {
        path: 'start', component: StartComponent,canActivate:[AuthGuard]
    },
    {
        path: 'teams', component: TeamListComponent,canActivate:[AuthGuard]
    },
    {
        path: 'teams/org', component: OrganizationTeamListComponent,canActivate:[AuthGuard]
    },
    {
        path: 'log', component: TestResultsOrganizationComponent,canActivate:[AuthGuard]
    },
    {
        path: 'toplists', component: OrganizationTestToplistsComponent,canActivate:[AuthGuard]
    },
    {
        path: 'tests', component: TestTableComponent,canActivate:[AuthGuard]
    },
    {
        path: 'testresultadd/:PlayerId/:ProfileId/:PlayerName', component: TestResultAddComponent,canActivate:[AuthGuard]
    },
    {
        path: 'testtoplist/team/:TeamId', component: TeamTestTopListsComponent ,canActivate:[AuthGuard]
    },
    {
        path: 'testtoplist/org', component: TestToplistComponent,canActivate:[AuthGuard]
    },
    {
        path: 'testadd', component: TestAddComponent,canActivate:[AuthGuard]
    },
   
    {
        path: 'test/edit/:TestId', component: TestEditorComponent,canActivate:[AuthGuard]
    },
    {
        path: 'testresults/player/:PlayerId/:ProfileId/:TeamId', component: TestResultsPlayerComponent, canActivate:[AuthGuard]
    },
    {
        path: 'testresults/compare/:TeamId', component: CompareTestResultsComponent, canActivate:[AuthGuard]
    },
    {
        path: 'teamprofiles', component: TeamProfileListComponent,canActivate:[AuthGuard]
    },
    {
        path: 'teamprofilesplayers/:TeamId/:ShowFilter', component: TeamProfilePlayersListComponent,canActivate:[AuthGuard]
    },
    {
        path: 'report', component: TestResultAddComponent,canActivate:[AuthGuard]
    },
    {
        path: 'teamplayers/:TeamId', component: PlayerListComponent,canActivate:[AuthGuard]
    },
    {
        path: 'teamseasons', component: TeamSeasonListComponent,canActivate:[AuthGuard]
    },
    {
        path: 'team/schedule/:TeamId', component: TeamScheduleMonthlyViewComponent,canActivate:[AuthGuard],data: {roles: ['Team_ScheduleViewer','Team_ScheduleEditor','Load_AllPhysicalStatus', 'Load_TeamMgr']}        
    },
    {
        path: 'team/schedule/week/:TeamId', component: TeamScheduleWeekViewComponent,canActivate:[AuthGuard],data: {roles: ['Team_ScheduleViewer','Team_ScheduleEditor','Load_AllPhysicalStatus', 'Load_TeamMgr']}        
    },
    {
        path: 'team/schedule/teamevent/:TeamEventId', component: TeamEventDetailsComponent,canActivate:[AuthGuard],data: {roles: ['Team_ScheduleViewer','Team_ScheduleEditor','Load_AllPhysicalStatus', 'Load_TeamMgr']}        
    },
    {
        path: 'playerdetails/:PlayerId/:ProfileId/:TeamId/:SeasonId', component: PlayerDetailsComponent,canActivate:[AuthGuard]
    },

    {
        path: 'configguide', component: ConfigGuideComponent,canActivate:[AuthGuard]
    },
    {
        path: 'user', component: UserProfileComponent ,canActivate:[AuthGuard]
    },
    {
        path: 'resetpassword', component: ResetPasswordComponent ,
    },
    {
        path: 'changepassword', component: ChangePasswordComponent,canActivate:[AuthGuard]
    }
    ,
    {
        path: 'playersteamseason/:TeamId', component: PlayersTeamSeasonComponent,canActivate:[AuthGuard],data: {roles: ['Test_Admin']}
    },
    {
        path: 'scouting/playertotalview', component: PlayerTotalViewComponent,canActivate:[AuthGuard]
    },
    {
        path: 'testresults/testview/:TeamId/:TestViewId', component: TestViewPlayerCardsComponent,canActivate:[AuthGuard]
    },

    {
        path: 'scouting/allreports', component: ScoutingFlowComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/flow', component: FilteredScoutingFlowComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
        
    {
        path: 'scouting/favourites', component: FavouritesFlowComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/lists', component: ScoutingRankingListsComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },  
     {
        path: 'scouting/list/:ListId', component: RankingListDetailsComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },  
    {
        path: 'scouting/rankinglistedit/:ListId', component: RankingListEditComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },  
    {
        path: 'scouting/games', component: GameListComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/games/search', component: GameSearchComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/setgamefilter', component: SetGameFilterComponent, canActivate: [AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
       
    {
        path: 'scouting/playerdetails/:PlayerId', component: PlayerTotalViewComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/gamedetails/:GameId', component: GameDetailsComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/schedule', component: ScheduleViewComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/player/search', component: PlayerSearchComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/report/search', component: ReportSearchComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/newreport/:PlayerId', component: NewReportComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/editreport/:ReportId', component: EditReportComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/newreport/:PlayerId/:GameId', component: NewReportComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/newnote/:PlayerId/:GameId', component: NewNoteComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/editnote/:NoteId', component: NewNoteComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Scout', 'Scouting_Admin']}
    },
    {
        path: 'scouting/statisticsdashboard', component: ScoutingStatisticsDashboardComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Admin']}
    },
    {
        path: 'scouting/agents', component: AgentListComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Admin', 'Scouting_Scout']}
    },
    {
        path: 'scouting/tags', component: TagListComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Admin', 'Scouting_Scout']}
    },
    {
        path: 'scouting/sources', component: ScoutingSourceListComponent, canActivate:[AuthGuard],data: {roles: ['Scouting_Admin']}
    },



    {
        path: 'performance/teamgrades/:TeamId', component: TeamPlayerGameGradesComponent,canActivate:[AuthGuard],data: {roles: ['Load_TeamMgr','Scouting_PerformanceViewer','Scouting_Admin', 'Scouting_Scout']}
    },
    {
        path: 'performance/game/statistics/:GameId/:TeamId', component: GameStatisticsTableComponent ,canActivate:[AuthGuard],data: {roles: ['Scouting_PerformanceViewer','Scouting_Admin', 'Scouting_Scout', 'Statistics_Viewer', 'Statistics_Admin', 'Statistics_Editor', 'StatisticsAdmin']}
    },
    
    {
        path: 'performance/game/statistics/import/:GameId/:TeamId', component: ImportStatisticsComponent ,canActivate:[AuthGuard],data: {roles: ['Statistics_Admin', 'Statistics_Editor', 'StatisticsAdmin']}
    },
    {
        path: 'performance/game/statistics/edit/:GameId/:TeamId/:ViewId', component: GameStatisticsTableEditComponent ,canActivate:[AuthGuard],data: {roles: ['Statistics_Admin', 'StatisticsAdmin', 'Statistics_Editor']}
    },
    {
        path: 'performance/teamplayers/:TeamId', component: PerformanceTeamPlayerListComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_PerformanceViewer','Scouting_Admin']}
    },
    {
        path: 'performance/teamstats/:TeamId', component: PerformanceTeamPlayerListComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_PerformanceViewer','Scouting_Admin']}
    },
    {
        path: 'performance/teamgames/:TeamId', component: TeamGameListComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_PerformanceViewer','Scouting_Admin', 'Scouting_Scout']}
    },
    {
        path: 'performance/gamecharts/:TeamId', component: GameChartsComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_PerformanceViewer','Scouting_Admin', 'Scouting_Scout']}
    },
    {
        path: 'performance/goals/team/:TeamId', component: TeamGoalOverviewComponent,canActivate:[AuthGuard],data: {roles: ['Goals_Viewer', 'Goals_Editor','Org_Admin']}
    },
    {
        path: 'performance/goals/organization', component: OrganizationGoalOverviewComponent,canActivate:[AuthGuard],data: {roles: ['Goals_Viewer', 'Goals_Editor','Org_Admin']}
    },
    {
        path: 'performance/teagoals/player/:PlayerId', component: PlayerGoalsComponent,canActivate:[AuthGuard],data: {roles: ['Goals_Viewer', 'Goals_Editor','Org_Admin']}
    },
    {
        path: 'performance/game/addgrades/:GameId/:TeamId', component: GradeGameComponent,canActivate:[AuthGuard],data: {roles: ['Scouting_PerformanceViewer', 'Org_Admin', 'Scouting_Admin']}
    },




    { path: 'load', loadChildren: () => import('./load/load.module').then(m => m.LoadModule) },
    {
        path: 'load/team/overview/:TeamId/:TodayOnly', component: LoadTeamOverview2Component,canActivate:[AuthGuard],data: {roles: ['Load_AllPhysicalStatus', 'Org_Admin', 'Load_TeamMgr']}
    },  
    {
        path: 'load/team/loadlevel/:TeamId', component: TeamLoadlevelAnalysisComponent,canActivate:[AuthGuard],data: {roles: ['Load_AllPhysicalStatus', 'Org_Admin', 'Load_TeamMgr']}
    },  
    {
        path: 'load/team/acwr/:TeamId', component: LoadTeamACWRTableComponent,canActivate:[AuthGuard],data: {roles: ['Load_AllPhysicalStatus', 'Org_Admin', 'Load_TeamMgr']}
    },     
    {

        path: 'load/team_feed/:TeamId', component: LoadTeamFeedComponent,canActivate:[AuthGuard],data: {roles: ['Load_TeamMgr']}
    },
    {
        path: 'load/game_feed/:TeamId', component: LoadGameListComponent,canActivate:[AuthGuard],data: {roles: ['Load_TeamMgr']}
    },
    {
        path: 'load/game_overview/:TeamId', component: LoadGameOverviewComponent,canActivate:[AuthGuard],data: {roles: ['Load_TeamMgr']}
    },
    {
        path: 'load/game/:GameId/:TeamId', component: LoadGameDetailsComponent,canActivate:[AuthGuard],data: {roles: ['Load_TeamMgr']}
    },
    {

        path: 'player/details/:PlayerId', component: PlayerFullDetailsComponent,canActivate:[AuthGuard],data: {roles: ['Load_TeamMgr', 'Scouting_Admin', 'Test_admin']}
    },
    {
        path: 'config/userlist', component: ConfigUserlistComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'User_Admin']}
    },
    {
        path: 'config/user/:UserId', component: ConfigUserDetailsComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'User_Admin']}
    },
    {
        path: 'config/statistics/list', component: ConfigStatisticsListComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'Statistics_Admin', 'StatisticsAdmin']}
    },
    {
        path: 'config/statistics/home', component: ConfigStatisticsHomeComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'Statistics_Admin', 'StatisticsAdmin']}
    },
    {
        path: 'config/gamestatistics/parameters/all', component: ConfigStatisticsListComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'Statistics_Admin', 'StatisticsAdmin']}
    },
    {
        path: 'config/gamestatistics/parameterscalculated/all', component: ConfigStatisticsCalculatedListComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'Statistics_Admin', 'StatisticsAdmin']}
    },
    {
        path: 'config/gamestatistics/view/:ViewId', component: ConfigStatisticsViewComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'Statistics_Admin', 'StatisticsAdmin']}
    },
    {
        path: 'config/gamestatistics/group/:GroupId', component: ConfigStatisticsGroupComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'Statistics_Admin', 'StatisticsAdmin']}
    },
    {
        path: 'config/gamestatistics/profiles', component: ConfigStatisticsProfilesComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'Statistics_Admin', 'StatisticsAdmin']}
    },
    {
        path: 'config/gamestatistics/profile/:ProfileId', component: ConfigStatisticsProfileComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'Statistics_Admin', 'StatisticsAdmin']}
    },
    {
        path: 'config/gameassessments', component: GameAssessmentsViewComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin','Scouting_Admin']}
    },

    {
        path: 'injury/add/:PlayerId/:PlayerName/:TeamId', component: AddPlayerInjuryComponent,canActivate:[AuthGuard],data: {roles: ['Medical_Admin', 'Medical_Editor']}
    },
    {
        path: 'injury/byteam/:TeamId/:Scope', component: InjuryTableTeamComponent,canActivate:[AuthGuard],data: {roles: ['Medical_Admin', 'Medical_Editor','Medical_Viewer']}
    },
    {
        path: 'injury/overview/:TeamId', component: InjuryTeamOverviewComponent,canActivate:[AuthGuard],data: {roles: ['Medical_Admin', 'Medical_Editor','Medical_Viewer']}
    },
    {
        path: 'injury/edit/:InjuryId', component: EditInjuryComponent,canActivate:[AuthGuard],data: {roles: ['Medical_Admin', 'Medical_Editor']}
    },
    {
        path: 'injury/previewinfo', component: InjuryPreviewinfoComponent,canActivate:[AuthGuard],data:  {roles:['Medical_Admin', 'Medical_Editor','Medical_Viewer']}
    },
    {
        path: 'settings/team/:TeamId', component: TeamParametersettingsListComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin']}
    }
    //Objectives
    ,
    {
        path: 'objectives/team/:TeamId', component: TeamObjectivesComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'Objective_Viewer', 'Objective_Editor']}
    }
    ,
    {
        path: 'objectives/organization', component: OrganizationObjectivesComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin','Objective_Editor']}
    },
    {
        path: 'objectives/stream/:StreamId', component: StreamObjectivesComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin','Objective_Editor']}
    },    
    {
        path: 'objectives/details/:ObjectiveId', component: ObjectiveDetailsComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin', 'Objective_Viewer', 'Objective_Editor']}
    },
    {
        path: 'objectives/new', component: NewObjectiveComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin','Objective_Editor']}
    },
    {
        path: 'objectives/:ObjectiveId/checkin', component: NewCheckinComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin','Objective_Editor']}
    },
    {
        path: 'objectives/guide', component: OkrGuideComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin','Objective_Editor']}
    },
    {
        path: 'objectives/mytasks', component: MyTasksComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin','Objective_Editor']}
    },
  
    {
        path: 'training/assigned', component: AssignedProgramsComponent,canActivate:[AuthGuard],data: {roles: ['Training_Mgr']}
    },
    {
        path: 'training/assigned/details/:AssignedProgramId', component: AssignedProgramDetailsComponent,canActivate:[AuthGuard],data: {roles: ['Training_Mgr']}
    },
    {
        path: 'training/assign', component: NewAssignedProgramComponent,canActivate:[AuthGuard],data: {roles: ['Training_Mgr']}
    },
    {
        path: 'training/assign/:ProgramId', component: NewAssignedProgramComponent,canActivate:[AuthGuard],data: {roles: ['Training_Mgr']}
    },
    {
        path: 'training/previewinfo', component: TrainingPreviewInfoComponent,canActivate:[AuthGuard],data: {roles: ['Training_Mgr']}
    },
    {
        path: 'training/teamplayerschedule', component: TeamPlayerScheduleComponent,canActivate:[AuthGuard],data: {roles: ['Training_Mgr']}
    },
    {
        path: 'training/playerschedule', component: PlayerScheduleComponent,canActivate:[AuthGuard],data: {roles: ['Training_Mgr']}
    },
    { path: 'configuration/training', loadChildren: () => import(`./configuration/training/training.module`).then(m => m.TrainingModule) },
    //Training
    
      {
        path: 'newequipment', component: NewEquipmentComponent
      },
    //Test routes
    {
        path: 'shared/uploadfiles', component: FileUploadComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin','Objective_Editor']}
    },
    {
        path: 'newworkout', component: NewWorkoutComponent
        
    },
    {
        path: 'editworkout/:WorkoutId', component: NewWorkoutComponent            
    },
    {
        path: 'newprogram', component: NewProgramComponent
        
    },
    {
        path: 'editprogram/:ProgramId', component: NewProgramComponent            
    },
   {   
        path: 'weightroom',
        loadChildren: () => import('./weightroom/weightroom.module').then(m => m.WeightroomModule)
    },
    {   
        path: 'team/:TeamId/formfeed/:FormId', component: TeamFormsFeedComponent, canActivate:[AuthGuard],data: {roles: ['Forms_Admin','Forms_Submitter', 'Forms_Viewer']},
       
    },


    //FORMS -------------------------------------
    {   
        path: 'formsubmission/new/:FormId', component: FormSubmissionComponent, canActivate:[AuthGuard],data: {roles: ['Forms_Admin','Forms_Submitter', 'Forms_Viewer']},
       
    },
    {   
        path: 'formsubmission/edit/:SubmissionId', component: FormSubmissionEditComponent, canActivate:[AuthGuard],data: {roles: ['Forms_Admin','Forms_Submitter', 'Forms_Viewer']},
       
    },
    {   
        path: 'formsubmission/view/table/:TeamId/:FormId', component: FormsTableComponent,canActivate:[AuthGuard],data: {roles: ['Forms_Admin','Forms_Submitter', 'Forms_Viewer']},
    },
       
    {   
        path: 'forms', component: OrgFormsComponent,canActivate:[AuthGuard],data: {roles: ['Forms_Admin','Forms_Submitter', 'Forms_Viewer']},
       
    },
    {   
        path: 'forms/edit/:FormId', component: EditFormComponent,canActivate:[AuthGuard],data: {roles: ['Forms_Admin']},
       
    },
    {   
        path: 'forms/settings/:FormId', component: FormSettingsComponent,canActivate:[AuthGuard],data: {roles: ['Forms_Admin']},
       
    },
    {   
        path: 'forms/guide', component: FormsHelpComponent,canActivate:[AuthGuard],data: {roles: ['Forms_Admin','Forms_Submitter', 'Forms_Viewer']},
       
    },

    //DASHBOARDS-------------------------------------
    {   
        path: 'dashboard/organization/teams', component: OrganizationTeamsDashboardComponent,canActivate:[AuthGuard],data: {roles: [ 'Test_Admin','Test_Viewer', 'Forms_Admin', 'Performance_Viewer']}, //TODO fix roles
       
    },
    {   
        path: 'forms/new', component: FormSettingsComponent,canActivate:[AuthGuard],data: {roles: ['Forms_Admin']},
       
    },
    
    
    {
        path: 'tree', component: OutletTestComponent,canActivate:[AuthGuard],data: {roles: ['Org_Admin']},
        children:[

            {
                path:'',
                redirectTo: 'test1',
                pathMatch: 'full' 
            },
            {
                path: 'test1', component: StreamObjectivesComponent,  canActivate:[AuthGuard],data: {roles: ['Org_Admin'], outlet: 'outlettest'}
            },    
            {
                path: 'test2', component: OrganizationObjectivesComponent, canActivate:[AuthGuard],data: {roles: ['Org_Admin']}
            }
        ]
    }    
    
    
    
    
];