import { Component, OnInit } from '@angular/core';
import { Season, TeamOverviewModel } from 'src/app/shared/team.model';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayerService } from 'src/app/shared/player.service';
import { TeamService } from 'src/app/shared/team.service';
import { UserService } from 'src/app/shared/user.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-performance-team-player-list',
  templateUrl: './performance-team-player-list.component.html',
  styleUrls: ['./performance-team-player-list.component.css']
})
export class PerformanceTeamPlayerListComponent implements OnInit {
  TeamId: string;
  ProfileId: string;
  _seasons: Season[];

  Players: PlayerViewModel[];
  seasonFormControl = new UntypedFormControl('');

  constructor(public _route: ActivatedRoute, 
    public _router: Router,
    public _plyrService: PlayerService,
    public _teamService: TeamService,
    public _userService: UserService,
    public _globals: Globals) 
  { 

    this._route.params.subscribe(params => {
        this.TeamId = params['TeamId'];
        console.log("TeamId")
    });
  }


  ngOnInit() {
    this._teamService.getSeasons().subscribe((s: Season[])=>{
      this._seasons = s; 
      this.seasonFormControl.setValue(this._seasons[this._seasons.length-1]);
      this.getPlayersInTeam(this._seasons[this._seasons.length-1].SeasonId);
   
    }); 
  }

  refreshData(){
    let tmp: Season = this.seasonFormControl.value;
    console.log("rehfresh season " + tmp.SeasonId);
    
    this.getPlayersInTeam(tmp.SeasonId);
  }

  getPlayersInTeam(seasonId: number){
/*     this._plyrService.getPlayersByTeam(this.TeamId).subscribe((p: TeamOverviewModel)=>{

      this.Players=p.Players;
      console.log(p);
    }); */

    this._plyrService.getPlayersByTeamAndSeason(this.TeamId, seasonId.toString()).subscribe((p: TeamOverviewModel)=>{
      this.Players=p.Players;
     
      //console.log(p);
    });
  }

}