<h1 mat-dialog-title style="font-family: Roboto;">NEW PASSWORD</h1>
<div mat-dialog-content >
    <div style="font-family: Roboto;">
        <label>{{this.data.UserName}}</label><br>
        <label>{{this.data.LastName}}</label>&nbsp;
        <label>{{this.data.FirstName}}</label>  
    </div>
    <form id="newPasswordForm">

        <mat-form-field >
            <input type="password" matInput placeholder="New password"   [formControl]="newPassword"  >                        
        </mat-form-field><br/>
    
        
        <div style="font-family: Roboto; color:gray;">
            Passwords must contain minimum 6 characters, contain lowercase letters, uppercase letters, number and special character (non digit non letter).
        </div>
  
    
    </form>

</div>
<div mat-dialog-actions>
   <button mat-raised-button color="primary"  (click)="save()"   cdkFocusInitial>SAVE</button>
   <button mat-button (click)="onNoClick()">CANCEL</button>
</div>