import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/sv';


import { Form, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PlayerService } from 'src/app/shared/player.service';
import { GameDetailsViewModel } from '../models/GameModels';
import { PlayerDetailsViewModel } from '../models/PlayerModels';
import { Report, ReportSkill, ReportText, ScoutingReportMasterData } from '../models/ReportModels';
import { GameService } from '../services/game.service';
import { ScoutingPlayerService } from '../services/scouting-player.service';
import { ScoutingService } from '../services/scouting.service';
import moment, { Moment } from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlayerPosition } from 'src/app/shared/models/player.model';



@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.css']
})
export class NewReportComponent implements OnInit {

  PlayerId: string;
  GameId: string;
  TeamId: string;
  ReportId: string;
  Game: GameDetailsViewModel;
  Player: PlayerDetailsViewModel;
  ReportMasterData: ScoutingReportMasterData;
  PlayerPositions: PlayerPosition[];
  reportForm: UntypedFormGroup;
  mylist = [0,1,2,3,4,5];
  gameperf_texts=['-','Bad', 'Average','Good', 'Very good', 'Excellent' ];
  gameperf_colors= ['','#ff2c1c','#e9c400','yellowgreen', 'limegreen', '#1e90ff'];

  constructor(
    private route: ActivatedRoute, 
    private _formBuilder: UntypedFormBuilder,
    private _adapter: DateAdapter<any>,
    private snackbar: MatSnackBar,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private location: Location,
    private _scoutingplayerService: ScoutingPlayerService,
    private _reportService: ScoutingService,
    private _gameService: GameService,
    private _playerService: PlayerService) { 
  }

  ngOnInit() {

    this._adapter.setLocale('sv');

    //This is for NEW report
    this.GameId = this.route.snapshot.paramMap.get('GameId');
    this.PlayerId = this.route.snapshot.paramMap.get('PlayerId');
    this.TeamId = this.route.snapshot.paramMap.get('TeamId');

    //This is for opening an old report
    this.ReportId = this.route.snapshot.paramMap.get('ReportId');

    //console.log(this.ReportId);

    if(this.ReportId){
        //Get report
        this._reportService.getReport(this.ReportId).subscribe(report=>{
          console.log(report);
        });

    }

    this.reportForm = this._formBuilder.group({
      PlayerId: this.PlayerId,
      GameId: this.GameId,
      Scout: '',  
      Capacity: '',  
      PlayerType: '',  
      GamePerformance: ['', [Validators.required]],   
      PositionId: '',
      Projection: ['', [Validators.required]],  
      Comment: ['', [Validators.required]],  
      Location: ['', [Validators.required]],        
      ScoutingSourceId: '',
      ReportDate: [moment(), [Validators.required]],  
      SkillGroups: this._formBuilder.array([
        
      ])
    });

    this._playerService.getPlayerPositionsByUserOrganization().subscribe(positions=>{
      this.PlayerPositions = positions;
    });


    this._playerService.getPlayerScouting(this.PlayerId).subscribe(plyr=>{
      this.Player = plyr as PlayerDetailsViewModel;
      this.reportForm.patchValue({'PositionId': this.Player.Player.PositionId});
      //Get valuelists for capacity, playertype, projection, skills
      this._scoutingplayerService.getReportMasterDataForPosition(plyr.Player.PositionId.toString()).subscribe(res=>{
        this.ReportMasterData = res;
        //Create skills groups
        var skillgroups = this.reportForm.get('SkillGroups') as UntypedFormArray;
        this.ReportMasterData.SkillsGroupsView.forEach(group => {
          //console.log(group);
          var skills = this._formBuilder.array([]);
          group.Skills.forEach(skill => {
            //console.log(skill);
            skills.push(this._formBuilder.group({
                SkillGroupId: group.SkillGroupId,
                SkillId: skill.SkillId,
                Value: 0
              })
            );
          });
            
          skillgroups.push(
            this._formBuilder.group({
              SkillGroupId: group.SkillGroupId,
              Skills: skills
            })
          );

        });
        //console.log(this.reportForm);
      });
    });
    //console.log("Getting game");
    this._gameService.getGameDetailsSimple(this.GameId).subscribe(data=>{
      this.Game = data as GameDetailsViewModel;
      //console.log(this.Game);
    });

    

  }

  onSubmit(){
    //console.log('submit');
    var newReport = new Report;
    newReport.ReportSkills = [];
    newReport.ReportTexts = [];

    newReport.GameId = this.GameId;
    newReport.PlayerId = this.PlayerId;
    newReport.Location = this.reportForm.get('Location').value;
    if(this.ReportMasterData.ScoutingSource.length>0){
      newReport.ScoutingSourceId = this.reportForm.get('ScoutingSourceId').value;
    }
    
    newReport.PositionId = this.reportForm.get('PositionId').value;
    newReport.ReportDate = (this.reportForm.get('ReportDate').value as Moment).toDate();

    var comments = new ReportText();
    comments.Text = this.reportForm.get('Comment').value;
    comments.TextTypeId = 1;
    newReport.ReportTexts.push(comments);

    var projSkill = new ReportSkill;    
    projSkill.Ranking = this.reportForm.get('Projection').value;
    projSkill.SkillGroupId = 0;
    projSkill.SkillId = 1;
    newReport.ReportSkills.push(projSkill);

    var gamePerformance = new ReportSkill;    
    gamePerformance.Ranking = this.reportForm.get('GamePerformance').value;
    gamePerformance.SkillGroupId = 0;
    gamePerformance.SkillId = 14;
    newReport.ReportSkills.push(gamePerformance);


    if(this.ReportMasterData.CapacityEnabled){
      var capacitySkill = new ReportSkill;    
      capacitySkill.Ranking = this.reportForm.get('Capacity').value;
      capacitySkill.SkillGroupId = 0;
      capacitySkill.SkillId = 24;
      newReport.ReportSkills.push(capacitySkill);
    }


    if(this.ReportMasterData.PlayerTypeEnabled){
      var playerType = new ReportSkill;    
      playerType.Ranking = this.reportForm.get('PlayerType').value;
      playerType.SkillGroupId = 0;
      playerType.SkillId = 25;
      newReport.ReportSkills.push(playerType);
    }
    


    var groups = this.reportForm.get('SkillGroups') as UntypedFormArray;
    groups.controls.forEach(group => {
      var skills = group.get('Skills') as UntypedFormArray;
      skills.controls.forEach(skill=>{
        var newSkill = new ReportSkill;
        newSkill.Ranking = skill.get('Value').value;
        newSkill.SkillGroupId = newSkill.SkillId = skill.get('SkillGroupId').value;
        newSkill.SkillId = skill.get('SkillId').value;
        newReport.ReportSkills.push(newSkill);        
      });
    });
    console.log(newReport);
    this._reportService.addReport(newReport).subscribe(result=>{
      if(result){
        this.openSnackBar("Report created", "CLOSE");
        this.location.back();
      }else{
        this.openSnackBar("Failed to create report", "CLOSE");
      }
        
    });
  }

  log(msg: string){
    console.log(msg);
  }

  openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action,{
      duration: 2000
    });
  }
  
  getText(value: number){
    if (value == 0) {
      return 'Not rated';
    }
    if (value == 1) {
      return 'Bad';
    }
    if (value == 2) {
      return 'Average';
    }
    if (value == 3) {
      return 'Good';
    }
    if (value == 4) {
      return 'Very good';
    }
    if (value == 5) {
      return 'Excellent';
    }


    return value;
  }

  getProjectionColor(value: number){
    var ProjColors = ['lightgray', '#ff2c1c','#e9c400','#e9c400','limegreen','limegreen','limegreen','limegreen','limegreen'];
    return ProjColors[Math.floor(value)];
  }

  getCapacityColor(value: number){
    var ProjColors = ['lightgray', '#ff2c1c','#e9c400','#e9c400','#e9c400','limegreen','limegreen','limegreen','limegreen','limegreen'];
    return ProjColors[Math.floor(value)]      
  }

  getCapacityText(value: number){
    
    return this.ReportMasterData.Capacity.filter(cap=>cap.Ranking==value)[0].Text;
  }

  getProjectionText(value: number){
    //console.log(value)          ;
    var proj = this.ReportMasterData.Projection.filter(cap=>cap.Ranking==value);
    //console.log(proj);
    if(proj.length>0)
      return proj[0].Text;
      else
        return '-';
  }


}
