<div class="app-page">
    <h2><mat-icon>calculated</mat-icon>Calculated parameters</h2>
    &nbsp;<a type="button" (click)="openCreateDialog()"  mat-raised-button color="primary">NEW </a>
    <table mat-table [dataSource]="dataSource" matSort style=" width: 100%;" >

        <ng-container matColumnDef="Abbreviation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Abbreviation </th>
            <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Abbreviation}} </td>
          <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
              Average<br/>
              Median
             </td> -->
          </ng-container>
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Name}} </td>
        <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
            Average<br/>
            Median
           </td> -->
        </ng-container>
       
        <ng-container matColumnDef="Formula">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Formula </th>
          <td mat-cell *matCellDef="let element"  ><div style="border-color: black;">{{ element.Formula }}</div> </td>
          <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">
       
    
          </td>
        </ng-container>
        <ng-container matColumnDef="ValueType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ValueType </th>
            <td mat-cell *matCellDef="let element"  ><div style="border-color: black;">{{ element.ValueType }}</div> </td>
            <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">
         
      
            </td>
          </ng-container>
          <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions </th>
            <td mat-cell *matCellDef="let element" style="width: 100px;"  >
                <button mat-icon-button (click)="confirmDelete(element)"> <mat-icon>delete</mat-icon></button>
                <button mat-icon-button (click)="openEditDialog(element)"> <mat-icon>edit</mat-icon></button>
            </td>
            <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">
         
      
            </td>
          </ng-container>

            
     
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 12px;;"></tr>
      <!--   <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" style="height: 24px;"></tr> -->
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons matSort ></mat-paginator>
   <!--  <mat-list dense>
        <div mat-subheader>Parameters</div>
        <mat-list-item *ngFor="let p of this.statisticsParameters">
          <mat-icon mat-list-icon>folder</mat-icon>
          <div mat-line>{{p.Abbreviation}}</div>
          <div mat-line> {{p.Description}} </div>
        </mat-list-item>
      </mat-list>    -->
</div>
