import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TestProfile_Update, Test } from 'src/app/shared/models/test.model';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, ValidatorFn } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TestService } from 'src/app/shared/test.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';




@Component({
  selector: 'app-profile-add',
  templateUrl: './profile-add.component.html',
  styleUrls: ['./profile-add.component.css']
})
export class ProfileAddComponent implements OnInit {

  public profileForm: UntypedFormGroup;
  public _profile: TestProfile_Update;
  public testitems: Test[];

  constructor(
    public testService: TestService,
    public location: Location,
    public _formBuilder: UntypedFormBuilder,
    public _router: Router,
    public _globals: Globals
    ) 
    {
     /*  this.profileForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(50)]],  
        tests: new FormArray([], minSelectedCheckboxes(1))
      }); */

      
  
     
    }

  ngOnInit() {
  
    this._globals.titleBarText = "New profile";

    this.profileForm = this._formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],  
      tests: this._formBuilder.array([
        
      ])
    });

    this.testService.getAllTests().subscribe((alltests: Test[]) => {
      
      this.testitems = alltests;
      
    }); 

    
  }

  


  save(){
    console.log(this.profileForm.value);
    this.testService.saveProfile(this.profileForm.value).subscribe(ret => {
      
      console.log(ret);
      this._router.navigate(['/profiles']);
      
    });
  }

  onCancel(){
    
    this.location.back();
  }

  onChange(event) {
    const tests = <UntypedFormArray>this.profileForm.get('tests') as UntypedFormArray;

    if(event.checked) {
      tests.push(new UntypedFormControl(event.source.value))
    } else {
      const i = tests.controls.findIndex(x => x.value === event.source.value);
      tests.removeAt(i);
    }
  }

  

}

function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: UntypedFormArray) => {
    const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
      .map(control => control.value)
      // total up the number of checked checkboxes
      .reduce((prev, next) => next ? prev + next : prev, 0);

    // if the total is not greater than the minimum, return the error message
    return totalSelected >= min ? null : { required: true };
  };

  return validator;
}