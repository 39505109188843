<div class="app-page" style="margin-left: 20px;">
  <div style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 15px;">
    <div style="display: flex; border-left:orange solid 4px; padding-left: 5px; margin-left: 10px; margin-bottom: 15px; margin-top: 15px; background: #424242; max-width: 450px; min-width: 360px; " *ngIf="this.GameEvent">
      <table>
          <tbody>
              <tr >
                  <td style="width: 50px;vertical-align:top;  font-size: 14px;font-family: Roboto;">
                      <span style="font-family: Roboto; font-size: 24px; vertical-align: text-top;">
                          {{ this.GameEvent.Game.GameDate | date:'dd' }}<br/>                       
                      </span>
                       {{ this.GameEvent.Game.GameDate  | date:'MMM'}}<br/>
                          {{ this.GameEvent.Game.GameDate  | date:'EEE'}} 
                  </td>
              </tr>
          </tbody>
      </table>
      <div  style="color: white; float:left; width: 37.5%; text-align: center"><img src="/assets/img{{ this.GameEvent.Game.HomeTeamSmallIcon}}" style="height: 50px; margin:auto;"/>
          <p style="margin: auto; font-weight: bold; font-size:small; font-family: Roboto;">{{ this.GameEvent.Game.TeamHomeName }}</p>
       </div>
      <div  style=" float:left; width: 25%;font-size: x-small; margin-top: 10px; font-weight: bold; white-space: nowrap; overflow-x: hidden; font-family: Roboto;">
          <i class="material-icons" style="font-size: 14px;">
              access_time
              </i> {{this.GameEvent.Game.GameTime | date:'HH:mm'}}<br/>
              <img _ngcontent-c0="" src="/assets/img/trophy-variant.png" style="height: 14px; opacity: 0.7;">
             <br/>
          <i class="material-icons" style="font-size: 14px;">
              place
              </i>
          {{this.GameEvent.Game.Venue}} </div>
      <div style="color: white; float:left; width: 37.5%;text-align: center "><img src="/assets/img{{ this.GameEvent.Game.AwayTeamSmallIcon}}" style="height: 50px; margin: auto;"/>
          <p style="margin: auto; font-weight: bold; font-size: small;font-family: Roboto;">{{ this.GameEvent.Game.TeamAwayName }}</p> 
      </div>  
    
      
      
    </div>
    <div style="margin-left: 30px; align-self: center;">
      <button mat-raised-button (click)="save()" color="primary">SAVE</button>
      <button mat-button (click)="this._location.back()">BACK</button>
    </div>
  </div>
  
    <mat-progress-spinner *ngIf="isBusy"
          class="example-margin"
          color="primary"
          mode="indeterminate"
    >
      </mat-progress-spinner>
      <table mat-table [dataSource]="dataSource"  >
         <!--  <ng-container matColumnDef="Name" sticky>
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Name}} </td>
         
          </ng-container> -->
                 
          
          <ng-container *ngFor="let parm of displayedColumns;let i = index" matColumnDef="{{parm}}">
            <th mat-header-cell *matHeaderCellDef style=" text-align: center;" [ngStyle]="{'background-color': this.displayedColumnsAbbreviationBgColor[i],'color': this.displayedColumnsAbbreviationTextColor[i]}" >{{displayedNames[i]}} </th>
            <td mat-cell *matCellDef="let element" style="font-size: 14px; text-align: center;"    [ngStyle]="{'background-color': this.displayedColumnsBgColor[i], 'color': 'black'}">
                <div *ngIf="i==0" >{{element[parm]}}</div>
                <mat-form-field class="small_input" *ngIf="i>0">               
                  <input matInput [(ngModel)]="element[parm]">
                </mat-form-field>
            </td>          
          </ng-container>
       
          <ng-container [matColumnDef]="'NameHeader'">
            <th mat-header-cell *matHeaderCellDef 
            style="text-align:Left;"
            >
          
            </th>
          </ng-container>
          
          <ng-container [matColumnDef]="'GroupHeader_' + G.GameStatisticsGroupId" *ngFor="let G of this._view.Groups; let i = index">
            <th mat-header-cell *matHeaderCellDef 
                style="text-align: center;"
                [attr.colspan]="G.Parameters.length "
                [ngStyle]="{'background-color': G.BgColorHeader, 'color': G.TextColorHeader}"              
                >
              {{G.Name}}
            </th>
          </ng-container>
         
     <!--      <tr mat-header-row *matHeaderRowDef="groupColumns; sticky: true"></tr>  -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 14px;"></tr>
  
        </table>
  </div>

  