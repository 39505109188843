import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';
import { BaseService } from './baseservice';
import { ResponseModel } from './models/ResponseModel';
import { TeamSettingModel } from './models/settings.models';

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseService {

  constructor(private http: HttpClient) {
    super();
   }

   

  getSettingsForTeam(TeamId: string) {
    
    return this.http.get(this.rootUrl + '/api/settings/team/' + TeamId);
  }
  
  getSettingsForOrg(OrgId: string) {
    
    return this.http.get(this.rootUrl + '/api/settings/organization/' + OrgId);
  }


  updateSettingsForTeam(TeamId: string, Settings: TeamSettingModel[]): Observable<ResponseModel>{
    return this.http.post<ResponseModel>(this.rootUrl + '/api/settings/team/' + TeamId, Settings, this.httpOptions);
  }
}
