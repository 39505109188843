<h2 mat-dialog-title>
  ADD PLAYER 
</h2>
<form class="example-form" [formGroup]='playersForm'>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Search"  formControlName='userInput' required>
        </mat-form-field>
        <button mat-button type="button" (click)="search()">SEARCH</button>
      </form>
<mat-list mat-dialog-content style="height: 350px; background-color: #303030 ">
  <mat-list-item *ngFor="let player of this.filteredPlayers" style="height: 40px;">
    <button mat-raised-button (click)="addPlayer(player.LifelineScoutingId)"> 
     <!--  <mat-icon mat-list-icon>add</mat-icon> -->
      ADD
   
     </button> 
    <h4 mat-line><b>{{player.LastName}}, {{player.FirstName}}</b> ({{this.positionNames[player.PositionId]}})</h4>
    <span mat-line>{{player.DateOfBirth | date:'yyyy-MM-dd'}}</span> 
   </mat-list-item>
  
  </mat-list>
<div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial >CLOSE</button>
  

</div>