<div class="app-page">
    <div class="loading-indicator" *ngIf="isLoading">

        <mat-progress-spinner
        class="example-margin"
        color="orange"
        mode="indeterminate"
        >
    </mat-progress-spinner>
    
      </div>
     <mat-toolbar style="height: 60px;"  >
   
   
     <mat-form-field style="font-size: 14px;" >
       <mat-label>Roster - Season</mat-label>
       <mat-select [formControl]="seasonFormControl"  (selectionChange)="refreshData()">
         <mat-option *ngFor="let season of _seasons"  [value]="season">
           {{season.Name}}
         </mat-option>
         
       </mat-select>
     </mat-form-field>
     &nbsp;
     </mat-toolbar>
    <div style="margin-left: 20px;;">
        <label >Display</label><br/>
        <mat-checkbox
        checked="true"
        (change)="this.PlayerRankingsIsVisible=!this.PlayerRankingsIsVisible;">
            Grades
        </mat-checkbox>
        &nbsp;
        <mat-checkbox
        checked="true"
        (change)="this.PlayerAssessmentsIsVisible=!this.PlayerAssessmentsIsVisible;">
            Player assessments
        </mat-checkbox>
        &nbsp;
        <mat-checkbox
        checked="false"
        (change)="this.DifferenceIsVisible=!this.DifferenceIsVisible;">
            Difference grade/player assessment
        </mat-checkbox>

    </div>
  <br/>
 <!-- 
    <mat-slide-toggle [checked]="this.PlayerRankingsIsVisible" color="primary" [(ngModel)]="PlayerRankingsIsVisible">PLAYER GRADES</mat-slide-toggle>
    <mat-slide-toggle [checked]="this.ScoutRankingsIsVisible" color="primary" [(ngModel)]="ScoutRankingsIsVisible" >GRADES</mat-slide-toggle> -->
    
    <div style="overflow-x:auto;margin-left: 9em; "> 
        
    <div *ngIf="this.perfData?.Players.length==0">
        NO PLAYERS ADDED FOR THIS SEASON
    </div>
    <div *ngIf="this.perfData?.Games.length==0" style="color: gray; margin-top: 20px;">
        NO GAMES ADDED FOR THIS SEASON / NO GAMES PLAYED YET
    </div>
    <table style=" font-size: small;" *ngIf="this.perfData?.Players.length>0 && this.perfData?.Games.length>0">
        <tr>
            <th>
            </th>

            <ng-container *ngFor="let game of this.perfData.Games">        
                    <th *ngIf="game.HomeTeamId == this.perfData.TeamId" style="height: 100px; align-content: center;vertical-align: bottom;"><div class="verticalText" style="width:30px; overflow: visible;">{{game.AwayTeamName}}<br><label style="font-size: x-small; color: gray;">{{game.GameDate | date:'yyyy-MM-dd'}}</label></div></th>                
                    <th *ngIf="game.HomeTeamId != this.perfData.TeamId" style="height: 100px; align-content: center;vertical-align: bottom;"><div class="verticalText" style="width: 30px; overflow: visible;">{{game.HomeTeamName}}<br><label style="font-size: x-small; color: gray;">{{game.GameDate | date:'yyyy-MM-dd'}}</label></div></th>            
            </ng-container>
        </tr>

        <tr style="background-color: darkgray;">    
            <th style="position: absolute ; left:0; top: auto; height: 20px;font-weight: bold;">GOAL TENDERS</th>            
            <td style="padding: 0; height: 15px;" [attr.colspan]="this.perfData.Games.length"></td>
        </tr>

        <ng-container *ngFor="let player of this.perfData.Players">
            <tr *ngIf="player.PositionId == 1" class="striped">
                <th style="position: absolute ; left:15px; top: auto; height: 20px;font-weight: normal;">
                    <a [routerLink]="['/player/details', player.PlayerId]" style="color: white; font-weight: bold; text-decoration: none;">
                        {{player.JerseyNo}} {{player.LastName + "," + player.FirstName}}
                    </a>
                    
                </th>               
                <td *ngFor="let game of player.Games" style="padding: 0; height: 20px;">    
                                              
                        <div *ngIf="game.Grades.length>0"   class="playerrating">                                                                                                              
                            <ng-container *ngFor="let rank of game.Grades">                        
                                    <div *ngIf="rank.Ranking==1" class=" rankingbox-red " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">1</div>                            
                                    <div *ngIf="rank.Ranking==2" class=" rankingbox-yellow "  [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">2</div>                            
                                    <div *ngIf="rank.Ranking==3" class="rankingbox-lightgreen " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">3</div>                            
                                    <div *ngIf="rank.Ranking==4" class="rankingbox-green " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">4</div>                            
                                    <div *ngIf="rank.Ranking==5" class="rankingbox-blue " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">5</div>
                            </ng-container>
                        </div>
                    
                        <div *ngIf="game.PlayerAssessment!=null"   class="playerassessment">                                                                                                              
                        
                                    <div *ngIf="game.PlayerAssessment.Ranking==1" class=" playerrankingbox-red " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">1</div>                            
                                    <div *ngIf="game.PlayerAssessment.Ranking==2" class=" playerrankingbox-yellow "  [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">2</div>                            
                                    <div *ngIf="game.PlayerAssessment.Ranking==3" class="playerrankingbox-lightgreen " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">3</div>                            
                                    <div *ngIf="game.PlayerAssessment.Ranking==4" class="playerrankingbox-green " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">4</div>                            
                                    <div *ngIf="game.PlayerAssessment.Ranking==5" class="playerrankingbox-blue " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">5</div>
                        
                        </div>
                        <div *ngIf="game.PlayerGradeVsAssessmentDifference<-0.5" class="diffbox-red" [style.display]="this.DifferenceIsVisible ? 'block':'none'" >   
                            {{ game.PlayerGradeVsAssessmentDifference }}                                                 
                        </div>
                        <div *ngIf="game.PlayerGradeVsAssessmentDifference > 0.5" class="diffbox-green"  [style.display]="this.DifferenceIsVisible ? 'block':'none'">   
                            {{ game.PlayerGradeVsAssessmentDifference }}                                                 
                        </div>
                        <div *ngIf="game.PlayerGradeVsAssessmentDifference>=-0.5 && game.PlayerGradeVsAssessmentDifference<=0.5 && game.PlayerGradeVsAssessmentDifference!=null "  [style.display]="this.DifferenceIsVisible ? 'block':'none'" class="diffbox-gray" >   
                            {{ game.PlayerGradeVsAssessmentDifference }}                                                 
                        </div>
                    
                    </td>                
            </tr>
        </ng-container>

        <tr style="background-color: darkgray;">    
            <th style="position: absolute ; left:0; top: auto; height: 20px;font-weight: bold;">DEFENDERS</th>
            
            <td style="padding: 0; height: 15px;"  [attr.colspan]="this.perfData.Games.length"></td>
        </tr>

        <ng-container *ngFor="let player of this.perfData.Players">
            <tr *ngIf="player.PositionId == 2 || player.PositionId == 3" class="striped">
                <th style="position: absolute ; left:15px; top: auto; height: 20px;font-weight: normal;">
                    <a [routerLink]="['/player/details', player.PlayerId]" style="color: white; font-weight: bold; text-decoration: none;">
                        {{player.JerseyNo}} {{player.LastName + "," + player.FirstName}}
                    </a>
                </th>               
                <td *ngFor="let game of player.Games" style="padding: 0; height: 20px;">    
                                              
                        <div *ngIf="game.Grades.length>0"   class="playerrating">                                                                                                              
                            <ng-container *ngFor="let rank of game.Grades">                        
                                    <div *ngIf="rank.Ranking==1" class=" rankingbox-red " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">1</div>                            
                                    <div *ngIf="rank.Ranking==2" class=" rankingbox-yellow "  [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">2</div>                            
                                    <div *ngIf="rank.Ranking==3" class="rankingbox-lightgreen " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">3</div>                            
                                    <div *ngIf="rank.Ranking==4" class="rankingbox-green " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">4</div>                            
                                    <div *ngIf="rank.Ranking==5" class="rankingbox-blue " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">5</div>
                            </ng-container>
                        </div>
                        <div *ngIf="game.PlayerAssessment!=null"   class="playerassessment">                                                                                                              
                           
                                    <div *ngIf="game.PlayerAssessment.Ranking==1" class=" playerrankingbox-red " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">1</div>                            
                                    <div *ngIf="game.PlayerAssessment.Ranking==2" class=" playerrankingbox-yellow "  [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">2</div>                            
                                    <div *ngIf="game.PlayerAssessment.Ranking==3" class="playerrankingbox-lightgreen " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">3</div>                            
                                    <div *ngIf="game.PlayerAssessment.Ranking==4" class="playerrankingbox-green " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">4</div>                            
                                    <div *ngIf="game.PlayerAssessment.Ranking==5" class="playerrankingbox-blue " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">5</div>
                           
                        </div>
                        <div *ngIf="game.PlayerGradeVsAssessmentDifference<-0.5" class="diffbox-red"  [style.display]="this.DifferenceIsVisible ? 'block':'none'">   
                            {{ game.PlayerGradeVsAssessmentDifference }}                                                 
                        </div>
                        <div *ngIf="game.PlayerGradeVsAssessmentDifference > 0.5" class="diffbox-green"  [style.display]="this.DifferenceIsVisible ? 'block':'none'">   
                            {{ game.PlayerGradeVsAssessmentDifference }}                                                 
                        </div>
                        <div *ngIf="game.PlayerGradeVsAssessmentDifference>=-0.5 && game.PlayerGradeVsAssessmentDifference<=0.5 && game.PlayerGradeVsAssessmentDifference!=null " class="diffbox-gray"  [style.display]="this.DifferenceIsVisible ? 'block':'none'">   
                            {{ game.PlayerGradeVsAssessmentDifference }}                                                 
                        </div>

                           
                    </td>    
            </tr>
        </ng-container>

    <tr style="background-color: darkgray;">
        <th style="position: absolute ; left:0; top: auto; height: 20px;font-weight: bold;">FORWARDS</th>
        
        <td style="padding: 0; height: 15px; "  [attr.colspan]="this.perfData.Games.length"></td>
    </tr>

    <ng-container *ngFor="let player of this.perfData.Players">
        <tr *ngIf="player.PositionId > 3" class="striped">
            
            <th style="position: absolute ; left:15px; top: auto; height: 20px;font-weight: normal;">
                <a [routerLink]="['/player/details', player.PlayerId]" style="color: white; font-weight: bold; text-decoration: none;">
                {{player.JerseyNo}} {{player.LastName + "," + player.FirstName}}
                </a>
            </th>               

            <td *ngFor="let game of player.Games" style="padding: 0; height: 20px;">    
                                              
                    <div *ngIf="game.Grades.length>0"   class="playerrating">                                                                                                              
                        <ng-container *ngFor="let rank of game.Grades">                        
                                <div *ngIf="rank.Ranking==1" class=" rankingbox-red " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">1</div>                            
                                <div *ngIf="rank.Ranking==2" class=" rankingbox-yellow "  [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">2</div>                            
                                <div *ngIf="rank.Ranking==3" class="rankingbox-lightgreen " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">3</div>                            
                                <div *ngIf="rank.Ranking==4" class="rankingbox-green " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">4</div>                            
                                <div *ngIf="rank.Ranking==5" class="rankingbox-blue " [style.display]="this.PlayerRankingsIsVisible ? 'block':'none'">5</div>
                        </ng-container>
                    </div>
                
                    <div *ngIf="game.PlayerAssessment!=null"   class="playerassessment">                                                                                                              
                    
                                <div *ngIf="game.PlayerAssessment.Ranking==1" class=" playerrankingbox-red " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">1</div>                            
                                <div *ngIf="game.PlayerAssessment.Ranking==2" class=" playerrankingbox-yellow "  [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">2</div>                            
                                <div *ngIf="game.PlayerAssessment.Ranking==3" class="playerrankingbox-lightgreen " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">3</div>                            
                                <div *ngIf="game.PlayerAssessment.Ranking==4" class="playerrankingbox-green " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">4</div>                            
                                <div *ngIf="game.PlayerAssessment.Ranking==5" class="playerrankingbox-blue " [style.display]="this.PlayerAssessmentsIsVisible ? 'block':'none'">5</div>
                    
                    </div>
                    <div *ngIf="game.PlayerGradeVsAssessmentDifference<-0.5" class="diffbox-red" [style.display]="this.DifferenceIsVisible ? 'block':'none'" >   
                        {{ game.PlayerGradeVsAssessmentDifference }}                                                 
                    </div>
                    <div *ngIf="game.PlayerGradeVsAssessmentDifference > 0.5" class="diffbox-green"  [style.display]="this.DifferenceIsVisible ? 'block':'none'">   
                        {{ game.PlayerGradeVsAssessmentDifference }}                                                 
                    </div>
                    <div *ngIf="game.PlayerGradeVsAssessmentDifference>=-0.5 && game.PlayerGradeVsAssessmentDifference<=0.5 && game.PlayerGradeVsAssessmentDifference!=null " class="diffbox-gray"  [style.display]="this.DifferenceIsVisible ? 'block':'none'">   
                        {{ game.PlayerGradeVsAssessmentDifference }}                                                 
                    </div>
                       
                </td>      
        </tr>
    </ng-container>

    </table>
</div>

</div>