import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/shared/user.service';
import { KeyResult, KeyResultValue } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';
import { NewKeyresultDialogComponent } from '../new-keyresult-dialog/new-keyresult-dialog.component';

@Component({
  selector: 'app-update-key-result-dialog',
  templateUrl: './update-key-result-dialog.component.html',
  styleUrls: ['./update-key-result-dialog.component.css']
})
export class UpdateKeyResultDialogComponent implements OnInit {
  keyresultForm;
  keyResult: KeyResult;

  constructor(   
    private fb: UntypedFormBuilder,
    private objectiveService: ObjectivesService,
    private userService: UserService,
    public dialogRef: MatDialogRef<NewKeyresultDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {KeyResult: KeyResult}) { }

  ngOnInit(): void {
    this.keyResult = this.data.KeyResult;

    this.keyresultForm = this.fb.group({
      ObjectiveId: [this.data.KeyResult.ObjectiveId],
      KeyResultId: [this.data.KeyResult.KeyResultId],  
      ValueInteger: ['',Validators.required],      
      PreviousValueInteger: [this.data.KeyResult.CurrentInteger,Validators.required],
    });
  }

  onSubmit(){
      // TODO: Use EventEmitter with form value
    
      this.objectiveService.updateKeyResultValue(this.keyresultForm.value).subscribe(res=>{
        this.dialogRef.close();
      });
  }

}
