import { Component, OnInit, Input, Inject } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { TestProfile } from 'src/app/shared/models/test.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TestService } from 'src/app/shared/test.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.css']
})
export class ProfileCardComponent implements OnInit {

  @Input() Profile: TestProfile;
  @Output() deleted = new EventEmitter();

  constructor(public dialog: MatDialog,
    public _testService: TestService) { }

  ngOnInit() {
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete the profile?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //console.log(this.Profile);
        this._testService.deleteProfile(this.Profile.ProfileId).subscribe(result => {
          this.deleted.emit();
        });
        //Notify about delete
        
      }
    });
  }

  deleteProfile(profileId: string): void{  
      
    this.openDialog();
    
  }

  

}
