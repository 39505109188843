<div class="app-page" style="margin-left: 20px;">
  <h3>Import csv file</h3>
    <div style="display: flex; border-left:orange solid 4px; padding-left: 5px; margin-left: 10px; margin-bottom: 15px; margin-top: 15px; background: #424242; max-width: 450px; min-width: 360px; " *ngIf="this.GameEvent">
        <table>
            <tbody>
                <tr >
                    <td style="width: 50px;vertical-align:top;  font-size: 14px;font-family: Roboto;">
                        <span style="font-family: Roboto; font-size: 24px; vertical-align: text-top;">
                            {{ this.GameEvent.Game.GameDate | date:'dd' }}<br/>                       
                        </span>
                         {{ this.GameEvent.Game.GameDate  | date:'MMM'}}<br/>
                            {{ this.GameEvent.Game.GameDate  | date:'EEE'}} 
                    </td>
                </tr>
            </tbody>
        </table>
        <div  style="color: white; float:left; width: 37.5%; text-align: center"><img src="/assets/img{{ this.GameEvent.Game.HomeTeamSmallIcon}}" style="height: 50px; margin:auto;"/>
            <p style="margin: auto; font-weight: bold; font-size:small; font-family: Roboto;">{{ this.GameEvent.Game.TeamHomeName }}</p>
         </div>
        <div  style=" float:left; width: 25%;font-size: x-small; margin-top: 10px; font-weight: bold; white-space: nowrap; overflow-x: hidden; font-family: Roboto;">
            <i class="material-icons" style="font-size: 14px;">
                access_time
                </i> {{this.GameEvent.Game.GameTime | date:'HH:mm'}}<br/>
                <img _ngcontent-c0="" src="/assets/img/trophy-variant.png" style="height: 14px; opacity: 0.7;">
               <br/>
            <i class="material-icons" style="font-size: 14px;">
                place
                </i>
            {{this.GameEvent.Game.Venue}} </div>
        <div style="color: white; float:left; width: 37.5%;text-align: center "><img src="/assets/img{{ this.GameEvent.Game.AwayTeamSmallIcon}}" style="height: 50px; margin: auto;"/>
            <p style="margin: auto; font-weight: bold; font-size: small;font-family: Roboto;">{{ this.GameEvent.Game.TeamAwayName }}</p> 
        </div>  
      
        
        
      </div>
    
        <input 
        style="display: none" 
        type="file" #csvReader name="Upload CSV" id="txtFileUpload" (change)="uploadListener($event)" accept=".csv"
        #fileInput name ="file"  required>
    <button color="primary" mat-raised-button (click)="fileInput.click()" >SELECT FILE</button>
    
<form  [formGroup]="mappingForm">
    

<table *ngIf="this.headersRow">
    <tr>   
       
        <th class="header" *ngFor="let header of this.headersRow; let i = index">{{header}}</th>    
    </tr>
 
    <tr >   
        <ng-container formArrayName="fieldArray">
            <td  *ngFor="let fields of headersRow; let i = index"  [formGroupName]="i" >
            <mat-form-field>
                <mat-select formControlName="Action" style="text-align: center;" >
                    <mat-option value="-SKIP-">-</mat-option>
                    <mat-option value="-PlayerFullName-">[Player full name]</mat-option>
                    <mat-option value="-PlayerFirstName-">[Player first name]</mat-option>
                    <mat-option value="-PlayerLastName-">[Player last name]</mat-option>
                    <mat-option [value]="param.Abbreviation" *ngFor="let param of this.availableParameters">{{param.Abbreviation}}</mat-option>
                    
                </mat-select>
            </mat-form-field>
            </td>       
        </ng-container>
    </tr>    
    <tr *ngFor="let row of this.rows; let rowindex = index">
       
        <td class="value" *ngFor="let header of this.headersRow; let col = index" >{{row[col]}}</td>
    </tr>
</table>

<button mat-raised-button (click)="verify()" color="primary">SAVE </button>

<div style="white-space: pre-line">
    {{this.errorMsg}}
</div>
</form>
</div>
