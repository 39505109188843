import { Component, OnInit,signal,Signal,ViewChild } from '@angular/core';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepickerIntl, MatDatepickerModule} from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { FlowItemModel } from '../../models/FlowItemModel';
import { ScoutingFlowService } from '../../services/scouting-flow.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ScoutingPlayerService } from '../../services/scouting-player.service';
import { CommonModule } from '@angular/common';
import { TestResultKPIViewModel } from 'src/app/shared/models/test.model';
import { NoteCardComponent } from '../../components/note-card/note-card.component';
import { ReportCardComponent } from '../../components/report-card/report-card.component';
import { ReportSearchResultOverviewComponent } from "../../components/report-search-result-overview/report-search-result-overview.component";
import { ScoutingSearchResultOverview } from '../../models/ScoutingSearchResultOverview';



@Component({
  selector: 'app-filtered-scouting-flow',
  standalone: true, 
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    // Moment can be provided globally to your app by adding `provideMomentDateAdapter`
    // to your app config. We provide it at the component level here, due to limitations
    // of our example generation script.
    provideMomentDateAdapter()
  ],
  templateUrl: './filtered-scouting-flow.component.html',
  styleUrl: './filtered-scouting-flow.component.css',
  imports: [SharedModuleModule, CommonModule, FormsModule, ReactiveFormsModule, ReportCardComponent, NoteCardComponent, MatDatepickerModule, ReportSearchResultOverviewComponent],
})




export class FilteredScoutingFlowComponent implements OnInit {
  _flowitems: FlowItemModel[];
  public isBusy = signal(true);
  reportMasterdata: any;
  availableBirthYears: number[];
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  searchForm = new UntypedFormGroup({
    PlayerFirstName: new UntypedFormControl(''),
    PlayerLastName: new UntypedFormControl(''),
    PositionIds: new UntypedFormControl(''),
    YearsOfBirth: new UntypedFormControl(''),
    ShootIds: new UntypedFormControl(''),
    Projections: new UntypedFormControl(''),
    PlayerTypes: new UntypedFormControl(''),
    Capacities: new UntypedFormControl(''),
    MaxHits: new UntypedFormControl('100'),
    minReportDate:new UntypedFormControl(''),
    maxReportDate:new UntypedFormControl(''), 
    ScoutingSources: new UntypedFormControl('')
  });
  SearchResultOverview: ScoutingSearchResultOverview;
  

  constructor(public flowService: ScoutingFlowService,public scoutingRep: ScoutingPlayerService, public matDialog: MatDialog) {

    scoutingRep.getReportSearchMasterDataForOrg().subscribe(data =>{
      //console.log(data);
      this.reportMasterdata = data;
    });
   }

  ngOnInit() {

    this.isBusy.set(true);
    //Set the search from date
    var d: Date = new Date();    
    d.setMonth(d.getMonth() - 3);
    d.setHours(0, 0, 0, 0);
   
    this.searchForm.controls['minReportDate'].setValue(d);

    var earliestYear = new Date().getFullYear() - 13;

    this.availableBirthYears =
    Array.from(
      { length: 45 },
      (value, index) => earliestYear - index 
      );
      //console.log("availableBirthYears");
      //console.log(this.availableBirthYears);

    this.scoutingRep.getScoutingSearchResultOverview(this.searchForm.value).subscribe(overview =>{
      this.SearchResultOverview = overview;
    });
    
    this.scoutingRep.getFlow(this.searchForm.value).subscribe(r=> {

      this._flowitems = r;
      this.isBusy.set(false);
    });
  }

  loadFlow(){
    this.isBusy.set(true);

    try {
      //Clear result

      var p = this.searchForm.value;

      //console.log(p);
   
      this._flowitems = null;

      this.scoutingRep.getScoutingSearchResultOverview(this.searchForm.value).subscribe(overview =>{
        this.SearchResultOverview = overview;
      });

      this.scoutingRep.getFlowReports(this.searchForm.value).subscribe( result=>{
          //console.log("loadFlow");
          
          this._flowitems = result;
          this.isBusy.set(false);
          
      }); 
    } catch (error) {
      this.isBusy.set(false);
    }
    finally{
      //this.isBusy.set(false);
    }

  }

  
}