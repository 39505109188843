<div style="margin: 5px;   font-family:Roboto;"  >
  <!--   <div style="font-family: Roboto; color: darkgray;">
        {{this.Game.DivisionName}}
    </div> -->
    <div style="display: flex;"  >    
        <div style="display: block; width: 100%">
        
                       <table>
                           <tr>
                               <td><mat-icon color="primary">sports_hockey</mat-icon></td>
                              
                               <td><span>{{this.Game.TeamHomeName}}</span></td>
                               <td>-</td>                  
                              
                               <td><span>{{this.Game.TeamAwayName}}</span></td>
                           </tr>
                           <tr>
                               <td></td>
                               <td colspan="3">
                                <span style="font-size: small; font-family: Roboto; color: darkgray;">
           
                                    {{this.Game.GameDate | date: 'dd'}}
                                    {{this.Game.GameDate  | date: 'LLL'}}
                                    <!-- {{this.Game.GameTime | date: 'HH:mm'}} -->
                                    {{this.Game.Venue}}           
                                    </span>
                               </td>
                           </tr>
                         <!--   <tr>                                                              
                            {{this.Game.GameDate | date: 'dd'}}
                            {{this.Game.GameDate  | date: 'LLL'}}
                            <!-- {{this.Game.GameTime | date: 'HH:mm'}}
                            {{this.Game.Venue}}
                           </tr> -->
                       </table>                                                                                  
                                                
                  
                 <!--    <td>
                        
                    </td>
                    <td style="width: 33%;font-size: small; font-family: Roboto; text-align: center; color: darkgray;">
                        {{this.Game.GameDate | date: 'dd'}}
                        {{this.Game.GameDate  | date: 'LLL'}}
          
                        {{this.Game.Venue}}
                    </td>
                    <td style="text-align: center;width: 33%;">
                        <img style="height: 30px;" [src]="'../../assets/img/' + this.Game.AwayTeamSmallIcon">
                        <span>{{this.Game.TeamAwayName}}</span>
                    </td> -->

                    
          
       
            <div></div>
        </div>
        <div></div>
        <div></div>
        <div></div>
    </div>

</div>