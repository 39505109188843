import { PlayerPosition } from "src/app/shared/models/player.model";

export class GameStatisticsParameterViewModel
{
    OrganizationId: number;
    SportId: number;
    StatisticsId: number;
    Name: string;
    Description: string;
    Abbreviation: string;
    ValueType: string;
    Operator: string;
    
}

export class GameStatisticsGroupViewModel{

    GameStatisticsGroupId: string;
    Name: string;
    DisplayName: string;
    BgColor: string;
    BgColorHeader: string;
    TextColorValue: string;
    TextColorHeader: string;
    Parameters: GameStatisticsParameterViewModel[];
    ParametersCalculated: GameStatisticsParameterCalculatedViewModel[];
    ParametersNotSelected: GameStatisticsParameterViewModel[];
    ParametersCalculatedNotSelected: GameStatisticsParameterCalculatedViewModel[];
}

export class GameStatisticsViewViewModel{
    GameStatisticsViewId: string;
    Name: string;
    DisplayName: string;
    Groups: GameStatisticsGroupViewModel[];
    GroupsNotSelected: GameStatisticsGroupViewModel[];
    Positions: PlayerPosition[];
}

export class GameStatisticsParameterCalculatedViewModel
{
    OrganizationId: number;   
    Name: string;
    Description: string;
    Abbreviation: string;
    ValueType: string;
    Formula: string;
    Operator: string;

}

export class GameStatisticsProfileViewModel{
    OrganizationId: string;
    GameStatisticsProfileId: string;
    Name: string;
    Limits: GameStatisticsProfileLimitViewModel[];
    LimitsCalculated: GameStatisticsProfileLimitViewModel[];
}

export class GameStatisticsProfileLimitViewModel
{
    OrganizationId: number;
    GameStatisticsProfileId: string;
    GameStatisticsId: string;
    Name: string;
    Abbreviation: string;
    LimitOK: string;
    LimitGood: string;
    LimitVeryGood: string;
    LimitExcellent: string;
    ResultUnit: string;
    ResultType: number;
    ValidationLimit: string;
    ValidationResult: string;    
    LimitMultiplierTestName: string;
    Operator: string;
    MaxPointsCalculated: number;
    IncludeInTotalRanking: boolean;
    OrderInProfile: number;
    HideInOverview: boolean;
}

