<h2 mat-dialog-title>
  SELECT DIVISIONS
  <button mat-icon-button class="close-button" (click)="onClose()" tabindex="-1">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-checkbox formControlName="selectAll" [checked]="hideOldDivisions" (change)="onCheckboxHideOldDivisionsChange($event)">Hide past series</mat-checkbox>
    <div formArrayName="divisions">
      <br />
      <mat-accordion>
        <ng-container *ngFor="let country of getUniqueCountries()">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="title-container">
                  <span>{{country}}</span>
                  <span class="badge-container">
                    <span matBadge="{{getDivisionCount(country)}}" matBadgeOverlap="false" matBadgeSize="medium" matBadgeColor="accent"></span>
                    <span *ngIf="getSelectedDivisionCount(country) > 0" matBadge="{{getSelectedDivisionCount(country)}}" matBadgeOverlap="false" matBadgeSize="medium" matBadgeColor="primary"></span>
                  </span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="form">
              <div formArrayName="divisions">
                <ng-container *ngFor="let division of divisionsData; let j = index">
                  <ng-container *ngIf="division.CountryName == country">
                    <div class="division-row">
                      <mat-checkbox [formControlName]="j" (change)="onCheckboxChange()">
                        {{division.Name}}&nbsp;&nbsp;({{division.GameCount}})
                      </mat-checkbox>
                      <div class="date-container">
                        <span class="date">{{division.GamesStartDate | date:'yyyy-MM-dd'}}</span> - 
                        <span class="date">{{division.GamesEndDate | date:'yyyy-MM-dd'}}</span>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </form>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
  </form>
</div>