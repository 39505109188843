<!-- <h3 mat-dialog-title style=" padding-bottom: 10px;">Team event</h3> -->
<div mat-dialog- style="padding: 12px;;">
        <form  style="color: white;" [formGroup]="addEventForm"  (ngSubmit)="onSubmit()" autocomplete="off" >
           <!--  <mat-tab-group [dynamicHeight]="true" animationDuration="0ms" selectedIndex="0">
                <mat-tab label="EVENT"> -->
                    <mat-form-field  color="accent" style="width: 100%; padding-top: 10px; font-size: small;" appearance="fill">
                        <mat-label style="color: white">Title</mat-label>
                        <input matInput placeholder="Enter title" name="Title" required color="accent" formControlName="Title">
                    </mat-form-field><br/>
                   
                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                        <mat-form-field color="accent" appearance="fill"  style="font-size: small;">
                            <mat-label style="color: white">Date</mat-label>
                            <input matInput [matDatepicker]="picker"  color="accent"  name="EventDate" required  formControlName="EventDate">
                            <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field> &nbsp;
                        <mat-form-field color="accent" appearance="fill"  style="font-size: small;">
                            <mat-label style="color: white">Time</mat-label>
                                <input matInput type="time" placeholder="Time" color="accent" name="StartTime" required color="accent" formControlName="StartTime">
                        </mat-form-field> &nbsp;
                       <!--  <div>
                            <mat-label style="color: white; font-family: Roboto; font-size: small;">Duration *</mat-label>
                            <br/>
                            <mat-slider thumbLabel="true"  min="0" max="120" step="5" tickInterval="30" [(ngModel)]="log.Duration"  name="Duration" style="width: 180px;" color="accent" formControlName="Duration"  ></mat-slider>
                            <span class="example-selected-value" style="white-space: nowrap;">{{addEventForm.value.Duration}} min</span>    
                        </div> -->
                        <mat-form-field appearance="fill" style="width: 150px; font-size: small;">
                            <mat-label>Duration</mat-label>
                            <mat-select formControlName="Duration">
                              <mat-option [value]="0">-</mat-option>
                              <mat-option [value]="15">0:15 h</mat-option>
                              <mat-option [value]="20">0:20 h</mat-option>
                              <mat-option [value]="25">0:25 h</mat-option>
                              <mat-option [value]="30">0:30 h</mat-option>
                              <mat-option [value]="35">0:35 h</mat-option>
                              <mat-option [value]="40">0:40 h</mat-option>
                              <mat-option [value]="45">0:45 h</mat-option>
                              <mat-option [value]="50">0:50 h</mat-option>
                              <mat-option [value]="55">0:55 h</mat-option>
                              <mat-option [value]="60">1:00 h</mat-option>
                              <mat-option [value]="75">1:15 h</mat-option>
                              <mat-option [value]="90">1:30 h</mat-option>
                              <mat-option [value]="105">1:45 h</mat-option>
                              <mat-option [value]="120">2:00 h</mat-option>
                              <mat-option [value]="150">2:30 h</mat-option>
                              <mat-option [value]="180">3:00 h</mat-option>
                              <mat-option [value]="210">3:30 h</mat-option>
                              <mat-option [value]="240">4:00 h</mat-option>
                              <mat-option [value]="270">4:30 h</mat-option>
                              <mat-option [value]="300">5:00 h</mat-option>
    
                            </mat-select>
                          </mat-form-field>
                    </div>
                    <mat-checkbox class="example-margin" formControlName="FullTeamEvent" #FullTeamEvent style="height: 30px; font-size: small;">All players in team roster</mat-checkbox>
                 
                    <div style="display: flex; flex-direction: row;" *ngIf="!FullTeamEvent.checked && players ">
                      <mat-form-field style="width: 100%; font-size: small; " appearance="fill" formArrayName="Attendees" >
                        <mat-label>Attendees</mat-label>
                        <mat-chip-grid #chipList aria-label="Attendees" style="max-height: 100px;">
                          <mat-chip-row
                          style="font-size: x-small;"
                          *ngFor="let attendee of this.attendees?.controls; let i= index;"
                          [formGroupName]="i"
                          (removed)="remove(attendee.get('PlayerId').value)"
                          >
                          <mat-icon>person</mat-icon>
                          {{attendee.get('LastName').value}}, {{attendee.get('FirstName').value}}
                            <button matChipRemove >
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </mat-chip-row>
                          <input
                          placeholder="Add attendee..."
                          #playerInput
                          [formControl]="playerCtrl"
                          [matAutocomplete]="auto"
                          [matChipInputFor]="chipList"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                          (matChipInputTokenEnd)="add($event)"
                        >
                        </mat-chip-grid>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                          <mat-option *ngFor="let player of players" [value]="player">
                            {{player.LastName}},{{player.FirstName}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                   
                    </div>
                    
                    <mat-form-field  color="accent" appearance="fill" style="width: 450px; font-size: small;">
                        <mat-label style="color: white">Location</mat-label>
                    <input matInput   name="Location" required color="accent" formControlName="Location"/>
                    </mat-form-field>&nbsp;

                    <mat-form-field appearance="fill" style="width: 150px; font-size: small;" >
                        <mat-label>Intensity</mat-label>
                        <mat-select formControlName="LoadLevel" id="LoadLevel" >
                          <mat-option value="0">NOT SET</mat-option>
          
                          <mat-option [value]="0">NOT SET</mat-option>
                          <mat-option [value]="1">1 - REST </mat-option>
                          <mat-option [value]="2">2 - VERY EASY </mat-option>
                          <mat-option [value]="3">3 - EASY </mat-option>
                          <mat-option [value]="4">4 - MODERATE LIGHT </mat-option>
                          <mat-option [value]="5">5 - MODERATE </mat-option>
                          <mat-option [value]="6">6 - MODERATE HEAVY </mat-option>
                          <mat-option [value]="7">7 - HARD </mat-option>                          
                          <mat-option [value]="8">8 - VERY HARD </mat-option>
                          <mat-option [value]="9">9 - EXTREMELY HARD </mat-option>
                          <mat-option [value]="10">10 - MAXIMAL EFFORT</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field style="width: 100%; font-size: small; "  color="accent" appearance="fill">
                        <mat-label style="color: white">Notes</mat-label>
                        <br/>
                        <textarea  matInput #notes rows="10" name="Note" maxlength="200" color="accent" style="width: 100%;" formControlName="Note"></textarea>
                        <mat-hint align="end">{{notes.value.length}} / 200</mat-hint>
                    </mat-form-field>     
                     <mat-form-field  color="accent" appearance="fill" style="width: 200px; font-size: small;" disabled>
                        <mat-label style="color: white">Created by</mat-label>
                        <input matInput   name="CreatedByName"  color="accent" formControlName="CreatedByName" disabled/>
                    </mat-form-field>&nbsp;
                    <mat-form-field  color="accent" appearance="fill" style="width: 200px; font-size: small;" disabled>
                      <mat-label style="color: white">Latest update by</mat-label>
                      <input matInput   name="CreatedByName"  color="accent" formControlName="UpdatedByName" disabled/>
                  </mat-form-field>&nbsp;

                     

                    
                    
                  
                        
                     
                      
                        
        
                        <!-- <div>                
                            <mat-label style="color: white; font-family: Roboto; ">Intensity</mat-label>
                            <br/>                        
                            <mat-slider thumbLabel="true"  min="0" max="10" step="1" tickInterval="1" [(ngModel)]="log.LoadLevel"  name="LoadLevel" style="width: 200px;" color="accent" formControlName="LoadLevel" ></mat-slider>
                            <span class="example-selected-value">{{addEventForm.value.LoadLevel}}</span>
                        </div>     -->  
                    
                  
    
                      
              <!--   </mat-tab>
                <mat-tab label="NOTES"> -->
                  <!--   <div style="width: 400px; height: 432px; padding-top: 10px;">
                        <mat-checkbox class="example-margin" [(ngModel)]="log.FullTeamEvent">All players in team roster</mat-checkbox>
                        <br/>    
                        <br/>    
                        <label *ngIf="!log.FullTeamEvent">Attendees</label>
                        <div style="overflow-y: scroll; height: 300px; " *ngIf="!log.FullTeamEvent">
                            
                            <mat-selection-list #shoes>
                                <mat-list-option *ngFor="let player of this.teamWithPlayers.Players">
                                  {{ player.JerseyNo}}&nbsp;{{ player.LastName}},{{ player.FirstName}}
                                </mat-list-option>
                              </mat-selection-list>
                              
                            
                        </div>
                    </div> -->
                  <!--   <mat-form-field style="width: 100%; height: 354px;"  color="accent" appearance="fill">
                        <mat-label style="color: white">Notes</mat-label>
                        <br/>
                        <textarea  matInput #notes rows="10" name="Note" maxlength="200" color="accent" style="width: 100%;" formControlName="Note"></textarea>
                        <mat-hint align="end">{{notes.value.length}} / 200</mat-hint>
                    </mat-form-field>      -->
<!-- 
                </mat-tab>
              </mat-tab-group> -->

             <!--  <label>{{ this.data.TeamId }}</label> -->
               <!--  <mat-form-field color="accent" *ngIf="teams?.length>1" >
                    <mat-label style="color: white">Lag</mat-label>
                    <mat-select placeholder="Lag" [(ngModel)]="log.TeamId" required name="TeamId" color="accent">
                        <mat-option *ngFor="let team of data" [value]="team.TeamId" selected>
                        {{ team.LevelName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                
                <div mat-dialog-actions>
                    <button mat-raised-button color="primary" type="submit" [disabled]="!addEventForm.valid">SAVE</button>
                    <button mat-button mat-dialog-close>CANCEL</button>
                    <button mat-button (click)="deleteTeamEvent()" color="warn" [disabled]="this.data.EventId==''">DELETE</button>
                </div>  
                 
                
                    
        </form>
    </div>
<!--     <div>
      {{this.addEventForm.value | json}}
    </div> -->

