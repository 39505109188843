import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyResult } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
  selector: 'app-organization-mission-dialog',
  templateUrl: './organization-mission-dialog.component.html',
  styleUrls: ['./organization-mission-dialog.component.css']
})
export class OrganizationMissionDialogComponent implements OnInit {

  orgForm;

  constructor( private objectiveService: ObjectivesService,
    public dialogRef: MatDialogRef<OrganizationMissionDialogComponent>,
    public fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: {Mission: string, Vision: string}) { }

  ngOnInit(): void {

    this.orgForm = this.fb.group({
      Mission: [this.data.Mission],
      Vision: [this.data.Vision]
    

      
    });
  }

  onSubmit(){
    console.log(this.orgForm.Mission);
    console.log(this.orgForm.value.Mission);
    this.objectiveService.updateMission(this.orgForm.value.Mission).subscribe(result => {
      if(result)
        this.objectiveService.updateVision(this.orgForm.value.Vision).subscribe(result => {
          if(result){
            this.dialogRef.close();
          }

        });
    });
  }

}
