<div class="app-page" style="margin-left: 30px;">
    <h1>New objective</h1>
    <form [formGroup]="objectiveForm" (ngSubmit)="onSubmit()">
        <input type="hidden" id="ObjectiveId" formControlName="ObjectiveId">
        <mat-form-field appearance="fill" style="width: 380px;">
            <mat-label>Objective</mat-label>
            <input matInput id="Title" type="text" formControlName="Title">
        </mat-form-field>
        <br/>
        <mat-form-field appearance="fill" style="width: 380px;">
            <mat-label>Decription</mat-label>
            <textarea  matInput id="Description" type="text" formControlName="Description"></textarea>
        </mat-form-field>
        <br/>
        <mat-form-field appearance="fill">
            <mat-label>Objective owner</mat-label>
            <mat-select formControlName="Owner">
              <mat-option value="one">Andreas Holmberg</mat-option>
              <mat-option *ngFor="let usr of this.users" [value]="usr.UserId">{{usr.LastName}}, {{usr.FirstName}}</mat-option>
            </mat-select>
          </mat-form-field>
          <br/>
         
          <mat-form-field appearance="fill">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="StartDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Choose a date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="TargetDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <br/>
          <button mat-raised-button type="submit">CREATE</button>
          <h2>Key results</h2>
          <button mat-raised-button (click)="addKeyResult()"  >ADD</button>
          <div
            formArrayName="keyresults"
            *ngFor="let item of objectiveForm.get('KeyResults')['controls']; let i = index;"
          >
            <div [formGroupName]="i">
              <input type="hidden" mat-input formControlName="KeyResultId" placeholder="Item name">
              <mat-form-field appearance="fill" style="width: 380px;">
                <mat-label>Title</mat-label>
                <input matInput id="Title" type="text" formControlName="Title">
            </mat-form-field>
         
            </div>
          </div>

        <!--   <div *ngFor="let kr of objectiveForm.get('KeyResults').value">
            {{kr.Title}}aaaa
          </div> -->
          

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          
          <button mat-raised-button type="submit"  >SUBMIT</button>
      </form>
</div>

