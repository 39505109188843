import { Component, OnInit, Input } from '@angular/core';
import { ScheduleEvent } from '../../models/ScheduleModels';

@Component({
  selector: 'app-game-scout-list',
  templateUrl: './game-scout-list.component.html',
  styleUrls: ['./game-scout-list.component.css']
})
export class GameScoutListComponent implements OnInit {
  @Input() Events: ScheduleEvent[];

  constructor() { }

  ngOnInit() {
  }

}
