<div class="app-page" style="margin-left: 20px;;">
    <h2>NEW CHECKIN</h2>
    <div style="border-left: seagreen; margin-left:20px;" >
        <table >
            <tr>
                <td colspan="2" style=" color: darkgray;">For objective</td>
            </tr>
            <tr>
                <td>
                    <mat-icon style="font-size: 24px;">track_changes</mat-icon>
                </td>
                <td style="font-size: 24px;">
                    {{this.Objective.Title}}
                </td>
            </tr>
        
        
        </table>    
        <mat-divider></mat-divider>
        <br/>
        <div style="display: flex;flex-direction: row; height: 30px;">
            <div class="title">Owner</div>
            <div>
                {{ this.Objective.OwnerInfo.FirstName}} {{ this.Objective.OwnerInfo.LastName}}
            </div>
        </div>    

        <div style="display: flex;flex-direction: row;height: 30px;">
            <div class="title">Description</div>    
            <div *ngIf="this.Objective.Description==null">
                {{this.Objective.Description}}
            </div>
            <div>
                {{this.Objective.Description}}
            </div>
        </div>

       <!--  <div style="display: flex;flex-direction: row;height: 30px;">
            <div class="title">Streams</div>            
            <div>
                FYSIO
            </div>
        </div> -->

        <div style="display: flex;flex-direction: row;height: 30px;">
            <div class="title">Target date</div>            
            <div>
                {{this.Objective.TargetDate | date}}
            </div>
        </div>

        <div style="display: flex;flex-direction: row;height: 30px;">
            <div class="title">Status</div>            
            <div>
                {{ this.Objective.Status }}
            </div>
        </div>
    
        <div style="display: flex;flex-direction: row;height: 30px;">
            <div class="title">Progress</div>            
            <div style="width: 200px; display: flex;flex-direction: row;">
                <mat-progress-bar mode="determinate"  value="{{this.Objective.CurrentProgress}}" style="max-width: 80px; padding-top: 10px; flex-grow: 1;  margin-bottom: 15px; margin-left: 15px;"></mat-progress-bar>
                <label style="font-size: small; font-family: Roboto; margin-left: 10px; margin-bottom: 10px;">{{this.Objective.CurrentProgress}}%</label>
            </div>
        </div>


        <div style="display: flex;flex-direction: row; align-items: flex-end;">
            <div class="subtitle">PROGRESS</div>        
            <!-- <mat-progress-bar mode="determinate"  value="{{80}}" style="max-width: 80px; width: 80px; padding-top: 10px; flex-grow: 1;  margin-bottom: 15px; margin-left: 15px;"></mat-progress-bar><label style="font-size: small; font-family: Roboto; margin-left: 10px; margin-bottom: 10px;">{{80}}%</label> -->
            <!-- <button mat-button (click)="addKeyResult()" >
                <mat-icon>add</mat-icon>
                ADD KEY RESULT
            </button> -->
        </div>
    <mat-divider></mat-divider>
        <div *ngIf="this.Objective.KeyResults==null || this.Objective.KeyResults.length==0" style="color: gray; margin: 10px">
            NO KEY RESULTS DEFINED
        </div>
        <app-keyresult-status-row *ngFor="let keyresult of this.Objective.KeyResults" [KeyResult]="keyresult" [ShowButtons]="false" ></app-keyresult-status-row>
    <!--  <mat-nav-list>
            <div mat-subheader>Folders</div>
            <mat-list-item *ngFor="let keyresult of this.Objective.KeyResults" >
            <a mat-line>
                <app-keyresult-status-row  [KeyResult]="keyresult"></app-keyresult-status-row>
            </a>          
            </mat-list-item>
        
        </mat-nav-list> -->

        <br/>
        <div style="display: flex;flex-direction: row; align-items: flex-end;">
            <div class="subtitle">TASKS</div>
    <!--         <button mat-button (click)="addTask()" >
                <mat-icon>add</mat-icon>
                ADD TASK
            </button> -->
        </div>
        <mat-divider></mat-divider>
        <div *ngIf="this.Objective.Tasks==null || this.Objective.Tasks.length==0" style="color: gray; margin: 10px">
            NO TASKS DEFINED
        </div>
        <app-task-status-row *ngFor="let task of this.Objective.Tasks" [Task]="task" [ShowButtons]="false" ></app-task-status-row>
    </div>


    <mat-divider></mat-divider>
    
    <form [formGroup]="checkinForm" (ngSubmit)="onSubmit()">
       <!--  <mat-form-field appearance="fill">
            <mat-label>Owner</mat-label>
            <mat-select formControlName="Owner">            
                <mat-option *ngFor="let usr of this.users" [value]="usr.UserId">{{usr.LastName}}, {{usr.FirstName}}</mat-option>
            </mat-select>
        </mat-form-field> -->
        
        <br/>
        <h2>
            <mat-icon style="font-size: 27px;">update</mat-icon>
            CHECK IN
        </h2>
        <mat-form-field appearance="fill" STYLE="margin-left: 20px;">
            <mat-label>Checkin by</mat-label>
            <mat-select formControlName="Owner">
              <mat-option *ngFor="let usr of this.users" [value]="usr.UserId">{{usr.LastName}}, {{usr.FirstName}}</mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
        <mat-form-field appearance="fill" class="smallinput" style="margin-left: 20px;">
            <mat-label>Checkin date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="CheckinDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        &nbsp;
        <mat-form-field appearance="fill" STYLE="margin-left: 20px;">
            <mat-label>New status</mat-label>
            <mat-select formControlName="Status">
                <mat-option value="OFF_TRACK">
                    OFF TRACK
                  </mat-option>
              <mat-option value="AT_RISK">
                AT RISK
              </mat-option>
              <mat-option value="ON_TRACK">
                ON TRACK
              </mat-option>
              <mat-option value="EXCEEDED">
                EXCEEDED
              </mat-option>
            </mat-select>
          </mat-form-field>

          <br/>
        <div style="display: flex; flex-direction: column;">
            <div style="display: flex; flex-direction: row;">
                <div class="flexdiv">
                    <mat-form-field appearance="fill" class="textarea-div">
                        <mat-label>Progress since last checkin</mat-label>
                        <textarea  matInput id="Progress" type="text" formControlName="Progress"  style="height: 200px;"></textarea>
                    </mat-form-field>
                </div>
                <div class="flexdiv">
                    <mat-form-field appearance="fill" class="textarea-div">
                        <mat-label>Confidence in reaching the objective</mat-label>
                        <textarea  matInput id="Impediments" type="text" formControlName="Confidence" style="height: 200px;"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div style="display: flex; flex-direction: row;">
                <div class="flexdiv">
                    <mat-form-field appearance="fill" class="textarea-div">
                        <mat-label>What is stopping me from reaching the objective</mat-label>
                        <textarea  matInput id="Impediments" type="text" formControlName="Impediments" style="height: 200px;"></textarea>
                    </mat-form-field>
                </div>
                <div class="flexdiv">
                    <mat-form-field appearance="fill" class="textarea-div">
                        <mat-label>Initiatives to improve progress</mat-label>
                        <textarea  matInput id="Initiatives" type="text" formControlName="Initiatives"  style="height: 200px;"></textarea>
                    </mat-form-field>
                </div>
            </div>
        
        
        
        </div>

        <button mat-raised-button type="submit">CHECK IN</button>&nbsp;
        <button mat-raised-button (click)="goback();" type="button" >CANCEL</button>
    </form>

</div>