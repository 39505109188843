<div class="app-page">
    <mat-toolbar style="height: 80px;">
        <mat-toolbar-row>
            <i class="material-icons">local_hospital</i>
              <mat-label>INJURY OVERVIEW</mat-label>
        </mat-toolbar-row>                         
         
       </mat-toolbar>

    <h3>SEASON</h3>
    <span style="font-family: Roboto; margin-left: 10px ;">No of reported injuries</span>
    <div class="container">
        <div id="bodyareaseason_chart" class="chartdiv">
            
        </div>
        <div id="bodypartseason_chart" class="chartdiv">
            
        </div>
        <div id="typeseason_chart" class="chartdiv">
            
        </div>
        <div id="conditionseason_chart" class="chartdiv">
            
        </div>


    </div>
    <span style="font-family: Roboto; margin-left: 10px ;">No of injury days</span>
    <div class="container">
        <div id="bodyareaseason_chart_days" class="chartdiv">
            
        </div>
        <div id="bodypartseason_chart_days" class="chartdiv">
            
        </div>
        <div id="typeseason_chart_days" class="chartdiv">
            
        </div>
        <div id="conditionseason_chart_days" class="chartdiv">
            
        </div>


    </div>

    <h3>CURRENT INJURIES</h3>
    <span style="font-family: Roboto; margin-left: 10px ;">No of reported injuries</span>
    <div class="container">
        <div id="bodyareacurrent_chart" class="chartdiv">
            
        </div>
        <div id="bodypartcurrent_chart" class="chartdiv">
            
        </div>
        <div id="typecurrent_chart" class="chartdiv">
            
        </div>
        <div id="conditioncurrent_chart" class="chartdiv">
            
        </div>


    </div>
    <span style="font-family: Roboto; margin-left: 10px ;">No of injury days</span>
    <div class="container">
        <div id="bodyareacurrent_chart_days" class="chartdiv">
            
        </div>
        <div id="bodypartcurrent_chart_days" class="chartdiv">
            
        </div>
        <div id="typecurrent_chart_days" class="chartdiv">
            
        </div>
        <div id="conditioncurrent_chart_days" class="chartdiv">
            
        </div>


    </div>



    
</div>