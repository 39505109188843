import { Component, Input, OnInit } from '@angular/core';
import { TeamEventLogSummary } from '../../models/schedulemodels';
import { T } from '@angular/cdk/keycodes';
declare var google:any;

@Component({
  selector: 'app-player-rpe-chart',
  templateUrl: './player-rpe-chart.component.html',
  styleUrls: ['./player-rpe-chart.component.css']
})
export class PlayerRpeChartComponent implements OnInit {

  @Input() EventId;
  @Input() LogSummary: TeamEventLogSummary;

  constructor() { }

  ngOnInit(): void {

        //Need uniquname even if component generated many teams, Key = Playerid+TestId
        var divname = 'RPEchart_div_'+ this.EventId;
        var logsummary = this.LogSummary;
     
        console.log(this.LogSummary);
        google.charts.load('current', {'packages':['corechart']});
        google.charts.setOnLoadCallback(function(){drawChart()});    
        
        function drawChart() {
          var data = new google.visualization.DataTable();
          data.addColumn('number','RPE');
          data.addColumn('number','Value');
          

          //console.log(logsummary);

          var _row = [];
          _row.push(1, logsummary.Intensity_NoOf_1);
          data.addRow(_row);
          var _row = [];
          _row.push(2, logsummary.Intensity_NoOf_2);
          data.addRow(_row);
          var _row = [];
          _row.push(3, logsummary.Intensity_NoOf_3);
          data.addRow(_row);
          var _row = [];
          _row.push(4, logsummary.Intensity_NoOf_4);  
          data.addRow(_row);
          var _row = [];        
          _row.push(5, logsummary.Intensity_NoOf_5);
          data.addRow(_row);
          var _row = [];
          _row.push(6, logsummary.Intensity_NoOf_6);
          data.addRow(_row);
          var _row = [];
          _row.push(7, logsummary.Intensity_NoOf_7);
          data.addRow(_row);
          var _row = [];
          _row.push(8, logsummary.Intensity_NoOf_8);
          data.addRow(_row);
          var _row = [];
          _row.push(9, logsummary.Intensity_NoOf_9);
          data.addRow(_row);
          var _row = [];
          _row.push(10, logsummary.Intensity_NoOf_10);
          data.addRow(_row);
          



          console.log(data);

          var options = {
          
            //chartArea: { width: this._chartWidth, height: this._chartHeight},
            legend: { position: 'none' },
            title: 'RPE reported',
            titleTextStyle: {color: 'white'},
            colors: ['orange', 'gray'],
            pointSize: 5,
            width: '600',
            backgroundColor: '#303030',
            hAxis: { textStyle: { color: 'white'} , baselineColor: 'white' },
            vAxis: { textStyle: { color: 'white'} , baselineColor: 'white' },
          }


          var chart = new google.visualization.ColumnChart(document.getElementById(divname));
          chart.draw(data, options);
        }
    }
  }


