import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from '../../../shared/baseservice';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { parseTemplate } from '@angular/compiler';
import { ConfigurationLoaderService } from '../../../app-configuration/configuration-loader.service';
import { EquipmentModel, ExerciseModel, ExerciseViewModel, ProgramModel, SetTemplateModel, TargetModel, TrainingMethodModel, WorkoutModel } from '../models/exercise.model';
import { ResponseModel } from 'src/app/shared/models/ResponseModel';

@Injectable({
  providedIn: 'root'
}) 

export class TrainingService extends BaseService {
  /* private httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
 */
  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }

  getAllExercises() {  
    return this.http.get(this.rootUrl + '/api/training/exercises');
  }

  createExercise(newExercise: ExerciseViewModel): Observable<boolean>{
  
      let ret = this.http.post<boolean>(this.rootUrl + '/api/training/exercises',newExercise,this.httpOptions);
      return ret;
  }

  deleteExercise(Exercise: ExerciseViewModel): Observable<boolean>{
  
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/training/exercises/' + Exercise.ExerciseId);
    return ret;
  }

  updateExercise(Exercise: ExerciseViewModel): Observable<boolean>{
  
    let ret = this.http.put<boolean>(this.rootUrl + '/api/training/exercises/',Exercise,this.httpOptions);
    return ret;
  }

  getTargets(){
    return this.http.get(this.rootUrl + '/api/training/targets');
  }

  createEquipment(newEquipment: EquipmentModel): Observable<boolean>{
  
    let ret = this.http.post<boolean>(this.rootUrl + '/api/training/equipment',newEquipment,this.httpOptions);
    return ret;
  }

  updateEquipment(Equipment: EquipmentModel): Observable<boolean>{
  
    let ret = this.http.put<boolean>(this.rootUrl + '/api/training/equipment/',Equipment,this.httpOptions);
    return ret;
  }

  deleteEquipment(Equipment: EquipmentModel): Observable<boolean>{
  
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/training/equipment/' + Equipment.EquipmentId);
    return ret;
  }

  getEquipmentList(){
    return this.http.get(this.rootUrl + '/api/training/equipment');
  }


  createTarget(newTarget: TargetModel): Observable<boolean>{
  
    let ret = this.http.post<boolean>(this.rootUrl + '/api/training/targets',newTarget,this.httpOptions);
    return ret;
  }

  updateTarget(Target: TargetModel): Observable<boolean>{
  
    let ret = this.http.put<boolean>(this.rootUrl + '/api/training/targets',Target,this.httpOptions);
    return ret;
  }

  deleteTarget(Target: TargetModel): Observable<boolean>{
  
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/training/targets/' + Target.TargetId);
    return ret;
  }

  getSetTemplates(){
    return this.http.get(this.rootUrl + '/api/training/settemplates');
  }



  createSetTemplate(newSetTempt: SetTemplateModel): Observable<boolean>{
  
    let ret = this.http.post<boolean>(this.rootUrl + '/api/training/settemplates',newSetTempt,this.httpOptions);
    return ret;
  }
  updateSetTemaplet(setTemplate: SetTemplateModel): Observable<boolean>{
  
    let ret = this.http.put<boolean>(this.rootUrl + '/api/training/settemplates',setTemplate,this.httpOptions);
    return ret;
  }

  deleteSetTemplate(SetTemplate: SetTemplateModel): Observable<boolean>{
  
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/training/settemplates/' + SetTemplate.SetTemplateId);
    return ret;
  }


  createWorkout(newWorkout: WorkoutModel){
    let ret = this.http.post<boolean>(this.rootUrl + '/api/training/workouts/',newWorkout,this.httpOptions);
    return ret;

  }

  getWorkouts(){
    return this.http.get(this.rootUrl + '/api/training/workouts');
  }

  getWorkout(workoutid: number){
    return this.http.get(this.rootUrl + '/api/training/workouts/' + workoutid );
  }

  updateWorkout(workout: WorkoutModel){
  
    let ret = this.http.put<boolean>(this.rootUrl + '/api/training/workouts',workout,this.httpOptions);
    return ret;
  }

  deleteWorkout(workout: WorkoutModel): Observable<boolean>{
  
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/training/workouts/' + workout.WorkoutId);
    return ret;
  }


  getPrograms(){
    return this.http.get(this.rootUrl + '/api/training/programs');
  }

  getActivePrograms(){
    return this.http.get(this.rootUrl + '/api/training/programs/active');
  }

  getProgram(programid: number){
    
    return this.http.get(this.rootUrl + '/api/training/programs/' + programid );
  }

  
  createProgram(newProgram: ProgramModel){
    let ret = this.http.post<boolean>(this.rootUrl + '/api/training/programs/',newProgram,this.httpOptions);
    return ret;

  }

  updateProgram(program: ProgramModel){
  
    let ret = this.http.put<boolean>(this.rootUrl + '/api/training/programs',program,this.httpOptions);
    return ret;
  }

  deleteProgram(Program: ProgramModel): Observable<ResponseModel>{
  
    let ret = this.http.delete<ResponseModel>(this.rootUrl + '/api/training/programs/' + Program.ProgramId);
    return ret;
  }

  getMethods(){
    return this.http.get(this.rootUrl + '/api/training/methods');
  }

  createMethod(method: TrainingMethodModel){
    let ret = this.http.post<boolean>(this.rootUrl + '/api/training/methods/',method,this.httpOptions);
    return ret;

  }

  updateMethod(method: TrainingMethodModel){
  
    let ret = this.http.put<boolean>(this.rootUrl + '/api/training/methods',method,this.httpOptions);
    return ret;
  }

  deleteMethod(method: TrainingMethodModel): Observable<boolean>{
  
    let ret = this.http.delete<boolean>(this.rootUrl + '/api/training/methods/' + method.TrainingmethodId);
    return ret;
  }

}
