import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { UserService } from 'src/app/shared/user.service';
import { PlayerContactModel } from '../../models/PlayerModels';
import { AddPlayerContactDialogComponent } from '../../player/add-player-contact-dialog/add-player-contact-dialog.component';
import { ScoutingPlayerService } from '../../services/scouting-player.service';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-player-contact-card',
  standalone: true,
  imports: [SharedModuleModule, CommonModule],
  templateUrl: './player-contact-card.component.html',
  styleUrls: ['./player-contact-card.component.css']
})
export class PlayerContactCardComponent implements OnInit {

  @Input() Contact: PlayerContactModel;
  @Output() ContactDeleted = new EventEmitter();
  @Output() ContactUpdated = new EventEmitter();

  isAllowedToEdit: boolean = false;

  constructor(private scoutingService: ScoutingPlayerService,public userService: UserService,private dialog: MatDialog) {

    this.isAllowedToEdit = this.userService.roleMatch(["Org_Admin", "Contacts_Editor"]);
    
  
  }

  ngOnInit(): void {
    console.log(this.Contact);
    console.log(this.isAllowedToEdit);
  }

  

  deleteContact(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete the contact? "
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.scoutingService.deletePlayerContact(this.Contact).subscribe(result=>{
          if(result)
            this.ContactDeleted.emit(this.Contact.ContactId);
        });
      }
    });   
  }

  ediContact(){
   
    const dialogRef = this.dialog.open(AddPlayerContactDialogComponent, {
      width: '450px',
      data:  {Contact: this.Contact}
    });
    dialogRef.afterClosed().subscribe(result => {
      
        this.ContactUpdated.emit(this.Contact);
      
    });   
  
  }

}
