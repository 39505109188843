<div class="app-page" >
  <h2><mat-icon>label</mat-icon>&nbsp;Tags</h2>
  <a type="button" (click)="openAddDialog()"  mat-raised-button color="primary">NEW </a>
  <div *ngIf="this.tags?.length==0" style="margin-top: 30px; color: gray;">
    NO TAGS DEFINED YET
  </div>
    <table mat-table [dataSource]="dataSource" matSort style="margin-left: 20px ;" *ngIf="this.tags?.length>0" >

        <ng-container matColumnDef="Text">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Text </th>
            <td mat-cell *matCellDef="let element" style="font-size: 12px; min-width: 200px;" > {{element.Text}} </td>
          <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
              Average<br/>
              Median
             </td> -->
          </ng-container>
        <ng-container matColumnDef="TagColor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Color </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;"  > 
            <mat-icon [class]="element.TagColor">
              label
            </mat-icon>

           </td>
        <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
            Average<br/>
            Median
           </td> -->
        </ng-container>
        <ng-container matColumnDef="Visible">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Visible </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;"  > 
            <label *ngIf="element.Visible">
              YES
            </label>
            <label *ngIf="!element.Visible">
              NO
            </label>
           </td>
        <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
            Average<br/>
            Median
           </td> -->
        </ng-container>
        <ng-container matColumnDef="Active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enabled </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;"  > 
            <label *ngIf="element.Active">
              YES
            </label>
            <label *ngIf="!element.Active">
              NO
            </label>
           </td>
        <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
            Average<br/>
            Median
           </td> -->
        </ng-container>
       
       

        <ng-container matColumnDef="Actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"> 
                
             
                <button mat-button type="button" (click)="openEditDialog(element.TagId)" >
                  EDIT
                </button>
                 
                <button mat-button type="button" (click)="confirmDelete(element.TagId)" >
                  DELETE
                </button>
               
            </td>
          </ng-container>
    
        
     
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 12px;;"></tr>
      <!--   <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" style="height: 24px;"></tr> -->
      </table>

</div>

