<div style="display: flex; flex-direction: column; flex-wrap: wrap; width: 100%; margin-left: 10px;" class="app-page">
    <h2 style="font-family: roboto;">SCOUTING DASHBOARD</h2>
    <div id="scouting_reports_chart" style="width: 90%; height: 270px;" >

    </div>
    <mat-divider></mat-divider>
   <div style="display: block;">
        <h2 style="font-family: Roboto;">Season statistics</h2>
        <form>
        <mat-form-field  style="font-size: small; width: 200px;">
                        <mat-label>Season</mat-label>
                        <mat-select [formControl]="seasonFormControl" (selectionChange)="refreshData()">
                          <mat-option *ngFor="let season of _seasons" [value]="season" >
                            {{season.Name}}
                          </mat-option>
                        </mat-select>
        </mat-form-field>       

       

       <!--  <button mat-raised-button (click)="editRoster()" *ngIf="this._userService.roleMatch('Test_Admin')" >EDIT ROSTER</button> -->
        </form>
        <br/>
        <div style="display: flex; flex-direction: row;">
            
            <div style="border: solid gray 1px; font-family: Roboto; font-size: x-large; width: 150px; display: flex; justify-content: start; flex-direction: column; margin-right: 20px;">
                <div style="font-size: small; margin-left: 3px;
                margin-top: 2px;">
                    Reports
                </div>
                <div style="text-align: center; width: 100%;">
                    {{this.SeasonNoOfReports}}
                </div>                 
            </div>
            <div style="border: solid gray 1px; font-family: Roboto; font-size: x-large; width: 150px; display: flex; justify-content: start; flex-direction: column; margin-right: 20px;">
                <div style="font-size: small; margin-left: 3px;
                margin-top: 2px;">
                   Players scouted
                </div>
                <div style="text-align: center; width: 100%;">
                    {{this.SeasonNoOfPlayers}}
                </div>
                 
            </div>
            <div style="border: solid gray 1px; font-family: Roboto; font-size: x-large; width: 150px; display: flex; justify-content: start; flex-direction: column; margin-right: 20px;">
                <div style="font-size: small; margin-left: 3px;
                margin-top: 2px;">
                    Scouting events
                </div>
                <div style="text-align: center; width: 100%;">
                    {{this.SeasonNoOfEvents + this.SeasonNoOfEvents2 }}
                </div>
                 
            </div>

        </div>
        <br/>
        <div style="display: flex; width: 100%; flex-wrap: wrap; flex-direction: row;">
            <div>
                <div id="scouting_scoutreporting_chart" style="min-width: 600px;height: 300px;" >
                </div>
            </div>
            <div>
                <div id="scouting_events_chart" style="min-width: 600px;height: 300px;" >
                </div>
            </div>                   
        </div>
        <h3>What did we find?</h3>
        <div style="display: flex; width: 100%; flex-wrap: wrap; flex-direction: row;">
              <div>
                <div id="scouting_reportsbybirthyear_chart" style=" min-width: 600px; height: 300px;" >
                </div>      
            </div>            
            <div>               
                <div id="scouting_projections_chart" style="min-width: 600px;height: 300px;" >
                </div>
            </div>
        </div>
        <br/>
     
        <div style="display: flex; flex-direction: row;">
            
            <div >
                <h3>Divisions</h3>
                <table style="margin-right: 20px;">
                    <tr>
                        <th style="text-align: left;">Division</th>
                        <th>Reports</th>
                    </tr>
                    <tr *ngFor="let division of this.Divisions | keyvalue: originalOrder">
                        <td style="white-space: nowrap;">{{division.key}}</td>
                        <td>{{division.value}}</td>
                    </tr>
                </table>
                
            </div>
            <div>
                <h3>Locations</h3>
                <div [ngClass]="{'expandable_div': this.lengthOfLocations>20 && !this.locationExpanded }">
                    <table>
                        <tr>
                            <th style="text-align: left;">Locations</th>
                            <th>Reports</th>
                        </tr>
                        <tr *ngFor="let location of this.Locations | keyvalue: originalOrder">
                            <td style="white-space: nowrap;">{{location.key}}</td>
                            <td>{{location.value}}</td>
                        </tr>
                    </table>
                    
                </div>
                <div *ngIf="this.lengthOfLocations>20 && !this.locationExpanded">.....</div>
                <button *ngIf="this.lengthOfLocations>20 && !this.locationExpanded" mat-button (click)="this.locationExpanded=true">EXPAND</button>
            </div>
        </div>
        
    </div>
      
    
    
</div>

