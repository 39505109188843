import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { PlayerService } from 'src/app/shared/player.service';
import { Season } from 'src/app/shared/team.model';
declare var google:any;

@Component({
  selector: 'app-player-load-ratio-chart',
  templateUrl: './player-load-ratio-chart.component.html',
  styleUrls: ['./player-load-ratio-chart.component.css']
})
export class PlayerLoadRatioChartComponent implements OnInit {

  
  @Input() PlayerId: string;
  @Input() Season: Season;
  

  constructor(private playerService: PlayerService ) { }

  

  ngOnInit(): void {
    //this.getPlayerLoadChartData(this.PlayerId);
  }

  
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.getPlayerLoadChartData(this.PlayerId);
    
  }

  getPlayerLoadChartData(_playerid: string): void{



    this.playerService.GetPlayerLoadAnalysis_ByPeriod_GoogleChart(_playerid, moment(this.Season.SeasonStartDate).format('YYYY-MM-DD'), moment(this.Season.SeasonEndDate).format('YYYY-MM-DD') ).subscribe(result=>{
     
      google.charts.load('current', {'packages':['bar','corechart']});
      google.charts.setOnLoadCallback(drawChart.bind(this));  
      
      //console.log(result);

      function drawChart() {

        var data = new google.visualization.DataTable(result);
      
         
        //Set minDate
        var minDate = new Date(this.Season.SeasonStartDate);
        //minDate.setMonth(minDate.getMonth()-2);

        //Tcikmarks on mondays
        var tickMarks = [];
        var maxDate = new Date(this.Season.SeasonEndDate);
        
          
          var classicOptions = {
            title: 'Acute/Chronic Load Ratios - Reported',
            titleTextStyle: { color: 'white'},
            chartArea: {width: '95%'}, 
            backgroundColor: '#303030',
            legend: {position: 'top', textStyle: {color: 'white', fontSize: 16}},
            // Gives each series an axis that matches the vAxes number below.
            series: {
              0: {targetAxisIndex: 0, },
              1: {targetAxisIndex: 0, },
              2: {targetAxisIndex: 1, color: 'white'},
              3: {targetAxisIndex: 0,},
              4: {targetAxisIndex: 0, },
              5: {targetAxisIndex: 1, color: 'white'}
            },
            vAxes: {
              // Adds titles to each axis.
              0: {textStyle: {color: 'white',fontSize: 10,}},
              1: {maxValue:3, viewWindow: {max: 3}, textStyle: {color: 'white',fontSize: 10,},}
            },
            hAxis: {
              baselineColor: 'white',
              
              textStyle: {color: 'white'},
              gridlines:{ color: 'gray'},
             
            },
            vAxis: {
             
            }
          };
          var view_EWMA = new google.visualization.DataView(data);      
          view_EWMA.hideColumns([1,2,3]);      
          var chartDiv = document.getElementById('LoadChart_ReportedMA_' + _playerid);
          var classicChart = new google.visualization.LineChart(chartDiv);
          classicChart.draw(view_EWMA, classicOptions);
           
         

      }
    })

  }

}
