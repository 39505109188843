<div style="display: flex;flex-direction: row;font-family: Roboto; align-content: space-between; " mat-ripple>
    <div *ngIf="this.Player[0]" style="font-size: 16px; margin-right: 5px;;">
        {{this.Player[0].JerseyNo}}
    </div>    
    <div *ngIf="this.Player[0]" (click)="navigate(this.Player[0].PlayerId)">
        
        <span style="font-size: 12px;">{{this.Player[0].PlayerLastName}}</span>
        <br/>
        <!-- <span style="font-size: 10px">{{this.Player[0].PlayerFirstName}}</span><br/> -->
        <!-- add tags-->

    </div>

</div>