<h2 mat-dialog-title>
    ADD TEAM 
  </h2>
  <form [formGroup]='teamsForm'>
        
        <div mat-dialog-content >
            <mat-selection-list #teamlist style="height: 350px; " [multiple]="false" [(ngModel)]="this.selectedTeam">
                <mat-list-option *ngFor="let team of this._teams" [value]="team">                
                  {{team.Level}}                  
                 </mat-list-option>                
            </mat-selection-list>
        </div>
  
  <div mat-dialog-actions>
      
      <button mat-button [mat-dialog-close]="teamlist.selectedOptions?.selected[0]?.value" cdkFocusInitial >{{ data.Action }}</button>
      <button mat-button [mat-dialog-close]="" >CANCEL</button>
    
  
  </div>
