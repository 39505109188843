<h2 mat-dialog-title style="font-family: Roboto;" *ngIf="this.data.mode=='NEW'">New tag</h2>
<h2 mat-dialog-title style="font-family: Roboto;" *ngIf="this.data.mode!='NEW'">Edit tag</h2>
<div mat-dialog-content>

  <form  [formGroup]="tagForm" (ngSubmit)="save()" autocomplete="off">
    <input type="hidden" formControlName="TagId" />
    <mat-form-field appearance="fill"  style="width: 400px;">
      <mat-label>English text</mat-label>
      <input matInput placeholder="Enter name" formControlName="TextEnglish" id="TextEnglish" /> 
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <!-- <mat-hint>Hint</mat-hint> -->
    </mat-form-field>
    <br/>
    <mat-form-field appearance="fill"  style="width: 400px;">
        <mat-label>Swedish text</mat-label>
        <input matInput placeholder="Enter name" formControlName="TextSwedish" id="TextSwedish" /> 
        <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
      <br/>
      <mat-form-field appearance="fill"  style="width: 400px;">
        <mat-label>Finnish text</mat-label>
        <input matInput placeholder="Enter name" formControlName="TextFinnish" id="TextFinnish" /> 
        <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
      <mat-form-field appearance="fill" style="width: 400px;">
        <mat-label>Tag color</mat-label>
        <mat-select formControlName="TagColor"  >
          <mat-option value="sc-playertag-green">Green</mat-option>
          <mat-option value="sc-playertag-yellow">Yellow</mat-option>
          <mat-option value="sc-playertag-red">Red</mat-option>
          <mat-option value="sc-playertag-cerice">Cerice</mat-option>
          <mat-option value="sc-playertag-black">Black</mat-option>
          <mat-option value="sc-playertag-gray">Gray</mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-slide-toggle formControlName="Active">Enabled</mat-slide-toggle>
      
    <br/>
    <h3>Tag access</h3>
    <div style="height: 250px; overflow-y: scroll;" *ngIf="this.PlayerTagsSecurity" formArrayName="PlayerTagsSecurity">
            
      <div *ngFor="let access of PlayerTagsSecurity.controls; index as i;">
        <div [formGroupName]="i" STYLE="display: flex; flex-direction: row; border-bottom: gray solid 1px;">
         
         
          <div style="width: 200px; font-family: Roboto;" >
              {{this.tagForm.controls['PlayerTagsSecurity'].controls[i].controls.LastName.value}}, {{this.tagForm.controls['PlayerTagsSecurity'].controls[i].controls.FirstName.value}}
          </div>
          <div  style="width: 100px;">
              <mat-checkbox formControlName="Viewable">View</mat-checkbox>
          </div>
           <div  style="width: 100px;">
              <mat-checkbox formControlName="Addable">Add</mat-checkbox>
          </div>
           
      </div>
      </div>
    </div>
   
    <button mat-button color="primary" *ngIf="this.data.mode=='NEW'"  type="submit" [disabled]="!tagForm.valid">CREATE</button>
    <button mat-button color="primary"  *ngIf="this.data.mode!='NEW'" type="submit" [disabled]="!tagForm.valid">SAVE</button>
    <button type="button" mat-button  (click)="onCancel()">CANCEL</button>
    <!-- <div  *ngFor="let Video of this.myVideos; let i=index">  
      <video width="270" height="220"   controls disabled="true"  (click)="toggleVideo()" #videoPlayer>   
        <source [src]="Video.URL" type="video/mp4">   
      </video>  
    </div>   -->
      
    

    
  </form>
  <!--     
  <div>
    {{ this.equipmentForm.value | json}}
  </div> -->
    
</div>