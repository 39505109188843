<div class="app-page" style="margin-left: 10px;">
  <div style="display: flex; justify-content: center; margin-top: 30px;"  *ngIf="this._flow.length==0">
    <div style="font-family: Roboto;">No assessments in the last month</div>
  </div> 
  <div style="display: flex;flex-direction: row;flex-wrap: wrap;">


    <ng-container *ngFor="let evt of _flow" >
       
        <app-load-player-status [event]="evt" *ngIf="evt.EventType=='SELFASSESSMENT'">

       </app-load-player-status>  
     <app-load-game-assessment [event]="evt" *ngIf="evt.EventType=='GAMERANK'">

       </app-load-game-assessment>  
</ng-container>

</div>
</div>

