import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';
import { BaseService } from 'src/app/shared/baseservice';
import { PlayerNote, PlayerNoteViewModel } from '../models/Note';
import { Report, ReportViewModel } from '../models/ReportModels';

@Injectable({
  providedIn: 'root'
})
export class ScoutingService   extends BaseService{

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
  }

   addReport(report: Report){
    /* var params = {
      GameId: GameId,
      EventStart: EventStart,
      Comment: Comment,
      Visibility: Visibility

    } */
     return this.http.post<boolean>(this.rootUrl + '/api/scouting/reports', report);     
  }

  saveReport(report: Report){
 
     return this.http.put<boolean>(this.rootUrl + '/api/scouting/reports', report);     
  }

  

  getReport(_reportId: string): Observable<ReportViewModel>{
    let ret = this.http.get<ReportViewModel>(this.rootUrl + '/api/report/' + _reportId);
    return ret;
  }

  addNote(note: PlayerNote){   
     return this.http.post<boolean>(this.rootUrl + '/api/scouting/notes', note);     
  }

  saveNote(note: PlayerNote){   
    return this.http.put<boolean>(this.rootUrl + '/api/scouting/notes', note);     
 }

 
 getNote(NoteId: string): Observable<PlayerNoteViewModel>{
  let ret = this.http.get<PlayerNoteViewModel>(this.rootUrl + '/api/scouting/notes/' + NoteId);
  return ret;
}

}
