<form [formGroup]="taskForm" (ngSubmit)="onSubmit()">

<h2 mat-dialog-title>EDIT TASK</h2>
<mat-dialog-content style="font-size: smaller;">
        <input type="hidden" id="ObjectiveId" formControlName="ObjectiveId"/>
        <input type="hidden" id="TaskId" formControlName="TaskId"/>
        <mat-form-field appearance="fill" style="width: 380px;">
            <mat-label>Task</mat-label>
            <input matInput id="Title" type="text" formControlName="Title">
        </mat-form-field>
        <br/>
        <mat-form-field appearance="fill" style="width: 380px;">
            <mat-label>Decription</mat-label>
            <textarea  matInput id="Description" type="text" formControlName="Description"></textarea>
        </mat-form-field>
        <br/>
          <mat-form-field appearance="fill">
            <mat-label>Objective owner</mat-label>
            <mat-select formControlName="Owner">              
              <mat-option *ngFor="let usr of this.users" [value]="usr.UserId">{{usr.LastName}}, {{usr.FirstName}}</mat-option>
            </mat-select>
          </mat-form-field>
          <br/>
         
          <mat-form-field appearance="fill" class="smallinput">
            <mat-label>Target date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="TargetDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <br/>
         
        

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button type="submit" [disabled]="!taskForm.valid">SAVE</button>
  <button mat-button mat-dialog-close>CANCEL</button> 
  
</mat-dialog-actions>
    
    
</form>


