<div class="app-page mat-typography" style="margin-left: 20px; margin-right: 20px;">
    <h2 clas="mat-h2">
        Data views
    </h2>
    <button  [routerLink]="['/config/gamestatistics/view/', 'NEW']"  mat-button color="primary"><mat-icon>add</mat-icon>NEW </button>
    <div style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: flex-start;">
    
        <mat-card appearance="outlined" style="max-width: 400px; min-width: 250px; margin-right: 20px; margin-bottom: 20px;" *ngFor="let view of this.views">
            <mat-card-header>
                
                <div mat-card-avatar><mat-icon matListItemIcon>table_chart</mat-icon></div>
                <mat-card-title>{{view.Name}}</mat-card-title>
            </mat-card-header>
            
            <mat-card-content style="margin-left: 30px;">
                <h3>Positions</h3>
                <div *ngIf="view.Positions.length==0" style="font-family: Roboto; margin-left: 30px; color: #f06565;">
                    No players selected
                </div>
                <mat-list dense>
                    <mat-list-item *ngFor="let group of view.Positions" >
                        <mat-icon matListItemIcon>people</mat-icon>
                        {{group.Text}}
                    </mat-list-item>
                </mat-list>
                <h3>Data groups</h3>
                <mat-list dense>
                    <mat-list-item *ngFor="let group of view.Groups" >
                        <mat-icon matListItemIcon>table_rows</mat-icon>
                        {{group.Name}}
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button [routerLink]="['/config/gamestatistics/view/', view.GameStatisticsViewId]">EDIT</button>
                <button mat-button (click)="confirmDeleteView(view)">DELETE</button>
            </mat-card-actions>
        </mat-card>
      
    </div>
    <h2 clas="mat-h2">
        Data groups
    </h2>
    <button [routerLink]="['/config/gamestatistics/group/', 'NEW']"   mat-button color="primary"><mat-icon>add</mat-icon>NEW</button>
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;align-items: flex-start;">
    
        <mat-card appearance="outlined" *ngFor="let group of this.groups" style="max-width: 400px; min-width: 250px; margin-right: 20px; margin-bottom: 20px;" >
            <mat-card-header>
                
                <div mat-card-avatar><mat-icon matListItemIcon>table_rows</mat-icon></div>
                <mat-card-title>{{group.Name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content >
                <mat-list dense>
                    <mat-list-item *ngFor="let parameter of group.Parameters" >
                        <mat-icon matListItemIcon>table_row</mat-icon>
                        <div matLine>{{parameter.Abbreviation}}</div>
                        <div matLine>{{parameter.Name}}</div>
                    </mat-list-item>
                    <mat-list-item *ngFor="let parameter of group.ParametersCalculated" >
                        <mat-icon matListItemIcon>calculate</mat-icon>
                        <div matLine>{{parameter.Abbreviation}}</div>
                        <div matLine>{{parameter.Name}}</div>
                    </mat-list-item>
                    
                </mat-list>
            </mat-card-content>
            <mat-card-actions>
                <button mat-button [routerLink]="['/config/gamestatistics/group/', group.GameStatisticsGroupId]">EDIT</button>
                <button mat-button (click)="confirmDeleteGroup(group)">DELETE</button>
            </mat-card-actions>
        </mat-card>
    
    </div>

<!-- 
    <h2 clas="mat-h2">
        Parameters
    </h2>
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;align-items: flex-start;">
        <mat-card  style="max-width: 400px; min-width: 250px; margin-right: 20px; margin-bottom: 20px;">
            <mat-card-title>Game parameters</mat-card-title>
           
            <mat-card-content>
                <div style="display: flex; flex-wrap: wrap;">
                    <div *ngFor="let parameter of this.Parameters" style="margin: 5px 5px 5px 5px; width: 50px;">[{{parameter.Abbreviation}}]</div>&nbsp;
                </div>
                
             
                <br/>
                {{this.parameters.length}} parameters defined

            </mat-card-content>
            <mat-card-actions>
                <button routerLink="/config/gamestatistics/parameters/all" mat-button>EDIT</button>
            </mat-card-actions>
        </mat-card>
    </div>
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;align-items: flex-start;">
        <mat-card  style="max-width: 400px; min-width: 250px; margin-right: 20px; margin-bottom: 20px;">
            <mat-card-title>Calculated game parameters</mat-card-title>
           
            <mat-card-content>
             
                {{this.parameterscalculated.length}} parameters defined

            </mat-card-content>
            <mat-card-actions>
                <button routerLink="/config/gamestatistics/parameterscalculated/all" mat-button>EDIT</button>
            </mat-card-actions>
        </mat-card>
    </div> -->
   

 
   
</div>
