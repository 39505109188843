import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { TeamService } from '../shared/team.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css']
})
export class WelcomePageComponent implements OnInit {

  constructor(public _globals: Globals, teamService: TeamService) {
    teamService.getTeams().subscribe((data: any) =>{
      //this.teams = data;
      if(data.length>0){
        this._globals.TeamName =  data.Name;
        //this._globals.TeamImage = '/assets/img/' + data[0].SmallIconFileName;
      }
      
    });
  }

  ngOnInit() {
    
  }

}
