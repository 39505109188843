<div style="display: flex; border-left:orange solid 4px; padding-left: 5px; margin-left: 10px; margin-top: 15px; background: #424242; max-width: 450px; min-width: 360px; ">
    <table>
        <tbody>
            <tr >
                <td style="width: 50px;vertical-align:top;  font-size: 14px;font-family: Roboto;">
                    <span style="font-family: Roboto; font-size: 24px; vertical-align: text-top;">
                        {{ this.GameEvent.EventDate | date:'dd' }}<br/>                       
                    </span>
                     {{ this.GameEvent.EventDate  | date:'MMM'}}<br/>
                        {{ this.GameEvent.EventDate  | date:'EEE'}} 
                </td>
            </tr>
        </tbody>
    </table>
    <div  style="color: white; float:left; width: 37.5%; text-align: center"><img src="/assets/img{{ this.GameEvent.HomeTeam.SmallIconFileName}}" style="height: 50px; margin:auto;"/>
        <p style="margin: auto; font-weight: bold; font-size:small; font-family: Roboto;">{{ this.GameEvent.HomeTeam.Name }}</p>
     </div>
    <div  style=" float:left; width: 25%;font-size: x-small; margin-top: 10px; font-weight: bold; white-space: nowrap; overflow-x: hidden; font-family: Roboto;">
        <i class="material-icons" style="font-size: 14px;">
            access_time
            </i> {{this.GameEvent.StartTime | date:'HH:mm'}}<br/>
            <img _ngcontent-c0="" src="/assets/img/trophy-variant.png" style="height: 14px; opacity: 0.7;">
            {{this.GameEvent.DivisionName}}<br/>
        <i class="material-icons" style="font-size: 14px;">
            place
            </i>
        {{this.GameEvent.Venue}} </div>
    <div style="color: white; float:left; width: 37.5%;text-align: center "><img src="/assets/img{{ this.GameEvent.AwayTeam.SmallIconFileName}}" style="height: 50px; margin: auto;"/>
        <p style="margin: auto; font-weight: bold; font-size: small;font-family: Roboto;">{{ this.GameEvent.AwayTeam.Name }}</p> 
    </div>  
  
  </div>
  <div style="display: flex; border-left:orange solid 4px; padding-left: 5px; margin-left: 10px; background: #424242; max-width: 450px; min-width: 360px; ">
    <div style="width: 50px;"></div>
    <div  style="color: white; float:left; width: 37.5%; text-align: center; font-family: Roboto; font-size: x-large;">{{ this.GameEvent.GameStatistics.GoalsHome}}</div>
    <div  style=" float:left; width: 25%;font-size: x-small; margin-top: 10px; white-space: nowrap; overflow-x: hidden; font-family: Roboto;">
        <div style="width: 50px;text-align: center; border-radius: 3px; background-color: green; font-family: Roboto; font-size: small;" *ngIf="this.GameEvent.MyTeamId==this.GameEvent.HomeTeam.TeamId &&  this.GameEvent.GameStatistics.GoalsHome>this.GameEvent.GameStatistics.GoalsAway" >Win</div>
        <div style="width: 50px;text-align: center; border-radius: 3px; background-color: red; font-family: Roboto; font-size: small;" *ngIf="this.GameEvent.MyTeamId==this.GameEvent.HomeTeam.TeamId &&  this.GameEvent.GameStatistics.GoalsHome<this.GameEvent.GameStatistics.GoalsAway" >Loss</div>
        <div style="width: 50px;text-align: center; border-radius: 3px; background-color: red; font-family: Roboto;font-size: small;" *ngIf="this.GameEvent.MyTeamId==this.GameEvent.AwayTeam.TeamId &&  this.GameEvent.GameStatistics.GoalsHome>this.GameEvent.GameStatistics.GoalsAway" >Loss</div>
        <div style="width: 50px;text-align: center; border-radius: 3px; background-color: green; font-family: Roboto;font-size: small;" *ngIf="this.GameEvent.MyTeamId==this.GameEvent.AwayTeam.TeamId &&  this.GameEvent.GameStatistics.GoalsHome<this.GameEvent.GameStatistics.GoalsAway" >Win</div>
    </div>
    <div style="color: white; float:left; width: 37.5%;text-align: center;font-family: Roboto; font-size: x-large; ">{{ this.GameEvent.GameStatistics.GoalsAway}}</div>
  </div>

  <div *ngIf="this.GameEvent.RankingNoOfBad + this.GameEvent.RankingNoOfNeutral + this.GameEvent.RankingNoOfGood + this.GameEvent.RankingNoOfVeryGood + this.GameEvent.RankingNoOfExcellent > 0"
      style="display: flex; border-left:orange solid 4px; padding-left: 5px; margin-left: 10px; background:  #424242;  max-width: 450px; ">
  
    <div style="display: flex; padding-top: 5px; margin-left: 10px;border-top: 1px darkgray solid; width: 100%; align-items: center; font-size: small;" >
       
      <div><mat-icon style="margin-right: 5px;">group</mat-icon>
          <span class="dotbad" *ngFor="let i of '1'.repeat(this.GameEvent.RankingNoOfBad).split('1').slice(1), let x=index"></span>
          <span class="dotaverage" *ngFor="let i of '1'.repeat(this.GameEvent.RankingNoOfNeutral).split('1').slice(1), let x=index"></span>
          <span class="dotgood" *ngFor="let i of '1'.repeat(this.GameEvent.RankingNoOfGood).split('1').slice(1), let x=index"></span>
          <span class="dotverygood" *ngFor="let i of '1'.repeat(this.GameEvent.RankingNoOfVeryGood).split('1').slice(1), let x=index"></span>
          <span class="dotexcellent" *ngFor="let i of '1'.repeat(this.GameEvent.RankingNoOfExcellent).split('1').slice(1), let x=index"></span>
          
      </div>
      <span style="margin-left: 5px;font-family: Roboto;">{{this.GameEvent.OverallAvg | number:'1.2-2' }}</span>
        
    </div> 
    
  </div>

  <div *ngIf="this.GameEvent.PlayerAssessmentAverages.NoOfPlayers>0" style="display: flex; border-left:orange solid 4px; padding-left: 5px; margin-left: 10px; background: #424242;  max-width: 450px;  ">
    
    <div style="display: flex; padding-top: 5px; margin-left: 10px;width: 100%" >
       
      <table style="font-size: x-small;">
          <tr>
              <td colspan="6" style="font-family: Roboto; font-size: 10px;">Pre game status (avg)</td>
          </tr>             
          <tr>
              <td>
                  <mat-icon class="example-icon" >person</mat-icon>
              </td>
              <td style="width:32px;">
                  <mat-icon class="example-icon"  >directions_run</mat-icon>
              </td>
              <td style="width:32px;">
                      <mat-icon class="example-icon"  >highlight</mat-icon>
                  </td>
              <td style="width:32px;">
                  <mat-icon class="example-icon"  >hotel</mat-icon>
              </td>
              
              <td style="width:37px; ">
                      <mat-icon class="example-icon" >flash_on</mat-icon>M
              </td>
  
              <td style="width:37px; ">
                      <mat-icon class="example-icon" >flash_on</mat-icon>O
              </td>
          </tr>
          <tr>
              <td>
                  {{ this.GameEvent.PlayerAssessmentAverages.NoOfPlayers }}
              </td>
              <td>
                  {{ this.GameEvent.PlayerAssessmentAverages.Freshness | number:'1.2-2' }}
              </td>
              <td>
                  {{ this.GameEvent.PlayerAssessmentAverages.Confidence  | number:'1.2-2'  }}
              </td>
              <td>
                  {{ this.GameEvent.PlayerAssessmentAverages.Sleep | number:'1.2-2'  }}
              </td>
              <td>
                  {{ this.GameEvent.PlayerAssessmentAverages.Muscles | number:'1.2-2'  }} 
              </td>
              <td>
                  {{ this.GameEvent.PlayerAssessmentAverages.Joints | number:'1.2-2'  }} 
              </td>
  
  
          </tr>
      </table>
       
        
    </div> 
    
  </div>
  
  
  
  
  <div style=" border-left:orange solid 4px; padding-left: 5px; margin-left: 10px; background:  #424242;  max-width: 450px;border-top: 1px darkgray solid;  ">
      <div style="font-size: small; margin-left: 10px;font-family: Roboto; vertical-align: bottom;" *ngFor="let ranking of this.GameEvent.PlayerRankingSummaryByScout">
          <img src="/assets/img/icons8-coach-32.png" style="width: 20px;color: darkgray; font-family: Roboto;">{{ ranking.ScoutName }}<br/>
          <span style="width: 24px">&nbsp;</span>
              <span class="dotbad" *ngFor="let i of '1'.repeat(ranking.RankingNoOfBad).split('1').slice(1), let x=index"></span>
              <span class="dotaverage" *ngFor="let i of '1'.repeat(ranking.RankingNoOfAverage).split('1').slice(1), let x=index"></span>
              <span class="dotgood" *ngFor="let i of '1'.repeat(ranking.RankingNoOfGood).split('1').slice(1), let x=index"></span>
              <span class="dotverygood" *ngFor="let i of '1'.repeat(ranking.RankingNoOfVeryGood).split('1').slice(1), let x=index"></span>
              <span class="dotexcellent" *ngFor="let i of '1'.repeat(ranking.RankingNoOfExcellent).split('1').slice(1), let x=index"></span>
              <span style="margin-left: 5px;font-family: Roboto;">{{ ((ranking.RankingNoOfExcellent*5 + ranking.RankingNoOfVeryGood*4 + ranking.RankingNoOfGood*3 + ranking.RankingNoOfAverage*2 + ranking.RankingNoOfBad)/(ranking.RankingNoOfExcellent + ranking.RankingNoOfVeryGood + ranking.RankingNoOfGood + ranking.RankingNoOfAverage + ranking.RankingNoOfBad)).toFixed(2)  }} </span>
      </div>
      
      
      
  </div>
  <DIV style="display: flex; border-left:orange solid 4px; padding-left: 5px; margin-left: 10px; background: #424242;  max-width: 450px; ">
    <button mat-button href="#" routerLink="/load/game/{{this.GameEvent.GameId}}/{{this.TeamId}}" color="PRIMARY">DETAILS</button>
    <button mat-button href="#" *ngIf="this.ShowStatistics" routerLink="/performance/game/statistics/{{this.GameEvent.GameId}}/{{this.TeamId}}"  color="PRIMARY">STATISTICS</button>
    <!-- <button mat-button href="#"  routerLink="/performance/game/addgrades/{{this.GameEvent.GameId}}/{{this.TeamId}}"  color="PRIMARY">GRADE</button> -->
  <!--   <a href="#" routerLink="/gamelogdetails/{{this.GameEvent.GameId}}/{{this.GameEvent.TeamId}}"mat-icon-button color="PRIMARY"  ><mat-icon>assessment</mat-icon></a> -->
    
</DIV>
       
          
  