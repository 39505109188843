<div class="rankinglistentry">
    <table style="width: 100%; ">
        <tr>
            <td>
                <mat-icon mat-list-icon style="font-size: 32px; padding-bottom: 32px;;" >format_list_numbered</mat-icon>
            </td>
            <td style="width: 350px;">
                <span style="font-family: Roboto; font-size: medium;">{{List.Name}}</span><br>
                <span style="font-family: Roboto; font-size: small;">{{List.createdBy}}</span>
            </td>
            <td>
                Ranked {{List.Ordinal}}
            </td>          
        </tr>
    </table>
    
</div>