<div class="app-page">
    <h2>Parameters</h2>
    <a type="button" (click)="openDialog()"  mat-button color="primary"><mat-icon>add</mat-icon>NEW </a>
    <table mat-table [dataSource]="dataSource" matSort style=" width: 100%;" >

        <ng-container matColumnDef="Abbreviation">
            <th mat-header-cell *matHeaderCellDef  mat-sort-header> Abbreviation </th>
            <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Abbreviation}} </td>
          <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
              Average<br/>
              Median
             </td> -->
          </ng-container>
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Name}} </td>
        <!--   <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
            Average<br/>
            Median
           </td> -->
        </ng-container>
       
        <ng-container matColumnDef="Description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description </th>
          <td mat-cell *matCellDef="let element"  ><div style="border-color: black;">{{ element.Description }}</div> </td>
          <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">
       
    
          </td>
        </ng-container>
        <ng-container matColumnDef="DisplayName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Display Name </th>
          <td mat-cell *matCellDef="let element"  ><div style="border-color: black;">{{ element.DisplayName }}</div> </td>
          <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">
       
    
          </td>
        </ng-container>
        <ng-container matColumnDef="Operator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Operator </th>
          <td mat-cell *matCellDef="let element"  ><div style="border-color: black;">
            <mat-icon>{{ this.getRankingArrow(element.Operator) }}</mat-icon>
          </div> </td>
          <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">               
          </td>
      </ng-container>
        <ng-container matColumnDef="ValueType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ValueType </th>
            <td mat-cell *matCellDef="let element"  ><div style="border-color: black;">{{ element.ValueType }}</div> </td>
            <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">               
            </td>
        </ng-container>

        <ng-container matColumnDef="Actions" >
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
             <!--  <button type="button" mat-icon-button  *ngIf="(!test.Global)"  >
                  <mat-icon>delete</mat-icon>
                </button> -->
                <button type="button" mat-icon-button *ngIf="element.OrganizationId>0" (click)="openEditDialog(element);">
                    <mat-icon>create</mat-icon>
                </button>
                
          
        </mat-cell>      
      </ng-container>
     
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 12px;;"></tr>
      <!--   <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" style="height: 24px;"></tr> -->
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons matSort style="margin-left: 20px;"></mat-paginator>
   <!--  <mat-list dense>
        <div mat-subheader>Parameters</div>
        <mat-list-item *ngFor="let p of this.statisticsParameters">
          <mat-icon mat-list-icon>folder</mat-icon>
          <div mat-line>{{p.Abbreviation}}</div>
          <div mat-line> {{p.Description}} </div>
        </mat-list-item>
      </mat-list>    -->
</div>
