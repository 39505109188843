import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-goals',
  templateUrl: './player-goals.component.html',
  styleUrls: ['./player-goals.component.css']
})
export class PlayerGoalsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
