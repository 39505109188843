<div class="app-page">
<h3>
    <mat-icon>dynamic_form</mat-icon>
    Form settings
</h3>

    <form  [formGroup]="_reactiveForm" *ngIf="this.form || this.mode=='new'">
        <mat-form-field style="width: 600px;" appearance="fill"   class="smalltext"    >
            <mat-label>Name</mat-label>
            <input matInput  maxlength="150" rows="4" cols="50" id="Name" formControlName="Name"/>            
        </mat-form-field>
        <br/>
        <mat-form-field style="width: 600px;" appearance="fill"   class="smalltext"    >
            <mat-label>Description</mat-label>
            <input matInput  maxlength="150" rows="4" cols="50" id="Name" formControlName="Description"/>            
        </mat-form-field>
        <input matInput type="hidden" id="FormId" formControlName="FormId" disabled/>
        <br/>
        <mat-checkbox  formControlName="AllowCreate">Allow users to create new submissions</mat-checkbox><br/>
        <mat-checkbox   formControlName="MultiEdit">Allow multiple users to change the form (not only the creator)</mat-checkbox><br/>
        <mat-checkbox   formControlName="Visible">Form is visible</mat-checkbox>


            <h3>Visible for Teams </h3>
           
            <ng-container formArrayName="TeamForms">    
                <ng-container *ngFor="let team of this.TeamForms.controls; let i = index;">
                    <div class="sectionframe" [formGroupName]="i"  style="margin-left: 20px; display: flex; flex-direction: row;">
                       <!--  <mat-form-field style="width: 600px;" appearance="fill"   class="smalltext" disabled   >
                            <mat-label>Name</mat-label>
                            <input matInput  maxlength="150" rows="4" cols="50" id="TeamName" formControlName="TeamName" disabled/>
                            
                        </mat-form-field> -->
                        <div style="width: 100px;">
                            <img src="../../assets/img/shield.png" />
                            {{team.get('TeamName').value}}
                            <input matInput type="hidden"  maxlength="150" rows="4" cols="50" id="TeamName" formControlName="TeamName" disabled/>
                            <input matInput type="hidden"  maxlength="150" rows="4" cols="50" id="TeamId" formControlName="TeamId" disabled/>
                        </div>
                        <button mat-button  (click)="removeTeam(team.get('TeamId').value)">
                            <mat-icon>delete</mat-icon>REMOVE
                        </button>
                    </div>
                </ng-container>
            </ng-container> 
            <button mat-raised-button (click)="addTeam()">
                <mat-icon>add</mat-icon>ADD TEAM
            </button>

    </form>
    <br/>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!this._reactiveForm.valid">SAVE</button>
    <button mat-button (click)="back()">CANCEL</button>

<!--     <div>
        {{this._reactiveForm.value | json}}
    </div> -->
</div>
