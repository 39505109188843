
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerService } from 'src/app/shared/player.service';
import { User } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { Objective } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';

@Component({
  selector: 'app-new-objective-dialog',
  templateUrl: './new-objective-dialog.component.html',
  styleUrls: ['./new-objective-dialog.component.css']
})
export class NewObjectiveDialogComponent implements OnInit {

  objectiveForm;
  users: User[];
  Objectives: Objective[];

  constructor(private fb: UntypedFormBuilder,
     private playerService: PlayerService,
     private objectiveService: ObjectivesService,
     public dialogRef: MatDialogRef<NewObjectiveDialogComponent>,
     @Inject(MAT_DIALOG_DATA) private data: { 
      ObjectiveLevel: string,
      TeamId: string, 
      ParentObjectiveId: string,
      Objectives: Objective[] },
     private userService: UserService) { }

  ngOnInit(): void {
    console.log(this.data.Objectives);
    console.log(this.data.ObjectiveLevel);
    console.log(this.data.TeamId);

    //Get objectives that can be parent objectives
    this.Objectives= this.data.Objectives;
    if(this.Objectives==null && this.data.ObjectiveLevel=="ORGANIZATION"){
      this.objectiveService.getObjectivesForOrg().subscribe(objs=>{
        this.Objectives = objs;
      });
    }else if(this.Objectives==null && this.data.ObjectiveLevel=="TEAM" && this.data.TeamId!=null){
      this.objectiveService.getObjectivesForOrg().subscribe(objs=>{
        this.Objectives = objs;
      });
    }else if(this.Objectives==null && this.data.ObjectiveLevel=="INDIVIDUAL" && this.data.TeamId!=null){
      this.objectiveService.getObjectivesForTeam(this.data.TeamId, "TEAM").subscribe(objs=>{
        this.Objectives = objs;
      });
    }

    this.objectiveForm = this.fb.group({
      ObjectiveId: [''],
      ParentObjectiveId: [this.data.ParentObjectiveId],
      ObjectiveLevel: [this.data.ObjectiveLevel],
      TeamId: [this.data.TeamId],
      Owner: ['', [Validators.required]],
      Title: ['',Validators.required],
      Description: ['',Validators.required],      
      StartDate: [new Date(),Validators.required],
      TargetDate: ['',Validators.required],

      
    });

    this.userService.getAllUsers().subscribe(data=>{
      this.users =  data as User[];
    });

   


  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.objectiveForm.value);
    console.log(this.objectiveForm.valid);
    this.objectiveService.addObjective(this.objectiveForm.value).subscribe(res=>{
      this.dialogRef.close();
    });
  }

}
