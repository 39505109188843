<mat-card appearance="outlined" style="max-width: 340px;  margin: 10px;"  >
    <table>
        <tr>
            <td><img style="height: 48px;"></td>
            <td>
                    <span style="font-size: large;"> this.Report.PlayerLastName,  this.Report.PlayerFirstName</span>
                    <div style="font-size: medium; color: gray; font-size: 12px; ">
                        <span >
                            Goaltender
                        </span>
                        <span  >
                            Defender
                        </span>
                        <span >
                            Forward
                        </span>
                        <span >
                            
                             this.Report.DateOfBirth | date:'yyyy-MM-dd' (this.Report.PlayerAgeYear + ' Years ' + this.Report.PlayerAgeMonth + ' Months')
                            
                        </span>
                        <br>
                        <span>
                            this.Report.ReportDivision + "/" + this.Report.ReportTeamName<br/>
                        </span>
                        <span >
                            this.Report.PlayerCurrentDivision + "/" + this.Report.PlayerCurrentTeam<br/>
                        </span>

                    </div>
                    
                 



                    
            </td>
        </tr>
    </table>
     
<mat-card-header>
    <!-- <img mat-card-avatar  [src]="'../../assets/img/' + this.Report.PlayerCurrentTeamIcon"     > -->
   
    <mat-card-title>
      
    </mat-card-title>
  


</mat-card-header>
<mat-card-content style="margin-bottom: 0px;" >
    <mat-divider></mat-divider>
        
        
        <div style="font-size: 14px; padding: 5px;;">
            this.Report.Text
        </div>
    <mat-divider></mat-divider>
       
    <mat-divider></mat-divider>


    <div style="font-family: verdana,arial,sans-serif; font-size: x-small;  text-align: left; vertical-align: top;  padding: 0; white-space: normal; margin: 0; color: white;  ">    
        <table>
            <tr>
                <td>
                        <mat-icon style="color: orange;font-size: 18px;">person</mat-icon>
                </td>
                <td>
                        <div style="font-size: 10px;">this.Report.CreatedByLastName, this.Report.CreatedByFirstName</div>
                        <span style="color: gray;font-size: 10px; color: gray;">this.Report.DateCreated | date:'yyyy-MM-dd HH:mm' </span>
                </td>
            </tr>
            <tr >
                <td>  
                  
                        <mat-icon style="color:orange;font-size: 18px;">sports_hockey</mat-icon>
                       
                  
                </td>
                <td>  
                    <div style="font-size: 10px;">
                        this.Report.GameText     <br>
                        <span>this.Report.ReportDivision</span>  
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    
                    <mat-icon style="color: orange; font-size: 18px;"> location_on</mat-icon>
                </td>
                <td>
                    <div style="font-size: 10px;">
                       
                    </div>
                </td>
            </tr>
        </table>    
      
       
        
    </div>


    <mat-divider></mat-divider>



</mat-card-content>
<mat-card-actions>
        <button mat-button  >
            <mat-icon style="font-size: 18px; opacity: 0.6;">
                    rate_review
            </mat-icon>
            Report
        </button>
    <button mat-button style="opacity: 0.6;" ><img style="height: 24px;"   src="../../assets/img/icons8-user-shield-48.png">Player</button>
    <!--<button mat-button style="opacity: 0.6;" (click)="gotoPlayerDashboard()" ><img style="height: 24px;"   src="../../assets/img/icons8-user-shield-48.png">Player</button>
    <button mat-button style="opacity: 0.6;" >
            <mat-icon style="font-size: 18px; opacity: 0.6;">sports_hockey</mat-icon>
        Game
    </button> -->
</mat-card-actions>
</mat-card> 


