
<div style="color: white;margin-left:20px;" class="app-page">
    
        <h2>Current average load and ACWR</h2>
        
    <mat-table mat-table [dataSource]="this.dataSource" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
            <ng-container matColumnDef="JerseyNo">
                <th mat-header-cell *matHeaderCellDef style="width: 30px;"> NO </th>
                <td mat-cell *matCellDef="let element" class="namecell"> {{element.JerseyNo }} </td>
            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="LastName">
                <th mat-header-cell *matHeaderCellDef> PLAYER </th>
                <td mat-cell *matCellDef="let element" class="namecell" style="width: 150px;"> 
                    <a [routerLink]="['/player/details',element.PlayerId]" style="color: white; text-decoration: none; ">
                    {{element.LastName}}, {{element.FirstName}}
                    </a>
                </td>
            </ng-container>
           <!--  <ng-container matColumnDef="FirstName">
                <th mat-header-cell *matHeaderCellDef> PLAYER </th>
                <td mat-cell *matCellDef="let element" class="namecell" style="width: 150px;"> 
                    <a [routerLink]="['/player/details',element.PlayerId]" style="color: white; text-decoration: none; ">
                    {{element.FirstName}}
                    </a>
                </td>
            </ng-container> -->
            <ng-container matColumnDef="AcuteRA">
                <th mat-header-cell *matHeaderCellDef> Acute RA </th>
                <td mat-cell *matCellDef="let element" class="namecell" style="width: 150px;"> 
                    <a [routerLink]="['/player/details',element.PlayerId]" style="color: white; text-decoration: none; ">
                    {{element.AcuteRA}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="ChronicRA">
                <th mat-header-cell *matHeaderCellDef> Chronic RA </th>
                <td mat-cell *matCellDef="let element" class="namecell" style="width: 150px;"> 
                    <a [routerLink]="['/player/details',element.PlayerId]" style="color: white; text-decoration: none; ">
                    {{element.ChronicRA}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="ACWR_RA">
                <th mat-header-cell *matHeaderCellDef> ACWR RA </th>
                <td mat-cell *matCellDef="let element" class="namecell" style="width: 150px;"> 
                    <a [routerLink]="['/player/details',element.PlayerId]" style="color: white; text-decoration: none; ">
                    {{element.ACWR_RA}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="AcuteEWMA">
                <th mat-header-cell *matHeaderCellDef> Acute EWMA </th>
                <td mat-cell *matCellDef="let element" class="namecell" style="width: 150px;"> 
                    <a [routerLink]="['/player/details',element.PlayerId]" style="color: white; text-decoration: none; ">
                    {{element.AcuteEWMA}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="ChronicEWMA">
                <th mat-header-cell *matHeaderCellDef> Chronic EWMA </th>
                <td mat-cell *matCellDef="let element" class="namecell" style="width: 150px;"> 
                    <a [routerLink]="['/player/details',element.PlayerId]" style="color: white; text-decoration: none; ">
                    {{element.ChronicEWMA}}
                    </a>
                </td>
            </ng-container>
            <ng-container matColumnDef="ACWR_EWMA">
                <th mat-header-cell *matHeaderCellDef> ACWR EWMA </th>
                <td mat-cell *matCellDef="let element" class="namecell" style="width: 150px;"> 
                    <a [routerLink]="['/player/details',element.PlayerId]" style="color: white; text-decoration: none; ">
                    {{element.ACWR_EWMA}}
                    </a>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="this.displayedColumns; sticky: true;" sticky></tr>
            <tr mat-row *matRowDef="let row; columns: this.displayedColumns;" style="height: 24px;"></tr>
    </mat-table>
</div>
