<div class="app-page">
  <mat-card appearance="outlined" style="margin: 10px; min-width: 300px; max-width: 500px;margin: auto;">
    <mat-card-header style="border-bottom: orange solid 1px;">
      <img mat-card-avatar src="../../assets/img/icons8-user-shield-48.png">
      <mat-card-title>{{this.PlayerName}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="testResultsForm" (ngSubmit)="save()">
            <br/>
            <mat-form-field>
                <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="PerformedDate" required >
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                
              </mat-form-field>
              &nbsp;
              <mat-form-field>
                  <input matInput type="time" placeholder="Choose a time" formControlName="PerformedTime"  required>
                </mat-form-field>
          <div formArrayName="testsarray">
              <table>
                
                  <tr>
                    <td colspan="2">
                      <mat-divider></mat-divider>
                      <h2>Enter test results</h2>
                    </td>
                  </tr>
                  <tr  *ngFor="let test of Tests.controls; let i = index" [formGroupName]="i"  >
                    <td>{{this._profile.Tests[i].Name}}</td>          
                    <td>
                        <mat-form-field appearance="fill" class="smallfield" >
                            <mat-label>Result</mat-label>
                            <input matInput placeholder="" formControlName="Result">                
                            <mat-hint>{{ this._profile.Tests[i].ResultUnit }}</mat-hint>
                          </mat-form-field>
                    </td>
                </tr>
              </table>
          </div>
          <button mat-raised-button color="primary" type="submit" [disabled]="!this.testResultsForm.valid">SAVE</button>
          <button mat-button type="button" (click)="cancel()">CANCEL</button>
        </form>
    </mat-card-content>
    <mat-card-actions>
     
    </mat-card-actions>  
  </mat-card>
</div>

