
    <div style="display: flex; align-content: center; justify-content: center; margin-top: 100px; margin-left: 20px;">
    <div>
        <h1 >Reset password</h1>
        <div>
                
            Password requirements are <br/>
            -Minimum length 6 characters, at least one upper case and one lower case letter, at least one numeric character, at least one none non-letter or digit character.
            
        </div><br/>
       
        <div *ngIf="showSuccess" >
            Your password has been reset. Please <a [routerLink]="['/login']" style="color: orangered;"> click here to log in. </a>
        </div>
        <form [formGroup]="resetPasswordForm" *ngIf="!showSuccess" autocomplete="off" novalidate (ngSubmit)="resetPassword(resetPasswordForm.value)">
            <mat-form-field appearance="outline" style="width: 350px;">
                <mat-label>Enter username</mat-label>
              
                <input matInput type="text" id="username" formControlName="username" placeholder="UserName" required>
                <mat-icon matSuffix>account_box</mat-icon>
                
              </mat-form-field>
              <br/>
              <mat-form-field appearance="outline" style="width: 350px;">
                <mat-label>New password</mat-label>
              
                <input matInput type="password" id="password" formControlName="password" placeholder="New password" required>
                <mat-icon matSuffix>account_box</mat-icon>
                
              </mat-form-field>
              <br/>
              <mat-form-field appearance="outline" style="width: 350px;">
                <mat-label>Confirm password</mat-label>
              
                <input matInput type="password" id="confirm" formControlName="confirm" placeholder="Confirm password" required>
                <mat-icon matSuffix>account_box</mat-icon>
                
              </mat-form-field>
              <br/>
           <!--  <div >
                <label for="username" >Username:</label>


                <div>
                    <input type="text" id="username" formControlName="username" />
                </div>
                <div>
                    <em *ngIf="validateControl('password') && hasError('password', 'required')">Password is required</em>
                </div>
            </div>
            <div >
                <label for="password" >Password:</label>
                <div>
                    <input type="password" id="password" formControlName="password" />
                </div>
                <div>
                    <em *ngIf="validateControl('password') && hasError('password', 'required')">Password is required</em>
                </div>
            </div>
            <div class="mb-3 row">
                <label for="confirm" >Confirm Password:</label>
                <div>
                    <input type="password" id="confirm" formControlName="confirm"/>
                </div>
                <div>
                    <em *ngIf="validateControl('confirm') && hasError('confirm', 'required')">Confirmation is required</em>
                    <em *ngIf="hasError('confirm', 'mustMatch')">Passwords must match</em>
                </div>
            </div> -->
            <br>
            <div >
                <div >          
                    <button mat-raised-button type="submit" class="btn btn-info" [disabled]="!resetPasswordForm.valid" color="primary">SET PASSWORD</button>
                </div>
            </div>
            <div *ngIf="this.showError" >
                <br/>
                <!-- <span style="color: red; font-family: Verdana; " [innerHTML]="errorMessage"></span><br/> -->
                <div style="color: red; font-family: Verdana; white-space: wrap; " >
                    Password could not be set. Check your input and please try again.<br/>
                </div><br/>
                <div>
                    Reasons could be invalid username, password not matching confirm password, to short or simple password, expired or used recovery mail link.
                    <br/>
                </div>
                <br/>
            </div>
        </form>
    </div>
</div>
