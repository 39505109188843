<div class="app-page" style="margin-left: 10px;color: white; ">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="this.isBusy()"></mat-progress-bar> -->
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <div style="display: flex;flex-direction: row; margin-right: 30px;">
            <!-- <img src="../../assets/img/icons8-user-shield-48.png" style="height: 48px;"/><br/> -->
            <mat-icon style="font-size: 70px; height: 100px; width: 70px;">account_circle</mat-icon>
            <div>
                <span style="font-size: x-large; ">{{this._playerData?.FlowPlayer.FirstName }}
                    {{_playerData?.FlowPlayer.LastName}}</span><br />
                <span style="font-size: small; ">
                    {{this._playerData?.FlowPlayer.DateOfBirth | date: 'yyy-MM-dd'}}
                </span>
            </div>

        </div>
        <div style="font-size: small;">
            <span style=" font-size: medium;">General</span>
            <table>
                <tr>
                    <td style="width: 70px;">Age:</td>
                    <td>
                        {{this._playerData?.PlayerAgeYear }} years {{this._playerData?.PlayerAgeMonth }} months
                    </td>
                    <td></td>
                    <td style="width: 70px;">Height:</td>
                    <td>{{this._playerData?.FlowPlayer.Height }} cm</td>
                </tr>
                <tr>
                    <td>Position:</td>
                    <td></td>
                    <td></td>
                    <td>Weight:</td>
                    <td>{{this._playerData?.FlowPlayer.Weight }} kg</td>
                </tr>

                <tr *ngIf="this._playerData?.Module_Tags">
                    <td>Tags:</td>
                    <td>
                        <div style="display:flex; align-items: baseline;">
                            <div *ngFor="let tag of this._playerData.tags" [ngClass]="tag.TagColor"
                                (click)="openShowTagDialog();" style="height: 20px;">
                                {{tag.TagText}}
                            </div>
                            <button mat-icon-button aria-label="Add" (click)="addTag()">
                                <mat-icon>add</mat-icon>
                            </button>
                            <button mat-icon-button aria-label="Edit" (click)="openShowTagDialog();">
                                <mat-icon>remove</mat-icon>
                            </button>

                        </div>
                    </td>
                    <td>

                    </td>

                </tr>

            </table>



        </div>
    </div>


    <div *ngIf="this._playerData">



        <div class="mat-tab-body-wrapper"
            style=" display: flex; flex-wrap: wrap; align-content: flex-start; height: 60vh;">

            <div>
                <label>RANKING LISTS</label>
                <app-player-ranking-list-entry *ngFor="let list of this._playerData.playerplayerlist" [List]="list">
                </app-player-ranking-list-entry>
                <div *ngIf="this._playerData?.playerplayerlist.length==0" STYLE="color: gray;;">
                    NOT IN ANY LIST VISIBLE TO YOU
                </div>
                <br />
            </div>


            <div *ngIf="this._playerData.flowitem.length==0" style="color: gray;">
                <br />
                NO REPORTS FOUND
            </div>
            <div style="width: 100%;">
                <app-chart-player-projection *ngIf="this.ProjectionTexts && this._playerData.flowitem.length>0"
                    [Reports]="this._playerData.flowitem" [Ticks]="this.ProjectionTexts"
                    [Ticks2]="this.tickValues"></app-chart-player-projection>
            </div>
            <div style=" display: flex; flex-wrap: wrap; align-content: flex-start; ">
                <ng-container *ngFor="let item of this._playerData.flowitem">
                    <app-report-card-player [Report]="item"
                        *ngIf="item.FlowItemType=='SCREPORT'"></app-report-card-player>
                    <app-note-card-player [Note]="item" *ngIf="item.FlowItemType=='PLAYERNOTE'"></app-note-card-player>
                </ng-container>
            </div>


        </div>



    </div>