import { Component, OnInit } from '@angular/core';
import { GameService } from '../../services/game.service';
import { GamePageViewModel } from '../../models/GameModels';
import { MatDialog } from '@angular/material/dialog';
import { DivisionSelectDialogComponent } from '../../components/division-select-dialog/division-select-dialog.component';
import { AddToScheduleDialogComponent } from '../../components/add-to-schedule-dialog/add-to-schedule-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ScheduleService } from '../../services/schedule.service';
import { SetGameFilterDialogComponent } from '../../game/set-game-filter/set-game-filter-dialog.component';

@Component({
    selector: 'app-game-list',
    templateUrl: './game-list.component.html',
    styleUrls: ['./game-list.component.css'],
    standalone: false
})
export class GameListComponent implements OnInit {
  isBusy: boolean = false;
  _pageData: GamePageViewModel;
  pastGamesActive: boolean = false;

  constructor(
    public gameService: GameService,
    private scheduleService: ScheduleService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.isBusy = true;
    try {
      this.pastGamesActive = false;
      this.gameService.getGames().subscribe((data) => {
        this._pageData = data;
        this.isBusy = false;
      });
    } catch (error) {
      console.error(error);
      this.isBusy = false;
    }
  }

  getPastGames() {
    this.isBusy = true;
    try {
      this.pastGamesActive = true;
      this.gameService.getPastGames().subscribe((data) => {
        this._pageData = data;
        this.isBusy = false;
      });
    } catch (error) {
      console.error(error);
      this.isBusy = false;
    }
  }

  openSetGameFilterDialog(): void {
    const dialogRef = this.dialog.open(SetGameFilterDialogComponent,
      {
        width: '600px' // Set the desired width here
      }
      
    );

    dialogRef.componentInstance.divisionChanged.subscribe(() => {
      this.getData();
    });

    dialogRef.componentInstance.showAllDivisionChanged.subscribe(() => {
      this.getData();
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData();
      }
    });
  }
  


  selectDivisions() {
    const dialogRef = this.dialog.open(DivisionSelectDialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (this.pastGamesActive) {
        this.getPastGames();
      } else {
        this.getData();
      }
    });
  }

  addToSchedule(HomeTeam: string, AwayTeam: string) {
    const dialogRef = this.dialog.open(AddToScheduleDialogComponent, {
      width: '250px',
      data: { HomeTeam, AwayTeam }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.openSnackBar("EVENT ADDED", "");
      if (this.pastGamesActive) {
        this.getPastGames();
      } else {
        this.getData();
      }
    });
  }

  removeFromSchedule(GameId: string) {
    this.openSnackBar("EVENT REMOVED", "");
    this.scheduleService.removeEventFromSchedule(GameId).subscribe((res) => {
      if (this.pastGamesActive) {
        this.getPastGames();
      } else {
        this.getData();
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}

