import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-game-header-full',
  templateUrl: './game-header-full.component.html',
  styleUrls: ['./game-header-full.component.css']
})
export class GameHeaderFullComponent implements OnInit {

  @Input() Game;
  @Input() Division;
  constructor() { }

  ngOnInit() {
  }

}
