import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { Configuration } from "./configurationmodel";

@Injectable({
  providedIn: "root"
})

export class ConfigurationLoaderService {
  private readonly CONFIGURATION_URL = "/assets/config/config.json";
  private _configuration: Configuration;

  constructor(private _http: HttpClient) {}

  public loadConfiguration() {
    return this._http
      .get(this.CONFIGURATION_URL)
      .toPromise()
      .then((configuration: Configuration) => {
        this._configuration = configuration;
        return configuration;
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  getConfiguration() {
    return this._configuration;
  }
}
