import { NumberSymbol } from '@angular/common';
import { PlayerViewModel } from 'src/app/shared/models/player.model';

export class Player{
    LifelineScoutingId: string;
    ShootId: number;
    PositionId: number;
    FirstName: string;
    LastName: string;
    DateOfBirth: Date;
    Weight: number;
    Height: number;
    ProfileImage?: string;

}

export class PlayerListViewModel{
    LifelineScoutingId: string;
    PlayerId: string;
    Player: Player;
    PlayerView: PlayerViewModel;
    DivisionName: string;
    TeamName: string;
    IsFavourite: boolean;
    Tags: PlayerTagPlayerViewModel;
    ReportCount: number;
    NoteCount: number;

}

export class PlayerTagPlayerViewModel{
    TagId: string;
    TagText: string;
    TagColor: string;
    UserId: string;
    UserLastName: string;
    UserFirstName: string;
    PlayerId: string;
    PlayerLastName: string;
    PlayerFirstName: string;
    OrganizationId: number;
    CreatedDate: Date;
}

export class PlayerTagViewModel{
    TagId: string;
    TagText: string;
    TagColor: string;
    PlayerTagsSecurity: PlayerTagSecurity[];
    PlayerTagsLanguage: PlayerTagsLanguage[];
}

export class PlayerTagsLanguage
{
    TagId: string;
    LanguageId: string;
    Text: string;

}

export class PlayerTagSecurity{
    TagId: string;
    UserId: string;
    LastName: string;
    FirstName: string;
    Addable: boolean;
    Viewable: boolean;
}

export class PlayerDetailsViewModel{
    Player: Player;
    DivisionName: string;
    TeamName: string;
    TeamIconFile: string;
    /* public IList<PlayerTeam> Teams: number;
    public IList<PlayerTeam> TeamsOrg: number;
    public IList<ReportViewModel> Reports: number;
    public IList<PlayerTagsViewModel> Tags: number; */
    //Agent: Agent;
    NoOfReports: number;
    NoOfNotes: number;

    IsFavourite: boolean;

    PlayerAgeYears: number;
    PlayerAgeMonths: number;
    PlayerAgeDays: number;
    
}

export class PlayerContactModel
{
    OrganizationId: number;
    PlayerId: string;
    ContactId: number;
    Relation: string;
    FirstName: string;
    LastName: string;
    CompanyName: string;
    Email: string;
    WorkPhone: string;
    MobilePhone: string;
    HomePhone: string;

    Addresses: PlayerContactAddressModel[];

}

export class PlayerContactAddressModel
{
    OrganizationId: number;
    PlayerId: string;
    ContactId: number;
    AddressId: number;
    AddressType: string;
    Address1: string;
    Address2: string;
    Address3: string;
    PostCode: string;
    City: string;
    State_Province_County: string;
    Country: string;

}
/* 
export class PlayerPosition{
    PositionId: number;
    LanguageCode: string;
    SportId: Number;
    Text: string;
} */