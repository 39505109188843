<h2 mat-dialog-title>EDIT PLAYER</h2>
<div mat-dialog-content>
  <form [formGroup]="editSeasonPlayerForm" (ngSubmit)="save()">
    
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Player" formControlName="PlayerName">
    </mat-form-field>
    <br/>
    <mat-form-field >
        <input matInput placeholder="Jersey no" formControlName="JerseyNo" [value]="this._JerseyNo"  >                        
    </mat-form-field><br/>
    <br/>
    <mat-form-field appearance="fill">
      <mat-label>Position</mat-label>
      <mat-select formControlName="Position" required [value]="this._PositionId" >
        <mat-option *ngFor="let p of this.positions" [value]="p.id">
          {{p.name}}
        </mat-option>
     
      </mat-select>
<!--       <mat-error *ngIf="animalControl.hasError('required')">Please choose an animal</mat-error> -->

    </mat-form-field><br/>
    <mat-form-field appearance="fill">
      <mat-label>Shoots/glove</mat-label>
      <mat-select formControlName="ShootId" required [value]="this._ShootId" >
        <mat-option *ngFor="let shot of this.shoots"  [value]="shot.id">
          {{shot.name}}
        </mat-option>
    
      
      </mat-select>
<!--       <mat-error *ngIf="animalControl.hasError('required')">Please choose an animal</mat-error> -->

    </mat-form-field><br/>
    <mat-form-field style="width: 350px;">
        <input matInput placeholder="Enter image path" formControlName="ProfileImage" [value]="this._ProfileImage" id="ProfileImage"  >                        
    </mat-form-field><br/>
    
    <!--   <mat-checkbox formControlName="AllTeams">All teams</mat-checkbox>      
    <br/>
   
      <mat-checkbox formControlName="AllSeasons">All seasons</mat-checkbox> -->
      
    
      
  </form>
  </div>  
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="save()">SAVE</button>
   <button mat-button (click)="onNoClick()">CANCEL</button>
 
 </div>