import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatLabel } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';

import {SharedModuleModule} from '../../shared-module/shared-module.module';
import { TestService } from '../test.service';
import { Profile } from 'selenium-webdriver/firefox';
import { PlayerPosition } from '../models/player.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-profile-dialog',
  templateUrl: './select-profile-dialog.component.html',
  styleUrls: ['./select-profile-dialog.component.css']
})
export class SelectProfileDialogComponent implements OnInit {
  
  _profiles: Profile[];
  _positions: PlayerPosition[];
  _teamId: string;


  profileId: string;
  PlayerPositionId: string;

  constructor(
    public dialogRef: MatDialogRef<SelectProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public message,
  
    public _testService: TestService) { 
        
        this._teamId = message.teamid;
    }


  ngOnInit(): void {
  
  
    

    //Get profiles
    this._testService.getAllProfiles().subscribe((p : Profile[])=> {
      this._profiles = p;
      this._testService.GetAllPositions(this._teamId).subscribe((pos: PlayerPosition[])=>{
          this._positions = pos;
      });
      //console.log(p);
    })
  }

  onCloseConfirm() {
    this.dialogRef.close({
       profileId: this.profileId ,
       PlayerPositionId: this.PlayerPositionId 
       
    });
   
  }

    onNoClick(): void {
      //console.log(this.profileId);
     this.dialogRef.close();
    }

}
