<h2 mat-dialog-title style="font-family: Roboto;">ASSING PROGRAM</h2>
<div mat-dialog-content>

  <form  [formGroup]="assignedPgmForm" (ngSubmit)="save()" autocomplete="off">
    <mat-form-field appearance="fill"  style="width: 400px;">
        <mat-label>Select program</mat-label>
        <mat-select formControlName="ProgramId">
            <mat-option>-</mat-option>
            <mat-option *ngFor="let pgm of programs" value="pgm.ProgramId">{{pgm.Name}} ({{pgm.NoOfWeeks}} week(s)</mat-option>            
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill"  style="width: 400px;">
      <mat-label>Program assignment name</mat-label>
      <input matInput placeholder="Enter name" formControlName="Name" id="Name" /> 
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <!-- <mat-hint>Hint</mat-hint> -->
    </mat-form-field>
    <br/>
    <mat-form-field appearance="fill">
        <mat-label>Choose a start date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="StartDate">
        <mat-hint>YYYY/MM/DD</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    <br/>
    <mat-form-field appearance="fill">
        <mat-label>Repeat program</mat-label>
        <input matInput type="number" value="1"  formControlName="Repeat" id="Repeat" />
    </mat-form-field>
    <!-- <mat-form-field appearance="fill" style="width: 400px;">
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Describe how to perform the exercise" formControlName="Description" id="Description"></textarea>  
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <!-- <mat-hint>Hint</mat-hint> -->

    <br/> 
   
   
    <button mat-button color="primary"  type="submit" [disabled]="!assignedPgmForm.valid">CREATE</button>

    <button type="button" mat-button  (click)="onCancel()">CANCEL</button>
    <!-- <div  *ngFor="let Video of this.myVideos; let i=index">  
      <video width="270" height="220"   controls disabled="true"  (click)="toggleVideo()" #videoPlayer>   
        <source [src]="Video.URL" type="video/mp4">   
      </video>  
    </div>   -->
      
    

    
  </form>
  <!--     
  <div>
    {{ this.equipmentForm.value | json}}
  </div> -->
    
</div>