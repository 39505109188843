import { FlexibleConnectedPositionStrategy  } from '@angular/cdk/overlay';
/* import { StringMap } from '@angular/compiler/src/compiler_facade_interface'; */
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, FormControlName } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import { GameDetailsViewModel } from 'src/app/scouting/models/GameModels';
import { GameService } from 'src/app/scouting/services/game.service';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { PlayerService } from 'src/app/shared/player.service';
import { Season } from 'src/app/shared/team.model';
import { TeamService } from 'src/app/shared/team.service';
import { TestService } from 'src/app/shared/test.service';
import { UserService } from 'src/app/shared/user.service';
import { GameStatisticsViewViewModel } from 'src/app/stats/models/statistics.model';

@Component({
  selector: 'app-game-statistics-table',
  templateUrl: './game-statistics-table.component.html',
  styleUrls: ['./game-statistics-table.component.css']
})
export class GameStatisticsTableComponent implements OnInit {

  TeamId: string;
  GameId: string;
  ViewId: string;
  isBusy: boolean;
  GameEvent: GameDetailsViewModel;
  isStatisticsEditor: boolean;
  public _view: GameStatisticsViewViewModel;
  public Views: GameStatisticsViewViewModel[];
  public displayedColumns: string[] = [];
  public displayedNames: string[] = [];
  public groupColumns: string[] = [];
  public groupColumnsBgColor: string[] = [];
  public displayedColumnsTextColor: string[] = [];
  public displayedColumnsBgColor: string[] = [];
  public displayedColumnsAbbreviationTextColor: string[] = [];
  public displayedColumnsAbbreviationBgColor: string[] = [];
  
  public dataSource = new MatTableDataSource<string>();

  viewFormControl = new UntypedFormControl('');

  constructor(public _route: ActivatedRoute, 
    public _plyrService: PlayerService,
    public _statsService: GameStatisticsService,
    public _teamService: TeamService,
    public _gameService: GameService,
    public _userService: UserService,
    public _globals: Globals) 
  { 
    this.isBusy=true;

    this.isStatisticsEditor = this._userService.roleMatch(["Statistics_Admin", "StatisticsAdmin", "StatisticsEditor", "Statistics_Editor"]);

    this._route.params.subscribe(params => {
      this.TeamId = params['TeamId'];
      this.GameId = params['GameId'];
      this.ViewId = params['ViewId'];
      //console.log("Test");
      _gameService.getGameDetailsSimple(this.GameId).subscribe(g=>{
        //console.log(g);
        this.GameEvent = g;
      });

     

      _statsService.getAllViews().subscribe(views=>{
        //console.log(views);
        this.Views = views as GameStatisticsViewViewModel[];
        
        this._view = this.Views[0];        
        this.displayedColumns.push('Name');
        //this.displayedNames.push('Name');      
        //this.displayedColumnsBgColor.push('');
        //this.displayedColumnsTextColor.push('');
        //this.displayedColumnsAbbreviationTextColor.push('');
        //this.displayedColumnsAbbreviationBgColor.push('');
        this.groupColumns.push('Name');        

        this.viewFormControl.setValue(this._view.GameStatisticsViewId);

        for(let group of this._view.Groups){
          this.groupColumns.push("GroupHeader_" + group.GameStatisticsGroupId);
          this.groupColumnsBgColor.push(group.BgColor);
       
          for(let param of group.Parameters){
            if(!this.displayedColumns.includes(group.GameStatisticsGroupId + '_' + param.Abbreviation)){
              this.displayedColumns.push(group.GameStatisticsGroupId + '_' + param.Abbreviation);
              if(param.Description=='')
                this.displayedNames.push(param.Abbreviation);
              else
                this.displayedNames.push(param.Description);
              this.displayedColumnsBgColor.push(group.BgColor);
              //this.displayedColumnsTextColor.push(group.TextColorValue);
              //switch()
              this.displayedColumnsAbbreviationTextColor.push(group.TextColorHeader);
              this.displayedColumnsAbbreviationBgColor.push(group.BgColorHeader);
              
              
            }
             
          }
          for(let param of group.ParametersCalculated){
            if(!this.displayedColumns.includes(group.GameStatisticsGroupId + '_' + param.Abbreviation)){
              this.displayedColumns.push(group.GameStatisticsGroupId + '_' + param.Abbreviation);
              if(param.Name=='' || param.Name==null)
                this.displayedNames.push(param.Abbreviation);
              else
                this.displayedNames.push(param.Name);
              this.displayedColumnsBgColor.push(group.BgColor);
              this.displayedColumnsTextColor.push(group.TextColorValue);
              this.displayedColumnsAbbreviationTextColor.push(group.TextColorHeader);
              this.displayedColumnsAbbreviationBgColor.push(group.BgColorHeader);
            }
             
          }
        }
        //console.log(this.displayedColumns);


        this._statsService.getStatisticsForGameAndTeam(this.GameId, this.TeamId, this.Views[0].GameStatisticsViewId).subscribe(stats=>{
          //console.log(stats);
          this.dataSource = stats as MatTableDataSource<string>;
        });
       
      });



    });
  }




  getRankingColor(Ranking: string){
    //console.log(Ranking);

    if(Ranking==='1')
      return "#ff3a2b";
    if(Ranking==='2')
      return "#FFC107";
    if(Ranking==='3')
      return "limegreen";
    if(Ranking==='4')
      return "#00ff00";
    if(Ranking==='5')
      return "rgb(22, 220, 255)";

    return "";
  }

  ngOnInit() {
    this.isBusy=false;
   
  }

  importFromSwehockey(){
    this.isBusy=true;
    this._statsService.getGameStatsFromSweHockey(this.GameId, this.TeamId).subscribe(s=>{
      //console.log("SweHockey import done" + s);
      this.refreshData();
      this.isBusy=false;
    })
    
  }

  refreshData(){
    console.log(this.ViewId)
    this.displayedColumns=[];
    this.groupColumns = [];
    this.groupColumnsBgColor = [];
    this.displayedColumnsTextColor = [];
    this.displayedColumnsBgColor = [];
    this.displayedColumnsAbbreviationTextColor = [];
    this.displayedColumnsAbbreviationBgColor = [];
    this.displayedNames = [];
    this._statsService.getAllViews().subscribe(views=>{
      //console.log(views);
      this.Views = views as GameStatisticsViewViewModel[];
      
      
      this.Views.forEach((prof,i)=>{
        if(prof.GameStatisticsViewId==this.ViewId)
          this._view=prof;
      });
      this.displayedColumns.push('Name');
     /*  this.displayedNames.push('Name');      
      this.displayedColumnsBgColor.push('');
      this.displayedColumnsTextColor.push('');
      this.displayedColumnsAbbreviationTextColor.push('');
      this.displayedColumnsAbbreviationBgColor.push(''); */
      this.groupColumns.push('Name');        

      for(let group of this._view.Groups){
        this.groupColumns.push("GroupHeader_" + group.GameStatisticsGroupId);
        this.groupColumnsBgColor.push(group.BgColor);
     
        for(let param of group.Parameters){
          if(!this.displayedColumns.includes(group.GameStatisticsGroupId + '_' + param.Abbreviation)){
            this.displayedColumns.push(group.GameStatisticsGroupId + '_' + param.Abbreviation);
            this.displayedNames.push(param.Abbreviation);
            this.displayedColumnsBgColor.push(group.BgColor);
            this.displayedColumnsTextColor.push(group.TextColorValue);
            this.displayedColumnsAbbreviationTextColor.push(group.TextColorHeader);
            this.displayedColumnsAbbreviationBgColor.push(group.BgColorHeader);
            
            
          }
           
        }
        for(let param of group.ParametersCalculated){
          if(!this.displayedColumns.includes(group.GameStatisticsGroupId + '_' + param.Abbreviation)){
            this.displayedColumns.push(group.GameStatisticsGroupId + '_' + param.Abbreviation);
            this.displayedNames.push(param.Abbreviation);
            this.displayedColumnsBgColor.push(group.BgColor);
            this.displayedColumnsTextColor.push(group.TextColorValue);
            this.displayedColumnsAbbreviationTextColor.push(group.TextColorHeader);
            this.displayedColumnsAbbreviationBgColor.push(group.BgColorHeader);
          }
           
        }
      }
      //console.log(this.displayedColumns);


      this._statsService.getStatisticsForGameAndTeam(this.GameId, this.TeamId, this._view.GameStatisticsViewId).subscribe(stats=>{
        this.dataSource = stats as MatTableDataSource<string>;
      });
     
    });

  }

}
