import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/baseservice';
import { Observable } from 'rxjs';
import { GamePageViewModel, GameDetailsViewModel, DivisionGameFilterFlatModel } from '../models/GameModels';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonGameTickerResult } from 'src/app/shared/models/ExternalSourceModels';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';
import { Season } from 'src/app/shared/team.model';

@Injectable({
  providedIn: 'root'
})
export class GameService extends BaseService {

   constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
      super();
     }

   getGames(): Observable<GamePageViewModel>{

    let ret = this.http.get<GamePageViewModel>(this.rootUrl + '/api/games/filtered' );
    return ret;
   }


   getGamesbydate(): Observable<GamePageViewModel>{

      let ret = this.http.get<GamePageViewModel>(this.rootUrl + '/api/games/2022-12-12/2023-12-12' );
      return ret;
     }
   getPastGames(): Observable<GamePageViewModel>{

      let ret = this.http.get<GamePageViewModel>(this.rootUrl + '/api/gamespast/filtered' );
      return ret;
     }

   getGameDetails(GameId: string): Observable<GameDetailsViewModel>{

    let ret = this.http.get<GameDetailsViewModel>(this.rootUrl + '/api/game/' + GameId );
    return ret;
   }

   getGameDetailsSimple(GameId: string): Observable<GameDetailsViewModel>{

      let ret = this.http.get<GameDetailsViewModel>(this.rootUrl + '/api/game/simple/' + GameId );
      return ret;
     }


   getGameStatistics(GameId: string): Observable<GameDetailsViewModel>{
    let ret = this.http.get<GameDetailsViewModel>(this.rootUrl + '/api/game/' + GameId + '/GetStatistics' );
    return ret;
   }

   getExternalGameStatistics(GameId:string){
      let ret = this.http.get<JsonGameTickerResult>(this.rootUrl + '/api/game/' + GameId + '/ImportStatistics')
      return ret;
   }

   getDivisionFilter(){
    let ret = this.http.get<DivisionGameFilterFlatModel[]>(this.rootUrl + '/api/game/filter')
    return ret;
   }

   setDivisionFilter( DivisionIds: string[]){
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json'});
    return this.http.post(this.rootUrl + '/api/game/filter', DivisionIds, { headers: reqHeader });

   }
   getGameRankingsForPlayerAndSeason_GoogleCharts(PlayerId: string, SeasonId: string): Observable<any>{
      return  this.http.get<any>(this.rootUrl + '/api/load/player/' + PlayerId + '/' + SeasonId + '/gameranking/chartdata');
     }

   getGameRankingsForPlayer_GoogleCharts(PlayerId: string): Observable<any>{
    return  this.http.get<any>(this.rootUrl + '/api/load/player/' + PlayerId + '/gameranking/chartdata');
   }
/*    getGamesParameterGroupForPlayer_GoogleCharts(PlayerId: string, ParameterGroupId: string): Observable<any>{
      //Get ganes with parametergroup values to generate chart for parameters in group over a time period
      return  this.http.get<any>(this.rootUrl + '/api/load/player/' + PlayerId + '/games/parametergroup/' + ParameterGroupId + '/parameters/chartdata');
   }
 */
   getGamesParameterGroupForPlayerAndSeason_GoogleCharts(PlayerId: string, ParameterGroupId: number, SeasonId: string): Observable<any>{
      //Get ganes with parametergroup values to generate chart for parameters in group over a time period
      return  this.http.get<any>(this.rootUrl + '/api/load/player/' + PlayerId + '/' + SeasonId + '/games/parametergroup/' + ParameterGroupId + '/parameters/chartdata');
   }
  
}
