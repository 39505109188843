<div class="app-page">

<mat-card appearance="outlined" STYLE="max-width: 400px; margin: 10px;">
    <mat-card-header>    
      <div mat-card-avatar>
        <i class="material-icons">
              assignment
        </i>
    </div>   
      <mat-card-title>
        
        New profile
      </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form  [formGroup]="profileForm" (ngSubmit)="save()">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput placeholder="Enter name" formControlName="name" id="name" required/> 
     
      </mat-form-field>
     <!--  <mat-form-field>
        <mat-label>
          Välj tester
        </mat-label>
        <mat-select [placeholder]="Test" formControlName="tests">
            <mat-option *ngFor="let test of this._tests" [value]="test.TestId">{{test.Name}}</mat-option>
        </mat-select>


      </mat-form-field> -->

      <!-- <label *ngFor="let test of this._tests;">
        <input formControlName="tests" type="radio" name="tests" [value]="test.TestId" />
        {{test.Name}}
      </label> -->

   <!--    <select formControlName="tests" id="tests">
          <option *ngFor="let test of _tests; let i = index" [value]="_tests[i].TestId">
            {{_tests[i].Name}}
          </option>
        </select> -->
      <h2>Select tests</h2>
    
      <div *ngFor="let testitem of testitems; let i = index" >
        <mat-checkbox 
        (change)="onChange($event)" [value]="testitem.TestId">{{testitem.Name}}</mat-checkbox>
        
      </div>



      <BR/>
      <button mat-raised-button color="primary" type="submit" [disabled]="!profileForm.valid">CREATE</button>
      <button type="button" mat-button (click)="onCancel()">CANCEL</button>
    </form>
    
  </mat-card-content>
  <mat-card-actions></mat-card-actions>
</mat-card>

</div>