<div class="app-page">
    <mat-toolbar style="height: 60px;">
        <h3>INJURY LIST</h3> &nbsp;
        <!--  <mat-form-field style="font-size: 14px;">
           <mat-label>Team</mat-label>
           <mat-select [formControl]="teamFormControl" (selectionChange)="refreshData()" >
             <mat-option *ngFor="let team of _teams" [value]="team">
               {{team.Level}}
             </mat-option>        
           </mat-select>
         </mat-form-field> -->
         <mat-form-field style="font-size: 14px;">
           <mat-label>Season</mat-label>
           <mat-select [formControl]="seasonFormControl"  (selectionChange)="refreshData()">
             <mat-option *ngFor="let season of _seasons"  [value]="season">
               {{season.Name}}
             </mat-option>
             
           </mat-select>
         </mat-form-field>
         <button *ngIf="this._globals.isMedicalEditor" color="primary" mat-button [routerLink]="['/injury/add', '', '', this.TeamId]">       
          <i class="material-icons">local_hospital</i>
           NEW INJURY
            
          </button>
         
         
       </mat-toolbar>

       <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="margin-left: 10px;">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
        <!-- Position Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            
          </th>
          <td mat-cell *matCellDef="let row" style="white-space:nowrap;">

            <button mat-icon-button [routerLink]="'/injury/edit/' + row.InjuryId  " >
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteInjury(row.InjuryId)" >
              <mat-icon>delete</mat-icon>
            </button>
           
          </td>
        </ng-container>
        <ng-container matColumnDef="InjuryId">
          <th mat-header-cell *matHeaderCellDef> Id. </th>
          <td mat-cell *matCellDef="let element"> {{element.InjuryId}} </td>
        </ng-container>
        <ng-container matColumnDef="PlayerId">
            <th mat-header-cell *matHeaderCellDef> PlayerId. </th>
            <td mat-cell *matCellDef="let element"> {{element.PlayerId}} </td>
          </ng-container>
          <ng-container matColumnDef="LastName">
            <th mat-header-cell *matHeaderCellDef> Last name </th>
            <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
          </ng-container>
          <ng-container matColumnDef="FirstName">
            <th mat-header-cell *matHeaderCellDef> First name </th>
            <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
          </ng-container>
          <ng-container matColumnDef="BodyPartName">
            <th mat-header-cell *matHeaderCellDef> BodyPart </th>
            <td mat-cell *matCellDef="let element"> {{element.BodyAreaName}}/{{element.BodyPartName}} </td>
          </ng-container>
          <ng-container matColumnDef="TypeName">
            <th mat-header-cell *matHeaderCellDef> Type </th>
            <td mat-cell *matCellDef="let element"> {{element.TypeName}} </td>
          </ng-container>
          <ng-container matColumnDef="Side">
            <th mat-header-cell *matHeaderCellDef> Side </th>
            <td mat-cell *matCellDef="let element"> {{element.Side}} </td>
          </ng-container>
          <ng-container matColumnDef="InjuryDate">
            <th mat-header-cell *matHeaderCellDef> Injury date </th>
            <td mat-cell *matCellDef="let element"> {{element.InjuryDate | date: 'yyyy-MM-dd'}} </td>
          </ng-container>
          <ng-container matColumnDef="ActualReturnDate">
            <th mat-header-cell *matHeaderCellDef> Actual return </th>
            <td mat-cell *matCellDef="let element"> {{element.ActualReturnDate | date: 'yyyy-MM-dd'}} </td>
          </ng-container>
          <ng-container matColumnDef="EstimatedReturnDate">
            <th mat-header-cell *matHeaderCellDef> Estimated return </th>
            <td mat-cell *matCellDef="let element"> {{element.EstimatedReturnDate | date: 'yyyy-MM-dd'}} </td>
          </ng-container>
          <ng-container matColumnDef="ConditionName">
            <th mat-header-cell *matHeaderCellDef> Condition </th>
            <td mat-cell *matCellDef="let element"> {{element.ConditionName }} </td>
          </ng-container>
          <ng-container matColumnDef="LocationName">
            <th mat-header-cell *matHeaderCellDef> Location </th>
            <td mat-cell *matCellDef="let element"> {{element.LocationName }} </td>
          </ng-container>
      
       
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 12px;;"></tr>
        
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20, 50]"   [pageSize]="10" showFirstLastButtons style="margin-left: 10px;"></mat-paginator>
       
</div>