<h2 mat-dialog-title>Edit test</h2>
<div mat-dialog-content>

    <form *ngIf="testForm" [formGroup]="testForm" (ngSubmit)="onSubmit()">
         
            <mat-form-field class="example-full-width">
                <input matInput placeholder="Name" formControlName="Name">
            </mat-form-field>


            <mat-form-field class="example-full-width">
                <input matInput placeholder="Description" formControlName="Description">
            </mat-form-field>


            <mat-form-field>
                <mat-label>Result type</mat-label>
                <mat-select formControlName="ResultType" id="ResultType">
                    <mat-option  value="10" >
                        Integer
                    </mat-option>
                    <mat-option  value="20" >
                        Numeric, 1 decimals
                    </mat-option>
                    <mat-option  value="21" >
                        Numeric, 2 decimal
                    </mat-option>
                    <mat-option  value="30" >
                        Time
                    </mat-option>                    
                    <mat-option  value="40" >
                        Text
                    </mat-option>
                      
                </mat-select> 

            </mat-form-field>


            <mat-form-field>
                <mat-label>Value unit</mat-label>
                <mat-select formControlName="ResultUnit" id="ResultUnit">
                    <mat-option  value="reps">
                    reps
                    </mat-option>
                    <mat-option  value="kg">
                    kg
                    </mat-option>              
                    <mat-option  value="m">
                    m
                    </mat-option>
                    <mat-option  value="cm">
                    cm
                    </mat-option>
                    <mat-option  value="hh:mm:ss">
                        hh:mm:ss
                    </mat-option>
                    <mat-option  value="s">
                    seconds
                    </mat-option>
                    <mat-option  value="-">
                    No unit
                    </mat-option>                                
                 </mat-select>
            
            </mat-form-field>

            <button mat-raised-button color="primary" [disabled]="!testForm.valid" type="submit" >SAVE</button>
            <button mat-button  type="button" [mat-dialog-close]="false" >CANCEL</button>
    </form>


</div > 

