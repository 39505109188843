<h2 mat-dialog-title>SELECT FORM</h2>
<form #selectForm [formGroup]="_form" (ngSubmit)="createForm()" *ngIf="!isLoading" >
<mat-dialog-content style="font-family: Roboto;background-color: #303030;">

   
        <!-- our form array of checkboxes will go here -->
        <div formArrayName="forms" style="height: 250px; overflow-y: scroll;">

          
            <mat-selection-list #formSelect [multiple]="false">
                <mat-list-option *ngFor="let form of formsData; let i = index" [value]="form.FormId">
                    <div style="display: flex; flex-direction: row;">
                        <mat-icon matListItemIcon>dynamic_form</mat-icon>
                        {{form.Name}}
                    </div>
                    
                </mat-list-option>
            </mat-selection-list>
      

            
            <div *ngIf="formsData.length==0" style="color: gray;">
                NO FORMS AVAILABLE
            </div>
          
     
        
        </div>
           
        
     <!--  <button TYPE="submit" >SAVE</button> -->
    

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" *ngIf="formSelect!=null" [mat-dialog-close] ="formSelect.selectedOptions.selected[0]?.value" [disabled]="formSelect.selectedOptions.selected.length==0" >CREATE</button>
    <button mat-button [mat-dialog-close]="" >CANCEL</button>
</mat-dialog-actions>
</form>
