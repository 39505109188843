<h2 mat-dialog-title>Copy ranking list</h2>
<form #selectForm [formGroup]="_form" (ngSubmit)="submit()" >
    <mat-dialog-content style="font-family: Roboto;">
            <input name="CopyFromListId" type="hidden" formControlName="CopyFromListId" />
     

          <div style="height: 230px;" >
              <mat-label style="font-size: small;">Copy from</mat-label><br/>
              <mat-label>{{this.data.CopyFromListName}}</mat-label><br/>
              
              <mat-label>{{this.data.CopyFromCategory}}</mat-label><br/>
              <!-- <mat-form-field  appearance="fill">
              <mat-label>Copy list</mat-label>
              <input matInput style="width: 300px;"  formControlName="CopyFromListName" disabled>
            </mat-form-field> -->
            <br/>

            <mat-form-field style="width: 300px;" appearance="fill">
              <mat-label>Enter name of new list</mat-label>
              <input matInput type="text"  formControlName="CopyToListName">
            </mat-form-field>

            <mat-checkbox class="example-margin" color="primary" formControlName="ctrlCopyCategory">
                Copy category
            </mat-checkbox>
            <br/>
            <mat-checkbox class="example-margin" color="primary" formControlName="ctrlCopySecurity">
                Copy security
            </mat-checkbox>

                <div>{{ this._form.errors}} </div>
          </div>

          
          
        
            
       

    
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-raised-button [disabled]="!this._form.valid" type="submit" color="primary">COPY</button>
        <button mat-button type="button" (click)="close()">CANCEL</button>
    </mat-dialog-actions>
    </form> 

