import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { TeamEventViewModel } from 'src/app/load/models/gameevent';
import { PlayerListViewModel } from 'src/app/scouting/models/PlayerModels';
import { TeamViewModel } from 'src/app/scouting/models/TeamModels';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { PlayerService } from 'src/app/shared/player.service';
import { ScheduleEvent, TeamEventModel } from '../../models/schedulemodels';
import { TeamScheduleService } from '../../services/team-schedule.service';

@Component({
  selector: 'app-add-team-event-dialog',
  templateUrl: './add-team-event-dialog.component.html',
  styleUrls: ['./add-team-event-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush //test to see if we can speed up this component
})
export class AddTeamEventDialogComponent implements OnInit {

  public log: TeamEventViewModel;
  public teamWithPlayers: TeamViewModel;
  playerCtrl = new UntypedFormControl('');
  players: PlayerListViewModel[] = [];
  @ViewChild('playerInput') playerInput: ElementRef<HTMLInputElement>;
  addEventForm: UntypedFormGroup;
  isLoaded: boolean = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    public dialogRef: MatDialogRef<AddTeamEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public ref: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private playerService: PlayerService,
    private eventService: TeamScheduleService) { 
    
      this.addEventForm=  this.fb.group({
        Title: ["", [Validators.required]],
        EventId: "",
        StartTime: ["10.00", [Validators.required]],
        Duration: [60, [Validators.required]],
        EventDate: [moment().toDate(), [Validators.required]],
        TeamdId: [this.data.TeamId, [Validators.required]],
        LoadLevel: "5",
        FullTeamEvent: "true",
        Location: ["" , [Validators.required]],
        Note: "",
        CreatedByName: [{value: "-", disabled: true}],
        UpdatedByName: [{value: "-", disabled: true}],
        Attendees: this.fb.array([])      
      })

    }
    

    ngOnInit() {
      
      if(this.data.EventId==''){
        //New event
        this.log = new TeamEventViewModel;
        //this.log.EventDate=moment(this.data.PlannedDate)..toDate();
        this.log.StartTime = "10:00";
        this.log.TeamId = this.data.TeamId;
        this.log.FullTeamEvent = true;

        console.log(this.data.PlannedDate);
        console.log(this.log.EventDate);
        this.addEventForm = this.fb.group({
          TeamId: this.log.TeamId,
          Title: ["", [Validators.required]],
          EventId: "",
          StartTime: ["10.00", [Validators.required]],
          Duration: [60, [Validators.required]],
          EventDate: [this.data.PlannedDate, [Validators.required]],
          TeamdId: [this.data.TeamId, [Validators.required]],
          LoadLevel: [5],
          FullTeamEvent: [true],
          Location: ["" , [Validators.required]],
          Note: "",
          CreatedByName: [{value: "-", disabled: true}],
          UpdatedByName: [{value: "-", disabled: true}],
          Attendees: this.fb.array([])      
        })
        this.isLoaded=true;

        //console.log(this.data.length);
        /* (this.data.team.length);
        if(this.data.team.length ==1){
          this.log.TeamId = this.data.team[0].TeamId;
        } */
      }else{
        //Get event from db
        this.eventService.getTeamEvent(this.data.TeamId,this.data.EventId).subscribe(data => {
            
            var theEvent = data as TeamEventViewModel;

            data.EventDate = moment.utc(data.EventDate).toDate();
            data.StartTime = moment.utc(data.EventDate).format('HH:mm');
            //console.log(data.StartTime);            
            this.log = data;
            console.log(this.log);
            this.addEventForm = this.fb.group({
              TeamId: this.log.TeamId,
              Title: [this.log.Title, [Validators.required]],
              EventId: [this.log.EventId],
              StartTime: [ moment.utc(data.EventDate).format('HH:mm'), [Validators.required]],
              Duration: [this.data.Duration, [Validators.required]],
              EventDate: [ moment.utc(data.EventDate).toDate(), [Validators.required]],
              TeamdId: this.data.TeamId,
              LoadLevel: this.log.LoadLevel,
              Location: [this.log.Location , [Validators.required]],
              FullTeamEvent: this.log.FullTeamEvent,
              Note: this.log.Note,
              CreatedByName: [{value: this.log.CreatedByName, disabled: true}],
              UpdatedByName: [{value: this.log.UpdatedByName, disabled: true}],
              Attendees: this.fb.array([])      
            })

            this.addEventForm.patchValue({ LoadLevel: theEvent.LoadLevel });
            this.addEventForm.patchValue({ Duration: theEvent.Duration });


            if(!theEvent.FullTeamEvent){
              theEvent.Attendees.forEach(theAttendee => {
                          
                this.attendees.push(this.fb.group({
                  EventId : theAttendee.EventId ,
                  PlayerId : theAttendee.PlayerId,
                  FirstName: theAttendee.FirstName,
                  LastName: theAttendee.LastName
                }));
              });
            }
            
            this.isLoaded=true;

            
        }); 


      }
      this.playerService.getPlayersByTeamAndCurrentSeasonSimple(this.data.TeamId).subscribe(players=>{
          //console.log(players);
          
        this.teamWithPlayers = players as TeamViewModel;
        this.players = this.teamWithPlayers.Players;
      });
    
      //console.log(this.log);
      //this.ref.detach();
      
          
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(){
    console.log(this.addEventForm.value);
    this.saveTeamEvent();
    this.dialogRef.close();
  }

  deleteTeamEvent(): boolean{
    //console.log('delete');
    this.eventService.deleteTeamEvent(this.data.TeamId, this.log.EventId).subscribe(result => console.log(result));
    this.dialogRef.close();
    return false;
  }

  saveTeamEvent(): void{
   
    console.log(this.addEventForm.value);
    
    this.log = this.addEventForm.value;
    
    let dateevent = moment.utc(this.log.EventDate);
    let time = moment(this.log.StartTime, "HH:mm");
    //let time =this.log.StartTime;
          
    
    dateevent.set({
      hour:   time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second')
    });

    this.log.EventDate  = moment.utc(dateevent).toDate();
    this.addEventForm.patchValue({"EventDate": moment.utc(dateevent).toDate()});
    console.log(this.addEventForm.value);
    //console.log(this.log);

    
    this.eventService.saveTeamEvent(this.data.TeamId, this.log).subscribe(result => console.log(result));
     //this.eventService.saveTeamEvent(this.log).subscribe(l => console.log(l));
  }

  get attendees(): UntypedFormArray{
    //console.log(this.addEventForm.get('Attendees') as FormArray);
    return this.addEventForm.get('Attendees') as UntypedFormArray;
  }

  add(event: MatChipInputEvent): void {    
    var pppp: PlayerListViewModel;
    pppp = JSON.parse(event.value);
    
    var index = this.attendees.value.findIndex(obj=> obj.PlayerId===pppp.PlayerId);
    
    console.log(index);
    // Add our player
    if (pppp && index==-1) {
      this.players.push(pppp);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.playerCtrl.setValue(null);
  }

  remove(playerId): void {
    var index = this.attendees.value.findIndex(obj=> obj.PlayerId===playerId);
    console.log(index);
    if(index>-1)
      this.attendees.removeAt(index);
    
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    
   /*  this.players.push(event.option.viewValue);*/
    var pppp: PlayerListViewModel;
    //pppp = JSON.parse(event.option.value);
    pppp = event.option.value;
    
    console.log(this.attendees.value);
    var index = this.attendees.value.findIndex(obj=> obj.PlayerId===pppp.PlayerId);
    console.log(index);
    if(index===-1){
      this.addPlayer(pppp);
    }
    
    this.playerInput.nativeElement.value = ''; 
    this.playerCtrl.setValue(null);

    console.log(this.addEventForm.value);
  }

  addPlayer(player){
    console.log("adding");
    this.attendees.push(this.fb.group({
      PlayerId: player.PlayerId,
      FirstName: player.FirstName,
      LastName: player.LastName
    }));
  }

}





export interface DialogData {
  TeamId: string;
  EventId: string;
  Title: string;
  LoadLevel: number;
  Duration: number;
  PlannedDate: string;
}

