<div class="app-page">
  <br/>
  <!-- <mat-card appearance="outlined" STYLE=" margin: 10px;">
    <mat-card-title>{{this._profile.Name}}</mat-card-title>
    <mat-card-subtitle>Limits</mat-card-subtitle>
    <mat-card-content > -->

  <label>EDIT PROFILE</label>
  <h2>{{this._profile.Name}}</h2>
    
  <div *ngIf="this._profile" >
    
    <form [formGroup]="limitsForm" (ngSubmit)="save()">

      <div formArrayName="testlimits">
        <table>
          <ng-container *ngFor="let limit of TestLimits.controls; let i = index" [formGroupName]="i">


          
          <tr    >
            <td style="white-space: nowrap; font-weight: bold; font-size: large; max-width: 450px; overflow: hidden;" colspan="3">
              <mat-icon > assignment_turned_in </mat-icon>
              {{this._profile.TestLimits[i].Name}}
             
            </td>
            <td> 
               
              <button mat-button type="button" color="warn" (click)="removeTest(this._profile.TestLimits[i].TestId)">
                 <!--  <mat-icon color="warn">delete</mat-icon> -->
                  REMOVE
                </button></td>
          </tr>
          <tr>           
            <td colspan="2">
             
              <mat-slide-toggle color="primary" style="padding-left: 40px;" matInput formControlName="IncludeInTotalRanking">Included in score</mat-slide-toggle>
              
            
            </td>
            <td>
              
            </td>
            <td>
              <mat-form-field appearance="fill" class="smallfield">
                <mat-label>View order</mat-label>
                <input matInput  placeholder="" formControlName="OrderInProfile">                                  
                
              </mat-form-field>
          
            </td>
           
          </tr>
      <tr>
            <td style="text-align: right; white-space: nowrap;">
                 <button mat-icon-button type="button" (click)="settingsClick(this._profile.TestLimits[i].ProfileId, this._profile.TestLimits[i].TestId, this._profile.TestLimits[i].Name);">
                    <mat-icon style="font-size: small;">settings</mat-icon>
                  </button>
                <mat-form-field appearance="fill" class="smallfield" >
                    <mat-label>OK</mat-label>
                    <input matInput placeholder="" formControlName="LimitOK">                
                    <mat-hint *ngIf="!this._profile.TestLimits[i].LimitMultiplierTestId">{{ this._profile.TestLimits[i].ResultUnit }}</mat-hint>
                    <mat-hint style="white-space: nowrap;" style="white-space: nowrap;" *ngIf="this._profile.TestLimits[i].LimitMultiplierTestId"><span style="font-size: medium; width: 50px; ">*</span> <mat-icon style="font-size: small;"> assignment_turned_in </mat-icon><b>{{this._profile.TestLimits[i].LimitMultiplierTestName}}</b></mat-hint>
                  </mat-form-field>
            </td>
            <td><mat-form-field appearance="fill" class="smallfield">
                <mat-label>Good</mat-label>
                <input matInput placeholder="" formControlName="LimitGood" >                
                <mat-hint *ngIf="!this._profile.TestLimits[i].LimitMultiplierTestId">{{ this._profile.TestLimits[i].ResultUnit }}</mat-hint>
                <mat-hint style="white-space: nowrap;" *ngIf="this._profile.TestLimits[i].LimitMultiplierTestId"><span style="font-size: medium; width: 50px; ">*</span> <mat-icon style="font-size: small;"> assignment_turned_in </mat-icon><b>{{this._profile.TestLimits[i].LimitMultiplierTestName}}</b></mat-hint>

              </mat-form-field></td>
            <td><mat-form-field appearance="fill" class="smallfield">
                <mat-label>Very Good</mat-label>
                <input matInput placeholder="" formControlName="LimitVeryGood">                
                <mat-hint *ngIf="!this._profile.TestLimits[i].LimitMultiplierTestId">{{ this._profile.TestLimits[i].ResultUnit }}</mat-hint>
                <mat-hint style="white-space: nowrap;" *ngIf="this._profile.TestLimits[i].LimitMultiplierTestId"><span style="font-size: medium; width: 50px; ">*</span> <mat-icon style="font-size: small;"> assignment_turned_in </mat-icon><b>{{this._profile.TestLimits[i].LimitMultiplierTestName}}</b></mat-hint>

              </mat-form-field></td>
              <td>
                <mat-form-field appearance="fill" class="smallfield">
                    <mat-label>Excellent</mat-label>
                    <input matInput  placeholder="" formControlName="LimitExcellent">                                  
                    <mat-hint *ngIf="!this._profile.TestLimits[i].LimitMultiplierTestId">{{ this._profile.TestLimits[i].ResultUnit }}</mat-hint>
                    <mat-hint style="white-space: nowrap;" *ngIf="this._profile.TestLimits[i].LimitMultiplierTestId"><span style="font-size: medium; width: 50px; ">*</span> <mat-icon style="font-size: small;"> assignment_turned_in </mat-icon><b>{{this._profile.TestLimits[i].LimitMultiplierTestName}}</b></mat-hint>

                  </mat-form-field>
            </td>     
         
          </tr>
        
          <tr colspan="4">
            <br/>
            <mat-divider></mat-divider>
          </tr>
        </ng-container>
        </table>
      </div>
      <button mat-raised-button color="primary" type="submit" [disabled]="!limitsForm.valid" >SAVE</button> &nbsp;
      <button mat-button  type="button" (click)="addTestOpenDialog()"  >
        <mat-icon>add</mat-icon>
        ADD TEST
      </button> &nbsp;
      <button mat-button (click)="back()" >
          <mat-icon>arrow_back</mat-icon>
        BACK
      </button>
    </form>
    
    
    
  </div>
<!--   </mat-card-content>
  <mat-card-actions>
  
        
  </mat-card-actions>
  </mat-card>
   -->
</div>