import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkoutModel } from 'src/app/configuration/training/models/exercise.model';

@Component({
  selector: 'app-display-workout-card',
  templateUrl: './display-workout-card.component.html',
  styleUrls: ['./display-workout-card.component.css']
})
export class DisplayWorkoutCardComponent implements OnInit {

  @Input() Workout: WorkoutModel;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  statuscardcolor(status: string): string{
    switch(status){
      case "DRAFT":
        return "#686868";
      case "ACTIVE":
        return "green";
      case "ARCHIVED":
        return "red";
    }
  }


}
