<div>
    
    <table *ngIf="false">
        <tr>
            <td>Opponent</td>
            <ng-container *ngFor="let g of this.Games[0].GameAssessmentGroups">
                <td colspan="g.Parameters.length">
                    {{g.Name}}
                </td>
                <td *ngFor="let p of g.Parameters">
                    {{p.Name}}
                </td>
            </ng-container>
        </tr>
       <!--  <tr *ngFor="let game of this.Games.slice(0,this.NoOfGames)">
            <td *ngIf="game.IsHomeGame && game.AwayTeam.ShortName!=null"><span style="color: lightgray;">{{game.EventDate | date:'dd/MM'}}</span>&nbsp; <b>{{game.AwayTeam.ShortName}}</b></td>
            <td *ngIf="game.IsHomeGame && game.AwayTeam.ShortName==null"><span style="color: lightgray;">{{game.EventDate | date:'dd/MM'}}</span>&nbsp; {{game.AwayTeam.Name}}</td>
            <td *ngIf="!game.IsHomeGame && game.HomeTeam.ShortName!=null"><span style="color: lightgray;">{{game.EventDate | date:'dd/MM'}}</span>&nbsp; {{game.HomeTeam.ShortName}}</td>
            <td *ngIf="!game.IsHomeGame && game.HomeTeam.ShortName==null"><span style="color: lightgray;">{{game.EventDate | date:'dd/MM'}}</span>&nbsp; {{game.HomeTeam.Name}}</td>
            <ng-container *ngFor="let group of game.GameAssessmentGroups">                
                <td  *ngFor="let parameter of group.Parameters"   >
                    <div class="rankingvaluecell" [style.background-color]="scaleColors[parameter.Ranking]"  [style.color]="scaleTextColors[parameter.Ranking]">
                        {{parameter.Ranking}}
                    </div>
                    
                </td>
                <td>
                    this.Averages[0][0]
                </td>
            </ng-container>
            
          
        </tr> -->
    </table>

    <table>
        <tr>
            
            <td></td>
            <td *ngFor="let game of this.Games.slice(0, this.NumberOfGames)">                          
                <span style="font-size: x-small; color: lightgray" >{{game.EventDate | date:'dd/MM'}}</span>                    
            </td>
            <td>
                
            </td>
        </tr>
        <tr>
            
            <td></td>
            <td *ngFor="let game of this.Games.slice(0, this.NumberOfGames)">
                <span  *ngIf="game.IsHomeGame ">{{game.AwayTeam.ShortName}}</span>                    
                <span *ngIf="!game.IsHomeGame">{{game.HomeTeam.ShortName}}</span>                    
            </td>
            <td>
                Avg
            </td>
        </tr>
        <tr>
            <td [attr.colspan]="this.Games.slice(0,this.NumberOfGames).length+1" style="background-image: linear-gradient(to right, gray, #303030); color:white;">
                Coach assessments
            </td>
        </tr>
        <tr>
            <td>GRADE</td>
            <td  *ngFor="let game of this.Games.slice(0,this.NumberOfGames)"   >

                <div *ngFor="let ranking of game.GameRankings">
                    <div *ngIf="ranking.Ranking>0"  class="rankingvaluecell" style="margin-bottom: 2px;" [style.background-color]="gamePerformanceColors[ranking.Ranking]"  [style.color]="gamePerformanceTextColors[ranking.Ranking]" [title]="ranking.ScoutName">
                        {{ ranking.Ranking}}
                    </div>   
                </div>
                <!-- <div *ngIf="game.Ranking>0"  class="rankingvaluecell" [style.background-color]="gamePerformanceColors[game.Ranking]"  [style.color]="gamePerformanceTextColors[game.Ranking]">
                    {{ game.Ranking}}
                </div>    
                <div *ngIf="game.NoParticipationReason>0"  class="rankingvaluecell" [style.background-color]="scaleColors[0]"  [style.color]="scaleTextColors[0]">
                    DNP
                </div>                                
                <div *ngIf="game.Ranking==-1"  class="rankingvaluecell" [style.background-color]="scaleColors[0]"  [style.color]="scaleTextColors[0]">
                    -
                </div>                                 -->
            </td>  
            <td>
               
            </td>   
        </tr>
        <tr>
            <td [attr.colspan]="this.Games.slice(0,this.NumberOfGames).length+1" style="background-image: linear-gradient(to right, gray, #303030); color:white;">
                Player assessment
            </td>
        </tr>
        <tr>
            <td>OVERALL</td>
            <td  *ngFor="let game of this.Games.slice(0,this.NumberOfGames)"   >
                <div *ngIf="game.Ranking>0"  class="rankingvaluecell" [style.background-color]="gamePerformanceColors[game.Ranking]"  [style.color]="gamePerformanceTextColors[game.Ranking]">
                    {{ game.Ranking}}
                </div>    
                <div *ngIf="game.NoParticipationReason>0"  class="rankingvaluecell" [style.background-color]="scaleColors[0]"  [style.color]="scaleTextColors[0]">
                    DNP
                </div>                                
                <div *ngIf="game.Ranking==-1"  class="rankingvaluecell" [style.background-color]="scaleColors[0]"  [style.color]="scaleTextColors[0]">
                    -
                </div>                                
            </td>  
            <td>
                <div *ngIf="this.RankingAverages[this.Games.slice(0,this.NumberOfGames).length-1]!='NaN'" class="rankingvaluecell" style="border: 1px white solid" [style.background-color]="gamePerformanceColors[ Math.floor(this.RankingAverages[this.Games.slice(0,this.NumberOfGames).length-1]) ]"  [style.color]="gamePerformanceTextColors[ Math.floor(this.RankingAverages[this.Games.slice(0,this.NumberOfGames).length-1]) ]">
                    {{ this.RankingAverages[this.Games.slice(0,this.NumberOfGames).length-1] }}
                </div>
            </td>   
        </tr>
        <ng-container *ngFor="let g of this.Games[0].GameAssessmentGroups; let j = index">
            <tr *ngFor="let param of g.Parameters;let i = index">           
                <td >{{param.Name}}</td>
            
                <td  *ngFor="let game of this.Games.slice(0,this.NumberOfGames); let k = index"   >
                    <div *ngIf="game.Ranking>0" class="valuecell" [style.background-color]="scaleColors[game.GameAssessmentGroups[j].Parameters[i].Ranking]"  [style.color]="scaleTextColors[game.GameAssessmentGroups[j].Parameters[i].Ranking]">
                        {{ game.GameAssessmentGroups[j].Parameters[i].Ranking}}
                    </div>
                    
                    
                </td>  
                <td>
                    <div *ngIf="this.Averages[i][this.Games.slice(0,this.NumberOfGames).length-1]!='NaN'" class="valuecell" style="border: 1px white solid" [style.background-color]="scaleColors[Math.floor(this.Averages[i][this.Games.slice(0,this.NumberOfGames).length-1])]"  [style.color]="scaleTextColors[Math.floor(this.Averages[i][this.Games.slice(0,this.NumberOfGames).length-1])]">
                        {{ this.Averages[i][this.Games.slice(0,this.NumberOfGames).length-1] }} 
                    </div>
                    
                </td>                                          
            </tr>
           <!--  <tr>
                <td [attr.colspan]="this.Games.slice(0,this.NumberOfGames).length+1" style="background-image: linear-gradient(to right, gray, #303030); color:white;">
                    Pregame self assessment
                </td>
            </tr>

            <tr>
                <td>Freshness</td>
                <td  *ngFor="let game of this.Games.slice(0,this.NumberOfGames)"   >
                    <div *ngIf="game.AssessmentBeforeGame">
                            a
                    </div>
                </td>
            </tr> -->

           







        </ng-container>   
      

    </table>
  

</div>