<div class="app-page">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ this._profile.Name}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form  [formGroup]="profileForm" (ngSubmit)="save()">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter name" id="name" required formControlName="Name"/> 
        </mat-form-field>

        <button mat-raised-button type="submit">SAVE</button>
      </form>
    </mat-card-content>
    <mat-card-actions>

    </mat-card-actions>
  </mat-card>
</div>
