<div *ngIf="injuryOverView">
    <h3 style="display: flex; align-items: center;">
        <mat-icon >local_hospital</mat-icon>
        INJURIES
    </h3>
    <div style="   margin-left:10px;">
        <!-- <div *ngIf="injuryOverView.ActiveInjuries.length>0">Body parts</div>
        <div style="display: flex; flex-direction: row;" *ngFor="let part of injuryOverView.BodyPartActiveCount.rows"  >
             <div class="InjuryBodyPart">{{part.c[0].v }} </div>
             <div class="InjuryBodyPartCount">{{part.c[1].v }}</div>
             <div class="InjuryBodyPartDays">{{part.c[2].v }} days</div>
        </div>    
        <BR/>
        <div *ngIf="injuryOverView.ActiveInjuries.length>0">Active injuries</div> -->
        <div style="display: flex; flex-direction: row;" *ngFor="let injury of injuryOverView.ActiveInjuries">
            <div class="redbox"></div>
            <div class="InjuryPlayerName">{{injury.LastName}}, {{injury.FirstName }}</div>
            <div class="InjuryType">{{injury.TypeName}} </div>
            <div class="InjuryBodyPart">{{injury.BodyPartName }} </div>
            <div class="InjuryDay">{{injury.InjuryDate | date: 'YYYY-MM-dd'}}</div>
            <div class="InjuryDayCount">{{injury.InjuryDays}} days</div>
        </div>
    
        
        <div *ngIf="injuryOverView.ActiveInjuries.length==0" class="NoActiveInjuries" style="color: darkgray;">
            NO ACTIVE INJURIES
        </div> 
        <br/>
        <button mat-stroked-button [routerLink]="['/injury/byteam/', this.Team.TeamId, '/current']" *ngIf="injuryOverView.ActiveInjuries.length>0">VIEW DETAILS</button>
        <button mat-stroked-button [routerLink]="['/injury/overview/', this.Team.TeamId]">VIEW CHARTS</button>

    </div>
   
</div>

