<div class="app-page">
   <!--  <div style="display: flex; flex-direction: row; flex-wrap: wrap;margin-left: 20px;">
        <ng-container *ngFor="let list of lists">
            <mat-divider></mat-divider>
            <app-ranking-list-card [List]="list"  style="width: 340px;"></app-ranking-list-card>
        </ng-container>
        
    </div> -->
    <h2>
      <mat-icon> format_list_numbered </mat-icon>
      RANKING LISTS
    </h2>
    <button mat-raised-button (click)="addList()" color="primary" >
      <mat-icon>add</mat-icon>
      NEW LIST
    </button>&nbsp;
    <button mat-raised-button (click)="getData()">
      <mat-icon>refresh</mat-icon>
      REFRESH
    </button>
    
    <br />
    <br />
    <div style="display: flex;">


      
   <!--    <div>
       <mat-nav-list dense>
      
      
        <h3 mat-subheader>LISTS</h3>
        <a  mat-list-item routerLink="/scouting/list/{{list.ListId}}" *ngFor="let list of lists">
          <mat-icon mat-list-icon style="font-size: 32px; padding-bottom: 32px;;" >format_list_numbered</mat-icon>
          <h4 mat-line><b>{{list.Description}}</b></h4>
          
          <p mat-line><mat-icon style="font-size: small; height: 13px;width: 13px;">person</mat-icon> {{list.CreatedBy}} </p>
          <p mat-line *ngIf="list.Category"><mat-icon style="font-size: small; height: 13px;width: 13px;">category</mat-icon> {{list.Category}} </p>
        </a>
        
      
        <mat-divider></mat-divider>
        
      </mat-nav-list> 
      </div> -->
      <div STYLE="width: 90%; display: flex; flex-direction: row; flex-wrap: wrap;">
      <div class="center">
        <mat-spinner *ngIf="isBusy"></mat-spinner>
      </div>
<!--         <div *ngFor="let item of newList | keyvalue">
          <div id="{{item.key}}">
            {{item.key}}
            <div class="item" *ngFor="let value of item.value">
              <div style="display: flex; flex-direction: column; border: 1px solid darkgray; border-radius: 2px; max-width: 300px; width: 300px; margin: 2px; font-size: small;">
                <div>{{value.Description}}</div>
                <div style="font-size: small;">{{value.Category}}</div>
              </div>
            </div>
          </div>


        
      
        </div> -->
        <div STYLE=" display: flex; flex-direction: row; flex-wrap: wrap;">
        
          <ng-contaner *ngFor="let list of this.lists">
        
        
            <app-ranking-list-card *ngIf="list.IsMyList" [List]="list" [hidden]="this.hiddenLists.includes(list.ListId)"
              (onDeleteListClicked)="this.deleteList2($event)" (onCopyListClicked)="this.copyList2($event)"
              (onArchiveListClicked)="this.archiveList($event)">
            </app-ranking-list-card>
          </ng-contaner>
        </div>
        
        <mat-accordion class="example-headers-align" multi *ngIf="this.lists.length>0">
        
          <ng-container *ngFor="let item of newList | keyvalue">
            <!-- <div>{{item.key}}</div> -->
            <mat-expansion-panel class="mat-elevation-z7" *ngIf="item.value.length>0 && !item.value[0].IsMyList">
              <mat-expansion-panel-header>
                <mat-panel-title> {{item.key}}</mat-panel-title>
                <mat-panel-description>
                  {{item.value.length}} lists
        
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div style="display: flex; flex-direction: row; flex-wrap: wrap; ">
                <app-ranking-list-card *ngFor="let value of item.value" [List]="value"
                  [hidden]="this.hiddenLists.includes(value.ListId)" (onDeleteListClicked)="this.deleteList2($event)"
                  (onCopyListClicked)="this.copyList2($event)" (onArchiveListClicked)="this.archiveList($event)">
                </app-ranking-list-card>
              </div>
              </mat-expansion-panel>
              </ng-container>
              </mat-accordion>

<!--         <mat-accordion style="max-width: 450px;" multi>
          <mat-expansion-panel style="max-width: 800px;" *ngFor="let item of newList | keyvalue">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{item.key}}
              </mat-panel-title>
              <mat-panel-description>
                {{item.value.length}} lists
              </mat-panel-description>
            </mat-expansion-panel-header>
           
            
            <div style="display:flex; flex-direction: row; flex-wrap: wrap; width: 100%;">
              <ng-container *ngFor="let value of item.value">
                  <div *ngIf="isnewCategory(value.Category)" style="width: 100%; font-size: large;">{{value.Category}}</div>
                  <div class="item" matRipple style="display: flex; flex-direction: row; border: 1px solid darkgray; border-radius: 2px; max-width: 450px; width: 450px; margin: 2px; font-size: small; padding: 3px; background-color: #424242; margin: 2px">             
                    <div  (click)="viewList(value.ListId)">
                      <mat-icon> format_list_numbered </mat-icon>
                    </div>
                    <div  (click)="viewList(value.ListId)" style="max-width: 355px; width: 355px; overflow-x: hidden;" >
                      <div style="font-size: medium;white-space: nowrap;">{{value.Description}}</div>
                      <div style="font-size: small; white-space: nowrap;">{{value.Category}}</div>
                    </div>
                    <div style="white-space: nowrap;">
                      <mat-icon>people</mat-icon>
                      <label>
                        {{value.NumberOfPlayers}}
                      </label>
                      
                    </div>
                    <div>
                      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editList(value.ListId)" [disabled]="!value.IsMyList">
                          <mat-icon>edit</mat-icon>
                          <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="deleteList(value.ListId, value.Description, item.key)" [disabled]="!isAllowedToDelete(value.IsMyList)">
                          <mat-icon>delete</mat-icon>
                          <span>Delete</span>
                        </button>
                       
                        <button mat-menu-item (click)="copyList(value.ListId, value.Description, value.Category)">
                          <mat-icon>content_copy</mat-icon>
                          <span>Copy</span>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                  
              </ng-container>
              
              
            </div>
            
          </mat-expansion-panel>
           
        </mat-accordion> -->
 
     <!--  <div STYLE="width: 90%">
        <mat-accordion>
          <mat-expansion-panel >
            <mat-expansion-panel-header>
              <mat-panel-title>
                Bjorn Liljander
              </mat-panel-title>
              <mat-panel-description>
                Landslaget
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div STYLE="display: flex; flex-direction: row; flex-wrap: wrap;">
              <app-ranking-list-card *ngFor="let list of lists" [List]="list"></app-ranking-list-card>
            </div>
            

          </mat-expansion-panel>
          <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title>
              Bjorn Liljander
            </mat-panel-title>
            <mat-panel-description>
              J20
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div STYLE="display: flex; flex-direction: row; flex-wrap: wrap;">
           
          </div>
          

        </mat-expansion-panel>
          <mat-expansion-panel >
            <mat-expansion-panel-header>
              <mat-panel-title>
                Fredrik Sjöström
              </mat-panel-title>
              <mat-panel-description>
                Listts
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div STYLE="display: flex; flex-direction: row; flex-wrap: wrap;">
              <app-ranking-list-card *ngFor="let list of lists" [List]="list"></app-ranking-list-card>
            </div>
            

          </mat-expansion-panel>
        </mat-accordion>
      
        

      </div> -->

    </div>

    
</div>