import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { parseTemplate } from '@angular/compiler';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';
import { ResponseModel } from 'src/app/shared/models/ResponseModel';
import { BaseService } from 'src/app/shared/baseservice';
import { AssignedProgramModel, AssignedProgramViewModel } from '../models/AssignedProgramModels';

@Injectable({
  providedIn: 'root'
}) 

export class AssignedProgramService extends BaseService {

  constructor(private http: HttpClient ) {
    super();
   }

   createAssignedProgram(newProgram: AssignedProgramViewModel): Observable<ResponseModel>{
  
    let ret = this.http.post<ResponseModel>(this.rootUrl + '/api/training/assignedprograms',newProgram,this.httpOptions);
    return ret;
  }

  getAssignedPrograms(){
    return this.http.get<AssignedProgramViewModel[]>(this.rootUrl + '/api/training/assignedprograms', this.httpOptions);
  }

  getAssignedProgram(AssignedProgramId: number){
    return this.http.get<AssignedProgramViewModel>(this.rootUrl + '/api/training/assignedprograms/' + AssignedProgramId, this.httpOptions);
  }

  deleteAssignedProgram(AssignedProgramId: number): Observable<ResponseModel>{
    let ret = this.http.delete<ResponseModel>(this.rootUrl + '/api/training/assignedprograms/' + AssignedProgramId);
    return ret;

  }

  activateAssignedProgram(AssignedProgramId: number): Observable<ResponseModel>{
    let ret = this.http.put<ResponseModel>(this.rootUrl + '/api/training/assignedprograms/activate/', { 'AssignedProgramId': AssignedProgramId},this.httpOptions);
    return ret;
  }

  archiveAssignedProgram(AssignedProgramId: number): Observable<ResponseModel>{
    let ret = this.http.put<ResponseModel>(this.rootUrl + '/api/training/assignedprograms/archive/', { 'AssignedProgramId': AssignedProgramId},this.httpOptions);
    return ret;
  }

}
