<div class="app-page">
    <h3>GAME ASSESSMENTS</h3>
    <div STYLE="color: gray;">
        A game assessment decides what parameters the player will assess after a game.<br>
        Set the active game assessment for each team in menu <a routerLink="/teams" style="color: white">CONFIGURATION/COMMON/TEAM SETUP</a><br>
        Currently new Game Assessments cannot be created here, contact Dalecarlia Scouting to create new assessments.

    </div>
    <div style="display: flex;">
        <app-game-assessment-card *ngFor="let ga of this.GameAssessments" [GameAssessment]="ga">
        </app-game-assessment-card>
    </div>
    <div *ngIf="this.GameAssessments && this.GameAssessments.length==0" style="font-size: 20px; color: gray;">
        NO GAME ASSESSMENTS DEFINED
    </div>
    
    
</div>

