
<div class="app-page">
  
    
   
      <app-player-header  style="margin-left: 20px;" [Player]="this.Player"></app-player-header>
  
    

    <mat-divider></mat-divider>
 
    <app-game-header-small *ngIf="this.Report?.Game" style="margin-left: 20px;" [Game]="this.Report.Game"></app-game-header-small>
    <mat-divider></mat-divider>
    <h2>
        General
    </h2>
  <!--   <div>
      {{this.reportForm.value | json}}
    </div> -->
    <form *ngIf="this.ReportMasterData!=null && this.Report!=null" [formGroup]="reportForm" (ngSubmit)="onSubmit()"> 
          <input type="hidden" formControlName="PlayerId"/>
          <input type="hidden" formControlName="GameId"/>
          <mat-form-field appearance="fill"  class="wideinput">
            <mat-label>Game performance</mat-label> 
            <mat-select #gameperformance  formControlName="GamePerformance">
              <mat-select-trigger>
                <span [ngStyle]="{'background-color': this.gameperf_colors[gameperformance?.value]} ">&nbsp;&nbsp;</span>
                {{this.gameperf_texts[gameperformance?.value]}}
              </mat-select-trigger>
            
              <mat-option [value]="1">
                <span style="background-color: #ff2c1c; ">&nbsp;&nbsp;</span>
               Bad                                
              </mat-option>
              <mat-option [value]="2">
                <span style="background-color: #e9c400; ">&nbsp;&nbsp;</span>
                Average
              </mat-option>
              <mat-option [value]="3">
                <span style="background-color: yellowgreen; ">&nbsp;&nbsp;</span>
                Good
              </mat-option>
              <mat-option [value]="4">
                <span style="background-color: limegreen; ">&nbsp;&nbsp;</span>
                Very good
              </mat-option>
              <mat-option [value]="5">
                <span style="background-color: #1e90ff; ">&nbsp;&nbsp;</span>
                Excellent
              </mat-option>

            </mat-select>
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="fill" class="wideinput" *ngIf="this.PlayerPositions">
            <mat-label>Position</mat-label>
            <mat-select #PositionId formControlName="PositionId">
              <mat-option *ngFor="let pos of this.PlayerPositions" [value]="pos.PlayerPositionId">
                {{pos.Text}}
              </mat-option>
            </mat-select>
          </mat-form-field><br />
          <mat-form-field appearance="fill" class="wideinput" *ngIf="this.ReportMasterData.CapacityEnabled">
            <mat-label>Player capacity</mat-label>
            <mat-select #Capacity formControlName="Capacity">
              <mat-select-trigger>
                <span [ngStyle]="{'background-color': this.getCapacityColor(Capacity?.value)} ">&nbsp;&nbsp;</span>
                {{this.getCapacityText(Capacity?.value)}}
              </mat-select-trigger>
              <mat-option *ngFor="let cap of this.ReportMasterData.Capacity" [value]="cap.Ranking">
                <span [style.background-color]="getCapacityColor(cap.Ranking)">&nbsp;&nbsp;</span>
                {{cap.Text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="fill" *ngIf="this.ReportMasterData.PlayerTypeEnabled" class="wideinput">
            <mat-label>Player type</mat-label>
            <mat-select formControlName="PlayerType">
              
              <mat-option *ngFor="let plyrtype of this.ReportMasterData.PlayerType" [value]="plyrtype.Ranking">
                {{plyrtype.Text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="fill" class="wideinput">
            <mat-label>Projection</mat-label>
            <mat-select #Projection formControlName="Projection">
              <mat-select-trigger>
                <span [ngStyle]="{'background-color': this.getProjectionColor(Projection?.value)} ">&nbsp;&nbsp;</span>
                {{this.getProjectionText(Projection?.value)}}
              </mat-select-trigger>
              <mat-option *ngFor="let proj of this.ReportMasterData.Projection" [value]="proj.Ranking">
                <span [style.background-color]="getProjectionColor(proj.Ranking)">&nbsp;&nbsp;</span>
                {{proj.Text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <br/>
          <mat-form-field  appearance="fill"  class="widetextarea">
            <mat-label>Comment</mat-label>
            <textarea matInput cdkTextareaAutosize cdkTextareaAutosize
            cdkAutosizeMinRows="5"
             placeholder="Enter comments about player abilities" formControlName="Comment"></textarea>
          </mat-form-field>
          <br/>
          <mat-form-field  appearance="fill"  class="wideinput">
            <mat-label>Location</mat-label>
            <input matInput 
             placeholder="Location" formControlName="Location">
          </mat-form-field>
          &nbsp;
          <mat-form-field appearance="fill" class="wideinput" *ngIf="this.ReportMasterData.ScoutingSource.length>0">
            <mat-label>Source</mat-label>
            <mat-select #ScoutingSourceId formControlName="ScoutingSourceId">
              <mat-option *ngFor="let source of this.ReportMasterData.ScoutingSource" [value]="source.ScoutingSourceId">
                {{source.Text}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
          <mat-form-field  appearance="fill"  class="wideinput">
            <input matInput [matDatepicker]="picker" formControlName="ReportDate">
            <mat-label>Report date</mat-label>
            <mat-hint>YYYY-MM-DD</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-divider></mat-divider>
          <h2>Skills</h2>
          <div formArrayName="SkillGroups">
            <div *ngFor="let group of this.ReportMasterData.SkillsGroupsView; index as i" >
              <div [formGroupName]="i">
                <div style="font-weight: bold; font-family: Roboto;">
                  <mat-icon>workspaces</mat-icon>
                  {{group.Name}}
                </div>
                <div style="margin-left: 10px;" formArrayName="Skills">
                 <!--  <app-skill-ranking *ngFor="let skill of group.Skills; "  [SkillText]="skill.Text" [SkillId]="skill.SkillId"></app-skill-ranking> -->

                 <div *ngFor="let skill of group.Skills; index as skillindex" >
                    <div style="font-family: Roboto">
                      {{skill.Text}} 
                    </div>
                    <div style="margin-left: 10px;" [formGroupName]="skillindex">
                      <mat-button-toggle-group formControlName="Value" (change)="this['selected'+skill.SkillId] = $event.value;"  >
                        <mat-button-toggle *ngFor="let op of mylist" 
                          [value]="op"          
                          [ngClass]="{'bg-bad': this['selected'+skill.SkillId] === 1,'bg-average':this['selected'+skill.SkillId] === 2,'bg-good':this['selected'+skill.SkillId] === 3,'bg-verygood':this['selected'+skill.SkillId] === 4,'bg-excellent':this['selected'+skill.SkillId] === 5}" 
                          (click)="this['selected'+skill.SkillId] = op;">
                          {{getText(op) }}
                          <!-- <div  style="font-size: small; line-height: 22px;display: flex; flex-direction: column;">
                                                      
                                                       <div style="height: 15px;">
                                {{op}}
                            </div> 
                            <div>
                                {{getText(op) }}
                            </div>
                          </div> -->
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                  
                 </div>
                 
                </div>
              </div>
             
              
            </div>

          </div>
          <br/>
      <button mat-raised-button type="submit" color="primary" [disabled]="!this.reportForm.valid">SAVE</button>
      <button mat-button type="button"  (click)="this.location.back()" >CANCEL</button>
    </form>
   
</div>

