

<mat-card appearance="outlined" STYLE="max-width: 400px; min-width: 330px; margin: 10px;">
    
  <mat-card-header>      
    <div mat-card-avatar><i class="material-icons" >assignment</i></div>
    <mat-card-title>{{this.Profile.Name }}</mat-card-title>
    <mat-card-subtitle>-</mat-card-subtitle>
    
  </mat-card-header>    
  <mat-card-content>
   
  
      <mat-list >
        <mat-list-item style="height: 32px; font-size: small;" *ngFor="let test of this.Profile.Tests">
            <mat-icon matListItemIcon style="font-size: medium;"> assignment_turned_in </mat-icon>
            <p matListItemTitle>  {{test.Name}} </p>
        </mat-list-item>
        
       </mat-list>
    
  </mat-card-content>
  <mat-card-actions>
    <button mat-button [routerLink]="['/profilelimits/',this.Profile.ProfileId]">EDIT</button>    
    <!-- <button mat-button [routerLink]="['/profileeditor/',this.Profile.ProfileId]">EDIT</button>     -->
    <button mat-button (click)="deleteProfile(this.Profile.ProfileId)" color="warn">
      
      DELETE
    </button>
  </mat-card-actions>
</mat-card>

