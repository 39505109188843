<div class="app-page" style="margin-left: 20px;">
  <div style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 15px;">
  <div style="display: flex; border-left:orange solid 4px; padding-left: 5px; margin-left: 10px; margin-bottom: 15px; margin-top: 15px; background: #424242; max-width: 450px; min-width: 360px; " *ngIf="this.GameEvent">
    <table>
        <tbody>
            <tr >
                <td style="width: 50px;vertical-align:top;  font-size: 14px;font-family: Roboto;">
                    <span style="font-family: Roboto; font-size: 24px; vertical-align: text-top;">
                        {{ this.GameEvent.Game.GameDate | date:'dd' }}<br/>                       
                    </span>
                     {{ this.GameEvent.Game.GameDate  | date:'MMM'}}<br/>
                        {{ this.GameEvent.Game.GameDate  | date:'EEE'}} 
                </td>
            </tr>
        </tbody>
    </table>
    <div  style="color: white; float:left; width: 37.5%; text-align: center"><img src="/assets/img{{ this.GameEvent.Game.HomeTeamSmallIcon}}" style="height: 50px; margin:auto;"/>
        <p style="margin: auto; font-weight: bold; font-size:small; font-family: Roboto;">{{ this.GameEvent.Game.TeamHomeName }}</p>
     </div>
    <div  style=" float:left; width: 25%;font-size: x-small; margin-top: 10px; font-weight: bold; white-space: nowrap; overflow-x: hidden; font-family: Roboto;">
        <i class="material-icons" style="font-size: 14px;">
            access_time
            </i> {{this.GameEvent.Game.GameTime | date:'HH:mm'}}<br/>
            <img _ngcontent-c0="" src="/assets/img/trophy-variant.png" style="height: 14px; opacity: 0.7;">
           <br/>
        <i class="material-icons" style="font-size: 14px;">
            place
            </i>
        {{this.GameEvent.Game.Venue}} </div>
    <div style="color: white; float:left; width: 37.5%;text-align: center "><img src="/assets/img{{ this.GameEvent.Game.AwayTeamSmallIcon}}" style="height: 50px; margin: auto;"/>
        <p style="margin: auto; font-weight: bold; font-size: small;font-family: Roboto;">{{ this.GameEvent.Game.TeammAwayName }}</p> 
    </div>  
  
    
    
  </div>
  <div style="margin-left: 30px; align-self: center;">
    <mat-form-field appearance="fill" style="font-size: 12px;">
      <mat-label>Statistics view</mat-label>
      <mat-select [(ngModel)]="ViewId" [formControl]="viewFormControl" (selectionChange)="refreshData()">
        <mat-option *ngFor="let view of this.Views" [value]="view.GameStatisticsViewId">
          {{view.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span>&nbsp;</span>
  <button mat-raised-button *ngIf="this.isStatisticsEditor" [routerLink]="['/performance/game/statistics/edit', this.GameId, this.TeamId, this.ViewId]" >
    <mat-icon>edit</mat-icon>
    EDIT DATA
  </button>&nbsp;
  <button mat-raised-button *ngIf="this.isStatisticsEditor" (click)="importFromSwehockey();">
    <mat-icon>cloud_download</mat-icon>  
    
    IMPORT FROM SWEHOCKEY
  </button>&nbsp;
  <a  [routerLink]="['/performance/game/statistics/import', this.GameId, this.TeamId]" mat-raised-button *ngIf="this.isStatisticsEditor" >
    <mat-icon>cloud_download</mat-icon>  
    
    IMPORT CSV
  </a >&nbsp;
  <!--  <button mat-raised-button *ngIf="this.isStatisticsEditor" (click)="notifyPlayers();">NOTIFY PLAYERS</button> -->
  </div>
</div>


  
  <mat-progress-spinner *ngIf="isBusy"
        class="loading-indicator"
        color="primary"
        mode="indeterminate"
  >
    </mat-progress-spinner>
    <table mat-table [dataSource]="dataSource"  >
       <!--  <ng-container matColumnDef="Name" sticky>
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Name}} </td>
       
        </ng-container> -->
               
        <ng-container  matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef style=" text-align: center;width: 250px;">Name</th>
          <td mat-cell *matCellDef="let element" style=" text-align: center; width: 250px;">
            <div style="max-width: 250px;"><a [routerLink]="['/player/details', element.PlayerId]"
                style="color: white; font-weight: bold; text-decoration: none;">{{element.Name}}</a></div>
          </td>         
        </ng-container>

        <ng-container *ngFor="let parm of displayedColumns.slice(1);let i = index" matColumnDef="{{parm}}">
          <th mat-header-cell *matHeaderCellDef style=" text-align: center;" [ngStyle]="{'background-color': this.displayedColumnsAbbreviationBgColor[i],'color': this.displayedColumnsAbbreviationTextColor[i],'padding': '2px', 'font-size':'x-small', 'font-weight':'normal'}" >{{displayedNames[i]}} </th>
          <td mat-cell *matCellDef="let element" style=" text-align: center;"    [ngStyle]="{'background-color': this.displayedColumnsBgColor[i]}">
              <div [ngStyle]="{'background-color': this.getRankingColor(element[parm+'_Ranking']), 'color': 'black'}" >{{element[parm]}}</div>             
          </td>         
        </ng-container>
    
        <ng-container [matColumnDef]="'NameHeader'">
          <th mat-header-cell *matHeaderCellDef 
          style="text-align:left" style="min-width: 100px;"
          >        
          </th>
        </ng-container>
        
        <ng-container [matColumnDef]="'GroupHeader_' + G.GameStatisticsGroupId" *ngFor="let G of this._view.Groups; let i = index">
          <th mat-header-cell *matHeaderCellDef 
          style="text-align:center"
              [attr.colspan]="G.Parameters.length + G.ParametersCalculated.length"
              [ngStyle]="{'background-color': G.BgColorHeader, 'color': G.TextColorHeader, 'margin-left': '20px'}"              
              >
            &nbsp;{{G.Name}}
          </th>
        </ng-container>
       
        <tr mat-header-row *matHeaderRowDef="groupColumns; sticky: true"></tr> 
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 14px;"></tr>

      </table>
</div>
