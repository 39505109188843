import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GameEventOverview, GameEvent } from 'src/app/load/models/gameevent';
import { Player } from 'src/app/load/models/player';
import { GameService } from 'src/app/load/services/game.service';
import { Globals } from 'src/app/globals';



@Component({
  selector: 'app-grade-game',
  templateUrl: './grade-game.component.html',
  styleUrls: ['./grade-game.component.css']
})
export class GradeGameComponent implements OnInit {

    
  private TeamId: string;
  public GameId: string;
  public players: Player[];
  public Game: GameEventOverview;
  public gameDetails: GameEvent;
  public selectedTest: number = 0;


  constructor( 
    public gameService: GameService,
    public route: ActivatedRoute,
    public _globals: Globals,

    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.TeamId = this.route.snapshot.paramMap.get('TeamId');
    this.GameId = this.route.snapshot.paramMap.get('GameId');


    this.GetPlayersForGameView();    
    this.GetGameOverview();
  }

  GetGameOverview():void{
    /*  this.eventservice.GetGameOverViewForTeam(this.GameId,this.TeamId).subscribe(g => {
       this.Game = g;
       this.generateChart(g);
       this.generateGamePrepChart(g);
 
     }); */
     this.gameService.GetGameDetails(this.GameId).subscribe(g => {
       this.gameDetails = g;
       console.log(g);
     });
   }
 
   GetPlayersForGameView(): void{
      this.gameService.GetPlayersForGameView(this.GameId, this.TeamId).subscribe(
       response => {
         this.players = response;
         console.log("PLAYERS");
         console.log(this.players);
       }
     ); 
   }

   getGrade(playerId:string){
     
      var player = this.players.find(p=>p.PlayerId == playerId);
      //console.log(player);
      if(player){
        //console.log('UserId: '  +this._globals.UserId);
        var grade = player.CoachRankings.find(g=>g.UserId==this._globals.UserId);
        if(grade!=null){
          console.log('Grade found: ' + grade.Ranking);
          return grade.Ranking.toString();
        }          
        else{
          console.log('No grade found for userid: ' + this._globals.UserId);
          return '0';
        }
      }else{
        console.log('No player found: ' + playerId);
        return '0';
      }
   }

   setGrade(event, playerid: string, value: string){
     console.log("GameId:" + this.GameId + ' PlayerId:' + playerid + ' Was set to ' + value);
    console.log(event);
   }

}
