import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-feed',
  templateUrl: './survey-feed.component.html',
  styleUrls: ['./survey-feed.component.css']
})
export class SurveyFeedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
