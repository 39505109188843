<div class="app-page">
    <mat-toolbar>
        <button mat-icon-button (click)="previousMonth()"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <div style="width:100%; text-align: center;">
            {{this.schedule.ActiveMonthText}} {{this.schedule.ActiveYear}}
        </div>
        <button mat-icon-button (click)="nextMonth()"><mat-icon>keyboard_arrow_right</mat-icon></button>
        
    </mat-toolbar>
    <mat-progress-bar *ngIf="this.isLoading" mode="query"></mat-progress-bar>

    <div class="calendar" id="calendar">
        <ul class="weekdays">
            <li>Monday</li>
            <li>Tuesday</li>
            <li>Wednesday</li>
            <li>Thursday </li>
            <li>Friday</li>
            <li>Saturday</li>
            <li>Sunday</li>
        </ul>
        <ul>

            <ng-container *ngFor="let day of this.Days; let i = index">
                <li [ngClass]="DayClass[i]" id="" style="height: 180px; overflow-y: scroll;">
                    <div style="float: left;">{{day.getDate()}}</div>
                    <div style="float: right; height:20px; ">
                        <!-- <a href="#"
                            onclick="javascript:showAddPopup('2019-11-12');"
                            style="text-decoration: none; font-size: large;"><img style="height: 20px;"
                                src="/img/AddCalenderitem.png"></a> -->
                    </div><br>
                    <ng-container *ngFor="let evt of getEvents(day)">
                        <div class="show-info-new" id="showinfo" *ngIf="evt.EventType==='GAME'" [style.border-left]="'5px solid'+ evt.EventColor" style="text-shadow: none; float: none; font-family: verdana; font-weight:normal;  white-space: nowrap; overflow-x:hidden;">
                            <div style="display: flex; flex-direction: row; width: 100%;">
                                                     
                                <div (click)="openGameDetails(evt.GameId)" style="flex-shrink: 3 ; min-width: 0; overflow: hidden;" >
                                    {{evt.DivisionName}}<br/>
                                    {{evt.HomeTeamName}}-{{evt.AwayTeamName}}<br>
                                    <span style="font-weight:normal;z-index: 0;">{{evt.LastName}},{{evt.FirstName}}</span>
                                </div>
                                <div   [attr.eventid]="evt.EventId" *ngIf="evt.IsMyEvent" (click)="deleteEventFromCalendar(evt.EventId)" >
                                    <!-- <a href="#" *ngIf="evt.IsMyEvent" class="removefromschedule" 
                                        [attr.eventid]="evt.EventId" eventtype="GAME" [attr.divisionname]="evt.DivisionName"
                                        [attr.gamename]="evt.HomeTeamName"
                                        [attr.eventdate]="day"> -->
                                <!--         <img src="../assets/img/Blackcross.png" style="height: 16px;"> -->
                                        <mat-icon STYLE="z-index: 100;"  aria-hidden="false" aria-label="Example home icon" style="color: black;">delete</mat-icon>
                                    <!-- </a>  -->
                                    
                                </div>         
                            </div>
                           
                           
                        </div>
                        <div class="show-info-new" id="showinfo" *ngIf="evt.EventType==='EVENT'" [style.border-left]="'5px solid'+ evt.EventColor" style="text-shadow: none; float: none; font-family: verdana; font-weight:normal;  white-space: nowrap; overflow-x:hidden;">
                            <div style="float:right;z-index: 100;"  (click)="deleteEventFromCalendar(evt.EventId)" *ngIf="evt.IsMyEvent">
                                <!-- <img *ngIf="evt.IsMyEvent" src="../assets/img/Blackcross.png" style="height: 16px;" /> -->
                                <mat-icon aria-hidden="false" aria-label="Example home icon">delete</mat-icon>
                            </div>
                            <div>{{evt.Title}}<br>
                                <span style="font-weight:normal;">{{evt.LastName}},{{evt.FirstName}}</span>
                            </div>
                        </div>
                    </ng-container>



                </li>
            </ng-container>

        </ul>
    </div>



</div>