<div style="text-align:center">    
    <h1>    
      Welcome to {{ title }}!    
    </h1>    
    <img width="300" alt="Angular Logo" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg==">    
  </div>    
       
  <div class="col-sm-10">    
    <ng-container>    
        <input type="file" id="file" multiple (change)="getFileDetails($event)">    
        <button class="btn btn-primary" (click)="uploadFiles()">Upload</button>    
    </ng-container>    
  </div>  
  <div class="col-sm-10" *ngFor="let Video of this.myVideos | async; let i=index">  
    <video width="270" height="220"   controls disabled="true"  (click)="toggleVideo()" #videoPlayer>   
      <source [src]="Video.Videos" type="video/mp4">   
    </video>  
  </div> 