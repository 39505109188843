import { Component, OnInit } from '@angular/core';
declare var google:any;
import * as _moment from 'moment-timezone';
import { GameEventOverview } from '../models/gameevent';

@Component({
  selector: 'app-load-game-series-grade-chart',
  templateUrl: './load-game-series-grade-chart.component.html',
  styleUrls: ['./load-game-series-grade-chart.component.css']
})
export class LoadGameSeriesGradeChartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  generateChart(game: GameEventOverview):void{
    google.charts.load('current', {'packages':['bar']});
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      var data = google.visualization.arrayToDataTable([
        ['Insats', '1', '2', '3', '4', '5'],
        [' ', game.RankingNoOfBad, game.RankingNoOfNeutral, game.RankingNoOfGood,game.RankingNoOfVeryGood,game.RankingNoOfExcellent]
      ]);

      var options = {
        axisTitlesPosition: 'none',
        legend: {
          position:'none',
        },
        hAxis: { textPosition: 'none', titleTextStyle: { color: 'white'}},
        vAxis: { titleTextStyle: { color: 'white'}, title: 'Player grades'},
        colors: ['#FF5722','#FFC107','#4CAF50','#2E7D32','#00BCD4'],
     
        chart: {
         
        },
        backgroundColor: '#303030',
        bars: 'horizontal' // Required for Material Bar Charts.
      };

      var chart = new google.charts.Bar(document.getElementById('chart_grades'));

      chart.draw(data, google.charts.Bar.convertOptions(options));
    }
  }

}
