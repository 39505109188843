import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ObjectiveTask } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';
import { EditTaskDialogComponent } from '../edit-task-dialog/edit-task-dialog.component';
import { UpdateTaskDialogComponent } from '../update-task-dialog/update-task-dialog.component';

@Component({
  selector: 'app-task-status-row',
  templateUrl: './task-status-row.component.html',
  styleUrls: ['./task-status-row.component.css']
})
export class TaskStatusRowComponent implements OnInit {

  @Input() Task: ObjectiveTask;
  @Input() ShowButtons: boolean = true;
  @Input() ShowObjectiveInfo: boolean = false;
  @Output() TaskDeletedEvent = new EventEmitter<string>();
  @Output() TaskChangedEvent = new EventEmitter<number>();

  constructor(private objectiveService: ObjectivesService,private dialog: MatDialog,) { }

  ngOnInit(): void {
  }

''

  editTask(){
    const editTaskDialog = this.dialog.open(EditTaskDialogComponent, {
      data: {
       ObjectiveTask: this.Task
      }
    });
    editTaskDialog.afterClosed().subscribe(result => {
      this.TaskChangedEvent.emit();
     
    });
  }


  completeTask(){
    const newObjectiveDialog = this.dialog.open(UpdateTaskDialogComponent, {
      data: {
       Task: this.Task
      }
    });
    newObjectiveDialog.afterClosed().subscribe(result => {
      this.TaskChangedEvent.emit();
     
    });
  }

  deleteTask(): void{
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete the task?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.objectiveService.deleteTask(this.Task.TaskId).subscribe(data=>{
          if(data)
            this.TaskDeletedEvent.emit(this.Task.TaskId);
        });
      }
    });
  }  

}
