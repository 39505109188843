<div class="app-page" style="margin-left: 20px;">
     

    
        <button mat-raised-button (click)="editOrgMission()">EDIT</button>
        <div style="display: flex; flex-direction: row; margin-left: 30px;">
            <div style="font-family: Roboto; margin-top: 30px;">
                <img src="assets/img/OKR/mission.png" style="width: 40px;">
                <div>MISSION</div>
                
            </div>
            <div *ngIf="this.Mission!=null && this.Mission!=''"  style="display: flex;
            font-family: roboto;
            font-size: large;
            width: 500px;
            align-items: center;
            margin-left: 30px;">
                {{this.Mission}} 
            </div>
            <div *ngIf="this.Mission==null || this.Mission==''" STYLE="width: 300px; margin-left: 100px;">
                <div style="font-family: Roboto; color: gray; margin-top: 30px;">
                    NO MISSION STATEMENT SET
                </div>
               
            </div>
        </div>
      
    
        <br/>

        <div style="display: flex; flex-direction: row; margin-left: 30px;">
            <div style="font-family: Roboto;">
                <img src="/assets/img/OKR/vision.png" STYLE="width: 50px;">
                <div>VISION</div>
            </div>
            <div *ngIf="this.Vision!=null && this.Vision!='' " style="display: flex;
            font-family: roboto;
            font-size: large;
            width: 380px;
            align-items: center;
            margin-left: 30px;">
                {{this.Vision}}

            </div>
            <div *ngIf="this.Vision==null || this.Vision==''" STYLE="width: 300px; margin-left: 100px;">
                <div style="font-family: Roboto; color: gray; margin-top: 30px;">
                    NO VISION STATEMENT SET 

                </div>
               
            </div>
        </div>
    
    <br/>
    <mat-divider></mat-divider>
    <br/>
    <div style="display: flex;flex-direction: row; align-items: flex-end;">
        <div class="title">OBJECTIVES</div>    
        <button mat-button (click)="addObjective('ORGANIZATION')"><mat-icon>add</mat-icon>NEW ORGANIZATION OBJECTIVE</button>
    </div>
    <app-objectives-current-state [Objectives]="this.objectives" *ngIf="this.objectives.length>0"></app-objectives-current-state>        

    
    <app-objective-player-row *ngFor="let obj of this.objectives" [Objective]="obj" (ObjectiveDeletedEvent)="refresh()" (ObjectiveChangedEvent)="refresh()" ></app-objective-player-row>    
    <div *ngIf="this.objectives.length==0" style="width: 300px; height: 200px; margin-left: 100px; align-items: center; display: flex;font-family: Roboto; color: gray">
        NO ORGANIZATION LEVEL OBJECTIVES DEFINED
    </div>
    <br/>
</div>
