import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { GameService } from 'src/app/scouting/services/game.service';
import { Season } from 'src/app/shared/team.model';
declare var google:any;

@Component({
  selector: 'app-game-parametergroup-chart',
  templateUrl: './game-parametergroup-chart.component.html',
  styleUrls: ['./game-parametergroup-chart.component.css']
})
export class GameParametergroupChartComponent implements OnInit {
  @Input() PlayerId: string;
  @Input() Season: Season;
  @Input() GroupId: number;
  @Input() GroupName: string;
  
  constructor(private gameService: GameService ) { }

  ngOnInit() {
    //this.getGroup(this.PlayerId, this.GroupId, this.GroupName);    
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.getGroup(this.PlayerId, this.GroupId, this.GroupName);
    
  }

  getGroup(_playerid: string, _groupId: number, _name: string): void{
    this.gameService.getGamesParameterGroupForPlayerAndSeason_GoogleCharts(_playerid, _groupId, this.Season.SeasonId.toString()).subscribe(datahttp=>{
   

       google.charts.load('current', {'packages':['corechart']});
       google.charts.setOnLoadCallback(drawChart.bind(this));        
       function drawChart() {

        // Create the data table.
        var data = new google.visualization.DataTable(datahttp);

        console.log("data.numbercolumns");
        if(data.getNumberOfColumns()==0) return;

        var columns = [];
        // display these data series by default
        var defaultSeries = [0,1,2,3,4,5,6,7,8,9,10];
        var series = {};
        for (var i = 0; i < data.getNumberOfColumns(); i++) {
            if (i == 0 || defaultSeries.indexOf(i) > -1) {
                // if the column is the domain column or in the default list, display the series
                columns.push(i);
            }
            else {
                // otherwise, hide it
                columns.push({
                    label: data.getColumnLabel(i),
                    type: data.getColumnType(i),
                    sourceColumn: i,
                    calc: function () {
                        return null;
                    }
                });
            }
            if (i > 0) {
               /*  columns.push({
                    calc: 'stringify',
                    sourceColumn: i,
                    type: 'string',
                    role: 'annotation'
                }); */
                // set the default series option
                series[i - 1] = {};
                if (defaultSeries.indexOf(i) == -1) {
                    // backup the default color (if set)
                    if (typeof(series[i - 1].color) !== 'undefined') {
                        series[i - 1].backupColor = series[i - 1].color;
                    }
                    series[i - 1].color = '#CCCCCC';
                }
            }
        }

        //console.log(columns);
        //console.log(this.Season);
        var minDate = new Date(this.Season.SeasonStartDate);
        //minDate.setMonth(minDate.getMonth()-2);

          //Tcikmarks on mondays
          var tickMarks = [];
          var maxDate = new Date(this.Season.SeasonEndDate);
          for (var d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
            if(d.getDay()==1){
              tickMarks.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            }
            
            
          }
          //console.log(minDate);
          //console.log(maxDate);

        var options = {
          title: _name,
          legend: { position: 'bottom', textStyle: {color: 'white', fontSize: 12} },
          titleTextStyle: {color: 'white'},
          backgroundColor: '#303030',
          height: 200,
          chartArea: {width: '95%'},
          //colors: ['#FF5722','#FFC107','#4CAF50','#2E7D32','00BCD4'],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines: { color: 'gray' }, minValue: minDate, maxValue: maxDate, ticks: tickMarks, format: 'd/M'},
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines: { color: 'gray' }, minValue: 0, maxValue: 5, ticks: [0,5,10] },
          
        };
        function showHideSeries () {
          var sel = chart.getChart().getSelection();
          
          // if selection length is 0, we deselected an element
          if (sel.length > 0) {
              // if row is undefined, we clicked on the legend
              if (sel[0].row == null) {
                  var col = sel[0].column;
                  //console.log(typeof(columns[col]));
                  if (typeof(columns[col]) == 'number') {
                      var src = columns[col];
                     
  
                      // hide the data series
                      columns[col] = {
                          label: data.getColumnLabel(src),
                          type: data.getColumnType(src),
                          sourceColumn: src,
                          calc: function () {
                              return null;
                          }
                      };
                     
                      //console.log("saetting gray color")
                      // grey out the legend entry
                      series[src - 1].color = '#CCCCCC';
                  }
                  else {
                      var src = columns[col].sourceColumn;
  
                      // show the data series
                      columns[col] = src;
                      series[src - 1].color = null;
                  }
                  var view = new google.visualization.DataView(data);
                  view.setColumns(columns);
                  chart = new google.visualization.ChartWrapper({
                    chartType: 'LineChart',
                    containerId: 'GameParameterGroup_'+_groupId,
                    dataTable: view,
                    
                    options: options,
                });
                google.visualization.events.addListener(chart, 'select', showHideSeries);
                
                  chart.draw();
              }
          }
      }

        //var chart = new google.visualization.LineChart(document.getElementById('GameParameterGroup_'+_groupId));

        var view = new google.visualization.DataView(data);
        view.setColumns(columns);

        var chart = new google.visualization.ChartWrapper({
          chartType: 'LineChart',
          containerId: 'GameParameterGroup_'+_groupId,
          dataTable: data,
          view: view,
          options: options,
      });

        google.visualization.events.addListener(chart, 'select', showHideSeries);

        chart.draw();
       
        //chart.draw(view, options);
        
      }          

     

    });
  }

}
