import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/baseservice';
import { Observable, of} from 'rxjs';
import { FlowEventModel } from '../models/EventModels';
import { GameEventOverviewViewModel, GameEvent } from '../models/gameevent';
import { Player } from 'src/app/load/models/player';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';

@Injectable({
  providedIn: 'root'
})
export class GameService extends BaseService{

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }

  public getPastGamesForTeam(TeamId: string): Observable<GameEventOverviewViewModel[]>{

    let ret = this.http.get<GameEventOverviewViewModel[]>(this.rootUrl + '/api/load/player/mgr/gamelog/' + TeamId );
   
    return ret;
   }

   public getPastGamesForTeam_MaxRows(TeamId: string, MaxRows: number): Observable<GameEventOverviewViewModel[]>{

    let ret = this.http.get<GameEventOverviewViewModel[]>(this.rootUrl + '/api/load/player/mgr/gamelog/' + TeamId + '/' + MaxRows );
   
    return ret;
   }

   GetPlayersForGameView(GameId: string, TeamId: string): Observable<Player[]> {
  
    return this.http.get<Player[]>(this.rootUrl + '/api/load/players/game/' + GameId + '/' + TeamId).pipe(
      catchError(this.handleError('GetPlayersForGameView', []))
        );
  }  

  GetGameDetails(GameId: string): Observable<GameEvent> {

    return this.http.get<GameEvent>(this.rootUrl + '/api/load/events/gameevent/' + GameId);
                                                    
    
  }


  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {    
      if (error instanceof HttpErrorResponse) {
        console.log("error status=" + error.status);
        if (error.status === 401) {
          // redirect to the login route
          // or show a modal 
          window.location.href="/Account/Login";
          //this.router.navigate(['/notlo//console.log("Redirecting to login");
          
        }else{
          //window.location.href="/Account/Login";
        }
      }
    
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
