import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Test, TestProfile, TestProfileTestLimit, TestResultPlayer, TestTopList, TestViewViewModel } from './models/test.model';
import { Observable, of } from 'rxjs';
import { BaseService } from './baseservice';
import { PlayerPosition } from './models/player.model';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';



@Injectable({
  providedIn: 'root'
})
export class TestService extends BaseService {

 
  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super()
   }
   
  private saveTestUrl = '/api/tests';  // URL to web api
  private saveProfileUrl = '/api/profiles';  // URL to web api

  getAllTests() {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/Tests');
  }

  getMultiplierTests(ProfileId: string) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/Tests/profile/' + ProfileId + '/multipliers');
  }

  getTest(TestId: String) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/test/' + TestId);
  }

  getAvailableTestsForProfile(ProfileId: string){
    return this.http.get(this.rootUrl + '/api/tests/profile/' + ProfileId + '/available');
  }

  saveTest(test: Test): Observable<Test>{
    let ret = this.http.post<Test>(this.rootUrl + this.saveTestUrl,test,this.httpOptions);
    return ret;
  }

  updateTest(test: Test): Observable<Test>{
    console.log(test);
    let ret = this.http.put<Test>(this.rootUrl + '/api/test',test,this.httpOptions);
    return ret;
  }


  addTestToProfile( ProfileId: string, TestId: string): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/profiles/' + ProfileId + '/addtest/' + TestId,"");
    return ret;
  }

  removeTestFromProfile( TestId: string, ProfileId: string){
    let ret = this.http.delete(this.rootUrl + '/api/profiles/' + ProfileId + '/test/' + TestId);
    return ret;
  }


  getAllProfiles() {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/profiles');
  }

  getProfile(ProfileId: String) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/profiles/' + ProfileId);
  }

  getProfileForTeam(Teamid: String) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/profiles/team/' + Teamid);
  }

  getAllTestViews() {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/testviews');
  }

  getTestView(TestViewId: string) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/testviews/' + TestViewId);
  }

  saveProfile(prof: TestProfile): Observable<TestProfile>{
    //console.log('in service');
    //console.log(prof);
    let ret = this.http.post<TestProfile>(this.rootUrl + this.saveProfileUrl,prof,this.httpOptions);
    return ret;
  }

  deleteProfile(ProfileId: string): Observable<string>{
    let ret = this.http.delete<string>(this.rootUrl + this.saveProfileUrl + "/" + ProfileId );

    return ret;
  }

  getTestLimits(ProfileId: string): Observable<TestProfileTestLimit[]>{
    let ret = this.http.get<TestProfileTestLimit[]>(this.rootUrl + '/api/profile/' + ProfileId + '/testlimits' );
    return ret;
  }

  GetAllPositions( TeamId: string): Observable<PlayerPosition[]>{
    let ret = this.http.get<PlayerPosition[]>(this.rootUrl + '/api/playerpositions/team/' + TeamId );

    return ret;
  }

  updateLimits(Limits: TestProfileTestLimit[]): Observable<any>{
    let ret = this.http.post(this.rootUrl + '/api/profile/testlimits' ,Limits,this.httpOptions);
    return ret;
  }
  
  updateLimitSettings(Limits: TestProfileTestLimit): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/profile/testlimits/settings' ,Limits,this.httpOptions);
    return ret;
  }

  getTestTopListsForSeasonTeam(teamId: string){
    let ret = this.http.get<TestTopList[]>(this.rootUrl + '/api/testresult/toplist/' + teamId + '/10/currentseason' );
    return ret;
  }

  getTestTopListsForTeam(teamId: string){
    let ret = this.http.get<TestTopList[]>(this.rootUrl + '/api/testresult/toplist/' + teamId + '/10' );
    return ret;
  }

  SaveTestView(tesview: TestViewViewModel): Observable<Response>{
    let ret = this.http.put<Response>(this.rootUrl + '/api/testviews',tesview,this.httpOptions);
    return ret;
  }

  CreateTestView(tesview: TestViewViewModel){
    let ret = this.http.post<Response>(this.rootUrl + '/api/testviews',tesview,this.httpOptions);
    return ret;
  }

  deleteTestView(TestViewId: string){
    let ret = this.http.delete<string>(this.rootUrl + "/api/testviews/" + TestViewId );

    return ret;
  }

  
  


  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {    
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // redirect to the login route
          // or show a modal 
          window.location.href="/Account/Login";
          //this.router.navigate(['/notlo//console.log("Redirecting to login");
          
        }
      }
    
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
}
}
