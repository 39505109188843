
<div style="color: white; width: 100%; margin-left:20px;" class="app-page">

    <mat-progress-spinner *ngIf="isBusy"
    class="loading-indicator"
    color="primary"
    mode="indeterminate"
></mat-progress-spinner>

    <a mat-raised-button (click)="getData()">
        <mat-icon>refresh</mat-icon>    
        REFRESH</a> &nbsp;
    <a mat-raised-button (click)="sendReminder()" *ngIf="this._userService.roleMatch(['Org_Admin'])">
        <mat-icon>send</mat-icon>    
        SEND REMINDER</a>
    <div class="ds-playerinlist">
        <table class="table table-header-rotated" style="color: white;display: block;  overflow-x: auto;  white-space: nowrap; margin-left: 9em; font-size: small;" >

                <tr>        
                    
                    <th colspan="8" style="text-align: left;" ><div><span>REPORTED STATUS</span></div></th>
                    <th colspan="4" style="text-align: left;" ><div><span>SICKNESS</span></div></th>
                   <!--  <th colspan="7"  style="text-align: left;"  ><div><span>REPORTED LOAD</span></div></th>                        
                     -->
                </tr>
                <tr>        
                        <th colspan="12" ><div><span></span></div></th>
                        <!-- <th colspan="3"  ><div><span>7 DAYS</span></div></th>        -->                 
                      <!--   <th colspan="4"  ><div><span>28 DAYS</span></div></th> -->
                </tr>
                
                <tr>
                        <th ></th>
                        <th class="rotate-45" ><div><span></span></div></th>
                        <th class="rotate-45" ><div><span>FRESHNESS</span></div></th>
                        <th class="rotate-45" ><div><span>CONFIDENCE</span></div></th>            
                        <th class="rotate-45" ><div><span>SLEEP</span></div></th>
                        <th class="rotate-45" ><div><span>MuUSCLE PAIN</span></div></th>
                        <th class="rotate-45" ><div><span>OTHER PAIN</span></div></th>
                        <!-- <th class="rotate-45" ><div><span>HAS FEVER</span></div></th>
                        <th class="rotate-45" ><div><span>TEMPERATURE</span></div></th>
                        <th class="rotate-45" ><div><span>HAS SYMPTOMS</span></div></th>
                        <th class="rotate-45" ><div><span>EXPOSED TO CONTAGION</span></div></th> -->
                        <th class="rotate-45" ><div><span></span></div></th>
                        <th class="rotate-45" ><div><span></span></div></th>
                        <th class="rotate-45" ><div><span></span></div></th>
                        <th class="rotate-45" ><div><span></span></div></th>
                        <th class="rotate-45" ><div><span></span></div></th>
            
            
<!--                         <th class="rotate-45" ><div><span>NO OF EVENTS</span></div></th>
                        <th class="rotate-45" ><div><span>TRAINING</span></div></th>
                        <th class="rotate-45" ><div><span>GAME</span></div></th>
                        <th class="rotate-45" style="min-width: 70px;" ><div><span>RATIO</span></div></th>
                        <th class="rotate-45" ><div><span>NO OF EVENTS</span></div></th>
                        <th class="rotate-45" ><div><span>TRAINING</span></div></th>
                        <th class="rotate-45" ><div><span>GAME</span></div></th> -->
                        
                    
                </tr>


                
<!--             <tr>
                <th Style="position: absolute;width: 5em;margin-left: -5em;max-width: 5em; "></th>
                <td> 
                    
                </td>
                <td style="margin-left: 5em;" colspan="4">Freshness</td>
                            
                
                <td colspan="3">
                        Muskler
                </td>
                
                <td></td>


                <td colspan="3">Belastning - 7 dagar</td>
                <td colspan="3">Belastning - 30 dagar</td>
                
              
            </tr>
            <tr>
                    <th style="position: absolute;width: 5em;margin-left: -5em;max-width: 5em; "></th>
                    <td style="margin-left: 5em;"  colspan="2">Status</td>
                    <td>Självförtroende</td>                                                                        
                    <td colspan="2">Sömn</td>                    
                    <td>Leder</td>
                    <td></td>
                    
                    <td></td>
                    <td>Antal</td>
                    <td>Träning</td>
                    <td>Match</td>
                    <td>Antal</td>
                    <td>Träning</td>
                    <td>Match</td>
                    
             
            </tr>
        
 -->  
    <!-- Players with logins created-->
    <ng-template ngFor let-player [ngForOf]="_players">                    
            <tr *ngIf="player.AddPositionHeaderBefore"  >
                    <th style="position: absolute;max-width: 9em; margin-left: -9em; min-width: 8em; margin-top: 10px; text-transform: uppercase;background-color: white; color: black; ">
                        {{player.Position}}
                        
                    </th>
                    <td colspan="14" style="height: 36px;" >&nbsp;</td>
            </tr>
            <tr  *ngIf="player.UserId!='00000000-0000-0000-0000-000000000000'" style="height: 24px;">
                    <th style="position: absolute;max-width: 9em; margin-left: -9em; font-weight: normal; ">
                        <a [routerLink]="['/player/details',player.PlayerId]" style="color: white; text-decoration: none; ">
                            
                            <div style="display:inline-block; vertical-align: top;margin-right: 3px; font-weight: bold;">
                                {{player.JerseyNo}}
                            </div>
                            <div style="display:inline-block; vertical-align: top;text-align: left;">
                                {{ player.LastName }}, 
                            {{player.FirstName}}                            
                            </div>                   
                        </a>                                       
                    </th>
                    <td>
                            <mat-chip-listbox *ngIf="player.Availability>0">
                                <mat-chip-option  selected="true"  [style.background-color]="availabilityColors[player.Availability]" [style.color]="'white'" style="width: 50px;text-align: center;" *ngIf="player.Availability>0" class="chipvalue" >                                        
                                    {{availabilityText[player.Availability]}}
                                </mat-chip-option>
                              
                            </mat-chip-listbox>    
                         <!--    <span style="color: gray; margin-left: 30px;" *ngIf="player.Availability==0">
                                NO DATA
                            </span> -->
                            
                            
                        </td>                                                       
                    <td >
                        <mat-chip-listbox *ngIf="player.Availability>0">                    
                            <mat-chip-option  selected="true"  [style.background-color]="scaleColors[player.Freshness]" [style.color]="scaleTextColors[player.Freshness]" class="chipvalue">
                                {{player.Freshness}}
                            </mat-chip-option>
                        </mat-chip-listbox>
                    
                    </td>
                    
                    <td>
                        <mat-chip-listbox *ngIf="player.Availability>0">
                            <mat-chip-option  selected="true"  [style.background-color]="scaleColors[player.Confidence]" [style.color]="scaleTextColors[player.Confidence]" class="chipvalue" >
                                {{player.Confidence}}
                            </mat-chip-option>
                            
                        </mat-chip-listbox>    
                    </td>
                    <td>
                        <mat-chip-listbox *ngIf="player.Availability>0">
                            <mat-chip-option  selected="true"  [style.background-color]="scaleColors[player.Sleep]" [style.color]="scaleTextColors[player.Sleep]" class="chipvalue">
                                    {{player.Sleep}}
                            </mat-chip-option>
                        </mat-chip-listbox>    
                    </td>
                    <td>
                        <mat-chip-listbox *ngIf="player.Availability>0">
                            <mat-chip-option  selected="true"  [style.background-color]="painScaleColors[player.Muscles]" [style.color]="painScaleTextColors[player.Muscles]" class="chipvalue">
                                    {{ player.Muscles }}
                            </mat-chip-option>
                        </mat-chip-listbox>
<!--                         <mat-chip-list *ngIf="player.Availability==0">
                            <mat-chip selected="true"  style="color: black; background-color: gray" class="chipvalue">
                                    0
                            </mat-chip>
                        </mat-chip-list> -->
                    </td>
                    <td>
                        <mat-chip-listbox *ngIf="player.Availability>0">
                            <mat-chip-option  selected="true"  [style.background-color]="painScaleColors[player.Joints]" [style.color]="painScaleTextColors[player.Joints]" class="chipvalue">
                                    {{ player.Joints }}
                            </mat-chip-option>
                        </mat-chip-listbox>
                        <!-- <mat-chip-list *ngIf="player.Availability==0">
                            <mat-chip selected="true" style="color: black; background-color: gray" class="chipvalue">
                                    0
                            </mat-chip>
                        </mat-chip-list> -->
                    </td>
                    <td>
                        <mat-chip-listbox *ngIf="player.Availability>0">
                            <mat-chip-option *ngIf="player.HasFever" selected="true"  [style.background-color]="painScaleColors[10]" [style.color]="painScaleTextColors[player.Joints]" class="chipvalue">
                                    FEVER
                            </mat-chip-option>
                        </mat-chip-listbox>                       
                    </td>
                
                <td>
                    <mat-chip-listbox *ngIf="player.Availability>0">
                        <mat-chip-option *ngIf="player.HasFever"   selected="true"  [style.background-color]="painScaleColors[10]" [style.color]="painScaleTextColors[player.Joints]" class="chipvalue">
                                {{ player.BodyTemperature }}
                        </mat-chip-option>
                    </mat-chip-listbox>
                   
                </td>
                <td>
                    <mat-chip-listbox *ngIf="player.Availability>0">
                        <mat-chip-option *ngIf="player.HasSicknessSymptoms" selected="true"  [style.background-color]="painScaleColors[10]" [style.color]="painScaleTextColors[player.Joints]" class="chipvalue">
                                SYMPTOMS
                        </mat-chip-option>
                    </mat-chip-listbox>                       
                </td>
                <td>
                    <mat-chip-listbox *ngIf="player.Availability>0">
                        <mat-chip-option *ngIf="player.ExposedToContagion" selected="true"  [style.background-color]="painScaleColors[10]" [style.color]="painScaleTextColors[player.Joints]" class="chipvalue">
                                EXPOSED
                        </mat-chip-option>
                    </mat-chip-listbox>                       
                </td>






                    <td style="text-align: left;">
                        <ng-container *ngIf="player.Availability>0">
                            <span  [class]="player.TimeAgoStatus" ></span>   
                        
                            {{ player.TimeAgoText  }} ago
                        </ng-container>
                        
                    </td>
                
<!--                     <td style="text-align: center">     
                        <div class="noofevents">
                            {{ player.KPIs.EventCount_7days }}
                        </div>                          
                   
                        
                    </td>
                    <td>
                        <div class="loadvalue">
                            {{ player.KPIs.SumLoadValue_7days }}
                        </div>
                       
                    </td>
                    <td>
                        <div class="loadvaluegames">
                            {{ player.KPIs.SumLoadValueGames_7days }}
                        </div>
                      
                    </td>
                    <td>{{ player.KPIs.LoadRatio7Days30Days | number:'1.2-2'  }}</td>
                    <td style="text-align: center">
                        <div class="noofevents">
                            {{ player.KPIs.EventCount_30days }}
                        </div>
              
                    </td>
                    <td>
                        <div class="loadvalue">
                            {{ player.KPIs.SumLoadValue_30days }}
                        </div>
                  
                    </td>
                    <td>
                        <div class="loadvaluegames">
                            {{ player.KPIs.SumLoadValueGames_30days }}
                        </div>

                    </td> -->
            
            </tr>
        
        <tr  *ngIf="player.UserId=='00000000-0000-0000-0000-000000000000'" style="height: 32px;">
            <th style="position: absolute;max-width: 9em; margin-left: -9em; font-weight: normal; ">
                <a [routerLink]="['/player/details',player.PlayerId]" style="color: white; text-decoration: none; ">
                    
                    <div style="display:inline-block; vertical-align: top;margin-right: 3px; font-weight: bold;">
                        {{player.JerseyNo}}
                    </div>
                    <div style="display:inline-block; vertical-align: top;text-align: left;">
                        {{ player.LastName }}, 
                    {{player.FirstName}}                            
                    </div>                   
                </a>                                       
            </th>
                <td></td>
                <td colspan="12" style="text-align: left; margin-left: 10px; height: 32px; ">
                    <span *ngIf="!player.ActiveInvitationExists" >

                  <span style="color: gray;">
                    PLAYER HAS NO USER ACCOUNT&nbsp;
                </span>
                    <a mat-stroked-button *ngIf="!(player.ActiveInvitationExists)" (click)="openInviteDialog(this.TeamId, player.PlayerId, player.LastName, player.FirstName );">
                            <i class="material-icons">
                                    send
                            </i>
                        SEND INVITATION
                    </a>
                </span>
                    <span style=" color: gray;" *ngIf="player.ActiveInvitationExists">                          
                        INVITITATION SENT (({{player.InviteEmail}})) <span style="color: white; margin-left: 10px; margin-right: 20px; font-size: large;"><b>{{player.AuthorizationCode }}</b></span>
                        <a mat-stroked-button  (click)="cancelInvitation(player.InviteId);" style="color: white">
                            <i class="material-icons">
                                    delete
                            </i>
                            CANCEL
                        </a>
                        &nbsp;
                     <!--    <a mat-stroked-button  (click)="resendInvitation(player.InviteId);" style="color: white">
                            <i class="material-icons">
                                    send
                            </i>
                            RESEND ({{player.InviteEmail}})
                        </a> -->
                       


                    </span>
                </td>
        
            </tr>



    </ng-template>
        <!-- Players with no logins-->
  <!--   <ng-template ngFor let-player [ngForOf]="_players">

            
            <tr *ngIf="player.Position!= this.CurrentPosition">
                <td colspan="13">
                    {{player.Position}}
                </td>
            </tr>
            <tr  *ngIf="player.UserId=='00000000-0000-0000-0000-000000000000'">
                    <th style="position: absolute;max-width: 5em; margin-left: -5em; font-weight: normal; ">
                        <a [routerLink]="['/playeroverview',player.PlayerId]" style="color: white; text-decoration: none;">
                            {{ player.LastName }},<br/>
                            {{player.FirstName}}                            
                        </a>                                       
                    </th>
                    <td colspan="13">
                        <a mat-button>
                                <i class="material-icons">
                                        send
                                </i>
                            SEND INVITATION
                        </a>
                    </td>
            
                </tr>




    </ng-template> -->
    </table>

    </div>


 <!--  <mat-nav-list dense >
      <mat-list-item *ngFor="let player of _players">                 
          <h4 matLine>{{ player.LastName }}, {{ player.FirstName }}</h4>
          <div matLine>          
              <table >
                  <tr>
                    <td></td>
                    <td style="text-align:center;">
                        <div [style.background]="availabilityColors[player.Availability]" style="padding-left: 3px; padding-right: 3px; width: 60px; ">
                            <div>&nbsp;</div>
                            {{ availabilityText[player.Availability] }}
                          </div>
                    </td>                
                    <td style="width: 25px;  text-align: center;">
                      <div [style.background]="scaleColors[player.Freshness]" [style.color]="scaleTextColors[player.Freshness]">
                          <div>Fresh</div>
                        {{player.Freshness}}
                      </div>
                    </td>
                    <td style="width: 25px; text-align: center;">
                        <div [style.background]="painScaleColors[player.Muscles]" [style.color]="scaleTextColors[player.Muscles]">
                            <div>Muskler</div>
                            {{player.Muscles}}
                            
                          </div>
                    </td>
                    <td style="width: 25px; text-align: center;">
                        <div [style.background]="painScaleColors[player.Joints]" [style.color]="scaleTextColors[player.Joints]">
                            <div>Leder</div>
                            {{player.Joints}}
                          </div>
                    </td>
                    <td style="width: 30px; text-align: center;">
                        <div [style.background]="scaleColors[player.Sleep]" [style.color]="scaleTextColors[player.Sleep]">
                            <div>Söm</div>
                            {{ player.Sleep}}
                          </div>
                          
                    </td>
                    <td style="width: 20px; text-align: center;">
                        <div [style.color]="scaleColors[10]"  style="padding-left: 3px; padding-right: 3px;">
                             {{ player.TimeAgoText }}
                        </div>
                    </td>
                  </tr>                 
               </table>
             </div>
       </mat-list-item>      
   </mat-nav-list> -->
</div>
