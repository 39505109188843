<div style="width: 370px; margin: 10px; ">
        <div>
                <img style="height: 48px;" src="../../assets/img/{{this.TeamIcon}}" />LINEUP
        </div>
        <app-text-divider [DividerText]="'FORWARDS'"></app-text-divider>
               <!--  <table style="width: 100%;">
                        <tr>
                                <td><app-lineup-player-card [Player]="this.getPlayer('1ST','LW')"></app-lineup-player-card></td>
                                <td><app-lineup-player-card [Player]="this.getPlayer('1ST','CE')"></app-lineup-player-card></td>
                                <td><app-lineup-player-card [Player]="this.getPlayer('1ST','RW')"></app-lineup-player-card></td>
                        </tr>
                        <tr>
                                <td><app-lineup-player-card [Player]="this.getPlayer('2ND','LW')"></app-lineup-player-card></td>
                                <td><app-lineup-player-card [Player]="this.getPlayer('2ND','CE')"></app-lineup-player-card></td>
                                <td><app-lineup-player-card [Player]="this.getPlayer('2ND','RW')"></app-lineup-player-card></td>
                        </tr>
                        <tr>
                                <td><app-lineup-player-card [Player]="this.getPlayer('3RD','LW')"></app-lineup-player-card></td>
                                <td><app-lineup-player-card [Player]="this.getPlayer('3RD','CE')"></app-lineup-player-card></td>
                                <td><app-lineup-player-card [Player]="this.getPlayer('3RD','RW')"></app-lineup-player-card></td>
                        </tr>
                        <tr>
                                <td><app-lineup-player-card [Player]="this.getPlayer('4TH','LW')"></app-lineup-player-card></td>
                                <td><app-lineup-player-card [Player]="this.getPlayer('4TH','CE')"></app-lineup-player-card></td>
                                <td><app-lineup-player-card [Player]="this.getPlayer('4TH','RW')"></app-lineup-player-card></td>
                        </tr>
                </table> -->
                
                <app-text-divider [DividerText]="'DEFENDERS'"></app-text-divider>
               <!--  <table style="width: 100%;">
                        <tr>
                                <td><app-lineup-player-card [Player]="this.getPlayer('1ST','LD')"></app-lineup-player-card></td>
                                
                                <td><app-lineup-player-card [Player]="this.getPlayer('1ST','RD')"></app-lineup-player-card></td>
                        </tr>
                        <tr>
                                <td><app-lineup-player-card [Player]="this.getPlayer('2ND','LD')"></app-lineup-player-card></td>
                                
                                <td><app-lineup-player-card [Player]="this.getPlayer('2ND','RD')"></app-lineup-player-card></td>
                        </tr>
                        <tr>
                                <td><app-lineup-player-card [Player]="this.getPlayer('3RD','LD')"></app-lineup-player-card></td>
                                
                                <td><app-lineup-player-card [Player]="this.getPlayer('3RD','RD')"></app-lineup-player-card></td>
                        </tr>
                        <tr>
                                <td><app-lineup-player-card [Player]="this.getPlayer('4TH','LD')"></app-lineup-player-card></td>
                                
                                <td><app-lineup-player-card [Player]="this.getPlayer('4TH','RD')"></app-lineup-player-card></td>
                        </tr>
                </table> -->
                    

       
        <app-text-divider [DividerText]="'GOALTENDERS'"></app-text-divider>
       <!--  <table style="width: 100%">
                <tr>
                        <td><app-lineup-player-card [Player]="this.getPlayer('1ST','GT')"></app-lineup-player-card></td>
                        
                        <td><app-lineup-player-card [Player]="this.getPlayer('2ND','GT')"></app-lineup-player-card></td>
                </tr>
        </table> -->
   
 
</div>