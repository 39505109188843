Status
<div id="{{'PhysStatus_' + uniqueid + '_'  + this.PlayerId}}">

</div>
<div id="{{'Fever_' + uniqueid + '_'  + this.PlayerId}}">

</div>
<!-- <div  style="display: flex; color: white; font-family: Roboto; font-size: small;">
        <div style="width:10px; height  :10px; background-color:#4285f4; border: 1px solid white ;">
          </div>
          <div style="margin-left: 5px; margin-right: 5px;">
              &nbsp;{{ 'Freshness' | translate }}
          </div>
          <div style="width:10px; height:10px; background-color: white; border: 1px solid white;">
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
                &nbsp;{{ 'Confidence' | translate }}
            </div>
          <div style="width:10px; height:10px; background-color:#008744; border: 1px solid white;">
          </div>
          <div style="margin-left: 5px; margin-right: 5px;">
              &nbsp;{{ 'Sleep' | translate }}
          </div>
          <div style="width:10px; height:10px; background-color:#d62d20;border: 1px solid white ;">
          </div>
          <div style="margin-left: 5px; margin-right: 5px;">
              &nbsp;{{ 'Muscle pain' | translate }}
          </div>
          <div style="width:10px; height:10px; background-color:#ffa700;border: 1px solid white ;">
          </div>
          <div>
              &nbsp;{{ 'Other pain' | translate }}
          </div>
    </div> -->
