import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { startWith, map } from 'rxjs';
import { ProgramModel, WorkoutModel } from 'src/app/configuration/training/models/exercise.model';
import { TrainingService } from 'src/app/configuration/training/services/training.service';
import { NewEquipmentDialogComponent } from 'src/app/configuration/training/views/new-equipment-dialog/new-equipment-dialog.component';
import { PlayerListViewModel } from 'src/app/scouting/models/PlayerModels';
import { TeamViewModel } from 'src/app/scouting/models/TeamModels';
import { PlayerService } from 'src/app/shared/player.service';
import { TeamService } from 'src/app/shared/team.service';
import { AssignedProgramService } from '../../service/assigned-program.service';
import { AssignedWorkoutService } from '../../service/assigned-workout.service';

@Component({
  selector: 'app-assign-workout-dialog',
  templateUrl: './assign-workout-dialog.component.html',
  styleUrls: ['./assign-workout-dialog.component.css']
})



export class AssignWorkoutDialogComponent implements OnInit {

  @Input() TeamId;
  @Input() PlayerId;
  @Input() WorkoutId;
  @Input() PlannedDate;

  @ViewChild('playerInput') playerInput: ElementRef<HTMLInputElement>;


  separatorKeysCodes: number[] = [ENTER, COMMA];
  
  teams: TeamViewModel[];  
  assignedWorkoutForm: UntypedFormGroup;
  players: PlayerListViewModel[] = [];
  workouts: WorkoutModel[] = [];

  playerCtrl = new UntypedFormControl('');
  

  constructor(
    private _trainingSrvc: TrainingService,  
    public _snackBar: MatSnackBar,
    public route: ActivatedRoute,
    //private _location: Location,
    public fb: UntypedFormBuilder,    
    private dialog: MatDialog,
    private teamService: TeamService,
    private _assignedWoService: AssignedWorkoutService,
    private playerSrv: PlayerService,
    public dialogRef: MatDialogRef<AssignWorkoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {

  }
 

  ngOnInit(): void {
    
    this.TeamId = this.data.TeamId;
    this.PlannedDate = this.data.PlannedDate;
    
    //console.log('planneddate:' + this.PlannedDate);
    
    this.playerSrv.getAllPlayersInCurrentSeason().subscribe(players=>{
      this.players = players as PlayerListViewModel[];
    
    });    

    this.assignedWorkoutForm = this.fb.group({
      WorkoutId: [this.WorkoutId, [Validators.required]],
      PlannedDate: [this.PlannedDate, [Validators.required]],
      TeamId: this.TeamId,
      AssignedPlayers: this.fb.array([])      
    })

    this.teamService.getTeams().subscribe((data: any) =>{
      this.teams = data;
    });

    this._trainingSrvc.getWorkouts().subscribe(result=>{
      this.workouts = result as WorkoutModel[];
   
    });


  }

  get assignedPlayers(): UntypedFormArray{
    return this.assignedWorkoutForm.get('AssignedPlayers') as UntypedFormArray;
  }

  add(event: MatChipInputEvent): void {    
    var pppp: PlayerListViewModel;
    pppp = JSON.parse(event.value);

    // Add our player
    if (pppp) {
      this.players.push(pppp);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.playerCtrl.setValue(null);
  }

  remove(playerId): void {
    var index = this.assignedPlayers.value.findIndex(obj=> obj.PlayerId===playerId);
    console.log(index);
    if(index>-1)
      this.assignedPlayers.removeAt(index);
    
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    //console.log(event.option.value);
   /*  this.players.push(event.option.viewValue);*/
    var pppp: PlayerListViewModel;
    //pppp = JSON.parse(event.option.value);
    pppp = event.option.value;
    this.addPlayer(pppp.Player);
    this.playerInput.nativeElement.value = ''; 
    this.playerCtrl.setValue(null);
  }

  addPlayer(player){
    this.assignedPlayers.push(this.fb.group({
      LifelineScoutingId: player.LifelineScoutingId,
      FirstName: player.FirstName,
      LastName: player.LastName
    }));
  }

/* 
  addPlayer(player){
    this.assignedPlayers.push(this.fb.group({
      PlayerId: player.LifelineScoutingId,
      FirstName: player.FirstName,
      LastName: player.LastName
    }));
  } */

  save(){

    //console.log(this.assignedWorkoutForm);
    //console.log(this.assignedWorkoutForm.value);
    this._assignedWoService.addAssignedWorkout(this.assignedWorkoutForm.value).subscribe(result=>{
      if(result.Success){
          this.dialogRef.close();
      }
    });

  }

  onCancel(){
    this.dialogRef.close();
  }

}


