<h2 mat-dialog-title>WORKOUT DETAILS</h2>
<div mat-dialog-content>
    <div style="display: flex; flex-direction: column;">
        <mat-form-field>
          <mat-label>Planned date</mat-label>
          <input matInput [value]="data.PlannedDate | date:'yyy-MM-dd'" disabled>
        </mat-form-field>
        <div *ngIf="data.PlannedFor!=null" style="color: rgba(255, 255, 255, 0.5)">
            <mat-label style="font-size: small;">Assigned to</mat-label><br/>
            <mat-icon  style="font-size: medium; width: 15px; ">shield</mat-icon>
            <mat-label>{{data.PlannedFor}}</mat-label>
        </div>
        <div  *ngIf="data.AssignedPlayers && data.AssignedPlayers.length>0" style="color: rgba(255, 255, 255, 0.5)"  > 
          <mat-label style="font-size: small;">Assigned to</mat-label>
          <div style="color: white;font-size:x-small;display: flex; flex-direction: row;flex-wrap: wrap;">                                                
              <div *ngFor="let player of data.AssignedPlayers" style="display: flex; flex-direction: row;color: rgba(255, 255, 255, 0.5) ">
                  <mat-icon style="font-size:small; width: 16px;color: rgba(255, 255, 255, 0.5)">
                      person
                  </mat-icon>                                
                  {{player.LastName}}, {{player.FirstName}}&nbsp;
              </div>
              
          </div>
        </div>
        
        

        <app-display-workout-card [Workout]="data.Workout"></app-display-workout-card>
       <mat-divider></mat-divider>
       
        
        <ng-container *ngIf="data.AssignedProgram">
          <mat-label style="color: darkgray;">INCLUDED IN</mat-label>
          <div style="display: flex; flex-direction: row;">
            <mat-icon style="font-size: large;"> table_chart </mat-icon>
            &nbsp;
            <div style="font-size: large;">{{data.AssignedProgram.Title}}</div>  &nbsp;
            <div class="status-card" [style.background-color]="statuscardcolor(data.AssignedProgram.Status)">
              {{data.AssignedProgram.Status}}
          </div>
          </div>
       
          <div *ngIf="data.AssignedProgram.LevelName!=null" style="display: flex; flex-direction: row; ">
            <mat-icon  style="font-size: medium; width: 15px; margin-left: 25px;">shield</mat-icon>
            <div style="font-size: small;">{{data.AssignedProgram.LevelName}}</div>
  
          </div>
          <div  *ngIf="data.AssignedProgram.AssignedPlayers && data.AssignedProgram.AssignedPlayers.length>0 "  > 
            <div style="color: white;font-size:x-small;display: flex; flex-direction: row;margin-left: 25px; flex-wrap: wrap;">                                                
              
                <div *ngFor="let player of data.AssignedProgram.AssignedPlayers" style="display: flex; flex-direction: row; ">
                    <mat-icon style="font-size:small; width: 16px;">
                        person
                    </mat-icon>                                
                    {{player.LastName}}, {{player.FirstName}}&nbsp;
                </div>
                
            </div>
        </div>
  
         

        </ng-container>
        
        

    </div>
    
 </div>
 <div mat-dialog-actions>
   
   <button mat-button (click)="onNoClick()">CLOSE</button>
   <button mat-button (click)="onDelete()" color="warning">DELETE</button>
 
 </div>