import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { FormViewModel } from 'src/app/shared/models/FormModels';

@Component({
  selector: 'app-form-admin-card',
  standalone: true,
  imports: [SharedModuleModule, CommonModule, RouterModule],
  templateUrl: './form-admin-card.component.html',
  styleUrl: './form-admin-card.component.css'
})
export class FormAdminCardComponent {
  @Input() Form: FormViewModel;

}


