<form [formGroup]="objectiveForm" (ngSubmit)="onSubmit()">
    <input type="hidden" id="ObjectiveId" formControlName="ObjectiveId">
    <input type="hidden" id="ObjectiveLevel" formControlName="ObjectiveLevel">
    <input type="hidden" id="TeamId" formControlName="TeamId">
<h2 mat-dialog-title>NEW OBJECTIVE</h2>
<mat-dialog-content>
  
        <div style="font-family: Roboto;">
          An objective is what you want to do. It describes your<br/>
          mission-supporting goal and sets a deadline for achieving it.<br/>
          <br/>
          SMART objectives:<br/>
          <b>S</b>pecific (simple, sensible, significant).<br/>
          <b>M</b>easurable (meaningful, motivating).<br/>
          <b>A</b>chievable (agreed, attainable) but far reaching.<br/>
          <b>R</b>elevant (reasonable, realistic and resourced, results-based).<br/>
          <b>T</b>ime bound (time-based, time limited, time/cost limited, timely, time-sensitive).<br/>
          <br/>

        </div>
        
        <mat-form-field appearance="fill" style="width: 380px;">
            <mat-label>Objective title</mat-label>
            <input matInput id="Title" type="text" formControlName="Title">
        </mat-form-field>
        <br/>
        <mat-form-field appearance="fill" style="width: 380px;">
            <mat-label>Decription</mat-label>
            <textarea  matInput id="Description" type="text" formControlName="Description"></textarea>
        </mat-form-field>
        <br/>
        <mat-form-field appearance="fill">
            <mat-label>Objective owner</mat-label>
            <mat-select formControlName="Owner">              
              <mat-option *ngFor="let usr of this.users" [value]="usr.UserId">{{usr.LastName}}, {{usr.FirstName}}</mat-option>
            </mat-select>
          </mat-form-field>
          <br/>
          <mat-form-field appearance="fill">
            <mat-label>Start date</mat-label>
            <input matInput [matDatepicker]="pickerstart" formControlName="StartDate">
            <mat-datepicker-toggle matSuffix [for]="pickerstart"></mat-datepicker-toggle>
            <mat-datepicker #pickerstart></mat-datepicker>
          </mat-form-field>
          <br/>
          <mat-form-field appearance="fill" class="smallinput">
            <mat-label>Target date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="TargetDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <br/>
          <div style="font-family: Roboto;">
            Add a parent objective if it relates to a higher level objective.
          </div>
          <mat-form-field appearance="fill" style="width: 500px;">
            <mat-label>Parent Objective</mat-label>
            <mat-select formControlName="ParentObjectiveId">
              <mat-option value="">NO PARENT OBJECTIVE</mat-option>
              <mat-option *ngFor="let obj of this.Objectives" [value]="obj.ObjectiveId">{{obj.Title}}</mat-option>
            </mat-select>
          </mat-form-field>
         
        

</mat-dialog-content>
<mat-dialog-actions>
  
  <button mat-button type="submit" [disabled]="!objectiveForm.valid">CREATE</button>
  <button mat-button mat-dialog-close>CANCEL</button> 
</mat-dialog-actions>
    
    
</form>


