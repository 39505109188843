import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';
import { BaseService } from './baseservice';

@Injectable({
  providedIn: 'root'
})
export class GameAssessmentService extends BaseService {


  constructor(private http: HttpClient) {
    super();
   }

   getAll() {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/gameassessments');
  }


}
