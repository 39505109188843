<div class="app-page">
    <div class="app-page" style="margin-left: 20px;">
        <form [formGroup]="searchForm">
            
          <mat-form-field appearance="fill"  style="font-size: small;" >
            <mat-label>Last name</mat-label>
            <input matInput formControlName="PlayerLastName" placeholder="Last name" required>
            <mat-hint>Use * as wildcard</mat-hint>
          </mat-form-field>&nbsp;
          <mat-form-field  appearance="fill"  style="font-size: small;">
            <mat-label>First name</mat-label>
            <input matInput formControlName="PlayerFirstName" placeholder="First name" >
            <mat-hint>Use * as wildcard</mat-hint>
          </mat-form-field>&nbsp;
            <mat-form-field  appearance="fill"  style="font-size: small;">
              <mat-label>Positions</mat-label>
              <mat-select formControlName="PositionIds" id="PositionIds" multiple>
                <mat-option value="1">Goaltender</mat-option>
                <mat-option value="2">Defender</mat-option>
                <mat-option value="4">Forward</mat-option>
                <mat-option value="5">Center</mat-option>
              </mat-select>
            </mat-form-field>&nbsp;
            <mat-form-field  appearance="fill" style="font-size: small;">
              <mat-label>Year of birth</mat-label>
              <mat-select formControlName="YearsOfBirth" id="YearsOfBirth" multiple>
                <mat-option *ngFor="let year of availableBirthYears" [value]="year">{{year}}</mat-option>
                     
              </mat-select>
            </mat-form-field>&nbsp;
            <mat-form-field  appearance="fill" style="font-size: small;">
                <mat-label>Shoots/glove</mat-label>
                <mat-select formControlName="ShootIds" id="ShootIds" multiple>
                  <mat-option value="1">Left</mat-option>
                  <mat-option value="2">Right</mat-option>             
                </mat-select>
              </mat-form-field>&nbsp;
              <div style="font-family: Roboto;">
                Report parameters
            </div>
              <mat-form-field  appearance="fill" style="font-size: small;" *ngIf="this.reportMasterdata">
                <mat-label>Projection</mat-label>
                <mat-select formControlName="Projections" id="Projections" multiple>
                  <mat-option *ngFor ="let proj of this.reportMasterdata.Projection" [value]="proj.Ranking">
                    {{ proj.Text}}<span *ngIf="proj.Hidden" style="color: gray;"> (hidden)</span>
                  </mat-option>
                  
                </mat-select>
              </mat-form-field>&nbsp;
             <mat-form-field  appearance="fill" style="font-size: small;" *ngIf="this.reportMasterdata">
                <mat-label>Capacity min</mat-label>
                <mat-select formControlName="Capacities" id="Capacities" multiple>
                    <mat-option *ngFor ="let proj of this.reportMasterdata.Capacity" [value]="proj.Ranking">
                      {{ proj.Text}}
                      <span *ngIf="proj.Hidden" style="color: gray;"> (hidden)</span>
                    </mat-option>         
                  
                </mat-select>
              </mat-form-field>&nbsp;
              <mat-form-field appearance="fill" style="font-size: small;">
                <mat-label >Report date from</mat-label>
                <input matInput [matDatepicker]="pickerfrom" formControlName="minReportDate"   name="minReportDate">
                <mat-datepicker-toggle matSuffix [for]="pickerfrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerfrom></mat-datepicker>
            </mat-form-field>&nbsp;
            <mat-form-field appearance="fill" style="font-size: small;">
                <mat-label >Report date to</mat-label>
                <input matInput [matDatepicker]="pickerto" formControlName="maxReportDate"   name="maxReportDate">
                <mat-datepicker-toggle matSuffix [for]="pickerto"></mat-datepicker-toggle>
                <mat-datepicker #pickerto></mat-datepicker>
            </mat-form-field>&nbsp;


              <mat-form-field  appearance="fill" style="font-size: small;">
                <mat-label>Max no of rows</mat-label>
                <mat-select formControlName="MaxHits" id="MaxHits" >
                    <mat-option selected value="50">50</mat-option>         
                    <mat-option value="100">100</mat-option>         
                    <mat-option value="200">200</mat-option>         
                    <mat-option value="500">500</mat-option>         
                  
                </mat-select>
              </mat-form-field>
            <br/>
            <a mat-raised-button color="primary" (click)="this.searchReports()" >SEARCH</a>
        </form>

        <mat-progress-spinner
          color="primary"
          *ngIf="this.isBusy"        
          mode="indeterminate"
        ></mat-progress-spinner>
        <table mat-table [dataSource]="dataSource" matSort style="margin-left: 20px; width: 100%;" >

            <ng-container matColumnDef="Player">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Player </th>
                <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.PlayerLastName}}, {{element.PlayerFirstName}}  </td>
              
              </ng-container>
            <ng-container matColumnDef="PlayerFirstName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> FirstName </th>
              <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.PlayerFirstName}} </td>
           
            </ng-container>     
            <ng-container matColumnDef="DateOfBirth">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of birth </th>
              <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.DateOfBirth | date:'yyy-MM-dd'}} </td>
           
            </ng-container>  
            <ng-container matColumnDef="DateCreated">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ReportDate </th>
                <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.DateCreated | date:'yyy-MM-dd'}} </td>
             
              </ng-container>  
            <ng-container matColumnDef="Weight">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Weight </th>
              <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Weight}} </td>
           
            </ng-container>    
            <ng-container matColumnDef="Height">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Height</th>
              <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Height}} </td>
           
            </ng-container> 
            <ng-container matColumnDef="TotalQualityText">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Projection </th>
                <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.TotalQualityText}} </td>
             
              </ng-container>     
              <ng-container matColumnDef="PlayerCapacityText">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Capacity </th>
                <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.PlayerCapacityText}} </td>
             
              </ng-container>        
              <ng-container matColumnDef="CreatedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created by </th>
                <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.CreatedByLastName}}, {{element.CreatedByFirstName}}  </td>
             
              </ng-container>        
            <ng-container matColumnDef="Actions">
              <th mat-header-cell *matHeaderCellDef > Actions </th>
            <td mat-cell *matCellDef="let element" style="font-size: 12px;">
              <button mat-icon-button (click)="openViewReport(element.ReportId)">
                <mat-icon style="font-size: 24px; opacity: 0.6;">
                  preview
                </mat-icon>
            
              </button>
              <button mat-icon-button style="opacity: 0.6;" (click)="openPlayer(element.PlayerId)">
                <mat-icon>
                  account_box
                </mat-icon>
              </button>
              <a mat-button [routerLink]="['/scouting/playerdetails', element.PlayerId]">VIEW</a>
            </td>
           
            </ng-container>    
                
         
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 12px;;"></tr>
          <!--   <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" style="height: 24px;"></tr> -->
          </table>
          <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons matSort style="margin-left: 20px;"></mat-paginator>

</div>
