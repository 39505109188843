import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BaseService } from './baseservice';
import { Observable } from 'rxjs';
import { NewInjuryModel } from './models/injurymodel';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';


@Injectable({
  providedIn: 'root'
})
export class InjuryService extends BaseService {

  constructor(private http: HttpClient) {
    super();
   }

   getForNewInjury(PlayerId: string, TeamId: string) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    if(PlayerId==null|| PlayerId=='')
      PlayerId = '00000000-0000-0000-0000-000000000000';
    return this.http.get(this.rootUrl + '/api/injury/new/' + PlayerId + '/' + TeamId);
  }

  getForEditInjury(InjuryId: string) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/injury/edit/' + InjuryId);
  }

  saveInjury(injury: NewInjuryModel[]): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/injury/add',injury,this.httpOptions);
    return ret;
  }

  deleteInjury(InjuryId: string): Observable<boolean>{
    let ret = this.http.post<boolean>(this.rootUrl + '/api/injury/delete/' + InjuryId, null,this.httpOptions);
    return ret;
  }

  getAllByTeam(TeamId: string, SeasonId: number) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/injury/allbyteam/' + TeamId + '/' + SeasonId);
  }

  getCurrentByTeam(TeamId: string, SeasonId: number) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/injury/currentbyteam/' + TeamId + '/' + SeasonId );
  }

  getOverviewByTeam(TeamId: string) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/injury/overview/' + TeamId );
  }

  
}
