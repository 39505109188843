<div class="app-page">
    <div style="font-size: large; font-weight: bold;">{{this.event.Title}}</div><br/>
    <mat-icon style="font-size:medium;">
        event
    </mat-icon>
    <b>{{this.event.EventDate | date:'yyyy-MM-dd HH:mm'}} </b>
    &nbsp; &nbsp;
    <mat-icon style="font-size:small; width: 12px;height: 16px;">
        schedule
    </mat-icon>

    {{this.event.Duration}} min
    &nbsp; &nbsp;
    <mat-icon style="font-size:small; width: 12px;height: 16px;">
    bolt
    </mat-icon>
    {{this.event.LoadValue}} ({{this.event.LoadLevel}})
    &nbsp; &nbsp;
    <mat-icon style="font-size:medium; height: 16px; width: 16px;">
        location_on
    </mat-icon>
    {{this.event.Location}}
    <div class="TeamEventNote">
        <mat-icon style="font-size:medium; height: 16px; width: 16px;">
            notes
        </mat-icon>
        {{this.event.Note}}
    </div>
    <div style="font-size:small;">
        <mat-icon style="font-size:small; width: 12px;height: 16px;" *ngIf="this.event.FullTeamEvent">
            shield
        </mat-icon>

    </div>
    <div class="TeamEventLocation">
       
    </div>
    <div style="font-weight: bold;">Player feedback</div>
    <div *ngIf="this.event.PlayerLogs.length==0" style="height: 286px; background-color: #303030;">
        No player feedback yet
    </div>
<!--     <div style="height: 20px; background-color: #303030;">
        Not participating
        <ng-container *ngFor="let log of this.event.PlayerLogs">
                <mat-icon style="color: orangered;" *ngIf="log.NoParticipationReason>0">person</mat-icon>

        </ng-container>
       
    </div> -->
    <app-player-feeling-chart [LogSummary]="this.event.LogSummary" *ngIf="this.event.LogSummary && this.event.PlayerLogs.length>0"></app-player-feeling-chart>
    <app-player-rpe-chart [EventId]="this.event.EventId" [LogSummary]="this.event.LogSummary" *ngIf="this.event.LogSummary && this.event.PlayerLogs.length>0"></app-player-rpe-chart>
    <div *ngIf="this.event.PlayerLogs.length>0" style=" background-color: #303030; color: gray;">
        {{this.event.PlayerLogs.length}} players have reported <ng-container *ngIf="this.NoParticipationCount>0">({{this.NoParticipationCount}} did not participate)</ng-container>
    </div>



</div> 