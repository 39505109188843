<div class="app-page" style="margin-left: 20px;">
    <div style="display: flex; flex-direction: row;">
        <div>
            <img src="/assets//img/{{this.team.SmallIconFileName}}" style="width: 100px;">
        </div>
        <div>
            <div class="PageTitle"> {{this.team.Name }}</div>
            <div class="PageSubTitle">{{this.team.Level }} ({{this.team.Gender}})</div>
        </div>
    </div>
    <br/>
    <mat-divider></mat-divider>
    <br/>
    
    

    <div class="title">TEAM LEVEL OBJECTIVES</div>    
    <app-objectives-current-state [Objectives]="this.objectivesTeam" *ngIf="this.objectivesTeam.length>0"></app-objectives-current-state>        
    <button mat-raised-button (click)="addObjective('TEAM')">NEW TEAM OBJECTIVE</button>
    <app-objective-player-row *ngFor="let obj of this.objectivesTeam" [Objective]="obj" (ObjectiveDeletedEvent)="refresh()" (ObjectiveChangedEvent)="refresh()"></app-objective-player-row>    
    <div *ngIf="this.objectivesTeam.length==0" style="width: 300px; height: 200px; margin-left: 100px; align-items: center; display: flex;font-family: Roboto; color: gray">
        NO TEAM LEVEL OBJECTIVES DEFINED
    </div>
    <br/>
    <mat-divider></mat-divider>
    <br/>
    
    <div class="title">INDIVIDUAL LEVEL OBJECTIVES</div>    
    <app-objectives-current-state [Objectives]="this.objectives" *ngIf="this.objectives.length>0"></app-objectives-current-state>        
    <button mat-raised-button (click)="addObjective('INDIVIDUAL')">NEW INDIVIDUAL OBJECTIVE</button>    
    
    <div *ngIf="this.objectives.length==0" style="width: 300px; height: 200px; margin-left: 100px; align-items: center; display: flex; font-family: Roboto; color: gray;">
        NO INDIVIDUAL OBJECTIVES DEFINED
    </div>
    <app-objective-player-row *ngFor="let obj of this.objectives" [Objective]="obj" (ObjectiveDeletedEvent)="refresh()"  (ObjectiveChangedEvent)="refresh()" ></app-objective-player-row>    
  
    
    
    
    
    
</div>
