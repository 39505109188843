import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';
import { BaseService } from './baseservice';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService {


  constructor(private http: HttpClient) {
    super();
   }
   

  sendPushReminder_for_StatusUpdate(TeamId: string){
    return this.http.get(this.rootUrl + '/api/load/push/statusupdate/' + TeamId);
  }

  sendPushReminder_for_GameAssessment(OrgId: string){
    return this.http.get(this.rootUrl + '/api/load/push/gamereport/' + OrgId);
  }
}
