<div class="app-page">
  <mat-toolbar style="height: 60px;"  >
    <!--  <mat-form-field style="font-size: 14px;">
       <mat-label>Team</mat-label>
       <mat-select [formControl]="teamFormControl" (selectionChange)="refreshData()" >
         <mat-option *ngFor="let team of _teams" [value]="team">
           {{team.Level}}
         </mat-option>        
       </mat-select>
     </mat-form-field> -->
   
     <mat-form-field style="font-size: 14px;" [style.display]="this.ShowFilter">
       <mat-label>Roster - Season</mat-label>
       <mat-select [formControl]="seasonFormControl"  (selectionChange)="refreshData()">
         <mat-option *ngFor="let season of _seasons"  [value]="season">
           {{season.Name}}
         </mat-option>
         
       </mat-select>
     </mat-form-field>
     &nbsp;
     <mat-form-field  style="font-size: 14px;" [style.display]="this.ShowFilter">
        <mat-label>Testresults - search last date</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="lastDateField" (dateChange)="changeDate()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
     </mat-form-field>
     &nbsp;
     <mat-form-field style="font-size: 14px;" >
      <mat-label>Testresults - search period</mat-label>
      <mat-select [formControl]="periodFormControl"  (selectionChange)="changePeriod()">
        <mat-option value="1D">
          1 day back
        </mat-option>
        <mat-option value="1W">
          1 week back
        </mat-option>
        <mat-option value="1M">
          1 month back
        </mat-option>
        <mat-option value="3M" selected>
          3 months back 
        </mat-option>
        <mat-option value="Season" selected>
          From start of  season 
        </mat-option>
        <mat-option value="FromStart" selected>
          Anytime
        </mat-option>
        
        
      </mat-select>
    </mat-form-field>
    <a mat-button [routerLink]="['/teamplayers', this.TeamId]"  routerLinkActive="active-list-item"> <img style="height: 24px; " src="../../assets/img/icons8-user-shield-48.png" >
      CARDS
    </a>

    <!--  <mat-form-field  style="font-size: 14px;" [style.display]="this.ShowFilter">
      <mat-label>Date range for test results</mat-label>
      <mat-date-range-input  [rangePicker]="picker">
        <input matStartDate [formControl]="startDate" placeholder="Start date">
        <input matEndDate [formControl]="endDate" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    
      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field> -->

   <!--  <div style="display: flex; flex-direction: row;">
     
      <mat-button-toggle-group [formControl]="period" style="font-size: small;font-family: Roboto;" (change)="changePeriod($event.value)" >
        <mat-button-toggle value="1W">1 W</mat-button-toggle>
        <mat-button-toggle value="1M">1 M</mat-button-toggle>
        <mat-button-toggle value="3M">3 M</mat-button-toggle>
        <mat-button-toggle value="Season">Season</mat-button-toggle>
        <mat-button-toggle value="FromStart">From start</mat-button-toggle>
      </mat-button-toggle-group>
      &nbsp;
      <div style="font-family: Roboto; font-size: x-small; color: gray;">
        How far back to look for latest result
      </div>
    </div> -->
      
    
      
     
   </mat-toolbar>


    
   <div class="loading-indicator" *ngIf="this.isBusy">
    <mat-progress-spinner mode="indeterminate" ></mat-progress-spinner>
  </div> 
<table mat-table [dataSource]="dataSource" matSort >
    <ng-container matColumnDef="Name" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element" style="font-size: 12px;" >
          <a [routerLink]="['/playerdetails', element.PlayerId, this.Profile.ProfileId, this.TeamId, this.seasonFormControl.value.SeasonId]"         
          style="color: white; text-decoration: none; ">
           
         {{element.Name}}
        </a>
       </td>
      <td mat-footer-cell *matFooterCellDef style="font-size: 10px;"> 
        Average<br/>
        Median
       </td>
    </ng-container>
   
    <ng-container *ngFor="let test of this.Tests " matColumnDef="{{'Test_' + test.TestId}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{test.Name}} </th>
      <td mat-cell *matCellDef="let element" class="valuecolor_{{element['Test_Ranking_' + test.TestId]}}_{{element['WARN_Result_Not_Current_' + test.TestId]}}" matTooltip="{{element['PerformedDate_' + test.TestId]}}" matTooltipClass="tooltip_large" ><div style="border-color: black;">{{element['Test_' + test.TestId]}}</div> </td>
      <td mat-footer-cell *matFooterCellDef style="font-size: 10px;">
        {{this.averages.get(test.TestId) }}<br/>
        {{this.median.get(test.TestId) }}
      </td>
    </ng-container>

    
   
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 12px;;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" style="height: 24px;"></tr>
  </table>

</div>