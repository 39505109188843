<div class="app-page">
    <mat-toolbar style="height: 60px;">
        <h3>TEAM SETTINGS</h3> &nbsp;
       
         
         
    </mat-toolbar>

       <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >

      
        <ng-container matColumnDef="Setting">
          <th mat-header-cell *matHeaderCellDef> Setting </th>
          <td mat-cell *matCellDef="let element"> {{element.Setting}} </td>
        </ng-container>
        <ng-container matColumnDef="Value">
            <th mat-header-cell *matHeaderCellDef> Value </th>
            <td mat-cell *matCellDef="let element"> {{element.Value}} </td>
          </ng-container>
         
       
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 12px;;"></tr>
        
      </table>

      <mat-paginator [pageSizeOptions]="[10, 20, 50]"   [pageSize]="10" showFirstLastButtons style="margin-left: 10px;"></mat-paginator>
       
</div>