import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-player-details-game-list',
  templateUrl: './player-details-game-list.component.html',
  styleUrls: ['./player-details-game-list.component.css']
})
export class PlayerDetailsGameListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
