<div style="display: flex; justify-content: space-between; margin: 5px;font-size:x-small;">

    <div style="display: flex; justify-content: space-between; margin: 5px;font-size:x-small;" *ngIf="GameText">
        <div>
            <mat-icon style="color:orange;font-size: 18px;">sports_hockey</mat-icon>

        </div>
        <div style="font-size: 10px;">
            {{GameText}} <br>
            <span>{{GameDivision}}</span>
        </div>
    </div>
    <div style="display: flex; justify-content: space-between; margin: 5px;font-size:x-small;" *ngIf="GameText"></div>
    <div>
        <!--  <mat-icon style="color:orange;font-size: 18px;">local_parking</mat-icon> -->
        <div class="OverallGradeLabel">Position</div>
        <div>
            {{Position}}
        </div>


    </div>
    <div style="font-size: 10px;">


    </div>

    <div>
        <div class="OverallGradeLabel">Game</div>
        <div [class]="this.classesRankingBox[this.GamePerformance]"></div>{{this.GamePerformanceText}}
    </div>
</div>