<div>

    <h4>Question text</h4>
    <div>
        <mat-form-field style="max-width: 400px;"  appearance="fill" >
            <!-- <mat-label>Leave a comment</mat-label> -->
            <input matInput placeholder="Enter text here" />
          </mat-form-field>

    </div>

</div>
<mat-divider></mat-divider>
