<div *ngIf="testOverView">
    <h3>PHYSICAL PROFILE</h3>
   
    <div *ngIf="this.kpis" style="color: darkgray;">LAST 28 DAYS</div> 
    <div  style="margin-left: 10px;">Tests performed</div>
    <div class="KPI">
            {{this.kpis.NoOfTestsPerformed}}
    </div>
    <div style="margin-left: 10px;" *ngIf="this.kpis.NoOfTestsPerformed>0">
        Ranked test results
    </div>
    <div style="display: flex; flex-direction: row;margin-left: 10px;" *ngIf="this.kpis.NoOfTestsPerformed>0">
        <div class="redbox" [style.width]="getWidth(this.kpis.Ranking1_Count)">{{ this.kpis.Ranking1_Count}}</div>
        <div class="yellowbox" [style.width]="getWidth(this.kpis.Ranking2_Count)">{{ this.kpis.Ranking2_Count}}</div>
        <div class="greenbox" [style.width]="getWidth(this.kpis.Ranking3_Count)">{{ this.kpis.Ranking3_Count}}</div>
        <div class="lightgreenbox" [style.width]="getWidth(this.kpis.Ranking4_Count)">{{ this.kpis.Ranking4_Count}}</div>
        <div class="bluebox" [style.width]="getWidth(this.kpis.Ranking5_Count)">{{ this.kpis.Ranking5_Count}}</div>
    </div>
    <button mat-stroked-button [routerLink]="['/teamplayers/', this.Team.TeamId]">VIEW DETAILS</button>
    
</div>