<div class="app-page" style="display: flex; flex-wrap: wrap; ">
  <app-profile-card *ngFor="let prof of this._profiles" [Profile]="prof" (deleted)="onDeleted()">

  </app-profile-card>
  <section>
    <div *ngIf="this._profiles.length==0" class="aa">
    No profiles created
  </div>
  </section>
  
  <div class="fab-container">
    <button mat-mini-fab color="primary"  routerLink="/profileadd" ><mat-icon>add</mat-icon></button>

  </div>
</div>