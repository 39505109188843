import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlayerService } from 'src/app/shared/player.service';
import { User } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { Objective, ObjectiveTask } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';


@Component({
  selector: 'app-edit-task-dialog',
  templateUrl: './edit-task-dialog.component.html',
  styleUrls: ['./edit-task-dialog.component.css']
})
export class EditTaskDialogComponent implements OnInit {

 
  taskForm;
  users: User[];
  ObjectiveTask: ObjectiveTask;

  constructor(private fb: UntypedFormBuilder,
    private playerService: PlayerService,
    private objectiveService: ObjectivesService,
    private userService: UserService,
    public dialogRef: MatDialogRef<EditTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {ObjectiveTask: ObjectiveTask}) { }

  ngOnInit(): void {

    this.ObjectiveTask = this.data.ObjectiveTask;
    console.log(this.ObjectiveTask);
    this.userService.getAllUsers().subscribe(data=>{
      this.users =  data as User[];
    });

    this.taskForm = this.fb.group({
      ObjectiveId: [this.ObjectiveTask.ObjectiveId],
      TaskId: [this.ObjectiveTask.TaskId],
      Owner: [this.ObjectiveTask.Owner, [Validators.required]],
      Title: [this.ObjectiveTask.Title,[Validators.required]],
      Description: [this.ObjectiveTask.Description,[Validators.required]],      
      TargetDate: [this.ObjectiveTask.TargetDate,[Validators.required]],

      
    });

   
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    /* console.warn(this.taskForm.value);
    console.log(this.taskForm.valid); */
    this.objectiveService.updateTask(this.taskForm.value).subscribe(res=>{
      this.dialogRef.close();
    });
  }

}
