<div class="app-page" style="margin-left: 20px;;">
    <table >
        <tr>
            <td>
                <mat-icon style="font-size: 24px;">track_changes</mat-icon>
            </td>
            <td style="font-size: 24px;">
                {{this.Objective.Title}}
            </td>
        </tr>
       
       
    </table>    
    <mat-divider></mat-divider>
    <br/>
    <div style="display: flex;flex-direction: row; height: 30px;">
        <div class="title">Owner</div>
        <div>
            {{ this.Objective.OwnerInfo.FirstName}} {{ this.Objective.OwnerInfo.LastName}}
        </div>
    </div>    

    <div style="display: flex;flex-direction: row;height: 30px;">
        <div class="title">Description</div>    
        <div *ngIf="this.Objective.Description==null">
            {{this.Objective.Description}}
        </div>
        <div>
            {{this.Objective.Description}}
        </div>
    </div>

    <!-- <div style="display: flex;flex-direction: row;height: 30px;">
        <div class="title">Streams</div>            
        <div>
            FYSIO
        </div>
    </div> -->

    <div style="display: flex;flex-direction: row;height: 30px;">
        <div class="title">Target date</div>            
        <div>
            {{this.Objective.TargetDate | date}}
        </div>
    </div>

    <div style="display: flex;flex-direction: row;height: 30px;">
        <div class="title">Status</div>            
        <div>
            <app-objective-status-div [Status]="this.Objective.Status"></app-objective-status-div>            
        </div>
    </div>
   
    <div style="display: flex;flex-direction: row;height: 30px;">
        <div class="title">Progress</div>            
        <div style="width: 200px; display: flex;flex-direction: row;">
            <mat-progress-bar mode="determinate"  value="{{this.Objective.CurrentProgress}}" style="max-width: 150px; height: 20px; padding-top: 5px; flex-grow: 1;  margin-bottom: 5px; "></mat-progress-bar>
            <label style=" font-family: Roboto; margin-left: 10px; margin-bottom: 10px; padding-top: 5px;">{{this.Objective.CurrentProgress}}%</label>
        </div>
    </div>
    <div style="display: flex;flex-direction: row;height: 30px; width: 100%;" >
        <div class="title">Parent objective</div>            
        <div style="width: 500px; display: flex;flex-direction: row;" *ngIf="this.Objective.ParentObjective!=null">            
            <mat-icon style="font-size: 24px;">track_changes</mat-icon>
            <label style=" font-family: Roboto; margin-left: 10px; margin-bottom: 10px; padding-top: 5px;">
                {{this.Objective.ParentObjective.Title}}
            </label>
        </div>
    </div>


    <div style="display: flex;flex-direction: row; align-items: flex-end;">
        <div class="subtitle">PROGRESS</div>        
        <!-- <mat-progress-bar mode="determinate"  value="{{80}}" style="max-width: 80px; width: 80px; padding-top: 10px; flex-grow: 1;  margin-bottom: 15px; margin-left: 15px;"></mat-progress-bar><label style="font-size: small; font-family: Roboto; margin-left: 10px; margin-bottom: 10px;">{{80}}%</label> -->
        <button mat-button (click)="addKeyResult()" >
            <mat-icon>add</mat-icon>
            ADD KEY RESULT
        </button>
    </div>
   <mat-divider></mat-divider>
    <div *ngIf="this.Objective.KeyResults==null || this.Objective.KeyResults.length==0" style="color: gray; margin: 10px">
        NO KEY RESULTS DEFINED
    </div>
    <app-keyresult-status-row *ngFor="let keyresult of this.Objective.KeyResults" [KeyResult]="keyresult" [ShowButtons]="true" (KeyResultDeletedEvent)="removeKeyResult($event)" (KeyResultChangedEvent)="refreshKeyResult($event)" ></app-keyresult-status-row>
   <!--  <mat-nav-list>
        <div mat-subheader>Folders</div>
        <mat-list-item *ngFor="let keyresult of this.Objective.KeyResults" >
          <a mat-line>
            <app-keyresult-status-row  [KeyResult]="keyresult"></app-keyresult-status-row>
          </a>          
        </mat-list-item>
     
      </mat-nav-list> -->

    <br/>
    <div style="display: flex;flex-direction: row; align-items: flex-end;">
        <div class="subtitle">TASKS</div>
        <button mat-button (click)="addTask()" >
            <mat-icon>add</mat-icon>
            ADD TASK
        </button>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="this.Objective.Tasks==null || this.Objective.Tasks.length==0" style="color: gray; margin: 10px">
        NO TASKS DEFINED
    </div>
    <app-task-status-row *ngFor="let task of this.Objective.Tasks" [Task]="task" (TaskDeletedEvent)="refreshTasks()" (TaskChangedEvent)="refreshTasks()" [ShowButtons]="true"></app-task-status-row>
    <br/>

    
    <div style="display: flex;flex-direction: row; align-items: flex-end;">
        <div class="subtitle">CHILD OBJECTIVES</div>      
    </div>
    <mat-divider></mat-divider>
    <app-objective-player-row *ngFor="let childObj of this.Objective.ChildObjectives" [Objective]="childObj" [ShowButtons]="false"></app-objective-player-row>
    <div *ngIf="this.Objective.ChildObjectives==null || this.Objective.ChildObjectives.length==0" style="color: gray; margin: 10px">
        NO CHILD OBJECTIVES DEFINED
    </div>

    <br/>
    <div style="display: flex;flex-direction: row; align-items: flex-end;">
        <div class="subtitle">CHECK INS</div>
        <button mat-button [routerLink]="['/objectives/' + this.Objective.ObjectiveId + '/checkin']" >
            <mat-icon>add</mat-icon>
            CHECK IN
        </button>
    </div>
    <mat-divider></mat-divider>
    <app-checkin-view-card *ngFor="let checkin of this.Objective.Checkins" [Checkin]="checkin"  (CheckinDeletedEvent)="refreshCheckins()"></app-checkin-view-card>

    <br/>

    <mat-divider></mat-divider>
</div>