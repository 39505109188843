<mat-card appearance="outlined" STYLE="max-width: 570px; min-width: 350px; margin: 10px;">
    <mat-card-header>  
      <div mat-card-avatar>      
          <i class="material-icons">
              dynamic_form
              </i>
        </div>    
      <mat-card-title>{{this.Form.Name}}</mat-card-title>
      <mat-card-subtitle>
        Version {{this.Form.LatestVersion}}<br/>
        
      </mat-card-subtitle>
      
    </mat-card-header>    
    <mat-card-content >
        <div>
            <mat-chip *ngIf="this.Form.Visible" >Visible</mat-chip>&nbsp;
            <mat-chip *ngIf="!this.Form.Visible" style="background-color: red;">Hidden</mat-chip>&nbsp;
            <mat-chip *ngIf="!this.Form.MultiEdit"  >Personal</mat-chip>&nbsp;
            <mat-chip *ngIf="this.Form.MultiEdit"  >Multi edit</mat-chip>&nbsp;
            <mat-chip *ngIf="this.Form.AllowCreate" >Open</mat-chip>&nbsp;
            <mat-chip *ngIf="!this.Form.AllowCreate"  style="background-color: red;" >Closed</mat-chip>&nbsp;
            <mat-chip *ngIf="this.Form.FormVersion.PlayerEnabled" >Player</mat-chip>&nbsp;
        </div><br/>
        <mat-divider></mat-divider>
   
        <div *ngFor="let section of this.Form.FormVersion.Sections" style="margin-left: 10px;">            
            [{{section.Text}}  ]
        </div>
        <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-actions>
        <button mat-button [routerLink]="['/forms/settings', this.Form.FormId]">SETTINGS</button>
        <button mat-button [routerLink]="['/forms/edit', this.Form.FormId]">DESIGN</button>
    </mat-card-actions>
</mat-card>

