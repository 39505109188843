<div class="app-page" style="margin-left: 10px ;">
    <h2><img src="../../assets/img/icons8-scorecard-48.png"style="height: 24px;">Statistics profiles</h2>
<button  mat-button (click)="openAddDialog();" color="primary"><mat-icon>add</mat-icon>NEW</button>

<div style="display: flex;   flex-direction: row; flex-wrap: wrap;align-items: flex-start;">
    <div *ngIf="this.profiles.length==0" style="font-family: Roboto; color: gray;" >
        NO PROFILES CREATED
    </div>
    
    <mat-card appearance="outlined" *ngFor="let profile of this.profiles" style="max-width: 400px; min-width: 250px; margin-right: 20px; margin-bottom: 20px;" >
        <mat-card-header>
            
            <!-- <div mat-card-avatar><mat-icon mat-card-avatar mat-list-icon>table_rows</mat-icon></div> -->
           
            <mat-card-title>
                <img style="height: 24px; " src="../../assets/img/icons8-scorecard-48.png" >
                {{profile.Name}}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content >
            <table>
                <tr>
                    
                    <td class="limitname" colspan="2">Name</td>
                    <td class="limitcell ">OK</td>
                    <td class="limitcell ">Good</td>
                    <td class="limitcell  ">Very good</td>
                    <td class="limitcell  ">Excellent</td>
                </tr>
                <tr  *ngFor="let limit of profile.Limits">
                    <td class="limitimage">   <mat-icon>table_row</mat-icon></td>
                    <td class="limitname ">{{limit.Abbreviation}}</td>
                    <td class="limitvalue valuecolor_2">{{limit.LimitOK}}</td>
                    <td class="limitvalue valuecolor_3" >{{limit.LimitGood}}</td>
                    <td class="limitvalue valuecolor_4">{{limit.LimitVeryGood}}</td>
                    <td class="limitvalue valuecolor_5">{{limit.LimitExcellent}}</td>

                    
                </tr>
                 <tr  *ngFor="let limit of profile.LimitsCalculated">
                    <td class="limitimage">   <mat-icon>calculate</mat-icon></td>
                    <td class="limitname ">{{limit.Abbreviation}}</td>
                    <td class="limitvalue valuecolor_2">{{limit.LimitOK}}</td>
                    <td class="limitvalue valuecolor_3" >{{limit.LimitGood}}</td>
                    <td class="limitvalue valuecolor_4">{{limit.LimitVeryGood}}</td>
                    <td class="limitvalue valuecolor_5">{{limit.LimitExcellent}}</td>

                    
                </tr>
                <tr *ngIf="profile.LimitsCalculated.length==0 && profile.Limits.length==0 ">
                    <td colspan="6" STYLE="color: gray;">
                        EMPTY
                    </td>
                </tr>
            </table>

          
        </mat-card-content>
        <mat-card-actions>
            <button mat-button [routerLink]="['/config/gamestatistics/profile/', profile.GameStatisticsProfileId]">EDIT</button>
            <button mat-button (click)="confirmDeleteProfile(profile)">DELETE</button> 
        </mat-card-actions>
    </mat-card>

</div>

</div>