

<mat-dialog-content style="font-family: Roboto;">
    <app-team-event-details [EventId]="this.EventId" *ngIf="this.EventId"></app-team-event-details>
   
</mat-dialog-content>
<mat-dialog-actions>
    <button  class="mat-button"  (click)="cancel()" >CLOSE</button>
<!--     <button color="primary" class="mat-raised-button" (click)="cancel()" >CANCEL</button> -->
</mat-dialog-actions>
