<div class="app-page" fxFill  style="margin-left: 20px;" >
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <div style="align-content: center; text-align: center; margin-right: 20px;">
            <img src="../../assets/img/icons8-user-shield-48.png" style="height: 48px;"/><br/>
            <span style="font-size: large; ">{{player?.FirstName }} {{player?.LastName}}</span><br/>
            <span style="font-size: small; ">
                {{player?.DateOfBirth | date: 'yyy-MM-dd'}}
            </span>
        </div>
        &nbsp;
        <div>
        <mat-form-field appearance="fill" style="font-size: 12px;">
          <mat-label>Season</mat-label>
          <mat-select [(ngModel)]="selectedSeasonId" [formControl]="SeasonFormControl" (selectionChange)="refreshAll()">
            <mat-option *ngFor="let season of this.seasons" [value]="season.SeasonId">
              {{season.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
    </div>
        <div *ngIf="false">
            <span style="font-size: small;">Latest status - {{player?.AssessmentTimestamp | date: 'yyyy-MM-dd HH:mm'}}</span>
                                <table  *ngIf="player">
                                <tr  *ngIf="player?.UserId!='00000000-0000-0000-0000-000000000000'" style="height: 24px;">

                                    
                                    <td>
                                            <mat-chip-listbox>
                                                <mat-chip-option  selected="true"  [style.background-color]="availabilityColors[player.Availability]"  style="width: 50px;text-align: center;" *ngIf="player.Availability>0" class="chipvalue" >                                        
                                                    {{availabilityText[player.Availability]}}
                                                </mat-chip-option>
                                                <mat-chip-option  selected="true"   style="width: 50px;text-align: center;color: white; background-color: gray" *ngIf="player.Availability==0"  class="chipvalue">
                                                    {{availabilityText[player.Availability]}}
                                                </mat-chip-option>
                                            </mat-chip-listbox>    
                                        </td>                                                       
                                    <td >
                                        <mat-chip-listbox>                    
                                            <mat-chip-option  selected="true"  [style.background-color]="scaleColors[player.Freshness]" [style.color]="scaleTextColors[player.Freshness]" class="chipvalue">
                                                <mat-icon class="smallicon" >directions_run</mat-icon>
                                                {{player.Freshness}}
                                            </mat-chip-option>
                                        </mat-chip-listbox>
                                    
                                    </td>
                                    
                                    <td>
                                        <mat-chip-listbox>
                                            <mat-chip-option  selected="true"  [style.background-color]="scaleColors[player.Confidence]" [style.color]="scaleTextColors[player.Confidence]" class="chipvalue" >
                                                <mat-icon class="smallicon" >highlight</mat-icon>
                                                {{player.Confidence}}
                                            </mat-chip-option>
                                            
                                        </mat-chip-listbox>    
                                    </td>
                                    <td>
                                        <mat-chip-listbox>
                                            <mat-chip-option  selected="true"  [style.background-color]="scaleColors[player.Sleep]" [style.color]="scaleTextColors[player.Sleep]" class="chipvalue">
                                                <mat-icon class="smallicon" >hotel</mat-icon>
                                                {{player.Sleep}}
                                            </mat-chip-option>
                                        </mat-chip-listbox>    
                                    </td>
                                    <td>
                                        <mat-chip-listbox *ngIf="player.Availability>0">
                                            <mat-chip-option  selected="true"  [style.background-color]="painScaleColors[player.Muscles]" [style.color]="painScaleTextColors[player.Muscles]" class="chipvalue">
                                                <mat-icon class="smallicon" >flash_on</mat-icon>
                                                {{ player.Muscles }}
                                            </mat-chip-option>
                                        </mat-chip-listbox>
                                        <mat-chip-listbox *ngIf="player.Availability==0">
                                            <mat-chip-option selected="true"  style="color: black; background-color: gray" class="chipvalue">
                                                <mat-icon class="smallicon" >flash_on</mat-icon>
                                                    0
                                            </mat-chip-option>
                                        </mat-chip-listbox>
                                    <td>
                                        <mat-chip-listbox *ngIf="player.Availability>0">
                                            <mat-chip-option  selected="true"  [style.background-color]="painScaleColors[player.Joints]" [style.color]="painScaleTextColors[player.Joints]" class="chipvalue">
                                                <mat-icon class="smallicon" >flash_on</mat-icon>
                                                    {{ player.Joints }}
                                            </mat-chip-option>
                                        </mat-chip-listbox>
                                        <mat-chip-listbox *ngIf="player.Availability==0">
                                            <mat-chip-option selected="true" style="color: black; background-color: gray" class="chipvalue">
                                                <mat-icon class="smallicon" >flash_on</mat-icon>
                                                    0
                                            </mat-chip-option>
                                        </mat-chip-listbox>
                                    </td>
                                    <td style="text-align: left;">
                                        <span [class]="player.TimeAgoStatus" ></span>   
                                        
                                            {{ player.TimeAgoText  }}
                                    </td>
                                                                                                                                    
                            
                                </tr>
                            </table>  
                            
                            <span style=" font-size: small;">Reported load</span>    
                            <table>
                                <td style="text-align: center">     
                                    <div class="noofevents">
                                        {{ player.LoadKPIs.EventCount_7days }}
                                    </div>                          
                                 <!--    <mat-chip-list>
                                        <mat-chip  selected="true"  [style.color]="white" style=" background-color: gray; " class="chipvalue">
                                            {{ player.KPIs.EventCount_7days }}
                                        </mat-chip>
                                    </mat-chip-list>   -->
                                                    
                                    
                                </td>
                                <td>
                                    <div class="loadvalue">
                                        {{ player.LoadKPIs.SumLoadValue_7days }}
                                    </div>
                                    <!--     <mat-chip-list>
                                            <mat-chip  selected="true"   [style.color]="white" style=" background-color:#5975b2;" class="chipvalue"> 
                                                        {{ player.KPIs.SumLoadValue_7days }}
                                            </mat-chip>
                                        </mat-chip-list>   -->
                                        <!-- {{ player.KPIs.EventCount_7days }} st - {{ player.KPIs.SumDuration_7days }} min -->
                                </td>
                                <td>
                                    <div class="loadvaluegames">
                                        {{ player.LoadKPIs.SumLoadValueGames_7days }}
                                    </div>
                                        <!-- '#4374E0', '#53A8FB', '#F1CA3A', '#E49307'], -->
                                    <!--     <mat-chip-list>
                                            <mat-chip  selected="true"  [style.color]="black" style="background-color: #ccb974; color: black; " class="chipvalue">
                                                    {{ player.KPIs.SumLoadValueGames_7days }}
                                            </mat-chip>
                                        </mat-chip-list>                                       -->
                                        <!-- {{ player.KPIs.GameEventCount_7days }} st - {{ player.KPIs.SumDurationGames_7days }} min -->
                                </td>
                              <!--   <td>{{ player.LoadKPIs.LoadRatio7Days30Days | number:'1.2-2'  }}</td> -->
                                <td style="text-align: center">
                                    <div class="noofevents">
                                        {{ player.LoadKPIs.EventCount_30days }}
                                    </div>
                              <!--       <mat-chip-list>
                                        <mat-chip  selected="true"  [style.color]="white" style=" background-color: gray; " class="chipvalue">
                                            {{ player.KPIs.EventCount_30days }}
                                        </mat-chip>
                                    </mat-chip-list>   -->
                                </td>
                                <td>
                                    <div class="loadvalue">
                                        {{ player.LoadKPIs.SumLoadValue_30days }}
                                    </div>
                                        <!-- <mat-chip-list>
                                            <mat-chip  selected="true"   [style.color]="white" style="background-color:#5975b2;" class="chipvalue"> 
                                                        {{ player.KPIs.SumLoadValue_30days }}
                                            </mat-chip>
                                        </mat-chip-list>   -->
                                        <!-- {{ player.KPIs.EventCount_7days }} st - {{ player.KPIs.SumDuration_7days }} min -->
                                </td>
                                <td>
                                    <div class="loadvaluegames">
                                        {{ player.LoadKPIs.SumLoadValueGames_30days }}
                                    </div>
                                        <!-- '#4374E0', '#53A8FB', '#F1CA3A', '#E49307'], -->
                                   <!--      <mat-chip-list>
                                            <mat-chip  selected="true"  [style.color]="black" style=" background-color:#ccb974; color: black; " class="chipvalue">
                                                    {{ player.KPIs.SumLoadValueGames_30days }}
                                            </mat-chip>
                                        </mat-chip-list>        -->                               
                                        <!-- {{ player.KPIs.GameEventCount_7days }} st - {{ player.KPIs.SumDurationGames_7days }} color: black;min -->
                                </td>
                            </table>
        </div>
        <div>
         
        </div>
    </div>
    <div class="loading-indicator" *ngIf="isLoading">

        <mat-progress-spinner
        class="example-margin"
        color="orange"
        mode="indeterminate"
        >
    </mat-progress-spinner>
    
      </div>
 
    <mat-tab-group  mat-stretch-tabs="false" >
        <mat-tab class="ds_tab" label="GAMES" *ngIf="this.isGameAssessmentViewer">      
            <ng-template mat-tab-label>
                <mat-icon>sports_hockey</mat-icon>
                GAMES
            </ng-template>      
                <ng-template matTabContent >                    
                 
                <div style="display: flex; flex-direction: column; flex-wrap: wrap;width: 100%;"  *ngIf="this.player?.PastGames.length>0"> 
                    <div style="margin-left: 10px; flex-grow: 1;min-width: 350px;">
                        <app-game-ranking-chart [PlayerId]="player.PlayerId" [Season]="this.selectedSeason()">

                        </app-game-ranking-chart>
                    </div>
                    <div style="margin-left: 10px; flex-grow: 1; min-width: 350px;" *ngFor="let group of this.groups?.ParameterGroups">
                        
                            <app-game-parametergroup-chart *ngIf="group" [GroupName]="group.Name" [PlayerId]="player?.PlayerId" [GroupId]="group.ParameterGroupId" [Season]="this.selectedSeason()" >

                            </app-game-parametergroup-chart>
                                                        
                    </div>
                </div>
                <div style="display: flex; flex-direction: row; flex-wrap: wrap;width: 100%;"  *ngIf="player?.PastGames.length>0"> 
                    <div style="display:flex; flex-direction: column; width: 100%;">
                            <h3>Data table</h3>
                            <div style="font-size: x-small;">No of games</div>
                            <mat-button-toggle-group name="noofgames" aria-label="Font Style">
                                <mat-button-toggle  (click)="this.TableNoOfGames=5">5</mat-button-toggle>
                                <mat-button-toggle  (click)="this.TableNoOfGames=10" checked="true">10</mat-button-toggle>
                                <mat-button-toggle  (click)="this.TableNoOfGames=15">15</mat-button-toggle>
                                <mat-button-toggle  (click)="this.TableNoOfGames=20">20</mat-button-toggle>
                                <mat-button-toggle (click)="this.TableNoOfGames=99" >SEASON</mat-button-toggle>
                            </mat-button-toggle-group>
                        
                            <app-player-game-table2 *ngIf="this.groups" [Games]="player?.PastGames" [NumberOfGames]="this.TableNoOfGames" [Groups]="this.groups"></app-player-game-table2>
                    </div>
                    <div style="margin-left: auto; margin-right: auto;" *ngIf="this.assessmentImage!=''">
                        <h3>Guide</h3>
                        <br>
                        <img [src]="this.assessmentImage" style="width: 100%; max-width: 570px;">
                    </div>

                </div>
              

                <h3  *ngIf="player?.PastGames.length>0">Game cards</h3>
                    <div style="display: flex; flex-direction: row; flex-wrap: wrap;"  *ngIf="player?.PastGames.length>0" >

                    
                    <div *ngFor="let event of player?.PastGames" >

                   
                    <div class="gamerank" >
                        <table>
                            <tbody>
                                <tr >
                                    <td style="width: 50px;vertical-align:top;  font-size: 14px;">
                                        <span style=" font-size: 24px; vertical-align: text-top;">
                                            {{ event.EventDate | date:'dd' }}<br/>                       
                                        </span>
                                        {{ event.EventDate  | date:'MMM'}}<br/>
                                            {{ event.EventDate  | date:'EEE'}} 
                                    </td>
                                    <td>
                                        <div style="margin-left: 14px;font-size: small;">
                                            <b>{{event.HomeTeam.Name + " - " + event.AwayTeam.Name}}</b><br>
                                            <div style=" display:block; color:gray;">{{event.DivisionName}}</div>
                                        </div>                                        
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                        
        <!--                     <div *ngIf="event.Comment!='' && event.Comment != null" style="font-size:small; margin-left: 10px;">
                                <i class="material-icons" >chat_bubble_outline</i>
                                {{ event.Comment}}
                            </div> -->
        
        
                                <div style=" background: linear-gradient(to right, #888888, #303030); height: 2px;"  ></div>   
                                <span style="font-size: small;margin-left: 10px;">Player assessment</span>
                                <div style="margin-left: 10px; margin-top: 5px;  ">
                                    <span *ngIf="event.Ranking==-1" style="font-size:small; color: gray; ">                    
                                        NO PLAYER ASSESSMENT
                                    </span>
                                    <span *ngIf="event.Ranking==0" style="font-size: small;">
                                        <i class="material-icons">
                                            highlight_off
                                            </i>
                                        DID NOT PLAY
                                    </span>
                            
                                    <table *ngIf="event && event.Ranking>0">
                                            <tr *ngIf="event.Comment>''">
                                                
                                            
                                                    <td colspan="6" style="font-size: small;">
                                                        <i class="material-icons" style="color: white;" >chat_bubble_outline</i>
                                                        {{event.Comment}}
                                                    </td>
                                            </tr>    
                                        <tr>
                                            <td  class="titlecell"><b>OVERALL</b></td>
                                            <td  class="valuecell" [style.background-color]="gamePerformanceColors[event.Ranking]" [style.color]="gamePerformanceTextColors[event.Ranking]">
                                                {{event.Ranking}}
                                            </td>
                                            <td class="spacer"></td>
                                            <td colspan="3"></td>
                                        </tr>
                                    
                                        
                                    </table>
                                    <div *ngIf="event.GameAssessmentGroups && event.GameAssessmentGroups!=null && event.Ranking>0" style="display: flex;">
                                        <div *ngFor="let group of event.GameAssessmentGroups">
                                            <span  style=" font-weight: bold; font-size: small;">{{group.Name}}</span>
                                            <table>
                                                <tr *ngFor="let param of group.Parameters">
                                                    <td class="titlecell">
                                                        {{param.Name}}
                                                    </td>
                                                    <td *ngIf="!param.ReverseRanking" class="valuecell" [style.background-color]="scaleColors[param.Ranking]"  [style.color]="scaleTextColors[param.Ranking]" >
                                                            <span *ngIf="param.Ranking>0">{{param.Ranking}}   </span>            
                                                    </td>
                                                    <td *ngIf="param.ReverseRanking" class="valuecell" [style.background-color]="painScaleColors[param.Ranking]"  [style.color]="painScaleTextColors[param.Ranking]" >
                                                        <span *ngIf="param.Ranking>0">{{param.Ranking}}   </span>            
                                                </td>
                                            </table>
                                            <div >
                                                
                                            </div>
                                        </div>
                            
                                    </div>
                                        
                                
                                </div>
        
        
        
        
        
        
        
        
        
        
        
        <!-- 
                            <div style="margin-left: 10px; margin-top: 5px; margin-bottom: 5px;  ">
                                <mat-chip-list class="chiplist" >
                                    <mat-chip [style.background-color]="gamePerformanceColors[event.Ranking]" [style.color]="gamePerformanceTextColors[event.Ranking]"   class="longsmallchip">
                                        <mat-icon class="smallicon">{{gamePerformanceIcon[event.Ranking]}}</mat-icon> {{ event.Duration }} min
                                    </mat-chip>
                                
                                    <mat-chip [style.background-color]="scaleColors[event.Sleep]" [style.color]="scaleTextColors[event.Sleep]"  class="smallchip">
                                        <mat-icon class="smallicon" >hotel</mat-icon>{{event.Sleep}}
                                    </mat-chip>
                        
                                    
                                    <mat-chip  [style.background-color]="scaleColors[event.Nutrition]" [style.color]="scaleTextColors[event.Nutrition]" class="smallchip">
                                        <mat-icon class="smallicon" >local_dining</mat-icon> {{event.Nutrition}}
                                    </mat-chip>
                        
                                    <mat-chip [style.background-color]="painScaleColors[event.Stress]" [style.color]="painScaleTextColors[event.Stress]"   class="smallchip">
                                        <mat-icon class="smallicon">warning</mat-icon> {{event.Stress}}
                                    </mat-chip>
                        
                        
                                </mat-chip-list>
                        
                                    
                            
                            </div> -->
                            <mat-divider *ngIf="event.AssessmentBeforeGame!=null"></mat-divider>    
                            <div style="margin-left: 10px;" *ngIf="event.AssessmentBeforeGame">
                            <span style="font-size: small;">Pre game status</span>
                            <table style="font-size: x-small; margin-left: 10px;" *ngIf="event.AssessmentBeforeGame.Availability>-1">                                        
                        
                                     
                                    <tr>
                                        <td class="valuecell" style="height: 20px;" [style.background-color]="availabilityColors[event.AssessmentBeforeGame.Availability]" style="color: white;"  > {{availabilityText[event.AssessmentBeforeGame.Availability] |  translate}}</td>
                                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="this.scaleColors[event.AssessmentBeforeGame.Freshness]" [style.color]="this.scaleTextColors[event.AssessmentBeforeGame.Freshness]">
                                                  <mat-icon class="smallicon" >directions_run</mat-icon> {{event.AssessmentBeforeGame.Freshness}}
                                        </td>
                                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="scaleColors[event.AssessmentBeforeGame.Confidence]" [style.color]="scaleTextColors[event.AssessmentBeforeGame.Confidence]">
                                            <mat-icon class="smallicon" >highlight</mat-icon> {{event.AssessmentBeforeGame.Confidence}}
                                        </td>
                                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="scaleColors[event.AssessmentBeforeGame.Sleep]" [style.color]="scaleTextColors[event.AssessmentBeforeGame.Sleep]">
                                            <mat-icon class="smallicon" >hotel</mat-icon> {{event.AssessmentBeforeGame.Sleep}}
                                        </td>
                                        <td class="valuecell" style="min-width: 50px; height: 20px;"  [style.background-color]="painScaleColors[event.AssessmentBeforeGame.Muscles]" [style.color]="painScaleTextColors[event.AssessmentBeforeGame.Muscles]">
                                            <mat-icon class="smallicon" >flash_on</mat-icon> {{event.AssessmentBeforeGame.Muscles}}
                                        </td>
                                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="painScaleColors[event.AssessmentBeforeGame.Joints]" [style.color]="painScaleTextColors[event.AssessmentBeforeGame.Joints]">
                                            <mat-icon class="smallicon" >flash_on</mat-icon> {{event.AssessmentBeforeGame.Joints}}
                                        </td>
                                        
                                    </tr>
                    
                                </table>
                                <span style="margin-left: 20px; font-size: 12px;;">{{ event.AssessmentBeforeGame.AssessmentTimestamp | date:'yyyy-MM-dd HH:mm' }} </span>
                            </div>
                            <mat-divider></mat-divider>
                            <!--<span style="font-family: Roboto; font-size: small; margin-left: 10px;"  *ngIf="event.AssessmentBeforeGame!=null">Pre game status</span>
                            <div style="font-family: Roboto; font-size: x-small; margin-left: 10px;"  *ngIf="event.AssessmentBeforeGame!=null">
                                {{event.AssessmentBeforeGame.AssessmentDate | date:'yyyy-MM-dd'}}
                            </div>
                             <table style="font-size: x-small; margin-left: 10px;" *ngIf="event.AssessmentBeforeGame!=null">                                        
            
                                                
                                <tr *ngIf="event.AssessmentBeforeGame.Comment!='' && event.AssessmentBeforeGame.Comment != null">
                                                    
                                                    
                                    <td colspan="7">
                                        <i class="material-icons" >chat_bubble_outline</i>
                                        {{ event.AssessmentBeforeGame.Comment}}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                            <mat-chip-list class="chiplist">
                                                    <mat-chip  selected="true"  [style.background-color]="availabilityColors[event.AssessmentBeforeGame.Availability]" style.color="white" class="smallchip">
                                                        {{availabilityText[event.AssessmentBeforeGame.Availability] |  translate}}
                                                    </mat-chip>
                                                </mat-chip-list>    
                                    </td>
                                <td>
                                            <mat-chip-list class="chiplist" >
                                                    <mat-chip  [style.background-color]="scaleColors[event.AssessmentBeforeGame.Freshness]" [style.color]="scaleTextColors[event.AssessmentBeforeGame.Freshness]" class="smallchip">
                                                        <mat-icon class="smallicon" >directions_run</mat-icon> {{event.AssessmentBeforeGame.Freshness}}
                                                    </mat-chip>
                                            </mat-chip-list>
                                        
                                    </td>

                                    <td>
                                            <mat-chip-list class="chiplist">
                                                    <mat-chip  [style.background-color]="scaleColors[event.AssessmentBeforeGame.Confidence]" [style.color]="scaleTextColors[event.AssessmentBeforeGame.Confidence]" class="smallchip">
                                                        <mat-icon class="smallicon" >highlight</mat-icon> {{event.AssessmentBeforeGame.Confidence}}
                                                    </mat-chip>
                                            </mat-chip-list>
                        
                                    </td>
                                    <td>
                                            <mat-chip-list class="chiplist">
                                                    <mat-chip  [style.background-color]="scaleColors[event.AssessmentBeforeGame.Sleep]" [style.color]="scaleTextColors[event.AssessmentBeforeGame.Sleep]" class="smallchip">
                                                        <mat-icon class="smallicon" >hotel</mat-icon> {{event.AssessmentBeforeGame.Sleep}}
                                                    </mat-chip>
                                            </mat-chip-list>
            
                                    </td>
                                    <td>
                                            <mat-chip-list class="chiplist">
                                                    <mat-chip  [style.background-color]="painScaleColors[event.AssessmentBeforeGame.Muscles]" [style.color]="painScaleTextColors[event.AssessmentBeforeGame.Muscles]" class="smallchip">
                                                        <mat-icon class="smallicon" >flash_on</mat-icon> {{event.AssessmentBeforeGame.Muscles}}
                                                    </mat-chip>
                                            </mat-chip-list>
                                        
                                    </td>
                                    <td>
                                            <mat-chip-list class="chiplist">
                                                    <mat-chip  [style.background-color]="painScaleColors[event.AssessmentBeforeGame.Joints]" [style.color]="painScaleTextColors[event.AssessmentBeforeGame.Joints]" class="smallchip">
                                                        <mat-icon class="smallicon" >flash_on</mat-icon> {{event.AssessmentBeforeGame.Joints}}
                                                    </mat-chip>
                                            </mat-chip-list>
                                        
                                    </td>
                            
                        </tr>
        
                        </table> -->
                        <div></div>
                        <mat-divider></mat-divider>  
                        <div >
                            <span style="font-size: small;margin-left: 10px;">Coach grades</span> <br/> 
                            <span *ngIf="event.GameRankings==null || event.GameRankings.length==0" style="font-size:small; color: gray; margin-left: 10px;">                    
                                NO GRADES
                            </span>
                            
                            <ng-container *ngIf="event.GameRankings">                        
                                <div style="display: flex; padding-top: 5px; margin-left: 10px;" *ngFor="let ranking of event.GameRankings">
                                            
                                    <img mat-card-avatar src="../assets/img/icons8-coach-32.png" style="margin-right: 5px; width:20px; height: 20px;"/>
                                    
                                
                                    <div >
                                        <div style="font-size: small; color:lightgray;">
                                                {{ ranking.ScoutName }}
                                            
                                        </div>      
                                        <div class="valuecell" [style.background-color]="gamePerformanceColors[ranking.Ranking]"  [style.color]="gamePerformanceTextColors[ranking.Ranking]">
                                            {{ranking.Ranking}}
                                        </div>          
                                    <!--   <div >
                                            <mat-chip-list >
                                                    <mat-chip [style.background-color]="gamePerformanceColors[ranking.Ranking]" [style.color]="gamePerformanceTextColors[ranking.Ranking]" style="padding-left: 5px;
                                                    padding-right: 5px;
                                                    padding-top: 3px;
                                                    padding-bottom: 3px;"  >
                                                        <i class="material-icons" style="font-size: 20px; ">{{gamePerformanceIcon[ranking.Ranking]}}</i>
                                                    </mat-chip>
                                            </mat-chip-list>
                                        </div> -->
                                        <div class="coachcomment" *ngIf="ranking.CommentToPlayer > '' ">
                                            <label style="color: white; font-size: x-small;">Comment - visible to player</label>     
                                            <div class="coachcomment">
                                            {{ ranking.CommentToPlayer }}
                                            </div>
                                        </div>
                                        <div class="coachcomment" style="border-color: white;" *ngIf="ranking.CommentPrivate > ''">                            
                                            <label style="color: white; font-size: x-small;">Comment - coaches only</label>   
                                            <div class="coachcomment">
                                                {{ ranking.CommentPrivate }}
                                            </div>   
                                        </div>
                                    </div>
                                
                                    
                                </div> 
                            </ng-container>
                            
                        </div>
                    </div>  
                    
                   
                
                    
                    
                    
                </div>
        
                    </div>


                    
                  <div style="margin: 5px; color: gray;" *ngIf="player?.PastGames.length==0" >
                    NO GAME DATA
                    </div>
                </ng-template>
             
        </mat-tab>
        <mat-tab class="ds_tab" *ngIf="this.isStatisticsViewer" >            
            <ng-template mat-tab-label>
                <mat-icon>table_chart</mat-icon>
                STATISTICS 
            </ng-template>    
            <br/>
            <ng-template matTabContent> 
                <ng-container *ngIf="this.Views?.length>0">

                
                
                    <mat-form-field appearance="fill" style="font-size: 12px;">
                        <mat-label>Statistics view</mat-label>
                        
                        <mat-select [(ngModel)]="selectedViewId" [formControl]="viewFormControl" (selectionChange)="refreshStatisticsPage()">
                        <mat-option *ngFor="let view of this.Views" [value]="view.GameStatisticsViewId">
                            {{view.Name}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    &nbsp;
                    <mat-form-field appearance="fill" style="font-size: 12px;">
                        <mat-label>Division</mat-label>
                        <mat-select  [formControl]="DivisionFormControl" (selectionChange)="doFilter($event)">
                        <mat-option value="" selected>All</mat-option>
                        <mat-option *ngFor="let division of this.divisions" [value]="division">
                            {{division}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <table mat-table [dataSource]="dataSource"  >
                        <!--  <ng-container matColumnDef="Name" sticky>
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                        <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Name}} </td>
                        
                        </ng-container> --> 
                                
                        
                        <ng-container *ngFor="let parm of displayedColumns;let i = index" matColumnDef="{{parm}}">
                        <th mat-header-cell *matHeaderCellDef style=" text-align: center;" [ngStyle]="{'background-color': this.displayedColumnsAbbreviationBgColor[i],'color': this.displayedColumnsAbbreviationTextColor[i]}" >{{displayedNames[i]}} </th>
                        <td mat-cell *matCellDef="let element" style="text-align: center;"    [ngStyle]="{'background-color': this.displayedColumnsBgColor[i]}">
                            <div [ngStyle]="{'color': this.getTextColor(i,element[parm + '_Ranking']),'background-color': this.getRankingColor(element[parm + '_Ranking'])}" >{{element[parm]}}</div>                          
                        </td>         
                        </ng-container>
                    
                    
<!--                         <ng-container [matColumnDef]="GameDate">
                            <th mat-header-cell *matHeaderCellDef 
                            
                            style="text-align: left;" 
                            >
                        
                            </th>
                        
                        </ng-container> -->
                        
                        <ng-container [matColumnDef]="'GroupHeader_' + G.GameStatisticsGroupId" *ngFor="let G of this._view.Groups; let i = index">
                        <th mat-header-cell *matHeaderCellDef 
                        style="text-align: center;" 
                            [attr.colspan]="G.Parameters.length + G.ParametersCalculated.length"
                            [ngStyle]="{'background-color': G.BgColorHeader, 'color': G.TextColorHeader, 'margin-left': '20px'}"              
                            >
                            &nbsp;{{G.Name}}
                        </th>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="groupColumns; sticky: true"></tr> 
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                
                    </table>
                </ng-container>
                <ng-container *ngIf="this.Views?.length==0">
                    <label style="color: gray;">
                        <br/>
                        THERE ARE NO STATISTICS VIEWS DEFINED FOR THIS PLAYER POSITION
                    </label>
                </ng-container>

            </ng-template>
        </mat-tab>
        <mat-tab class="ds_tab" *ngIf="this.isStatusViewer"  >
            <ng-template mat-tab-label>
                <mat-icon>directions_run</mat-icon>
                STATUS
            </ng-template>
            <ng-template matTabContent>
                
              
                
                <app-physical-status-chart [PlayerId]="player.PlayerId" [Season]="this.selectedSeason()">
    
                </app-physical-status-chart>
            </ng-template>
            
        </mat-tab>
        <mat-tab class="ds_tab" *ngIf="this.isStatusViewer"  >
            <ng-template mat-tab-label>
                <mat-icon>flash_on</mat-icon>
                LOAD
            </ng-template>
            <ng-template matTabContent>
                
                <app-player-load-chart [PlayerId]="player.PlayerId" [Season]="this.selectedSeason()">

                </app-player-load-chart>

                                
                <app-player-load-ratio-chart [PlayerId]="player.PlayerId" [Season]="this.selectedSeason()">

                </app-player-load-ratio-chart>
             
            </ng-template>
            
        </mat-tab>
        <mat-tab class="ds-tab" label="OBJECTIVES" *ngIf="this.isObjectiveViewer" >
            <ng-template mat-tab-label>
                <mat-icon>track_changes</mat-icon>
                &nbsp;
                OBJECTIVES
            </ng-template>
            <ng-container *ngIf="this._objectives!=null">
                
                <app-objective-player-row *ngFor="let obj of this._objectives" [Objective]="obj" (ObjectiveDeletedEvent)="refreshObjectives()"  (ObjectiveChangedEvent)="refreshObjectives  ()" ></app-objective-player-row>    
            </ng-container>
            <span *ngIf="this._objectives==null || this._objectives.length==0" style="color: gray; margin-top: 30px;">
                NO OBJECTIVES FOUND
            </span>
            
          
        </mat-tab>
        <mat-tab  class="ds_tab"  *ngIf="this.isInjuryViewer && this.Injuries">
            <ng-template matTabLabel>
                <mat-icon>flash_on</mat-icon>
                <span [matBadge]="this.countActiveInjuries()" matBadgeSize="small" matBadgeColor="warn" [matBadgeHidden]="this.countActiveInjuries()===0">INJURY</span>
            </ng-template>
            
            <ng-template matTabContent>
                <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                    <app-player-injury [Injury]="injury" *ngFor="let injury of this.Injuries"></app-player-injury>
                </div>
                <div *ngIf="this.Injuries.length==0" style="margin: 5px; color: gray;">
                    NO INJURIES                 </div>
                    
            </ng-template>
            
        </mat-tab>
        <mat-tab  class="ds_tab" style="width: 120px;" *ngIf="this.isContactsViewer">
            <ng-template mat-tab-label>
                <mat-icon>person</mat-icon>
                <span  >CONTACTS</span>                
            </ng-template>
            <div  class="hidescrollbars"     style="overflow-y:scroll !important; height: calc(100%-120px) !important;" >
              
                <label>AGENTS</label> &nbsp;<button type="button" mat-button (click)="openAddAgentDialog()" *ngIf="this.isContactsEditor">ADD AGENT</button><br/>
                
                <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                   
                    <app-agent-card *ngFor="let agent of _agents" [Agent]="agent" [ConnectedPlayerId]="this._playerId" (AgentRemovedFromPlayer)="this.refreshAgents()"></app-agent-card>                
                   
                   
                </div>
                <div *ngIf="this._agents?.length===0" style="margin: auto;color: gray;height: 50px;">
                    NO AGENT INFO
                </div>
               
                <mat-divider></mat-divider>
                <label>CONTACTS</label> &nbsp; <button type="button" mat-button (click)="openAddContactDialog()" *ngIf="this.isContactsEditor">ADD CONTACT</button>

                <div *ngIf="this._contacts?.length===0" style="margin: auto;color: gray; height: 50px;">
                    NO CONTACTS
                </div>
                <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                    <app-player-contact-card *ngFor="let contact of this._contacts" [Contact]="contact" (ContactUpdated)="refreshContacts();" (ContactDeleted)="refreshContacts();"></app-player-contact-card>
                </div>
                
                <mat-divider></mat-divider>
            </div>
        </mat-tab>
        <mat-tab class="ds_tab" *ngIf="this.isFormsViewer && this.player">
            <ng-template mat-tab-label>
                <mat-icon>dynamic_form</mat-icon>
                <span  >FORMS</span>                
            </ng-template>
            <div  class="hidescrollbars"     style="overflow-y:scroll !important; height: calc(100%-120px) !important;" >
                <ng-container *ngFor="let team of this.player.Teams">
                    <button  mat-raised-button color="primary" (click)="openSelectFormDialog(team.TeamId, team.LevelName)">NEW ({{team.LevelName}})</button>&nbsp;
                </ng-container>                
                <button mat-button (click)="this.minimizedForms=!this.minimizedForms;">
                    <SPAN *ngIf="this.minimizedForms">EXPAND ALL</SPAN>
                    <span *ngIf="!this.minimizedForms">MINIMIZE ALL</span>
                </button>&nbsp;
            </div>
            <div> 
                

            </div>
            <div style="overflow-y:scroll !important; height:calc(100vh - 200px); display: flex; flex-direction: row; flex-wrap: wrap;" >
                <app-form-card *ngFor="let form of this.formsList" [FormSubmitted]="form" [Minimized]="this.minimizedForms" (onDelete)="refreshForms()" ></app-form-card>
            </div>
        </mat-tab>
     <!--    <mat-tab class="ds-tab" label="SCHEDULE" >
            <ng-template mat-tab-label>
                <mat-icon>calendar_month</mat-icon>
                &nbsp;
                SCHEDULE
            </ng-template>
             
           
        </mat-tab> -->

        <mat-tab class="ds_tab" label="Access" *ngIf="false">
            <table>
                <tr>
                    <td>isStatisticsViewer</td>
                    <td>{{this.isStatisticsViewer}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>isObjectiveViewer</td>
                    <td>{{this.isObjectiveViewer}}</td>
                    <td>{{this.isTeamObjectivesEnabled}}</td>
                </tr>
                <tr>
                    <td>isTrainingViewer</td>
                    <td>{{this.isTrainingViewer}}</td>
                    <td>{{this.isTeamTrainingEnabled}}</td>
                </tr>
               
                <tr>
                    <td>isContactsViewer</td>
                    <td>{{this.isContactsViewer}}</td>
                    <td>{{this.isTeamContactsEnabled}}</td>
                </tr>
                <tr>
                    <td>isContactsEditor</td>
                    <td>{{this.isContactsEditor}}</td>
                    <td>{{this.isTeamContactsEnabled}}</td>
                </tr>
                <tr>
                    <td>isGameAssessmentViewer</td>
                    <td>{{this.isGameAssessmentViewer}}</td>
                    <td>{{isTeamGameAssessmentEnabled}}</td>
                </tr>
                <tr>
                    <td>IsIjnuryViewer</td>
                    <td>{{this.isInjuryViewer}}</td>
                    <td>{{this.isTeamInjuryEnabled}}</td>
                </tr>
            

            </table>
           
        </mat-tab>
      

        
</mat-tab-group>

<button mat-fab style="position: fixed; bottom: 25px; left: 10px; z-index: 1; opacity: 0.5;" (click)="location.back()">
    <mat-icon >navigate_before</mat-icon>
</button>

<button  *ngIf="!(isHandset$ | async) " mat-fab style="position: fixed; bottom: 25px; left: 220px; z-index: 1; opacity: 0.5;" (click)="location.back()">
    <mat-icon >navigate_before</mat-icon>
</button>
</div>