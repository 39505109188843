import { Component, OnInit } from '@angular/core';
import { Team } from 'src/app/shared/team.model';
import { TeamService } from 'src/app/shared/team.service';

@Component({
  selector: 'app-organization-teams-dashboard',
  templateUrl: './organization-teams-dashboard.component.html',
  styleUrls: ['./organization-teams-dashboard.component.css']
})
export class OrganizationTeamsDashboardComponent implements OnInit {

  teams: Team[];
  
  constructor( public teamService: TeamService) { }

  ngOnInit(): void {
    this.refreshtData();
  }

  refreshtData(){
    this.teamService.getTeams().subscribe((data: any) =>{
      this.teams = data;
    });
  }

}
