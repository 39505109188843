<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>LastName, FirstName</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list cols="5" rowHeight="2:1">
      <mat-grid-tile>1</mat-grid-tile>
      <mat-grid-tile>2</mat-grid-tile>
      <mat-grid-tile>3</mat-grid-tile>
      <mat-grid-tile>4</mat-grid-tile>
      <mat-grid-tile>5</mat-grid-tile>
      <mat-grid-tile>6</mat-grid-tile>
      <mat-grid-tile>7</mat-grid-tile>
      <mat-grid-tile>8</mat-grid-tile>
      <mat-grid-tile>9</mat-grid-tile>
      <mat-grid-tile>10</mat-grid-tile>
      
    </mat-grid-list>
  </mat-card-content>
</mat-card>
