import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { TestResultPlayer } from 'src/app/shared/models/test.model';
import * as moment from 'moment';
import { TestResultModule } from 'src/app/test-result/test-result.module';
declare var google:any;

@Component({
  selector: 'app-playercompare-chart-numeric',
 
  templateUrl: './playercompare-chart-numeric.component.html',
  styleUrls: ['./playercompare-chart-numeric.component.css']
})



export class PlayercompareChartNumericComponent implements OnInit {
  _xaxis: string = 'nn';
  _interval: string = '2Y';
  _results: TestResultPlayer[];
  _resultsChanged: boolean = true;
  @Input() PlayerId: string;
  @Input() TestId: number;
  @Input() Results: TestResultPlayer[];
  @Input() LimitsOK_Series: TestResultPlayer[];
  @Input() LimitsGood_Series: TestResultPlayer[];
  @Input() LimitsVeryGood_Series: TestResultPlayer[];
  @Input() LimitsExcellent_Series: TestResultPlayer[];
  
  /* set Results(value:TestResultPlayer[]){
    this._results = value;
  }
  get(): TestResultPlayer[] {
    return this._results;
  } */
  @Input() ResultsForComparison: TestResultPlayer[];
  @Input() ResultType: number;
  @Input() ChartHeight: string;

  @Input() 
  set xvalue(xvalue: string) {
    this._xaxis = (xvalue && xvalue.trim()) || 'date';
  
    //this.ngOnInit();
  }

  @Input() 
  set Interval(interval: string) {
    this._interval = (interval && interval.trim()) || '2Y';
    //this.ngOnInit();
  }

  @Input() CurrentAge: number;

  
  
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {

    
    var _results= this.Results;
    var _limitsOK = this.LimitsOK_Series;
    var _limitsGood = this.LimitsGood_Series;
    var _limitsVeryGood = this.LimitsVeryGood_Series;
    var _limitsExcellent = this.LimitsExcellent_Series;
    var _resultsforcomparison= this.ResultsForComparison;
    var _resType = this.ResultType;
    var _xvalue = this._xaxis;
    var _interval = this._interval;
    var _currentAge = this.CurrentAge;
    
   
    //Need uniquname even if component generated many teams, Key = Playerid+TestId
    var divname = 'chart_div_'+ this.PlayerId + "_" + this.TestId;
    
     
      google.charts.load('current', {'packages':['corechart']});
      google.charts.setOnLoadCallback(function(){drawChart()});        

 
    function drawChart() {

      // Create the data table.
      var data = new google.visualization.DataTable();
      var dataforcomparison = new google.visualization.DataTable();
      var dataLimitOK = new google.visualization.DataTable();
      var dataLimitGood = new google.visualization.DataTable();
      var dataLimitVeryGood = new google.visualization.DataTable();
      var dataLimitExcellent = new google.visualization.DataTable();
      

      if(_results.length==0)
        return;

        //X axis
      if(_xvalue=='date')
        data.addColumn('date', 'Date'); 
      else if(_xvalue='age')
        data.addColumn('number', 'Age');

        //Y axis
      switch(_resType){
        case 30:
        case 31:
            data.addColumn('timeofday', 'Value');
          break;
        default:
            data.addColumn('number', 'Value');
      }      

      if(_xvalue=='date'){
        dataforcomparison.addColumn('date', 'Date'); 
        dataLimitOK.addColumn('date', 'Date'); 
        dataLimitGood.addColumn('date', 'Date'); 
        dataLimitVeryGood.addColumn('date', 'Date'); 
        dataLimitExcellent.addColumn('date', 'Date'); 
      }        
      else if(_xvalue='age'){
        dataforcomparison.addColumn('number', 'Age');
        dataLimitOK.addColumn('number', 'Age');
        dataLimitGood.addColumn('number', 'Age');
        dataLimitVeryGood.addColumn('number', 'Age');
        dataLimitExcellent.addColumn('number', 'Age');
      }
        

        //Y axis
      switch(_resType){
        case 30:
        case 31:
          dataforcomparison.addColumn('timeofday', 'Value');
          dataLimitOK.addColumn('timeofday', 'Value');
          dataLimitGood.addColumn('timeofday', 'Value');
          dataLimitVeryGood.addColumn('timeofday', 'Value');
          dataLimitExcellent.addColumn('timeofday', 'Value');
          break;
        default:
          dataforcomparison.addColumn('number', 'Value');
          dataLimitOK.addColumn('number', 'Value');
          dataLimitGood.addColumn('number', 'Value');
          dataLimitVeryGood.addColumn('number', 'Value');
          dataLimitExcellent.addColumn('number', 'Value');
      }
   

      var _data = [];
      var _dataforcomparison = [];
      var _dataOKLimit = [];
      var _dataOKGood = [];
      var _dataOKVeryGood = [];
      var _dataOKExcellent = [];
      var count = 0;
      
      _results.forEach(element => {
        count++;
        switch(_resType){
          case 30:
          case 31:
            
            
            var hours = parseInt(element.ResultTime.toString().substr(0,2));
            var minutes = parseInt(element.ResultTime.toString().substr(3,2));
            var seconds = parseInt(element.ResultTime.toString().substr(6,2));
            if(_xvalue=='date'){
              _data.push([new Date(element.PerformedDate), [hours, minutes,seconds]]);
            
            }
            else{
              _data.push([element.AgeYearsDecimal, [hours, minutes,seconds]]);
           
            }
            
            break;
          case 10:
            
              if(_xvalue=='date'){
                _data.push([new Date(element.PerformedDate), element.ResultInt]);
               
              }
                
              else{
                _data.push([element.AgeYearsDecimal, element.ResultInt]);
               
              }
                
              break;
          case 20:
          case 21:
              if(_xvalue=='date'){
                _data.push([new Date(element.PerformedDate), element.ResultDecimal]);
               
              }
                
              else{
                _data.push([element.AgeYearsDecimal, element.ResultDecimal]);
               
              }
                
            break;
          
          default:
            

        }                
      });

      //Limitseries
      if(_limitsOK!=null){
        //console.log('Wazupp!');
      
        _limitsOK.forEach(element => {
          count++;
          switch(_resType){
            case 30:
            case 31:
              
              
              var hours = parseInt(element.ResultTime.toString().substr(0,2));
              var minutes = parseInt(element.ResultTime.toString().substr(3,2));
              var seconds = parseInt(element.ResultTime.toString().substr(6,2));
              if(_xvalue=='date'){
                _dataOKLimit.push([new Date(element.PerformedDate), [hours, minutes,seconds]]);              
              }
              else{
                _dataOKLimit.push([element.AgeYearsDecimal, [hours, minutes,seconds]]);            
              }
              
              break;
            case 10:
              
                if(_xvalue=='date'){
                  _dataOKLimit.push([new Date(element.PerformedDate), element.ResultInt]);                
                }                  
                else{
                  _dataOKLimit.push([element.AgeYearsDecimal, element.ResultInt]);                
                }
                  
                break;
            case 20:
            case 21:
                if(_xvalue=='date'){
                  _dataOKLimit.push([new Date(element.PerformedDate), element.ResultDecimal]);                
                }
                  
                else{
                  _dataOKLimit.push([element.AgeYearsDecimal, element.ResultDecimal]);                
                }
                  
              break;          
            default:            

          }         
          //console.log(_dataOKLimit);
          if(_dataOKLimit!=null && _dataOKLimit.length>0){
            //console.log('Hännä händärä');
            dataLimitOK.addRows(_dataOKLimit);
            //console.log(dataLimitOK);
          }
        });
       
          
      }
      if(_limitsGood!=null){
        
      
        _limitsGood.forEach(element => {
          count++;
          switch(_resType){
            case 30:
            case 31:
              
              
              var hours = parseInt(element.ResultTime.toString().substr(0,2));
              var minutes = parseInt(element.ResultTime.toString().substr(3,2));
              var seconds = parseInt(element.ResultTime.toString().substr(6,2));
              if(_xvalue=='date'){
                _dataOKGood.push([new Date(element.PerformedDate), [hours, minutes,seconds]]);              
              }
              else{
                _dataOKGood.push([element.AgeYearsDecimal, [hours, minutes,seconds]]);            
              }
              
              break;
            case 10:
              
                if(_xvalue=='date'){
                  _dataOKGood.push([new Date(element.PerformedDate), element.ResultInt]);                
                }                  
                else{
                  _dataOKGood.push([element.AgeYearsDecimal, element.ResultInt]);                
                }
                  
                break;
            case 20:
            case 21:
                if(_xvalue=='date'){
                  _dataOKGood.push([new Date(element.PerformedDate), element.ResultDecimal]);                
                }
                  
                else{
                  _dataOKGood.push([element.AgeYearsDecimal, element.ResultDecimal]);                
                }
                  
              break;          
            default:            

          }         
          ////console.log(_dataOKGood);
          if(_dataOKGood!=null && _dataOKGood.length>0){
            ////console.log('Hännä händärä Good');
            dataLimitGood.addRows(_dataOKGood);
            ////console.log(dataLimitGood);
          }
        });
       
          
      }
      if(_limitsVeryGood!=null){
        
      
        _limitsVeryGood.forEach(element => {
          count++;
          switch(_resType){
            case 30:
            case 31:
              
              
              var hours = parseInt(element.ResultTime.toString().substr(0,2));
              var minutes = parseInt(element.ResultTime.toString().substr(3,2));
              var seconds = parseInt(element.ResultTime.toString().substr(6,2));
              if(_xvalue=='date'){
                _dataOKVeryGood.push([new Date(element.PerformedDate), [hours, minutes,seconds]]);              
              }
              else{
                _dataOKVeryGood.push([element.AgeYearsDecimal, [hours, minutes,seconds]]);            
              }
              
              break;
            case 10:
              
                if(_xvalue=='date'){
                  _dataOKVeryGood.push([new Date(element.PerformedDate), element.ResultInt]);                
                }                  
                else{
                  _dataOKVeryGood.push([element.AgeYearsDecimal, element.ResultInt]);                
                }
                  
                break;
            case 20:
            case 21:
                if(_xvalue=='date'){
                  _dataOKVeryGood.push([new Date(element.PerformedDate), element.ResultDecimal]);                
                }
                  
                else{
                  _dataOKVeryGood.push([element.AgeYearsDecimal, element.ResultDecimal]);                
                }
                  
              break;          
            default:            

          }         
          ////console.log(_dataOKVeryGood);
          if(_dataOKVeryGood!=null && _dataOKVeryGood.length>0){
            ////console.log('Hännä händärä VeryGood');
            dataLimitVeryGood.addRows(_dataOKVeryGood);
            ////console.log(dataLimitVeryGood);
          }
        });
       
          
      }
      if(_limitsExcellent!=null){
        
      
        _limitsExcellent.forEach(element => {
          count++;
          switch(_resType){
            case 30:
            case 31:
              
              
              var hours = parseInt(element.ResultTime.toString().substr(0,2));
              var minutes = parseInt(element.ResultTime.toString().substr(3,2));
              var seconds = parseInt(element.ResultTime.toString().substr(6,2));
              if(_xvalue=='date'){
                _dataOKExcellent.push([new Date(element.PerformedDate), [hours, minutes,seconds]]);              
              }
              else{
                _dataOKExcellent.push([element.AgeYearsDecimal, [hours, minutes,seconds]]);            
              }
              
              break;
            case 10:
              
                if(_xvalue=='date'){
                  _dataOKExcellent.push([new Date(element.PerformedDate), element.ResultInt]);                
                }                  
                else{
                  _dataOKExcellent.push([element.AgeYearsDecimal, element.ResultInt]);                
                }
                  
                break;
            case 20:
            case 21:
                if(_xvalue=='date'){
                  _dataOKExcellent.push([new Date(element.PerformedDate), element.ResultDecimal]);                
                }
                  
                else{
                  _dataOKExcellent.push([element.AgeYearsDecimal, element.ResultDecimal]);                
                }
                  
              break;          
            default:            

          }         
          ////console.log(_dataOKExcellent);
          if(_dataOKExcellent!=null && _dataOKExcellent.length>0){
            ////console.log('Hännä händärä VeryGood');
            dataLimitExcellent.addRows(_dataOKExcellent);
            ////console.log(dataLimitExcellent);
          }
        });
       
          
      }
      /* //console.log("-----------------");
      //console.log(_dataforcomparison.length);
      //console.log(_data.length); */
      if(_resultsforcomparison!=null){
             _resultsforcomparison.forEach(element => {
      
                switch(_resType){
                  case 30:
                  case 31:
                    
                    
                    var hours = parseInt(element.ResultTime.toString().substr(0,2));
                    var minutes = parseInt(element.ResultTime.toString().substr(3,2));
                    var seconds = parseInt(element.ResultTime.toString().substr(6,2));
                    if(_xvalue=='date')
                    _dataforcomparison.push([new Date(element.PerformedDate), [hours, minutes,seconds]]);
                    else
                    _dataforcomparison.push([element.AgeYearsDecimal, [hours, minutes,seconds]]);
                    break;
                  case 10:
                    
                      if(_xvalue=='date')
                        _dataforcomparison.push([new Date(element.PerformedDate), element.ResultInt]);
                      else
                        _dataforcomparison.push([element.AgeYearsDecimal, element.ResultInt]);
                      break;
                  case 20:
                  case 21:
                      if(_xvalue=='date')
                        _dataforcomparison.push([new Date(element.PerformedDate), element.ResultDecimal]);
                      else
                        _dataforcomparison.push([element.AgeYearsDecimal, element.ResultDecimal]);
                    break;
                  
                  default:
                    

                }                
              });

              dataforcomparison.addRows(_dataforcomparison);
      }
 

      //console.log('Hännä3');
      
      data.addRows(_data);
     
      if(dataforcomparison!=null){
        var datafull = google.visualization.data.join(data, dataforcomparison, 'full',  [[0, 0]], [1], [1]);
        ////console.log("joining data");
        ////console.log(datafull);      
        ////console.log(dataLimitOK);     
        if(_dataOKLimit!=null){
          var dataevenfuller = google.visualization.data.join(datafull, dataLimitOK, 'full',  [[0, 0]], [1, 2], [1]);         
          var dataevenfuller = google.visualization.data.join(dataevenfuller, dataLimitGood, 'full',  [[0, 0]], [1,2,3], [1]);
          var dataevenfuller = google.visualization.data.join(dataevenfuller, dataLimitVeryGood, 'full',  [[0, 0]], [1,2,3,4], [1]);
          var dataevenfuller = google.visualization.data.join(dataevenfuller, dataLimitExcellent, 'full',  [[0, 0]], [1,2,3,4,5], [1]);
         /*  //console.log('ggggg---------------');
          //console.log(dataevenfuller);
          //console.log('ggggg---------------'); */
        }else{
          var dataevenfuller = datafull;
        }
  
      }
      else{        
        ////console.log("joining data2");
        ////console.log(datafull);  
        if(_dataOKLimit!=null){
          var dataevenfuller = google.visualization.data.join(data, dataLimitOK, 'full',  [[0, 0]], [1], [1]);
          if(_dataOKGood.length>0){
            var dataevenfuller = google.visualization.data.join(dataevenfuller, dataLimitOK, 'full',  [[0, 0]], [1,2], [1]);
            var dataevenfuller = google.visualization.data.join(dataevenfuller, dataLimitGood, 'full',  [[0, 0]], [1,2,3], [1]);
            var dataevenfuller = google.visualization.data.join(dataevenfuller, dataLimitVeryGood, 'full',  [[0, 0]], [1,2,3,4], [1]);
            var dataevenfuller = google.visualization.data.join(dataevenfuller, dataLimitExcellent, 'full',  [[0, 0]], [1,2,3,4,5], [1]);
            ////console.log('hhhhh');
          }
        }else{
          dataevenfuller = data;
        }
        
      }
    
      //console.log('Hännä2');
    

      var options;
      if(_xvalue=='date'){
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var minDate = new Date(year, month, day);
        //minDate.setMonth(minDate.getMonth()-3);
        
        switch(_interval){
          case "3M":
            minDate.setMonth(minDate.getMonth()-3);
            break;
          case "6M":
            minDate.setMonth(minDate.getMonth()-6);
            break;
          case "1Y":
              minDate= new Date(year-1, month, day);
              break;
          case "2Y":
              minDate= new Date(year-2, month, day);
              break;
          case "3Y":
              minDate= new Date(year-3, month, day);
              break;
          case "4Y":
            minDate= new Date(year-4, month, day);
            break;
          case "5Y":
            minDate= new Date(year-5, month, day);
            break;
          case "10Y":
              minDate= new Date(year-10, month, day);
              break;
        }
        //e.log("mintdate" + minDate);
        //console.log('Hännä1');
        
         /* if(_LimitOK!=null){
          
          _dataOKLimit.push([new Date(minDate), _LimitOK]);
          _dataOKLimit.push([new Date(d), _LimitOK]);
          datafull = google.visualization.data.join(data, _dataOKLimit, 'full', [[0, 0]], [1], [1]);
        } */
        
        //console.log('almost dwrawing now');
        //console.log(dataevenfuller);
        options = {
        
          //chartArea: { width: this._chartWidth, height: this._chartHeight},
          legend: { position: 'none' },
          //chartArea: { height: '100%'},
          //histogram: { bucketSize: 1 },
          chartArea:{left:'10%',width:'80%',height:'80%'},
          series: {
            2: { pointSize: '3',pointShape: 'square',lineWidth: 1},
            3: { pointSize: '3',pointShape: 'square',lineWidth: 1},
            4: { pointSize: '3',pointShape: 'square',lineWidth: 1},
            5: { pointSize: '3',pointShape: 'square',lineWidth: 1}
          },
          colors: ['white', '#1E90FF', '#FFC107', '#00ff00', 'limegreen', '#16DCFF'],
          pointSize: 5,
          interpolateNulls: true,
          backgroundColor: '#424242',
          hAxis: { textStyle: { color: 'white', opacity: 0.3} , gridlines: { color: 'white', opacity: 0.3},  baselineColor: 'white', minValue: minDate, maxValue: d },
          vAxis: { textStyle: { color: 'white', opacity: 0.3} , baselineColor: 'white', opacity:0.3 },
        }


      }else{
        var minAge: number;

        switch(_interval){
          case "3M":
            minAge = _currentAge - 3.0/12;
            break;
          case "6M":
            minAge = _currentAge - 0.5
            break;
          case "1Y":
              minAge = _currentAge - 1;
              break;
          case "2Y":
              minAge = _currentAge - 2;
              break;
          case "3Y":
              minAge = _currentAge - 3;
              break;
          case "4Y":
            minAge = _currentAge - 4;
            break;
          case "5Y":
            minAge = _currentAge - 5;
            break;
          case "10Y":
            minAge = _currentAge - 10;
            break;
        }
        
        /* //console.log(minAge);
        //console.log(_currentAge); */
        options = {
          legend: { position: 'none' },
          //histogram: { bucketSize: 1 },
          chartArea:{height:'80%'},
          colors: ['white', '#1E90FF', '#FFC107', '#00ff00', 'limegreen', '#16DCFF'],
          pointSize: 5,
          interpolateNulls: true,
          series: {
            2: { pointSize: '3',pointShape: 'square',lineWidth: 1},
            3: { pointSize: '3',pointShape: 'square',lineWidth: 1},
            4: { pointSize: '3',pointShape: 'square',lineWidth: 1},
            5: { pointSize: '3',pointShape: 'square',lineWidth: 1}
          },
          backgroundColor: '#424242',
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', minValue: minAge, maxValue: _currentAge },
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white' },
        }
        

      }
      


       
      
      
      
      ////console.log("redrawing");
      var chart = new google.visualization.LineChart(document.getElementById(divname));
      chart.draw(dataevenfuller, options);
      }
      
      
          
  }

}