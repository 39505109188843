import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ScoutingSourceViewModel } from 'src/app/scouting/models/ReportModels';
import { ScoutingSourceService } from 'src/app/scouting/services/scouting-source.service';

@Component({
  selector: 'app-scouting-source-list',
  standalone: true,
  imports: [MatTableModule, MatIconModule, MatSlideToggleModule],
  templateUrl: './scouting-source-list.component.html',
  styleUrl: './scouting-source-list.component.css'
})
export class ScoutingSourceListComponent {

  public displayedColumns: string[] = ['ScoutingSourceId', 'Text','IsEnabled'];
  public dataSource = new MatTableDataSource<ScoutingSourceViewModel>();
  public data = [];

  constructor(public sourceSrv: ScoutingSourceService, private dialog: MatDialog) {
   
    this.loadData();

  }

  loadData(){
    this.sourceSrv.getSources().subscribe( sources =>{
      //console.log(users);
      this.data = sources;
      this.dataSource = new MatTableDataSource(this.data);
      /* this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator; */
    });
  }


  
  onIsEnabledChange(value, ScoutingSourceId) {
    console.log(value.checked);
    if(value.checked){
      console.log("call service");
      this.sourceSrv.activateSource(ScoutingSourceId).subscribe(result=>{
        if(result)
          this.loadData;
      });
    
    }else{
      this.sourceSrv.inactivateSource(ScoutingSourceId).subscribe(result=>{
        if(result)
          this.loadData;
      });
    }
  }

}
