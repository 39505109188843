
<div class="app-page">
    <h2 mat-dialog-title style="font-family: Roboto;">
      <mat-icon>table_chart</mat-icon>
      Create new assigned program
    </h2>
  <form  [formGroup]="assignedPgmForm" (ngSubmit)="save()" autocomplete="off">
    <mat-form-field appearance="fill"  style="width: 400px;">
      <mat-label>Program assignment title</mat-label>
      <input matInput placeholder="Enter title" formControlName="Title" id="Title" autofocus  /> 
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <!-- <mat-hint>Hint</mat-hint> -->
    </mat-form-field>
    <br/>
    <mat-form-field appearance="fill"  style="width: 400px;" >
        <mat-label>Select program template</mat-label>
        <mat-select formControlName="ProgramId"  >
            <mat-option>-</mat-option>
            <mat-option *ngFor="let pgm of programs" [value]="pgm.ProgramId">{{pgm.Name}} ({{pgm.NoOfWeeks}} weeks {{pgm.NoOfWorkouts}} workouts)</mat-option>            
        </mat-select>
    </mat-form-field>
    <br/>
 
    <mat-form-field appearance="fill">
        <mat-label>Choose a start date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="StartDate">
        <mat-hint>YYYY/MM/DD</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    <br/>
    

    <mat-form-field appearance="fill">
        <mat-label>Repeat program</mat-label>
        <input matInput type="number" value="1"  formControlName="RepeatProgramCount" id="RepeatProgramCount" />
    </mat-form-field>
    <!-- <mat-form-field appearance="fill" style="width: 400px;">
      <mat-label>Description</mat-label>
      <textarea matInput placeholder="Describe how to perform the exercise" formControlName="Description" id="Description"></textarea>  
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <!-- <mat-hint>Hint</mat-hint> -->

    <br/> 
<!--     <div style="display: flex; flex-direction: row; ">
        <div style="height: 350px;">
            <label>Players</label>
            <mat-selection-list #players style="height: 300px; overflow-y: scroll; background-color: #424242;" dense>
                <mat-divider></mat-divider>
                <div mat-subheader>Herrlag</div>
                <mat-list-option ngFor="let shoe of typesOfShoes">
                  Andreas Holmberg
                </mat-list-option>
                <mat-list-option ngFor="let shoe of typesOfShoes">
                    Andreas Holmberg
                  </mat-list-option>
                  <mat-list-option ngFor="let shoe of typesOfShoes">
                    Andreas Holmberg
                  </mat-list-option>
                  <mat-list-option ngFor="let shoe of typesOfShoes">
                    Andreas Holmberg
                  </mat-list-option>
                  <mat-divider></mat-divider>
                  <div mat-subheader>J20</div>
                  <mat-list-option ngFor="let shoe of typesOfShoes">
                    Andreas Holmberg
                  </mat-list-option>
                  <mat-list-option ngFor="let shoe of typesOfShoes">
                    Andreas Holmberg
                  </mat-list-option>
                  <mat-list-option ngFor="let shoe of typesOfShoes">
                    Andreas Holmberg
                  </mat-list-option>
                  <mat-list-option ngFor="let shoe of typesOfShoes">
                    Andreas Holmberg
                  </mat-list-option>
                  <mat-list-option ngFor="let shoe of typesOfShoes">
                    Andreas Holmberg
                  </mat-list-option>
                  <mat-list-option ngFor="let shoe of typesOfShoes">
                    Andreas Holmberg
                  </mat-list-option>
            </mat-selection-list>
        </div>
        <div style="padding: 20px;">
            <button mat-raised-button style="width: 120px;">ADD>></button><br/>
            <button mat-raised-button style="width: 120px;">&lt;&lt;REMOVE</button>
        </div>
        <div style="height: 100px;">
            <label>Assign to</label>
            <mat-selection-list #players style="height: 300px; overflow-y: scroll; background-color: #424242;" dense>
                <mat-list-option ngFor="let shoe of typesOfShoes">
                  Andreas Holmberg
                </mat-list-option>
            </mat-selection-list>
        </div>
       
    </div> -->
     
<!--       <div formArrayName="AssignedPlayers" style="display: flex; flex-direction: row; flex-wrap: wrap; width: 100%;">
        <div *ngFor="let player of this.assignedPlayers?.controls; let i= index;" >
            <div [formGroupName]="i" style="border: 1px solid darkgray;" >
              <mat-icon>person</mat-icon>
              {{player.get('LastName').value}}, {{player.get('FirstName').value}}
            </div>
            

        </div>

      </div> -->
    <div style="font-family: Roboto;">
      Selecting a team assigns the program to all players in the team.<br>
      Assign program to specific players by adding the in the Assigned players field.<br/>
      You can also use any method, or both.
    </div>
    <mat-form-field appearance="fill"  style="width: 400px;">
        <mat-label>Assign to team</mat-label>
        <mat-select formControlName="TeamId"  >
            <mat-option>-</mat-option>
            <mat-option *ngFor="let team of teams" [value]="team.TeamId">team.Name - team.Level </mat-option>            
        </mat-select>
    </mat-form-field>
    <br/>
    <div style="display: flex; flex-direction: row;">
      <mat-form-field style="max-width: 480px; min-width: 400px;" appearance="fill" formArrayName="AssignedPlayers">
        <mat-label>Assigned players</mat-label>
        <mat-chip-grid #chipList aria-label="Fruit selection">
          <mat-chip-row
          *ngFor="let player of this.assignedPlayers?.controls; let i= index;"
          [formGroupName]="i"
          (removed)="remove(player.get('PlayerId').value)"
          >
          <mat-icon>person</mat-icon>
          {{player.get('LastName').value}}, {{player.get('FirstName').value}}
            <button matChipRemove >
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input
          placeholder="New player..."
          #playerInput
          [formControl]="playerCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
        >
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let player of players" [value]="player">
            {{player.Player.LastName}},{{player.Player.FirstName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div>
        <button mat-button type="button" (click)="openAddPlayerDialog()">ADD MULTIPLE PLAYERS</button>

      </div>
    </div>
     
   <br/>
    <button mat-button color="primary"  type="submit" [disabled]="!assignedPgmForm.valid">CREATE</button>

    <button type="button" mat-button  (click)="onCancel()">CANCEL</button>
    <!-- <div  *ngFor="let Video of this.myVideos; let i=index">  
      <video width="270" height="220"   controls disabled="true"  (click)="toggleVideo()" #videoPlayer>   
        <source [src]="Video.URL" type="video/mp4">   
      </video>  
    </div>   -->
      
    

    
  </form>
      
 
    
</div>
