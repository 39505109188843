
 
<div class="app-page" style="margin-left: 10px; height: 100%;"  >
    <mat-progress-bar mode="indeterminate" *ngIf="this.isBusy()"></mat-progress-bar>
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <div style="display: flex;flex-direction: row; margin-right: 30px;">
            <!-- <img src="../../assets/img/icons8-user-shield-48.png" style="height: 48px;"/><br/> -->
            <mat-icon style="font-size: 70px; height: 100px; width: 70px;">account_circle</mat-icon>
            <div>
                            <span style="font-size: x-large; ">{{this._playerData?.FlowPlayer.FirstName }} {{_playerData?.FlowPlayer.LastName}}</span><br/>
            <span style="font-size: small; ">
                {{this._playerData?.FlowPlayer.DateOfBirth | date: 'yyy-MM-dd'}}
            </span>
            </div>

        </div>
        <div style="font-size: small;">
            <span style=" font-size: medium;"  >General</span>
            <table>
                <tr>
                    <td style="width: 70px;">Age:</td>
                    <td>
                        {{this._playerData?.PlayerAgeYear }} years {{this._playerData?.PlayerAgeMonth }} months
                    </td>
                    <td></td>
                    <td style="width: 70px;">Height:</td>
                    <td>{{this._playerData?.FlowPlayer.Height }} cm</td>
                </tr>
                <tr>
                    <td>Position:</td>
                    <td></td>
                    <td></td>
                    <td>Weight:</td>
                    <td>{{this._playerData?.FlowPlayer.Weight }} kg</td>
                </tr>
            <!--    <tr>
                    <td>Height:</td>
                    <td>{{this._playerData?.FlowPlayer.Height }} cm</td>
                    <td></td>
                    <td style="width: 70px;">Age:</td>
                    <td></td>
                </tr>
            <tr>
                    <td>Weight:</td>
                    <td>{{this._playerData?.FlowPlayer.Weight }} kg</td>
                    <td></td>
                    <td style="width: 70px;">Age:</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Shoots:</td>                    
                    <td *ngIf="this._playerData?.FlowPlayer.ShootId==1">L</td>
                    <td *ngIf="this._playerData?.FlowPlayer.ShootId==2">R</td>
                    <td></td>
                </tr> -->
                <tr *ngIf="this._playerData?.Module_Tags">
                    <td>Tags:</td>
                    <td>
                        <div style="display:flex; align-items: baseline;">
                            <div *ngFor="let tag of this._playerData.tags" [ngClass]="tag.TagColor" (click)="openShowTagDialog();" style="height: 20px;">
                                {{tag.TagText}}
                            </div>
                            <button mat-icon-button aria-label="Add" (click)="addTag()" >
                                <mat-icon >add</mat-icon>
                              </button>
                              <button mat-icon-button aria-label="Edit" (click)="openShowTagDialog();">
                                <mat-icon>remove</mat-icon>
                              </button>
                            
                        </div>
                    </td>
                    <td>
                     
                    </td>

                </tr>
              
            </table>
         
        </div>
    
       
       <!--  <div>
            <span style=" font-size: small;">Teams</span>
            <div *ngFor="let team of this._playerData?.playerteams" style="">
                <img [src]="'../assets/img' + team.SmallIconFileName" style="height: 20px;"/>
                <b>{{ team.JerseyNo }}</b>&nbsp;{{team.Division}} - {{team.Name}}
            </div>
        </div> -->
    </div>
<div>



    <mat-tab-group [selectedIndex]="this.selectedTab.value"  (selectedIndexChange)="selectedTab.setValue($event)"  mat-stretch-tabs="false" mat-align-tabs="start" class="mat-tab-fill-height"  >
<!--         <mat-tab label="Overview"> 
           
            <div class="hidescrollbars"    style="overflow-y:scroll !important; height: calc(100%-120px) !important;" *ngIf="this._playerData && this._reportmasterdata" >
                <div style="display: flex; flex-direction: column; ">
                    <div>
                        <span style="">Past games</span>
                        <div *ngIf="this._playerData.GamesWithRanking.length==0" class="div_nodata">
                            NO DATA
                        </div>
                        <div style="display: flex; width: 100%; overflow-x: scroll;" class="hidescrollbars" >
                            <app-game-card *ngFor="let game of this._playerData.GamesWithRanking.slice(0,6)" [Game]="game" [ShowDate]="true"></app-game-card>
                        </div>
                    </div>
                    <span style="">Coming games</span>
                    <div *ngIf="this._playerData.games.length==0" class="div_nodata">
                        NO DATA
                    </div>
                    <div style="display: flex; width: 100%; overflow-x: scroll;" class="hidescrollbars" >
                    
                        <app-game-card *ngFor="let game of this._playerData.games.slice(0,6)" [Game]="game" [ShowDate]="true">

                        </app-game-card>
                    </div>
                </div>
                <span style="">Scouting summary</span>
                <div *ngIf="this._playerData.flowitem.length==0" class="div_nodata">
                    NO DATA
                </div>
               <app-player-projection-chart *ngIf="this._playerData.flowitem.length>0"  [PlayerData]="this._playerData" [ReportMasterData]="this._reportmasterdata"></app-player-projection-chart>
            </div>
        </mat-tab> -->
        <mat-tab *ngIf="this._playerData"  >
            <ng-template mat-tab-label >
                <span [matBadge]="this._playerData?.flowitem.length" matBadgeOverlap="false" style="min-width: 150px;">REPORTS</span>
              </ng-template>
         
              <app-chart-player-projection *ngIf="this.ProjectionTexts && this._playerData.flowitem.length>0" [Reports]="this._playerData.flowitem" [Ticks]="this.ProjectionTexts" [Ticks2]="this.tickValues"></app-chart-player-projection>
              
            <div  class="mat-tab-body-wrapper"    style=" display: flex; flex-wrap: wrap; align-content: flex-start; height: 60vh;"  >
                <div *ngIf="this._playerData.flowitem.length==0" style="color: gray;">
                        <br/>
                        NO REPORTS FOUND
                </div>
                
                <ng-container *ngFor="let item of this._playerData.flowitem" >
                    <app-report-card-player [Report]="item" *ngIf="item.FlowItemType=='SCREPORT'"></app-report-card-player>
                    <app-note-card-player [Note]="item" *ngIf="item.FlowItemType=='PLAYERNOTE'"></app-note-card-player>
                </ng-container>
                
            </div>
           
        </mat-tab>
        <mat-tab *ngIf="this._playerData"  [disabled]="!this._playerData?.playerplayerlist && this._playerData.playerplayerlist.length==0"> 
            <ng-template mat-tab-label>
                <span [matBadge]="this._playerData.playerplayerlist.length" matBadgeOverlap="false" style="min-width: 150px;">LISTS</span>
              </ng-template>
            <div  class="hidescrollbars"     style="overflow-y:scroll !important; height: calc(100%-120px) !important;" >
              
                <app-player-ranking-list-entry *ngFor="let list of this._playerData.playerplayerlist" [List]="list">
                </app-player-ranking-list-entry>
                <div *ngIf="this._playerData.playerplayerlist.length===0" style="margin: auto;color: gray;">
                    PLAYER IS NOT IN ANY LIST 
                </div>
            </div>
            
        </mat-tab>
        <mat-tab *ngIf="this._playerData" >
            <ng-template mat-tab-label>
                <span [matBadge]="this._agents?.length + this._contacts?.length" matBadgeOverlap="false" style="min-width: 150px;" >CONTACTS</span>
            </ng-template>
            <div  class="hidescrollbars"     style="overflow-y:scroll !important; height: calc(100%-120px) !important;" >
              
                <label>AGENTS</label> &nbsp;<button type="button" mat-button (click)="openAddAgentDialog()">ADD AGENT</button><br/>
                
                <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                   
                    <app-agent-card *ngFor="let agent of _agents" [Agent]="agent" [ConnectedPlayerId]="this._playerId" (AgentRemovedFromPlayer)="this.refreshAgents()"></app-agent-card>                
                   
                   
                </div>
                <div *ngIf="this._agents?.length===0" style="margin: auto;color: gray;height: 50px;">
                    NO AGENT INFO
                </div>
               
                <mat-divider></mat-divider>
                <label>CONTACTS</label> &nbsp; <button type="button" mat-button (click)="openAddContactDialog()">ADD CONTACT</button>

                <div *ngIf="this._contacts?.length===0" style="margin: auto;color: gray; height: 50px;">
                    NO CONTACTS
                </div>
                <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                    <app-player-contact-card *ngFor="let contact of this._contacts" [Contact]="contact" (ContactUpdated)="refreshContacts();" (ContactDeleted)="refreshContacts();"></app-player-contact-card>
                </div>
                
                <mat-divider></mat-divider>
            </div>
        </mat-tab>
       
        
      </mat-tab-group>

     
</div>

