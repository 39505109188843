import { Component, OnInit } from '@angular/core';
import { ScoutingFlowService } from '../services/scouting-flow.service';
import { ScoutingPlayerService } from '../services/scouting-player.service';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { PlayerService } from 'src/app/shared/player.service';
import { TeamService } from 'src/app/shared/team.service';
import { Season } from 'src/app/shared/team.model';
import { UntypedFormControl } from '@angular/forms';
import { KeyValue, NumberSymbol } from '@angular/common';
declare var google:any;


@Component({
  selector: 'app-scouting-statistics-dashboard',
  templateUrl: './scouting-statistics-dashboard.component.html',
  styleUrls: ['./scouting-statistics-dashboard.component.css']
})
export class ScoutingStatisticsDashboardComponent implements OnInit {

  Reportdata: any;
  BirthyearData: any;
  EventData: any;
  ProjectionData: any;
  ScoutStats: any;
  Divisions: any;
  Locations: any;
  _seasons: Season[];
  currentSeason: number;
  SeasonNoOfReports: number;
  SeasonNoOfPlayers: number;
  SeasonNoOfEvents: number;
  SeasonNoOfEvents2: number
  locationExpanded: boolean = false;
  lengthOfLocations: number = 0;

  
  seasonFormControl = new UntypedFormControl('');

  constructor(public reportService: ScoutingFlowService,   public _teamService: TeamService,) {
    reportService.getOrganizationStatsForScouting().subscribe(data=>{
      //console.log(data);
      this.Reportdata =  data;
      this.generateProjectionChartSeason();
    });

    
   }

  ngOnInit() {
    
    this._teamService.getSeasons().subscribe((s: Season[])=>{
      this._seasons = s; 
      this.seasonFormControl.setValue(this._seasons[this._seasons.length-1]);
        this.currentSeason = this._seasons[this._seasons.length-1].SeasonId;
      this.refreshData();
    }); 
  }

  refreshData(){
    let tmp: Season = this.seasonFormControl.value;
    //console.log("rehfresh season " + tmp.SeasonId);
    this.currentSeason = tmp.SeasonId;
    console.log(this.currentSeason);
    this.reportService.getOrganizationBirthyearStatsForScouting(this.currentSeason).subscribe(data=>{
      this.BirthyearData = data;
      //console.log(data);
      this.SeasonNoOfPlayers= data.rows.map(r => r.c[1].v).reduce((sum,current) => sum + current,0);
      
      this.generateReportBirthdayChart();

    });
    this.reportService.getOrganizationEventStatsForScouting(this.currentSeason).subscribe(data=>{
      this.EventData = data;
      this.SeasonNoOfEvents= data.rows.map(r => r.c[1].v).reduce((sum,current) => sum + current,0);
      this.SeasonNoOfEvents2 = data.rows.map(r => r.c[2].v).reduce((sum1,current1) => sum1 + current1,0);
      this.generateReportEventChart();

    });
    this.reportService.getOrganizationProjectionStatsForScouting(this.currentSeason).subscribe(data=>{
      this.ProjectionData = data;
      //this.SeasonNoOfReports= data.rows.map(r => r.c[1].v).reduce((sum,current) => sum + current,0);
      this.generateReportProjectionChart();

    });

    this.reportService.getOrganizationReportingForScoutsBySeason(this.currentSeason).subscribe(data=>{
      this.ScoutStats = data;
      this.SeasonNoOfReports= data.rows.map(r => r.c[1].v).reduce((sum,current) => sum + current,0);
      this.generateScoutReportingSeason();

    });

    this.reportService.GetReportingGameDivisionCountBySeason(this.currentSeason).subscribe(data=>{
      this.Divisions = data.NoOfReportsByGameDivision;          
      
    });

    this.reportService.GetScoutReportingLocationCount(this.currentSeason).subscribe(data=>{
      this.Locations = data.NoOfReportsByLocation;          
      this.lengthOfLocations =  Object.keys(this.Locations).length;
      console.log(this.lengthOfLocations);
      console.log(this.Locations);
    });

  }

  generateReportProjectionChart(): void{
    //google.charts.load('current', {'packages':['bar']});
    google.charts.load('current', {packages: ['corechart', 'bar']});
       google.charts.setOnLoadCallback(drawChart.bind(this));        


       function drawChart() {

       
        var data = new google.visualization.DataTable(this.ProjectionData);


       var options = {
          title: "Reports per projection" ,    
          titleTextStyle: {color: 'white'},
          //legend: { position: 'none' },
          backgroundColor: '#303030',
          //colors: [ '#4CAF50' ],
          hAxis: { textStyle: { color: 'white', slantedText: 'true'} , baselineColor: 'white', gridlines:{ color: '#303030'},   },
          vAxis: { textStyle: { color: 'white'} ,
                   baselineColor: 'white',
                  
                   //minValue: 1,
                   //viewWindow: {max:  100},
                  
                   
                    },
          legend: {textStyle: {color: 'white'}},
          isStacked: true,
          chartArea: {backgroundColor: '#303030'},
         
          
          

        }; 

      

/*         var chart = new google.charts.Bar(document.getElementById('scouting_projections_chart'));
        chart.draw(data, google.charts.Bar.convertOptions(options)); */

        var chart = new google.visualization.ColumnChart(document.getElementById('scouting_projections_chart'))
        chart.draw(data, options);
        
  }
}

  generateReportEventChart(): void{
    google.charts.load('current', {'packages':['bar']});
       google.charts.setOnLoadCallback(drawChart.bind(this));        


       function drawChart() {

       
        var data = new google.visualization.DataTable(this.EventData);

     
      
      

       var options = {
          title: "Scheduled scouting events per month" ,    
          titleTextStyle: {color: 'white'},
          //legend: { position: 'none' },
          backgroundColor: '#303030',
          //colors: [ '#4CAF50' ],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines:{ color: '#303030'}, },
          vAxis: { textStyle: { color: 'white'} ,
                   baselineColor: 'white',
                   
                   //minValue: 1,
                   //viewWindow: {max:  100},
                  
                   
                    },
          legend: {textStyle: {color: 'white'}},
          isStacked: true,
          chartArea: {backgroundColor: '#303030'},
         
          
          

        }; 

      

        var chart = new google.charts.Bar(document.getElementById('scouting_events_chart'));

        chart.draw(data, google.charts.Bar.convertOptions(options));
  }
}

  generateReportBirthdayChart(): void{
    google.charts.load('current', {'packages':['bar']});
       google.charts.setOnLoadCallback(drawChart.bind(this));        


       function drawChart() {

       
        var data = new google.visualization.DataTable(this.BirthyearData);

     
      
      

       var options = {
          title: "No of players scouted per birthyear" ,    
          titleTextStyle: {color: 'white'},
          //legend: { position: 'none' },
          backgroundColor: '#303030',
          //colors: [ '#4CAF50' ],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines:{ color: '#303030'}, format: '####'},
          vAxis: { textStyle: { color: 'white'} ,
                   baselineColor: 'white',
                   
                   //minValue: 1,
                   //viewWindow: {max:  100},
                  
                   
                    },
          legend: {textStyle: {color: 'white'}},
          isStacked: false,
          chartArea: {backgroundColor: '#303030'},
         
          
          

        }; 

      

        var chart = new google.charts.Bar(document.getElementById('scouting_reportsbybirthyear_chart'));

        chart.draw(data, google.charts.Bar.convertOptions(options));
  }
}

  generateProjectionChartSeason(): void{

       google.charts.load('current', {'packages':['bar']});
       google.charts.setOnLoadCallback(drawChart.bind(this));        


       function drawChart() {

       
        var data = new google.visualization.DataTable(this.Reportdata);

       var options = {
          title: "Reports per month - last 5 years" ,    
          titleTextStyle: {color: 'white'},
          //legend: { position: 'none' },
          backgroundColor: '#303030',
          //colors: [ '#4CAF50' ],
          hAxis: { textStyle: { color: 'white'} , maxValue: 12, baselineColor: 'white', gridlines:{ color: '#303030'}, ticks: [1,2,3,4,5,6,7,8,9,10,11,12],  },
          vAxis: { textStyle: { color: 'white'} ,
                   baselineColor: 'white',
                   minValue: 1,
                   //viewWindow: {max:  100},
                  
                   
                    },
          colors: ['#4285F4', '#4285F4', '#4285F4', '#4285F4', '#4285F4', 'orange', '#4285F4', '#4285F4'],
          legend: {textStyle: {color: 'white'}},
          isStacked: false,
          chartArea: {backgroundColor: '#303030'},
         
          
          

        }; 

      

        var chart = new google.charts.Bar(document.getElementById('scouting_reports_chart'));

        chart.draw(data, google.charts.Bar.convertOptions(options));
 /* 
        var chart = new google.visualization.ColumnChart(document.getElementById('scouting_reports_chart'));
        chart.draw(data, options); */

        

      }

  }

  generateScoutReportingSeason(): void{

   //google.charts.load('current', {'packages':['bar']});
   google.charts.load('current', {packages: ['corechart', 'bar']});
   google.charts.setOnLoadCallback(drawChart.bind(this));        


   function drawChart() {

   
    var data = new google.visualization.DataTable(this.ScoutStats);


   var options = {
      title: "Reporting per scout" ,    
      titleTextStyle: {color: 'white'},
      //legend: { position: 'none' },
      backgroundColor: '#303030',
      //colors: [ '#4CAF50' ],
      hAxis: { textStyle: { color: 'white', slantedText: 'true'} , baselineColor: 'white', gridlines:{ color: '#303030'},   },
      vAxis: { textStyle: { color: 'white'} ,
               baselineColor: 'white',
              
               //minValue: 1,
               //viewWindow: {max:  100},
              
               
                },
      legend: {textStyle: {color: 'white'}},
      colors: ['#4285F4', 'white'],
      isStacked: true,
      chartArea: {backgroundColor: '#303030'},
     
      
      

    }; 

  

/*         var chart = new google.charts.Bar(document.getElementById('scouting_projections_chart'));
    chart.draw(data, google.charts.Bar.convertOptions(options)); */

    var chart = new google.visualization.ColumnChart(document.getElementById('scouting_scoutreporting_chart'))
    chart.draw(data, options);
  }

}

//keyvalue sort orders
// Preserve original property order
originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
  return 0;
}

// Order by ascending property value
valueAscOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
  return a.value.localeCompare(b.value);
}

// Order by descending property key
keyDescOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
  return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
}

}
