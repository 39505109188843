<h2 mat-dialog-title>SELECT DIVISION</h2>
<form #selectForm [formGroup]="_form" (ngSubmit)="submit()" >
<mat-dialog-content style="font-family: Roboto;background-color: #303030;">

   
        <!-- our form array of checkboxes will go here -->
        <ng-container formArrayName="divisions">
            <div *ngFor="let order of _form.controls['divisions']['controls']; let i = index">
                  <mat-checkbox 
              [formControlName]="i" [checked]="divisions[i].DivisionFiltered" >{{divisions[i].Name}}</mat-checkbox>
            </div>
          
     
        
        </ng-container>
           
        
     <!--  <button TYPE="submit" >SAVE</button> -->
    

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="save()" >SAVE</button>
</mat-dialog-actions>
</form>