<h2 mat-dialog-title>{{limit.Name}} - settings</h2>
<mat-dialog-content>
  <form  (ngSubmit)="save()">
      
      <mat-form-field>
      <mat-label>Select multiplier value</mat-label>
      <mat-select  [(ngModel)]="limit.LimitMultiplierTestId" name="LimitMultiplierTestId" [value]="limit.LimitMultiplierTestId"   #LimitMultiplierTestId>
          <mat-option   value="null">No multiplier</mat-option>
          <mat-option  *ngFor="let test of testlist" [value]="test.TestId">
            {{test.Name}}
          </mat-option>

        
      </mat-select> 
      
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <!-- <mat-hint>Hint</mat-hint> -->
    </mat-form-field>
    <br/>
    <button mat-button color="primary" type="submit" >SAVE</button>
    <button type="button" mat-button  (click)="onCancel()">CANCEL</button>
    </form>

</mat-dialog-content>
