import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/user.service';
import { Globals } from '../globals';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router : Router,private userService : UserService, private _globals: Globals){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean {
      if (localStorage.getItem('userToken') != null)
      {
        
        let roles = next.data["roles"] as Array<string>;
        //console.log(roles);
        if (roles) {
          for(let role of roles){
            var match = this.userService.roleMatch(roles);
            if (match){
              this._globals.isLoggedIn=true;              
              return true;
            }
            else {
              
              this.router.navigate(['/login']);
              return false;
            }
          }
          return false;
          
        }
        else{
          this._globals.isLoggedIn=true;
          return true;    
        }
          
      }
      //this.router.navigate(['/']);
      return false;
  }
}
