import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormService } from 'src/app/shared/form.service';
import { FormViewModel } from 'src/app/shared/models/FormModels';


@Component({
  selector: 'app-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.css']
})
export class FormEditComponent implements OnInit {
  formId: string;
  form: FormViewModel;
  formGroup: UntypedFormGroup;
  loading: boolean;
  errorMessage = "";


  constructor(private formService: FormService, private activatedRoute: ActivatedRoute,private fb: UntypedFormBuilder) { 

  }

  ngOnInit(): void {
    this.loading = true;
    this.activatedRoute.params.subscribe(params => {
      this.formId = params['FormId'];
      this.getForm();


    });

  }

  getForm(){
    this.formService.getForm_LatestVersion(this.formId).subscribe(form=>{
      
      this.form = form as FormViewModel;
      this.buildForm();
    }); 

  }

  buildForm(){
    //console.log("buildform");
    this.formGroup = this.fb.group({
      Name:  this.form.Name,
      FormId: this.form.FormId,
      Version: this.form.FormVersion.Version,
      Sections:this.fb.array([]),
      FormVersion: this.fb.group({
        Version: this.form.FormVersion.Version,
        Sections: this.fb.array([])
      })
    });
    console.log("buildform2");
    this.form.FormVersion.Sections.forEach(section => {
      const sectionForm = this.fb.group({
      /*   Name: section.Name, */
        Text: [section.Text, Validators.required],
        Collapsible: section.Collapsible,
        Questions: this.fb.array([])
      });

      section.Questions.forEach(question=>{
        //console.log('----Question.Text:' + question.Text);
        //console.log('----Question.key:' + question.Key);
        //console.log('----Question.Required:' + question.Required);
        //console.log('----Question.ControlType:' + question.ControlType);
        const questionForm = this.fb.group({          
          Text: question.Text,
          Key: [question.Key, Validators.required],
          Required: question.Required,
          ControlType: question.ControlType
        });
        (sectionForm.controls["Questions"] as UntypedFormArray).push(questionForm);

      });
      //console.log("buildform3");
      this.Sections.push(sectionForm);

    });
    console.log(this.formGroup);
    //console.log( (this.formGroup.controls["FormVersion"] as FormGroup).controls["Sections"] as FormArray);
    this.loading = false;
  }

  get Sections() {
    return (this.formGroup.controls["FormVersion"].get("Sections")  as UntypedFormArray);
  }

  addSection(){
    const sectionForm = this.fb.group({
      //Name: 'New',
      Text: 'New section',
      Collapsible: 'false',
      Questions: this.fb.array([])
    });
    const newQuestion =  this.fb.group({          
      Text: 'TEXT',
      Key: 'NEW KEY',
      Required: 'false',
      ControlType: 'ShortText'

    });
    (sectionForm.get("Questions") as UntypedFormArray).push(newQuestion);

    this.Sections.push(sectionForm);
  }

  saveAsNewVersion(){
    //Check that all keys are unique
    var keys: string[] = [];
    this.Sections.controls.forEach(section => {
      (section.get("Questions") as UntypedFormArray).controls.forEach(question=>{
        console.log(question.get("Key").value);
        console.log(keys.findIndex(q=>q === question.get("Key").value));
        if(keys.findIndex(q=>q === question.get("Key").value)!=-1){
          console.log("Errorrororroro");
          this.errorMessage = "The keys for the questions must be unique within the form. Key '" + question.get("Key").value + "' has been used multiple times.";
          return false;
        }else{
          this.errorMessage = "";
        }             
        keys.push(question.get("Key").value);
      })
    });

    this.formService.createNewFormVersion(this.formGroup.value).subscribe(response=>{
        console.log(response);
    });

  }
}
