import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { PlayerListService } from '../../services/player-list.service';
import { PlayerListDetailsViewModel } from '../../models/PlayerListModels';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { SelectSinglePlayerDialogComponent } from 'src/app/shared/components/select-single-player-dialog/select-single-player-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { CommonModule } from '@angular/common';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { PlayerTotalViewComponent } from '../../player/player-total-view/player-total-view.component';

@Component({
  selector: 'app-ranking-list-details',
  standalone: true,
  imports: [CommonModule, SharedModuleModule, PlayerTotalViewComponent, RouterModule],
  templateUrl: './ranking-list-details.component.html',
  styleUrls: ['./ranking-list-details.component.css']
})
export class RankingListDetailsComponent implements OnInit {

 _listId: string;
 _list: PlayerListDetailsViewModel;
 selectedPlayer:string;

constructor(public route: ActivatedRoute,
   public _playerlistService: PlayerListService,
   public router: Router,
   public dialog: MatDialog) {
  let _listId = this.route.snapshot.paramMap.get('ListId');
  //console.log(_gameId);
  this.getListDetails(_listId);

  
  }
  


  ngOnInit() {
  }

  navigate(player: string){
    this.router.navigateByUrl("scouting/playerdetails/" + player)  ;
  }


  getListDetails(ListId:string){
    this._playerlistService.getListDetails(ListId).subscribe(res=>{
      this._list = res;
    });
  }

  
  drop(event: CdkDragDrop<string[]>) {
    this._playerlistService.MovePlayerInList(this._list.ListId, this._list.players[event.previousIndex].Player.LifelineScoutingId, event.previousIndex, event.currentIndex).subscribe(res=>{
      //console.log("Test");
      if(res){
        moveItemInArray(this._list.players, event.previousIndex, event.currentIndex);
      }
    });
        
  }

  addPlayer(){
    const confirmDialog = this.dialog.open(SelectSinglePlayerDialogComponent, {
      width: '450px',
      data: {
        
        Action: 'ADD'
        
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
    
        
        this._playerlistService.addPlayerInList(this._list.ListId, result.SelectedPlayer).subscribe( res =>{
          if(res)
            this.getListDetails(this._list.ListId);
        });
     
    });
  }

  confirmDelete(player){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to remove the player?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        //console.log(TestResultId);
        this._playerlistService.deletePlayerInList(this._list.ListId,player).subscribe(result => {
          this.getListDetails(this._list.ListId);
        });
        //Notify about delete
        
      }
    });
    //console.log(player);
  }

}
