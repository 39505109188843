
<mat-card appearance="outlined" style="max-width: 340px; min-width: 300px;   margin: 10px; border: gray 1px solid; border-left: white 3px solid;" class="mat-elevation-z8">
        <table>
            <tr>
                <td><img style="height: 48px;"  [src]="'../../assets/img/' + this.Note.PlayerCurrentTeamIcon"     ></td>
                <td>
                        <span style="font-size: large;">{{ this.Note.PlayerLastName}}, {{ this.Note.PlayerFirstName}}</span><br>
                        <span style="font-size: medium; color: gray; font-size: 12px; ">
                            {{this.Note.ReportDivision + "/" + this.Note.ReportTeamName}}<br/>
                            {{ this.Note.DateOfBirth | date:'yyyy-MM-dd'}} ({{this.Note.PlayerAgeYear + ' Years ' + this.Note.PlayerAgeMonth + ' Months'}})
                            
                        </span>
                        
                </td>
            </tr>
        </table>
         
    <mat-card-header>
        <!-- <img mat-card-avatar  [src]="'../../assets/img/' + this.Report.PlayerCurrentTeamIcon"     > -->
       
        <mat-card-title>
          
        </mat-card-title>
      
    
    
    </mat-card-header>
    <mat-card-content style="margin-bottom: 0px;" >
        <mat-divider></mat-divider>
            
            
            <div style="font-size: 14px; margin-top: 5px;min-height: 50px;">
                {{this.Note.Text}}
            </div>
        <mat-divider></mat-divider>
       
    
    
        <div style="font-family: verdana,arial,sans-serif; font-size: x-small;  text-align: left; vertical-align: top;  padding: 0; white-space: normal; margin: 0; color: white;  ">    
            <table>
                <tr>
                    <td>
                            <mat-icon style="color: orange;font-size: 18px;">person</mat-icon>
                    </td>
                    <td>
                            <div style="font-size: 10px;">{{this.Note.CreatedByLastName}}, {{this.Note.CreatedByFirstName}}</div>
                            <span style="color: gray;font-size: 10px; color: gray;">{{this.Note.DateCreated | date:'yyyy-MM-dd HH:mm' }}</span>
                    </td>
                </tr>
                <tr *ngIf="this.Note.GameText">
                    <td>  
                      
                            <mat-icon style="color:orange;font-size: 18px;">sports_hockey</mat-icon>
                           
                      
                    </td>
                    <td>  
                        <div style="font-size: 10px;">
                            {{this.Note.GameText}}     <br>
                            <span>{{this.Note.ReportDivision}}</span>  
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td>
                        
                        <mat-icon style="color: orange; font-size: 18px;"> location_on</mat-icon>
                    </td>
                    <td>
                        <div style="font-size: 10px;">
                            {{this.Note.Location}}
                        </div>
                    </td>
                </tr>
                <tr *ngIf="this.Note.Source">
                        <td>
                            
                            <mat-icon style="color: orange; font-size: 18px;"> hearing</mat-icon>
                        </td>
                        <td>
                            <div style="font-size: 10px;">
                                {{this.Note.Source}}
                            </div>
                        </td>
                    </tr>
            </table>    
          
           
            
        </div>
    
    
        <mat-divider></mat-divider>
    
    
    
    </mat-card-content>
    <mat-card-actions>
       <!--  <button mat-button  (click)="openViewReport(this.Report.ReportId)" >
            <mat-icon style="font-size: 18px; opacity: 0.6;">
                    rate_review
            </mat-icon>
            Report
        </button> -->
    <button mat-button style="opacity: 0.6;" (click)="gotoPlayerDetails(this.Note.PlayerId)" ><img style="height: 24px;"   src="../../assets/img/icons8-user-shield-48.png">Player</button>
    <button mat-icon-button *ngIf="this.Note.IsCreatedByCurrentUser" [routerLink]="['/scouting/editnote', this.Note.PlayerNoteId]" >
        <mat-icon style="font-size: 18px; opacity: 0.6;">
                edit
        </mat-icon>   
      
    </button>
    <!--<button mat-button style="opacity: 0.6;" (click)="gotoPlayerDashboard()" ><img style="height: 24px;"   src="../../assets/img/icons8-user-shield-48.png">Player</button>
    <button mat-button style="opacity: 0.6;" >
            <mat-icon style="font-size: 18px; opacity: 0.6;">sports_hockey</mat-icon>
        Game
    </button> -->
    </mat-card-actions>
   <!--  <mat-card-actions>
           
        <button mat-button style="opacity: 0.6;"  ><img style="height: 24px;"   src="../../assets/img/icons8-user-shield-48.png">Player</button>
        <button mat-button style="opacity: 0.6;" >
                <mat-icon style="font-size: 18px; opacity: 0.6;">sports_hockey</mat-icon>
            Game
        </button>
    </mat-card-actions> -->
    </mat-card> 
