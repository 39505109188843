import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-injury-dialog',
  templateUrl: './delete-injury-dialog.component.html',
  styleUrls: ['./delete-injury-dialog.component.css']
})
export class DeleteInjuryDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteInjuryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

    
  }

}
