
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <div style="align-content: center; text-align: center; margin-right: 20px;">
            <img src="../../assets/img/icons8-user-shield-48.png" style="height: 48px;"/><br/>
            <span style="font-size: large; font-family: Roboto;">{{this.Player?.Player.FirstName }} {{Player?.Player.LastName}}</span><br/>
            <span style="font-size: small; font-family: Roboto;">
                {{this.Player?.Player.DateOfBirth | date: 'yyy-MM-dd'}}
            </span>
        </div>
        <div style="font-family: Roboto;font-size: x-small;">
            <span style="font-family: Roboto; font-size: small;"  >General</span>
            <table>
                <tr>
                    <td>Age:</td>
                    <td>
                        {{this.Player?.PlayerAgeYears }} years {{this.Player?.PlayerAgeMonths }} months
                    </td>
                </tr>
                <tr>
                    <td>Position:</td>
                    <td *ngIf="this.Player?.Player.PositionId==1">
                        Goaltender
                    </td>
                    <td *ngIf="this.Player?.Player.PositionId==2">
                        Defender
                    </td>
                    <td *ngIf="this.Player?.Player.PositionId==3">
                        Forward
                    </td>
                    <td *ngIf="this.Player?.Player.PositionId==4">
                        Center
                    </td>
                    <td *ngIf="this.Player?.Player.PositionId==5">
                        Forward
                    </td>
                </tr>
                <tr>
                    <td>Height:</td>
                    <td>{{this.Player?.Player.Height }} cm</td>
                </tr>
                <tr>
                    <td>Weight:</td>
                    <td>{{this.Player?.Player.Weight }} kg</td>
                </tr>
                <tr>
                    <td>Shoots:</td>                    
                    <td *ngIf="this.Player?.Player.ShootId==1">L</td>
                    <td *ngIf="this.Player?.Player.ShootId==2">R</td>
                </tr>

            </table>
       
        </div>
       <!--  <div>
            <span style="font-family: Roboto; font-size: small;">Teams</span>
            <div *ngFor="let team of this._playerData?.playerteams" style="font-family: Roboto;">
                <img [src]="'../assets/img' + team.SmallIconFileName" style="height: 20px;"/>
                <b>{{ team.JerseyNo }}</b>&nbsp;{{team.Division}} - {{team.Name}}
            </div>
        </div> -->
    </div>
