import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/baseservice';
import { Observable } from 'rxjs';
import { GamePageViewModel, GameDetailsViewModel, DivisionGameFilterFlatModel } from '../models/GameModels';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonGameTickerResult } from 'src/app/shared/models/ExternalSourceModels';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';
import { Season } from 'src/app/shared/team.model';
import { AgentAuthority, AgentViewModel } from '../models/AgentModels';

@Injectable({
  providedIn: 'root'
})
export class AgentService extends BaseService {

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }

   getAgents(): Observable<AgentViewModel[]>{

    let ret = this.http.get<AgentViewModel[]>(this.rootUrl + '/api/agents' );
    return ret;
   }

   getAgentAuthorites(): Observable<AgentAuthority[]>{

    let ret = this.http.get<AgentAuthority[]>(this.rootUrl + '/api/agentauthorities' );
    return ret;
   }

   getAgentsForPlayer(PlayerId: string): Observable<AgentViewModel[]>{

    let ret = this.http.get<AgentViewModel[]>(this.rootUrl + '/api/agents/byplayer/' + PlayerId );
    return ret;
   }

   getAgentsByAuhority(AuthorityId: number): Observable<AgentViewModel[]>{

    let ret = this.http.get<AgentViewModel[]>(this.rootUrl + '/api/agents/byauthority/' + AuthorityId );
    return ret;
   }

   
   addAgentToPlayer(AgentId: string, PlayerId: string){

     return this.http.post<boolean>(this.rootUrl + '/api/players/' + PlayerId + '/agents/' + AgentId, null);     
   }

   removeAgentFromPlayer(AgentId: string, PlayerId: string){

    return this.http.delete<boolean>(this.rootUrl + '/api/players/' + PlayerId + '/agents/' + AgentId);     
  }
}
