<h2>{{this.Title}}</h2>
<div class="app-page" style="margin-left: 20px; margin-top: 20px;">

    
    <mat-form-field>
      <mat-label>View name</mat-label>
      <input matInput placeholder="Profile name" [(ngModel)]="this.view.Name">        
    </mat-form-field>
    <br/>
    <mat-form-field>
      <mat-label>Display name</mat-label>
      <input matInput placeholder="Display name" [(ngModel)]="this.view.DisplayName">        
    </mat-form-field>
    <br/>
    <span style="font-family:Roboto; color:gray;">
     Select positions that the view used for
    </span>
    <span class="example-list-section">
      <ul>
        <li *ngFor="let pos of this.positions">
          <mat-checkbox [(ngModel)]="pos.Checked"                       
                        >
            {{pos.Text}}
          </mat-checkbox>
        </li>
      </ul>
    </span>

<div style="display: flex; flex-direction: row; min-width: 500px;justify-content: start; margin-right: 30px;">
 
 
  <div style="margin-right: 30px;">
    <h3>Selected groups</h3><br/>
    <div
      cdkDropList
      #selectedList="cdkDropList"
      [cdkDropListData]="view.Groups"
      [cdkDropListConnectedTo]="[notselectedList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of view.Groups" cdkDrag>{{item.Name}}</div>
    </div>
  </div>
  
    
  <div >
    <h3>Available groups</h3>
  
    <div
      cdkDropList
      #notselectedList="cdkDropList"
      [cdkDropListData]="view.GroupsNotSelected"
      [cdkDropListConnectedTo]="[selectedList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of view.GroupsNotSelected" cdkDrag>{{item.Name}}</div>
    </div>

  
  </div>
  
</div>
<span style="font-family:Roboto; color:gray;">
  Drag groups from "Available groups" to "Selected groups"
</span>
<br/>
<br/>
<br/>

<button mat-raised-button (click)="save()" color="primary">SAVE</button>
<button mat-button (click)="goback()" >CANCEL</button>