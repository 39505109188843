<div class="app-page">
    <mat-toolbar style="background-color: #303030;">
        <button mat-icon-button (click)="previousWeek()" color="primary"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button mat-icon-button (click)="nextWeek()" color="primary"><mat-icon>keyboard_arrow_right</mat-icon></button>
       <!--  <div>
            
            <a (click)="openAddDialog()" mat-button color="primary"> <mat-icon>add</mat-icon>EVENT</a>
            
        </div> -->
       <!--  <mat-form-field  appearance="fill"  style="width: 400px;font-size: small;" >
            <mat-label>Select schedule</mat-label>
            <mat-select (selectionChange)="reloadSchedule($event)" [(value)]="this.TeamId" >
                <mat-option *ngFor="let team of this.Teams" [value]="team.TeamId">{{team.Name}} - {{team.Level}}</mat-option>
            </mat-select>
        
        </mat-form-field>
        &nbsp; -->
        <mat-form-field  appearance="fill"  style="width: 400px;font-size: small;" >
            <mat-label>Players</mat-label>
            <mat-select (selectionChange)="reloadSchedulePlayer($event)" [(value)]="this.PlayerId" >
                <mat-option value="">Team level</mat-option>
                <mat-option *ngFor="let player of this.Players" [value]="player.PlayerId">{{player.LastName}}, {{player.FirstName}}</mat-option>
            </mat-select>        
        </mat-form-field>
        &nbsp;
        <button mat-raised-button (click)="reload()" style="min-width: 120px;">REFRESH</button> &nbsp;
        <button mat-raised-button [routerLink]="['/team/schedule', this.TeamId]" STYLE="min-width: 120px;" >MONTH VIEW</button>
        <div style="width:100%; text-align: center;">
            
        </div>
       
        
    </mat-toolbar>
    <mat-progress-bar *ngIf="this.isLoading" mode="query"></mat-progress-bar>
    <div style="display: flex; flex-direction: column; width: 100%;" *ngIf="!isLoading">
 
        <div *ngFor="let day of this.schedule.Days" class="dayframe">
            <div class="dayheader rightdivider">
                
                <div style="font-size: large;">{{day.CalendarDate | date:'d' }}</div>
                <div style="font-size: small;">{{day.CalendarDate | date:'MMM' }}</div>
                <div style="font-size: small;">{{day.CalendarDate | date:'E' }}</div>
                <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>add</mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openAssignWorkoutDialog(day.CalendarDate)"> Workout</button>
                        <button mat-menu-item (click)="openAddEventDialog(day.CalendarDate)">Event</button>
                    </mat-menu>
            </div>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                <div class="teamevent" *ngFor="let evt of day.TeamEvents" style="font-size: small;">
                    <div style="display:flex; flex-direction: row; justify-content: space-between;">
                    
                        <div>
                            <div>
                                <a (click)="openForEditDialog(evt.EventId)">
                                    <mat-icon style="font-size:medium;">
                                        event
                                    </mat-icon>
                                    <b>{{evt.EventDate | date:'HH:mm'}} {{evt.Title}}</b>
                                </a>
                            </div>
                            
                            <div class="TeamEventLocation">
                                <mat-icon style="font-size:medium; height: 16px; width: 16px;">
                                    location_on
                                </mat-icon>
                                {{evt.Location}}
                            </div>
                        
                        </div>
                        <div style="display: flex; flex-direction: row;">
                             <a mat-icon-button style="font-size: small;" (click)="openForEditDialog(evt.EventId)">
                                <mat-icon>edit</mat-icon>
                            </a>
                           <!--  <a mat-icon-button style="font-size: small;" (click)="openTeamEventDetails(evt.EventId)" >
                                <mat-icon>pageview
                                </mat-icon>                              
                            </a> -->
                        </div>
                       
                    </div>
                    <div class="TeamEventNote">
                        <mat-icon style="font-size:medium; height: 16px; width: 16px;">
                            notes
                        </mat-icon>
                        {{evt.Note}}
                    </div>
                    <div style="display: flex; flex-direction: row;margin-left: 25px;">
                        <div style="font-size:small;">
                            <mat-icon style="font-size:small; width: 12px;height: 16px;" *ngIf="evt.FullTeamEvent">
                                shield
                            </mat-icon>
                            <ng-container *ngIf="evt.FullTeamEvent">
                                {{evt.Team.LevelName}}
                            </ng-container>
                            
                            <mat-icon style="font-size:small; width: 12px;height: 16px;">
                                schedule
                            </mat-icon>
                            {{evt.Duration}} min
                            <mat-icon style="font-size:small; width: 12px;height: 16px;">
                            bolt
                            </mat-icon>
                            {{evt.LoadValue}} ({{evt.LoadLevel}})
                        </div>
                        &nbsp;&nbsp;
                        
                    </div>
                    <div  *ngIf="evt.FullTeamEvent"   style="color: gray;font-size:small; margin-left: 25px;">
                        
                    </div>
                    <div  *ngIf="!evt.FullTeamEvent"  > 
                        <div style="color: white;font-size:x-small;display: flex; flex-direction: row;margin-left: 25px; flex-wrap: wrap;">
                            
                            <div *ngFor="let player of evt.Attendees" style="display: flex; flex-direction: row; ">
                                <mat-icon style="font-size:small; width: 16px;" >
                                    person
                                </mat-icon>                                
                                {{player.LastName}}, {{player.FirstName}}&nbsp;
                            </div>
                            
                        </div>
                    </div>
                    <div *ngIf="evt.LogSummary">
                        
                        <div style="color: white;font-size:x-small;display: flex; flex-direction: row;margin-left: 25px; flex-wrap: wrap;">
                            <span style="width: 40px;">Feeling:</span>
                            <div class="smallredcircle" *ngFor="let _ of CreateTempArray(evt.LogSummary.Feeling_NoOfVeryBad)">
                                    1
                            </div>
                            <div class="smallredcircle" *ngFor="let _ of CreateTempArray(Feeling_NoOfBad)">
                                2
                            </div>
                            <div class="smallyellowcircle" *ngFor="let _ of CreateTempArray(evt.LogSummary.Feeling_NoOfOK)">
                                3
                            </div>
                            <div class="smallgreencircle" *ngFor="let _ of CreateTempArray(evt.LogSummary.Feeling_NoOfGood)">
                                4
                            </div>
                            <div class="smallgreenyellowcircle" *ngFor="let _ of CreateTempArray(evt.LogSummary.Feeling_NoOfVeryGood)">
                                5
                            </div>
                        </div>  
                        <div style="color: white;font-size:x-small;display: flex; flex-direction: row;margin-left: 25px; flex-wrap: wrap;">
                            <span style="width: 40px;">RPE:</span>
                            <div class="circle_intensity_1" *ngFor="let _ of CreateTempArray(evt.LogSummary.Intensity_NoOf_1)">
                                1
                            </div>
                            <div class="circle_intensity_2" *ngFor="let _ of CreateTempArray(evt.LogSummary.Intensity_NoOf_2)">
                                2
                            </div>
                            <div class="circle_intensity_3" *ngFor="let _ of CreateTempArray(evt.LogSummary.Intensity_NoOf_3)">
                                3
                            </div>
                            <div class="circle_intensity_4" *ngFor="let _ of CreateTempArray(evt.LogSummary.Intensity_NoOf_4)">
                                4
                            </div>
                            <div class="circle_intensity_5" *ngFor="let _ of CreateTempArray(evt.LogSummary.Intensity_NoOf_5)">
                                5
                            </div>
                            <div class="circle_intensity_6" *ngFor="let _ of CreateTempArray(evt.LogSummary.Intensity_NoOf_6)">
                                6
                            </div>
                            <div class="circle_intensity_7" *ngFor="let _ of CreateTempArray(evt.LogSummary.Intensity_NoOf_7)">
                                7
                            </div>
                            <div class="circle_intensity_8" *ngFor="let _ of CreateTempArray(evt.LogSummary.Intensity_NoOf_8)">
                                8
                            </div>
                            <div class="circle_intensity_9" *ngFor="let _ of CreateTempArray(evt.LogSummary.Intensity_NoOf_9)">
                                9
                            </div>
                            <div class="circle_intensity_10" *ngFor="let _ of CreateTempArray(evt.LogSummary.Intensity_NoOf_10)">
                                10
                            </div>
                        </div>  
                    </div>
                </div>




                <div class="gameevent"  *ngFor="let evt of day.GameEvents">
                  
                    <div style="float:right;"><!-- <a href="#" class="removefromschedule"
                            [attr.eventid]="evt.EventId" eventtype="GAME" [attr.divisionname]="evt.DivisionName"
                            [attr.gamename]="evt.HomeTeamName"
                            [attr.eventdate]="day"><img src="/img/blackcross.png" style="height: 16px;"></a> -->
                    </div>
                    
                    <div >
                        <span class="gameeventtitle" *ngIf="evt.IsHomeGame">
                            <mat-icon style="font-size:large; height: 16px; width: 16px;">
                                sports_hockey
                            </mat-icon>
                            {{formatTimeSpan(evt.StartTime) }}
                            {{evt.DivisionName}} &nbsp; {{evt.AwayTeamName}}
                            
                        </span>
                        <span  class="gameeventtitle" *ngIf="!evt.IsHomeGame">
                            <mat-icon style="font-size:medium; height: 16px; width: 16px;">
                                sports_hockey
                            </mat-icon>
                            {{formatTimeSpan(evt.StartTime) }} {{evt.DivisionName}} {{evt.HomeTeamName}}
                        </span>
                    </div>
                    <div class="gameeventlocation" *ngIf="evt.Venue">
                        <mat-icon style="font-size:medium; height: 16px; width: 16px;">
                            location_on
                        </mat-icon>
                        {{ evt.Venue}}
                    </div>

                </div>      
                
                

                <div class="trainingevent"  *ngFor="let evt of day.AssignedWorkouts">
                    <div style="font-weight: bold; font-size: small;">
                        <a (click)="openDisplayWorkoutDialog(evt)">
                        <mat-icon style="font-size:large; height: 16px; width: 16px;">
                            view_list
                        </mat-icon>
                        {{evt.Workout.Name}}
                        </a>
                    </div>
                     <!-- <div  style="display: flex;align-items: end; font-size: x-small; margin-left: 18px;">
                       <mat-icon style="font-size:medium; height: 16px; width: 16px;">
                            table_chart
                        </mat-icon> 
                        
                        -
                    </div>-->
                    <div class="TrainingEventLevel" >                        
                        <mat-icon style="font-size: small; color: darkgray; width: 16px;height: 16px;" *ngIf="evt.PlannedFor">shield</mat-icon>
                        <label *ngIf="evt.PlannedFor">{{ evt.PlannedFor }}</label>
                        <mat-icon style="font-size:small; width: 12px;height: 16px;">
                            schedule
                        </mat-icon>&nbsp;                 
                        <label>{{evt.Workout.EstimatedDuration}} min</label>
                        &nbsp;
                        <mat-icon style="font-size:small; width: 12px;height: 16px;">
                            flash_on
                        </mat-icon>&nbsp;                 
                        <label>{{evt.Workout.EstimatedDuration * evt.Workout.Intensity}} ({{evt.Workout.Intensity}})</label>
                    </div>
                    <div  *ngIf="evt.AssignedPlayers"  > 
                        <div style="color: white;font-size:x-small;display: flex; flex-direction: row;margin-left: 25px; flex-wrap: wrap;">
                            
                            <div *ngFor="let player of evt.AssignedPlayers" style="display: flex; flex-direction: row; ">
                                <mat-icon style="font-size:small; width: 16px;height: 16px;">
                                    person
                                </mat-icon>                                
                                {{player.LastName}}, {{player.FirstName}}&nbsp;
                            </div>
                            
                        </div>
                    </div>
                    
                    <div *ngIf="evt.AssignedProgram" [ngClass]="{'show-info-training': evt.AssignedProgram.Status==='ACTIVE', 'show-info-training-draft': evt.AssignedProgram.Status==='DRAFT'}" id="showinfotraining" 
                               style="text-shadow: none; float: none; font-family: Roboto; font-weight:normal;  white-space: nowrap; overflow-x:hidden; margin-left: 25px">
                                    <div style="float:right;"><!-- <a href="#" class="removefromschedule"
                                            [attr.eventid]="evt.EventId" eventtype="EVENT"
                                            
                                            [attr.eventdate]="day"><img src="/img/blackcross.png" style="height: 16px;"></a> -->
                                    </div>
                                    <a (click)="openDisplayWorkoutDialog(evt)">
                                        <div style="font-weight: bold; font-size: 12px; display: flex; align-items: center;">
                                            <mat-icon style="font-size:medium; height: 16px; width: 16px;">
                                                table_chart
                                            </mat-icon>
                                            &nbsp;
                                            {{evt.AssignedProgram.Title}}                                          
                                        </div>
                                        <div class="TrainingEventLevel" >       
                                            <mat-icon style="font-size: small; color: darkgray; width: 16px;height: 16px;" *ngIf="evt.AssignedProgram.LevelName">shield</mat-icon>
                                          <!--   <img src="../../assets/img/shield.png" style="height: 12px; color: darkgray;" *ngIf="evt.AssignedProgram.LevelName"> -->
                                            <label *ngIf="evt.AssignedProgram.LevelName">{{ evt.AssignedProgram.LevelName }}</label>
                                                       
                                          
                                        </div>
                                        <div  *ngIf="evt.AssignedProgram.AssignedPlayers"  > 
                                            <div style="color: white;font-size:x-small;display: flex; flex-direction: row;margin-left: 25px; flex-wrap: wrap;">                                                
                                                <div *ngFor="let player of evt.AssignedProgram.AssignedPlayers" style="display: flex; flex-direction: row; ">
                                                    <mat-icon style="font-size:small; width: 16px;height: 16px; color: darkgray;">
                                                        person
                                                    </mat-icon>                                
                                                    {{player.LastName}}, {{player.FirstName}}&nbsp;
                                                </div>
                                                
                                            </div>
                                        </div>
                                      
                                     
                                    </a> 
                                </div>
                </div>            
            </div>
                
        </div>
    
    </div>

</div>