<div [class]="this.bordercolor">
    <table>
        <tr>
            
            <td>
                {{this.Injury.BodyAreaName}}/{{this.Injury.BodyPartName}}&nbsp;({{this.Injury.Side}})
            </td>
            <td >
                {{this.Injury.TypeName}}
            </td>
            <td>
               
            </td>
            <td>  {{this.Injury.InjuryDays}} days</td>
        </tr>
        <tr>
            
            <td class="minortext" colspan="3"> {{this.Injury.InjuryDate | date:'yyyy-MM-dd'}} -  {{this.Injury.ActualReturnDate | date:'yyyy-MM-dd'}} <span style="color:gray;" *ngIf="this.Injury.EstimatedReturnDate!=null">({{this.Injury.EstimatedReturnDate | date:'yyyy-MM-dd'}})</span></td>
           
            <td>
              
            </td>
        </tr>

     
        <tr>
            <td  class="smalltitle">
                Trauma
            </td>
            <td class="smalltitle">
                Conditiion         
            </td>
            <td class="smalltitle">
                Location
            </td>
            <td class="smalltitle">
                
            </td>
        </tr>
        <tr>
            <td  class="value">
                <span *ngIf="this.Injury.Trauma">YES</span>
                <span *ngIf="!this.Injury.Trauma">NO</span>
            </td>
            <td  class="value">
                {{this.Injury.ConditionName}} &nbsp;   
            </td>
            <td  class="value">
                {{this.Injury.LocationName}}
            </td>
            <td  class="value">
                
            </td>
        </tr>
        <tr *ngIf="this.Injury.Description!=null">
            <td class="smalltitle">
                Description
            </td>
            <td>

            </td>

        </tr>
        <tr *ngIf="this.Injury.Description!=null">
            <td class="value" colspan="4">
                {{this.Injury.Description}}
            </td>
            <td>

            </td>

        </tr>
    </table>
    <button mat-button [routerLink]="'/injury/edit/' + this.Injury.InjuryId">EDIT</button>
    
</div>