
<!-- <form #loginForm="ngForm" class="col s12 white" (ngSubmit)="OnSubmit(UserName.value,Password.value)">
   <div class="row">
     <div class="input-field col s12">
       <i class="material-icons prefix">account_circle</i>
       <input type="text" #UserName ngModel name="UserName" placeholder="Username" required>
     </div>
   </div>
   <div class="row">
      <div class="input-field col s12">
        <i class="material-icons prefix">vpn_key</i>
        <input type="password" #Password ngModel name="Password" placeholder="Password" required>
      </div>
    </div>
    <div class="row">
        <div class="input-field col s12">
          <button [disabled]="!loginForm.valid" class="btn-large btn-submit" type="submit">Login</button>
        </div>
      </div>
</form> -->

<div style="display: flex;  align-content: center; justify-content: center; width: 100%; margin-top: 100px;">
  <div class="loading-indicator" *ngIf="isLoggingIn">

    <mat-progress-spinner
    class="example-margin"
    color="orange"
    mode="indeterminate"
    >
</mat-progress-spinner>

  </div>
  
    <div style="display: flex; flex-direction: column;">
  
    
    <mat-card appearance="outlined" style="width: 450px;">
        <mat-card-header >
            <img  src="../../assets/img/DSLogo.png" style="height: 40px; margin-bottom: 15px;"/>
           
        </mat-card-header>
        <mat-card-content>
          <form #loginForm="ngForm"  (ngSubmit)="OnSubmit(UserName.value,Password.value)" >
      
          <mat-form-field appearance="outline" style="width: 100%;">
              <mat-label translate>Enter username</mat-label>
            
              <input matInput type="text" #UserName ngModel name="UserName" placeholder="Username" required [disabled]="isLoggingIn">
              <mat-icon matSuffix>account_box</mat-icon>
              
            </mat-form-field>
      
        <br/>
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Enter password</mat-label>
        
          <input matInput type="password" #Password ngModel name="Password" placeholder="Password" style="width: 300px;" required [disabled]="isLoggingIn" >
          <mat-icon matSuffix>vpn_key</mat-icon>
          
        </mat-form-field>
          
        <br/> 
        <button mat-button [disabled]="isLoggingIn"  type="submit">LOG IN</button>
       
<!--         <button mat-button  type="button" routerLink="/setnewpassword"  type="submit">RESET PASSWORD</button> -->
        
      </form>

        </mat-card-content>
        
      </mat-card>
      <div>
        <a mat-button [disabled]="isLoggingIn"  type="button" routerLink="/resetpassword"  type="submit" style="color: gray;">RECOVER PASSWORD</a>
      </div>

      <div style="font-family: Roboto; font-size: small;color: gray; width: 100%; text-align: right;">
        v1.70
      </div>
      <div *ngIf="isLoginError" style="color: #c62828; font-family: Roboto;">
        <i class="material-icons">error</i> Incorrect username or password
    </div>
  </div>
</div>
