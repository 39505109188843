import { Component, Input, OnInit } from '@angular/core';
import { FlowItemModel } from '../../models/FlowItemModel';
import { CommonModule } from '@angular/common';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';

@Component({
  selector: 'app-note-card-player',
  standalone: true,
  imports: [CommonModule,SharedModuleModule],
  templateUrl: './note-card-player.component.html',
  styleUrls: ['./note-card-player.component.css']
})
export class NoteCardPlayerComponent implements OnInit {
  @Input() Note: FlowItemModel;
  constructor() { }

  ngOnInit() {
  }

}
