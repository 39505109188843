
<div class="app-page" >    
    <mat-toolbar>
        <!-- <button mat-raised-button (click)="selectDivisions()">
            <mat-icon>filter_alt</mat-icon>
            FILTER
        </button> &nbsp; -->
       
        <button mat-raised-button color="" (click)="openSetGameFilterDialog()">
            <mat-icon>filter_alt</mat-icon>
            DIVISIONS
          </button> &nbsp;
        <!-- <app-set-game-filter-dialog (divisionChanged)="getData()"></app-set-game-filter-dialog> -->

        <!-- <button mat-raised-button routerLink="/scouting/setgamefilter">
            <mat-icon>filter_alt</mat-icon>
            DIVISIONS
        </button> &nbsp; -->

        <!--<button mat-raised-button color="primary" (click)="openDialog()">Open Filter</button>-->

        <button mat-raised-button (click)="getPastGames()">
            <mat-icon>event_repeat</mat-icon>
            PAST GAMES</button>&nbsp;
        <button mat-raised-button (click)="getData()">
            <mat-icon>event</mat-icon>
            COMING GAMES
        </button>
      <!--   <button mat-raised-button routerLink="/scouting/games/search">
            <mat-icon>search</mat-icon>
            SEARCH GAMES
        </button> -->
    </mat-toolbar>
    <mat-progress-spinner mode="indeterminate" *ngIf="this.isBusy"></mat-progress-spinner>
    <div *ngIf="this._pageData?.Games.length==0" style="font-family: Roboto; margin: 20px;;">
        NO GAMES FOUND
    </div>
    <ng-container *ngFor="let group of this._pageData.Games | groupByDate" >
        <div style="margin-left: 20px;">
                <app-date-divider [Date]="group.GameDate"></app-date-divider>
        </div>
       
        <div style="display: flex; flex-direction: row; flex-wrap: wrap;margin-left: 20px;" *ngIf="this._pageData">
             <div *ngFor="let game of group.Games">
                <app-game-card [Game]="game" [ShowDate]="false" (removeEvent)="removeFromSchedule($event)" (openAddEvent)="addToSchedule($event, game.HomeTeamName, game.AwayTeamName, game.GameDate, game.GameTime)"></app-game-card>    
            </div>
        </div>
       
        
    </ng-container> 
    
</div>

