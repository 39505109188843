<div class="app-page">

<form #selectForm [formGroup]="_form" (ngSubmit)="submit()" *ngIf="loadedList && loadedCats && loadedUsers">
  
          <br/>
          <div >
              <mat-form-field  appearance="fill" style="min-width: 350px;">
              <mat-label>Name</mat-label>
              <input matInput  formControlName="Description" autocomplete="false">
            </mat-form-field>
            <br/>

            <mat-form-field class="example-full-width" appearance="fill" style="min-width: 350px;">
              <mat-label>Category</mat-label>
              <input type="text" matInput formControlName="Category" [matAutocomplete]="auto" >
              <mat-autocomplete #auto="matAutocomplete"  >
                <mat-option *ngFor="let option of filteredCategories | async" [value]="option" style="color: white;">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> 
          </div>
          
      
          <label>
            VIEW
          
          </label>
          <label style="margin-left: 60px;">
            EDIT
          </label>
          <br/>
         <!--  <mat-selection-list #sharinglist style="height: 350px;" [multiple]="true"  >
            <mat-list-option *ngFor="let usr of this.users" [value]="usr.User_Id" >             
              <h4 mat-line>{{ usr.LastName }}, {{ usr.FirstName }} </h4>              
             </mat-list-option>
            
            </mat-selection-list> -->
            <div style="height: 450px; overflow-y: scroll; border: 1px gray solid;" *ngIf="this.PlayerListSecurity"
              formArrayName="PlayerListSecurity">
            
              <div *ngFor="let access of PlayerListSecurity.controls; index as i;">
                <div [formGroupName]="i" STYLE="display: flex; flex-direction: row; border-bottom: gray solid 1px;">
                 
                  <div  style="width: 100px;">
                      <mat-checkbox formControlName="value"></mat-checkbox>
                  </div>
                  <div  style="width: 100px;">
                    <mat-checkbox formControlName="CanEdit"></mat-checkbox>
                </div>
                  <div style="width: 200px; font-family: Roboto;" >
                      {{this._form.controls['PlayerListSecurity'].controls[i].controls.FullName.value}}
                  </div>
              </div>
              </div>
            </div>
    
<!-- 
        <div>{{this._form.value | json }}</div> -->

    
 
        <button mat-button [disabled]="!this._form.valid" type="submit">SAVE</button>
        <button mat-button type="button" (click)="goback()" >CANCEL</button>

</form>
</div>