<div class="app-page" >
    <mat-progress-spinner *ngIf="this.isBusy"
    class="loading-indicator"
    color="primary"
    mode="indeterminate"
>
</mat-progress-spinner>
&nbsp;
<h2>
    <mat-icon> sports_hockey </mat-icon>
    GAMES
</h2>
<a mat-raised-button (click)="sendReminder()" *ngIf="this._userService.roleMatch(['Org_Admin'])">
    <mat-icon>send</mat-icon>    
    SEND REMINDER TO PLAYERS</a>
    <mat-divider></mat-divider>
    <h3>Overview</h3>
    <app-game-charts [GameList]="this.games" *ngIf="!this.isBusy"></app-game-charts>
    <mat-divider></mat-divider>
    <h3>Game details</h3>
    <div style="display: flex; flex-wrap: wrap;">
        <div style="display: flex; justify-content: center; margin-top: 30px;"  *ngIf="this.games?.length==0">
            <div style="font-family: Roboto;">No games played yet</div>
        </div>
        
        
        <app-load-game-card *ngFor="let game of this.games" [GameEvent]="game" [TeamId]="this.TeamId" [ShowStatistics]="this.isStatisticsUser" >
            
        </app-load-game-card>
    </div>
    </div>

