<div class="app-page" style="margin-left: 20px; margin-top: 20px;">

    <mat-form-field>
      <mat-label>Group name</mat-label>
      <input matInput placeholder="Group name" [(ngModel)]="this.group.Name" required>        
    </mat-form-field>
    <br/>
    <mat-form-field>
      <mat-label>Display name</mat-label>
      <input matInput placeholder="Display name" [(ngModel)]="this.group.DisplayName">        
    </mat-form-field>
    <br/>
 
    <mat-form-field class="form-field">
      <mat-label>Background color - rows</mat-label>
    <input matInput type="color" name="BgColor" [ngClass]="{'no-color': !this.group.BgColor}" [(ngModel)]="this.group.BgColor" (ngModelChange)="this.group.BgColor=$event" required placeholder="Color">
    </mat-form-field>
  
    <mat-form-field class="form-field">
      <mat-label>Text color - rows</mat-label>
    <input matInput type="color" name="TextColorValue" [ngClass]="{'no-color': !this.group.TextColorValue}" [(ngModel)]="this.group.TextColorValue" (ngModelChange)="this.group.TextColorValue=$event" required placeholder="Color">
    </mat-form-field><br/>
    <mat-form-field class="form-field">
      <mat-label>Background color - header</mat-label>
    <input matInput type="color" name="BgColorHeader" [ngClass]="{'no-color': !this.group.BgColorHeader}" [(ngModel)]="this.group.BgColorHeader" (ngModelChange)="this.group.BgColorHeader=$event" required placeholder="Color">
    </mat-form-field>
    <mat-form-field class="form-field">
      <mat-label>Text color - header</mat-label>
    <input matInput type="color" name="TextColorHeader" [ngClass]="{'no-color': !this.group.TextColorHeader}" [(ngModel)]="this.group.TextColorHeader" (ngModelChange)="this.group.TextColorHeader=$event" required placeholder="Color">
    </mat-form-field>


    <h3>Parameters</h3>
    <div style="display: flex; flex-direction: row; min-width: 500px;justify-content: start;">

 
  <div style="margin-right: 30px;">
    <span style="font-family: Roboto;">Selected</span><br/>
    <div
      cdkDropList
      #selectedList="cdkDropList"
      [cdkDropListData]="group.Parameters"
      [cdkDropListConnectedTo]="[notselectedList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of this.group.Parameters" cdkDrag>{{item.Abbreviation}}</div>
    </div>
  </div>
  
    
  <div >
    <span style="font-family: Roboto;">Available</span>
  
    <div
      cdkDropList
      #notselectedList="cdkDropList"
      [cdkDropListData]="group.ParametersNotSelected"
      [cdkDropListConnectedTo]="[selectedList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of this.group.ParametersNotSelected" cdkDrag>{{item.Abbreviation}}</div>
    </div>
 
  
  </div>
  
</div>
<span style="font-family:Roboto; color:gray;">
  Drag parameters to select and order them 
</span>
<br/>
<h3>Calculated parameters</h3>
<div style="display: flex; flex-direction: row; min-width: 500px;justify-content: start;">

 
  <div style="margin-right: 30px;">
    <span style="font-family: Roboto;">Selected</span><br/>
    <div
      cdkDropList
      #selectedCalculatedList="cdkDropList"
      [cdkDropListData]="group.ParametersCalculated"
      [cdkDropListConnectedTo]="[notselectedCalculatedList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of this.group.ParametersCalculated" cdkDrag>{{item.Abbreviation}}</div>
    </div>
  </div>
  
    
  <div >
    <span style="font-family: Roboto;">Available </span>
  
    <div
      cdkDropList
      #notselectedCalculatedList="cdkDropList"
      [cdkDropListData]="group.ParametersCalculatedNotSelected"
      [cdkDropListConnectedTo]="[selectedCalculatedList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of this.group.ParametersCalculatedNotSelected" cdkDrag>{{item.Abbreviation}}</div>
    </div>
 
  
  </div>
  
</div>
<span style="font-family:Roboto; color:gray;">
  Drag parameters to select and order them 
</span>
<br/>
<br/>
<br/>
<button mat-raised-button (click)="save()" color="primary">SAVE</button>
<button mat-button (click)="goback()" >CANCEL</button>
