<div class="app-page">
    <mat-toolbar>
        <button mat-icon-button (click)="previousMonth()" color="primary"><mat-icon>keyboard_arrow_left</mat-icon></button>
       <!--  <div>
            
            <a (click)="openAddDialog()" mat-button color="primary"> <mat-icon>add</mat-icon>EVENT</a>
            
        </div> -->
        <mat-form-field  appearance="fill"  style="width: 400px;font-size: small;" >
            <mat-label>Select schedule</mat-label>
            <mat-select (selectionChange)="reloadSchedule($event)" [(value)]="this.TeamId" >
                <mat-option *ngFor="let team of this.Teams" [value]="team.TeamId">{{team.Name}} - {{team.Level}}</mat-option>
            </mat-select>
        
        </mat-form-field>
        &nbsp;
        <mat-form-field  appearance="fill"  style="width: 400px;font-size: small;" >
            <mat-label>Players</mat-label>
            <mat-select (selectionChange)="reloadSchedulePlayer($event)" [(value)]="this.PlayerId" >
                <mat-option value="">Team level</mat-option>
                <mat-option *ngFor="let player of this.Players" [value]="player.PlayerId">{{player.LastName}}, {{player.FirstName}}</mat-option>
            </mat-select>        
        </mat-form-field>
        &nbsp;
        &nbsp;
        &nbsp;
        <button mat-button (click)="reload()" color="primary">REFRESH</button>
        <div style="width:100%; text-align: center;">
            {{this.schedule.ActiveYear}}&nbsp;{{this.schedule.ActiveMonthText}}
        </div>
        <button mat-icon-button (click)="nextMonth()" color="primary"><mat-icon>keyboard_arrow_right</mat-icon></button>
        
    </mat-toolbar>
    <mat-progress-bar *ngIf="this.isLoading" mode="query"></mat-progress-bar>
    
    

   
    <div class="calendar" id="calendar">
        <ul class="weekdays">
            <li>Monday</li>
            <li>Tuesday</li>
            <li>Wednesday</li>
            <li>Thursday </li>
            <li>Friday</li>
            <li>Saturday</li>
            <li>Sunday</li>
        </ul>
        <ul>

            <ng-container *ngFor="let day of this.Days; let i = index">
                <li [ngClass]="DayClass[i]" id="" >
                    <div style="float: left;">{{day.getDate()}}</div>
                   
                    <button mat-icon-button [matMenuTriggerFor]="menu"><mat-icon>add</mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="openAssignWorkoutDialog(day)"> Workout</button>
                        <button mat-menu-item (click)="openAddEventDialog(day)">Event</button>
                    </mat-menu>
                    <div style="float: right; height:20px; ">
                       
                        <!-- <a href="#"
                            onclick="javascript:showAddPopup('2019-11-12');"
                            style="text-decoration: none; font-size: large;"><img style="height: 20px;"
                                src="/img/AddCalenderitem.png"></a> -->
                    </div><br>
                  
                   <!--  <ng-container  *ngFor="let daya of this.schedule.Days[i+this.daysbeforeStartOfMonth]"> -->
                   
                        <ng-container *ngIf="this.schedule.Days[i-this.daysbeforeStartOfMonth] && this.schedule.Days[i-this.daysbeforeStartOfMonth].TeamEvents">

                        
                            <ng-container  *ngFor="let evt of this.schedule.Days[i-this.daysbeforeStartOfMonth].TeamEvents">
                                
                                <div class="show-info-new" id="showinfo" 
                                [style.border-left]="'3px solid #c632df'" style="text-shadow: none; float: none; font-family: verdana; font-weight:normal;  white-space: nowrap; overflow-x:hidden; margin-left: 1px">
                                    <div style="float:right;"><!-- <a href="#" class="removefromschedule"
                                            [attr.eventid]="evt.EventId" eventtype="EVENT"
                                            
                                            [attr.eventdate]="day"><img src="/img/blackcross.png" style="height: 16px;"></a> -->
                                    </div>
                                    <a (click)="openForEditDialog(evt.EventId)">
                                        <div style="font-weight: bold; font-size: 12px; display: flex; align-items: center;">
                                            <mat-icon style="font-size:medium; height: 16px; width: 16px;">
                                                event
                                            </mat-icon>
                                            {{evt.EventDate | date:'HH:mm'}} {{evt.Title}}
                                            <br>
                                        </div>
                                        <!-- <div>{{evt.EventDate | date:'HH:mm'}} {{evt.Title}}<br></div> -->
                                        <div>{{evt.Location}}</div>
                                        <div style="display: flex; flex-direction: row;">
                                            <div style="color: white;font-size:x-small;">
                                                <mat-icon style="font-size:small; width: 12px;">
                                                    schedule
                                                </mat-icon>
                                                {{evt.Duration}} min
                                                <mat-icon style="font-size:small; width: 12px;">
                                                bolt
                                                </mat-icon>
                                                {{evt.LoadValue}}
                                            </div>
                                            &nbsp;&nbsp;
                                            <div *ngIf="evt.FullTeamEvent" style="color: white;font-size:x-small;">
                                                <img src="../../assets/img/shield.png" style="height: 12px;">
                                                {{evt.Team.LevelName}}
                                            </div>
                                            <div *ngIf="!evt.FullTeamEvent"> 
                                                <div style="color: white;font-size:x-small;">
                                                    <mat-icon style="font-size:small; width: 16px;">
                                                    people
                                                    </mat-icon>
                                                    {{evt.Attendees.length}} 
                                                    players
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </a>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="this.schedule.Days[i-this.daysbeforeStartOfMonth] && this.schedule.Days[i-this.daysbeforeStartOfMonth].AssignedWorkouts">

                        
                            <ng-container  *ngFor="let evt of this.schedule.Days[i-this.daysbeforeStartOfMonth].AssignedWorkouts">

                                
                                <div *ngIf="evt.AssignedProgram==null" class='show-info-training' id="showinfotraining" 
                                [style.border-left]="'3px solid #0791ff'" style="text-shadow: none; float: none; font-family: Roboto; font-weight:normal;  white-space: nowrap; overflow-x:hidden; margin-left: 1px">
                                    <div style="float:right;"><!-- <a href="#" class="removefromschedule"
                                            [attr.eventid]="evt.EventId" eventtype="EVENT"
                                            
                                            [attr.eventdate]="day"><img src="/img/blackcross.png" style="height: 16px;"></a> -->
                                    </div>
                                    <a (click)="openDisplayWorkoutDialog(evt)">
                                        <div style="font-weight: bold; font-size: 12px; display: flex; align-items: center;">
                                            <mat-icon style="font-size:medium; height: 16px; width: 16px;">
                                                view_list
                                            </mat-icon>
                                            {{evt.Workout.Name}}
                                            <br>
                                        </div>
                                        <div  style="display: flex;align-items: end; font-size: x-small; margin-left: 18px;">
                                    <!--         <mat-icon style="font-size:medium; height: 16px; width: 16px;">
                                                table_chart
                                            </mat-icon> -->
                                            
                                            -
                                        </div>
                                        <div>
                                            {{ evt.PlannedDate }}
                                            -
                                            {{evt.Workout.EstimatedDuration}} min
                                        </div>
                                        
                                        <!-- <div style="color: gray;">{{evt.Duration}} min - {{evt.LoadValue}}</div> -->
                                    </a> 
                                </div>
                                
                                <div *ngIf="evt.AssignedProgram" [ngClass]="{'show-info-training': evt.AssignedProgram.Status==='ACTIVE', 'show-info-training-draft': evt.AssignedProgram.Status==='DRAFT'}" id="showinfotraining" 
                                [style.border-left]="'3px solid #0791ff'" style="text-shadow: none; float: none; font-family: Roboto; font-weight:normal;  white-space: nowrap; overflow-x:hidden; margin-left: 1px">
                                    <div style="float:right;"><!-- <a href="#" class="removefromschedule"
                                            [attr.eventid]="evt.EventId" eventtype="EVENT"
                                            
                                            [attr.eventdate]="day"><img src="/img/blackcross.png" style="height: 16px;"></a> -->
                                    </div>
                                    <a (click)="openDisplayWorkoutDialog(evt)">
                                        <div style="font-weight: bold; font-size: 12px; display: flex; align-items: center;">
                                            <mat-icon style="font-size:medium; height: 16px; width: 16px;">
                                                view_list
                                            </mat-icon>
                                            {{evt.Workout.Name}}
                                            <br>
                                        </div>
                                        <div *ngIf="evt.AssignedProgram" style="display: flex;align-items: end; font-size: x-small; margin-left: 18px;">                                                                                        
                                            {{evt.AssignedProgram.Title}}
                                        </div>
                                        <div>
                                          <!--   <mat-icon *ngIf="evt.AssignedProgram.TeamId!='00000000-0000-0000-0000-000000000000'" style="font-size: medium; width: 15px;">shield</mat-icon> -->
                                            {{ evt.PlannedDate }}
                                            -
                                           <!--  <mat-icon style="font-size: medium; width: 15px;">schedule</mat-icon> -->
                                            {{evt.Workout.EstimatedDuration}} min
                                        </div>
                                        
                                        <!-- <div style="color: gray;">{{evt.Duration}} min - {{evt.LoadValue}}</div> -->
                                    </a> 
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="i-this.daysbeforeStartOfMonth>0 && this.schedule.Days[i-this.daysbeforeStartOfMonth] && this.schedule.Days[i-this.daysbeforeStartOfMonth].GameEvents">
                            
                            <ng-container *ngFor="let evt of this.schedule.Days[i-this.daysbeforeStartOfMonth].GameEvents">
                                
                                <div class="show-info-game" id="showinfo"
                                    [style.border-left]="'3px solid orange'"  style="text-shadow: none; float: none; font-family: verdana; font-weight:normal;  white-space: nowrap; overflow-x:hidden; margin-left: 1px;">
                                    <div style="float:right;"><!-- <a href="#" class="removefromschedule"
                                            [attr.eventid]="evt.EventId" eventtype="GAME" [attr.divisionname]="evt.DivisionName"
                                            [attr.gamename]="evt.HomeTeamName"
                                            [attr.eventdate]="day"><img src="/img/blackcross.png" style="height: 16px;"></a> -->
                                    </div>
                                    
                                    <div >
                                        <span class="show-name" *ngIf="evt.IsHomeGame">
                                            {{evt.StartTime | date:'HH:mm' }}
                                            {{evt.DivisionName}} &nbsp; {{evt.AwayTeamName}}
                                            
                                        </span>
                                        <span class="show-name" *ngIf="!evt.IsHomeGame">
                                            {{evt.StartTime | date:'HH:mm' }} {{evt.DivisionName}} {{evt.HomeTeamName}}
                                        </span>
                                    </div>
                                    <div>
                                        {{ evt.Venue}}
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    <!-- </ng-container> -->



                </li>
            </ng-container>

        </ul>
    </div>
<div>
    
</div>


</div>