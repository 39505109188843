<div style="margin-left: 20px;" >
  <h2>SELECT DIVISIONS</h2>
  
  <form #selectForm [formGroup]="_form" (ngSubmit)="submit()" *ngIf="isLoaded" >

      

 <!--         <ng-container formArrayName="divisions">

               <ng-container *ngFor="let group of this.divisions | groupByCountry" >
                  <div style="margin-left: 10px;">
                          {{group.CountryName}}
                  </div>
              
                  <div style="display: flex; flex-direction: column;; flex-wrap: wrap;margin-left: 30px;">
                      <div *ngFor="let division of group.Divisions; let i = index">
                          <mat-checkbox 
                              [formControlName]="i" [checked]="division.DivisionFiltered" >{{division.Name}}</mat-checkbox>
                      </div>
                  </div>
              
                  
              </ng-container>  -->

              <div formArrayName="divisions"  >
                  <br/>
                  <div *ngFor="let order of theDivisions.controls; let i = index" style="font-size: small;"  >
                      
                      <div *ngIf="i==0" style="font-size: medium;">{{divisionsData[i].CountryName}}</div>
                      <div *ngIf="i>0 && divisionsData[i].CountryName!=divisionsData[i-1].CountryName " style="font-size: medium;">{{divisionsData[i].CountryName}}</div>
                      
                      <mat-checkbox 
                          [formControlName]="i" [checked]="divisionsData[i].DivisionFiltered" >
                      {{divisionsData[i].CountryName}} - {{divisionsData[i].Name}}
                      </mat-checkbox>          
                                 
                  </div>
              </div>
          <!-- </ng-container> -->
          <button mat-raised-button color="primary" type="submit">SAVE</button>&nbsp;
          <button mat-button  (click)="cancel()" >CANCEL</button>
      </form>
      

</div>