import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';
import { BaseService } from './baseservice';

import { PlayerInvite } from './invite-player-dialog/invite-player-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PlayerInviteService extends BaseService {

  constructor(private http: HttpClient) {
    super();
   }



  private getPendingInvitesByTeamUrl = '/Team/GetPendingInvitesByTeam?TeamId=';  // URL to web api
  private SendInviteUrl = '/api/playerinvite';  // URL to web api
  private CancelInviteUrl = '/api/playerinvite/cancel';
  private ResendInviteUrl = '/Account/ResendPlayerInvite';
  




  getPendingInvitesByTeam(TeamId:string): Observable<PlayerInvite[]> {
      
    return this.http.get<PlayerInvite[]>(this.getPendingInvitesByTeamUrl + TeamId).pipe(
      catchError(this.handleError('getPendingInvitesByTeam', []))
        );
  }


  SendInvite(TeamId:string, PlayerId: string, Email: string): Observable<any> {
    var params = "/" + TeamId  + "/" + PlayerId ;
        //console.log(params);
    return this.http.post<any>(this.rootUrl + this.SendInviteUrl + params, JSON.stringify(Email), this.httpOptions).pipe(
      catchError(this.handleError('SendInvite', []))
        );
  }

 /*  ResendInvite(InviteId:string,): Observable<any> {
    var params = "?InviteId=" + InviteId;
      
    return this.http.get<any>(this.ResendInviteUrl + params).pipe(
      catchError(this.handleError('ResendInviteUrl', []))
        );
  } */

  CancelInvite(InviteId:string): Observable<any> {
    var params = "/" + InviteId;
    
    return this.http.post<any>(this.rootUrl + this.CancelInviteUrl + params, JSON.stringify(InviteId), this.httpOptions).pipe(
      catchError(this.handleError('SendInvite', []))
        );
  /*   return this.http.get<any>(this.CancelInviteUrl + params).pipe(
      catchError(this.handleError('CancelInviteUrl', []))
        ); */
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {    
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // redirect to the login route
          // or show a modal 
          window.location.href="/Account/Login";
          //this.router.navigate(['/notlo//console.log("Redirecting to login");
          
        }
      }
    
    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
  }
}
