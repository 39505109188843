<mat-accordion style=" margin-left: 70px;">
    <mat-expansion-panel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
        <mat-expansion-panel-header>
            <mat-panel-title> Overview </mat-panel-title>
            <mat-panel-description> {{this.ResultOverview.ReportCount}} reports found.
                {{this.ResultOverview.PlayerCount}} players found. </mat-panel-description>
        </mat-expansion-panel-header>
        <div style=" margin-left: 30px; display: flex; flex-direction: row; ">

            <div>
                <div style="display: flex;flex-direction: row;">
                    <div class="titletext">Projections</div>
                    <div class="titletext2">Reports</div>
                    <div class="titletext2">Players</div>
                </div>
                <ng-container *ngFor="let proj of this.ResultOverview.Projections">
                    <div style="display: flex;flex-direction: row;">
                        <div class="nametext">{{proj.Name}}</div>
                        <div class="valuetext">{{proj.ReportCount}}</div>
                        <div class="valuetext">{{proj.PlayerCount}}</div>
                    </div>
                </ng-container>
            </div>
            <div>
                <div style="display: flex;flex-direction: row;">
                    <div class="titletext">Capacity</div>
                    <div class="titletext2">Reports</div>
                    <div class="titletext2">Players</div>
                </div>
                <ng-container *ngFor="let cap of this.ResultOverview.Capacities">
                    <div style="display: flex;flex-direction: row;">
                        <div class="nametext">{{cap.Name}}</div>
                        <div class="valuetext">{{cap.ReportCount}}</div>
                        <div class="valuetext">{{cap.PlayerCount}}</div>
                    </div>
                </ng-container>
            </div>
            <div>
                <div style="display: flex;flex-direction: row;">
                    <div class="titletext" style="min-width: 100px;">Year of birth</div>
                    <div class="titletext2">Reports</div>
                    <div class="titletext2">Players</div>
                </div>
                <ng-container *ngFor="let cap of this.ResultOverview.YearOfBirths">
                    <div style="display: flex;flex-direction: row;">
                        <div class="nametext" style="min-width: 100px;">{{cap.Name}}</div>
                        <div class="valuetext">{{cap.ReportCount}}</div>
                        <div class="valuetext">{{cap.PlayerCount}}</div>
                    </div>
                </ng-container>
            </div>
            <div>
                <div style="display: flex;flex-direction: row;">
                    <div class="titletext" style="min-width: 100px;">Position</div>
                    <div class="titletext2">Reports</div>
                    <div class="titletext2">Players</div>
                </div>
                <ng-container *ngFor="let cap of this.ResultOverview.Positions">
                    <div style="display: flex;flex-direction: row;">
                        <div class="nametext" style="min-width: 100px;">{{cap.Name}}</div>
                        <div class="valuetext">{{cap.ReportCount}}</div>
                        <div class="valuetext">{{cap.PlayerCount}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </mat-expansion-panel>

</mat-accordion>