import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class Globals {
  isLoggedIn: boolean = false;
  titleBarText = "Dalecarlia Scouting";
  isTestEnvironment: Boolean = false;
  showSearch="none";
  private subject = new Subject<any>();
  UserFullName: string;
  TeamName: string;
  _TeamImage: string;
  isMedicalUser: boolean = false;
  isMedicalViewer: boolean = false;
  isMedicalEditor: boolean = false;
  organizationId: string;
  UserId: string;

  playerCompare: string;
  playerCompareFirstName: string;
  playerCompareLastName: string;

  testresultPeriodIndex: string;


  set TeamImage(value: string){
    console.log("Set TeamImage:" + value);
    this._TeamImage = value;
  }

  get TeamImage(){
    return this._TeamImage;
  }

  sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  setIsTestEnv(value: boolean){
    this.isTestEnvironment = value;
  }
  
  IsTestEnvironment(): Boolean{
    return this.isTestEnvironment;
  }
}