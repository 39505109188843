import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ObjectiveTask } from '../../models/objectives';
import { ObjectivesService } from '../../services/objectives.service';
import { UpdateTaskDialogComponent } from '../update-task-dialog/update-task-dialog.component';

@Component({
  selector: 'app-my-task-row',
  templateUrl: './my-task-row.component.html',
  styleUrls: ['./my-task-row.component.css']
})
export class MyTaskRowComponent implements OnInit {

  @Input() Task: ObjectiveTask;
  @Output() TaskChangedEvent = new EventEmitter<number>();

  constructor(private objectiveService: ObjectivesService,private dialog: MatDialog,) { }

  ngOnInit(): void {
  }

  completeTask(){
    const newObjectiveDialog = this.dialog.open(UpdateTaskDialogComponent, {
      data: {
       Task: this.Task
      }
    });
    newObjectiveDialog.afterClosed().subscribe(result => {
      this.TaskChangedEvent.emit();
     
    });
  }

}
