import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { GameStatisticsGroupViewModel, GameStatisticsParameterCalculatedViewModel, GameStatisticsParameterViewModel } from 'src/app/stats/models/statistics.model';

@Component({
  selector: 'app-config-statistics-group',
  templateUrl: './config-statistics-group.component.html',
  styleUrls: ['./config-statistics-group.component.css']
})
export class ConfigStatisticsGroupComponent implements OnInit {

  group: GameStatisticsGroupViewModel;

  constructor(public statsService: GameStatisticsService, route: ActivatedRoute, public router: Router) {    
    const groupid = route.snapshot.paramMap.get('GroupId');
    //console.log(groupid);
    if(groupid==='NEW'){
      this.group = new GameStatisticsGroupViewModel();
      this.group.BgColor ="#FFFFFF";
      this.group.BgColorHeader ="#C9C9C9";
      this.group.TextColorHeader ="#000000";
      this.group.TextColorValue ="#000000";
      statsService.getAllParameters().subscribe(parms=>{
        this.group.ParametersNotSelected = parms as GameStatisticsParameterViewModel[];
        this.group.Parameters = [];
      });
      statsService.getAllParametersCalculated().subscribe(parms=>{
        this.group.ParametersCalculatedNotSelected = parms as GameStatisticsParameterCalculatedViewModel[];
        this.group.ParametersCalculated = [];
      });
    }else{
      statsService.getGroup(groupid).subscribe(data=>{
        console.log(data);
        this.group = data as GameStatisticsGroupViewModel;
      
      });
    }
   

  }

  ngOnInit() {
  }

  save(){
    console.log(this.group);
    this.statsService.saveGroup(this.group).subscribe(res =>{
      if(res)
        this.router.navigateByUrl('config/statistics/home');
    });
  }

  goback(){
    this.router.navigateByUrl('config/statistics/home');
  }

  noGroups: GameStatisticsGroupViewModel[];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

}
