<div style="display: flex; align-content: center; justify-content: center; width: 100%; margin-top: 100px;">
  <mat-card appearance="outlined" style="width: 350px;" *ngIf="!requestSuccess">
      <mat-card-header>
          <img  src="../../assets/img/DS-vit.png" style="height: 40px;"/>
         
      </mat-card-header>
      <mat-card-content>
        <form (ngSubmit)="forgotPassword(UserName.value)" >
    
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Enter username</mat-label>
          
            <input matInput type="text" #UserName ngModel name="UserName" placeholder="UserName" required>
            <mat-icon matSuffix>account_box</mat-icon>
            
          </mat-form-field>
    
      <br/>
     
        
      <br/> 
      <button mat-button [disabled]="false"  type="submit">RECOVER PASSWORD</button>
      
    
    </form>
      </mat-card-content>
      
    </mat-card>
    <div *ngIf="requestSuccess">
      We have sent you an email with instructions how to recover your password.<br/>
      <span style="color:gray">Remember to check spam folder if you can't find the mail in your inbox.</span>
    </div>
    <div *ngIf="requestError">
      There was an error revocering your password
    </div>
    
</div>
