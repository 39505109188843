
<mat-card appearance="outlined" style="max-width: 460px; min-width: 280px; margin: 10px;" class="mat-elevation-z4" *ngIf="this.Profile">
    
                     
          
          <mat-card-header>
          
   
          <mat-card-title>
            <img matListItemIcon *ngIf="(this.Player.ProfileImage)" [src]="'../../assets' + this.Player.ProfileImage"
              style="float: left;margin-top: -10px; height: 70px; margin-right: 10px;border-radius: 10px; border: white 1px solid;">
            <img matListItemIcon *ngIf="!(this.Player.ProfileImage)" src="../../assets/img/icons8-user-shield-48.png" style="float: left;margin-top: -10px; height: 50px;"> 
            <div style="display: flex; justify-content: space-between; width: 10; height: 70px;">
                <div style="display: inline-block;">{{ this.Player.LastName }}, {{ this.Player.FirstName }}<br/>
                  <span style="font-size: small; color: gray;">({{positionText[this.Player.Position.PlayerPositionId]}})</span>
                </div>
               
            </div>
            
          </mat-card-title>        
          <div  style=" flex-grow: 2; text-align: right; font-size: large; font-weight: bold;">
                    <div style="font-weight: normal;" style="font-size: small;">{{this.Player.PlayerNoOfTests}}/{{this.Player.ProfileNoOfTests}}</div>
                    <div class="valuecolor_{{this.Player.PlayerPointsAverage}}" style="font-size: x-large;">{{this.Player.PlayerPointsCalculated}}/{{this.Player.PlayersPointsMax}}</div>
          </div>

  </mat-card-header>
  <mat-card-content style="background-color: #303030; color: white;">
    <div style="display: flex; flex-wrap: wrap;">
      <div *ngFor="let test of Profile.Tests" class="testdiv">
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex; flex-direction: column;">
            <div style="font-size: x-small;flex-wrap: nowrap; height: 16px;">{{ test.Name }}<!-- &nbsp;<mat-icon class="outdated_warning" *ngIf="this.getWarning(test.TestId)">update</mat-icon>--></div>   
         
            <div style="font-size: x-small;flex-wrap: nowrap; display: flex; margin-right: 10px;;">
              <div class="valuediv" style="width: 24px;"><mat-icon style="color:gray;font-size: 16px;">{{ this.getTrendArrow(test.TestId) }}</mat-icon></div>
              <div class="valuediv valuecolor_{{this.filterTestResult_ranking(test.TestId)}}" >
                 {{ this.filterTestResult(test.TestId, test.ResultType) }}
                 <div class="limitsdiv" style="color:white; font-weight:normal; ">
                   {{ this.filterTestResult_DatePerformed(test.TestId)}}<mat-icon class="outdated_warning" *ngIf="this.getWarning(test.TestId)">update</mat-icon>
                 </div>
              </div>                    
            </div>        
            <div class="limitsdiv" style="color: gray;" *ngIf="this.IsThereABest(test.TestId)">PB: <b>{{ this.getBest(test.TestId)}}</b> ( {{ this.getBestDate(test.TestId) | date: "yyyy-MM-dd" }})</div> 
          </div>
          <div style="font-size: x-small;flex-wrap: nowrap; color:gray; justify-content: flex-end;" >
              
            <div style="border-left: rgb(22, 220, 255) solid 2px; text-align: right;padding-left:5px;text-align: right; color:white;">{{ this.getLimit(test.TestId,5)}}</div>
            <div style="border-left: #00ff00  solid 2px;padding-left:5px;text-align: right; margin-top:1px;color:white; ">{{ this.getLimit(test.TestId,4)}}</div>                         
            <div style="border-left: limegreen solid 2px;padding-left:5px;text-align: right;margin-top:1px;color:white;">{{ this.getLimit(test.TestId,3)}}</div>
            <div style="border-left: #FFC107 solid 2px; padding-left:5px;text-align: right;margin-top:1px;color:white;">{{ this.getLimit(test.TestId,2)}}</div>
        
          </div>       
          
        </div>

         <!--  <div style="display: flex; justify-content: space-between; ">
              <div style="font-size: x-small;flex-wrap: nowrap; height: 16px;">{{ test.Name }}&nbsp;</div>  
              <div >
                <mat-icon class="outdated_warning" *ngIf="this.getWarning(test.TestId)">update</mat-icon>
              </div>
          </div>
          <div style="display: flex;justify-content: space-between; ">
              <div style="font-size: x-small;flex-wrap: nowrap; display: flex;">
                <div class="valuediv" style="width: 24px;"><mat-icon style="color:gray;font-size: 16px;">{{ this.getTrendArrow(test.TestId) }}</mat-icon></div>
                <div class="valuediv valuecolor_{{this.filterTestResult_ranking(test.TestId)}}">{{ this.filterTestResult(test.TestId, test.ResultType) }}</div>      
              </div>          
              <div style="font-size: x-small;flex-wrap: nowrap; color:gray;">
              
                <div style="border-left: rgb(22, 220, 255) solid 2px; text-align: right;padding-left:5px;text-align: right; color:white;">{{ this.getLimit(test.TestId,5)}}</div>
                <div style="border-left: #00ff00  solid 2px;padding-left:5px;text-align: right; margin-top:1px;color:white; ">{{ this.getLimit(test.TestId,4)}}</div>                         
                <div style="border-left: limegreen solid 2px;padding-left:5px;text-align: right;margin-top:1px;color:white;">{{ this.getLimit(test.TestId,3)}}</div>
                <div style="border-left: #FFC107 solid 2px; padding-left:5px;text-align: right;margin-top:1px;color:white;">{{ this.getLimit(test.TestId,2)}}</div>
            
              </div>          
          </div>                           
         <div class="limitsdiv" style="color: gray;" *ngIf="this.IsThereABest(test.TestId)">PB: <b>{{ this.getBest(test.TestId)}}</b> ( {{ this.getBestDate(test.TestId) | date: "yyyy-MM-dd" }})</div>           -->     
      </div>

    </div>

  </mat-card-content>
  <mat-card-actions>
    
    <button mat-icon-button [routerLink]="['/testresultadd/', this.Player.PlayerId, this.Profile.ProfileId, this.Player.FirstName + ' ' + this.Player.LastName]" *ngIf="this.userService.roleMatch(['Test_Admin','Test_Performer'])" >
      <i class="material-icons">
        add
        </i>
     
    </button>
    <button  mat-icon-button (click)="openAddDialog()"  *ngIf="this.userService.roleMatch(['Test_Admin','Test_Performer'])" >
      <i class="material-icons">
        post_add
        </i>
      
    </button>
    <button mat-icon-button [routerLink]="['/playerdetails', this.Player.PlayerId, this.Profile.ProfileId, this.TeamId, this.SeasonId]" >
      <i class="material-icons">
        show_chart
        </i>
      
    </button>
     
      <button mat-icon-button [routerLink]="['/testresults/player', this.Player.PlayerId, this.Profile.ProfileId, this.TeamId]">       
        <i class="material-icons">history</i>
        
      </button>
    <!--   <button *ngIf="this._globals.isMedicalUser" mat-button [routerLink]="['/injury/add', this.Player.PlayerId, this.Player.LastName + ', ' + this.Player.FirstName, this.TeamId]">       
      <i class="material-icons">local_hospital</i>
       NEW INJURY
        
      </button> -->
      
  </mat-card-actions>
</mat-card>