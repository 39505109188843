<h1 mat-dialog-title>New parameter</h1>
<div mat-dialog-content>
 <form #newForm="ngForm" >
  <mat-form-field>
    <mat-label>Abbreviation</mat-label>
    <input matInput [(ngModel)]="data.Abbreviation" required name="abbreviation"  #abbreviation="ngModel" [disabled]="this.data.OrganizationId">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="data.Name"  name="name" #name="ngModel">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Display Name</mat-label>
    <input matInput [(ngModel)]="data.DisplayName"  name="displayname" #name="ngModel">
  </mat-form-field>
 
  <mat-form-field appearance="fill">
    <mat-label>Data type</mat-label>
    <mat-select  required [(ngModel)]="data.ValueType" name="valuetype" #valuetype="ngModel">
      <mat-option value="INTEGER">INTEGER</mat-option>
      <mat-option value="DECIMAL">DECIMAL</mat-option>    
      <mat-option value="MMSS">MM:SS</mat-option>  
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Validation</mat-label>
    <mat-select  [(ngModel)]="data.ValidationClassName" required name="validationclassname" #validationclassname="ngModel">
      <mat-option value="positive_number">Positive number </mat-option>
      <mat-option value="number">Number </mat-option>
      <mat-option value="percent">Percentage</mat-option>      
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Ranking</mat-label>¨
    <mat-select required  [(ngModel)]="data.Operator" name="Operator"  #operator="ngModel">
      <mat-option  value="<=">
        Lower value is better
      </mat-option>
      <mat-option  value="==">
        Equal to limit value
      </mat-option>
      <mat-option  value=">=">
        Higher value is better
      </mat-option>

     
    </mat-select>
  </mat-form-field>
 </form>
  
  <div style="color: #f44336; font-family: 'Roboto';">{{this.errormessage}}</div>
</div>
<div mat-dialog-actions>
  
  <button mat-button (click)="saveParameter()"  cdkFocusInitial [disabled]="name.invalid || abbreviation.invalid || valuetype.invalid || validationclassname.invalid || operator.invalid ">SAVE</button>
  <button mat-button (click)="onNoClick()">CANCEL</button>
</div>