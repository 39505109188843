import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InData } from 'src/app/profiles/add-test-to-profile-dialog/add-test-to-profile-dialog.component';
import { GameStatisticsService } from 'src/app/shared/game-statistics.service';
import { TestService } from 'src/app/shared/test.service';
import { GameStatisticsProfileLimitViewModel } from 'src/app/stats/models/statistics.model';

@Component({
  selector: 'app-config-add-to-profile-dialog',
  templateUrl: './config-add-to-profile-dialog.component.html',
  styleUrls: ['./config-add-to-profile-dialog.component.css']
})
export class ConfigAddToProfileDialogComponent implements OnInit {

  ProfileId: string;
  statsForm: UntypedFormGroup;
  limits: GameStatisticsProfileLimitViewModel[];
  limitscalculated: GameStatisticsProfileLimitViewModel[];

  selectedPlain: GameStatisticsProfileLimitViewModel[];
  selectedCalculated: GameStatisticsProfileLimitViewModel[];

  constructor(
    public dialogRef: MatDialogRef<ConfigAddToProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InData,
    public  _statService: GameStatisticsService) { 

  }

  ngOnInit() {

    this.ProfileId = this.data.ProfileId;
    this.getAvailableParameters(this.ProfileId);
    this.getAvailableCalculatedParameters(this.ProfileId);

    
  }

  onSelection(e, v) {
    //console.log("Selection list");
    this.selectedPlain = [];
    for(let a of v) {
      this.selectedPlain.push(a.value);
    }
    //console.log(this.selectedPlain);
  }

  onSelectionCalc(e, v) {
    //console.log("Selection list");
    this.selectedCalculated = [];
    for(let a of v) {
      this.selectedCalculated.push(a.value);
    }
    //console.log(this.selectedCalculated);
  }

  onSubmitForm(){

    console.log(this.selectedPlain);
    console.log(this.selectedCalculated);
    if(this.selectedPlain!=undefined){
      this._statService.addParamatersToProfile(this.ProfileId, this.selectedPlain as GameStatisticsProfileLimitViewModel[]).subscribe(res => {
        console.log(res);
      });
    }
    
    if(this.selectedCalculated!= undefined){
       this._statService.addParamatersCalculatedToProfile(this.ProfileId, this.selectedCalculated as GameStatisticsProfileLimitViewModel[]).subscribe(res => {
      console.log(res);
    });
    }
   
    //return this.statsForm.value;

    
    
    
    this.dialogRef.close(true);

   

    
  }


  getAvailableParameters(ProfileId: string){
    this._statService.getParametersNotUsedInProfile(this.data.ProfileId).subscribe((limits: GameStatisticsProfileLimitViewModel[])=>{
      this.limits = limits;
      console.log(this.limits);
     /*  this.statsForm = new FormGroup({    
        limits: new FormControl(limits)
      }) */
    
    });
    
  }

  getAvailableCalculatedParameters(ProfileId:string){
    this._statService.geParametersCalculatedNotUsedInProfile(this.data.ProfileId).subscribe((limits: GameStatisticsProfileLimitViewModel[])=>{
      this.limitscalculated= limits;
      this.statsForm = new UntypedFormGroup({    
        limitscalculated: new UntypedFormControl(limits)
      })
    
    });
     
   }

}
