<h2 mat-dialog-title>ADD TO SCHEDULE</h2>

<mat-dialog-content style="font-family: Roboto;">

    <form [formGroup]="_form" >
    <label>{{this._gameDate | date }}</label><br/>
    <label>{{this._gameTime | date: 'HH:mm' }}</label><br/>
   <label style="font-weight: bold; font-size: large;">{{this._homeTeam}} - {{this._awayTeam}}</label><br/>
   <br/>
   
   
    <mat-form-field appearance="fill">
        <mat-label >Note</mat-label>
        <textarea matInput  formControlName="_comment" rows="3" style="width: 100%;" ></textarea>
    </mat-form-field><br/>
    <mat-checkbox  formControlName="_private" >Private</mat-checkbox>

</form> 
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="add()">ADD TO SCHEDULE</button>
    <button mat-raised-button (click)="cancel()">CANCEL</button>
</mat-dialog-actions>
