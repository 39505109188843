import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stream-objectives',
  templateUrl: './stream-objectives.component.html',
  styleUrls: ['./stream-objectives.component.css']
})
export class StreamObjectivesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
