import { Component, Input, OnInit } from '@angular/core';
import { Player } from 'src/app/scouting/models/PlayerModels';

@Component({
  selector: 'app-player-details-section',
  templateUrl: './player-details-section.component.html',
  styleUrls: ['./player-details-section.component.css']
})
export class PlayerDetailsSectionComponent implements OnInit {

  @Input() Player: Player;

  constructor() { }

  ngOnInit(): void {
    console.log(this.Player);
  }

  
  fullname(){
    return this.Player.LastName + ", " + this.Player.FirstName;
  }

}
