<h1 mat-dialog-title>New parameter</h1>
<div mat-dialog-content>
 
  <mat-form-field  appearance="fill">
    <mat-label>Abbreviation</mat-label>
    <input required matInput [(ngModel)]="data.Abbreviation" maxLength="15">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Display name</mat-label>
    <input  matInput [(ngModel)]="data.Name" maxLength="50">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <mat-label>Formula</mat-label>
    <textarea required matInput [(ngModel)]="data.Formula" 
    cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="5"
    ></textarea>
  </mat-form-field>
 
  <mat-form-field appearance="fill">
    <mat-label>Data type</mat-label>
    <mat-select  required [(ngModel)]="data.ValueType" >
      <mat-option value="INTEGER">INTEGER</mat-option>
      <mat-option value="DECIMAL">DECIMAL</mat-option>    
      <mat-option value="MMSS">MM:SS</mat-option>  
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Ranking</mat-label>¨
    <mat-select [(ngModel)]="data.Operator" id="Operator" required>
      <mat-option  value="<=">
        Lower value is better
      </mat-option>
      <mat-option  value="==">
        Equal to limit value
      </mat-option>
      <mat-option  value=">=">
        Higher value is better
      </mat-option>
  
     
    </mat-select>
  </mat-form-field>
  
  <div style="color: #f44336; font-family: 'Roboto';">{{this.errormessage}}</div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="createParameter()"  color="primary">SAVE</button>
  <button mat-button (click)="onNoClick()">CANCEL</button>
 
</div>