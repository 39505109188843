
<div class="app-page">
    <h2 mat-dialog-title style="font-family: Roboto;">
      <mat-icon>table_chart</mat-icon>
      ADD WORKOUT
    </h2>
  <form  [formGroup]="assignedWorkoutForm" (ngSubmit)="save()" autocomplete="off">
   <!--  <mat-form-field appearance="fill"  style="width: 400px;">
      <mat-label>Program assignment title</mat-label>
      <input matInput placeholder="Enter title" formControlName="Title" id="Title" autofocus  /> 
     
    </mat-form-field>
    <br/> -->
    <mat-form-field appearance="fill"  style="width: 400px;" color="primary">
        <mat-label>Select workout</mat-label>
        <mat-select formControlName="WorkoutId"  >
            <mat-option>-</mat-option>
            <mat-option *ngFor="let wo of workouts" [value]="wo.WorkoutId">{{wo.Name}}</mat-option>            
        </mat-select>
    </mat-form-field>
    <br/>
 
    <mat-form-field appearance="fill">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="PlannedDate">
        <mat-hint>YYYY/MM/DD</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    <br/>
    


       <div style="font-family: Roboto;">
      Selecting a team assigns the program to all players in the team.<br>
      Assign program to specific players by adding the in the Assigned players field.<br/>
      You can also use any method, or both.
    </div>
    <mat-form-field appearance="fill"  style="width: 400px;" color="accent">
        <mat-label>Assign to team</mat-label>
        <mat-select formControlName="TeamId"  >
            <mat-option>-</mat-option>
            <mat-option *ngFor="let team of teams" [value]="team.TeamId">{{team.team.Name}} - {{team.team.Level}} </mat-option>            
        </mat-select>
    </mat-form-field>
    <br/>
    <div style="display: flex; flex-direction: row;">
      <mat-form-field style="max-width: 480px; min-width: 400px;" appearance="fill" formArrayName="AssignedPlayers" >
        <mat-label>Assigned players</mat-label>
        <mat-chip-grid #chipList aria-label="Fruit selection">
          <mat-chip-row
          *ngFor="let player of this.assignedPlayers?.controls; let i= index;"
          [formGroupName]="i"
          (removed)="remove(player.get('PlayerId').value)"
          >
          <mat-icon>person</mat-icon>
          {{player.get('LastName').value}}, {{player.get('FirstName').value}}
            <button matChipRemove >
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
          <input
          placeholder="New player..."
          #playerInput
          [formControl]="playerCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"
        >
        </mat-chip-grid>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let player of players" [value]="player">
            {{player.Player.LastName}},{{player.Player.FirstName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
   
    </div>
     
   <br/>
    <button mat-button color="primary"  type="submit" [disabled]="!assignedWorkoutForm.valid">CREATE</button>

    <button type="button" mat-button  (click)="onCancel()">CANCEL</button>
    <!-- <div  *ngFor="let Video of this.myVideos; let i=index">  
      <video width="270" height="220"   controls disabled="true"  (click)="toggleVideo()" #videoPlayer>   
        <source [src]="Video.URL" type="video/mp4">   
      </video>  
    </div>   -->
      
    

    
  </form>
      
 
    
</div>
