<mat-card appearance="outlined" style="max-width: 340px; width: 340px;  margin: 10px;"   >
     
<mat-card-header>
    <!-- <img mat-card-avatar  [src]="'../../assets/img/' + this.Report.PlayerCurrentTeamIcon"     > -->
    <mat-icon mat-card-avatar style="font-size: 32px; padding-bottom: 32px;;" >format_list_numbered</mat-icon>
    <mat-card-title style="max-width: 245px; overflow-x: hidden;">
      {{List.Description}}
    </mat-card-title>
    <mat-card-subtitle>
      <mat-icon style="font-size: small; height: 13px;width: 13px;">person</mat-icon>&nbsp;{{List.CreatedByLastName}}, {{List.CreatedByFirstName}}<br/>
      <mat-icon style="font-size: small; height: 13px;width: 13px;">category</mat-icon>&nbsp;{{List.Category}}
    </mat-card-subtitle>

</mat-card-header>
<mat-card-content style="margin-bottom: 0px; background-color: #303030; min-height: 95px;" >
  <div *ngFor="let player of List.PlayerListPlayer; let i= index;">
    <span>{{i+1}}. {{player.LastName}}, {{player.FirstName}} </span>
  </div>
  <div *ngIf="List.NumberOfPlayers>5" style="color: gray">
    ....{{List.NumberOfPlayers-5}} more
  </div>
  



</mat-card-content>
<mat-card-actions>
  <a mat-button routerLink="/scouting/list/{{List.ListId}}">OPEN</a>
  <a mat-button (click)="this.onCopyListClicked.emit(List)">COPY</a>
  <a mat-button (click)="this.onArchiveListClicked.emit(List)" *ngIf="List.IsMyList || List.CanEdit">ARCHIVE</a>
  <a mat-button (click)="this.onDeleteListClicked.emit(List)" *ngIf="List.IsMyList || List.CanEdit">DELETE</a>
</mat-card-actions>
</mat-card> 



