<div class="app-page" style="margin-left: 20px;">
  <form [formGroup]="searchForm">
    <mat-form-field  appearance="fill" class="smallinput">
      <mat-label>Last name</mat-label>
      <input matInput formControlName="LastName" placeholder="Last name"  autocomplete="off"> 
      <mat-hint>Use * as wildcard</mat-hint>
    </mat-form-field>&nbsp;
    <mat-form-field  appearance="fill" class="smallinput" >
      <mat-label>First name</mat-label>
      <input matInput formControlName="FirstName" placeholder="First name"  autocomplete="off" >
      <mat-hint>Use * as wildcard</mat-hint>
    </mat-form-field>&nbsp;
      <mat-form-field  appearance="fill" class="smallinput">
        <mat-label>Positions</mat-label>
        <mat-select formControlName="PositionIds" id="PositionIds" multiple   class="smallinput">
          <mat-option value="1">Goaltender</mat-option>
          <mat-option value="2">Defender</mat-option>
          <mat-option value="4">Forward</mat-option>
          <mat-option value="5">Center</mat-option>
        </mat-select>
      </mat-form-field>

      <a mat-button (click)="this.searchPlayers()" [disabled]="searchForm.invalid">SEARCH</a>
  </form>
  <br/>
    


    <table mat-table [dataSource]="dataSource" matSort style="margin-left: 20px; width: 100%;" *ngIf="dataSource" >

        <ng-container matColumnDef="LastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> LastName </th>
            <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.LastName}} </td>
          
          </ng-container>
        <ng-container matColumnDef="FirstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> FirstName </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.FirstName}} </td>
       
        </ng-container>     
        <ng-container matColumnDef="DateOfBirth">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date of birth </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.DateOfBirth | date:'yyy-MM-dd'}} </td>
       
        </ng-container>  
        <ng-container matColumnDef="Weight">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Weight </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Weight}} </td>
       
        </ng-container>    
        <ng-container matColumnDef="Height">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Height </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;" > {{element.Height}} </td>
       
        </ng-container>    
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
          <td mat-cell *matCellDef="let element" style="font-size: 12px;" ><a mat-button [routerLink]="['/scouting/playerdetails', element.LifelineScoutingId]">VIEW</a> </td>
       
        </ng-container>    
            
     
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px; font-size: 12px;;"></tr>
      <!--   <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" style="height: 24px;"></tr> -->
      </table>
      <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons matSort style="margin-left: 20px;"></mat-paginator>
</div>

