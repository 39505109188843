<h2 mat-dialog-title>New test</h2>
<div mat-dialog-content>


<form  [formGroup]="testForm" (ngSubmit)="save()">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput placeholder="Enter name" formControlName="Name" id="Name" required/> 
    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
    <!-- <mat-hint>Hint</mat-hint> -->
  </mat-form-field>
  <mat-form-field>
    <mat-label>Description</mat-label>
    <textarea matInput placeholder="Describe the test" formControlName="Description" id="Description"></textarea>  
    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
    <!-- <mat-hint>Hint</mat-hint> -->
  </mat-form-field>
  
  <mat-form-field>
    <mat-label>Value type</mat-label>
    <mat-select formControlName="ResultType" id="ResultType">
      <mat-option  value="10">
        Integer
      </mat-option>
      <mat-option  value="20">
        Numeric, 1 decimals
      </mat-option>
      <mat-option  value="21">
          Numeric, 2 decimal
        </mat-option>
      <mat-option  value="30">
        Time (hh:mm:ss)
      </mat-option>
      <mat-option  value="40">
        Text
      </mat-option>
      
    </mat-select> 
    
    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
    <!-- <mat-hint>Hint</mat-hint> -->
  </mat-form-field>
  <mat-form-field>
    <mat-label>Value unit</mat-label>
    <mat-select formControlName="ResultUnit" id="ResultUnit">
      <mat-option  value="reps">
        reps
      </mat-option>
      <mat-option  value="kg">
        kg
      </mat-option>              
      <mat-option  value="m">
        m
      </mat-option>
      <mat-option  value="cm">
        cm
      </mat-option>
      <mat-option  value="hh:mm:ss">
          hh:mm:ss
      </mat-option>
      <mat-option  value="s">
        seconds
      </mat-option>
      <mat-option  value="-">
        No unit
    </mat-option>
      
     
    </mat-select>
    
    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
    <!-- <mat-hint>Hint</mat-hint> -->
  </mat-form-field>

  <mat-form-field>
    <mat-label>Ranking</mat-label>¨
    <mat-select formControlName="Operator" id="Operator">
      <mat-option  value="<=">
        Lower value is better
      </mat-option>
      <mat-option  value="==">
        Equal to limit value
      </mat-option>
      <mat-option  value=">=">
        Higher value is better
      </mat-option>
      <mat-option  value="NO">
        No ranking
      </mat-option>
     
    </mat-select>
    
    <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
    <!-- <mat-hint>Hint</mat-hint> -->
  </mat-form-field>
  
  <button mat-button color="primary" type="submit" [disabled]="!testForm.valid">CREATE</button>
  <button type="button" mat-button  (click)="onCancel()">CANCEL</button>
</form>

</div> 