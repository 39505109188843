import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GameService } from 'src/app/load/services/game.service';
import { GameEventOverviewViewModel } from '../models/gameevent';
import { LoadEventService } from '../services/load-event.service';
declare var google:any;
import * as _moment from 'moment-timezone';
import { tick } from '@angular/core/testing';

@Component({
  selector: 'app-load-game-overview',
  templateUrl: './load-game-overview.component.html',
  styleUrls: ['./load-game-overview.component.css']
})
export class LoadGameOverviewComponent implements OnInit {

  
  TeamId: string;
  gameService: GameService;
  games: GameEventOverviewViewModel[];
  isBusy: boolean;
  
  constructor(private _gameService: GameService, public _route: ActivatedRoute,   public _router: Router,    public _eventService: LoadEventService) {
    this.isBusy=true;
    this.gameService = _gameService;    
    this._route.params.subscribe(params => {
      this.TeamId = params['TeamId'];    
      this.LoadGames(this.TeamId) ;
    });
  }

  ngOnInit() {
  }

  LoadGames(TeamId: string){
    this.isBusy=true;
    this.gameService.getPastGamesForTeam(TeamId).subscribe( g => {
      console.log(g);
        this.games = g;
        this.generateChartGameRanking(g);
        this.isBusy=false;
    });
  }

  
  generateChartGameRanking(_games): void{

   

   

    google.charts.load('current', {'packages':['corechart']});
    google.charts.setOnLoadCallback(drawChart);        
    

      //this.datajson = datahttp;

      
       function drawChart() {
        var minDate = new Date();
        var _data = new google.visualization.DataTable();
        _data.addColumn('date', 'Date'); 
        _data.addColumn('number', 'Players average'); 
        //_data.addColumn('number', 'Coaches average'); 
        //_data.addColumn({type:'string', role:'annotation'}); // annotation role col.
    
        _games.forEach((obj,index)=>{
          
          minDate = new Date(obj.EventDate);
                          
          var _row = [];
          if(obj.OverallAvg==0) 
            obj.OverallAvg=null;
          
         

          _row.push([new Date(obj.EventDate), obj.OverallAvg]);
          _data.addRows(_row);
          
         
          console.log(_row);
        });
        // Create the data table.
        console.log(minDate);
        //var data = new google.visualization.DataTable(_data);

        

        //console.log(data);
        
        //minDate.setMonth(minDate.getMonth()-2);

          //Tcikmarks on mondays
          var tickMarks = [];
          var maxDate = new Date();
          for (var d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
            if(d.getDay()==1){
              tickMarks.push(new Date(d.getFullYear(), d.getMonth(), d.getDate()));
            }
            
            //console.log(tickMarks);
        }

        var options = {
          title: 'Player assessment - average',
          legend: { position: 'bottom', textStyle: {color: 'white', fontSize: 12} },
          titleTextStyle: {color: 'white'},
          backgroundColor: '#303030',
          chartArea: {width: '95%'},
          annotation: { 0: {style: 'letter'}},
          annotations: {
            textStyle: {
              //auraColor: '#ccc',
              bold: true,
              color: 'white',
              fontSize: 10,
              opacity: 0.3
            }
          },
          displayAnnotations: true,
          height: 200,         
          colors: ['#FF5722','#FFC107','#4CAF50','#2E7D32','00BCD4'],
          hAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines: { color: 'gray' }, minValue: minDate, maxValue: new Date(), ticks: tickMarks, format: 'd/M'},
          vAxis: { textStyle: { color: 'white'} , baselineColor: 'white', gridlines: { color: 'gray' }, minValue: 0, maxValue: 5, ticks: [0,1,2,3,4,5] },

        };

        

        var chart = new google.visualization.LineChart(document.getElementById('GameRanking'));
        chart.draw(_data, options);
      }          

    
  }

}
