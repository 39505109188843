<div class="app-page" style="margin-left: 30px;">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        1. Tests
      </mat-card-title>
    </mat-card-header>
      <mat-card-content>
        The test is described by a test name, result unit, result type and ranking type.<br/>
        <br/>
        <b>Result unit</b><br/>
        The unit for the result. Examples: kg,cm, hh:mm:ss.<br/>
        <br/>

        <b>Result type</b><br/>
        Describes what values are allowed when entering a result. Examples are<br/>
        <b>Integer</b> - only integer values allowed <br/>
        <b>Numeric</b> - number with decimals allowed (85,5).<br>
        <b>Time</b> - only time values allowed (00:10:23)<br/>
        <b>Text</b> - any text can be entered<br/>

        Purpose is to validate input from user to avoid incorrect result data.<br/>
        <br/>
        <b>Ranking type</b><br/>
        "Higher value is better" gives results with higher value a higher ranking than results with a lower value.<br/>
        "Lower value is better" gives results with lower value a higher ranking than results with a higher value.<br/>
        "No ranking" means that no ranking calculation will be done, and the result will not be part of players ranking score.Typcially uesd for body measures like weight.<br/>
        "Equal to limit values" means that the result has to be a perfect match with the result limit to give ranking score.<br/>
        <br/>
        <br/>
        Dalecarlia Scouting provides a number of predefined tests. They can not be deleted or changed.<br/>
      </mat-card-content>  
    
  </mat-card>
  <br/>
  <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title>
          2a. Create profiles
        </mat-card-title>
      </mat-card-header>
        <mat-card-content>
          The profile consists of a number of tests and test limits. <br/>
          <br/>
          When creating a profile you first select tests to include in the profile.<br/>
          Next step is to set test limits for each test in profile, which is the foundation for ranking score.
          <br/>
          The profile is not complete until you have saved the limits for the profile.
        </mat-card-content>  
      
    </mat-card>
    <br/>
    <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>
            2b. Profiles - set test limits
          </mat-card-title>
        </mat-card-header>
          <mat-card-content>
            The test limits decide how the test is scored.<br/>
            <br/>
            Limits can be give for 4 levels<br/>
            <b>OK</b> - scores 2 points, displayed as yellow<br/>
            <b>Good</b> - scores 3 points, displayed as green<br/>
            <b>Very Good</b> - scores 4 points, displayed as sharp green<br/>
            <b>Excellent</b> - scores 5 points, displayed as sharp blue<br/>
            <br/>
            The limit for level OK is required. If a level is left empty it will not be evaluated when calculating scores. <br/>
            <br/>
            <b>Special case - Limts with multipliers</b><br/>
            If you want the test limit to relate to another test, like body weight, you can set a "mulitplier" by clicking the settings cog-wheel.
            Value entered in limit must be a decimal number that is the multiplier.

           
          </mat-card-content>  
        
      </mat-card>
      <br/>
      <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>
              3. Teams - set profile for team
            </mat-card-title>
          </mat-card-header>
            <mat-card-content>
           Last step is to assign a profile to each team.<br/>
           <br/>
           You're done configuring Dalecarlia Scouting Test Center. Happy testing!<br/>
           <br/>
            </mat-card-content>  
          
        </mat-card>
</div>
