import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  loginForm: UntypedFormGroup;
  requestSuccess: boolean = false;
  requestError: boolean = false;

  constructor(private UserService: UserService) { }

  ngOnInit() {

  }

  forgotPassword(UserName: string){
    //console.log(Email);
    this.UserService.forgotPassword(UserName).subscribe(
      {
        next: (v) => (this.requestSuccess=true),
        error: (e)=> (this.requestError=true),
        complete: () => (this.requestSuccess=true)
      }
    );

  }

}
