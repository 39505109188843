<div class="playerassessment">
    <div style="display: flex; padding-top: 5px; margin-left: 10px;width: 100%; border-radius: 4px;">
        <div >
          <div style="font-size: medium; font-weight: bold;">
              <a routerLink="/player/details/{{event.PlayerId}}" style="color:#96bdff;text-decoration: none;">
                {{event.LastName}}  {{event.FirstName}} 
              </a>
          </div>
          <div style="font-size:small; font-family: Roboto;">{{event.TimeAgoText}} - ({{event.UpdatedTimestamp | date:'yyyy-MM-dd HH:MM:ss'}})<br></div>
        </div>
    </div>
    <!-- <div style=" background: linear-gradient(to right, #888888, #303030); height: 2px;">

    </div> -->
    <div style="margin-left: 10px; margin-top: 5px; margin-bottom: 5px;  ">
        <mat-chip-listbox class="chiplist" >
            <mat-chip-option  selected="true"   [style.background-color]="availabilityColors[this.event.Availability]" style="color: white;">
                {{availabilityText[this.event.Availability] }}
            </mat-chip-option>
            <mat-chip-option  [style.background-color]="scaleColors[this.event.Freshness]" [style.color]="scaleTextColors[this.event.Freshness]" class="smallchip">
                <mat-icon class="smallicon">directions_run</mat-icon> {{this.event.Freshness}}
            </mat-chip-option>
            <mat-chip-option  [style.background-color]="scaleColors[this.event.Confidence]" [style.color]="scaleTextColors[this.event.Confidence]" class="smallchip">
                <mat-icon class="smallicon" >highlight</mat-icon> {{this.event.Confidence}}
            </mat-chip-option>
            <mat-chip-option  [style.background-color]="scaleColors[this.event.Sleep]" [style.color]="scaleTextColors[this.event.Sleep]" class="smallchip">
                <mat-icon class="smallicon">hotel</mat-icon> {{this.event.Sleep}}
            </mat-chip-option>
            <mat-chip-option  [style.background-color]="painScaleColors[this.event.Muscles]" [style.color]="painScaleTextColors[this.event.Muscles]" class="smallchip">
                <mat-icon class="smallicon" >flash_on</mat-icon> {{this.event.Muscles}}
            </mat-chip-option>
            <mat-chip-option  [style.background-color]="painScaleColors[this.event.Joints]" [style.color]="painScaleTextColors[this.event.Joints]" class="smallchip">
                <mat-icon class="smallicon" >flash_on</mat-icon> {{this.event.Joints}}
            </mat-chip-option>
    </mat-chip-listbox>
    <mat-divider></mat-divider>
    <mat-chip-listbox class="chiplist" >
        <mat-chip-option  selected="true" *ngIf="this.event.HasFever"   [style.background-color]="'#FF5722'" [style.color]="'white'" class="longsmallchip">
            <mat-icon  >sick</mat-icon>FEVER
        </mat-chip-option>
        <mat-chip-option  *ngIf="this.event.HasFever"  [style.background-color]="'#FF5722'" [style.color]="scaleTextColors[this.event.Freshness]" class="longsmallchip">
            <mat-icon  >device_thermostat</mat-icon>&nbsp;
            {{this.event.BodyTemperature}}
        </mat-chip-option>
      
        <mat-chip-option  selected="true" *ngIf="this.event.ExposedToContagion"   [style.background-color]="'#FF5722'" [style.color]="'white'" class="longsmallchip">
            <mat-icon  >coronavirus</mat-icon>&nbsp;
            EXPOSED
        </mat-chip-option>
        
</mat-chip-listbox>
<mat-chip-listbox>
    <mat-chip-option  selected="true" *ngIf="this.event.HasSicknessSymptoms"   [style.background-color]="'#FF5722'" [style.color]="'white'" class="longsmallchip">
        SYMPTOMS
    </mat-chip-option>
</mat-chip-listbox>
    
        <div>
            {{this.event.SicknessSymptoms}}
        </div>
    </div>
</div>
