<div style="background-color: #0480ba; " class="statusdiv" *ngIf="this.Status=='EXCEEDED'">
    EXCEEDED
</div>
<div style="background-color: green; " class="statusdiv"  *ngIf="this.Status=='ON_TRACK'">
    ON TRACK
</div>
<div style="background-color: #cbb202;" class="statusdiv"  *ngIf="this.Status=='AT_RISK'">
    AT RISK
</div>
<div style="background-color: #d80000;" class="statusdiv"  *ngIf="this.Status=='OFF_TRACK'">
    OFF TRACK
</div>
<div style="background-color: gray ;" class="statusdiv" *ngIf="this.Status==null">
    NOT SET
</div>