<mat-card appearance="outlined" style="max-width: 330px; min-width: 275px; margin: 10px;" class="mat-elevation-z4">
    <mat-card-header>
     <div mat-card-avatar class="example-header-image">
            <mat-icon>
                quiz
            </mat-icon>
        </div> 
       
    <!-- <img mat-card-avatar *ngIf="!(this.Player.ProfileImage)" src="../../assets/img/icons8-user-shield-48.png">     -->
    <mat-card-title>
        <mat-label style="font-size: medium ;">{{ GameAssessment.Name }}</mat-label>
    </mat-card-title>       
    <mat-card-subtitle>
        <mat-label style="font-size: medium ;">Used {{GameAssessment.UsedCount}} times</mat-label>
    </mat-card-subtitle> 
</mat-card-header>
<mat-card-content >
    <table>
        <tr>
            <th style="min-width: 70px; text-align: left;">Group</th>
            <th  style="min-width: 70px; text-align: left;">Parameter</th>
        </tr>
        <ng-container *ngFor="let group of GameAssessment.ParameterGroups">
            <tr>
                <td>{{group.Name}}</td>
                <td></td>
            </tr>
            <tr *ngFor="let parameter of group.Parameters">
                <td></td>
                <td>
                    {{parameter.Name}}
                </td>
            </tr>
        </ng-container>
        
    </table>
    <br/>
   
  
    
</mat-card-content>
<mat-card-actions>
  <!-- <button mat-button [routerLink]="['/testview/', TestView.TestViewId]">EDIT</button>
  <button mat-button (click)="deleteView()">DELETE</button> -->
</mat-card-actions>
</mat-card>

