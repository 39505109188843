import { Component, OnInit, Input } from '@angular/core';
import { PlayerViewModel } from 'src/app/shared/models/player.model';
import { PlayerListViewModel } from '../../models/PlayerModels';

@Component({
  selector: 'app-team-roster',
  templateUrl: './team-roster.component.html',
  styleUrls: ['./team-roster.component.css']
})
export class TeamRosterComponent implements OnInit {
  @Input() Roster: PlayerListViewModel[];
  @Input() TeamIcon: string;
  @Input() GameId: string;
  constructor() { }

  ngOnInit() {
    console.log(this.GameId);
  }


  getGoalies(){
    return this.Roster.filter(function (el){
      return el.PlayerView.PositionId==1;
    });
  }

  getDefenders(){
    return this.Roster.filter(function (el){
      return el.PlayerView.PositionId==2 || el.PlayerView.PositionId==3;
    });
  }

  getForwards(){
    return this.Roster.filter(function (el){
      return el.PlayerView.PositionId>3;
    });
  }

}
