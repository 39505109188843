

<mat-card appearance="outlined" STYLE="max-width: 500px; margin: 10px;">
    <mat-card-header>   
        <img mat-card-avatar src="../../assets/img/shield.png">   
      <mat-card-title> {{this.Team.Name }}</mat-card-title>
      <mat-card-subtitle> {{this.Team.Level }} ({{this.Team.Gender}})</mat-card-subtitle>
    </mat-card-header>    
    <mat-card-content style="background-color: #303030;">
    
        <div style="font-size: 15px; margin-left: 20px;">  <!--  <mat-icon style="vertical-align: bottom;">assignment</mat-icon> -->PHYSICAL PROFILES</div>
        <div style="color: gray;margin-left: 20px;"  >Physical profiles contains tests and limits used to grade the physical profile. </div>
        <table style="margin-left: 20px;">
          <tr *ngFor="let prof of this.Team.Profiles">
            <td style="min-width: 120px;">{{prof.PositionText}}</td>
            <td style="min-width: 120px;" >
              <img style="height: 24px; " src="../../assets/img/icons8-scorecard-48.png" >
              <!--  <mat-icon style="vertical-align: bottom;">assignment</mat-icon> -->
               {{prof.TestProfileName}}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <button color="primary" mat-button (click)="setProfile(this.Team.TeamId)">
                <!-- <mat-icon>assignment</mat-icon> -->
                 SET PROFILE
              </button>
            </td>
          </tr>
        </table>
   

        <mat-divider></mat-divider>

        <div style="font-size: 15px; margin-left: 20px;">   <!-- <mat-icon style="vertical-align: bottom;">table_chart</mat-icon> -->STATISTICS </div>
        
        <div style="color: gray;  margin-left: 20px;" ><b>Profile</b> contains limits used to grade the statistics. </div>
        <div style="color: gray;  margin-left: 20px;" ><b>Player view</b> decides what statistics the player can see. </div>

        <table style="margin-left: 20px;">
          <tr>
            <td style="width: 100px;">
              
            </td>
            <td>
                PROFILE
            </td>
            <td >
                PLAYER VIEW
            </td>
          </tr>
        
        
        
          <tr *ngFor="let pos of this.Positions">
              <td style="width: 120px;">{{pos.Text}}</td>
              <td style="width: 120px;">
               <!--  <mat-icon *ngIf="getStatsProfilePerPosition(pos.PlayerPositionId)!='-'" style="vertical-align: bottom;">table_chart</mat-icon> -->
               <img style="height: 24px; " src="../../assets/img/icons8-scorecard-48.png" >
                {{ getStatsProfilePerPosition(pos.PlayerPositionId) }}
              </td>
              <td>
                <mat-icon *ngIf="getTeamPlayerStatisticsView(pos.PlayerPositionId)!='-'" style="vertical-align: bottom;">table_chart</mat-icon>
                 {{ getTeamPlayerStatisticsView(pos.PlayerPositionId) }}
              </td>
              
          </tr>
          <tr>
            <td>

            </td>
            <td>
               <button color="primary" mat-button (click)="setStatisticsProfile(this.Team.TeamId)">        
                SET PROFILE
              </button>
            </td>
            <td>
               <button color="primary" mat-button (click)="setStatisticsPlayerViewProfile(this.Team.TeamId)">
                <!--  <mat-icon>table_chart</mat-icon> -->
                  SET PLAYER VIEW
                </button>
            </td>
          </tr>
        </table>
       
        <mat-divider></mat-divider>

        <div style="font-size: 15px; margin-left: 20px;"> GAME ASSESSMENT </div>
        <table style="margin-left: 20px;">
          <tr>
            <td style="width: 150px; margin-left: 20px;">
              Game assessment 
            </td>
            <td>
              <ng-container *ngIf="this.Team.GameAssessment">
                 <mat-icon style="vertical-align: bottom;">table_chart</mat-icon>
                 {{this.Team.GameAssessment.Name}}
              </ng-container>  
             
            </td>
           <td>
            <button color="primary" mat-button (click)="setGameAssessment(this.Team.TeamId)">        
              EDIT
            </button>
           </td>
          </tr>
          <tr>
            <td>

            </td>
            <td>
              
            </td>
          </tr>
        </table>
      
    </mat-card-content>
    <mat-card-actions>
     <!--  <button mat-button (click)="setProfile(this.Team.TeamId)">
        <mat-icon>assignment</mat-icon>
        TEST
      </button> -->
     <!--  <button mat-button (click)="setStatisticsProfile(this.Team.TeamId)">
        <mat-icon>table_chart</mat-icon>
        STATS
      </button> -->
    <!--   <button mat-button [routerLink]="['/playersteamseason', this.Team.TeamId]">PLAYERS PER SEASON</button> -->

      <a  mat-button [routerLink]="['/playersteamseason', this.Team.TeamId]"  >
        <mat-icon>group</mat-icon>
        &nbsp;ROSTER
      </a>
      <a  mat-button [routerLink]="['/settings/team', this.Team.TeamId]"  >
        <mat-icon>settings_applications</mat-icon>
        &nbsp;SETTINGS
      </a>
    </mat-card-actions>
  </mat-card>
