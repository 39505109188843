<div class="mat-elevation-z8"  class="DS_Teamroster">
        <div >
                <img style="height: 48px;" src="../../assets/img/{{this.TeamIcon}}" />ROSTER
        </div>
    <app-text-divider [DividerText]="'GOALTENDERS'"></app-text-divider>
    
            <app-team-roster-player *ngFor="let player of this.getGoalies()" [Player]="player" [GameId]="this.GameId"></app-team-roster-player>
         
            
   
    
    <app-text-divider [DividerText]="'DEFENDERS'"></app-text-divider>
        <app-team-roster-player *ngFor="let player of this.getDefenders()" [Player]="player" [GameId]="this.GameId"></app-team-roster-player>
  
           <!--  <app-team-roster-player ></app-team-roster-player>  <app-team-roster-player ></app-team-roster-player>  
   
            <app-team-roster-player ></app-team-roster-player>  <app-team-roster-player ></app-team-roster-player>  
  
            <app-team-roster-player ></app-team-roster-player>  <app-team-roster-player ></app-team-roster-player>  
    
            <app-team-roster-player ></app-team-roster-player>  <app-team-roster-player ></app-team-roster-player>   -->
  
  <app-text-divider [DividerText]="'FORWARDS'"></app-text-divider>
   
        <app-team-roster-player *ngFor="let player of this.getForwards()" [Player]="player" [GameId]="this.GameId"></app-team-roster-player>


</div>