<h1 mat-dialog-title style="font-family: Roboto;">SET PLAYER VIEW</h1>
<div mat-dialog-content >
  <div style="font-family: Roboto;">
    Select the position you want to update, then select the view.
</div>
  <form id="statsPlayerView">

    <mat-form-field>
      <mat-label>Select position</mat-label>
      <mat-select [(value)]="PlayerPositionId"  >
        <mat-option *ngFor="let p of this._positions" [value]="p.PlayerPositionId" (onSelectionChange)="change($event)">{{ p.Text }}</mat-option>        
      </mat-select>   
    </mat-form-field>
    &nbsp;
    <mat-form-field>
      <mat-label>Select view</mat-label>
      <mat-select [(value)]="GameStatisticsViewId"   >
        <mat-option value="">No view</mat-option>
        <mat-option *ngFor="let v of this._views" [value]="v.GameStatisticsViewId">{{ v.Name }}</mat-option>        
      </mat-select>     
    </mat-form-field>
    
  </form>

 </div>
 <div mat-dialog-actions>
    <button mat-raised-button color="primary"  (click)="onCloseConfirm()"   cdkFocusInitial>SAVE</button>
   <button mat-button (click)="onNoClick()">CANCEL</button>
 </div>