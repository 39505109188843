<div style="width: 100%; display: block; ">
    
    <table style="width: 100%; ">
        <tr>
            <td>
                <mat-icon style="font-size: 27px;">track_changes</mat-icon>
            </td>
            <td style="width: 300px; overflow-x: visible;">
                <div style="font-family: Roboto; max-width: 300px; flex-grow: 4;">        
                    {{this.Objective.Title}}<br/>
                    
                </div>
            </td>
            <td style="width: 30px;">
                <mat-icon style="font-size: 24px;">person</mat-icon>
            </td>
            <td style="font-family: Roboto; width: 200px;">
                {{this.Objective.OwnerInfo.LastName}},
                {{this.Objective.OwnerInfo.FirstName}}
            </td>                                   
            <td>
                <div style="display: flex; flex-direction: row; padding: 2px ;font-family: Roboto; align-items: center; ">
                    
                    <div style="width: 125px;">
                        {{this.Objective.TargetDate | date }}
                    </div>
                    <app-objective-status-div [Status]="this.Objective.Status"></app-objective-status-div>
                  
                 <!--    <div>
                        {{ this.Objective.LatestCheckinDate | date}}
                    </div> -->
                   
                    <div style="width: 200px;margin-left: 20px; display: flex; flex-direction: row;">
                        <mat-progress-bar mode="determinate"  value="{{this.Objective.CurrentProgress}}" style="max-width: 120px; height: 15px; padding-top: 10px; flex-grow: 1;"></mat-progress-bar>
                        <label style="padding-top: 5px; margin-left: 5px; font-family: Roboto;">{{this.Objective.CurrentProgress}}%</label>
                    </div>
                    <div >
                        <a mat-button [routerLink]="['/objectives/details', this.Objective.ObjectiveId]">DETAILS</a>
                    </div>
                    <div *ngIf="this.ShowButtons==true">
                        <a mat-button [routerLink]="['/objectives/' + this.Objective.ObjectiveId + '/checkin']">CHECK IN</a>
                    </div>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" *ngIf="this.ShowButtons==true">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="addChildObjective(this.Objective.ObjectiveLevel,this.Objective.ObjectiveId)" *ngIf="this.Objective.ObjectiveLevel!='INDIVIDUAL'">
                            <mat-icon>add</mat-icon>
                            <span>ADD CHILD OBJECTIVE</span>
                          </button>
                          <button mat-menu-item (click)="editObjective(this.Objective.ObjectiveId)">
                            <mat-icon>edit</mat-icon>
                            <span>EDIT</span>
                          </button>
                        <button mat-menu-item (click)="deleteObjective()">
                          <mat-icon>delete</mat-icon>
                          <span>DELETE</span>
                        </button>
                     <!--    <button mat-menu-item (click)="editTask()" >
                          <mat-icon>edit</mat-icon>
                          <span>EDIT</span>
                        </button> -->
                       
                      </mat-menu>
                </div>
            </td>
            <td>
              
            </td>
        </tr>
        <!-- <tr>
            <td></td>        
            <td></td>
            <td colspan="3" *ngIf="this.Objective.KeyResults!=null" >
                <div *ngFor="let kr of this.Objective.KeyResults" style="max-width: 500px;">
                                    <app-keyresult-status-row [KeyResult]="kr" ></app-keyresult-status-row>
                </div>
            </td>
            <td colspan="3" *ngIf="this.Objective.KeyResults==null" >
                NO KEY RESULTS DEFINED
            </td>
            <td></td>
        </tr> -->
   
    </table>
    <mat-divider></mat-divider>
</div>
