import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from './baseservice';
import { Observable } from 'rxjs';
import { GameStatisticsGroupViewModel, GameStatisticsParameterCalculatedViewModel, GameStatisticsProfileLimitViewModel, GameStatisticsViewViewModel } from '../stats/models/statistics.model';
import { MatTableDataSource } from '@angular/material/table';
import { parseTemplate } from '@angular/compiler';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';

@Injectable({
  providedIn: 'root'
}) 
export class GameStatisticsService extends BaseService {
 
  constructor(private http: HttpClient) {
    super();
   }
   
   addParamatersToProfile(ProfileId: string, Limits: GameStatisticsProfileLimitViewModel[]){
    return this.http.post(this.rootUrl + '/api/gamestatistics/profile/' + ProfileId + '/parameters',Limits, this.httpOptions);
   }

   addParamatersCalculatedToProfile(ProfileId: string, Limits: GameStatisticsProfileLimitViewModel[]){
    return this.http.post(this.rootUrl + '/api/gamestatistics/profile/' + ProfileId + '/parameterscalculated',Limits, this.httpOptions);
   }
   
   saveProfileLimits(ProfileId: string, Limits: GameStatisticsProfileLimitViewModel[]){
     //console.log(Limits);
    return this.http.post(this.rootUrl + '/api/gamestatistics/profile/' + ProfileId + '/limits',Limits, this.httpOptions);
   }
   saveProfileLimitsCalculated(ProfileId: string, Limits: GameStatisticsProfileLimitViewModel[]){
    //console.log(Limits);
   return this.http.post(this.rootUrl + '/api/gamestatistics/profile/' + ProfileId + '/limitscalculated',Limits, this.httpOptions);
  }

  insertProfile(Name: string){
    return this.http.post(this.rootUrl + '/api/gamestatistics/profile/new', JSON.stringify(Name), this.httpOptions);
  }

  getAllParameters() {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/gamestatisticsparameters/all');
  }

  getParametersNotUsedInProfile(ProfileId: string) {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/gamestatistics/parameters/notused/' + ProfileId);
  }

  getAllParametersCalculated() {
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/gamestatistics/parameterscalculated/all');
  }
  
  geParametersCalculatedNotUsedInProfile(ProfileId: string){
    //var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/gamestatistics/parameterscalculated/notused/' + ProfileId);
  }
  getAllProfiles(){
    return this.http.get(this.rootUrl + '/api/gamestatistics/profile/all');
  }
  getProfile(ProfileId: string){
    return this.http.get(this.rootUrl + '/api/gamestatistics/profile/' + ProfileId);
  }

  getAllViews(){
    return this.http.get(this.rootUrl + '/api/gamestatistics/views/all');
  }
  getView(ViewId: string){
    return this.http.get(this.rootUrl + '/api/gamestatistics/view/' + ViewId);
  }
  getViewsByPosition(PositionId: number){
    return this.http.get(this.rootUrl + '/api/gamestatistics/views?PlayerPositionId=' + PositionId);
  }
  saveView(view: GameStatisticsViewViewModel){
    return this.http.post(this.rootUrl + '/api/gamestatistics/view',view, this.httpOptions);
  }

  getAllGroups(){
    return this.http.get(this.rootUrl + '/api/gamestatistics/groups/all');
  }
  getGroup(GroupId: string){
    return this.http.get(this.rootUrl + '/api/gamestatistics/group/' + GroupId);
  }
  saveGroup(group: GameStatisticsGroupViewModel){
    return this.http.post(this.rootUrl + '/api/gamestatistics/group',group, this.httpOptions);
  }

  saveParameter(data){
    return this.http.post(this.rootUrl + '/api/gamestatistics/parameters',data, this.httpOptions);
  }

  saveParameterCalculated(data){
    return this.http.post(this.rootUrl + '/api/gamestatistics/parametercalculated',data, this.httpOptions);
  }

  saveStatisticsPlayerView(data){
    return this.http.post(this.rootUrl + '/api/gamestatistics/teamplayerview/',data, this.httpOptions);
  }

 

  insertParameterCalculated(data){
    return this.http.post(this.rootUrl + '/api/gamestatistics/parametercalculated/new',data, this.httpOptions);
  }

  deleteParameterCalculated(data: GameStatisticsParameterCalculatedViewModel){
    //return this.http.delete(this.rootUrl + '/api/gamestatistics/parametercalculated/' + encodeURIComponent(data.Abbreviation), this.httpOptions);
    console.log(data);
    return this.http.post(this.rootUrl + '/api/gamestatistics/parametercalculated/delete', data, this.httpOptions);
  }

  deleteStatisticsGroup(GroupId: string){
    //return this.http.delete(this.rootUrl + '/api/gamestatistics/parametercalculated/' + encodeURIComponent(data.Abbreviation), this.httpOptions);
    console.log(GroupId);
    return this.http.post(this.rootUrl + '/api/gamestatistics/group/delete/' + GroupId, this.httpOptions);
  }
  deleteView(ViewId: string){
    //return this.http.delete(this.rootUrl + '/api/gamestatistics/parametercalculated/' + encodeURIComponent(data.Abbreviation), this.httpOptions);
    console.log(ViewId);
    return this.http.post(this.rootUrl + '/api/gamestatistics/view/delete/' + ViewId, this.httpOptions);
  }

  deleteProfile(ProfileId: string){
    //return this.http.delete(this.rootUrl + '/api/gamestatistics/parametercalculated/' + encodeURIComponent(data.Abbreviation), this.httpOptions);
    //console.log(ProfileId);
    return this.http.delete(this.rootUrl + '/api/gamestatistics/profile/' + ProfileId, this.httpOptions);
  }

  deleteParameterFromProfile(ProfileId: string, GameStatisticsId: string){
    //return this.http.delete(this.rootUrl + '/api/gamestatistics/parametercalculated/' + encodeURIComponent(data.Abbreviation), this.httpOptions);
    console.log(ProfileId);
    return this.http.delete(this.rootUrl + '/api/gamestatistics/profile/' + ProfileId + '/parameter/' + GameStatisticsId, this.httpOptions);
  }

  deleteParameterCalculatedFromProfile(ProfileId: string, Abbreviation: string){
    //return this.http.delete(this.rootUrl + '/api/gamestatistics/parametercalculated/' + encodeURIComponent(data.Abbreviation), this.httpOptions);
    //console.log(Abbreviation);
    return this.http.post(this.rootUrl + '/api/gamestatistics/profile/' + ProfileId + '/parametercalculated/delete', JSON.stringify(Abbreviation), this.httpOptions);
  }

  getStatisticsForGameAndTeamForEdit(GameId: string, TeamId: string, ViewId: string){
    return this.http.get(this.rootUrl + '/api/gamestatistics/game/edit/'+ GameId + '/team/' + TeamId + '/view/' + ViewId);
  }

  getStatisticsForGameAndTeam(GameId: string, TeamId: string, ViewId: string){
    return this.http.get(this.rootUrl + '/api/gamestatistics/game/'+ GameId + '/team/' + TeamId + '/view/' + ViewId);
  }

  getGameStatisticsForPlayer(PlayerId: string, SeasonId: number, ViewId ){
     
    return this.http.get(this.rootUrl + '/api/gamestatistics/player?PlayerId=' + PlayerId + "&SeasonId=" + SeasonId + '&GameStatisticsViewId=' + ViewId);
  }


  saveStatistics(GameId: string, TeamId: string, ViewId: string, data: MatTableDataSource<string>){

    return this.http.post(this.rootUrl + '/api/gamestatistics/game/' + GameId + '/team/' + TeamId +  '/view/' + ViewId, data, this.httpOptions);
  }

  getGameStatsFromSweHockey(GameId:string, TeamId: string){
    return this.http.get(this.rootUrl + '/api/gamestatistics/swehockey/'+ GameId + '/team/' + TeamId ); 
  }

  importStatistics(GameId: string, TeamId: string, mappings: any, headers: any, data: any){
    
    return this.http.post(this.rootUrl + '/api/gamestatistics/import/game/' + GameId + "/team/" + TeamId ,  { Headers: headers, Mappings: mappings, Rows: data}, this.httpOptions);
  }

  getGameStatisticsImportMappings(){
    return this.http.get(this.rootUrl + '/api/gamestatistics/import/mappings/' ,  this.httpOptions);
  }
  
}