<div [formGroup]="form">


<mat-accordion class="accordion" multi class="mat-elevation-z8" *ngIf="section.Collapsible" >
    <mat-expansion-panel style="max-width: 570px;"  [expanded]="true">
      <mat-expansion-panel-header style="background-color: gray;" >
        <mat-panel-title>
          {{section.Text}}
        </mat-panel-title>
   <!--      <mat-panel-description>
          Type your name and age
          <mat-icon>account_circle</mat-icon>
        </mat-panel-description> -->
      </mat-expansion-panel-header>

      <mat-divider></mat-divider>
    
    
      <ng-container *ngFor="let question of section.Questions">
    
        <div [ngSwitch]="question.ControlType" style="margin-left: 10px;">
    
            <span class="QuestionText">         
              {{question.Text}}
            </span>
            <div class="QuestionLongText">
              {{question.LongText}}
            </div>
            <div style="margin-left: 10px;;">
                <mat-form-field style="width: 530px;" appearance="fill"  *ngSwitchCase="'LongText'" class="smalltext"    >
                    <mat-label>{{question.Label}}</mat-label>
                    <textarea matInput [placeholder]="question.Placeholder" maxlength="500" rows="4" cols="50" [id]="question.Key" [formControlName]="question.Key"></textarea>
                    <mat-hint>{{question.Hint}}</mat-hint>
                  </mat-form-field>
    
                <mat-form-field style="width: 530px;"  appearance="fill" *ngSwitchCase="'ShortText'"  class="smalltext"   >
                    <mat-label>{{question.Label}}</mat-label>
                    <input matInput [placeholder]="question.Placeholder" [id]="question.Key" [formControlName]="question.Key" maxlength="100" class="smalltext" />
                                    
                </mat-form-field>
               
                <mat-form-field  appearance="fill" *ngSwitchCase="'SelectList'"  class="smalltext">
                    <mat-label>Favorite food</mat-label>
                    <mat-select [id]="question.Key" [formControlName]="question.Key"  >
                        <mat-option *ngFor="let choice of question.FormQuestionChoices" [value]="choice.Value">
                          {{choice.Text}}
                        </mat-option>
                        
                    </mat-select>
                </mat-form-field>
    
                <mat-form-field appearance="fill" *ngSwitchCase="'DatePicker'" class="smalltext">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picker"  [id]="question.Key" [formControlName]="question.Key">
                    <mat-hint>YYYY-MM-DD</mat-hint>
                    <mat-datepicker-toggle matIconSuffix  matSuffix  [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
    
    
                <mat-radio-group [id]="question.Key" [formControlName]="question.Key" *ngSwitchCase="'Rating5'" class="smalltext" >
                  <mat-radio-button value="1" style="width: 90px;" class="smalltext">1</mat-radio-button>
                  <mat-radio-button value="2" style="width: 90px;" class="smalltext">2</mat-radio-button>
                  <mat-radio-button value="3" style="width: 90px;" class="smalltext">3</mat-radio-button>
                  <mat-radio-button value="4" style="width: 90px;" class="smalltext">4</mat-radio-button>
                  <mat-radio-button value="5" style="width: 90px;" class="smalltext">5</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group [id]="question.Key" [formControlName]="question.Key" *ngSwitchCase="'Rating6'"  class="smalltext">
                  <mat-radio-button value="1" style="width: 90px;">1</mat-radio-button>
                  <mat-radio-button value="2" style="width: 90px;">2</mat-radio-button>
                  <mat-radio-button value="3" style="width: 90px;">3</mat-radio-button>
                  <mat-radio-button value="4" style="width: 90px;">4</mat-radio-button>
                  <mat-radio-button value="5" style="width: 90px;">5</mat-radio-button>
                  <mat-radio-button value="6" style="width: 90px;">6</mat-radio-button>
                </mat-radio-group>
                <mat-radio-group [id]="question.Key" [formControlName]="question.Key" *ngSwitchCase="'Rating10'"  class="smalltext">
                  <mat-radio-button value="1" style="width: 70px;">1</mat-radio-button>
                  <mat-radio-button value="2" style="width: 70px;">2</mat-radio-button>
                  <mat-radio-button value="3" style="width: 70px;">3</mat-radio-button>
                  <mat-radio-button value="4" style="width: 70px;">4</mat-radio-button>
                  <mat-radio-button value="5" style="width: 70px;">5</mat-radio-button>
                  <mat-radio-button value="6" style="width: 70px;">6</mat-radio-button>
                  <mat-radio-button value="7" style="width: 70px;">7</mat-radio-button>
                  <mat-radio-button value="8" style="width: 70px;">8</mat-radio-button>
                  <mat-radio-button value="9" style="width: 70px;">9</mat-radio-button>
                  <mat-radio-button value="10" style="width: 70px;">10</mat-radio-button>
                </mat-radio-group>
    
            </div>
    
        </div>
    
    
      </ng-container>
  
   
  
    </mat-expansion-panel>
</mat-accordion>

<div *ngIf="!section.Collapsible">
  <mat-divider></mat-divider>
  <h2>{{section.Text}}</h2>

  <ng-container *ngFor="let question of section.Questions">

    <div [ngSwitch]="question.ControlType" style="margin-left: 20px;">

        <span class="QuestionText">         
          {{question.Text}}
        </span>
        <div class="QuestionLongText">
          {{question.LongText}}
        </div>
        <div style="margin-left: 10px; margin-bottom: 20px;">
            <mat-form-field style="width: 600px;" appearance="fill"  *ngSwitchCase="'LongText'" class="smalltext"    >
                <mat-label>{{question.Label}}</mat-label>
                <textarea matInput [placeholder]="question.Placeholder" maxlength="500" rows="4" cols="50" [id]="question.Key" [formControlName]="question.Key"></textarea>
                <mat-hint>{{question.Hint}}</mat-hint>
              </mat-form-field>

            <mat-form-field style="width: 600px;"  appearance="fill" *ngSwitchCase="'ShortText'"  class="smalltext"   >
                <mat-label>{{question.Label}}</mat-label>
                <input matInput [placeholder]="question.Placeholder" [id]="question.Key" [formControlName]="question.Key" maxlength="100" class="smalltext" />
                                
            </mat-form-field>
           
            <mat-form-field  appearance="fill" *ngSwitchCase="'SelectList'"  class="smalltext">
                <mat-label>Favorite food</mat-label>
                <mat-select [id]="question.Key" [formControlName]="question.Key"  >
                    <mat-option *ngFor="let choice of question.FormQuestionChoices" [value]="choice.Value">
                      {{choice.Text}}
                    </mat-option>
                    
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" *ngSwitchCase="'DatePicker'" class="smalltext">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker"  [id]="question.Key" [formControlName]="question.Key">
                <mat-hint>YYYY-MM-DD</mat-hint>
                <mat-datepicker-toggle matIconSuffix  matSuffix  [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>


            <mat-radio-group [id]="question.Key" [formControlName]="question.Key" *ngSwitchCase="'Rating5'" class="smalltext" >
              <mat-radio-button value="1" style="width: 90px;" class="smalltext">1</mat-radio-button>
              <mat-radio-button value="2" style="width: 90px;" class="smalltext">2</mat-radio-button>
              <mat-radio-button value="3" style="width: 90px;" class="smalltext">3</mat-radio-button>
              <mat-radio-button value="4" style="width: 90px;" class="smalltext">4</mat-radio-button>
              <mat-radio-button value="5" style="width: 90px;" class="smalltext">5</mat-radio-button>
            </mat-radio-group>
            <mat-radio-group [id]="question.Key" [formControlName]="question.Key" *ngSwitchCase="'Rating6'"  class="smalltext">
              <mat-radio-button value="1" style="width: 90px;">1</mat-radio-button>
              <mat-radio-button value="2" style="width: 90px;">2</mat-radio-button>
              <mat-radio-button value="3" style="width: 90px;">3</mat-radio-button>
              <mat-radio-button value="4" style="width: 90px;">4</mat-radio-button>
              <mat-radio-button value="5" style="width: 90px;">5</mat-radio-button>
              <mat-radio-button value="6" style="width: 90px;">6</mat-radio-button>
            </mat-radio-group>
            <mat-radio-group [id]="question.Key" [formControlName]="question.Key" *ngSwitchCase="'Rating10'"  class="smalltext">
              <mat-radio-button value="1" style="width: 70px;">1</mat-radio-button>
              <mat-radio-button value="2" style="width: 70px;">2</mat-radio-button>
              <mat-radio-button value="3" style="width: 70px;">3</mat-radio-button>
              <mat-radio-button value="4" style="width: 70px;">4</mat-radio-button>
              <mat-radio-button value="5" style="width: 70px;">5</mat-radio-button>
              <mat-radio-button value="6" style="width: 70px;">6</mat-radio-button>
              <mat-radio-button value="7" style="width: 70px;">7</mat-radio-button>
              <mat-radio-button value="8" style="width: 70px;">8</mat-radio-button>
              <mat-radio-button value="9" style="width: 70px;">9</mat-radio-button>
              <mat-radio-button value="10" style="width: 70px;">10</mat-radio-button>
            </mat-radio-group>

        </div>

    </div>


  </ng-container>
</div>
<br/>
</div>