<div class="app-page" style="margin-left:30px;">


    <div >Guide to OKRs</div>
    <div >
        <pre>
            OKRs turn missions into milestones.
            OKR stands for Objectives and Key Results.
        </pre>
    </div>


    <div >Mission</div>
    <div>
        <pre>
         A mission statement is a concise explanation of the organization's reason for existence.
         It describes the organization's purpose and its overall intention. 
        </pre>
    </div>


    <div >Objectives</div>
    <div>
        <pre>
        An objective is what you want to do. It describes your mission-supporting goal and sets a deadline
        for achieving it.

        Objectives can be on three levels, Organization, Team and Individual/Personal level.

        Objectives can be aligned so that organizational objectives have child team objectives.
        Team objectives can have child individual objectives.
        </pre>
    </div>

    <div >Key results</div>
    <div>
        <pre>
        Key Results are the measures and milestones that indicate how you know you will
        accomplish our Objective.
        Remember, good Key Results have several basic characteristics:

        They are specific and time-bound.
        They are aggressive, yet realistic.
        They are measurable and verifiable.

        Key Results are not independent little goals to meet. They’re stepping stones on 
        the way to achieving the Objective.

        So if you could achieve your Objective without completing your Key Results, 
        your Key Results probably aren’t the right milestones.

        The same is true if you feel like you could complete all your Key Results and 
        still not achieve your Objective. Key Results identify essential indicators to turn an 
        Objective into reality.

        Your Key Results will usually fit into one of three categories: Inputs, Outputs and Outcomes.
        Inputs are things that you can control. 
            Example: "Run 50km each week for 8 weeks"
        Outputs are the effects of your inputs. 
            Example: "Decrease time on 10km from 45 to 38 minutes"
        Outcomes are a more advanced way to think about Outputs. Outputs tend to describe
        the desired end result itself. 
            Example: "Increase MIP capability to 20+ minutes"
        </pre>
    </div>

    <div >Tasks</div>
    <div>
        <pre>
        To achieve your Key Results, you need to break it down into a specific set of tasks.
        </pre>  
    </div>

    <div >Check Ins</div>
    <div>
        <pre>
        OKR Check-Ins allow teams and the whole organization to track progress
        on a regular basis to adapt tactics as needed and remove
        first impediments along the way.    

        Regular basis Check Ins brings
        -Transparency of the status-quo
        -Overview of the progress and upcoming tasks and activities
        -Get everybody on the same page
        -Identify impediments for fast removal    


        </pre>  
    </div>

</div>