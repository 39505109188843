import { Routes } from '@angular/router';
import { ProfilesListComponent } from './profiles-list/profiles-list.component';
import { AuthGuard } from '../auth/auth.guard';
import { ProfileAddComponent } from './profile-add/profile-add.component';
import { TestLimitsEditorComponent } from './test-limits-editor/test-limits-editor.component';

 export const ProfilesRoutes: Routes = [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: 'ProfilesList'
    },
    {
      path: 'ProfilesList',
      component: ProfilesListComponent,
      canActivate:[AuthGuard]
      
    },
    {
      path: 'profileadd',
      component: ProfileAddComponent,
      canActivate:[AuthGuard]
    },
    {
      path: 'profilelimits/:ProfileId',
      component: TestLimitsEditorComponent,
      canActivate:[AuthGuard]
    }
  ];