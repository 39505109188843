import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import { Team, TeamOverviewModel } from '../shared/team.model';
import { SelectProfileDialogComponent } from '../shared/select-profile-dialog/select-profile-dialog.component';
import { TestService } from '../shared/test.service';
import { TeamService } from '../shared/team.service';
import { Globals } from '../globals';
import { SelectGameStatisticsProfileComponent } from '../shared/select-game-statistics-profile/select-game-statistics-profile.component';
import { GameStatisticsService } from '../shared/game-statistics.service';
import { PlayerPosition } from '../shared/models/player.model';
import { TeamGameStatisticsProfilePositionViewModel, TeamPlayerGameStatisticsViewModel } from '../shared/models/gamestatistics.model';
import { ConfigSetStatsPlayerViewDialogComponent } from '../configuration/config-set-stats-player-view-dialog/config-set-stats-player-view-dialog.component';
import { ConfigSetGameassessmentDialogComponent } from '../configuration/config-set-gameassessment-dialog/config-set-gameassessment-dialog.component';
import { GameAssessmentService } from '../shared/game-assessment.service';


@Component({
  selector: 'app-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.css']
})
export class TeamCardComponent implements OnInit {
  @Input() Team: TeamOverviewModel;
  @Input() Positions: PlayerPosition[];
  @Output() updated = new EventEmitter();

  constructor(private dialog: MatDialog, 
    private _teamService: TeamService,
    private _statsService: GameStatisticsService, 
    private _gameassessmentService: GameAssessmentService,
    private _globals: Globals ) { }

  ngOnInit() {
    this._globals.titleBarText="Team Settings";
  }

  public getStatsProfilePerPosition(Positionid: string): string{

    tmp: TeamGameStatisticsProfilePositionViewModel;
    let tmp = this.Team.GameStatisticsProfiles.filter(r=>r.PositionId  ==Positionid);
    if(tmp.length==0)
      return "-";
    else{
      return tmp[0].Name;
    }
      
  }

  public getTeamPlayerStatisticsView(Positionid: number): string{

    tmp: TeamGameStatisticsProfilePositionViewModel;
    let tmp = this.Team.TeamPlayerStatisticsViews.filter(r=>r.PositionId==Positionid);
    if(tmp.length==0)
      return "-";
    else{
      return tmp[0].Name;
    }
      
  }

  

 

  setProfile(TeamId: string){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { teamid: this.Team.TeamId };
    
    let dialogRef = this.dialog.open(SelectProfileDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed' + result.profileId + result.PlayerPositionId);
      this._teamService.setProfileForTeam( result.profileId,this.Team.TeamId, result.PlayerPositionId).subscribe(r=>{
        this.updated.emit();
      });
      
    });
  }

  setGameAssessment(TeamId: string){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { TeamId: this.Team.TeamId };
    
    let dialogRef = this.dialog.open(ConfigSetGameassessmentDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed' + result.profileId + result.PlayerPositionId);
      console.log(result);
      if(result!=undefined){
        this._teamService.setGameAssessmentForTeam( this.Team.TeamId, result).subscribe(r=>{
          this.updated.emit();
        });
      }
      
    });
  }

  setStatisticsProfile(TeamId: string){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { teamid: this.Team.TeamId };
    
    let dialogRef = this.dialog.open(SelectGameStatisticsProfileComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed' + result.profileId + result.PlayerPositionId);
     
      this._teamService.setGameStatisticsProfileForTeam( result.profileId,this.Team.TeamId, result.PlayerPositionId).subscribe(r=>{
        this.updated.emit();
      });
    
      
      
    });
  }

  setStatisticsPlayerViewProfile(TeamId: string){

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { TeamId: this.Team.TeamId };
    
    let dialogRef = this.dialog.open(ConfigSetStatsPlayerViewDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed' + result.ViewId +"   :" + result.PlayerPositionId);
      var tmp: TeamPlayerGameStatisticsViewModel;
      tmp = new TeamPlayerGameStatisticsViewModel();
      tmp.PositionId = result.PlayerPositionId;
      tmp.TeamId = this.Team.TeamId;
      tmp.GameStatisticsViewId = result.ViewId;
      this._statsService.saveStatisticsPlayerView(tmp).subscribe(r=>{
        this.updated.emit();
      }); 
      
    });
  }

}


