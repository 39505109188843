<h3 mat-dialog-title style="border-bottom: 1px orange solid; padding-bottom: 10px;">Grade player</h3>
<div mat-dialog-content>
  <p style="font-family: Roboto;">{{ this.data.GameName }}</p>
  <p style="font-family: Roboto; font-weight: bold;">{{this.data.LastName}}, {{this.data.FirstName}}</p>
  
  <form #ratingForm="ngForm">

    <p>
      
        <mat-button-toggle-group name="Grade" aria-label="Set grade"  [(ngModel)]="log.Ranking" name="Ranking">
            <mat-button-toggle [value]="1" >
                <mat-icon style="color: #FF5722;">sentiment_very_dissatisfied</mat-icon><br/>
                
            </mat-button-toggle>
            <mat-button-toggle  [value]="2" >
                <mat-icon style="color:#FFC107;">sentiment_neutral</mat-icon><br/>
                
            </mat-button-toggle>
            <mat-button-toggle  [value]="3" >
              <mat-icon style="color:#4CAF50">sentiment_satisfied</mat-icon><br/>
                
            </mat-button-toggle>
            <mat-button-toggle  [value]="4" >
                <mat-icon style="color: #2E7D32">sentiment_very_satisfied</mat-icon><br/>
                
              </mat-button-toggle>
            <mat-button-toggle  [value]="5" >
                <mat-icon style="color: #00BCD4;">star_border</mat-icon><br/>
                
            </mat-button-toggle>
        </mat-button-toggle-group><br/>
        
    </p>
    
    
    <mat-form-field class="example-full-width" style="width: 100%;" appearance="outline" mat-hint="Max 250 characters"  >
        <mat-label>Comment - visible for player</mat-label>
      <textarea matInput [(ngModel)]="log.CommentToPlayer" name="CommentToPlayer" maxlength="256" style="height: 120px;"></textarea>
      <mat-hint>Max 250 characters</mat-hint>
    </mat-form-field>
  
    <mat-form-field class="example-full-width" style="width: 100%;" appearance="outline">
        <mat-label>Comment - coaches only</mat-label>
      <textarea matInput placeholder="Comment - coaches only" [(ngModel)]="log.CommentPrivate" maxlength="256" style="height: 120px;" name="CommentPrivate"></textarea>
      <mat-hint>Max 250 characters</mat-hint>
    </mat-form-field>
  </form>
   

  
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">AVBRYT</button>
  <button mat-button  cdkFocusInitial (click)="onSaveClick()">SPARA</button>
  <button mat-button color="warn" (click)="onDeleteClick()" *ngIf="this.showDeleteButton">RADERA</button>
</div>