<div class="app-page">
       
    <mat-toolbar>
        <form>
            <mat-form-field  style="font-size: small; width: 100px;">
                            <mat-label>Roster - Season</mat-label>
                            <mat-select [formControl]="seasonFormControl" (selectionChange)="refreshData()">
                              <mat-option *ngFor="let season of _seasons" [value]="season" >
                                {{season.Name}}
                              </mat-option>
                            </mat-select>
            </mat-form-field>       
        </form>
    </mat-toolbar>

    <div *ngIf="Players" style="display: flex; flex-wrap: wrap; justify-content: center; ">
     
</div>
</div>
