<div class="questionframe" [formGroup]="form" *ngIf="form" >
  <h3>Question</h3>
  
      <mat-form-field appearance="fill" style="width: 400px;" class="smallformfield">
        <mat-label>Question text</mat-label>
        <textarea matInput type="text" maxlength="200" id="Text" formControlName="Text"></textarea>
        <mat-hint>The question text that should be answered</mat-hint>
      </mat-form-field>
      <!-- <div style="display: flex; flex-direction: row; flex-wrap: wrap;"> -->
        <mat-form-field appearance="fill" style="width: 200px;" class="smallformfield">
          <mat-label>Key for value</mat-label>
          <input matInput type="text" placeholder="Value key" maxlength="50" required  id="Key" formControlName="Key" />
          <mat-hint>Key must be unique in form</mat-hint>
        </mat-form-field>
         &nbsp;
         <input type="text" id="ControlType" formControlName="ControlType"/>
                <!-- <mat-form-field appearance="fill" class="smallformfield">
                  <mat-label>Answer type</mat-label>
                  <mat-select  id="ControlType" formControlName="ControlType">
                    <mat-option value="ShortText">
                      Short text
                    </mat-option>
                    <mat-option value="LongText">
                      Long text
                    </mat-option>
                    <mat-option value="SelectList">
                      Select list
                    </mat-option>
                    <mat-option value="Date">
                      Date picker
                    </mat-option>
                    <mat-option value="Rating5">
                      Rating 1-5
                    </mat-option>
                    <mat-option value="Rating6">
                      Rating 1-6
                    </mat-option>
                    <mat-option value="Rating10">
                      Rating 1-10
                    </mat-option>
                  </mat-select>
                  <mat-hint>How will the question be answered</mat-hint>
                </mat-form-field> -->

                &nbsp;

              <div style="display: flex; flex-direction: row; align-items: center;">
                <input type="text" id="Required" formControlName="Required"  /> Required
                
              </div>
              
      <!-- </div> -->
      <br/>
      <button mat-raised-button color="accent" (click)="this.deleteQuestionEvent.emit();">DELETE</button>
      


</div>
