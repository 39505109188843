<div>

    <h4>Question text</h4>

    <label id="example-radio-group-label">Pick your favorite season</label>
    <mat-radio-group
      aria-labelledby="example-radio-group-label"
      class="example-radio-group"
      [(ngModel)]="favoriteSeason">
      <mat-radio-button class="example-radio-button" *ngFor="let season of seasons" [value]="season">
        {{season}}
      </mat-radio-button>
    </mat-radio-group>
    <div>Your favorite season is: {{favoriteSeason}}</div>


</div>
<mat-divider></mat-divider>
