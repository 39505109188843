import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import { Globals } from 'src/app/globals';
import { TestService } from 'src/app/shared/test.service';
import { Test } from 'src/app/shared/models/test.model';



export class TestData{
  TestId: string;
}

@Component({
  selector: 'app-test-editor',
  templateUrl: './test-editor.component.html',
  styleUrls: ['./test-editor.component.css']
})


export class TestEditorComponent implements OnInit {

  _Test: Test;
  testForm: UntypedFormGroup; 
  Types: any = [{name: 'Integer', value:'10'},{name: 'Decimal', value:'20'},];
  
  constructor(
    public dialogRef: MatDialogRef<TestEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public _test: TestData,  
    private _globals: Globals,   
    private _testService: TestService) { }

  ngOnInit() {
    

    let _testId = this._test.TestId;

  
   
    this._testService.getTest(_testId).subscribe((t: Test)=>{
      this._Test = t;
      this.testForm = new UntypedFormGroup({
        TestId: new UntypedFormControl(this._Test.TestId),
        Name: new UntypedFormControl(this._Test.Name, [Validators.required, Validators.maxLength(50)]),
        Description: new UntypedFormControl(this._Test.Description, [ Validators.maxLength(100)]),
        ResultType: new UntypedFormControl(this._Test.ResultType.toString(), [Validators.required] ),
        ResultUnit: new UntypedFormControl(this._Test.ResultUnit, [Validators.required] )  ,                  
        Operator: new UntypedFormControl(this._Test.Operator, [Validators.required] )  ,                 
      });        

      //this.testForm.patchValue({ResultType: this._Test.ResultType});

      
    });
  }

  onSubmit() {
    
    

    
    this._testService.updateTest(this.testForm.value).subscribe(res=>{
      this.dialogRef.close(res);
    });

  
  }

}
