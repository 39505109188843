<div mat-dialog-content>
  <h2 style="font-family: Roboto;">Add test to profile</h2>
  <form [formGroup]="testForm" (ngSubmit)="onSubmitForm()" id="testForm" autocomplete="off">
   
      <mat-form-field >
        <mat-label>Select tests</mat-label>
        <mat-select formControlName="tests" [(value)]="testid" >
          <mat-option *ngFor="let test of _tests" [value]="test.TestId">{{test.Name}}</mat-option>
        </mat-select>
      </mat-form-field>
     
     
     
    </form>
    
 </div>
 <div mat-dialog-actions>
   <button type="submit" form="testForm"  mat-raised-button color="primary" cdkFocusInitial>ADD</button>   
   <button mat-button [mat-dialog-close]="false" >CANCEL</button>
 </div>