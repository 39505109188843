import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from  '@angular/forms';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { TestResultRoutingModule } from './test-result-routing.module';
import { TeamProfilePlayersListComponent } from './team-profile-players-list/team-profile-players-list.component';
import { TeamProfilePlayerCardComponent } from './team-profile-player-card/team-profile-player-card.component';
import { TeamProfileListComponent } from './team-profile-list/team-profile-list.component';
import { TestResultAddComponent } from './test-result-add/test-result-add.component';
import { TestResultsPlayerComponent } from './test-results-player/test-results-player.component';
import { TestResultsOrganizationComponent } from './test-results-organization/test-results-organization.component';
import { TestResultAddDialogComponent } from './test-result-add-dialog/test-result-add-dialog.component';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatOptionModule } from '@angular/material/core';
import { CompareTestResultsComponent } from './views/compare-test-results/compare-test-results.component';
import { TestViewPlayerCardsComponent } from './test-view-player-cards/test-view-player-cards.component';
import { PlayerCardComponent } from './components/player-card/player-card.component';
import { ProfilePlayerCardComponent } from './components/profile-player-card/profile-player-card.component';



@NgModule({
  declarations: [
    TeamProfilePlayersListComponent,
    TeamProfilePlayerCardComponent, 
    TeamProfileListComponent, 
    TestResultAddComponent, 
    TestResultsPlayerComponent, 
    TestResultsOrganizationComponent, TestResultAddDialogComponent, CompareTestResultsComponent, TestViewPlayerCardsComponent, ProfilePlayerCardComponent
    
  ],
  imports: [
    CommonModule,
    SharedModuleModule,
    ReactiveFormsModule,
    TestResultRoutingModule,
    MatPaginatorModule,
    
    
    MatButtonToggleModule

  ]
})
export class TestResultModule { }
