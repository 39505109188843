import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';  
import { ActivatedRoute, Router } from '@angular/router';

import { PlayerDetailsModel } from 'src/app/load/models/player';
import { AgentSelectDialogComponent } from '../../agents/agent-select-dialog/agent-select-dialog.component';
import { AgentViewModel } from '../../models/AgentModels';
import { PlayerContactModel } from '../../models/PlayerModels';
import { AgentService } from '../../services/agent.service';
import { ScoutingPlayerService } from '../../services/scouting-player.service';
import { AddPlayerContactDialogComponent } from '../add-player-contact-dialog/add-player-contact-dialog.component';
import { AddTagDialogComponent } from '../add-tag-dialog/add-tag-dialog.component';
import { ShowTagsDialogComponent } from '../show-tags-dialog/show-tags-dialog.component';
import { ChartPlayerProjectionComponent } from '../../components/chart-player-projection/chart-player-projection.component';
import { FormControl } from '@angular/forms';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { CommonModule } from '@angular/common';
import { ReportCardPlayerComponent } from '../../components/report-card-player/report-card-player.component';
import { NoteCardPlayerComponent } from '../../components/note-card-player/note-card-player.component';
import { PlayerRankingListEntryComponent } from '../../components/player-ranking-list-entry/player-ranking-list-entry.component';
import { PlayerContactCardComponent } from '../../components/player-contact-card/player-contact-card.component';
import { AgentCardComponent } from '../../components/agent-card/agent-card.component';

@Component({
    selector: 'app-player-total-view',
  imports: [SharedModuleModule, CommonModule, ChartPlayerProjectionComponent
    //, AddPlayerContactDialogComponent
    , ReportCardPlayerComponent, NoteCardPlayerComponent, PlayerRankingListEntryComponent, PlayerContactCardComponent, AgentCardComponent],
    templateUrl: './player-total-view.component.html',
    styleUrls: ['./player-total-view.component.css']
})
export class PlayerTotalViewComponent implements OnInit {

  _playerData: any;
  _playerId: string;
  _agents: AgentViewModel[];
  _reportmasterdata: any;
  ProjectionTexts: string[]= [];
  tickValues: { [value: number]: [Text: string]}[]=[];
  _contacts: PlayerContactModel[];
  @Input() PlayerId: string;
  _contactCount: number = 0;
  selectedTab = new FormControl(0);
  
  playerDataLoaded: boolean = false;
  agentDataLoaded: boolean = false;
  masterdataLoaded: boolean = false;  
  contactDataLoaded: boolean = false;

  constructor(private route: ActivatedRoute, 
    public playerService: ScoutingPlayerService, 
    private router: Router,
    private agentService: AgentService, 
    private _snackBar: MatSnackBar,
    private dialog: MatDialog) {
      
   /*  this._playerId = this.PlayerId;
    this._playerId = route.snapshot.paramMap.get('PlayerId');
    //console.log('Constructor ' + this._playerId );
    
    playerService.getPlayerDetails(this._playerId).subscribe(data=>{
      this._playerData = data;
      //console.log(data);
      playerService.getReportMasterDataForPosition(data.FlowPlayer.PositionId).subscribe(md=>{
        this._reportmasterdata = md;   
        this.ProjectionTexts = [];
        this._reportmasterdata.Projection.forEach(proj => {
          this.ProjectionTexts.push(proj.Text);
          let tick = {v: proj.Ranking, f: proj.Text};
          this.tickValues.push(tick);
        });         
        //console.log(this.tickValues);
      })     
      playerService.getPlayerContacts(this._playerId).subscribe(contacts=>{
        this._contacts = contacts;
      });
    });   
    this.agentService.getAgentsForPlayer(this._playerId).subscribe(data=>{
      this._agents = data;
    }); */
  }



  ngOnChanges(changes: SimpleChanges) {
    //go to first tab
    this.selectedTab.setValue(0);

    this.playerDataLoaded = false;
    this.agentDataLoaded = false;
    this._playerId = this.PlayerId;
    //console.log('ngOnChanges ' + this._playerId );
    this.playerService.getPlayerDetails(this._playerId).subscribe(data=>{
      this._playerData = null;      
      this._playerData = data;
      this.playerDataLoaded = true;
      this.playerService.getReportMasterDataForPosition(data.FlowPlayer.PositionId).subscribe(md=>{
        this._reportmasterdata = md;   
        this.masterdataLoaded = true;
        this.ProjectionTexts = [];
        this._reportmasterdata.Projection.forEach(proj => {
          this.ProjectionTexts.push(proj.Text);
          let tick = {v: proj.Ranking, f: proj.Text};
          this.tickValues.push(tick);
          this.masterdataLoaded = true;
        });         
        //console.log(this.tickValues);
      }); 
      this.agentService.getAgentsForPlayer(this._playerId).subscribe(data=>{
        this._agents = data;    
        this.agentDataLoaded = true;    
      }); 
      this.refreshContacts();
    });
   
  }
 
  

  ngOnInit() {
    this.masterdataLoaded = false;
    this.playerDataLoaded= false;
    this.agentDataLoaded = false;
    //this._playerId = this.PlayerId;
    this._playerId = this.route.snapshot.paramMap.get('PlayerId');
    
    if(this._playerId==null){
      this._playerId = this.PlayerId; 
    }
    console.log('ngOnInit ' + this._playerId );
    this.playerService.getReportMasterData(this._playerId).subscribe(md=>{
      this._reportmasterdata = md;    
      this._reportmasterdata.Projection.forEach(proj => {
        this.ProjectionTexts.push(proj.Text);
        let tick = {v: proj.Ranking, f: proj.Text};
        this.tickValues.push(tick);
        this.masterdataLoaded = true;
        
      });    
      //console.log(this.ProjectionTexts)
      //this._playerId = this.PlayerId;
     
      //console.log('Constructor ' + this._playerId );
      
      this.playerService.getPlayerDetails(this._playerId).subscribe(data=>{
        
        this._playerData = data;
        this.playerDataLoaded = true;
        //console.log(data);
        this.playerService.getReportMasterDataForPosition(data.FlowPlayer.PositionId).subscribe(md=>{
          this._reportmasterdata = md;   
          this.masterdataLoaded = true;
          this.ProjectionTexts = [];
          this._reportmasterdata.Projection.forEach(proj => {
            this.ProjectionTexts.push(proj.Text);
            let tick = {v: proj.Ranking, f: proj.Text};
            this.tickValues.push(tick);
            this.masterdataLoaded = true;
          });         
          //console.log(this.tickValues);
        });     
        this.playerService.getPlayerContacts(this._playerId).subscribe(contacts=>{
          this._contacts = contacts;
          console.log("ngOnInit set true");
          this.contactDataLoaded = true;
        });
      });   
      this.agentService.getAgentsForPlayer(this._playerId).subscribe(data=>{
        this._agents = data;
        this.agentDataLoaded = true;
      });
    })     
  }

  openAddAgentDialog(){
    const dialogRef = this.dialog.open(AgentSelectDialogComponent, {
      width: '400px',     
    });
   

    dialogRef.afterClosed().subscribe(AgentId => {
      this.agentDataLoaded = false;
      this.agentService.addAgentToPlayer(AgentId, this._playerId).subscribe(result=>{
        if(result){
          this.agentService.getAgentsForPlayer(this._playerId).subscribe(data=>{
            this._agents = data;
            this.agentDataLoaded = true;
          });
          this.openSnackBar("Agent added", "CLOSE");
        }
          
        else{
          this.openSnackBar("Failed", "CLOSE");
          this.agentDataLoaded = true;
        }
          
      });
    });
  }


  openAddContactDialog(){
    
    const dialogRef = this.dialog.open(AddPlayerContactDialogComponent, {
      width: '500px',
      data: {PlayerId: this._playerId}   
    });

    
    dialogRef.afterClosed().subscribe(result => {
      this.contactDataLoaded = false;
        if(result){
          this.playerService.getPlayerContacts(this._playerId).subscribe(data=>{
            this._contacts = data;
            console.log("dialogAddContact1 set true");
            this.contactDataLoaded = true;
          });
          this.openSnackBar("Contact added", "CLOSE");
        }          
        else{
          console.log("dialogAddContact2 set true");
          this.contactDataLoaded = true;
          this.openSnackBar("Failed", "CLOSE");
        }
          
      });
    }
    
  isBusy(): boolean{
      return !(this.playerDataLoaded && this.contactDataLoaded && this.masterdataLoaded && this.agentDataLoaded);
    }

  refreshContacts(){
    this.playerService.getPlayerContacts(this._playerId).subscribe(data=>{
      this._contacts = data;
      console.log("refreshContacts set true");
      this.contactDataLoaded = true;
    });
  }

  refreshAgents(){
    this.agentService.getAgentsForPlayer(this._playerId).subscribe(data=>{
      this._agents = data;
      this.agentDataLoaded = true;
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  addTag(){
    const dialogRef = this.dialog.open(AddTagDialogComponent, {
      width: '500px',
      data: {PlayerId: this._playerId,  Tags: this._playerData.Tags_AllUsable}   
    });

    dialogRef.afterClosed().subscribe(result => {
      this.playerDataLoaded = false;
      this.playerService.getPlayerDetails(this._playerId).subscribe(data=>{
        this._playerData = data;
        this.playerDataLoaded = true;
      });
    });
      
  }

  openShowTagDialog(){
    const dialogRef = this.dialog.open(ShowTagsDialogComponent, {
      width: '500px',
      data: {PlayerId: this._playerId}   
    });
    const subscribeDialog = dialogRef.componentInstance.tagRemoved.subscribe((data) => {
      this.playerDataLoaded = false;
      this.playerService.getPlayerDetails(this._playerId).subscribe(data=>{
        this._playerData = data;
        this.playerDataLoaded = true;
      });
      
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
