<div class="app-page">
    <mat-card appearance="outlined" style="margin: 10px; min-width: 300px; max-width: 500px;margin: auto;" *ngIf='injuryForm'>
      <mat-card-header style="border-bottom: orange solid 1px;">
        <i mat-card-avatar class="material-icons">local_hospital</i>
        <mat-card-title>EDIT INJURY</mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <form [formGroup]="injuryForm" (ngSubmit)="submit()" >
              <br/>
              <h2>
                {{this.newInjury.Player.LastName}}, {{this.newInjury.Player.FirstName}}
              </h2>
              
                 
                  <input matInput id="PlayerId"  formControlName="PlayerId" hidden>
                  <input matInput id="InjuryId"  formControlName="InjuryId" hidden>
                  <input matInput id="SportId"  formControlName="SportId" hidden>
                  <input matInput id="OrganizationId"  formControlName="OrganizationId" hidden>
              
              <h3>Dates</h3>
              <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                <div style="margin-right: 10px;;">

                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" placeholder="Injury date" formControlName="InjuryDate"  required >
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        
                      </mat-form-field>
                </div>  
                <div style="margin-right: 10px;;">
                  <mat-form-field>
                                    <input matInput [matDatepicker]="pickerExpectedReturnDate" placeholder="Estimated return date" formControlName="EstimatedReturnDate"   >
                                    <mat-datepicker-toggle matSuffix [for]="pickerExpectedReturnDate" ng-model-options="{timezone: 'utc'}"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerExpectedReturnDate></mat-datepicker>
                                    
                                  </mat-form-field>
                </div>  
                <div style="margin-right: 10px;;">
                      <mat-form-field>
                                          <input matInput [matDatepicker]="pickerActualReturnDate" placeholder="Actual return date" formControlName="ActualReturnDate"   >
                                          <mat-datepicker-toggle matSuffix [for]="pickerActualReturnDate" ng-model-options="{timezone: 'utc'}"></mat-datepicker-toggle>
                                          <mat-datepicker #pickerActualReturnDate></mat-datepicker>
                                          
                                        </mat-form-field>
                </div>  

              </div>  
                 
                

            <h3>Injury details</h3>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
              <div style="margin-right: 10px;;">
                <mat-form-field appearance="fill" style="width: 200px;" >
                  <mat-label>Body part</mat-label>
                  <mat-select  formControlName="BodyPartId"  required>
                    <mat-option *ngFor="let part of this.newInjury.BodyParts" [value]="part.BodyPartId">{{part.BodyAreaName}}/{{part.Name}}</mat-option>
                 
                  </mat-select>
                </mat-form-field>
              </div>
              <div style="margin-right: 10px;">
                <mat-form-field appearance="fill" >
                  <mat-label>Side</mat-label>
                  <mat-select formControlName="Side" required>
                    <mat-option value="-">No side</mat-option>
                    <mat-option value="L">Left</mat-option>
                    <mat-option value="R">Right</mat-option>
                    <mat-option value="B">Both</mat-option>
                    
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="fill">
                  <mat-label>Type of injury</mat-label>
                  <mat-select formControlName="TypeId" required>
                    <mat-option *ngFor="let type of this.newInjury.Types" [value]="type.TypeId">{{ type.TypeName }}</mat-option>
                    
                    
                  </mat-select>
                </mat-form-field>
              </div>
            
            <mat-form-field appearance="fill" style="width: 350px;">
              <mat-label>Description</mat-label>
              <textarea formControlName="Description" matInput></textarea>
              </mat-form-field>
            <div>
            <h3>Conditions</h3>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
              <div style="margin-right: 10px;">
                <mat-form-field appearance="fill">
                  <mat-label>Condition</mat-label>
                  <mat-select formControlName="ConditionId" >
                    <mat-option *ngFor="let condition of this.newInjury.Conditions" [value]="condition.ConditionId">{{ condition.ConditionName }}</mat-option>
                    
                    
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="fill">
                  <mat-label>Location</mat-label>
                  <mat-select formControlName="LocationId">
                    <mat-option *ngFor="let location of this.newInjury.Locations" [value]="location.LocationId">{{ location.LocationName }}</mat-option>
                    
                    
                  </mat-select>
                </mat-form-field>
             
                
              </div>
              <div style="margin-bottom: 20px;">
               
                  
                <mat-checkbox  matInput  formControlName="Trauma">
                  Caused by trauma
                </mat-checkbox>
                <br/>

              </div>
              </div>
              </div>  

            </div>
           
          
          
            
            <button mat-raised-button color="primary" type="submit" [disabled]="injuryForm.status=='INVALID'">SAVE</button>
            <button mat-button type="button" (click)="this.location.back()">CANCEL</button>
          </form>
      </mat-card-content>
      <mat-card-actions>
       
      </mat-card-actions>  
    </mat-card>
  </div>
