<h2 *ngIf="this.isNewList" mat-dialog-title>NEW RANKING LIST</h2>
<h2 *ngIf="!this.isNewList" mat-dialog-title>EDIT RANKING LIST</h2>
<form #selectForm [formGroup]="_form" (ngSubmit)="submit()" *ngIf="this.loadedCats && this.loadedUsers && this.loadedList" >
    <mat-dialog-content style="font-family: Roboto;">

      <mat-tab-group>
        <mat-tab label="LIST">
          <br/>
          <div style="height: 450px;">
              <mat-form-field  appearance="fill" style="min-width: 350px;">
              <mat-label>Name</mat-label>
              <input matInput  formControlName="Description" autocomplete="false">
            </mat-form-field>
            <br/>

            <mat-form-field class="example-full-width" appearance="fill" style="min-width: 350px;">
              <mat-label>Category</mat-label>
              <input type="text" matInput formControlName="Category" [matAutocomplete]="auto" >
              <mat-autocomplete #auto="matAutocomplete"  >
                <mat-option *ngFor="let option of filteredCategories | async" [value]="option" style="color: white;">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          
        </mat-tab>
        <mat-tab label="SHARING">
          <label style="min-width: 100px;">
            VIEW
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </label>
          <label>
            EDIT
          </label>
          <br/>
         <!--  <mat-selection-list #sharinglist style="height: 350px;" [multiple]="true"  >
            <mat-list-option *ngFor="let usr of this.users" [value]="usr.User_Id" >             
              <h4 mat-line>{{ usr.LastName }}, {{ usr.FirstName }} </h4>              
             </mat-list-option>
            
            </mat-selection-list> -->
            <div style="height: 450px; overflow-y: scroll;" *ngIf="this.PlayerListSecurity" formArrayName="PlayerListSecurity">
            
              <div *ngFor="let access of PlayerListSecurity.controls; index as i;">
                <div [formGroupName]="i" STYLE="display: flex; flex-direction: row; border-bottom: gray solid 1px;">
                 
                  <div  style="width: 100px;">
                      <mat-checkbox formControlName="value"></mat-checkbox>
                  </div>
                  <div  style="width: 100px;">
                    <mat-checkbox formControlName="CanEdit"></mat-checkbox>
                </div>
                  <div style="width: 200px; font-family: Roboto;" >
                      {{this._form.controls['PlayerListSecurity'].controls[i].controls.FullName.value}}
                  </div>
              </div>
              </div>
            </div>
        </mat-tab>        
      </mat-tab-group>
<!-- 
        <div>{{this._form.value | json }}</div> -->

    
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button *ngIf="this.isNewList" [disabled]="!this._form.valid" type="submit">CREATE</button>
        <button mat-button *ngIf="!this.isNewList" [disabled]="!this._form.valid" type="submit">SAVE</button>
        <button mat-button type="button" (click)="close()">CANCEL</button>
    </mat-dialog-actions>
</form>

