import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { PlayerTotalViewComponent } from '../player/player-total-view/player-total-view.component';
import { MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { ReportViewDialogComponent } from '../report-view-dialog/report-view-dialog.component';
import { ScoutingFlowService } from '../services/scouting-flow.service';
import { PlayerReportViewComponent } from '../player/player-report-view/player-report-view.component';

@Component({
  selector: 'app-player-view-dialog',
  standalone: true,
  imports: [CommonModule, SharedModuleModule, PlayerReportViewComponent],
  templateUrl: './player-view-dialog.component.html',
  styleUrl: './player-view-dialog.component.css'
})
export class PlayerViewDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PlayerViewDialogComponent>){}


  close() {
    this.dialogRef.close();
  }
}
