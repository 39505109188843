<div class="app-page">
    <pre>
        Preview info for Training programs module.

        This is a preview of a feature that is under development. Purpose of the preview is to get customer feedback.        


        DEVELOPMENT STATUS
        ==================
        Functions for defining training programs are mostly completed. 
        Functions for assigning programs to players schedule, and player training reporting is not available in preview.
        Some work needed on page design overall.




        OBJECT DESCRIPTIONS
        ===================
        EXERCISE: An exercise. Example: CHINS, DIPS, LATERAL LUNGE, PLANK

        WORKOUT: A number of exercises performed at one time.
        A workout can have different sections with exercises.
        Each exercise is defined with sets, reps, rest and a method (see definition below).

        PROGRAM: A number of workouts scheduled over a period of time.

        TARGET: [Optional to use] Something that can be the target for the training. 
        Example: CORE, BICEPS, SHOULDER, HAMSTRINGS

        EQUIPMENT: [Optional to use] An equipment needed to perform exercises. 
        Example: Dumbbell, Ab roller wheel, Cable Tower, Foam roller

        METHOD: [Optional to use] Description on how to perform an exercise, describing things like load and speed.

        [NEW 1.31] ASSIGNED PROGRAM: Assign a program for a list of players for a period of time.

        [NEW 1.31] WEIGHT ROOM screen to let players enter workout log, currently just a non-functional mockup.


        
        ASSIGNING PROGRAMS (v1.31)
        ==================================
        You can assign a program to a team or to specific players.
        If a program is assigned to the team, all players in the team have the workouts in their calendar.
        If you assign it to specific players, only those players have it in the calendar.

        LOOKING AT THE SCHEDULES  (v1.31)
        ==========================================
        Select the "SCHEDULE" menu option in "TRAINING" menu.
        You can now choose to look at the schedule on team level or player level.
        Workouts scheduled on team level is visible in players level, but player level workouts can not be seen on team level schedule.
        The team level schedule also contains planned games for the team.
        The players schedule shows all games and workouts for all teams the player is part of for the current season, and also any workouts assigned to the player.




    </pre>


</div>
