<div class="app-page">
    <h2>
        <mat-icon>grid_view</mat-icon>
        PROFILE VIEWS &nbsp;
    </h2>
    <a mat-button [routerLink]="['/testview/', 'NEW']" color="primary">
        <mat-icon>add</mat-icon>
        NEW
    </a>
    <div class="app-page" style="display: flex; flex-wrap: wrap;">

        <ng-container *ngIf="this.TestViews">
            <app-config-test-view-card *ngFor="let view of this.TestViews" [TestView]="view" (onDelete)="refresh()">

            </app-config-test-view-card>
        
        </ng-container>
        

    </div>
</div>