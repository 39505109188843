import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilesListComponent } from './profiles-list/profiles-list.component';
import { ProfilesRoutes } from './profiles.routes';
import { RouterModule } from '@angular/router';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { SharedModuleModule } from '../shared-module/shared-module.module';
import { Globals } from '../globals';
import { ProfileAddComponent } from './profile-add/profile-add.component'
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { TestLimitsEditorComponent } from './test-limits-editor/test-limits-editor.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { ProfileEditorComponent } from './profile-editor/profile-editor.component';
import { LimitEditorDialogComponent } from './limit-editor-dialog/limit-editor-dialog.component';
import { AddTestToProfileDialogComponent } from './add-test-to-profile-dialog/add-test-to-profile-dialog.component';



@NgModule({
    declarations: [ProfilesListComponent, ProfileCardComponent, ProfileAddComponent, TestLimitsEditorComponent, ProfileEditorComponent, LimitEditorDialogComponent, AddTestToProfileDialogComponent],
    imports: [
        CommonModule,
        SharedModuleModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatCardModule,
        RouterModule.forChild(ProfilesRoutes)
    ]
})
export class ProfilesModule { }
