import { Component, OnInit } from '@angular/core';
import { TeamViewModel } from 'src/app/scouting/models/TeamModels';
import { PlayerService } from '../../player.service';

@Component({
  selector: 'app-select-multiple-playerorg-dialog',
  templateUrl: './select-multiple-playerorg-dialog.component.html',
  styleUrls: ['./select-multiple-playerorg-dialog.component.css']
})
export class SelectMultiplePlayerorgDialogComponent implements OnInit {

  teams: TeamViewModel[];
  constructor(private playerSrv: PlayerService) { }

  ngOnInit(): void {
    this.playerSrv.getAllPlayersInTeamsForCurrentSeason().subscribe(teams=>{
      this.teams = teams;
      //console.log(teams);
    });

  }


}
