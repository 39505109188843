<h2 mat-dialog-title style="font-family: Roboto;">
    <mat-icon>person</mat-icon>
    Tags
</h2>
<div mat-dialog-content>
    <div style="height: 200px; overflow-y: scroll; ">
        <table>
            <tr *ngFor="let tag of this.Tags">
                <td style="width: 100px; font-size: small;">
                    <div  [ngClass]="tag.TagColor" >
                        {{tag.TagText}}
                    </div>
                </td>
                <td style="width: 200px;font-size: small;">{{tag.UserLastName}}, {{tag.UserFirstName}}</td>
                <td style="font-size: small;">
                    <button mat-button (click)="removeTag(tag.TagId, tag.PlayerId);"  >REMOVE</button>
                </td>
            </tr>
        </table>
     
        
    </div>
</div> 
<div mat-dialog-actions>
    <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial >SAVE</button> -->
   <button mat-button [mat-dialog-close]="false"  >CLOSE</button>
 
 </div>