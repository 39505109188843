import { TeamOverviewModel } from 'src/app/shared/team.model';
import { ScheduleEvent } from './ScheduleModels';
import { Time } from '@angular/common';

export class GameViewFlatModel {
    HomeTeamId: string;
    HomeTeamName: string;
    HomeTeamSmallIconFileName; string;
    HomeTeamSmallIcon: string;
    HomeTeamTags: TeamPlayerTagViewModel[];

    AwayTeamId: string;
    AwayTeamName: string;
    AwayTeamSmallIconFileName: string;
    AwayTeamSmallIcon: string;
    AwayTeamTags: TeamPlayerTagViewModel[];
    

    GameId: string;
    GameDate: Date;
    GameTime: Date;

    Venue: string;
    Round: number;
    DivisionName: string;
    DivisionOrdinal: number;

    ExternalGameId: string;
    ExternalSourceId: string;

    IsInMySchedule: boolean;
    EventId: string; //For the event in scouting schedule

    

}

export class TeamPlayerTagViewModel{
    TagId: string;
    TagName: string;
    TagColor: string;
    NoOfPlayers: number;
}

export class DivisionGameFilterFlatModel{
    DivisionId: string;
    Name: string;
    CountryISO: string;
    CountryName: string;
    Userid: string;
    DivisionFiltered: boolean;
    GameCount: string;
    GamesStartDate: Date;
    GamesEndDate: Date;
    GamesStartDateUtc: Date;
    GamesEndDateUtc: Date;
}

export class GamePageViewModel
{
    DivisionFilter: DivisionGameFilterFlatModel[];
    Games: GameViewFlatModel[];
}


export class GameDetailsViewModel{

    Game: GameModel;

    HomeTeam: TeamOverviewModel;
    AwayTeam: TeamOverviewModel;

    HomeTeamAvgWeight: number;
    HomeTeamAvgHeight: number;
    HomeTeamAvgAge: number;
    AwayTeamAvgWeight: number;
    AwayTeamAvgHeight: number;
    AwayTeamAvgAge: number;

    Module_Statistics: boolean;
    Module_Lineups: boolean;
    Module_ExternalSources: boolean;
    Module_PlayerTags: boolean;

    HomeStatistics: GameStatisticsPlayerViewModel[];
    AwayStatistics: GameStatisticsPlayerViewModel[];

    ScheduledEvents: ScheduleEvent[];

}

export class GameModel{
   GameId: string;
   DivisionId: string;
   DivisionName: string;
   TeamHomeId: string;
   TeamAwayId: string;
   TeamHomeName: string;
   TeamAwayName: string;
   GameDate: Date;
   GameTime: Date;
   Venue: string;
   Round: number;
   HomeTeamSmallIcon: string;
   AwayTeamSmallIcon: string;
}

export class SCGameLineUpPlayer
{
     GameId: string;
     TeamId: string;
     PlayerId: string;
     LineCode: string;  //1ST,2ND,3RD,4TH,PP1,PP2,PK
     PositionCode: string; //RW,C,LW,RD,LD, GT
     JerseyNo: number;

}

export class GameLineUp
{
        

         constructor(LineUpPlayers: SCGameLineUpPlayer[] ) { 
            
            for(let lup of LineUpPlayers)
            {
                switch (lup.LineCode)
                {
                    case "1ST":
                        switch (lup.PositionCode)
                        {
                            case "RW":
                                this.PlayerId_FirstLineRW = lup.PlayerId;
                                break;
                            case "C":
                                this.PlayerId_FirstLineC = lup.PlayerId;
                                break;
                            case "LW":
                                this.PlayerId_FirstLineLW = lup.PlayerId;
                                break;
                            case "LD":
                                this.PlayerId_FirstLineLD = lup.PlayerId;
                                break;
                            case "RD":
                                this.PlayerId_FirstLineRD = lup.PlayerId;
                                break;
                            case "GT":
                                this.PlayerId_First_GT = lup.PlayerId;
                                break;
                        }
                        break;
                    case "2ND":
                        switch (lup.PositionCode)
                        {
                            case "RW":
                                this.PlayerId_SecondLineRW = lup.PlayerId;
                                break;
                            case "C":
                                this.PlayerId_SecondLineC = lup.PlayerId;
                                break;
                            case "LW":
                                this.PlayerId_SecondLineLW = lup.PlayerId;
                                break;
                            case "LD":
                                this.PlayerId_SecondLineLD = lup.PlayerId;
                                break;
                            case "RD":
                                this.PlayerId_SecondLineRD = lup.PlayerId;
                                break;
                            case "GT":
                                this.PlayerId_Second_GT = lup.PlayerId;
                                break;
                        }
                        break;
                    case "3RD":
                        switch (lup.PositionCode)
                        {
                            case "RW":
                                this.PlayerId_ThirdLineRW = lup.PlayerId;
                                break;
                            case "C":
                                this.PlayerId_ThirdLineC = lup.PlayerId;
                                break;
                            case "LW":
                                this.PlayerId_ThirdLineLW = lup.PlayerId;
                                break;
                            case "LD":
                                this.PlayerId_ThirdLineLD = lup.PlayerId;
                                break;
                            case "RD":
                                this.PlayerId_ThirdLineRD = lup.PlayerId;
                                break;
                        }
                        break;
                    case "4TH":
                        switch (lup.PositionCode)
                        {
                            case "RW":
                                this.PlayerId_FourthLineRW = lup.PlayerId;
                                break;
                            case "C":
                                this.PlayerId_FourthLineC = lup.PlayerId;
                                break;
                            case "LW":
                                this.PlayerId_FourthLineLW = lup.PlayerId;
                                break;
                            case "LD":
                                this.PlayerId_FourthLineLD = lup.PlayerId;
                                break;
                            case "RD":
                                this.PlayerId_FourthLineRD = lup.PlayerId;
                                break;
                            
                        }
                        break;
                    case "PP1":
                        switch (lup.PositionCode)
                        {
                            case "RW":
                                this.PlayerId_PP1_RW = lup.PlayerId;
                                break;
                            case "C":
                                this.PlayerId_PP1_C = lup.PlayerId;
                                break;
                            case "LW":
                                this.PlayerId_PP1_LW = lup.PlayerId;
                                break;
                            case "LD":
                                this.PlayerId_PP1_LD= lup.PlayerId;
                                break;
                            case "RD":
                                this.PlayerId_PP1_RD= lup.PlayerId;
                                break;
                        }
                        break;
                    case "PP2":
                        switch (lup.PositionCode)
                        {
                            case "RW":
                                this.PlayerId_PP2_RW = lup.PlayerId;
                                break;
                            case "C":
                                this.PlayerId_PP2_C = lup.PlayerId;
                                break;
                            case "LW":
                                this.PlayerId_PP2_LW = lup.PlayerId;
                                break;
                            case "LD":
                                this.PlayerId_PP2_LD = lup.PlayerId;
                                break;
                            case "RD":
                                this.PlayerId_PP2_RD = lup.PlayerId;
                                break;
                        }
                        break;
                    case "PK1":
                    case "PK":
                        switch (lup.PositionCode)
                        {
                            case "RW":
                                this.PlayerId_PK_RW = lup.PlayerId;
                                break;
                            
                            case "LW":
                                this.PlayerId_PK_LW = lup.PlayerId;
                                break;
                            case "LD":
                                this.PlayerId_PK_LD = lup.PlayerId;
                                break;
                            case "RD":
                                this.PlayerId_PK_RD = lup.PlayerId;
                                break;
                        }
                        break;
                }
            }
        }
       
        GameId: string;
        TeamId: string;

        //GAME LINEUP
        PlayerId_FirstLineLD: string;
        PlayerId_FirstLineRD: string;
        PlayerId_FirstLineLW: string;
        PlayerId_FirstLineC: string;
        PlayerId_FirstLineRW: string;

        PlayerId_SecondLineLD: string;
        PlayerId_SecondLineRD: string;
        PlayerId_SecondLineLW: string;
        PlayerId_SecondLineC: string;
        PlayerId_SecondLineRW: string;

        PlayerId_ThirdLineLD: string;
        PlayerId_ThirdLineRD: string;
        PlayerId_ThirdLineLW: string;
        PlayerId_ThirdLineC: string;
        PlayerId_ThirdLineRW: string;

        PlayerId_FourthLineLD: string;
        PlayerId_FourthLineRD: string;
        PlayerId_FourthLineLW: string;
        PlayerId_FourthLineC: string;
        PlayerId_FourthLineRW: string;

        PlayerId_First_GT: string;
        PlayerId_Second_GT: string;


        // POWERPLAY
        PlayerId_PP1_LD: string;
        PlayerId_PP1_RD: string;
        PlayerId_PP1_LW: string;
        PlayerId_PP1_C: string;
        PlayerId_PP1_RW: string;

        PlayerId_PP2_LD: string;
        PlayerId_PP2_RD: string;
        PlayerId_PP2_LW: string;
        PlayerId_PP2_C: string;
        PlayerId_PP2_RW: string;

        // PENALTY KILLING
        PlayerId_PK_LW: string;
        PlayerId_PK_RW: string;
        PlayerId_PK_LD: string;
        PlayerId_PK_RD: string;

        CreatedBy: string;
        UpdatedBy: string;
        OrganizationI: number;

}

export class GameLineUpViewModel {
    
}

export class GameStatisticsPlayerViewModel{
    PlayerId: string;
    LastName: string;
    FirstName: string;
    GameId: string;
    TeamId: string;
    
    HomeTeamName: string;
    AwayTeamName: string;
    DivisionName: string;

    StatisticsId: number;
    OrganizationId: number;
    GameDate: Date;
    Name: string;
    CreatedBy: string;
    Description: string;
    Abbreviation: string;
    ValueType: string;
    ValueInteger: number;    
    ValueTime: Time;
    ValueSeconds: number;
    ValueMinutes: number;
    ValueDecimal: number;
    FormattedValue: string;

    ValidationClassName: string;
}

export class GamePlayerInfoViewModel
    {
        GameId: string;
        PlayerId: string;

        HomeTeamId: string;
        AwayTeamId: string;
        HomeTeamName:string;
        HomeTeamSmallIcon:string;
        AwayTeamName:string;
        AwayTeamSmallIcon:string;
        GameDate: Date;
        GameTime: Date;
        Venue:string;
        Round: number;
        DivisionName:string;

        //Reports

        //Statistics
        GameStatistics: GameStatisticsPlayerViewModel[];

        //Grades
        PlayerRankings: GamePlayerRankingModel[];



        //Player grades


        //Lineups
        LineUp: GameLineUpViewModel[];

        

}

export class GamePlayerRankingModel
{
    GameID: string;
    HomeTeamName: string;
    HomeTeamSmallIconFileName: string;
    AwayTeamName: string;
    AwayTeamSmallIconFileName: string;
    GameDate: Date;
    
    ScoutName: string;
    UserId: string;
    ScoutFullName: string;
    PlayerId: string;
    PlayerFirstName: string;
    PlayerLastName: string;
    PositionId: number;
    JerseyNo: number;
    TeamId: string;
    TeamName: string;
    Ranking: number;
    OrganizationId: number;
    DivisionName: string;
    Updated: Date;
}