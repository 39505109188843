import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReactiveFormsModule, FormsModule }   from '@angular/forms';
/* import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators'; */

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { YouTubePlayerModule } from '@angular/youtube-player';

import { SharedModuleModule } from './shared-module/shared-module.module';
import { UserService } from './shared/user.service';
import { TeamService } from './shared/team.service';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { UserComponent } from './user/user.component';
import { SignInComponent } from './user/sign-in/sign-in.component'
import { ChangePasswordComponent} from './user/change-password/change-password.component'

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { PlayerDashboardComponent } from './player-dashboard/player-dashboard.component';
import { HomeComponent } from './home/home.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule, MatProgressBar } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { LayoutModule } from '@angular/cdk/layout';
import { MomentModule } from 'ngx-moment';





import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { ProfilesModule } from './profiles/profiles.module';
import { TeamListComponent } from './team-list/team-list.component';
import { TeamCardComponent } from './team-card/team-card.component';
import { PlayerCardComponent, PlayerLimitsDialog } from './test-result/components/player-card/player-card.component';
import { PlayerListComponent } from './test-result/player-list/player-list.component';
import { TestModule } from './test/test.module';
import { TestResultModule } from './test-result/test-result.module';
import { TestResultService } from './shared/test-result.service';
//import { RankingListModule } from './scouting/rankinglists/ranking-list.module';
import { TrainingModule } from './configuration/training/training.module';


import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { SelectProfileDialogComponent } from './shared/select-profile-dialog/select-profile-dialog.component';
import { PlayerAddTestValueComponent } from './shared/player-add-test-value/player-add-test-value.component';
import { HighScoreComponent } from './shared/high-score/high-score.component';
import { StartComponent } from './start/start.component';
import { Globals } from './globals';
import { PlayerFullDetailsComponent } from './player/player-full-details/player-full-details.component';
import { ConfigGuideComponent } from './config-guide/config-guide.component';
import { PlayerChartNumericComponent } from './charts/player-chart-numeric/player-chart-numeric.component';
import { OrganizationTeamListComponent } from './organization-team-list/organization-team-list.component';
import { TestScatterChartComponent } from './charts/test-scatter-chart/test-scatter-chart.component';
import { TeamSeasonListComponent } from './team-season-list/team-season-list.component';
import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { PlayersTeamSeasonComponent } from './players-team-season/players-team-season.component';
import { TeamOverviewCardComponent } from './team-overview-card/team-overview-card.component';
import { TestResultAddDialogComponent } from './test-result/test-result-add-dialog/test-result-add-dialog.component';
import { PlayerSearchDialogComponent } from './player-search-dialog/player-search-dialog.component';
import { PlayerSearchComponent } from './scouting/player-search/player-search.component';
import { PlayerTeamSeasonEditDialogComponent } from './player-team-season-edit-dialog/player-team-season-edit-dialog.component';
import { PlayerTotalViewComponent } from './scouting/player/player-total-view/player-total-view.component';
import { ScoutingFlowComponent } from './scouting/flow/scouting-flow/scouting-flow.component';
//import { ScoutingRankingListsComponent } from './scouting/rankinglists/scouting-ranking-lists/scouting-ranking-lists.component';
import { ReportCardComponent } from './scouting/components/report-card/report-card.component';
import { NoteCardComponent } from './scouting/components/note-card/note-card.component';
import { ReportOverallGradesComponent } from './scouting/components/report-overall-grades/report-overall-grades.component';
import { GameListComponent } from './scouting/game/game-list/game-list.component';
import { GameCardComponent } from './scouting/components/game-card/game-card.component';
import { GroupByPipe } from './shared/pipes/group-by.pipe';
import { DateDividerComponent } from './scouting/components/date-divider/date-divider.component';
import { GameDetailsComponent } from './scouting/game/game-details/game-details.component';
import { GameHeaderComponent } from './scouting/components/game-header/game-header.component';
import { GameLineupComponent } from './scouting/components/game-lineup/game-lineup.component';
import { LineupPlayerCardComponent } from './scouting/components/lineup-player-card/lineup-player-card.component';
import { TextDividerComponent } from './scouting/components/text-divider/text-divider.component';
import { GameHeaderFullComponent } from './scouting/components/game-header-full/game-header-full.component';
import { TeamRosterComponent } from './scouting/components/team-roster/team-roster.component';
import { TeamRosterPlayerComponent } from './scouting/components/team-roster-player/team-roster-player.component';
import { GameScoutListComponent } from './scouting/components/game-scout-list/game-scout-list.component';
import { GameTaggedPlayersComponent } from './scouting/components/game-tagged-players/game-tagged-players.component';
import { ReportViewDialogComponent } from './scouting/report-view-dialog/report-view-dialog.component';
import { TeamPerformanceScreenComponent } from './performance/screens/team-performance-screen/team-performance-screen.component';
import { TeamPlayerGameGradesComponent } from './performance/components/team-player-game-grades/team-player-game-grades.component';
import { ScheduleViewComponent } from './scouting/schedule/schedule-view/schedule-view.component';
import { ScheduleDayComponent } from './scouting/components/schedule-day/schedule-day.component';
import { GameStatisticsComponent } from './scouting/components/game-statistics/game-statistics.component';
import { ReportViewSkillsComponent } from './scouting/components/report-view-skills/report-view-skills.component';
import { PlayerFullComponent } from './performance/screens/player-full/player-full.component';
import { PlayerTestOverviewComponent } from './performance/components/player-test-overview/player-test-overview.component';
//import { PlayerLoadOverviewComponent } from './performance/components/player-load-overview/player-load-overview.component';
import { PlayerScoutingOverviewComponent } from './performance/components/player-scouting-overview/player-scouting-overview.component';
import { PlayerGameGradesOverviewComponent } from './performance/components/player-game-grades-overview/player-game-grades-overview.component';
//import { PlayerHeaderComponent } from './performance/components/player-header/player-header.component';
import { PlayerGameStatisticsSmallComponent } from './scouting/components/player-game-statistics-small/player-game-statistics-small.component';
import { DivisionSelectDialogComponent } from './scouting/components/division-select-dialog/division-select-dialog.component';
import { AccessDeniedComponent } from './shared/access-denied/access-denied.component';
import { PerformanceTeamPlayerListComponent } from './performance/screens/performance-team-player-list/performance-team-player-list.component';
import { TeamGameListComponent } from './performance/screens/team-game-list/team-game-list.component';
/* import { RankinglistsModule } from './rankinglists/rankinglists.module';
import { RankingListCardComponent } from './scouting/components/ranking-list-card/ranking-list-card.component'; */
import { RankingListDetailsComponent } from './scouting/rankinglists/ranking-list-details/ranking-list-details.component'; 
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FavouritesFlowComponent } from './scouting/flow/favourites-flow/favourites-flow.component';
/* import { RankingListModule } from './scouting/rankinglists/ranking-list.module'; */
/* import { RankingListDetailsComponent } from './scouting/rankinglists/ranking-list-details/ranking-list-details.component';
import { RankingListRoutingModule } from './scouting/rankinglists/ranking-list-routing.module';*/
import { RankingListCardComponent } from './scouting/components/ranking-list-card/ranking-list-card.component'; 
//import { PlayerTotalComponent } from './player/player-total/player-total.component';
//import { PlayerGameOverviewComponent } from './player/player-game-overview/player-game-overview.component';
//import { PlayerAgentOverviewComponent } from './player/player-agent-overview/player-agent-overview.component';
import { TestNavigationComponent } from './test/test-navigation/test-navigation.component';
import { LoadTeamFeedComponent } from './load/load-team-feed/load-team-feed.component';

import { LoadModule } from './load/load.module';
import { LoadPlayerStatusComponent } from './load/load-player-status/load-player-status.component';
import { LoadGameAssessmentComponent } from './load/load-game-assessment/load-game-assessment.component';
import { LoadGameListComponent } from './load/load-game-list/load-game-list.component';
import { LoadGameCardComponent } from './load/load-game-card/load-game-card.component';
import { JsonDatePipe } from './pipes/json-date.pipe';
import { LoadGameDetailsComponent } from './load/load-game-details/load-game-details.component';
import { GameGradeDialogComponent } from './load/game-grade-dialog/game-grade-dialog.component';
import { PlayerDetailsGameListComponent } from './player/components/player-details-game-list/player-details-game-list.component';
import { PlayerDetailsOverviewComponent } from './player/components/player-details-overview/player-details-overview.component';
import { PhysicalStatusChartComponent } from './player/components/physical-status-chart/physical-status-chart.component';
import { PlayerDetailsComponent } from './player-details/player-details.component';
import { GameRankingChartComponent } from './player/components/game-ranking-chart/game-ranking-chart.component';
import { GameParametergroupChartComponent } from './player/components/game-parametergroup-chart/game-parametergroup-chart.component';
import { PlayerLoadChartComponent } from './player/components/player-load-chart/player-load-chart.component';
import { ConfigUserlistComponent } from './configuration/config-userlist/config-userlist.component';
import { AddPlayerInjuryComponent } from './player/components/add-player-injury/add-player-injury.component';
import { InjuryTableTeamComponent } from './injury/screens/injury-table-team/injury-table-team.component';
import { InjuryTeamOverviewComponent } from './injury/screens/injury-team-overview/injury-team-overview.component';
import { EditInjuryComponent } from './injury/screens/edit-injury/edit-injury.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DeleteInjuryDialogComponent } from './injury/components/delete-injury-dialog/delete-injury-dialog.component';
import { InjuryPreviewinfoComponent } from './injury/screens/injury-previewinfo/injury-previewinfo.component';
import { TeamParametersettingsListComponent } from './configuration/team-parametersettings-list/team-parametersettings-list.component';
import { ScoutingRankingListsComponent } from './scouting/rankinglists/scouting-ranking-lists/scouting-ranking-lists.component';
import { PlayerInjuryComponent } from './player/components/player-injury/player-injury.component';
import { PlayerGameTableComponent } from './player/components/player-game-table/player-game-table.component';
import { LoadTeamOverviewComponent } from './load/load-team-overview/load-team-overview.component';
import { ConfigUserDetailsComponent } from './configuration/config-user-details/config-user-details.component';
import { ConfigStatisticsListComponent, DialogStatisticsAddParameterDialog } from './configuration/config-statistics-list/config-statistics-list.component';
import { ConfigStatisticsHomeComponent } from './configuration/config-statistics-home/config-statistics-home.component';
import { ConfigStatisticsGroupComponent } from './configuration/config-statistics-group/config-statistics-group.component';
import { ConfigStatisticsViewComponent } from './configuration/config-statistics-view/config-statistics-view.component';
import { GameStatisticsTableComponent } from './performance/screens/game-statistics-table/game-statistics-table.component';
import { SelectGameStatisticsProfileComponent } from './shared/select-game-statistics-profile/select-game-statistics-profile.component';
import { GameStatisticsTableEditComponent } from './performance/screens/game-statistics-table-edit/game-statistics-table-edit.component';
import { ConfigStatisticsCalculatedListComponent, DialogStatisticsAddParameterCalculatedDialog, DialogStatisticsEditParameterCalculatedDialog } from './configuration/config-statistics-calculated-list/config-statistics-calculated-list.component';
import { LoadGameOverviewComponent } from './load/load-game-overview/load-game-overview.component';
import { LoadGameGradesChartComponent } from './load/load-game-grades-chart/load-game-grades-chart.component';
import { LoadGameSeriesGradeChartComponent } from './load/load-game-series-grade-chart/load-game-series-grade-chart.component';
import { ConfigStatisticsNewprofileDialog, ConfigStatisticsProfilesComponent } from './configuration/config-statistics-profiles/config-statistics-profiles.component';
import { TeamScheduleMonthlyViewComponent } from './TeamSchedule/screens/team-schedule-monthly-view/team-schedule-monthly-view.component';
import { AddTeamEventDialogComponent } from './TeamSchedule/screens/add-team-event-dialog/add-team-event-dialog.component';
import { ConfigStatisticsProfileComponent } from './configuration/config-statistics-profile/config-statistics-profile.component';
import { ConfigAddToProfileDialogComponent } from './configuration/config-add-to-profile-dialog/config-add-to-profile-dialog.component';
import { TeamLoadlevelAnalysisComponent } from './load/team-loadlevel-analysis/team-loadlevel-analysis.component';
import { TeamLoadlevelACWRChartComponent } from './load/team-loadlevel-acwrchart/team-loadlevel-acwrchart.component';
import { TeamLoadlevelChartComponent } from './load/team-loadlevel-chart/team-loadlevel-chart.component';
import { TeamLoadlevelincreaseChartComponent } from './load/team-loadlevelincrease-chart/team-loadlevelincrease-chart.component';
import { ReportCardPlayerComponent } from './scouting/components/report-card-player/report-card-player.component';
import { PlayerProjectionChartComponent } from './scouting/components/player-projection-chart/player-projection-chart.component';
import { PlayerRankingListEntryComponent } from './scouting/components/player-ranking-list-entry/player-ranking-list-entry.component';
import { NoteCardPlayerComponent } from './scouting/components/note-card-player/note-card-player.component';
import { ReportSearchComponent } from './scouting/report-search/report-search.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { ScoutingStatisticsDashboardComponent } from './scouting/scouting-statistics-dashboard/scouting-statistics-dashboard.component';
import { ImportStatisticsComponent } from './performance/screens/import-statistics/import-statistics.component';
import { NewReportComponent } from './scouting/new-report/new-report.component';
import { InvitePlayerDialogComponent } from './shared/invite-player-dialog/invite-player-dialog.component';
import { ConfigSetStatsPlayerViewDialogComponent } from './configuration/config-set-stats-player-view-dialog/config-set-stats-player-view-dialog.component';
import { ConfigurationService } from './shared/configuration.service';
import { AppConfigurationModule } from './app-configuration/app-configuration.module';
import { AddToScheduleDialogComponent } from './scouting/components/add-to-schedule-dialog/add-to-schedule-dialog.component';
import { SetUserPasswordDialogComponent } from './configuration/set-user-password-dialog/set-user-password-dialog.component';
import { PlayerGoalsComponent } from './development/screens/player-goals/player-goals.component';
import { OrganizationGoalOverviewComponent } from './development/screens/organization-goal-overview/organization-goal-overview.component';
import { TeamGoalOverviewComponent } from './development/screens/team-goal-overview/team-goal-overview.component';
import { GoalCardComponent } from './development/components/goal-card/goal-card.component';
import { TeamObjectivesComponent } from './objectives/views/team-objectives/team-objectives.component';
import { OrganizationObjectivesComponent } from './objectives/views/organization-objectives/organization-objectives.component';
import { ObjectiveComponent } from './objectives/components/objective/objective.component';
import { KeyresultComponent } from './objectives/components/keyresult/keyresult.component';
import { TaskComponent } from './objectives/components/task/task.component';
import { KeyresultStatusRowComponent } from './objectives/components/keyresult-status-row/keyresult-status-row.component';
import { StreamObjectivesComponent } from './objectives/views/stream-objectives/stream-objectives.component';
import { OutletTestComponent } from './objectives/views/outlet-test/outlet-test.component';
import { ObjectiveCheckinComponent } from './objectives/views/objective-checkin/objective-checkin.component';
import { SessionListComponent } from './objectives/views/session-list/session-list.component';
import { ObjectiveDetailsComponent } from './objectives/views/objective-details/objective-details.component';
import { ObjectiveStatusRowComponent } from './objectives/components/objective-status-row/objective-status-row.component';
import { NewObjectiveComponent } from './objectives/views/new-objective/new-objective.component';
import { NewObjectiveDialogComponent } from './objectives/components/new-objective-dialog/new-objective-dialog.component';
import { ObjectivePlayerRowComponent } from './objectives/components/objective-player-row/objective-player-row.component';
import { TaskStatusRowComponent } from './objectives/components/task-status-row/task-status-row.component';
import { NewKeyresultDialogComponent } from './objectives/components/new-keyresult-dialog/new-keyresult-dialog.component';
import { EditKeyResultDialogComponent } from './objectives/components/edit-key-result-dialog/edit-key-result-dialog.component';
import { UpdateKeyResultDialogComponent } from './objectives/components/update-key-result-dialog/update-key-result-dialog.component';
import { NewTaskDialogComponent } from './objectives/components/new-task-dialog/new-task-dialog.component';
import { UpdateTaskDialogComponent } from './objectives/components/update-task-dialog/update-task-dialog.component';
import { ObjectivesCurrentStateComponent } from './objectives/components/objectives-current-state/objectives-current-state.component';
import { NewCheckinComponent } from './objectives/views/new-checkin/new-checkin.component';
import { CheckinViewCardComponent } from './objectives/components/checkin-view-card/checkin-view-card.component';
import { ObjectiveStatusDivComponent } from './objectives/components/objective-status-div/objective-status-div.component';
import { OkrGuideComponent } from './objectives/views/okr-guide/okr-guide.component';
import { MyTasksComponent } from './objectives/views/my-tasks/my-tasks.component';
import { OrganizationMissionDialogComponent } from './objectives/components/organization-mission-dialog/organization-mission-dialog.component';
import { EditObjectiveDialogComponent } from './objectives/components/edit-objective-dialog/edit-objective-dialog.component';
import { EditTaskDialogComponent } from './objectives/components/edit-task-dialog/edit-task-dialog.component';
import { MyTaskRowComponent } from './objectives/components/my-task-row/my-task-row.component';
import { PlayercompareChartNumericComponent } from './charts/playercompare-chart-numeric/playercompare-chart-numeric.component';
import { SelectSinglePlayerDialogComponent } from './shared/components/select-single-player-dialog/select-single-player-dialog.component';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { GradeGameComponent } from './performance/screens/grade-game/grade-game.component';
import { NewRankingListComponent } from './scouting/new-ranking-list/new-ranking-list.component';
import { NewRankingListDialogComponent } from './scouting/components/new-ranking-list-dialog/new-ranking-list-dialog.component';
import { RankingListCopyDialogComponent } from './scouting/components/ranking-list-copy-dialog/ranking-list-copy-dialog.component';
import { PlayerHeaderComponent } from './scouting/components/player-header/player-header.component';
import { SkillRankingComponent } from './scouting/components/skill-ranking/skill-ranking.component';
import { EditReportComponent } from './scouting/edit-report/edit-report.component';
import { NewNoteComponent } from './scouting/new-note/new-note.component';
import { GameHeaderSmallComponent } from './scouting/components/game-header-small/game-header-small.component';
import { AgentListComponent } from './scouting/agents/agent-list/agent-list.component';
import { AgentDetailsComponent } from './scouting/agents/agent-details/agent-details.component';
import { AgentCardComponent } from './scouting/components/agent-card/agent-card.component';
import { AgentSelectDialogComponent } from './scouting/agents/agent-select-dialog/agent-select-dialog.component';
import { AssignedProgramsComponent } from './training/views/assigned-programs/assigned-programs.component';
import { AssignProgramDialogComponent } from './training/components/assign-program-dialog/assign-program-dialog.component';
import { TrainingPreviewInfoComponent } from './training/views/training-preview-info/training-preview-info.component';
import { AddPlayerContactDialogComponent } from './scouting/player/add-player-contact-dialog/add-player-contact-dialog.component';
import { PlayerContactCardComponent } from './scouting/components/player-contact-card/player-contact-card.component';
import { GameSearchComponent } from './scouting/game/game-search/game-search.component';
import { AssignedProgramDetailsComponent } from './training/views/assigned-program-details/assigned-program-details.component';
import { NewAssignedProgramComponent } from './training/views/new-assigned-program/new-assigned-program.component';
import { SelectMultiplePlayerorgDialogComponent } from './shared/components/select-multiple-playerorg-dialog/select-multiple-playerorg-dialog.component';
import { AssignedWorkoutProgressCardComponent } from './training/components/assigned-workout-progress-card/assigned-workout-progress-card.component';
import { TeamPlayerScheduleComponent } from './training/views/team-player-schedule/team-player-schedule.component';
import { PlayerScheduleComponent } from './training/views/player-schedule/player-schedule.component';
import { PlayerTrainingDetailsComponent } from './training/views/player-training-details/player-training-details.component';
import { PlayerWorkoutDetailsCardComponent } from './training/components/player-workout-details-card/player-workout-details-card.component';
import { DisplayAssignedWorkoutDialogComponent } from './training/components/display-assigned-workout-dialog/display-assigned-workout-dialog.component';
import { DisplayWorkoutCardComponent } from './training/components/display-workout-card/display-workout-card.component';
import { WeightRoomViewComponent } from './training/views/weight-room-view/weight-room-view.component';
import { AssignWorkoutDialogComponent } from './training/components/assign-workout-dialog/assign-workout-dialog.component';
import { TeamScheduleWeekViewComponent } from './TeamSchedule/screens/team-schedule-week-view/team-schedule-week-view.component';
import { AddTagDialogComponent } from './scouting/player/add-tag-dialog/add-tag-dialog.component';
import { TagListComponent } from './scouting/tags/tag-list/tag-list.component';
import { EditTagDialogComponent } from './scouting/tags/edit-tag-dialog/edit-tag-dialog.component';
import { ShowTagsDialogComponent } from './scouting/player/show-tags-dialog/show-tags-dialog.component';
import { SetNewPasswordComponent } from './user/set-new-password/set-new-password.component';
import { PlayerLoadRatioChartComponent } from './player/components/player-load-ratio-chart/player-load-ratio-chart.component';
import { ConfigTestViewListComponent } from './configuration/config-test-view-list/config-test-view-list.component';
import { ConfigTestViewCardComponent } from './configuration/config-test-view-card/config-test-view-card.component';
import { ConfigTestviewEditComponent } from './configuration/config-testview-edit/config-testview-edit.component';

import { ConfigTestviewAddteamDialogComponent } from './configuration/config-testview-addteam-dialog/config-testview-addteam-dialog.component';
import { ConfigTestviewAdduserDialogComponent } from './configuration/config-testview-adduser-dialog/config-testview-adduser-dialog.component';
import { SelectUsersMultipleDialogComponent } from './shared/components/select-users-multiple-dialog/select-users-multiple-dialog.component';
import { TeamEventDetailsComponent } from './TeamSchedule/screens/team-event-details/team-event-details.component';
import { PlayerRpeChartComponent } from './TeamSchedule/components/player-rpe-chart/player-rpe-chart.component';
import { PlayerFeelingChartComponent } from './TeamSchedule/components/player-feeling-chart/player-feeling-chart.component';
import { TeamEventDetailsDialogComponent } from './TeamSchedule/screens/team-event-details-dialog/team-event-details-dialog.component';
import { ConfigSetGameassessmentDialogComponent } from './configuration/config-set-gameassessment-dialog/config-set-gameassessment-dialog.component';
import { PlayerGameTable2Component } from './player/components/player-game-table2/player-game-table2.component';
import { GameAssessmentsViewComponent } from './configuration/assessments/game-assessments-view/game-assessments-view.component';
import { GameAssessmentCardComponent } from './configuration/assessments/game-assessment-card/game-assessment-card.component';
import { SurveyFeedComponent } from './surveys/screens/survey-feed/survey-feed.component';
import { TeamFormsFeedComponent } from './forms/screens/team-forms-feed/team-forms-feed.component';
import { FormCardComponent } from './forms/components/form-card/form-card.component';
import { QuestionLongTextComponent } from './forms/components/questiontypes/question-long-text/question-long-text.component';
import { QuestionShortTextComponent } from './forms/components/questiontypes/question-short-text/question-short-text.component';
import { QuestionSelectListComponent } from './forms/components/questiontypes/question-select-list/question-select-list.component';
import { QuestionChoiceComponent } from './forms/components/questiontypes/question-choice/question-choice.component';
import { FormSubmissionComponent } from './forms/screens/form-submission/form-submission.component';
import { FormSubmissionEditComponent } from './forms/screens/form-submission-edit/form-submission-edit.component';
import { QuestionDateComponent } from './forms/components/questiontypes/question-date/question-date.component';
import { DynamicSectionComponent } from './forms/components/dynamic-section/dynamic-section.component';

//import { OrgFormsComponent } from './forms/screens/admin/org-forms/org-forms.component';
import { FormsTableComponent } from './forms/screens/forms-table/forms-table.component';
import { FormSubmissionTableComponent } from './forms/components/form-submission-table/form-submission-table.component';
import { FormEditComponent } from './forms/screens/admin/form-edit/form-edit.component';
import { FormEditQuestionComponent } from './forms/components/form-edit-question/form-edit-question.component';
import { FormEditSectionComponent } from './forms/components/form-edit-section/form-edit-section.component';
import { EditFormComponent } from './forms/screens/admin/edit-form/edit-form.component';
import { FormSettingsComponent } from './forms/screens/admin/form-settings/form-settings.component';
import { OrganizationTeamsDashboardComponent } from './dashboards/screens/organization/organization-teams-dashboard/organization-teams-dashboard.component';
import { OrganizationTeamCardComponent } from './dashboards/components/organization-team-card/organization-team-card.component';
import { TeamPhysicalStatusBlockComponent } from './dashboards/components/team-physical-status-block/team-physical-status-block.component';
import { TeamGamesBlockComponent } from './dashboards/components/team-games-block/team-games-block.component';
import { TeamPhysicalTestBlockComponent } from './dashboards/components/team-physical-test-block/team-physical-test-block.component';
import { TeamInjuryBlockComponent } from './dashboards/components/team-injury-block/team-injury-block.component';
import { GameChartsComponent } from './performance/screens/game-charts/game-charts.component';
import { SelectFormDialogComponent } from './forms/components/select-form-dialog/select-form-dialog.component';
import { PlayerDetailsSectionComponent } from './forms/components/player-details-section/player-details-section.component';
import { SelectSingleTeamDialogComponent } from './shared/components/select-single-team-dialog/select-single-team-dialog.component';
import { FormsHelpComponent } from './forms/screens/admin/forms-help/forms-help.component';

import { ChartPlayerProjectionComponent } from './scouting/components/chart-player-projection/chart-player-projection.component';
import { SetGameFilterDialogComponent } from "./scouting/game/set-game-filter/set-game-filter-dialog.component";





//import { LoadGameOverviewComponent } from './load/load-game-overview/load-game-overview.component';



//import { TeamPlayerCardListComponent } from './player/views/team-player-card-list/team-player-card-list.component';
//import { PlayerTotalCardComponent } from './player/components/player-total-card/player-total-card.component';

/* import { RankingListRoutingModule } from './scouting/rankinglists/ranking-list-routing.module'; */

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({ declarations: [
        AppComponent,
        PlayerDashboardComponent,
        HomeComponent,
        ForbiddenComponent,
        AdminPanelComponent,
        UserComponent,
        SignUpComponent,
        SignInComponent,
        ChangePasswordComponent,
        TeamListComponent,
        TeamCardComponent,
        PlayerCardComponent,
        PlayerListComponent,
        ConfirmationDialogComponent,
        SelectProfileDialogComponent,
        PlayerAddTestValueComponent,
        StartComponent,
        //TestListComponent,
        PlayerDetailsComponent,
        PlayerFullDetailsComponent,
        ConfigGuideComponent,
        PlayerChartNumericComponent,
        OrganizationTeamListComponent,
        TestScatterChartComponent,
        TeamSeasonListComponent,
        UserProfileComponent,
        PlayerLimitsDialog,
        ResetPasswordComponent,
        PlayersTeamSeasonComponent,
        TeamOverviewCardComponent,
        HighScoreComponent,
        PlayerSearchDialogComponent,
        PlayerSearchComponent,
        PlayerTeamSeasonEditDialogComponent,
        //PlayerTotalViewComponent,
        //ScoutingFlowComponent,
        ScoutingRankingListsComponent,
        //ReportCardComponent,
        //NoteCardComponent,
        //ReportOverallGradesComponent,
        GameListComponent,
        GameCardComponent,
        GroupByPipe,
        DateDividerComponent,
    //GameDetailsComponent,
    //GameHeaderComponent,
    //GameLineupComponent,
    //LineupPlayerCardComponent,
    //TextDividerComponent,
    //GameHeaderFullComponent,
    //TeamRosterComponent,
    //TeamRosterPlayerComponent,
        GameScoutListComponent,
        GameTaggedPlayersComponent,
        //ReportViewDialogComponent,
        TeamPerformanceScreenComponent,
        TeamPlayerGameGradesComponent,
        ScheduleViewComponent,
        ScheduleDayComponent,
    //GameStatisticsComponent,
        //ReportViewSkillsComponent,
        PlayerFullComponent,
        PlayerTestOverviewComponent,
        //PlayerLoadOverviewComponent,
        PlayerScoutingOverviewComponent,
        PlayerGameGradesOverviewComponent,
        //PlayerHeaderComponent,
        PlayerGameStatisticsSmallComponent,
        DivisionSelectDialogComponent,
        AccessDeniedComponent,
        PerformanceTeamPlayerListComponent,
        TeamGameListComponent,
        RankingListCardComponent,
        //RankingListDetailsComponent,
        FavouritesFlowComponent,
        LoadTeamFeedComponent,
        LoadTeamOverviewComponent,
        LoadPlayerStatusComponent,
        LoadGameAssessmentComponent,
        LoadGameListComponent,
        LoadGameCardComponent,
        LoadGameDetailsComponent,
        LoadGameOverviewComponent,
        LoadGameGradesChartComponent,
        LoadGameSeriesGradeChartComponent,
        TeamLoadlevelAnalysisComponent,
        TeamLoadlevelACWRChartComponent,
        TeamLoadlevelChartComponent,
        TeamLoadlevelincreaseChartComponent,
        GameGradeDialogComponent,
        JsonDatePipe,
        PlayerDetailsGameListComponent,
        PlayerDetailsOverviewComponent,
        PhysicalStatusChartComponent,
        GameRankingChartComponent,
        GameParametergroupChartComponent,
        PlayerLoadChartComponent,
        ConfigUserlistComponent,
        AddPlayerInjuryComponent,
        InjuryTableTeamComponent,
        InjuryTeamOverviewComponent,
        EditInjuryComponent,
        DeleteInjuryDialogComponent,
        InjuryPreviewinfoComponent,
        TeamParametersettingsListComponent,
        PlayerInjuryComponent,
        PlayerGameTableComponent,
        ConfigUserDetailsComponent,
        ConfigStatisticsListComponent,
        ConfigStatisticsHomeComponent,
        DialogStatisticsAddParameterDialog,
        DialogStatisticsAddParameterCalculatedDialog,
        DialogStatisticsEditParameterCalculatedDialog,
        ConfigStatisticsGroupComponent,
        ConfigStatisticsViewComponent,
        GameStatisticsTableComponent,
        SelectGameStatisticsProfileComponent,
        GameStatisticsTableEditComponent,
        ConfigStatisticsCalculatedListComponent,
        ConfigStatisticsProfilesComponent,
        ConfigStatisticsNewprofileDialog,
        TeamScheduleMonthlyViewComponent,
        AddTeamEventDialogComponent,
        ConfigStatisticsProfileComponent,
        ConfigAddToProfileDialogComponent,
        //ReportCardPlayerComponent,
        //PlayerProjectionChartComponent,
        //PlayerRankingListEntryComponent,
        //NoteCardPlayerComponent,
        //ReportSearchComponent,
        WelcomePageComponent,
        ScoutingStatisticsDashboardComponent,
        ImportStatisticsComponent,
        NewReportComponent,
        InvitePlayerDialogComponent,
        ConfigSetStatsPlayerViewDialogComponent,
        AddToScheduleDialogComponent,
        SetUserPasswordDialogComponent,
        PlayerGoalsComponent,
        OrganizationGoalOverviewComponent,
        TeamGoalOverviewComponent,
        GoalCardComponent,
        TeamObjectivesComponent,
        OrganizationObjectivesComponent,
        ObjectiveComponent,
        KeyresultComponent,
        TaskComponent,
        KeyresultStatusRowComponent,
        StreamObjectivesComponent,
        OutletTestComponent,
        ObjectiveCheckinComponent,
        SessionListComponent,
        ObjectiveDetailsComponent,
        ObjectiveStatusRowComponent,
        NewObjectiveComponent,
        NewObjectiveDialogComponent,
        ObjectivePlayerRowComponent,
        TaskStatusRowComponent,
        NewKeyresultDialogComponent,
        EditKeyResultDialogComponent,
        UpdateKeyResultDialogComponent,
        NewTaskDialogComponent,
        UpdateTaskDialogComponent,
        ObjectivesCurrentStateComponent,
        NewCheckinComponent,
        CheckinViewCardComponent,
        ObjectiveStatusDivComponent,
        OkrGuideComponent,
        MyTasksComponent,
        OrganizationMissionDialogComponent,
        EditObjectiveDialogComponent,
        EditTaskDialogComponent,
        MyTaskRowComponent,
        PlayercompareChartNumericComponent,
        SelectSinglePlayerDialogComponent,
        FileUploadComponent,
        GradeGameComponent,
        NewRankingListComponent,
        NewRankingListDialogComponent,
        RankingListCopyDialogComponent,
        PlayerHeaderComponent,
        SkillRankingComponent,
        EditReportComponent,
        NewNoteComponent,
        GameHeaderSmallComponent,
        //AgentListComponent,
        AgentDetailsComponent,
        //AgentCardComponent,
        AgentSelectDialogComponent,
        AssignedProgramsComponent,
        AssignProgramDialogComponent, TrainingPreviewInfoComponent,
        //AddPlayerContactDialogComponent, 
        //PlayerContactCardComponent, 
        GameSearchComponent, AssignedProgramDetailsComponent,
        NewAssignedProgramComponent,
        SelectMultiplePlayerorgDialogComponent,
        AssignedWorkoutProgressCardComponent,
        TeamPlayerScheduleComponent,
        PlayerScheduleComponent,
        PlayerTrainingDetailsComponent,
        PlayerWorkoutDetailsCardComponent,
        DisplayAssignedWorkoutDialogComponent,
        DisplayWorkoutCardComponent,
        WeightRoomViewComponent,
        AssignWorkoutDialogComponent,
        TeamScheduleWeekViewComponent,
        AddTagDialogComponent,
        TagListComponent,
        EditTagDialogComponent,
        ShowTagsDialogComponent,
        SetNewPasswordComponent,
        PlayerLoadRatioChartComponent,
        ConfigTestViewListComponent,
        ConfigTestViewCardComponent,
        ConfigTestviewEditComponent,
        ConfigTestviewAddteamDialogComponent,
        ConfigTestviewAdduserDialogComponent,
        SelectUsersMultipleDialogComponent,
        TeamEventDetailsComponent,
        PlayerRpeChartComponent,
        PlayerFeelingChartComponent,
        TeamEventDetailsDialogComponent,
        ConfigSetGameassessmentDialogComponent,
        PlayerGameTable2Component,
        GameAssessmentsViewComponent,
        GameAssessmentCardComponent,
        SurveyFeedComponent,
        TeamFormsFeedComponent,
        FormCardComponent,
        QuestionLongTextComponent,
        QuestionShortTextComponent,
        QuestionSelectListComponent,
        QuestionChoiceComponent,
        FormSubmissionComponent,
        FormSubmissionEditComponent,
        QuestionDateComponent,
        DynamicSectionComponent,
        //OrgFormsComponent,
        FormsTableComponent,
        FormSubmissionTableComponent,
        FormEditComponent,
        FormEditQuestionComponent,
        FormEditSectionComponent,
        EditFormComponent,
        FormSettingsComponent,
        OrganizationTeamsDashboardComponent,
        OrganizationTeamCardComponent,
        TeamPhysicalStatusBlockComponent,
        TeamGamesBlockComponent,
        TeamPhysicalTestBlockComponent,
        TeamInjuryBlockComponent,
        GameChartsComponent,
        SelectFormDialogComponent,
        PlayerDetailsSectionComponent,
        SelectSingleTeamDialogComponent,
        FormsHelpComponent,
        //ChartPlayerProjectionComponent
    ],
    bootstrap: [AppComponent], imports: [TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    BrowserModule,
    /* RankingListRoutingModule, */
    AppRoutingModule,
    BrowserAnimationsModule,
    MomentModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatTreeModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatSortModule,
    MatTableModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatChipsModule,
    MatRippleModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSliderModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    /*     RxReactiveFormsModule, */
    ProfilesModule,
    TestModule,
    TestResultModule,
    DragDropModule,
    MatTabsModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatMomentDateModule,
    AppConfigurationModule, SetGameFilterDialogComponent], providers: [UserService, TeamService,
        //TestResultService,
        AuthGuard, Globals,
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { 
 

}
