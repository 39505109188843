
<div class="app-page" >    
  
        <mat-form-field appearance="fill" style="font-size: medium;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-hint>YYYY-MM-DD - YYYY-MM-DD</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
          &nbsp;
        <button mat-raised-button (click)="search()" color="primary">
            <mat-icon>search</mat-icon>
            SEARCH
        </button>&nbsp;
        
   



</div>
