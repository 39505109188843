import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ScoutingFlowService } from '../services/scouting-flow.service';
import { ReportViewModel } from '../models/ReportModels';
import { ReportOverallGradesComponent } from '../components/report-overall-grades/report-overall-grades.component';
import { share } from 'rxjs';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { CommonModule } from '@angular/common';
import { ReportViewSkillsComponent } from '../components/report-view-skills/report-view-skills.component';
import { ReportOverallGameComponent } from '../components/report-overall-game/report-overall-game.component';
import { FlowItemModel } from '../models/FlowItemModel';
import { ReportOverallSkillsgroupComponent } from '../components/report-overall-skillsgroup/report-overall-skillsgroup.component';

@Component({
  selector: 'app-report-view-dialog',
  standalone: true,
  imports: [ReportOverallGradesComponent, ReportOverallGameComponent, ReportOverallSkillsgroupComponent, SharedModuleModule, CommonModule, ReportViewSkillsComponent],
  templateUrl: './report-view-dialog.component.html',
  styleUrls: ['./report-view-dialog.component.css']
})
export class ReportViewDialogComponent implements OnInit {  

  Report: ReportViewModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data2: any, public dialogRef: MatDialogRef<ReportViewDialogComponent>, private _reportService: ScoutingFlowService ){}

  ngOnInit() {
    this.getReport(this.data2.ReportId);
  }

  close() {
    this.dialogRef.close();
  }

  getReport(test: string){

    this._reportService.getReport(test).subscribe(report => {
      this.Report = report;
      //console.log(this.Report);
      //console.log(this.Report.SCReport);
      //console.log(this.Report.SCReport.Player);
    });

  }

}
