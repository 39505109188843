import { Component, OnInit } from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';

@Component({
  selector: 'app-outlet-test',
  templateUrl: './outlet-test.component.html',
  styleUrls: ['./outlet-test.component.css']
})
export class OutletTestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
