import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';      
import { HttpClient } from '@angular/common/http';      
 
import { Observable } from 'rxjs';    

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  title = 'fileupload';      
  remark = '';      
  constructor(private httpService: HttpClient) { }      
  myFiles: string[] = [];      
  public myVideos: Observable<VideoMaster[]>;    
       
  @ViewChild('videoPlayer') videoplayer: ElementRef;    
    
  toggleVideo(event: any) {    
      this.videoplayer.nativeElement.play();    
  }    
  getvideos() {    
 /*    debugger;    
   this.myVideos= this.VediosList();     */
  }    
    
 /*  VediosList(): Observable<VideoMaster[]> {    
    return this.httpService.get<VideoMaster[]>('http://localhost:50401/api/FileUpload/Vedios');    
  }    
     */
  sMsg: string = '';      
  StudentIdUpdate: string;      
  ngOnInit() {    
    this. getvideos();    
  }      
        
  getFileDetails(e) {      
    
    //console.log (e.target.files);      
    for (var i = 0; i < e.target.files.length; i++) {      
      this.myFiles.push(e.target.files[i]);      
    }      
  }      
  uploadFiles() {      
    const frmData = new FormData();      
    for (var i = 0; i < this.myFiles.length; i++) {      
      frmData.append("fileUpload", this.myFiles[i]);      
    }      
    this.httpService.post('http://localhost:60194/api/shared/uploadfile', frmData).subscribe(      
      data => {      
        // SHOW A MESSAGE RECEIVED FROM THE WEB API.      
        this.sMsg = data as string;      
        console.log(this.sMsg);      
      }      
    );      
  }    

}

export class VideoMaster  
{  
     int:number;  
     Videos:string;  
}
