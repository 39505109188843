
<div style="margin-left:40px;" id="{{'LoadChart_' + this.PlayerId}}">

</div>

<div id="{{'LoadChart_Planned_' + this.PlayerId}}">

</div>

<div id="{{'LoadChart_Reported_' + this.PlayerId}}">

</div>
<div  style="display: flex; color: white; font-family: Roboto; font-size: small;margin-left:100px;">
    <div style="width:10px; height  :10px; background-color:dodgerblue; border: 1px solid dodgerblue ;">
    
      </div>
      <div style="margin-left: 5px; margin-right: 5px;">
          &nbsp;{{ 'Workouts' | translate }}
      </div>
      <div style="width:10px; height  :10px; background-color:#c632df; border: 1px solid #c632df ;">
    
    </div>
    <div style="margin-left: 5px; margin-right: 5px;">
        &nbsp;{{ 'Event' | translate }}
    </div>
    
      <div style="width:10px; height:10px; background-color:orange;border: 1px solid orange ;">
      </div>
      <div style="margin-left: 5px; margin-right: 5px;">
          &nbsp;{{ 'Game' | translate }}
      </div>
      <div style="width:10px; height:10px; background-color: gray; border: 1px solid gray;">
    </div>
        <div style="margin-left: 5px; margin-right: 5px;">
            &nbsp;Unreported
        </div>
  
</div>



