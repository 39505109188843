<div class="app-page">
  <h2>Tests</h2>
  <button mat-button  (click)="addClick();"><mat-icon>add</mat-icon>NEW</button>

<app-test-card *ngFor="let t of this.Tests" [Test]="t">

</app-test-card>
<!-- <div class="fab-container">
  <button mat-mini-fab  (click)="addClick();" >New</button>

</div> -->

</div>
