import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/baseservice';
import { ScheduleViewModel } from '../models/ScheduleModels';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PlayerDetailsModel } from 'src/app/load/models/player';
import { PlayerSearchParameters } from '../models/PlayerSearchParameters';
import { FlowItemModel, FlowItemViewModel } from '../models/FlowItemModel';
import { ReportSearchParameters } from '../models/ReportSearchParameters';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';
import { PlayerContactModel } from '../models/PlayerModels';
import { ResponseModel } from 'src/app/shared/models/ResponseModel';

@Injectable({
  providedIn: 'root'
})
export class ScoutingPlayerService extends BaseService {
  
  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }


   getPlayerDetails(playerid: string): Observable<any>{
    
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/playerflow/' + playerid );
    return ret;
   }

   getPlayerContacts(playerid: string): Observable<any>{
    
    let ret = this.http.get<any>(this.rootUrl + '/api/load/player/' + playerid + "/contacts" );
    return ret;
   }
   
   updatePlayerContact(contact: PlayerContactModel ): Observable<any>{
    
    let ret = this.http.put<any>(this.rootUrl + '/api/load/player/' + contact.PlayerId + "/contacts", contact );
    return ret;
   }

   addPlayerContact(contact: PlayerContactModel ): Observable<ResponseModel>{
    
    let ret = this.http.post<ResponseModel>(this.rootUrl + '/api/load/player/' + contact.PlayerId + "/contacts", contact );
    
    return ret;
   }

   deletePlayerContact(contact: PlayerContactModel): Observable<any>{
    let ret = this.http.delete(this.rootUrl + '/api/load/player/' + contact.PlayerId + '/contacts/' + contact.ContactId);
    return ret;
   }

   getReportMasterDataForPosition(PositionId: string){
    return this.getReportMasterDataForPositionAndReport(PositionId,'00000000-0000-0000-0000-000000000000');
   }

   getReportMasterDataForOrg(){
    return this.getReportMasterDataForPosition('2');
   }

   getReportMasterDataForPositionAndReport(PositionId: string, ReportId: string){
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/reportmasterdata/' + PositionId + '/' + ReportId );
    return ret;
   }

   getReportSearchMasterDataForOrg(){
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/searchmasterdata/true');
    return ret;
   }



   getReportMasterData(PlayerId: string){
     return this.getReportMasterDataReport(PlayerId,'00000000-0000-0000-0000-000000000000','00000000-0000-0000-0000-000000000000');
    
   }

   getReportMasterDataReport(PlayerId: string, GameId: string, ReportId: string){
    let ret = this.http.get<any>(this.rootUrl + '/api/scouting/reportmasterdata/' + PlayerId + '/' + GameId + '/' + ReportId );
    return ret;
   }



   searchReports(params: ReportSearchParameters): Observable<FlowItemViewModel[]>{
    let reports = this.http.post<FlowItemViewModel[]>(this.rootUrl + '/api/scouting/reports/search', params);

    return reports;
  }

  getFlow(params: ReportSearchParameters): Observable<FlowItemModel[]>{
    let reports = this.http.post<FlowItemModel[]>(this.rootUrl + '/api/scouting/flow', params);

    return reports;
  }

  getFlowReports(params: ReportSearchParameters): Observable<FlowItemModel[]>{
    let reports = this.http.post<FlowItemModel[]>(this.rootUrl + '/api/scoutingflow/reports/search', params);

    return reports;
  }

  getScoutingSearchResultOverview(params: ReportSearchParameters): Observable<any>{
    let reports = this.http.post<FlowItemModel[]>(this.rootUrl + '/api/scouting/reports/searchoverview', params);
    
    return reports;
  }
}
