import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { TestViewViewModel } from 'src/app/shared/models/test.model';
import { TestService } from 'src/app/shared/test.service';

@Component({
  selector: 'app-config-test-view-card',
  templateUrl: './config-test-view-card.component.html',
  styleUrls: ['./config-test-view-card.component.css']
})
export class ConfigTestViewCardComponent implements OnInit {

  @Input() TestView: TestViewViewModel;
  @Output() onDelete:  EventEmitter<any> = new EventEmitter();

  constructor(private dialog: MatDialog, private testService: TestService) { }

  ngOnInit(): void {
  }


  deleteView(){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to delete '" + this.TestView.Name + "'? "
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.testService.deleteTestView(this.TestView.TestViewId).subscribe(res=>{
          if(res){
            this.onDelete.emit({message: 'Deleted'});
          }
            
        });
      }
    });
  }

}
