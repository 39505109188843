import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { GameService } from '../../services/game.service';
import { DivisionGameFilterFlatModel }  from '../../models/GameModels';
//import { GroupByCountryPipe } from '../../../shared/pipes/group-by.pipe';
import { ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';

@Component({
    selector: 'app-set-game-filter',
    templateUrl: './set-game-filter.component.html',
    styleUrl: './set-game-filter.component.css',
    imports: [CommonModule,
      //GroupByCountryPipe,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatButtonModule,
        MatFormFieldModule]
})
export class SetGameFilterComponent implements OnInit {
  divisionsData: DivisionGameFilterFlatModel[];
  _form: UntypedFormGroup;
  isLoaded: boolean = false;

  constructor(private gameService: GameService, 
    private formBuilder:  UntypedFormBuilder,
    private location: Location,
    private router: Router){
    this._form = this.formBuilder.group({
      divisions: new UntypedFormArray([])
    });
  }

  ngOnInit() {
    this.getData();
    
   }
 
   get theDivisions() {
    return this._form.get('divisions') as UntypedFormArray;
   }

  private addCheckboxes() {
    this.divisionsData.forEach((o, i) => {
      const control = new UntypedFormControl(this.divisionsData[i].DivisionFiltered); // if first item set to true, else false
      
      this.theDivisions.push(control);
    });
    console.log(this._form.controls);
    this.isLoaded = true;
  }
  
  getData(){

    this.gameService.getDivisionFilter().subscribe(data=>{
      this.divisionsData = data;      
      this.addCheckboxes();
    });
  }

  submit(){
    const selectedDivisions = this._form.value.divisions
      .map((v:any, i:any) => v ? this.divisionsData[i].DivisionId : null)
      .filter((v:any) => v !== null);
      this.gameService.setDivisionFilter(selectedDivisions).subscribe(res=>{
          console.log(res);
          this.router.navigate(["/scouting/games"]);
      });

  }

  cancel() {
    this.location.back(); // <-- go back to previous location on cancel
  }
}