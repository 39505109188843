<div class="app-page">
  
   
    <app-player-header style="margin-left: 20px;" [Player]="this.Player"></app-player-header>
   
    <mat-divider></mat-divider><br/>
    <form  [formGroup]="noteForm" (ngSubmit)="onSubmit()"> 
        <mat-form-field  appearance="fill"  class="widetextarea">
            <mat-label>Note</mat-label>
            <textarea matInput cdkTextareaAutosize cdkTextareaAutosize
            cdkAutosizeMinRows="5"
            placeholder="Note" formControlName="Note"></textarea>
        </mat-form-field>
        <br/>
        <mat-form-field  appearance="fill"  class="wideinput">
            <mat-label>Location</mat-label>
            <input matInput 
            placeholder="Location" formControlName="Location">
        </mat-form-field>
        <br/>
        <mat-form-field  appearance="fill"  class="wideinput">
            <mat-label>Source</mat-label>
            <input matInput 
            placeholder="Source" formControlName="Source">
        </mat-form-field>
        <br/>
        <mat-divider></mat-divider>   
        <app-game-header-small style="margin-left: 20px;" *ngIf="this.Game" [Game]="this.Game.Game"></app-game-header-small>
        <mat-divider></mat-divider>   
        <br/>
        <button mat-raised-button type="submit" color="primary">SAVE</button>&nbsp;
        <button mat-button type="button" (click)="this.back()" >CANCEL</button>


    </form>
