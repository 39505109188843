import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith} from 'rxjs/operators';

import { UserModel } from 'src/app/shared/user.model';
import { UserService } from 'src/app/shared/user.service';
import { PlayerListService } from '../../services/player-list.service';
import { PlayerListSecurity } from '../../models/PlayerListModels';
import { ActivatedRoute } from '@angular/router';

import { CommonModule } from '@angular/common';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';


@Component({
  selector: 'app-ranking-list-edit',
  standalone: true,
  imports: [ CommonModule, SharedModuleModule, ReactiveFormsModule, MatAutocompleteModule],
  templateUrl: './ranking-list-edit.component.html',
  styleUrl: './ranking-list-edit.component.css'
})
export class RankingListEditComponent implements OnInit {
  
  
  _form: UntypedFormGroup;
  filteredCategories: Observable<string[]>;
  categories: string[] = [];
  users: UserModel[];
  selected: string[];
  loadedCats: boolean = false;
  loadedUsers: boolean = false;
  loadedList: boolean = false;  
  readyLoading: boolean = false;
  theListId: string;


  constructor( 
    private route: ActivatedRoute, 
    public formBuilder: UntypedFormBuilder,
    private listService: PlayerListService,
    private userService: UserService,
    private readonly location: Location
   ) {

    this.theListId = this.route.snapshot.paramMap.get('ListId');
    console.log("edit");
    console.log(this.theListId);
    this.listService.getListDetails(this.theListId).subscribe(list=>{
      
      this._form = this.formBuilder.group({      
        Description: [list.Description, [Validators.required]],
        Category: list.Category,
        PlayerListSecurity: this.formBuilder.array([])          
      });
      console.log("this.loadedList = true;");
      this.loadedList = true;
      this.listService.getCategories().subscribe(data=>{
        this.categories = data as string[];
        this.filteredCategories = this._form.controls['Category'].valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value)),
        );
        this.getUsers(list.PlayerListSecurity);
        console.log("this.loadedCats = true;");
        this.loadedCats = true;
      });
    });
   

    }
  
  ngOnInit(){

  }

  submit(){
    //console.log(this._form.value);
    var security: PlayerListSecurity[] = [];
    this._form.controls['PlayerListSecurity'].value.forEach(sec=>{
      //console.log(sec);
      if(sec.value===true || sec.CanEdit==true){
        var data: PlayerListSecurity = new PlayerListSecurity();
        data.UserId = sec.UserId;     
        data.CanEdit = sec.CanEdit;   
        security.push(data);
      }
      
    });
    
      //console.log("Update");
      this.listService.updateList(this.theListId, this._form.controls['Description'].value, this._form.controls['Category'].value, false, security).subscribe(result=>{
        //this.dialogRef.close(result);
        this.goback();
      });

 
   
  }

  
  getUsers(security: PlayerListSecurity[]){
    this.userService.getAllScoutsActive().subscribe(data=>{
      this.users = data as UserModel[];
      //console.log('users');
      var accessrights = this._form.get('PlayerListSecurity') as UntypedFormArray;
      this.users.forEach(function(e) {
        const SecurityUser =  security.find(user => user.UserId== e.UserId );
        var CanEdit=false;
        if(SecurityUser!=undefined)
          CanEdit = SecurityUser.CanEdit;
        accessrights.push(
          this.formBuilder.group({
            UserId: e.UserId,
            FullName: e.LastName + ', ' + e.FirstName,
            value: security.some(user => user.UserId === e.UserId),
            CanEdit: CanEdit
          })
        );  
      }, this);
      //console.log("loaded Users");
      this.loadedUsers = true;
      console.log(this._form.value);
    });

      //console.log(this._form.value);
  }

  get PlayerListSecurity(): UntypedFormArray{
    return this._form.get('PlayerListSecurity') as UntypedFormArray;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log("_filter");
    return this.categories.filter(option => option?.toLowerCase().includes(filterValue));
    
  }

  goback(){
    this.location.back();
  }
}
