<div style="display: flex; flex-direction: column; margin-top: 8px; ">

    <div style="display: flex; flex-direction: row; width: 100%; padding: 2px ; ">
        <ng-container *ngIf="this.ShowObjectiveInfo==true">
          <div style=" font-family: Roboto; max-width: 400px; flex-grow: 4;">
            <mat-icon style="font-size: 27px;">track_changes</mat-icon>
              {{this.Task.Objective.Title}}<br/>
              <mat-icon >person</mat-icon>
              <label style="font-family: Roboto;">{{this.Task.Objective.OwnerInfo.FirstName}} {{this.Task.Objective.OwnerInfo.LastName}}</label>
            </div>
            <!-- <div style=" font-family: Roboto; max-width: 200px; flex-grow: 1;">
              <mat-icon >person</mat-icon>
              <label style="font-family: Roboto;">{{this.Task.Objective.OwnerInfo.FirstName}} {{this.Task.Objective.OwnerInfo.LastName}}</label>
          </div> -->

        </ng-container>
        
        <div style="width: 32px;">
            <mat-icon style="font-size: 24px; color: white; " *ngIf="this.Task.Completed">check_circle</mat-icon>
            <mat-icon style="font-size: 24px; color: white;" *ngIf="!this.Task.Completed">check_circle</mat-icon>
        </div>
        <div style=" font-family: Roboto; max-width: 400px; flex-grow: 4;">        
            {{this.Task.Title}}
        </div>
        <label style="font-family: Roboto; margin-right: 10px; width: 150px;">
          <mat-icon >event</mat-icon>
          {{this.Task.TargetDate | date }}
        </label>
        <div style=" font-family: Roboto; max-width: 200px; flex-grow: 1;">
            <mat-icon >person</mat-icon>
            <label style="font-family: Roboto;">{{this.Task.OwnerInfo.FirstName}} {{this.Task.OwnerInfo.LastName}}</label>
        </div>
       
        <div style=" font-family: Roboto; max-width: 200px; flex-grow: 1;">
          
          <label style=" font-family: Roboto; background-color: #cbb202; border-radius: 5px; padding: 5px;"*ngIf="!this.Task.Completed; ">TODO </label>
          <label style=" font-family: Roboto; background-color: green;  border-radius: 5px; padding: 5px;"*ngIf="this.Task.Completed">COMPLETED </label>
        </div>
       
          <button mat-raised-button (click)="completeTask()" *ngIf="this.ShowButtons && !this.Task.Completed">SET COMPLETE</button>
          <div *ngIf="this.Task.Completed" style="width: 133px;">
            
       
          </div>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" *ngIf="this.ShowButtons">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="deleteTask()">
                <mat-icon>delete</mat-icon>
                <span>DELETE</span>
              </button>
              <button mat-menu-item (click)="editTask()" >
                <mat-icon>edit</mat-icon>
                <span>EDIT</span>
              </button>
             
            </mat-menu>
    </div>
    <div *ngIf="this.Task.Completed==true" style="display: flex; flex-direction: row; margin-left: 50px; font-size: small;">
      <div>
        <mat-icon style="font-size: 16px; color: green; " *ngIf="this.Task.Completed">check_circle</mat-icon>
      </div>
      <div style="margin-right: 20px;">
        {{this.Task.CompletedDate | date}}
      </div>
      <div style="width: 20px;">
        <mat-icon style="font-size: 16px" >person</mat-icon>
      </div>
      
      <div style="max-width: 150px; width: 200px; overflow-x: hidden; ">
        {{this.Task.CompletedByInfo.LastName}},{{this.Task.CompletedByInfo.FirstName}}
      </div>
      <div style="margin-right: 20px;">
        {{this.Task.CompletedComment}}
      </div>
      
    </div>
</div>
<mat-divider></mat-divider>