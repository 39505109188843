<div style="margin: 5px; width: 100%; display: flex; flex-direction: column;"    >
    <div style=" color: darkgray;">
            {{this.Division.Name}}
    </div>
    <div style="display: flex; align-items: center;"  >
        <div style="display: flex; width: 100%; justify-content: center;">
            <table >
                <tr>
                    <td style="text-align: center; width: 30%;">
                        <img style="height: 60px;" [src]="'../../assets/img/' + this.Game.Game.HomeTeamSmallIcon"><br/>
                        <span>{{this.Game.HomeTeam.team.Name}}</span>
                    </td>
                    <td style="width: 40%;font-size: small; font-family: Roboto; text-align: center; color: darkgray;">
                        {{this.Game.Game.GameDate | date: 'dd'}}
                        {{this.Game.Game.GameDate  | date: 'LLL'}}
                        <div style="font-size: large;">{{this.Game.Game.GameTime | date: 'HH:mm'}}</div>
                        {{this.Game.Game.Venue}}
                    </td>
                    <td style="text-align: center;width: 30%;">
                        <img style="height: 80px;" [src]="'../../assets/img/' + this.Game.Game.AwayTeamSmallIcon"><br/>
                        <span>{{this.Game.AwayTeam.team.Name}}</span>
                    </td>
                    
                    
                </tr>
          
            </table>

       
            <div></div>
        </div>
        <div></div>
        <div></div>
        <div></div>
    </div>

</div>