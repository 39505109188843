import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/baseservice';
import { ScheduleViewModel } from '../models/ScheduleModels';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationLoaderService } from 'src/app/app-configuration/configuration-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService  extends BaseService{

  constructor(private http: HttpClient, private _configLoader: ConfigurationLoaderService) {
    super();
   }

   getSchedule(year: number, month: number): Observable<ScheduleViewModel>{
    //console.log('hå');
    let ret = this.http.get<ScheduleViewModel>(this.rootUrl + '/api/scouting/schedule/' + year + "/" + month );
    return ret;
   }

   

   addGameToSchedule(GameId: string, EventStart: Date, Comment: string, Visibility: boolean ){
     var params = {
       GameId: GameId,
       EventStart: EventStart,
       Comment: Comment,
       Visibility: Visibility

     }
      return this.http.post<boolean>(this.rootUrl + '/api/scouting/schedule/addgame', params);     
   }

   
   removeEventFromSchedule(EventId: string){
    var params = {
      EventId: EventId
    }
    
    return this.http.post<boolean>(this.rootUrl + '/api/scouting/schedule/removeevent', params);
   }

}
