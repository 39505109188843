import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators'
import { ResetPasswordDto, UpdateUserModel, User, UserInvitationModel, UserModel } from './user.model';
import { BaseService } from './baseservice';
import { ConfigurationLoaderService } from '../app-configuration/configuration-loader.service';
import { ResponseModel } from './models/ResponseModel';


export class ChangePasswordModel{
  OldPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
}

export class ResetPasswordModel{
  Password: string;
  Email: string;
  Token: string;
}

@Injectable()
export class UserService extends BaseService {
 
  userRoles: string[];


  constructor(private http: HttpClient) {
    super();
   }

  registerUser(user: User,roles : string[]) {
    const body = {
      UserName: user.UserName,
      Password: user.Password,
      Email: user.Email,
      FirstName: user.FirstName,
      LastName: user.LastName,
      Roles : roles
    }
    var reqHeader = new HttpHeaders({'No-Auth':'True'});
    return this.http.post(this.rootUrl + '/api/User/Register', body,{headers : reqHeader});
  }

  registerPlayer(user: UserInvitationModel) {
    const body = {
      InviteId: user.InviteId,
      UserName: user.UserEmail,
      Password: user.Password,  
      Email: user.UserEmail
    }
    var reqHeader = new HttpHeaders({'No-Auth':'True'});
    return this.http.post(this.rootUrl + '/api/Account/RegisterPlayer', body,{headers : reqHeader});
  }

  userAuthentication(userName, password) {
    var data = "userName=" + userName + "&password=" + password + "&grant_type=password";
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded','No-Auth':'True' });
    return this.http.post(this.rootUrl + '/token', data, { headers: reqHeader });
  }

  resetPassword(resetPwd: ResetPasswordDto){
    
    var data = "UserName=" + resetPwd.username + "&Password=" + resetPwd.password + "&Token=" + resetPwd.token;
    var reqHeader = new HttpHeaders({ 'No-Auth':'True' });
    console.log("resetpassword body:");
    console.log(data);
    return this.http.post(this.rootUrl + '/api/Account/ResetPassword', resetPwd, { headers: reqHeader });
  }

  getUserClaims(){
   return  this.http.get(this.rootUrl+'/api/GetUserClaims');
  }

  getAllRoles() {
    var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get(this.rootUrl + '/api/GetAllRoles', { headers: reqHeader });
  }

  roleMatch(allowedRoles: string[]): boolean {
    var isMatch = false;
     this.userRoles = JSON.parse(localStorage.getItem('userRoles'));
    //console.log(this.userRoles)    ;
    //console.log("AllowedROles:" + allowedRoles + " ");  
    

    for(let role of this.userRoles){
      //console.log("ROle:" + role + " ");  
      

      for(let allowedRole of allowedRoles){
        //console.log("in for 2");
        if (allowedRole===role) {
         //console.log("Role" + role + " is match")   ;
         isMatch = true;
          return true;
        }
      }

   
    }
    
    return false;

  }

  getUser(UserId: string){
    return  this.http.get(this.rootUrl+'/api/users/detail/' + UserId);
  }

  getAllUsers(){
    return  this.http.get(this.rootUrl+'/api/users/all');
  }

  getAllScouts(): Observable<UserModel[]>{
    return  this.http.get<UserModel[]>(this.rootUrl+'/api/users/scouts');
  }

  getAllScoutsActive(): Observable<UserModel[]>{
    return  this.http.get<UserModel[]>(this.rootUrl+'/api/users/scouts/active');
  }

  getUsersByRoles(roles: string[]){
    const body = {
      data: roles
    }
   console.log(body);
   
    return  this.http.post<any>(this.rootUrl+'/api/users/byroles', roles);
  }


  isModuleActive(module: string): boolean{
    var modules: string[] = JSON.parse(localStorage.getItem('Modules'));     
    //console.log(modules.toString() + ' - ' + module);
    //console.log(modules.toString().indexOf(module.toString()));
    return (modules.toString().indexOf(module.toString()) > -1);
  }

  changePassword(data: ChangePasswordModel){
    
    var reqHeader = new HttpHeaders({ 'forwarderror':'True' });
    return this.http.post(this.rootUrl + '/api/Account/ChangePassword', data, { headers: reqHeader });
    
  }

  setNewUserPassword(username: string, password: string): Observable<any>{
    let ret =  this.http.post<ResponseModel>(this.rootUrl + '/api/Account/SetNewPassword', { username: username, newPassword: password, confirmPassword: password},this.httpOptions).pipe(
      catchError(this.handleError('setNewUserPassword', []))
        ) ;
    
    return ret;
  }


  saveUser(updatedUser: UpdateUserModel){
    
    
    return this.http.post(this.rootUrl + '/api/user/save', updatedUser);
    
  }

  
  updateIsenabled(UserId:string, IsEnabled: boolean){
    return  this.http.get(this.rootUrl + '/api/user/isenabled/' + UserId + '/'+ IsEnabled);
  }

  forgotPassword(username: string){
    //console.log('forgotpassword');
    var reqHeader = new HttpHeaders({ 'No-Auth':'True' });
    return this.http.post<any>(this.rootUrl + '/api/Account/ForgotPassword', {UserName: username}, { headers: reqHeader });
  }




  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {    
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // redirect to the login route
          // or show a modal 
          window.location.href="/Account/Login";
          //this.router.navigate(['/notlo//console.log("Redirecting to login");
          
        }
      }
    
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
}

}
