import { Component, OnInit } from '@angular/core';
import { TeamService } from '../shared/team.service';
import { Team } from '../shared/team.model';
import { Globals } from '../globals';
import { PlayerService } from '../shared/player.service';
import { PlayerPosition } from '../shared/models/player.model';


@Component({
  selector: 'app-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.css']
})
export class TeamListComponent implements OnInit {

  teams: Team[];
  positions: PlayerPosition[];

  constructor(private teamService: TeamService, private playerService: PlayerService, private _globals: Globals) { }

  ngOnInit() {
    this._globals.titleBarText="Team list";
    this.teamService.getTeams().subscribe((data: Team[]) =>{
      this.teams = data;
      this.playerService.getPlayerPositionsBySport("1").subscribe(positions=>{
        this.positions = positions as PlayerPosition[];
      });
      //console.log(data);
    });

  }

}
