import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { GameAssessmentPlayerParameter, UpdateGameAssessmentModel } from 'src/app/load/models/EventModels';
import { GameEventViewModel } from 'src/app/load/models/gameevent';

@Component({
  selector: 'app-player-game-table2',
  templateUrl: './player-game-table2.component.html',
  styleUrls: ['./player-game-table2.component.css']
})
export class PlayerGameTable2Component implements OnInit {

  @Input() Games: GameEventViewModel[];
  @Input() NumberOfGames: number;
  @Input() Groups: UpdateGameAssessmentModel;

  public Averages;
  public RankingAverages;

  public matrix;
  public avgs;
  
  public scaleColors: string[] = ['gray','#FF5722','#FF5722','#FF9800','#FFC107','#FFFEB3','#CDDC39', '#8BC34A', '#4CAF50','#4CAF50','#4CAF50'];
  public scaleTextColors: string[] = ['black','black','black','black','black','black','black', 'black', 'black','black','black'];
  public availabilityColors: string[] = ['gray', '#4CAF50','#FF5722','#FF5722'];
  public availabilityText: string[] = ['-','READY TO PLAY','SICK','INJURED','AWAY' ];
  public painScaleColors: string[] = ['#4CAF50','#FFFEB3','#FFFEB3', '#FFC107', '#FFC107', '#FF9800','#FF9800','#FF5722','#FF5722','#FF5722','#FF5722'];
  public painScaleTextColors: string[] = ['white','black','black','black','black','black','black','black','white','white','white'];
  public gamePerformanceIcon: string[] = ['block','sentiment_very_dissatisfied','sentiment_neutral','sentiment_satisfied','sentiment_very_satisfied','star_border'];
  public gamePerformanceColors: string[] = ['','#FF5722','#FFC107','#4CAF50','#2E7D32','#00BCD4'];
  public gamePerformanceTextColors: string[] = ['white', 'white','black','white','white','white'];

  constructor() { }

  ngOnInit(): void {
    //console.log(this.Groups);
    this.calculateAverages();
  }

  ngOnChanges(): void {
    //console.log(this.Groups);
  }


  findValue(gameindex: number, parameterGroupId: number, parameterId: number): number{

    var group = this.Games[gameindex].GameAssessmentGroups.find(f => 
      f.ParameterGroupId==parameterGroupId
    );

    if(group==null){      
      return null;
    }else{

      var param = group.Parameters.find(p=>p.ParameterId==parameterId);
      if(param!=null)
        return param.Ranking;
      else
        return null;
    }
  }

  getAverageForParameter(parameterGroupId: number, parameterId: number): string{
    var average: number;
    var sum=0;
    var num=0;
    //console.log("games length:" + this.Games.length);
    if(this.NumberOfGames>this.Games.length)
      this.NumberOfGames=this.Games.length;

    for (let i = 0; i < this.NumberOfGames; i++) {

      var group = this.Games[i].GameAssessmentGroups.find(f => 
        f.ParameterGroupId==parameterGroupId
      );
      if(group==null){
        /*   console.log('No group found');
          console.log('GAmes:' + i);
          console.log('group:' + parameterGroupId);
          console.log('parameter:' + parameterId); */
      }
      else{
        var param = group.Parameters.find(p=>p.ParameterId==parameterId);
        if(param!=null && param.Ranking!==0){
          num++;
          /* console.log('num:' + num); */
          sum= sum + param.Ranking;
        }        
      }

    }
    if(num>0){
      average = sum/num;
      return average.toFixed(2);
    }
    else 
      return  null;
    
  }
  
  


  calculateAverages(){
    var valueMatrix: number[][];
   
    var gameIndex =0;
    var groupIndex =0;
    var parameterIndex=0;
    var parameterCount=0;   
     var notCounted = true;
    //Check number of parameters to ge matrix dimensions
   
    if(this.Groups.ParameterGroups!=null && notCounted){
      //this.GameAssessmentGroups = game.GameAssessmentGroups;
      this.Groups.ParameterGroups.forEach(function(g){
        parameterCount = parameterCount + g.Parameters.length;    
      }.bind(this));
      notCounted = false;
    }

 
    
    
    //Initiliaze matrix
    this.matrix = new Array(parameterCount).fill(0).map(() => new Array(this.Games.length).fill(0));
    this.avgs = new Array(parameterCount).fill(0).map(() => new Array(this.Games.length).fill(0));
    //console.log("QA");
     //Fill matrix with ranking values, parameter rows, games columns, ra
/*      this.Games.forEach(function(game){
       if(game.GameAssessmentGroups){
         game.GameAssessmentGroups.forEach(function(group){
           group.Parameters.forEach(function(p){
               console.log('Group:' + group.Name);
               console.log('Parameter:' + p.Name);
               console.log('P:' + parameterIndex + ' G:' + gameIndex + ' R:'+p.Ranking);
               
               matrix[parameterIndex][gameIndex]=p.Ranking;
               parameterIndex = parameterIndex + 1;  
           });
           groupIndex = groupIndex + 1;
           gameIndex = gameIndex + 1;
           
         });parameterIndex = 0;  
       }
       
       
     }); */
 
     //Calculate AVG per parameter
    /*  parameterIndex=0;
     gameIndex=0;
  
     this.Groups.ParameterGroups.forEach(function(g){
       
       g.Parameters.forEach(function(p){
         var valueCount=0;
         var sumValue = 0;        
         this.Games.forEach(function(game){
           var tmpValue= matrix[parameterIndex][gameIndex];
           if(tmpValue>0){
             valueCount = valueCount + 1;
             sumValue = sumValue + tmpValue;
             
           }
           avgs[parameterIndex][gameIndex] = (sumValue/valueCount).toFixed(2);
           
           gameIndex = gameIndex +1;
         });
         gameIndex=0;
         parameterIndex = parameterIndex + 1;  
       }, this);
     }, this);
 
     this.Averages = avgs;
     console.log(this.Averages);
     
     //Calculate AVG ranking
     var sumRanking: number=0;
     var rankingCount: number=0;
     var rankingAvg: string[]=[];
     gameIndex=0;
     this.Games.forEach(function(game){
       if(game.Ranking>0)
         sumRanking = sumRanking + game.Ranking;
       if(game.Ranking>0)
         rankingCount=rankingCount + 1;
       rankingAvg.push((sumRanking/rankingCount).toFixed(2));
     });
     console.log(rankingAvg);
     this.RankingAverages = rankingAvg; */
 
       
     
   }


}
