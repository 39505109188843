<div class="app-page"  *ngIf="theform">

<!--        <div style="display: flex; flex-direction: row; align-items: flex-end;">
                 <button mat-icon-button (click)="collapseNew()" *ngIf="this.expanded">
                    <mat-icon>expand_more</mat-icon>
                </button>
                <button mat-icon-button (click)="expandNew()" *ngIf="!this.expanded">
                    <mat-icon>chevron_right</mat-icon>
                </button>
                <div>NEW</div>
                
        </div>  -->
        <h3>
            <mat-icon>dynamic_form</mat-icon>
            SUBMITTED - {{  theform.Name }} ({{teamName}})
        </h3>
        <div id="forms"  style="display: flex; flex-direction: row; padding: 10px;">
            <button mat-raised-button *ngIf="this.theform.AllowCreate" [routerLink]="['/formsubmission/new', formId]" [queryParams]="{TeamId: teamId, TeamName: teamName  }" color="primary" style="margin-right: 3px;" >
                <mat-icon>
                    dynamic_form
                </mat-icon>
                NEW
            </button>
            <button mat-button [routerLink]="['/formsubmission/view/table',  teamId, formId]" [queryParams]="{FormName: theform.Name, TeamName: teamName  }" style="margin-right: 3px;">
                <mat-icon>
                    table_chart
                </mat-icon>
                TABLE
            </button>
            <!-- <div style="display: flex; flex-direction: row; border-color: gray; border-style: solid 1px ; " *ngFor="let form of this.forms ">
                <div  [routerLink]="['/formsubmission/new', form.FormId]" [queryParams]="{TeamId: this.teamId, TeamName: this.teamName}">
                    <button mat-stroked-button color="primary" *ngFor="let form of this.forms " style="margin-right: 3px;">
                        <mat-icon>
                            dynamic_form
                        </mat-icon>
                        {{form.Name}}
                    </button>
                </div>
               

            </div> -->


           
        </div>

        <mat-divider></mat-divider>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
            <app-form-card *ngFor="let form of formssubmitted"  [FormSubmitted]="form" (onDelete)="getSubmittedForms(this.formId,this.teamId)">

            </app-form-card>
            <div *ngIf="formssubmitted==null || formssubmitted.length==0" style="margin-top: 30px; margin-left: 30px; color: gray;">
                NO DATA FOUND<BR/>
                Click on NEW button to submit a form
            </div>

        </div>
      
       
    
</div>
