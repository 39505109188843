import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormService } from 'src/app/shared/form.service';
import { FormViewModel } from 'src/app/shared/models/FormModels';

@Component({
  selector: 'app-forms-table',
  templateUrl: './forms-table.component.html',
  styleUrls: ['./forms-table.component.css']
})
export class FormsTableComponent{
  teamId: string;
  formId: string;
  teamName: string;
  formName: string;
  theform: FormViewModel;

  constructor(private activatedRoute: ActivatedRoute,private formService: FormService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params=>{
      this.teamId = params['TeamId'];
      this.formId = params['FormId'];
      this.formService.getFormSettingsById(this.formId).subscribe(form=>{
        //console.log(form);
        this.theform = form as FormViewModel;        
        
      });
      //console.log(this.teamId);
    });

    this.activatedRoute.queryParams.subscribe(params=>{
      this.teamName = params['TeamName'];
      this.formName = params['FormName'];
      
      //console.log(this.teamId);
    });
  }

}
