
<div style="height: 100%;display: flex; flex-direction: row;" class="app-page">

  <div style="display: block; width: 300px; background-color: black;">
    <div style="display: flex; flex-direction: column; height: 100%;">
      <label> Filter</label>
      <div style="flex: 0 0 auto;">
        <mat-form-field appearance="fill" class="smallinput">
          <mat-label  class="smallinput">Equipment</mat-label>
          <mat-select multiple #ctrlEquipment  class="smallinput" (selectionChange)="refreshDataEq($event)">           
            <mat-option *ngFor="let e of Equipment" [value]="e.EquipmentId">{{ e.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <br/>          
        <mat-form-field appearance="fill" class="smallinput">
            <mat-label  class="smallinput">Targets</mat-label>
            <mat-select multiple #ctrlTargets class="smallinput" (selectionChange)="refreshData($event)" >              
              <mat-option *ngFor="let t of TargetsData" [value]="t.TargetId">{{ t.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <label> Exercise library</label>
      <div style=" flex: 1 1 auto; overflow-y: scroll;"  id="exerciseList" cdkDropList   [cdkDropListData]="filteredExercisesForm"  [cdkDropListConnectedTo]="this.droptargets"  class="example-list" (cdkDropListDropped)="drop1($event)">          
        <div *ngFor="let ex of filteredExercises">
          <div cdkDrag class="mat-elevation-z4" style="display: flex; flex-direction: row; max-width: 200px;  border-radius: 3px; padding: 5px; margin: 8px;border-color: #969696;
          border-style: solid; "
        >
           <!--  <button mat-icon-button>
              <mat-icon>add</mat-icon>
            </button>   -->
            <label >{{ex.Name}}</label>
          </div>
          
        
      </div>
      </div>
   
    </div>
  </div>
  <div style="margin-left: 20px; height: 100%; overflow-y: scroll; width: 100%;"  *ngIf="this.workoutForm">
     <h3 *ngIf="!this.workoutid">NEW WORKOUT</h3>
     <h3 *ngIf="this.workoutid">UPDATE WORKOUT</h3>

      <form [formGroup]="workoutForm" (ngSubmit)="save()" autocomplete="off">
        <mat-form-field appearance="fill"  style="width: 400px;">
          <mat-label>Workout name</mat-label>
          <input matInput placeholder="Enter a name for the workout" formControlName="Name" id="Name" /> 
          <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
         <!--  <mat-hint>Max length 50 characters</mat-hint>  -->
        </mat-form-field>   
        <br/>     
        <mat-form-field appearance="fill"  style="width: 400px;" class="smallinput">
          <mat-label>Description</mat-label>
          <input matInput formControlName="Description" id="Description" /> 
         
        </mat-form-field>  
        <br>
       <mat-form-field appearance="fill"  style="width: 150px;" class="smallinput">
          <mat-label>Estimated duration (minutes)</mat-label>
          <input matInput  formControlName="EstimatedDuration" id="EstimatedDuration" /> 
          
       </mat-form-field>

        <br/>          
        <mat-form-field appearance="fill" class="smallinput">
          <mat-label  class="smallinput">Planned intensity</mat-label>
          <mat-select  class="smallinput" formControlName="Intensity" id="Intensity" [value]="this.workoutForm.Intensity"  >              
            <mat-option value="0">NOT SET</mat-option>
            <mat-option value="1">1 - NOTHING</mat-option>
            <mat-option value="2">2 - VERY EASY</mat-option>
            <mat-option value="3">3 - EASY</mat-option>
            <mat-option value="4">4 - MODERATE LIGHT</mat-option>
            <mat-option value="5">5 - MODERATE</mat-option>
            <mat-option value="6">6 - MODERATE HEAVY</mat-option>
            <mat-option value="7">7 - HARD</mat-option>
            <mat-option value="8">8 - VERY HARD</mat-option>
            <mat-option value="9">9 - EXTREMELY HARD</mat-option>
            <mat-option value="10">10 - MAXIMAL EFFORT</mat-option>
            
        </mat-select>
      </mat-form-field> 
       <br/>
      <mat-form-field appearance="fill" class="smallinput">
          <mat-label  class="smallinput">Status</mat-label>
          <mat-select  class="smallinput" formControlName="Status" id="Status" [value]="this.workoutForm.Status"  >              
            <mat-option value="DRAFT">DRAFT</mat-option>
            <mat-option value="ACTIVE">ACTIVE</mat-option>
            <mat-option value="ARCHIVED">ARCHIVED</mat-option>
        </mat-select>
      </mat-form-field> 
      <br/>
      <br/>
      <label>Sections</label><br/>      
      <mat-divider></mat-divider>
      <br/>
      <div formArrayName="Sections" style=" height: 4; overflow-y: scroll;" >
          <div *ngFor="let section of this.getSections().controls; let sectionno = index; " style="  margin-left: 20px;  margin-bottom: 10px;" >
              <div [formGroupName]="sectionno" style="font-size: small;">
                  <mat-form-field appearance="fill"  style="width: 400px;">
                    <mat-label>Section name</mat-label>
                    <input matInput placeholder="Enter a name for the section" id="Name" formControlName="Name" autocomplete="off" />                   
                  </mat-form-field>
                  <button mat-button type="button" (click)="deleteSection(sectionno)">DELETE SECTION</button>
                  <br/>
                 
                  <div formArrayName="Exercises" style="margin-left: 20px;" >
                    <label >Exercises</label>
                      <div
                              cdkDropList                              
                              [id]="'Section_' + (sectionno+1)"                            
                              [cdkDropListData]="getExercisesForSection(sectionno)?.controls"
                              [cdkDropListConnectedTo]="this.droptargets"
                              class="example-list"
                              (cdkDropListDropped)="drop1($event)">
                          <div *ngFor="let exercise of getExercisesForSection(sectionno)?.controls; let exerciseindex = index">
                              <div [formGroupName]="exerciseindex" class="example-box">
                              <!--   <input type="text"  formControlName="Name">
                                <input type="text" formControlName="ExerciseId">
                                <label>{{ exercise.get('ExerciseId').value }}</label> -->
                              
                                <div   cdkDrag>
                                  <div style="display: flex; flex-direction: row; align-items: center;">
                                      <!-- <div style="background-color: gray; width: 80px; height: 60; padding: 5px;">
                                        
                                        <div *ngIf="exercise.get('Mediafile')===null">
                                          {{exercise.get('Mediafile').get('Type').value}}
                                        </div>
                                        
                                        <video width="80" *ngIf="exercise.get('Mediafile')!=null && exercise.get('Mediafile').controls.get('Type').value==='video' "    #videoPlayer>   
                                          <source [src]="'http://localhost:8081' + exercise.get('Mediafile').controls.get('PathDisk').value + '/' + exercise.get('Mediafile').controls.get('FileNameDisk').value" >   
                                      </video>  
                                      </div> -->
                                      <div>
                                        <mat-icon>
                                          view_list
                                        </mat-icon>
                                        
                                      </div>
                                      <div style="width: 200px;">
                                        {{exercise.get('Name').value}}                                       
                                      </div>
                                      
                                      &nbsp;
                                      <div>
                                        <mat-form-field appearance="fill" class="smallerinputbox">
                                          <mat-label  >Sets</mat-label>
                                          <input matInput type="number" id="Sets" formControlName="Sets">
                                          
                                        </mat-form-field>
                                      </div>
                                      &nbsp;
                                      <div>
                                        <mat-form-field appearance="fill" class="smallerinputbox">
                                          <mat-label  >Reps</mat-label>
                                          <input matInput type="number" id="Reps" formControlName="Reps">
                                          
                                        </mat-form-field>
                                      </div>
                                      &nbsp;
                                      <div>
                                        <mat-form-field appearance="fill" class="smallerinputbox">
                                          <mat-label>Weight</mat-label>
                                          <input matInput type="number" id="Weight" formControlName="Weight">
                                          
                                        </mat-form-field>
                                      </div>                                     
                                      &nbsp;
                                      <mat-form-field appearance="fill" class="smallinputbox">
                                        <mat-label  class="smallinputbox">Weight unit</mat-label>
                                        <mat-select class="smallinputbox"  id="WeightUnit" formControlName="WeightUnit" (selectionchange)="onWeightUnitChange($event)" [value]="" >           
                                          <mat-option>-</mat-option>
                                          <mat-option value="kg" selected>kg</mat-option>
                                          <mat-option value="%">%</mat-option>
                                                                               
                                      </mat-select>
                                      </mat-form-field>
                                      &nbsp;
                                      <div>
                                        <mat-form-field appearance="fill" class="smallerinputbox">
                                          <mat-label  >Duration</mat-label>
                                          <input matInput type="number" id="Duration" formControlName="Duration">                                          
                                        </mat-form-field>
                                      </div>
                                      &nbsp;
                                      <div *ngIf="this.DurationUnits.length>0">
                                        <mat-form-field appearance="fill" class="smallinputbox">
                                          <mat-label  class="smallinputbox">Duration unit</mat-label>
                                          <mat-select class="smallinputbox"  id="DurationUnit" formControlName="DurationUnit" (selectionchange)="onDurationUnitChange($event)" [value]="" >           
                                            <mat-option>-</mat-option>
                                            <mat-option *ngFor="let durationunit of this.DurationUnits" [value]="durationunit.UnitName">{{ durationunit.UnitName }}</mat-option>                                            
                                        </mat-select>
                                        </mat-form-field>
                                      </div>
                                      &nbsp;
                                      <div>
                                        <mat-form-field appearance="fill" class="smallerinputbox">
                                          <mat-label >Rest</mat-label>
                                          <input matInput type="number" id="Rest" formControlName="Rest">
                                          <mat-hint>s</mat-hint>
                                        </mat-form-field>
                                      </div>
                                      &nbsp;
                                      <div *ngIf="this.TrainingMethods.length>0">
                                        <mat-form-field appearance="fill" class="smallinputbox">
                                          <mat-label  class="smallinputbox">Method</mat-label>
                                          <mat-select class="smallinputbox"  id="TrainingMethodId" formControlName="TrainingMethodId" (selectionchange)="onMethodChange($event)" [value]="" >           
                                            <mat-option>-</mat-option>
                                            <mat-option *ngFor="let method of this.TrainingMethods" [value]="method.TrainingMethodId">{{ method.Name }}</mat-option>                                            
                                        </mat-select>
                                        </mat-form-field>
                                      </div>
                                      &nbsp;
                                      <div>
                                        <mat-form-field appearance="fill" class="smallerinputbox">
                                          <mat-label  >Effect</mat-label>
                                          <input matInput type="number" id="Effect" formControlName="Effect"> 
                                          <mat-hint>W</mat-hint>                                         
                                        </mat-form-field>
                                      </div>

                                     
                                      &nbsp;
                                     


                                      <div>
                                        <button type="button" mat-icon-button (click)="removeExerciseFromSection(sectionno, exerciseindex)"><mat-icon>delete</mat-icon></button>
                                      </div>

                                  </div>
                                  
                                </div>
                              </div>

                          </div>
                            <!--  <div
                              cdkDropList
                              [id]="'Exercises_' + exerciseno"
                            
                              [cdkDropListConnectedTo]="[exerciseList]"
                              class="example-list"
                              (cdkDropListDropped)="drop($event)">
                                <div class="example-box" *ngFor="let item of done2" cdkDrag>{{item.Name}}</div>
                              </div> -->
                      </div>
                  </div>

                      
                  
                 
              </div>
              <mat-divider></mat-divider>
          </div>
          
      </div>
      <button mat-raised-button type="button" (click)="addSection()">ADD SECTION</button>&nbsp;<br/>
      <br/>
      <button mat-raised-button type="button" color="primary" (click)="save()" [disabled]="!workoutForm.valid">SAVE</button>&nbsp;
      <button mat-raised-button type="button" (click)="cancel()" >CANCEL</button>
      </form>
    
<!--      <!--  <div>
        {{this.workoutForm.value | json}}
      </div>  --> 
        
  </div>  


  <mat-divider></mat-divider>
  <br/> 

  
 
</div>

   
