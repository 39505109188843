<div class="app-page">

    <ng-container *ngIf="formGroup && form && !loading" [formGroup]="formGroup">
        <h2>{{form.Name}}</h2>
       
        <input type="hidden" id="Name" formControlName="Name"/>
        <input type="hidden" id="FormId" formControlName="FormId"/>

        <h3>
          Usage
        </h3>
        <div style="margin-left: 20px;">
<!--           <div>Settings for where the form can be used</div>
          <div>
          
            <mat-checkbox formControlName="TeamEnabled">For team</mat-checkbox>
          </div> -->
       <!--     <div>
            
            <mat-checkbox formControlName="GameEnabled">For game</mat-checkbox>
          </div>-->
          <div>
            <!-- <input type="checkbox" id="Collapsible" formControlName="Collapsible" >Collapsible -->
            <mat-checkbox formControlName="PlayerEnabled">Player level - Form can created for specific player</mat-checkbox>
          </div>
      </div>
      <h3>
        Form content
      </h3>
        <ng-container formArrayName="Sections" >    
            <ng-container *ngFor="let section of Sections.controls; let i = index;">
                <div class="sectionframe" [formGroupName]="i" >
                    <input type="hidden" id="Version" formControlName="Version"/>
                   
                    <div style="display: flex; flex-direction: row;    align-items: baseline;    justify-content: space-between;">
                        <h2>Section</h2>
                        <!-- <mat-checkbox id="Collapsible" formControlName="Collapsible" >Collapsible</mat-checkbox> -->
                        <div>
                            <!-- <input type="checkbox" id="Collapsible" formControlName="Collapsible" >Collapsible -->
                            <mat-checkbox formControlName="Collapsible">Collapsible section</mat-checkbox>
                        </div>
                        
                        
                    </div>              
                    <mat-form-field appearance="fill" class="smallformfield" >
                        <mat-label>Section text</mat-label>
                        <input matInput id="Text" formControlName="Text" />
                    </mat-form-field>
                    &nbsp;
                   <!--  <button mat-raised-button color="warn" (click)="deleteSection(i);">DELETE SECTION</button> -->
                    <br/>
                
                  <ng-container formArrayName="Questions"  >
                    <ng-container *ngFor="let question of Questions(i).controls; let j = index;">
                        <div class="questionframe" [formGroupName]="j" >
                           <!--  <h3>Question</h3> -->
                            
                                <mat-form-field appearance="fill" style="width: 400px;" class="smallformfield">
                                  <mat-label>Question text</mat-label>
                                  <textarea matInput type="text" maxlength="200" id="Text" formControlName="Text"></textarea>
                                 <!--  <mat-hint>The question text that should be answered</mat-hint> -->
                                </mat-form-field>
                                &nbsp;
                                <mat-form-field appearance="fill" style="width: 400px;" class="smallformfield">
                                  <mat-label>Question long description</mat-label>
                                  <textarea matInput type="text" maxlength="500" id="LongText" formControlName="LongText"></textarea>
                                  <!-- <mat-hint>The description with directions on how to answer</mat-hint> -->
                                </mat-form-field>
                                <br/>
                               
                                  <mat-form-field appearance="fill" style="width: 200px;" class="smallformfield">
                                    <mat-label>Key for value</mat-label>
                                    <input matInput type="text" placeholder="Value key" maxlength="50" required  id="Key" formControlName="Key" />
                                    <mat-hint>Key must be unique in form</mat-hint>
                                  </mat-form-field>
                                   &nbsp;
                                   <mat-form-field appearance="fill" class="smallformfield">
                                    <mat-label>Answer type</mat-label>
                                    <mat-select  id="ControlType" formControlName="ControlType">
                                      <mat-option value="ShortText">
                                        Short text
                                      </mat-option>
                                      <mat-option value="LongText">
                                        Long text
                                      </mat-option>
                                     <!--  <mat-option value="SelectList">
                                        Select list
                                      </mat-option> -->
                                      <mat-option value="DatePicker">
                                        Date picker
                                      </mat-option>
                                      <mat-option value="Rating5">
                                        Rating 1-5
                                      </mat-option>
                                      <mat-option value="Rating6">
                                        Rating 1-6
                                      </mat-option>
                                      <mat-option value="Rating10">
                                        Rating 1-10
                                      </mat-option>
                                     <!--  <mat-option value="PlayerSelect">
                                        Player
                                      </mat-option> -->
                                      <!-- <mat-option value="TeamSelect">
                                        Team
                                      </mat-option> -->
                                    </mat-select>
                                    <mat-hint>How will the question be answered</mat-hint>
                                  </mat-form-field>

                                  &nbsp;
                                  <mat-checkbox formControlName="Required">Required answer</mat-checkbox>
                    
                               
                                <br/>
                               <button mat-raised-button color="warn" (click)="deleteQuestion(i,j);">DELETE</button>
                                
                          
                          
                          </div>
                    </ng-container>
                
                
                    </ng-container> 
                
                    <button mat-stroked-button (click)="addQuestion(i);">ADD QUESTION</button>
                   
                </div>
               
            </ng-container>
            
        </ng-container>
    </ng-container>
    
    <button mat-stroked-button  (click)="addSection()">ADD SECTION</button>

</div>
<br/>
<div style="color: red; margin:20px; font-family: Roboto; font-weight: bold;">
  {{this.errorMessage}}
</div>
<div style="margin-left:20px;">
  <button *ngIf="this.formGroup" mat-raised-button color="primary" (click)="saveAsNewVersion();" [disabled]="!this.formGroup.valid">SAVE NEW VERSION</button>
  <button mat-button onclick="history.back();">CANCEL</button>
</div>


<!-- <div *ngIf="this.formGroup">
    {{this.formGroup.value | json}}
</div> -->