<div style="height: 100%;display: flex; flex-direction: row;" class="app-page" >

    <div style="display: block; margin-top:50px; background-color: black; width: 300px;">
      <div style="display: flex; flex-direction: column; height: 100%;">
      <!--   <label> Filter</label>
        <div style="flex: 0 0 auto;">
          <mat-form-field appearance="fill" class="smallinput">
            <mat-label  class="smallinput">Equipment</mat-label>
            <mat-select multiple #ctrlEquipment  class="smallinput" (selectionChange)="refreshDataEq($event)">           
              <mat-option *ngFor="let e of Equipment" [value]="e.EquipmentId">{{ e.Name }}</mat-option>
          </mat-select>
          </mat-form-field>
          <br/>          
          <mat-form-field appearance="fill" class="smallinput">
              <mat-label  class="smallinput">Targets</mat-label>
              <mat-select multiple #ctrlTargets class="smallinput" (selectionChange)="refreshData($event)" >              
                <mat-option *ngFor="let t of TargetsData" [value]="t.TargetId">{{ t.Name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <label> Workout library</label>
        <div style=" flex: 1 1 auto; overflow-y: scroll;"  id="workoutList" cdkDropList   [cdkDropListData]="filteredWorkoutsForm"  [cdkDropListConnectedTo]="this.droptargets"  class="example-list" (cdkDropListDropped)="drop1($event)">          
          <div *ngFor="let ex of filteredWorkouts">
            <div cdkDrag class="mat-elevation-z4" style="display: flex; flex-direction: row; max-width: 250px;  border-radius: 3px; padding: 5px; margin: 8px;border-color: #565656;
            border-style: solid; "
          >
             <!--  <button mat-icon-button>
                <mat-icon>add</mat-icon>
              </button>   -->
              <label >{{ex.Name}}</label>
            </div>
            
          
        </div>
        </div>
     
      </div>
    </div>
    <div style="margin-left: 20px;" *ngIf="this.isLoaded==true">
      
       <h3 *ngIf="!this.isUpdate">NEW PROGRAM</h3>
       <h3 *ngIf="this.isUpdate">UPDATE PROGRAM</h3>
  
        <form [formGroup]="programForm" (ngSubmit)="save()" autocomplete="off">
          <mat-form-field appearance="fill"  style="width: 400px;">
            <mat-label>Program name</mat-label>
            <input matInput placeholder="Enter a name for the program" formControlName="Name" id="Name" /> 
            <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
           <!--  <mat-hint>Max length 50 characters</mat-hint>  -->
          </mat-form-field>   
          <br/>     
          <mat-form-field appearance="fill"  style="width: 400px;" class="smallinput">
            <mat-label>Description</mat-label>
            <input matInput formControlName="Description" id="Description" />            
          </mat-form-field>  
          <br>
        
          <br/> 
        <mat-form-field appearance="fill" class="smallinput">
            <mat-label  class="smallinput">Status</mat-label>
            <mat-select  class="smallinput" formControlName="Status" id="Status" [value]="this.programForm.Status"  >              
              <mat-option value="DRAFT">DRAFT</mat-option>
              <mat-option value="ACTIVE">ACTIVE</mat-option>
              <mat-option value="ARCHIVED">ARCHIVED</mat-option>
          </mat-select>
        </mat-form-field> 
        <br/>
        <br/>
        <label>SCHEDULE</label>
        <!-- <button mat-button type="button" (click)="addWeek()">ADD WEEK</button>&nbsp;<br/> -->
        <br/>      
        <mat-divider></mat-divider>
        <br/>
        <div  formArrayName="Weeks">
          <div *ngFor="let week of this.programForm.get('Weeks').controls; let weekNo = index" [formGroupName]="weekNo">
            <label>WEEK {{weekNo+1}}</label>&nbsp;
            <button type="button" mat-raised-button (click)="removeWeek(weekNo)">REMOVE</button>
            <div  class="example-box">
              <div  formArrayName="Days" style=" height: 4; display: flex; flex-direction: row; flex-wrap: wrap; " >
                <div *ngFor="let theDay of week.get('Days')?.controls; let dayno = index"  class="dayframe" [formGroupName]="dayno">
                  <div style="display: flex; flex-direction: column;">
                    <div >
                      <center>
                        <h3>{{this.DayNames[dayno % 7]}}</h3>
                        <mat-divider></mat-divider>
                      </center>
                      
                    </div>
                    <div formArrayName="ProgramWorkouts" style="margin-left: 10px;" >
                     
                      <div
                              cdkDropList                              
                              [id]="'Day_' + (dayno) + '_' + (weekNo)"
                              [cdkDropListData]="theDay.get('ProgramWorkouts')?.controls"
                              [cdkDropListConnectedTo]="this.droptargets"
                              class="example-list"
                              (cdkDropListDropped)="drop1($event)">
                          <div *ngFor="let workout of theDay.get('ProgramWorkouts')?.controls; let workoutindex = index" [formGroupName]="workoutindex" class="workout">
                              
                                <div   cdkDrag>
                                  <div style="display: flex; align-items: center;">                                     
                                        <mat-icon>view_list</mat-icon>
                                        <label>{{workout.controls['Name'].value}}</label>                                
                                  </div>                                                                 
                                  </div>
                              
                          </div>  
                          
                      </div>
          
                  </div> 
       
                  </div>
                 <!--  <input  formControlName="Day" id="Day"/> -->
                </div>  
              </div>
             
            </div> 
            

            
          </div>
        </div>
       
           <!--  <div [formGroupName]="weekindex"> -->
             
             
              <!-- <div formArrayName="Days" style=" height: 4; overflow-y: scroll;display: flex; flex-direction: row; flex-wrap: wrap; min-width: 1215px; max-width: 1215px; " >

                <div *ngFor="let day of this.daysforweek(weekindex)?.controls; let dayno = index; " class="dayframe">
                    <div [formGroupName]="dayno" style="font-size: small;">
                        <div style="display: flex; flex-direction: row;">
                            <div>
                              <h3>{{this.DayNames[dayno % 7]}}</h3>
                            </div>
               
                        </div>
                      
                        <br/>
                      
                        <div formArrayName="Workouts" style="margin-left: 20px;" >
                        
                            <div
                                    cdkDropList                              
                                    [id]="'Day_' + (dayno)"
                                    [cdkDropListData]="getWorkoutsForDay(dayno)?.controls"
                                    [cdkDropListConnectedTo]="this.droptargets"
                                    class="example-list"
                                    (cdkDropListDropped)="drop1($event)">
                                <div *ngFor="let workout of getWorkoutsForDay(dayno)?.controls; let workoutindex = index">
                                    <div [formGroupName]="workoutindex" class="example-box">
                                    --   <input type="text"  formControlName="Name">
                                      <input type="text" formControlName="ExerciseId">
                                      <label>{{ exercise.get('ExerciseId').value }}</label> --
                                    
                                      <div   cdkDrag>
                                        <div style="display: flex; flex-direction: row;">

                                            <div style="width: 300px;">
                                              {{workout.get('Name').value}}                                       
                                        
                                            </div>
                                        </div>                                                                 
                                        </div>
                                    </div>
                                </div>  
                                <mat-divider></mat-divider>
                            </div>
                
                        </div> 
                        
                    </div>
                </div>
            
              </div>  
            </div>-->
       <!--    </div>
          
          

           
        </div> -->

        <button mat-raised-button type="button"  (click)="addWeek()" >ADD WEEK</button>&nbsp;
        <br/>
        <br/>
        <button mat-raised-button type="button" color="primary" (click)="save()" [disabled]="!programForm.valid">SAVE</button>&nbsp;
        <button mat-raised-button type="button" (click)="cancel()" >CANCEL</button>
        </form>

     <!--    <div>
          {{this.droptargets}}
        </div>
        <div>
          {{this.programForm.value | json}}
        </div> -->
      
    </div>
         
    
  
    <mat-divider></mat-divider>
    <br/> 

    
   
</div>
  
     
  