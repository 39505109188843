<!-- <mat-divider></mat-divider>
<div style="width: 100%; display: block; ">
    <table style="width: 100%; ">
        <tr>
            <td style="width: 30px;">
                <mat-icon style="font-size: small;">assignment_turned_in</mat-icon>
            </td>
            <td>
                <div style="display: flex; flex-direction: row; width: 100%; padding: 2px ; ">
                    <div style="font-size: smaller; font-family: Roboto; max-width: 200px; flex-grow: 4;">        
                        {{this.KeyResult.Title}}<br/>
                        <label style="font-size: x-small; color: gray; font-family: Roboto;">Fredrik Sjostrom</label>
                    </div>
                    <mat-progress-bar mode="determinate"  value="{{this.valueInPercent}}" style="width: 80px; padding-top: 10px; flex-grow: 1;"></mat-progress-bar><label style="font-size: x-small; font-family: Roboto;">{{this.KeyResult.ValueDecimal}}</label>
                </div>
            </td>
        </tr>
    </table>
</div> -->
<div style="display: flex; flex-direction: row; margin-top: 8px; ">

    <div style="display: flex; flex-direction: row; width: 100%; padding: 2px ; ">
        <div style="width: 32px;">
            <mat-icon style="font-size: 24px; color: lightseagreen;">show_chart</mat-icon>
        </div>
        <div style=" font-family: Roboto; max-width: 400px; flex-grow: 4;">        
            {{this.KeyResult.Title}}
        </div>
        <label style="font-family: Roboto; margin-right: 10px; min-width: 150px; width: 150px; max-width: 150px;">
            <mat-icon >pending_actions</mat-icon>
            {{this.KeyResult.TargetDate | date }}
          </label>
        <div style=" font-family: Roboto; max-width: 200px; flex-grow: 1;">
            <mat-icon >person</mat-icon>
            <label style="font-family: Roboto;">{{this.KeyResult.OwnerInfo.FirstName}} {{this.KeyResult.OwnerInfo.LastName}}</label>
        </div>
        <div style="display: flex;flex-direction: column; max-width: 200px; width: 200px;">
            <div style="display: flex; flex-direction: row;justify-content: space-between;">
                <div style="font-size: small; color: gray;">{{ this.KeyResult.BaselineInteger }}</div>
                <div  style="font-size: small;color:gray;">{{ this.KeyResult.TargetInteger }}</div>
            </div>
           
            <mat-progress-bar mode="determinate"  value="{{this.getPercentageValue()}}" style="max-width: 200px; padding-top: 10px; flex-grow: 1;"></mat-progress-bar>
        </div>
        
        <label style="font-size: large; font-family: Roboto; margin-left: 10px; margin-top: 10px; width: 50px;">{{this.KeyResult.CurrentInteger}}</label>
        
            <button mat-button (click)="updateKeyResultValue()" *ngIf="this.ShowButtons==true">UPDATE</button>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" *ngIf="this.ShowButtons==true">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" >
                <button mat-menu-item (click)="deleteKeyResult()">
                  <mat-icon>delete</mat-icon>
                  <span>DELETE</span>
                </button>
                <button mat-menu-item (click)="editKeyResult()" >
                  <mat-icon>edit</mat-icon>
                  <span>EDIT</span>
                </button>
               
              </mat-menu>
    
        
    </div>
</div>


