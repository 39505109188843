import { Component, OnInit, Output } from '@angular/core';
import { TeamService } from '../shared/team.service';
import { Season, Team, TeamOverviewModel } from '../shared/team.model';
import { PlayerService } from '../shared/player.service';
import { PlayerViewModel } from '../shared/models/player.model';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PlayerSearchDialogComponent } from '../player-search-dialog/player-search-dialog.component';
import { EventEmitter } from 'events';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { PlayerTeamSeasonEditDialogComponent } from '../player-team-season-edit-dialog/player-team-season-edit-dialog.component';

@Component({
  selector: 'app-players-team-season',
  templateUrl: './players-team-season.component.html',
  styleUrls: ['./players-team-season.component.css']
})
export class PlayersTeamSeasonComponent implements OnInit {

  _seasons: Season[];
  _teams: Team[];
  _players: PlayerViewModel[];
  _teamName: string;
  _level: string;
  private _teamId : string;
  public dataSource = new MatTableDataSource<PlayerViewModel>();
  public displayedColumns: string[] = ['JerseyNo','LastName', 'FirstName','Position','ShootId','ProfileImage', 'Actions'];

  teamFormControl = new UntypedFormControl('');
  seasonFormControl = new UntypedFormControl('');

  public _positionName: string[] = ['','Goalkeeper', 'Defender','Defender','Center','Forward','Forward'];
  public _shootName: string[] = ['',' L ' ,' R ']

  

  constructor(
     private _teamService: TeamService,
     private _playerService: PlayerService,
     private _route: ActivatedRoute,
     private dialog: MatDialog) { }

  ngOnInit() {

       

    this._route.params.subscribe(params => {
      this._teamId = params['TeamId'];
      this._teamName = params['TeamName'];
      this._level = params['Level'];

     

     

      
    });
    this._teamService.getSeasons().subscribe((s: Season[])=>{
            this._seasons = s; 
            this.seasonFormControl.setValue(this._seasons[this._seasons.length-1]);
            this.refreshData();
          }); 
    /* this._teamService.getTeams().subscribe((t: Team[])=>{
      this._teams = t;
    }); */

    //Get players in team fro the season
  
  }

  refreshData(){
    let tmp: Season = this.seasonFormControl.value;
    
    this._playerService.getPlayersByTeamAndSeasonSimple(this._teamId, tmp.SeasonId.toString()).subscribe((team: TeamOverviewModel)  =>{
      this._players = team.Players;

      this.dataSource = new MatTableDataSource(team.Players);
    });
  }

  openSearch(){
    const dialogRef = this.dialog.open(PlayerSearchDialogComponent, {
      width: '400px',
      data: {
        TeamId: this._teamId,
        SeasonId: this.seasonFormControl.value.SeasonId.toString()
      }      

     
    });
    const sub = dialogRef.componentInstance.dataChanged.subscribe(() => {
        this.refreshData();
      });
    

  }

  OpenPlayersWithTestResults(){
    alert("Not implemented yet");
  }

  OpenPlayersInOrg(){
    alert("Not implemented yet");
  }

  

  deleteFromTeamSeason(PlayerId: string){
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to remove the player from the team?"
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        
        this._playerService.DeleteFromTeamSeason(PlayerId,this._teamId,this.seasonFormControl.value.SeasonId.toString() ).subscribe(res=>{
          this.refreshData();
        });
        //Notify about delete
        
      }
    });
    
  }

  editTeamSeason(PlayerId: string, LastName: string, FirstName: string, editProfileImage: string, JerseyNo: string, ShootId: string, PositionId: string,){
    console.log(editProfileImage);
    const dialogRef=this.dialog.open(PlayerTeamSeasonEditDialogComponent, {
      width: '500',
      data: {        
        PlayerId: PlayerId,
        PlayerName: LastName + ', ' + FirstName,
        ProfileImage: editProfileImage,
        TeamId: this._teamId,
        SeasonId: this.seasonFormControl.value.SeasonId.toString(),
        ShootId: ShootId,
        PositionId: PositionId,
        JerseyNo: JerseyNo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("after close, emit");
      this.refreshData();
//      this.changed.emit();
    });

    
    
  }

  AddAllFromCurrentTeam(){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Do you want to import the current roster from the Dalecarlia Scouting player database?"
    });


    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this._playerService.AddCurrentPlayersToTeamSeason(this._teamId,this.seasonFormControl.value.SeasonId.toString()).subscribe(res=>{
          this.refreshData();
        });
      }
    });

    
  }

}
