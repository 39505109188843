<h1 mat-dialog-title>Select profile</h1>
<div mat-dialog-content >
  
  <form id="profile">
   
        <mat-form-field>
    <mat-label>Position</mat-label>

    <mat-select [(value)]="PlayerPositionId"  >
      <mat-option *ngFor="let p of this._positions" [value]="p.PlayerPositionId">{{ p.Text }}</mat-option>        
    </mat-select>
   
  </mat-form-field>
  <mat-form-field>
    <mat-label>Profile</mat-label>
    <mat-select [(value)]="profileId"  >
      <mat-option *ngFor="let p of this._profiles" [value]="p.ProfileId">{{ p.Name }}</mat-option>        
    </mat-select>
   
  </mat-form-field>
  </form>

 </div>
 <div mat-dialog-actions>
    <button mat-raised-button color="primary"  (click)="onCloseConfirm()"   cdkFocusInitial>SAVE</button>
   <button mat-button (click)="onNoClick()">CANCEL</button>
 </div>
