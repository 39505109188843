<div style="display: flex; align-content: center; justify-content: center; width: 100%; margin-top: 100px;">
  <mat-card appearance="outlined" style="width: 350px;">
      <mat-card-header>
          <img  src="../../assets/img/DS-vit.png" style="height: 40px;"/>
         
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="changePwdForm"  (ngSubmit)="OnSubmit($event)" >
    
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Old password</mat-label>
          
            <input matInput type="password" #OldPassword  name="OldPassword" placeholder="Old password" required formControlName="OldPassword">
            <mat-icon matSuffix>vpn_key</mat-icon>
            
        </mat-form-field>
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>New password</mat-label>
        
          <input matInput type="password" #NewPassword  name="NewPassword" placeholder="New password" required formControlName="NewPassword">
          <mat-icon matSuffix>vpn_key</mat-icon>
          
      </mat-form-field>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Confirm password</mat-label>
      
        <input matInput type="password" #ConfirmPassword  name="ConfirmPassword" placeholder="Confirm password" required formControlName="ConfirmPassword">
        <mat-icon matSuffix>vpn_key</mat-icon>
        
    </mat-form-field>
    
      <br/>
      <div>
        Password must be 6 characters or longer, contain special characters, uppercase letters,  lowercase letters and digits.
      </div>   
        
      <br/> 
      <button mat-button [disabled]="this.changePwdForm.invalid"  type="button" (click)="OnSubmit($event)">SAVE</button>
      
    
    </form>
    <div>
      {{ this.errorMessage | json}}
    </div>
      </mat-card-content>
      
    </mat-card>
    
</div>

