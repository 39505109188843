<form [formGroup]="keyresultForm" (ngSubmit)="onSubmit()">
    <input type="hidden" id="ObjectiveId" formControlName="ObjectiveId">
    <input type="hidden" id="KeyResultId" formControlName="KeyResultId">
    <h2 mat-dialog-title>Edit Key Result</h2>
    <mat-dialog-content style="font-size: smaller;">
    
            <mat-form-field appearance="fill" style="width: 380px;">
                <mat-label>Key result</mat-label>
                <input matInput id="Title" type="text" formControlName="Title">
            </mat-form-field>
            <br/>
            <mat-form-field appearance="fill">
                <mat-label>Objective owner</mat-label>
                <mat-select formControlName="Owner">            
                    <mat-option *ngFor="let usr of this.users" [value]="usr.UserId">{{usr.LastName}}, {{usr.FirstName}}</mat-option>
                </mat-select>
            </mat-form-field>
            <br/>
             
          <mat-form-field appearance="fill" class="smallinput">
            <mat-label>Start date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="StartDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
           &nbsp;
          <mat-form-field appearance="fill" class="smallinput">
            <mat-label>Target date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="TargetDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
                        <br/>
        
            <mat-form-field appearance="fill" style="width: 100px;">
            <mat-label>Initial value</mat-label>
            <input matInput id="BaselineInteger" type="text" formControlName="BaselineInteger">
            </mat-form-field>
            <br/>
            <mat-form-field appearance="fill" style="width: 100px;">
                <mat-label>Target value</mat-label>
                <input matInput id="TargetInteger" type="text" formControlName="TargetInteger">
            </mat-form-field>
            <br/>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button  type="submit">SAVE</button>
            <button mat-button mat-dialog-close>CANCEL</button> 
        
    </mat-dialog-actions>
        
        
</form>
    