<div class="app-page">
<div style="margin: 20px">

    <mat-card  appearance="outlined"  style="margin-top:10px;" >
      <mat-card-header>        
        
      </mat-card-header>
        <mat-card-content>
  
  
          <table style="width:100%">
              <tr>
                <td style="width: 30px;vertical-align:top;">
                  <span style=" font-size: 24px; vertical-align: text-top;">
                     
                     {{ this.gameDetails?.EventDate | date:'dd'  }} 
                 </span><br>
   
                  {{this.gameDetails?.EventDate  | date:'MMM'}}<br/>
                  {{this.gameDetails?.EventDate  | date:'EEE' }}  
                </td>
                <td>
                  <div >
                      <div style="flex-grow: 1;">
                          <div style="color: white; display:block; font-size: 18px;" >{{ this.gameDetails?.HomeTeam.Name }}</div>
                          <div style="color: white;  display:block;font-size: 18px;">{{ this.gameDetails?.AwayTeam.Name }}</div>           
                          <div style=" display:block; color:gray;">{{this.gameDetails?.DivisionName}}</div>                
                      </div>
                    
                  </div>
                </td>
              </tr>
            </table>
  
  
        </mat-card-content>
        <mat-card-actions>
          
        </mat-card-actions>
        
    </mat-card>
  
  
<!--     <h2 style="border-bottom: orange solid 1px;">
      <mat-icon class="example-icon" style="color:orange;">equalizer</mat-icon>
    {{ 'Översikt' | translate }}    
    
  </h2> 
  <div style="display: flex;
              justify-content: space-evenly;
              flex-wrap: wrap;
              align-items: baseline;">
      <div id="chart_grades" style="height: 100px; width: 300px;" ></div>
      <div id="chart_gameprep" style="height: 250px; width: 300px;" ></div>       
  </div> -->
  <h2 >
    Overview
  </h2>
  <div style="display: flex; flex-direction: row; gap: 20px; flex-wrap: wrap; ">
    <div id="freshness_chart_div" style="width: '400px'; height: '200px';"></div>    
    <div id="sleep_chart_div" style="width: '400px'; height: '200px';"></div>    
    <div id="confidence_chart_div" style="width: '400px'; height: '200px';"></div>    
    <div id="pains_chart_div" style="width: '400px'; height: '200px';"></div>    
    <div id="grade_chart" style="width: '400px'; height: '200px';"></div>
  </div>
  <mat-divider></mat-divider>


  <h2 >
    Goaltenders
  </h2>
  <mat-divider></mat-divider>
  
  <div style="display: flex; flex-direction: row; gap: 20px; flex-wrap: wrap; ">
    <div class="gamerank" *ngFor="let player of this.filterPlayers([1])">
        <div style="display: flex; padding-top: 5px; margin-left: 10px;width: 100%; border-radius: 4px;">
            
                <div style="font-size: medium; font-weight: bold;  width: 100%;">              
                    <img  *ngIf="(player.ProfileImage)"  [src]="'../../assets' + player.ProfileImage" style="float: left; height: 70px; margin-right: 5px;">
                    
                    <a routerLink="/player/details/{{player.PlayerId}}" style="color:#96bdff;text-decoration: none;">
                    {{player.JerseyNo}} <br/> {{player.LastName}}&nbsp;{{player.FirstName}}               
                    </a>
                </div>
                
            
        </div>
        
        <div style=" background: linear-gradient(to right, #888888, #303030); height: 2px;"  >
    
        </div>   
        <span style=" font-size: small;margin-left: 10px;">Player assessment</span>
        <div style="margin-left: 10px; margin-top: 5px; margin-bottom: 5px;  ">
    
                <span *ngIf="player.GameAssessment && player.GameAssessment.Ranking == 0" style="font-size:small; ">
                        <div  style="width: 100px; border-radius: 4px; text-align: center;background-color: gray;" >
                            DID NOT PLAY
                        </div>
                        
                    </span>

                    <span *ngIf="player.GameAssessment==null" style="font-size:small; color: gray; ">
                    
                        NO PLAYER ASSESSMENT
                    </span>
    
            <table *ngIf="player.GameAssessment &&  player.GameAssessment.Ranking > 0">
    
                    <tr *ngIf="player.GameAssessment.Comment>''">
                        
                        
                            <td colspan="6" style="font-size: small;">
                                    <i class="material-icons" style="color: white;" >chat_bubble_outline</i>
                                {{player.GameAssessment.Comment}}
                            </td>
                    </tr>    
                <tr>
                    <td  class="titlecell">OVERALL</td>
                    <td   style="width: 30px; border-radius: 4px; text-align: center;" [style.background-color]="gamePerformanceColors[player.GameAssessment.Ranking]" [style.color]="gamePerformanceTextColors[player.GameAssessment.Ranking]">
                        {{player.GameAssessment.Ranking}}
                    </td>
                    <td class="spacer"></td>
                    <td colspan="3"></td>
                </tr>                                  
            </table> 
            <div *ngIf="player.GameAssessment && player.GameAssessment.ParameterGroups!=null && player.GameAssessment.Ranking != 0 " style="display: flex;">
            <div *ngFor="let group of player.GameAssessment.ParameterGroups" style="margin-left: 20px;">
                <span  style=" font-size: small;">{{group.Name}}</span>
                <table style="margin-left: 20px;">
                    <tr *ngFor="let param of group.Parameters">
                        <td class="smalltitlecell">
                            {{param.Name}}
                        </td>
                        <td *ngIf="!param.ReverseRanking" class="valuecell" [style.background-color]="scaleColors[param.Ranking]"  [style.color]="scaleTextColors[param.Ranking]" >
                                <span *ngIf="param.Ranking>0">{{param.Ranking}}   </span>            
                        </td>
                        <td *ngIf="param.ReverseRanking" class="valuecell" [style.background-color]="painScaleColors[param.Ranking]"  [style.color]="painScaleTextColors[param.Ranking]" >
                            <span *ngIf="param.Ranking>0">{{param.Ranking}}   </span>            
                    </td>
                </table>
                <div >
                    
                </div>
            </div>

        </div>
            
                
            
        </div>
        <mat-divider *ngIf="player.Availability>-1"></mat-divider>    
            <div style="margin-left: 10px;" *ngIf="player.Availability>-1">
            <span style=" font-size: small;">Pre game status</span>
            <table style="font-size: x-small; margin-left: 10px;" *ngIf="player.Availability>-1">                                        
        
                     
                    <tr>
                        <td class="valuecell" style="height: 20px;" [style.background-color]="availabilityColors[player.Availability]" style.color="white;"  > {{availabilityText[player.Availability] |  translate}}</td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="this.scaleColors[player.Freshness]" [style.color]="this.scaleTextColors[player.Freshness]">
                                  <mat-icon class="smallicon" >directions_run</mat-icon> {{player.Freshness}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="scaleColors[player.Confidence]" [style.color]="scaleTextColors[player.Confidence]">
                            <mat-icon class="smallicon" >highlight</mat-icon> {{player.Confidence}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="scaleColors[player.Sleep]" [style.color]="scaleTextColors[player.Sleep]">
                            <mat-icon class="smallicon" >hotel</mat-icon> {{player.Sleep}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;"  [style.background-color]="painScaleColors[player.Muscles]" [style.color]="painScaleTextColors[player.Muscles]">
                            <mat-icon class="smallicon" >flash_on</mat-icon> {{player.Muscles}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="painScaleColors[player.Joints]" [style.color]="painScaleTextColors[player.Joints]">
                            <mat-icon class="smallicon" >flash_on</mat-icon> {{player.Joints}}
                        </td>
                        
                    </tr>
    
                </table>
                <span style=" margin-left: 20px; font-size: 12px;;">{{ player.AssessmentTimestamp | date:'yyyy-MM-dd HH:mm' }} </span>
            </div>
    
        <mat-divider></mat-divider>  
        <span style=" font-size: small;margin-left: 10px;">Coach grades</span> <br/> 
        <span *ngIf="player.CoachRankings==null || player.CoachRankings.length==0" style="font-size:small; color: gray;  margin-left: 10px; margin-top: 5px; margin-bottom: 5px; ">                   
                NO GRADES
        </span>
        <div style="display: flex; flex-direction: column; padding-top: 5px; margin-left: 10px; font-size: small;" *ngFor="let ranking of player.CoachRankings">       
                      
            <div style="display: flex;" >      
                <img mat-card-avatar src="../assets/img/icons8-coach-32.png" style="margin-right: 5px; width:28px; height: 28px;"/>                              
                <div *ngIf="ranking.Ranking>0" style="width: 30px; height: 20px; border-radius: 4px; text-align: center;" [style.background-color]="gamePerformanceColors[ranking.Ranking]" [style.color]="gamePerformanceTextColors[ranking.Ranking]" >                
                        <span style="font-size: medium">{{ranking.Ranking}}</span>   
                    <!-- <mat-chip-list >
                            <mat-chip [style.background-color]="gamePerformanceColors[ranking.Ranking]" [style.color]="gamePerformanceTextColors[ranking.Ranking]"   class="smallchip" style="padding-right: 2px;">
                                    <span style="font-size: medium">{{ranking.Ranking}}</span>          
                            </mat-chip>
                        
                    </mat-chip-list> -->
                </div>
                <div style=" margin-left: 5px;">
                        {{ ranking.ScoutFullName }}
                </div> 
            </div>
            <div style="margin-left: 30px ;">
                <div *ngIf="ranking.CommentToPlayer > '' ">
                    <label style="color: white; font-size: x-small;">Comment - visible to player</label>              
                    <div class="coachcomment" style="margin-bottom: 5px;">  
                    
                        {{ ranking.CommentToPlayer }}<br/>
                    </div>
                </div>
                <div *ngIf="ranking.CommentPrivate > ''">
                    <label style="color: white; font-size: x-small;">Comment - coaches only</label>      
                     <div class="coachcomment" style="border-color: white;" >                            
                                
                        {{ ranking.CommentPrivate }}
                    </div>
                </div>
               
            </div>
            
            
        </div>       
        <mat-divider></mat-divider>    
        <button mat-button (click)="openRatingDialog(player.PlayerId, player.LastName, player.FirstName)">
                <mat-icon aria-label="Edit grade"  >edit</mat-icon>
                GRADE
        </button>
    </div>
    
  </div>
  <h2>Defenders</h2>
    <mat-divider></mat-divider>
  <div style="display: flex; flex-direction: row; gap: 20px; flex-wrap: wrap; ">
  
    <div class="gamerank" *ngFor="let player of this.filterPlayers([2])">
        <div style="display: flex; padding-top: 5px; margin-left: 10px;width: 100%; border-radius: 4px;">
            
                <div style="font-size: medium; font-weight: bold;  width: 100%;">              
                    <img  *ngIf="(player.ProfileImage)"  [src]="'../../assets' + player.ProfileImage" style="float: left; height: 70px; margin-right: 5px;">
                    
                    <a routerLink="/player/details/{{player.PlayerId}}" style="color:#96bdff;text-decoration: none;">
                    {{player.JerseyNo}} <br/> {{player.LastName}}&nbsp;{{player.FirstName}}               
                    </a>
                </div>
                
            
        </div>
        
        <div style=" background: linear-gradient(to right, #888888, #303030); height: 2px;"  >
    
        </div>   
        <span style=" font-size: small;margin-left: 10px;">Player assessment</span>
        <div style="margin-left: 10px; margin-top: 5px; margin-bottom: 5px;  ">
    
                <span *ngIf="player.GameAssessment && player.GameAssessment.Ranking == 0" style="font-size:small; ">
                        <div  style="width: 100px; border-radius: 4px; text-align: center;background-color: gray;" >
                            DID NOT PLAY
                        </div>
                        
                    </span>

                    <span *ngIf="player.GameAssessment==null" style="font-size:small; color: gray; ">
                    
                        NO PLAYER ASSESSMENT
                    </span>
    
            <table *ngIf="player.GameAssessment &&  player.GameAssessment.Ranking > 0">
    
                    <tr *ngIf="player.GameAssessment.Comment>''">
                        
                        
                            <td colspan="6" style="font-size: small;">
                                    <i class="material-icons" style="color: white;" >chat_bubble_outline</i>
                                {{player.GameAssessment.Comment}}
                            </td>
                    </tr>    
                <tr>
                    <td  class="titlecell">OVERALL</td>
                    <td   style="width: 30px; border-radius: 4px; text-align: center;" [style.background-color]="gamePerformanceColors[player.GameAssessment.Ranking]" [style.color]="gamePerformanceTextColors[player.GameAssessment.Ranking]">
                        {{player.GameAssessment.Ranking}}
                    </td>
                    <td class="spacer"></td>
                    <td colspan="3"></td>
                </tr>                                  
            </table> 
            <div *ngIf="player.GameAssessment && player.GameAssessment.ParameterGroups!=null && player.GameAssessment.Ranking != 0 " style="display: flex;">
            <div *ngFor="let group of player.GameAssessment.ParameterGroups" style="margin-left: 20px;">
                <span  style=" font-size: small;">{{group.Name}}</span>
                <table style="margin-left: 20px;">
                    <tr *ngFor="let param of group.Parameters">
                        <td class="smalltitlecell">
                            {{param.Name}}
                        </td>
                        <td *ngIf="!param.ReverseRanking" class="valuecell" [style.background-color]="scaleColors[param.Ranking]"  [style.color]="scaleTextColors[param.Ranking]" >
                                <span *ngIf="param.Ranking>0">{{param.Ranking}}   </span>            
                        </td>
                        <td *ngIf="param.ReverseRanking" class="valuecell" [style.background-color]="painScaleColors[param.Ranking]"  [style.color]="painScaleTextColors[param.Ranking]" >
                            <span *ngIf="param.Ranking>0">{{param.Ranking}}   </span>            
                    </td>
                </table>
                <div >
                    
                </div>
            </div>

        </div>
            
                
            
        </div>
        <mat-divider *ngIf="player.Availability>-1"></mat-divider>    
            <div style="margin-left: 10px;" *ngIf="player.Availability>-1">
            <span style=" font-size: small;">Pre game status</span>
            <table style="font-size: x-small; margin-left: 10px;" *ngIf="player.Availability>-1">                                        
        
                     
                    <tr>
                        <td class="valuecell" style="height: 20px;" [style.background-color]="availabilityColors[player.Availability]" style.color="white;"  > {{availabilityText[player.Availability] |  translate}}</td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="this.scaleColors[player.Freshness]" [style.color]="this.scaleTextColors[player.Freshness]">
                                  <mat-icon class="smallicon" >directions_run</mat-icon> {{player.Freshness}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="scaleColors[player.Confidence]" [style.color]="scaleTextColors[player.Confidence]">
                            <mat-icon class="smallicon" >highlight</mat-icon> {{player.Confidence}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="scaleColors[player.Sleep]" [style.color]="scaleTextColors[player.Sleep]">
                            <mat-icon class="smallicon" >hotel</mat-icon> {{player.Sleep}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;"  [style.background-color]="painScaleColors[player.Muscles]" [style.color]="painScaleTextColors[player.Muscles]">
                            <mat-icon class="smallicon" >flash_on</mat-icon> {{player.Muscles}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="painScaleColors[player.Joints]" [style.color]="painScaleTextColors[player.Joints]">
                            <mat-icon class="smallicon" >flash_on</mat-icon> {{player.Joints}}
                        </td>
                        
                    </tr>
    
                </table>
                <span style=" margin-left: 20px; font-size: 12px;;">{{ player.AssessmentTimestamp | date:'yyyy-MM-dd HH:mm' }} </span>
            </div>
    
        <mat-divider></mat-divider>  
        <span style=" font-size: small;margin-left: 10px;">Coach grades</span> <br/> 
        <span *ngIf="player.CoachRankings==null || player.CoachRankings.length==0" style="font-size:small; color: gray;  margin-left: 10px; margin-top: 5px; margin-bottom: 5px; ">                   
                NO GRADES
        </span>
        <div style="display: flex; flex-direction: column; padding-top: 5px; margin-left: 10px; font-size: small;" *ngFor="let ranking of player.CoachRankings">       
                      
            <div style="display: flex;" >      
                <img mat-card-avatar src="../assets/img/icons8-coach-32.png" style="margin-right: 5px; width:28px; height: 28px;"/>                              
                <div *ngIf="ranking.Ranking>0" style="width: 30px; height: 20px; border-radius: 4px; text-align: center;" [style.background-color]="gamePerformanceColors[ranking.Ranking]" [style.color]="gamePerformanceTextColors[ranking.Ranking]" >                
                        <span style="font-size: medium">{{ranking.Ranking}}</span>   
                    <!-- <mat-chip-list >
                            <mat-chip [style.background-color]="gamePerformanceColors[ranking.Ranking]" [style.color]="gamePerformanceTextColors[ranking.Ranking]"   class="smallchip" style="padding-right: 2px;">
                                    <span style="font-size: medium">{{ranking.Ranking}}</span>          
                            </mat-chip>
                        
                    </mat-chip-list> -->
                </div>
                <div style=" margin-left: 5px;">
                        {{ ranking.ScoutFullName }}
                </div> 
            </div>
            <div style="margin-left: 30px ;">
                <div *ngIf="ranking.CommentToPlayer > '' ">
                    <label style="color: white; font-size: x-small;">Comment - visible to player</label>              
                    <div class="coachcomment" style="margin-bottom: 5px;">  
                    
                        {{ ranking.CommentToPlayer }}<br/>
                    </div>
                </div>
                <div *ngIf="ranking.CommentPrivate > ''">
                    <label style="color: white; font-size: x-small;">Comment - coaches only</label>      
                     <div class="coachcomment" style="border-color: white;" >                            
                                
                        {{ ranking.CommentPrivate }}
                    </div>
                </div>
               
            </div>
            
            
        </div>       
        <mat-divider></mat-divider>    
        <button mat-button (click)="openRatingDialog(player.PlayerId, player.LastName, player.FirstName)">
                <mat-icon aria-label="Edit grade"  >edit</mat-icon>
                GRADE
        </button>
    </div>
  
  
  </div>
  
    <h2>Forwards</h2>
    <mat-divider></mat-divider>
  <div style="display: flex; flex-direction: row; gap: 20px; flex-wrap: wrap; ">
  
    <div class="gamerank" *ngFor="let player of this.filterPlayers([4,5])">
        <div style="display: flex; padding-top: 5px; margin-left: 10px;width: 100%; border-radius: 4px;">
            
                <div style="font-size: medium; font-weight: bold;  width: 100%;">              
                    <img  *ngIf="(player.ProfileImage)"  [src]="'../../assets' + player.ProfileImage" style="float: left; height: 70px; margin-right: 5px;">
                    
                    <a routerLink="/player/details/{{player.PlayerId}}" style="color:#96bdff;text-decoration: none;">
                    {{player.JerseyNo}} <br/> {{player.LastName}}&nbsp;{{player.FirstName}}               
                    </a>
                </div>
                
            
        </div>
        
        <div style=" background: linear-gradient(to right, #888888, #303030); height: 2px;"  >
    
        </div>   
        <span style=" font-size: small;margin-left: 10px;">Player assessment</span>
        <div style="margin-left: 10px; margin-top: 5px; margin-bottom: 5px;  ">
    
                <span *ngIf="player.GameAssessment && player.GameAssessment.Ranking == 0" style="font-size:small; ">
                        <div  style="width: 100px; border-radius: 4px; text-align: center;background-color: gray;" >
                            DID NOT PLAY
                        </div>
                        
                    </span>

                    <span *ngIf="player.GameAssessment==null" style="font-size:small; color: gray; ">
                    
                        NO PLAYER ASSESSMENT
                    </span>
    
            <table *ngIf="player.GameAssessment &&  player.GameAssessment.Ranking > 0">
    
                    <tr *ngIf="player.GameAssessment.Comment>''">
                        
                        
                            <td colspan="6" style="font-size: small;">
                                    <i class="material-icons" style="color: white;" >chat_bubble_outline</i>
                                {{player.GameAssessment.Comment}}
                            </td>
                    </tr>    
                <tr>
                    <td  class="titlecell">OVERALL</td>
                    <td   style="width: 30px; border-radius: 4px; text-align: center;" [style.background-color]="gamePerformanceColors[player.GameAssessment.Ranking]" [style.color]="gamePerformanceTextColors[player.GameAssessment.Ranking]">
                        {{player.GameAssessment.Ranking}}
                    </td>
                    <td class="spacer"></td>
                    <td colspan="3"></td>
                </tr>                                  
            </table> 
            <div *ngIf="player.GameAssessment && player.GameAssessment.ParameterGroups!=null && player.GameAssessment.Ranking != 0 " style="display: flex;">
            <div *ngFor="let group of player.GameAssessment.ParameterGroups" style="margin-left: 20px;">
                <span  style=" font-size: small;">{{group.Name}}</span>
                <table style="margin-left: 20px;">
                    <tr *ngFor="let param of group.Parameters">
                        <td class="smalltitlecell">
                            {{param.Name}}
                        </td>
                        <td *ngIf="!param.ReverseRanking" class="valuecell" [style.background-color]="scaleColors[param.Ranking]"  [style.color]="scaleTextColors[param.Ranking]" >
                                <span *ngIf="param.Ranking>0">{{param.Ranking}}   </span>            
                        </td>
                        <td *ngIf="param.ReverseRanking" class="valuecell" [style.background-color]="painScaleColors[param.Ranking]"  [style.color]="painScaleTextColors[param.Ranking]" >
                            <span *ngIf="param.Ranking>0">{{param.Ranking}}   </span>            
                    </td>
                </table>
                <div >
                    
                </div>
            </div>

        </div>
            
                
            
        </div>
        <mat-divider *ngIf="player.Availability>-1"></mat-divider>    
            <div style="margin-left: 10px;" *ngIf="player.Availability>-1">
            <span style=" font-size: small;">Pre game status</span>
            <table style="font-size: x-small; margin-left: 10px;" *ngIf="player.Availability>-1">                                        
        
                     
                    <tr>
                        <td class="valuecell" style="height: 20px;" [style.background-color]="availabilityColors[player.Availability]" style.color="white;"  > {{availabilityText[player.Availability] |  translate}}</td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="this.scaleColors[player.Freshness]" [style.color]="this.scaleTextColors[player.Freshness]">
                                  <mat-icon class="smallicon" >directions_run</mat-icon> {{player.Freshness}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="scaleColors[player.Confidence]" [style.color]="scaleTextColors[player.Confidence]">
                            <mat-icon class="smallicon" >highlight</mat-icon> {{player.Confidence}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="scaleColors[player.Sleep]" [style.color]="scaleTextColors[player.Sleep]">
                            <mat-icon class="smallicon" >hotel</mat-icon> {{player.Sleep}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;"  [style.background-color]="painScaleColors[player.Muscles]" [style.color]="painScaleTextColors[player.Muscles]">
                            <mat-icon class="smallicon" >flash_on</mat-icon> {{player.Muscles}}
                        </td>
                        <td class="valuecell" style="min-width: 50px; height: 20px;" [style.background-color]="painScaleColors[player.Joints]" [style.color]="painScaleTextColors[player.Joints]">
                            <mat-icon class="smallicon" >flash_on</mat-icon> {{player.Joints}}
                        </td>
                        
                    </tr>
    
                </table>
                <span style=" margin-left: 20px; font-size: 12px;;">{{ player.AssessmentTimestamp | date:'yyyy-MM-dd HH:mm' }} </span>
            </div>
    
        <mat-divider></mat-divider>  
        <span style=" font-size: small;margin-left: 10px;">Coach grades</span> <br/> 
        <span *ngIf="player.CoachRankings==null || player.CoachRankings.length==0" style="font-size:small; color: gray;  margin-left: 10px; margin-top: 5px; margin-bottom: 5px; ">                   
                NO GRADES
        </span>
        <div style="display: flex; flex-direction: column; padding-top: 5px; margin-left: 10px; font-size: small;" *ngFor="let ranking of player.CoachRankings">       
                      
            <div style="display: flex;" >      
                <img mat-card-avatar src="../assets/img/icons8-coach-32.png" style="margin-right: 5px; width:28px; height: 28px;"/>                              
                <div *ngIf="ranking.Ranking>0" style="width: 30px; height: 20px; border-radius: 4px; text-align: center;" [style.background-color]="gamePerformanceColors[ranking.Ranking]" [style.color]="gamePerformanceTextColors[ranking.Ranking]" >                
                        <span style="font-size: medium">{{ranking.Ranking}}</span>   
                    <!-- <mat-chip-list >
                            <mat-chip [style.background-color]="gamePerformanceColors[ranking.Ranking]" [style.color]="gamePerformanceTextColors[ranking.Ranking]"   class="smallchip" style="padding-right: 2px;">
                                    <span style="font-size: medium">{{ranking.Ranking}}</span>          
                            </mat-chip>
                        
                    </mat-chip-list> -->
                </div>
                <div style=" margin-left: 5px;">
                        {{ ranking.ScoutFullName }}
                </div> 
            </div>
            <div style="margin-left: 30px ;">
                <div *ngIf="ranking.CommentToPlayer > '' ">
                    <label style="color: white; font-size: x-small;">Comment - visible to player</label>              
                    <div class="coachcomment" style="margin-bottom: 5px;">  
                    
                        {{ ranking.CommentToPlayer }}<br/>
                    </div>
                </div>
                <div *ngIf="ranking.CommentPrivate > ''">
                    <label style="color: white; font-size: x-small;">Comment - coaches only</label>      
                     <div class="coachcomment" style="border-color: white;" >                            
                                
                        {{ ranking.CommentPrivate }}
                    </div>
                </div>
               
            </div>
            
            
        </div>       
        <mat-divider></mat-divider>    
        <button mat-button (click)="openRatingDialog(player.PlayerId, player.LastName, player.FirstName)">
                <mat-icon aria-label="Edit grade"  >edit</mat-icon>
                GRADE
        </button>
    </div>
  
  
  </div>
  
    
  
  <!--   <div fxLayout="row" fxLayoutWrap>
    
      <mat-card  style=" margin-top:10px; padding-top:5px; padding-bottom: 18px;"  *ngIf="false" >
        <mat-card-header>        
         
        </mat-card-header>
          <mat-card-content>
  
          
            <table style="width: 100%"  style="width:100%;">
              <tr>
                  <td><img mat-card-avatar src="../assets/img/players/{{ player?.ProfileImage ? player.ProfileImage : 'emptyprofile.png' }}" style="background-color: white;"/></td>
                  <td style="font-size: 16px;">
                    {{player.LastName}}, {{player.FirstName}}
                  </td>
              </tr>
              <tr>
                <td style="width: 30px;vertical-align:top;">
               
                </td>
                <td>
                
                  <div style="display: flex; flex-wrap: wrap;-webkit-flex-wrap: wrap;" >
                      <div  *ngIf="player.PlayerRanking != null && player.PlayerRanking.Sleep>-1">
                             <table >
  
                                      <tr *ngIf="player.PlayerRanking != null && player.PlayerRanking.Sleep>-1">                                                                            
                                              <td style="color:white; font-size: large; text-align: left; width: 80px;">
      
                                                      <mat-chip-list >
                                                          <mat-chip [style.background-color]="gamePerformanceColors[player.PlayerRanking.Ranking]" [style.color]="gamePerformanceTextColors[player.PlayerRanking.Ranking]"   class="smallchip">
                                                              <i class="material-icons" style="font-size: 24px; padding-right: 5px;">{{gamePerformanceIcon[player.PlayerRanking.Ranking]}}</i> {{ player.PlayerRanking.Duration }} min
                                                          </mat-chip>
                                                  </mat-chip-list>
          
                                                      
                                              </td>            
                                              <td style="width: 32px">
                                                      <mat-chip-list >
                                                              <mat-chip [style.background-color]="scaleColors[player.PlayerRanking.Sleep]" [style.color]="scaleTextColors[player.PlayerRanking.Sleep]"  style=" font-size:small;" class="smallchip">
                                                                  <mat-icon class="example-icon" >hotel</mat-icon>{{player.PlayerRanking.Sleep}}
                                                              </mat-chip>
                                                      </mat-chip-list>
                                              </td>                
                                              <td style="width: 32px">
                                                      <mat-chip-list >
                                                              <mat-chip  [style.background-color]="scaleColors[player.PlayerRanking.Nutrition]" [style.color]="scaleTextColors[player.PlayerRanking.Nutrition]" class="smallchip">
                                                                  <mat-icon class="example-icon" >local_dining</mat-icon> {{player.PlayerRanking.Nutrition}}
                                                              </mat-chip>
                                                      </mat-chip-list>
                                              </td>                
                                  
                                              <td style="width: 32px">
                                                      <mat-chip-list >
                                                              <mat-chip [style.background-color]="painScaleColors[player.PlayerRanking.Stress]" [style.color]="painScaleTextColors[player.PlayerRanking.Stress]"   class="smallchip">
                                                                  <mat-icon class="example-icon">warning</mat-icon> {{player.PlayerRanking.Stress}}
                                                              </mat-chip>
                                                      </mat-chip-list>
                                              </td>   
                                              <td></td>
                                                
                                  
                                          </tr>
                                          <tr *ngIf="player.PlayerRanking.Comment != null">
                                             
                                           
                                            <td colspan="5"> <i class="material-icons" >chat_bubble_outline</i>
                                                {{ player.PlayerRanking.Comment }}
                                            </td>
                                        </tr>
                              </table>
                      </div>
  
  
                      <div *ngIf="player.Freshness>-1">                                                                                              
                          <table>
                              <tr>                        
                                  <td style="color:green; width: 32px; font-size: large; ">
                                      <mat-chip-list >
                                          <mat-chip  selected="true"  [style.background-color]="scaleColors[player.Freshness]"  [style.color]="scaleTextColors[player.Freshness]" style="padding-top:3px; padding-bottom: 3px; color: black; " class="smallchip">
                                              <mat-icon class="example-icon"  >directions_run</mat-icon>
                                              {{player.Freshness}}
                                                                  
                                          </mat-chip>
                                      </mat-chip-list>
                                  </td>                
                                  <td >
                                          <mat-chip-list >
                                                  <mat-chip  selected="true"  [style.background-color]="scaleColors[player.Confidence]" [style.color]="scaleTextColors[player.Confidence]" class="smallchip">
                                                          <mat-icon class="example-icon"  >highlight</mat-icon>
                                                      {{player.Confidence}}
                                                  </mat-chip>
                                          </mat-chip-list>
                                  </td>                
                                  <td >
                                          <mat-chip-list >
                                                  <mat-chip  selected="true"  [style.background-color]="scaleColors[player.Sleep]" [style.color]="scaleTextColors[player.Sleep]" class="smallchip">
                                                          <mat-icon class="example-icon" >hotel</mat-icon>
                                                          {{ player.Sleep }}
                                                      </mat-chip>
                                          </mat-chip-list>
                                  </td>                
                  
                                  <td >
                                          <mat-chip-list >
                                                  <mat-chip  selected="true"  [style.background-color]="painScaleColors[player.Muscles]" [style.color]="painScaleTextColors[player.Muscles]" class="smallchip">
                                                          <mat-icon class="example-icon" >flash_on</mat-icon>
                                                      {{player.Muscles}}
                                                  </mat-chip>
                                          </mat-chip-list>
                                  </td>   
                                  <td >
                                      <mat-chip-list >
                                              <mat-chip  selected="true"  [style.background-color]="painScaleColors[player.Joints]" [style.color]="painScaleTextColors[player.Joints]" class="smallchip">
                                                      <mat-icon class="example-icon" >flash_on</mat-icon>
                                                  {{player.Joints}}
                                              </mat-chip>
                                      </mat-chip-list>
                                  </td>   
                              
                              
              
                              </tr>
                          </table>
                      </div>
                     
                     
                  </div>
                </td>
              </tr>
             
             
          </table>
          <mat-divider *ngIf="player.CoachRankings!=null && player.CoachRankings.length>0"></mat-divider>
          <span *ngIf="player.CoachRankings!=null && player.CoachRankings.length>0" style="color: gray;">Coach comments</span>
          <div style="display: flex; padding-top: 5px; margin-left: 10px;" *ngFor="let ranking of player.CoachRankings">
             
              <img mat-card-avatar src="../assets/img/icons8-coach-32.png" style="margin-right: 5px; width:28px; height: 28px;"/>
              
             
              <div >
                  <div>
                          <b>{{ ranking.ScoutName }}</b>
                        
                  </div>                
                  <div >
                      <mat-chip-list >
                              <mat-chip [style.background-color]="gamePerformanceColors[ranking.Ranking]" [style.color]="gamePerformanceTextColors[ranking.Ranking]"   class="smallchip" style="padding-right: 2px;">
                                  <i class="material-icons" style="font-size: 24px; padding-right: 5px;">{{gamePerformanceIcon[ranking.Ranking]}}</i>
                              </mat-chip>
                      </mat-chip-list>
                  </div>
                  <div class="coachcomment" *ngIf="ranking.CommentToPlayer > '' ">
                      
                      {{ ranking.CommentToPlayer }}<br/>
                  </div>
                  <div class="coachcomment" style="border-color: white;" *ngIf="ranking.CommentPrivate > ''">                            
                      {{ ranking.CommentPrivate }}
                  </div>
              </div>
             
              
          </div> 
  
  
          <mat-divider ></mat-divider>
          </mat-card-content>
          <mat-card-actions>
              <button mat-button (click)="openRatingDialog(player.PlayerId)"> ADD/CHANGE COMMENT</button>
          </mat-card-actions>
          
          
      </mat-card>
  
    
    </div> -->
  
    <button mat-fab style="position: fixed; bottom: 25px; left: 10px; opacity: 0.5;" (click)="location.back()">
          <mat-icon >navigate_before</mat-icon>
      </button>
    </div>
  </div>
  