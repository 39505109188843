<div class="app-page">
  <h2>RECORDS - ALL TIME </h2>

  <mat-form-field appearance="fill">
    <mat-label>Gender</mat-label>
    <mat-select [(value)]="selectedGender" (selectionChange)="getData()">
      <mat-option value="M">Men</mat-option>
      <mat-option value="W">Women</mat-option>
    </mat-select>
  </mat-form-field>
  <div style="display: flex; flex-wrap: wrap;">
<ng-container  *ngFor="let toplist of _topLists">
  
<app-test-top-list-card  [TopList]="toplist" ></app-test-top-list-card>
</ng-container>
</div>

</div>