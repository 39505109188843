<!-- <section class="example-container mat-elevation-z8" tabindex="0"> -->
    <div class="app-page">
        <h2 style="font-family: Roboto;"><mat-icon>table_chart</mat-icon>Assigned programs</h2>
        <button mat-raised-button color="primary" routerLink="/training/assign">NEW ASSIGNMENT</button>
        <table mat-table [dataSource]="dataSource" *ngIf="this.dataLoaded">
    
        <!-- Name Column -->
        <ng-container matColumnDef="Title" >
            <th mat-header-cell *matHeaderCellDef> Title</th>
            <td mat-cell *matCellDef="let element">
                <mat-icon>table_chart</mat-icon>
                    {{element.Title}} 
              
                 
            </td>
        </ng-container>
        <ng-container matColumnDef="Status" >
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
                <div class="status-card" [style.background-color]="statuscardcolor(element.Status)">
                    {{element.Status}} 
                </div>
                 
            </td>
        </ng-container>
        <ng-container matColumnDef="ProgramName" >
            <th mat-header-cell *matHeaderCellDef>Program </th>
            <td mat-cell *matCellDef="let element"> 
                <mat-icon>calendar_view_week</mat-icon>
                {{element.ProgramName}} 
            </td>
        </ng-container>

          <ng-container matColumnDef="Actions" >
            <th mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
                <button mat-button *ngIf="element.Status=='DRAFT'" [routerLink]="['/training/assigned/details', element.AssignedProgramId]">EDIT</button>
                <button mat-button *ngIf="element.Status=='DRAFT'" type="button" (click)="delete(element.AssignedProgramId, element.Title)">DELETE</button>
                <button mat-button *ngIf="element.Status=='DRAFT'" type="button" (click)="activate(element.AssignedProgramId, element.Title)">ACTIVATE</button>
                
                <button mat-button *ngIf="element.Status=='ACTIVE'" [routerLink]="['/training/assigned/details', element.AssignedProgramId]">VIEW</button>
              <!--   <button mat-button *ngIf="element.Status=='ACTIVE'" >ANALYZE</button> -->
                <!-- <button mat-button *ngIf="element.Status=='ACTIVE'" type="button" (click)="archive(element.AssignedProgramId, element.Title)">ARCHIVE</button> -->
            </td>
            
        </ng-container>
    
        <ng-container matColumnDef="Team">
            <th mat-header-cell *matHeaderCellDef> Players </th>
            <td mat-cell *matCellDef="let element" > 
                <mat-icon *ngIf="element.Team!=''">person</mat-icon>
                {{element.Team}} 
            </td>
        </ng-container>
    
        <ng-container matColumnDef="NoOfPlayers">
            <th mat-header-cell *matHeaderCellDef> Players </th>
            <td mat-cell *matCellDef="let element" > 
                <mat-icon>person</mat-icon>
                {{element.NoOfPlayers}} 
            </td>
        </ng-container>
    
        
        <ng-container matColumnDef="StartDate">
            <th mat-header-cell *matHeaderCellDef> StartDate </th>
            <td mat-cell *matCellDef="let element"> {{element.StartDate | date}} </td>
        </ng-container>
    
        <ng-container matColumnDef="EndDate">
            <th mat-header-cell *matHeaderCellDef> End date </th>
            <td mat-cell *matCellDef="let element"> {{element.EndDate | date}} </td>
        </ng-container>
    
        
        <ng-container matColumnDef="NoOfWeeks">
            <th mat-header-cell *matHeaderCellDef> Weeks </th>
            <td mat-cell *matCellDef="let element"> 
               
                {{element.NoOfWeeks}}
            </td>
        </ng-container>

        <ng-container matColumnDef="NoOfWorkouts">
            <th mat-header-cell *matHeaderCellDef> Workouts </th>
            <td mat-cell *matCellDef="let element"> 
                <mat-icon>
                    view_list
                </mat-icon>
                {{element.NoOfWorkouts}}
            </td>
        </ng-container>
    
        <!--   <!-- Star Column --
        <ng-container matColumnDef="star" stickyEnd>
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
            <mat-icon>more_vert</mat-icon>
            </td>
        </ng-container> -->
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    <!--   </section> -->
    <div *ngIf="dataSource.length==0" style="color: darkgray; font-family: Roboto;">
        <br/>
        NO ASSIGNED PROGRAMS FOUND
    </div>
    </div>