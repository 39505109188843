<div class="sectionframe" [formGroup]="form" *ngIf="form" >
    <div style="display: flex; flex-direction: row;    align-items: baseline;    justify-content: space-between;">
        <h2>Section</h2>
        <!-- <mat-checkbox id="Collapsible" formControlName="Collapsible" >Collapsible</mat-checkbox> -->
        <div>
            <input type="checkbox" id="Collapsible" formControlName="Collapsible" >Collapsible
        </div>
        
        
    </div>
<!--     <mat-form-field appearance="fill" class="smallformfield" >
        <mat-label>Section name</mat-label>
        <input matInput id="Name" formControlName="Name" />
    </mat-form-field>
 -->
    <mat-form-field appearance="fill" class="smallformfield" >
        <mat-label>Section text</mat-label>
        <input matInput id="Text" formControlName="Text" />
    </mat-form-field>
    <br/>

  <ng-container formArrayName="Questions" *ngIf="!loading" >
    <ng-container *ngFor="let question of Questions.controls; let j = index;">
        <app-form-edit-question   [form]="question" [formControlName]="j" (deleteQuestionEvent)="onDeleteQuestionEvent(j, Questions)"></app-form-edit-question>
    </ng-container>


    </ng-container> 

    <button mat-raised-button color="primary" (click)="addQuestion();">ADD QUESTION</button>
</div>