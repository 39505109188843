import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PlayerService } from 'src/app/shared/player.service';
import { FlowItemViewModel } from '../models/FlowItemModel';
import { ReportSearchParameters } from '../models/ReportSearchParameters';
import { ScoutingPlayerService } from '../services/scouting-player.service';
import { CommonModule } from '@angular/common';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { RouterModule } from '@angular/router';
import { PlayerViewDialogComponent } from '../player-view-dialog/player-view-dialog.component';
import { ReportViewDialogComponent } from '../report-view-dialog/report-view-dialog.component';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-report-search',
  standalone: true,
  imports: [CommonModule, SharedModuleModule, RouterModule, ReactiveFormsModule, PlayerViewDialogComponent, ReportViewDialogComponent  ],
  templateUrl: './report-search.component.html',
  styleUrls: ['./report-search.component.css']
})
export class ReportSearchComponent implements OnInit {
  displayedColumns: string[] = ['Player', 'DateOfBirth','DateCreated', 'TotalQualityText','PlayerCapacityText','CreatedBy', 'Actions'];
  public dataSource= new MatTableDataSource<FlowItemViewModel>();
  isBusy: boolean;
  reportMasterdata: any;
  availableBirthYears: number[];
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  searchForm = new UntypedFormGroup({
    PlayerFirstName: new UntypedFormControl(''),
    PlayerLastName: new UntypedFormControl('', Validators.required),
    PositionIds: new UntypedFormControl(''),
    YearsOfBirth: new UntypedFormControl(''),
    ShootIds: new UntypedFormControl(''),
    Projections: new UntypedFormControl(''),
    Capacities: new UntypedFormControl(''),
    MaxHits: new UntypedFormControl('50'),
    minReportDate:new UntypedFormControl(''),
    maxReportDate:new UntypedFormControl(''), 
  });
  
  constructor(public playerRep: PlayerService, public scoutingRep: ScoutingPlayerService,public matDialog: MatDialog,) { 

    scoutingRep.getReportSearchMasterDataForOrg().subscribe(data =>{
      //console.log(data);
      this.reportMasterdata = data;
    });
  }

  ngOnInit() {
    var earliestYear = new Date().getFullYear() - 13;

    this.availableBirthYears =
    Array.from(
      { length: 45 },
      (value, index) => earliestYear - index 
      );
      //console.log("availableBirthYears");
      //console.log(this.availableBirthYears);
  }

  searchReports(){
    this.isBusy=true;

    try {
      //Clear result
      this.dataSource = new MatTableDataSource();
      var p: ReportSearchParameters;
      p = new ReportSearchParameters();
      p = this.searchForm.value;
   
      this.scoutingRep.searchReports(this.searchForm.value).subscribe( result=>{
          //console.log(result);
          this.dataSource = new MatTableDataSource(result);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          
      }); 
    } catch (error) {
      this.isBusy=false;
    }
    finally{
      this.isBusy = false;
    }

  }



  
  openPlayer(playerId:String) {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(PlayerViewDialogComponent, {
      data: { PlayerId: playerId },
    });
  }
  openViewReport(reportId:String) {
    const dialogConfig = new MatDialogConfig();
    this.matDialog.open(ReportViewDialogComponent, {
      data: { ReportId: reportId },
    });
  }
}
