<div mat-dialog-title>
    ADD TEAM
</div>
<div mat-dialog-content style="height: 140px;">
    
    <form (ngSubmit)="onSubmitForm()" id="addteam" autocomplete="off" *ngIf="this._teams">
    
        <mat-form-field>
            <mat-label>Team</mat-label>
            <mat-select id="TeamId" [(value)]="TeamId"  >
              <mat-option *ngFor="let team of this._teams" [value]="team.TeamId">
                {{team.Level}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <br/>
            <mat-checkbox   [checked]="this.Enabled" (change)="this.Enabled = !this.Enabled;" >
                Enabled
            </mat-checkbox>
<br/>

            <mat-checkbox [checked]="this.VisibleForAll" (change)="this.VisibleForAll = !this.VisibleForAll;">
                Visible for all
            </mat-checkbox>


    </form>
  
   </div>
   <div mat-dialog-actions>
     <button type="submit" form="addteam"  mat-raised-button color="primary" cdkFocusInitial>ADD</button>   
     <button mat-button [mat-dialog-close]="false" >CANCEL</button>
   </div>