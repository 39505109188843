
<mat-card appearance="outlined" style="max-width: 340px; min-width: 300px;   margin: 10px; border: gray 1px solid; border-left: white 3px solid;" class="mat-elevation-z8">
    <table>
        <tr>
            <td>
                <span class="material-symbols-outlined" style="font-size: 40px;">
                    supervisor_account
                </span>
            </td>
            <td>
                    <span style="font-size: large; font-family: Rubik;">{{ this.Agent.Agent.LastName}}, {{ this.Agent.Agent.FirstName}}</span><br>
                    <span style="font-size: medium; color: darkgray; font-size: 12px;font-family: Rubik; ">
                      {{this.Agent.Agent.BusinessName}}
                    </span>
                  
                    
            </td>
        </tr>
    </table>
     
<mat-card-header>
    <!-- <img mat-card-avatar  [src]="'../../assets/img/' + this.Report.PlayerCurrentTeamIcon"     > -->
   
    <mat-card-title>
     
    </mat-card-title>
  


</mat-card-header>
<mat-card-content style="margin-bottom: 0px;" >
    <div style="display: flex; flex-direction: row;">
        <div  class="authority" [style.background-color]="authority.BgColor" [style.color]="authority.Color" *ngFor="let authority of this.Agent.Authorities">{{ authority.Name }}</div>
    </div>

    <div style=" font-size: small;  text-align: left; vertical-align: top;  padding: 0; white-space: normal; margin: 0; color: white;  ">    
        <table>
            <tr>
                <td>
                        <mat-icon style="color: white; font-size: 16px;">phone</mat-icon>
                </td>
                <td>
                    <a [href]="'tel:' + this.Agent.Agent.BusinessCellPhone" style="text-decoration: none; color: white;">
                    {{this.Agent.Agent.BusinessCellPhone}}
                    </a>
                </td>
            </tr>
            <tr >
                <td>  
                  
                        <mat-icon style="color:white;">mail</mat-icon>
                       
                  
                </td>
                <td>  
                    <div >
                        <a [href]="'mailto:' + this.Agent.Agent.BusinessEmail" style="text-decoration: none; color: white;">
                        {{this.Agent.Agent.BusinessEmail}}
                        </a>
                   
                    </div>
                </td>
            </tr>
            
            <tr *ngIf="this.Agent.Agent.BusinessURL">
                <td>
                    
                    <mat-icon style="color: white; font-size: 16px; "> http</mat-icon>
                </td>
                <td>
                    <a [href]="'http://' + this.Agent.Agent.BusinessURL" target="_blank" style="text-decoration: none; color: white;">
                    {{this.Agent.Agent.BusinessURL}}
                    </a>
                </td>
            </tr>
            <tr >
                    <td style="vertical-align: top;">
                        <mat-icon style="color: white; font-size: 16px; "> home</mat-icon>
                        
                    </td>
                    <td>
                        <div>
                            {{this.Agent.Agent.BusinessName}}
                        </div>                        
                        <div *ngIf="this.Agent.Agent.BusinessAddress1">
                            {{this.Agent.Agent.BusinessAddress1}}    
                        </div>
                        <div *ngIf="this.Agent.Agent.BusinessAddress2">
                            {{this.Agent.Agent.BusinessAddress2}}
                        </div>
                        <div *ngIf="this.Agent.Agent.BusinessAddress3">
                            {{this.Agent.Agent.BusinessAddress3}}
                        </div>
                        <div *ngIf="this.Agent.Agent.BusinessAddressCity">
                            {{this.Agent.Agent.BusinessAddressPO}}&nbsp;{{this.Agent.Agent.BusinessAddressCity}}                            
                        </div>
                        <div *ngIf="this.Agent.Agent.BusinessAddressCountry">
                            {{this.Agent.Agent.BusinessAddressCountry}}                            
                        </div>

                        
                        
                        
                        
                    </td>
                </tr>
        </table>    
      
       
        
    </div>


    <mat-divider></mat-divider>



</mat-card-content>
<mat-card-actions>
    <button *ngIf="this.ConnectedPlayerId && this.isAllowedToEdit" mat-button (click)="removeFromPlayer()">REMOVE</button>
</mat-card-actions>

</mat-card> 
