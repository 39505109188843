<div class="app-page" style="margin-left: 10px;"  >
  <!-- <mat-toolbar style="position: fixed; z-index: 9999; margin-left:-10px;"> -->
    <div style="display: flex; flex-direction: row; align-items: flex-start;">
      
      <div style="display: inline-block; font-family: Roboto; font-size: 20px; font-weight: bold; margin-left: 20px;" *ngIf="this._player">
        &nbsp;
        <img  *ngIf="(this._player?.ProfileImage)"  [src]="'../../assets' + this._player.ProfileImage" style="float: left;margin-top: 10px; height: 100px;"> 
       
      </div>  
      <div style="font-size: 20px; font-weight: 500;">
        {{ this._player.JerseyNo }} {{ this._player.LastName }}, {{ this._player.FirstName }}<br/>
        
      </div>
      
    </div>
 

<!--   </mat-toolbar> -->

   
  
     <!--      <mat-accordion class="example-headers-align" *ngIf="false">
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Chart settings
                </mat-panel-title>
                <mat-panel-description>
                  &nbsp;
                  <mat-icon>expand_more</mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
          
               <button mat-button type="button" (click)="xaxis('age')">BY AGE</button>
              <button mat-button type="button" (click)="xaxis('date')">BY DATE</button>
              
                  <mat-button-toggle-group  name="xaxistype" aria-label="Font Style" id="xaxistype" #xaxistype="matButtonToggleGroup" value="date">
                    <mat-button-toggle value="date" selected="true">DATE</mat-button-toggle>
                    <mat-button-toggle value="age">AGE</mat-button-toggle>
                    
                    
                  </mat-button-toggle-group>

                  
                  <div name="dateinterval"  [hidden]="this.xaxistype.value=='age'" [formGroup]="myGroup">
                    <mat-button-toggle-group formControlName="IntervalDate"  name="interval_date" aria-label="Font Style" id="interval_date" #interval_date="matButtonToggleGroup" value="6M" (change)="refreshData($event)">
                      <mat-button-toggle value="3M" >3M</mat-button-toggle>
                      <mat-button-toggle value="6M">6M</mat-button-toggle>
                      <mat-button-toggle value="1Y">1Y</mat-button-toggle>
                      <mat-button-toggle value="2Y">2Y</mat-button-toggle>
                      <mat-button-toggle value="3Y">3Y</mat-button-toggle>
                      <mat-button-toggle value="5Y">5Y</mat-button-toggle>
                      
                      
                    </mat-button-toggle-group>
                  </div>
                  <div name="ageinterval" [hidden]="this.xaxistype.value=='date'">
                    <mat-button-toggle-group  name="intervall_age" aria-label="Font Style" id="intervall_age" #intervall_age="matButtonToggleGroup" value="3M">
                      <mat-button-toggle value="3M" >15</mat-button-toggle>
                      <mat-button-toggle value="6M">16</mat-button-toggle>
                      <mat-button-toggle value="1Y">17</mat-button-toggle>
                      <mat-button-toggle value="1Y">18</mat-button-toggle>
                      <mat-button-toggle value="2Y">19</mat-button-toggle>
                      <mat-button-toggle value="3Y">20</mat-button-toggle>
                      <mat-button-toggle value="3Y">25</mat-button-toggle>
                      
                            
                    </mat-button-toggle-group>
                    <mat-button-toggle-group  name="intervall_age_max" aria-label="Font Style" id="intervall_agemax" #intervall_agemax="matButtonToggleGroup" value="2Y">
                      <mat-button-toggle value="3Y">CURRENT</mat-button-toggle>
                      <mat-button-toggle value="6M">16</mat-button-toggle>
                      <mat-button-toggle value="1Y">17</mat-button-toggle>
                      <mat-button-toggle value="1Y">18</mat-button-toggle>
                      <mat-button-toggle value="2Y">19</mat-button-toggle>
                      <mat-button-toggle value="3Y">20</mat-button-toggle>
                      <mat-button-toggle value="3Y">25</mat-button-toggle>
                      
                            
                    </mat-button-toggle-group>
                  </div>
            
          
            <mat-action-row>
                <button mat-button color="primary" (click)="nextStep()">Next</button>
              </mat-action-row> 
            </mat-expansion-panel>
          </mat-accordion> -->
         
         <!--  <button mat-raised-button (click)="addPlayerForComparison()">COMPARE</button>
          <div>{{this._LastNameCompare}}, {{this._FirstNameCompare}}</div> -->


          <div style="display: flex; justify-content: flex-end; padding-top: 50px; flex-wrap: wrap;">
            
            <div style="display: flex; flex-direction: row; align-items: center; font-family: Roboto; flex-grow: 10;">
              <button mat-button (click)="addPlayerForComparison()"  *ngIf="this._PlayerIdCompare==null">
                <mat-icon>add</mat-icon>
                ADD PLAYER TO COMPARE
              </button> 
              <div style="background-color: '#1E90FF'; width: 40px; height: 2px;" *ngIf="this._PlayerIdCompare!=null"></div>&nbsp;<div *ngIf="this._PlayerIdCompare!=null" style="font-size: small;">{{this._LastNameCompare}}, {{this._FirstNameCompare}}</div>
             <!--  <button mat-raised-button (click)="removeCompare()" *ngIf="this._PlayerIdCompare!=null">DELETE</button> -->
              <button mat-icon-button aria-label="Remove player from chart"  (click)="removeCompare()" *ngIf="this._PlayerIdCompare!=null" >
                <mat-icon style="font-size: medium;">delete</mat-icon>
              </button>
            </div>
            
            <div style="margin-left: 20px;">
              <div>
                <mat-label class="mat-small">
                  X-axis type
                </mat-label>
              </div>
              <mat-button-toggle-group formControlName="xaxis"  name="xaxis" aria-label="Axis" id="xaxis" #xaxis="matButtonToggleGroup" value="date" (change)="refreshAgeData($event)">
                <mat-button-toggle value="date" >Date</mat-button-toggle>
                <mat-button-toggle value="age">Age</mat-button-toggle>
              </mat-button-toggle-group>

            </div>
            <div style="margin-left: 20px;">
              <div>
                <mat-label class="mat-small">
                  Select interval
                </mat-label>
              </div>
          
              <div name="dateinterval"    style="font-size: small;">
                <mat-button-toggle-group formControlName="IntervalDate"  name="interval_date" aria-label="Interval" id="interval_date" #interval_date="matButtonToggleGroup" value="2Y" (change)="refreshIntervalData($event)">
                  <mat-button-toggle value="3M" >3M</mat-button-toggle>
                  <mat-button-toggle value="6M">6M</mat-button-toggle>
                  <mat-button-toggle value="1Y">1Y</mat-button-toggle>
                  <mat-button-toggle value="2Y">2Y</mat-button-toggle>
                  <mat-button-toggle value="3Y">3Y</mat-button-toggle>
                  <mat-button-toggle value="5Y">5Y</mat-button-toggle>                                    
                  <mat-button-toggle value="10Y">10Y</mat-button-toggle>         
                </mat-button-toggle-group> 
              </div>
            </div>      
            
            <div style="margin-left: 20px;">
              <div>
                <mat-label class="mat-small">
                  Chart size
                </mat-label>
              </div>
          
             
                <mat-button-toggle-group formControlName="ChartSize"  name="chartsize" aria-label="Chart size" id="ChartSize" #chartsize="matButtonToggleGroup" value="200" (change)="refreshData()">
                  <mat-button-toggle value="200" selected >Small</mat-button-toggle>
                  <mat-button-toggle value="300">Medium</mat-button-toggle>
                  <mat-button-toggle value="400">Large</mat-button-toggle>
                                  
                </mat-button-toggle-group> 
              
            </div>      

          </div> 


      <div class="playertestoverview">

          <mat-card  appearance="outlined"  [ngClass]="{'test-container_small': this.chartsize.value=='200','test-container_medium': this.chartsize.value==='300','test-container_large': this.chartsize.value==='400'}" *ngFor="let t of Tests" >
          <mat-card-header>
            <mat-card-title>{{t.Name}}</mat-card-title>
            <mat-card-subtitle></mat-card-subtitle>
          </mat-card-header>    
          <mat-card-content>
             
              
              <div *ngIf="t.TestResults.length==0" style=" width: 100%; display: flex;
              align-items: center; text-align: center; color: gray;">
                NO DATA
              </div>
              <div [ngClass]="{'chart_div_small': this.chartsize.value=='200','chart_div_medium': this.chartsize.value==='300','chart_div_large': this.chartsize.value==='400'}">
                <app-playercompare-chart-numeric *ngIf="this._tests" [PlayerId]="this.PlayerId" [TestId]="t.TestId" [Results]="t.TestResults" [ResultsForComparison]="getMatchingTestForComparison(t.TestId)?.TestResults" [LimitsOK_Series]="t.LimitOK_SeriesData" [LimitsGood_Series]="t.LimitGood_SeriesData" [LimitsVeryGood_Series]="t.LimitVeryGood_SeriesData" [LimitsExcellent_Series]="t.LimitExcellent_SeriesData"   [ResultType]="t.ResultType" [xvalue]="this.xaxis.value" [CurrentAge]="this._player.AgeYearsDecimal" [Interval]="this.interval_date.value" [ChartHeight]="this.chartsize.value"  ></app-playercompare-chart-numeric>
              </div>
              
              <div style="width: 100%; display: flex; justify-content: space-between" *ngIf="t.TestResults.length>0">
                <div>
                Current 
                  <div>
                      <div style="font-size: large; font-weight: bold;" *ngIf="t.ResultType==10 ">{{t.TestResults[0].ResultInt}}</div>
                      <div style="font-size: large; font-weight: bold;" *ngIf="(t.ResultType==20 || t.ResultType==21)  ">{{t.TestResults[0].ResultDecimal}}</div>
                      <div style="font-size: large; font-weight: bold;" *ngIf="t.ResultType==30 || t.ResultType==31">{{t.TestResults[0].ResultTime}}</div>
                  </div>
                </div>
                <div *ngIf="!(t.TestResults.length>0)" style="height: 30px;">
                  
                </div>
                
               
              </div>
              <mat-divider *ngIf="t.Limit"></mat-divider>
              <div *ngIf="t.Limit" style="margin-top:5px;font-size: 10px;">Limits</div>
              <div style="display: flex;justify-content: space-between" *ngIf="t.Limit">
                <div class="valuecolor_2">2</div>
                <div style="flex-grow: 4; font-weight: bold;">{{t.Limit.LimitOK}}</div>
                <div class="valuecolor_3">3</div>
                <div style="flex-grow: 4;  font-weight: bold;">{{t.Limit?.LimitGood ? t.Limit?.LimitGood : '-'}}</div>
                <div class="valuecolor_4">4</div>
                <div style="flex-grow: 4;  font-weight: bold;">{{t.Limit?.LimitVeryGood ? t.Limit?.LimitVeryGood : '-'}}</div>
                <div class="valuecolor_5">5</div>
                <div style="flex-grow: 4; font-weight: bold; ">{{t.Limit?.LimitExcellent ? t.Limit?.LimitExcellent : '-'}}</div>                
              </div>
              <div *ngIf="t.Limit" style="margin-top: 4px; font-style: oblique;"> {{t.Limit.LimitMultiplierTestName ? '(*' + t.Limit.LimitMultiplierTestName + ')' : ' '}}</div>
              
              
              <mat-divider *ngIf="t.Limit"></mat-divider>
          </mat-card-content>
          <mat-card-actions>
           <!--  <button mat-button type="button" disabled="true">LOG</button> -->
            
          </mat-card-actions>
        </mat-card>
        
        
        
      </div>


  <button mat-fab style="position: fixed; bottom: 25px; z-index: 100;" (click)="_location.back()">
    <mat-icon >navigate_before</mat-icon>
  </button>

     

</div>

