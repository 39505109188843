<div style="margin: 5px;   font-family:Roboto;"  >
    <div style="font-family: Roboto; color: darkgray;">
        {{this.Game.DivisionName}}
    </div>
    <div style="display: flex;"  >
    
        <div style="display: block; width: 100%">
            <table >
                <tr>
                    <td style="text-align: center; width: 33%;">
                        <img style="height: 60px;" [src]="'../../assets/img/' + this.Game.HomeTeamSmallIcon"><br/>
                        <span>{{this.Game.TeamHomeName}}</span>
                    </td>
                    <td style="width: 33%;font-size: small; font-family: Roboto; text-align: center; color: darkgray;">
                        {{this.Game.GameDate | date: 'dd'}}
                        {{this.Game.GameDate  | date: 'LLL'}}
                        <div style="font-size: large;">{{this.Game.GameTime | date: 'HH:mm'}}</div>
                        {{this.Game.Venue}}
                    </td>
                    <td style="text-align: center;width: 33%;">
                        <img style="height: 80px;" [src]="'../../assets/img/' + this.Game.AwayTeamSmallIcon"><br/>
                        <span>{{this.Game.AwayTeamName}}</span>
                    </td>
                    
                    
                </tr>
          
            </table>

       
            <div></div>
        </div>
        <div></div>
        <div></div>
        <div></div>
    </div>

</div>