
<div style="margin: 5px;   font-family:Roboto;" *ngIf="this.gameDetails"  >
    <div style="font-family: Roboto; color: darkgray;">
            {{this.gameDetails?.DivisionName}}
    </div>
    <div style="display: flex; flex-direction: column;"  >
    
        <div style="display: block; width: 100%">
            <table style="width:500px;" >
                <tr>
                    <td style="text-align: center; width: 33%;">
                        <img style="height: 60px;" [src]="'../../assets/img/' + this.gameDetails.HomeTeam.SmallIconFileName"><br/>
                        <span>{{this.gameDetails.HomeTeamName}}</span>
                    </td>
                    <td style="width: 33%;font-size: small; font-family: Roboto; text-align: center; color: darkgray;">
                        {{this.gameDetails.EventDate | date: 'dd'}}
                        {{this.gameDetails.EventDate  | date: 'LLL'}}
                        <div style="font-size: large;">{{this.gameDetails.startTime | date: 'HH:mm'}}</div>
                        {{this.gameDetails.Venue}}
                    </td>
                    <td style="text-align: center;width: 33%;">
                        <img style="height: 80px;" [src]="'../../assets/img/' + this.gameDetails.AwayTeam.SmallIconFileName"><br/>
                        <span>{{this.gameDetails.AwayTeamName}}</span>
                    </td>
                    
                    
                </tr>
          
            </table>                  
        </div>
        <mat-divider></mat-divider>


        

                
                <ng-container #playerlist *ngFor="let player of this.players">
                    <div style="display: flex;flex-direction: row;font-family: Roboto; align-content: space-between; border-bottom: 1px #56565677 solid; " class="DS_Teamroster"    >
                        <div style="min-width: 45px; margin-right: 5px; flex-grow: 1;">
                            <img [src]="'/assets' + player.ProfileImage" style="width: 25px;">
                        </div>  
                        <div style="font-size: 20px; margin-right: 5px; flex-grow: 1; min-width: 25px;">
                            {{player.JerseyNo}}
                        </div>    
                        <div style="min-width: 150px;">        
                            <span style="font-size: 12px; ">{{player.LastName}}, {{player.FirstName}}</span><br/>
                        <!--    <span style="font-size: 10px"> this.Player.PlayerView.DateOfBirth | date:'yyy-mm-dd' - this.Player.PlayerView.Height cm - this.Player.PlayerView.Weight kg </span><br/>
                            <!-- add tags        -->
                        </div> 
                        <div  style="flex-grow: 100; text-align: right;">
                        <!--   <mat-icon *ngIf="this.Player.IsFavourite" style="color: orange;">star</mat-icon> -->
                        <mat-button-toggle-group appearance="legacy" [value]="getGrade(player.PlayerId)" >
                            <mat-button-toggle class="green_button" [id]="player.PlayerId + '_0'"  value="0" (click)="setGrade($event,player.PlayerId,'0')" >-</mat-button-toggle>
                            <mat-button-toggle [id]="player.PlayerId + '_1'" value="1" (click)="setGrade($event,player.PlayerId,'1')">Bad</mat-button-toggle>
                            <mat-button-toggle [id]="player.PlayerId + '_2'" value="2" (click)="setGrade($event,player.PlayerId,'2')">Average</mat-button-toggle>
                            <mat-button-toggle [id]="player.PlayerId + '_3'" value="3" (click)="setGrade($event,player.PlayerId,'3')">Good</mat-button-toggle>
                            <mat-button-toggle [id]="player.PlayerId + '_4'" value="4" (click)="setGrade($event,player.PlayerId,'4')">Very good</mat-button-toggle>
                            <mat-button-toggle [id]="player.PlayerId + '_5'" value="5" (click)="setGrade($event,player.PlayerId,'5')">Excellent</mat-button-toggle>
                        </mat-button-toggle-group>
                        </div>
                        <div  style="flex-grow: 1; text-align: LEFT;width: 50px;font-size: small;">
                        <!--  <mat-icon  style="font-size: small;"> rate_review </mat-icon>&nbsp;<b>this.Player.PlayerView.ReportCount</b><br/> -->
                        <!--  <span *ngIf="this.Player.PlayerView.NoteCount>0"><mat-icon   style="font-size: medium;"> insert_comment </mat-icon>&nbsp;this.Player.PlayerView.NoteCount<br/></span> -->
                        </div>
                    <!--    <div *ngIf="this.Player.ReportCount==0 && this.Player.NoteCount==0 "  style="flex-grow: 1; text-align: right; width: 40px;">
                    
                        </div> -->
                    <!--   <div style="flex-grow: 1;">
                                <button mat-icon-button aria-label="Add button" (click)="addPushed($event,this.Player.LifelineScoutingId, this.GameId); false;">
                                        <mat-icon>add</mat-icon>
                                </button>
                        </div> -->
                    
                    </div>
                </ng-container>
                
            

      
    </div>

</div>