<div class="app-page" style="display: flex; justify-content: center;">
  <mat-card appearance="outlined" class="mat-elevation-z4" style="min-width: 330px; max-width: 580px;margin-top: 30px;">
    <mat-card-header>
      <div mat-card-avatar>
          <i class="material-icons" style="font-size: 36px;">
              account_box
          </i>
      </div>
      <mat-card-title>
        {{this.Name}}
      </mat-card-title>    
      <mat-card-subtitle>
          <div *ngFor="let role of this.Roles.split(',',100)">
            {{role}}
          </div>
        
        </mat-card-subtitle>    
    </mat-card-header>
      <mat-card-content>
        
      </mat-card-content>
      <mat-card-actions>
        <button type="button" mat-raised-button (click)="logout();">LOGOUT</button>
        <button type="button" mat-raised-button routerLink="/changepassword">CHANGE PASSWORD</button>
      </mat-card-actions>

  </mat-card>

  <!-- <div>
    <button type="button" mat-raised-button (click)="this.translate.use('sv');">SVENSKA</button>
    <button type="button" mat-raised-button (click)="this.translate.use('en');">ENGLISH</button>
    
  </div> -->
</div>
