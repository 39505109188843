<form class="col s12 white" #userRegistrationForm="ngForm" (ngSubmit)="OnSubmit(userRegistrationForm)">
  <div class="row">
    <div class="input-field col s6">
      <input class="validate" type="text" name="UserName" #UserName="ngModel" [(ngModel)]="user.UserName" required>
      <label data-error="Required field!">UserName</label>
    </div>
    <div class="input-field col s6">
      <input class="validate" type="password" name="Password" #Password="ngModel" [(ngModel)]="user.Password" required minlength="3">
      <label [attr.data-error]="Password.errors!=null?(Password.errors.required?'Required field!':'Minimum 3 characters needed'):''">Password</label>
    </div>
  </div>
  <div class="row">
    <div class="input-field col s12">
      <input class="validate" type="text" name="Email" #Email="ngModel" [(ngModel)]="user.Email" [pattern]="emailPattern">
      <label data-error="Invalid email!">Email</label>
    </div>
  </div>
  <div class="row">
    <div class="input-field col s6">
      <input type="text" name="FirstName" #FirstName="ngModel" [(ngModel)]="user.FirstName">
      <label>First Name</label>
    </div>
    <div class="input-field col s6">
      <input type="text" name="LastName" #LastName="ngModel" [(ngModel)]="user.LastName">
      <label>Last Name</label>
    </div>
  </div>
  <div class="row" *ngIf="roles">
    <ul>
      <li *ngFor="let item of roles;let i = index">
        <input type="checkbox" [id]="'roles-'+i" value="{{item.Id}}" [checked]="item.selected"  (change)="updateSelectedRoles(i)">
        <label [for]="'roles-'+i">{{item.Name}}</label>
      </li>
    </ul>
  </div>
  <div class="row">
    <div class="input-field col s12">
      <button [disabled]="!userRegistrationForm.valid" class="btn-large btn-submit" type="submit">Submit</button>
    </div>
  </div>
</form>