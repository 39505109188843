import { Component, OnInit } from '@angular/core';
import { GameService } from 'src/app/load/services/game.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GameStatisticsPlayerViewModel } from 'src/app/scouting/models/GameModels';
import { GameEventOverviewViewModel } from '../models/gameevent';
import { UserService } from 'src/app/shared/user.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { NotificationService } from 'src/app/shared/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GameChartsComponent } from 'src/app/performance/screens/game-charts/game-charts.component';

@Component({
  selector: 'app-load-game-list',
  templateUrl: './load-game-list.component.html',
  styleUrls: ['./load-game-list.component.css']
})
export class LoadGameListComponent implements OnInit {

  TeamId: string;
  gameService: GameService;
  games: GameEventOverviewViewModel[];
  isBusy: boolean;
  isStatisticsUser: boolean;

  constructor(private _gameService: GameService,
        public _route: ActivatedRoute, 
        public _userService: UserService,  
        public _router: Router,
        private _notificationService: NotificationService,        
        private dialog: MatDialog,
        private _snackBar: MatSnackBar ) {

    this.isBusy=true;
    this.gameService = _gameService;    
    this.isStatisticsUser = _userService.roleMatch(['Statistics_Admin','Statistics_Viewer']);
    this._route.params.subscribe(params => {
      this.TeamId = params['TeamId'];    
      this.LoadGames(this.TeamId) ;
    });
  }

  ngOnInit() {
    //console.log("ngOnInit");
    //this.LoadGames(this.TeamId);
    //this.isBusy=false;
  }


  LoadGames(TeamId: string){
    this.isBusy=true;
    this.gameService.getPastGamesForTeam(TeamId).subscribe( g => {
        console.log(g);
        this.games = g;
        this.isBusy=false;
    });
  }

  sendReminder(){

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Send reminder for game assessments? Will only be sent from games within configured time frame in organization settings.'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){

        this._notificationService.sendPushReminder_for_GameAssessment(this.TeamId).subscribe(res=>{
          this._snackBar.open("Push sent", "", {
            duration: 1000,
          });
        });
      }
    });
  }
  

}
