import { Component, OnInit, ɵConsole } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService, ChangePasswordModel, } from '../../shared/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  data: ChangePasswordModel;
  errorMessage : string;
  readytosubmit: boolean;

  changePwdForm = new UntypedFormGroup({
    OldPassword: new UntypedFormControl('',Validators.minLength(6)),
    NewPassword: new UntypedFormControl('',Validators.minLength(6)),
    ConfirmPassword: new UntypedFormControl('', Validators.minLength(6)),
  });



  constructor(private _userService: UserService, private _router: Router) { }

  ngOnInit() {
  }

  OnSubmit(event){
    
    event.preventDefault();
    return this._userService.changePassword(this.changePwdForm.value).subscribe(res=> {
      console.log(res);
      
      this._router.navigateByUrl('/user');
      this.readytosubmit= true;
      
      
    },
    error => {             
        console.log(error.error.ModelState);
        this.errorMessage = error.error.ModelState;
        this.errorMessage = error.error;  
        this.readytosubmit = false;        
        return false;
            
    });
    
    
            

  }

}
