<div style="display: flex; flex-direction: row; flex-wrap: wrap;">
    <div id="{{this.divname}}" style="max-width: 370px;">

    </div>

    <div id="{{this.taskdivname}}" style="max-width: 370px;">

    </div>

    <div id="scouting_gameperformance_chart" style="max-width: 370px;">

    </div>
</div>