<div class="app-page">
    <div style="" *ngIf="!datafound">
        NO FORMS SUBMITTED
    </div>
    <!-- <mat-form-field>
        <mat-label>Columns</mat-label>
        <mat-select [formControl]="selectedColumns" value="columns"  multiple>
          
          <mat-option *ngFor="let column of columns" [value]="column">{{column}}</mat-option>
        </mat-select>
      </mat-form-field> -->
    <div > 
        <mat-table *ngIf="dataSource && datafound" [dataSource]="dataSource" style="max-width: 500px;"  >

            <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column" [sticky]="isSticky(columns[i])">
                <mat-header-cell *matHeaderCellDef>{{columnNames[i].Name}}</mat-header-cell>
                <mat-cell *matCellDef="let object" matTooltip="xcbcxvbxc">
                  <div [class]="getClass(column, object[column])" >
                    {{object[column]}}
                  </div>
                  
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columns;" style="min-height:25px;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[30, 50, 100]"
                 showFirstLastButtons 
                 aria-label="Select page">
  </mat-paginator>
    </div>

</div>

